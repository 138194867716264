import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

const TabContainer = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => props.theme.liBgColor};
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 16px;
`;

const Tab = styled.div`
  position: relative;
  padding: 10px 15px;
  cursor: pointer;
  width: ${(props) => `${100 / props.totalTabs}%`};
  border-radius: ${(props) => (props.active ? "6px" : "unset")};
  text-align: center;
  font: ${(props) => (props.active ? font.boldBase : font.normalBase)};
  color: ${(props) => props.theme.tabTextColor};
  z-index: 1;
  transition: background-color 0.3s linear, transform 0.3s linear;
  &:hover {
    transform: scale(1.02);
    color: ${(props) => props.theme.secondaryBorderColor};
  }
`;

const TabIndicator = styled.div`
  position: absolute;
  bottom: 1px;
  left: 2px;
  height: 40px;
  z-index: 0;
  width: ${(props) => `${100 / props.totalTabs}%`};
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: ${(props) => `0px 1px 3px ${props.theme.boxShadowBgColor}`};
  border-radius: 6px;
  transform: ${(props) => `translateX(${props.activeTab * 99}%)`};
  transition: transform 0.3s linear;
  @media screen and (max-width: 767px) {
    width: 33.33%;
    transform: ${(props) => `translateX(${props.activeTab * 98}%)`};
  }
`;

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            totalTabs={tabs.length}
            active={index === activeTab}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </Tab>
        ))}
        <TabIndicator totalTabs={tabs.length} activeTab={activeTab} />
      </TabContainer>
      <div>{tabs[activeTab].content}</div>
    </>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    })
  ),
};

export default Tabs;
