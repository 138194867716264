import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

export const LabelInput = styled.input.attrs({ type: "text" })`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-radius: 6px;
  padding: 8px;
  margin: 8px 0;
  height: 36px;
  font: ${font.normalBase};
  color: ${font.spanGrey};
  margin-bottom: 10px;
  padding-left: ${(props) => (props.icon ? "40px" : "8px")};
  &:focus {
    outline: 0px;
    box-shadow: none;
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
  &:hover {
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
  ::placeholder {
    font: ${font.normalBase};
    padding-left: ${(props) => (props.icon ? "30px" : "0")};
    color: ${(props) => props.theme.labelTextColor};
  }
`;
const WrapperInput = styled.div`
  position: relative;
`;
const InputImage = styled.img`
  position: absolute;
  left: 10px;
  top: 14px;
`;

const LabelInputField = ({
  placeholder,
  onChange,
  onFocus,
  onBlur,
  width,
  icon,
  value,
}) => {
  return (
    <WrapperInput>
      <LabelInput
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        width={width}
        icon={icon}
        value={value}
      />
      <InputImage src={icon} />
    </WrapperInput>
  );
};

LabelInputField.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
};
export default LabelInputField;
