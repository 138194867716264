/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import * as Sentry from "@sentry/react";

class CatchHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // Capture the error and error info in Sentry
        Sentry.captureException(error, {
            contexts: { react: errorInfo },
        });

        // You can also group errors by setting the fingerprint
        Sentry.captureException(error, {
            fingerprint: ['Crash-Editor-2.O', error.message],
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default CatchHandler;
