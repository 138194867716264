import React from "react";
import { Anchor, FooterWrap } from "./animo-component";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import content from "../constants/content";

const Footer = () => {
  const getYear = new Date().getFullYear();
  return (
    <FooterWrap>
      <p>
        Copyright © {getYear} <span> Animaker Inc. </span> All rights Reserved.
      </p>
      <Anchor className="footer-link">
        <Typography
          content={content.FEEDBACK}
          target="_blank"
          href="https://www.vmaker.com/feedback"
          font={font.normalBase_21}
          color={"#787B80"}
          isAnchor
          cursor="pointer"
        />
        <Typography
          content={content.TERMS}
          font={font.normalBase_21}
          color={"#787B80"}
          isAnchor
          cursor="pointer"
          target="_blank"
          href="https://www.vmaker.com/terms-policy"
        />
        <Typography
          content={content.SUPPORT}
          target="_blank"
          href="https://www.vmaker.com/support"
          font={font.normalBase_21}
          color={"#787B80"}
          isAnchor
          cursor="pointer"
        />
      </Anchor>
    </FooterWrap>
  );
};

export default Footer;
