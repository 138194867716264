import styled from "styled-components";
import PropTypes from "prop-types";
import { RULER_OPTIONS } from "../timeline-constants";
import { font } from "../../../constants/font";

export const PlayheadIndicatorContainer = styled.div.attrs((props) => {
  return {
    style: {
      left: `${props.$x}px`,
      transition: props.$animatePosition ? "left 500ms ease" : "none",
    },
  };
})`
  pointer-events: none;
  position: absolute;
  top: ${RULER_OPTIONS.height - 1}px;
  width: 1px;
  height: calc(100% - ${RULER_OPTIONS.height - 1}px);
  transform: translate(-50%, 0);
  background-color: ${(props) => props.theme.PLAYHEAD_INDICATOR_COLOR};
  z-index: 4;

  .phic--time {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0px 5px;
    height: 17px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.PLAYHEAD_INDICATOR_COLOR};

    display: flex;
    align-items: center;

    > span {
      font: ${font.mediumMicro};
      color: ${(props) => props.theme.PLAYHEAD_INDICATOR_FONT_COLOR};
    }
  }
`;
PlayheadIndicatorContainer.propTypes = {
  $x: PropTypes.number.isRequired,
  $animatePosition: PropTypes.bool,
};
PlayheadIndicatorContainer.displayName = "PlayheadIndicatorContainer";

export const PlayheadThumbContainer = styled.div.attrs((props) => {
  const styles = {
    left: `${props.$x}px`,
    transition: props.$animatePosition ? "left 500ms ease" : "none",
  };
  if (props.$isPlayheadSnap) {
    styles.backgroundColor = props.theme.SNAP_LINE_COLOR;
  }
  return {
    style: styles,
  };
})`
  pointer-events: none;
  position: absolute;
  top: ${RULER_OPTIONS.height - 1}px;
  width: 1px;
  height: calc(100% - ${RULER_OPTIONS.height - 1}px);
  transform: translate(-50%, 0);
  background-color: ${(props) => props.theme.PLAYHEAD_THUMB_COLOR};
  z-index: 4;

  .phtc--head {
    pointer-events: auto;
    width: 30px;
    height: 10px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: ew-resize;

    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${(props) => props.theme.PLAYHEAD_THUMB_COLOR};
      /** @todo replace this with proper svg icon */
      clip-path: path(
        "m 11.110609,9.6139908 8.351408,-6.968265 A 1.6702815,1.5247848 0 0 0 18.301172,2.2379938e-4 H 1.665168 A 1.6702815,1.5247848 0 0 0 0.537727,2.6457258 l 8.351408,6.968265 a 1.6702815,1.5247848 0 0 0 2.221474,0 z"
      );
    }
  }
`;
PlayheadThumbContainer.propTypes = {
  $x: PropTypes.number.isRequired,
  $animatePosition: PropTypes.bool,
};
PlayheadThumbContainer.displayName = "PlayheadThumbContainer";

export const Split = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  pointer-events: auto;
  bottom: 100%;
  cursor: ${(props) => props.cursor || "pointer"};
  transform: translate(-50%, 0);
`;
Split.displayName = "Split";
