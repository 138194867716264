import React from "react";
import PropTypes from "prop-types";
import Text from "./text";
import { getFlatternHtmlText } from "../../helper/splitTextHelper";


class BuildTextEffectDom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlText: props.isSplitAnim ? getFlatternHtmlText(props.text.getIn(["textData", "htmlText"]), {
        isCapitalize: props.text.getIn(["textData", "formats", "containerStyle", "textTransform"]) === "capitalize",
        hasUnderLine: props.text.getIn(["textData", "formats", "containerStyle", "textDecoration"]) === "underline",
      }) : null,
    }
  }

  render() {
    const { isSplitAnim, isSplitLineText, isMaskAnim } = this.props;
    const props = { ...this.props, preventSplitText: isSplitLineText, isSplitLineText: false }
    if (isSplitAnim && isMaskAnim) {
      let { text } = this.props;
      text = text.updateIn(["textData", "htmlText"], () => this.state.htmlText);
      const { isCollideAnim } = this.props;
      return (
        <>
          {
            isCollideAnim && (
              <div className='mask-split-collide' style={{ visibility: "hidden" }}>
                <Text {...props} text={text} />
              </div>
            )
          }
          <div className='splEff-wrapper'>
            <Text {...props} text={text} />
          </div>
          <div className='splEff-wrapper' style={{ opacity: 0 }}>
            <Text {...props} text={text} />
          </div>
        </>
      )
    }
    if (isMaskAnim) {
      return (
        <>
          <div className='splEff-wrapper'>
            <Text {...props} />
          </div>
          <div className='splEff-wrapper'>
            <Text {...props} style={{ opacity: 0 }} />
          </div>
        </>
      )
    }
    if (isSplitAnim) {
      let {text} = this.props;
      text = text.updateIn(["textData", "htmlText"], () => this.state.htmlText);
      return (
        <Text {...props} text={text} />
      )
    }
    // Default return statement to ensure a value is always returned
    return null;
  }
}

BuildTextEffectDom.propTypes = {
  isPlayAll: PropTypes.bool,
  isPlay: PropTypes.bool,
  text: PropTypes.object,
  isSplitAnim: PropTypes.bool,
  isMaskAnim: PropTypes.bool,
  isCollideAnim: PropTypes.bool,
  isSplitLineText: PropTypes.bool,
};


export default BuildTextEffectDom;
