import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initWebWorker } from "../redux/actions/appUtils";
import Worker from "./cors-worker";

const WebWorkerRegister = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            /** @type {Object.<string, globalThis.Worker | null>} */
            const workers = {};

            try {
                // syntax has to match exactly like below
                // see https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272 and https://webpack.js.org/guides/web-workers/
                const corsworker = new Worker(new URL("./utilityworker.js", import.meta.url), { type: "module" });
                const worker = await corsworker.createWorker();
                workers.UTILITY_1 = worker;
            } catch (error) {
                workers.UTILITY_1 = null;
            }

            dispatch(initWebWorker({ status: "done", workers }));
            return workers;
        }
        const workersPromise = init();

        return () => {
            workersPromise
                .then((workers) => {
                    Reflect.ownKeys(workers).forEach((workerKey) => {
                        const worker = workers[workerKey];
                        if (worker) {
                            worker.terminate();
                        }
                        workers[workerKey] = undefined;
                    })
                })
                .catch(() => { });
        }
    }, [dispatch]);

    return null;
}

export default WebWorkerRegister;
