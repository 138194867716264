/* eslint-disable react/sort-comp, jsx-a11y/no-static-element-interactions */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import CheckBox from "../../../common-components/CheckBox";
import { AudioVolumeContainer } from "./propertywindow-components";
import content from "../../../constants/content";
import NumberInput from "../../../common-components/NumberInputComponent";
import Slider from "../../zoom/zoom-slider";
import { getSelectedItemIds, getSelectionContainer } from "../../../helper/IndividualSelectHelper";
import { applyFlip, changeAudioVolume } from "../../../redux/actions/timelineUtils";
import { setDetachData, setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import { isAudioOnly, isVideoOnly } from "../../timeline/timeline-helper";
import { videoItemToAudioItem } from "../library-helper";
import vmTheme from "../../../constants/theme";

class AudioSectionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            volume: props.volume,
        };

        this.isFrameClip = this.isFrameClip.bind(this);
        this.saveVolume = this.saveVolume.bind(this);
        this.onVolumeChange = this.onVolumeChange.bind(this);
        this.onVolumeSubmit = this.onVolumeSubmit.bind(this);
        this.onMuteClick = this.onMuteClick.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.detachAudio = this.detachAudio.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.volume !== this.props.volume && this.props.volume !== this.state.volume) {
            this.setState({ volume: this.props.volume });
        }
    }

    isFrameClip() {
        return (
            this.props.item
            && this.props.item.get("type") === "FRAME"
            && this.props.selectedFrameClip.get("clipId")
        );
    }

    saveVolume(volume) {
        const { selectionContainer, selectedFrameClip, item } = this.props;

        if (
            isAudioOnly(item.get("type"), item.get("subType"))
            || (item.get("isDetachedAudio") && isVideoOnly(item.get("type"), item.get("subType")))
        ) {
            this.props.changeAudioVolume({
                id: item.get("id"),
                volume,
            });
        } else if (this.isFrameClip()) {
            const volumeUpdate = {
                container: selectionContainer,
                id: selectedFrameClip.get("id"),
                clipId: selectedFrameClip.get("clipId"),
                data: { volume },
            };
            this.props.applyFlip([volumeUpdate]);
        } else if (item) {
            const volumeUpdate = {
                container: selectionContainer,
                id: item.get("id"),
                data: { volume },
            };
            this.props.applyFlip([volumeUpdate]);
        }
    }

    onVolumeChange(e, volume) {
        this.setState({ volume: volume / 100 });
    }

    onVolumeSubmit() {
        if (this.props.volume !== this.state.volume) {
            this.saveVolume(this.state.volume);
        }
    }

    onMuteClick() {
        const { volume } = this.state;
        const shouldMute = !(volume === 0);
        this.saveVolume(shouldMute ? 0 : 1);
    }

    onInputFocus() {
        this.props.setWorkspaceTextFocus(true);
    }

    onInputBlur() {
        this.props.setWorkspaceTextFocus(false);
    }

    detachAudio() {
        const { item, selectedFrameClip, selectionContainer } = this.props;
        let selection = item;
        const isClipSelected = this.isFrameClip();

        if (isClipSelected) {
            selection = selection.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
            const playDuration = selection.get("videoEnd") - selection.get("videoStart");
            const playStart = item.get("enterStart");
            selection = selection.set("playStart", playStart);
            selection = selection.set("playEnd", playStart + playDuration);
        }

        if (
            !selection
            || !isVideoOnly(selection.get("type"), selection.get("subType"))
            || selection.get("isAudioDetached")
        ) {
            return;
        }

        const detachedAudio = videoItemToAudioItem({
            videoItem: selection,
            returnImmutable: false,
        });
        detachedAudio.isDetachedAudio = true;

        const toUpdate = [
            {
                container: "audios",
                id: detachedAudio.id,
                newItemData: detachedAudio,
                isAdd: true,
                isAudioDetach: true,
            },
        ];

        if (isClipSelected) {
            let imgDetails = item.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
            imgDetails = imgDetails.set("isAudioDetached", true);
            imgDetails = imgDetails.set("volume", 0);
            imgDetails = imgDetails.set("detachedAudioId", detachedAudio.id);

            toUpdate.push({
                id: item.get("id"),
                container: selectionContainer,
                toUpdate: {
                    frameClipImage: {
                        clipId: selectedFrameClip.get("clipId"),
                        imgDetails: imgDetails.toJS(),
                    },
                },
            });
        } else {
            toUpdate.push({
                id: item.get("id"),
                container: selectionContainer,
                toUpdate: {
                    isAudioDetached: true,
                    volume: 0,
                    detachedAudioId: detachedAudio.id,
                },
            });
        }

        this.props.audioDetach({ toUpdate });
    }

    render() {
        const { intl, item, selectedFrameClip, selectionContainer } = this.props;
        const { volume } = this.state;
        const displayValue = Math.round(volume * 1000) / 10;
        const isMute = volume === 0;
        const { theme } = this.props;

        let canDetach = false;
        let selection = item;

        if (this.isFrameClip()) {
            selection = selection.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
        }

        if (
            selection
            && (selectionContainer === "workspaceItems" || selectionContainer === "workspaceChildren")
            && isVideoOnly(selection.get("type"), selection.get("subType"))
            && !selection.get("isAudioDetached")
        ) {
            canDetach = true;
        }

        return (
            <AudioVolumeContainer>
                <p className="audiovc--heading">
                    <FormattedMessage id={content.VOLUME} />
                </p>
                <NumberInput
                    min={0}
                    max={100}
                    step={1}
                    value={displayValue}
                    precision={1}
                    onChange={this.onVolumeChange}
                    onSubmit={this.onVolumeSubmit}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    suffix="%"
                    saveOnUnmount={true}
                />
                <div className="audiovc--slider">
                    <Slider
                        min={0}
                        max={100}
                        inputMin={0}
                        inputMax={100}
                        value={displayValue}
                        step={1}
                        onChangeSliderValue={this.onVolumeChange}
                        onMouseUp={this.onVolumeSubmit}
                        border={"none"}
                        borderRadius={"3px"}
                        background={vmTheme[theme].veryLightGray}
                        margin={"0"}
                        height={"5px"}
                        thumb={{
                            background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                            border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                            height: "16px",
                            width: "16px",
                            hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                            hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                        }}
                        progressBackground={vmTheme[theme].secondaryBorderColor}
                        isChangeBg={true}
                    />
                </div>
                <div className="audiovc--mute">
                    <div onClick={this.onMuteClick}>
                        <CheckBox
                            isChecked={isMute}
                            borderRadius="4px"
                            checkBoxWidth="18px"
                            checkBoxHeight="18px"
                            checkBoxColor={vmTheme[theme].propertyCheckBoxAudioColor}
                            id={"audiovc--mute-check"}
                            label={intl.formatMessage({ id: content.MUTE })}
                        />
                    </div>
                </div>
                {
                    canDetach && (
                        <div className="audiovc--detach" onClick={this.detachAudio}>
                            <FormattedMessage id={content.DETACH_AUDIO} />
                        </div>
                    )
                }
            </AudioVolumeContainer>
        );
    }
}

AudioSectionComponent.propTypes = {
    volume: PropTypes.number,
    selectionContainer: PropTypes.string,
    item: PropTypes.object,
    selectedFrameClip: PropTypes.object,
    applyFlip: PropTypes.func,
    changeAudioVolume: PropTypes.func,
    setWorkspaceTextFocus: PropTypes.func,
    intl: PropTypes.object,
    theme: PropTypes.string,
    audioDetach: PropTypes.func,
};

const getVolumeFromState = (state) => {
    let container = getSelectionContainer({
        childrenSelection: state.app.get("childrenSelection"),
        selectedItems: state.app.get("selectedItems"),
    });
    const selectedItems = getSelectedItemIds({
        childrenSelection: state.app.get("childrenSelection"),
        selectedItems: state.app.get("selectedItems")
    });
    let item = state.projectDetails.getIn([container, selectedItems.first()]);
    const selectedAudios = state.app.get("selectedAudios");

    let volume = 1; // default volume

    if (item) {
        if (item.get("type") === "FRAME") {
            if (state.app.getIn(["selectedFrameClip", "clipId"])) { // clip is selected
                const imgDetails = item.getIn(["clipDetails", state.app.getIn(["selectedFrameClip", "clipId"]), "imgDetails"]);
                volume = imgDetails.get("volume");
            }
        } else {
            volume = item.get("volume");
        }
    } else if (selectedAudios.size) {
        container = "audios";
        item = state.projectDetails.getIn(["audios", selectedAudios.first()]);

        if (item) {
            volume = item.get("volume");
        }
    }

    if (!Number.isFinite(volume)) {
        volume = 1;
    }

    return {
        selectionContainer: container,
        volume,
        item,
    };
}

const mapStateToProps = (state) => ({
    theme: state.app.get("theme"),
    selectedFrameClip: state.app.get("selectedFrameClip"),
    ...getVolumeFromState(state),
});

const mapDispatchToProps = (dispatch) => ({
    applyFlip: (data) => dispatch(applyFlip(data)),
    changeAudioVolume: (data) => dispatch(changeAudioVolume(data)),
    setWorkspaceTextFocus: (data) => dispatch(setWorkspaceTextFocus(data)),
    audioDetach: (data) => dispatch(setDetachData(data)),
});

const AudioSection = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AudioSectionComponent));

export default AudioSection;
