import styled from "styled-components";

export const OverlayContainer = styled.div`
  position: absolute;
  display: ${(props) => props.display};
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: 0% 0% no-repeat padding-box padding-box
    ${(props) => props.theme.rgbaBlack56};
  z-index: 99999;
`;

export const Overlay = styled.div`
  z-index: 2;
  width: 384px;
  height: 205px;
  top: 248px;
  left: 448px;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
`;

export const CloseIcon = styled.img`
  float: right;
  width: 17px;
  height: 17px;
  margin: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;
export const Wrapper = styled.div`
  padding: 24px;
  input {
    width: "100%";
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.zIndex};
  background: ${(props) => props.background || "transparent"};
  @media (max-width: 768px) {
    background: ${(props) => props.theme.blackColor} 0% 0% no-repeat padding-box;
    opacity: 0.32;
    z-index: 14;
  }
`;

export const Container = styled.div`
  padding: 25px;
`;
