/* eslint-disable no-bitwise */
const uuid = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export function randomString() {
  return `b${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + (new Date().getTime()).toString()}`
}

export default uuid;