/* eslint-disable no-restricted-syntax, react/no-danger, prefer-template, camelcase */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { TweenMax, TimelineMax, Linear, Ease } from "gsap";
import CustomEase from "gsap/CustomEase";
import { _iHSL10, hex2rgb } from "../../helper/colorHelper";
import { clearTimeline } from "./workspace-helper";
import { CustomSVGRenderContainer } from "./workspace-components";
import config from "../../constants/config";

class CustomSVGRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      svgElement: null,
      isSVGLoading: false,
      isAnimationLoading: false,
    };

    this.setColors = this.setColors.bind(this);
    this.setGradient = this.setGradient.bind(this);
    this.clearTimeLineMax = this.clearTimeLineMax.bind(this);
    this.assignRandomId = this.assignRandomId.bind(this);
    this.loadSVGData = this.loadSVGData.bind(this);
    this.assignTweenValues = this.assignTweenValues.bind(this);
    this.loadSVGAnimation = this.loadSVGAnimation.bind(this);

    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.svgContainerRef = React.createRef(null);
    /** @type {AbortController | null} */
    this.abortController = null;
    this.domParser = new DOMParser();
    this.t1 = null;
    this.overAllBenzValues = [];
  }

  componentDidMount() {
    if (
      (this.props.isPlay || this.props.isPlayAll) &&
      this.props.assetType === "PROP" &&
      this.props.isAnimated
    ) {
      this.loadSVGData(this.props.animsrc, false);
    } else if (
      this.props.selectedItems.size === 1 &&
      this.props.selectedItems.get(0) === this.props.data_id &&
      this.props.assetType === "PROP" &&
      this.props.isAnimated
    ) {
      this.loadSVGData(this.props.animsrc, false);
    } else {
      this.loadSVGData(this.props.src, false);
    }
  }

  componentDidUpdate(prevProps) {
    const isSwap =
      this.props.swapDetails !== null && this.props.swapDetails !== undefined;
    const isCurrentItemSelected =
      this.props.selectedItems &&
      this.props.selectedItems.get(0) === this.props.data_id;
    const isCurrentItemSelectedPreviously =
      prevProps.selectedItems &&
      prevProps.selectedItems.get(0) === this.props.data_id;
    const focusChanged =
      isCurrentItemSelected !== isCurrentItemSelectedPreviously;
    let isColorsDifferent = this.props.colors !== prevProps.colors;
    if (isColorsDifferent && this.props.colors && prevProps.colors) {
      isColorsDifferent = !this.props.colors.equals(prevProps.colors);
    }

    if (
      (this.props.isPlay && this.props.isPlay !== prevProps.isPlay) ||
      (this.props.isPlayAll && this.props.isPlayAll !== prevProps.isPlayAll) ||
      this.props.src !== prevProps.src ||
      this.props.animsrc !== prevProps.animsrc ||
      this.props.animdata !== prevProps.animdata ||
      this.props.isAnimated !== prevProps.isAnimated ||
      (isColorsDifferent &&
        (!this.svgContainerRef.current ||
          !this.svgContainerRef.current.getElementsByTagName("svg")[0])) ||
      (this.props.assetType === "PROP" && this.props.isAnimated && focusChanged)
    ) {
      this.clearTimeLineMax();
      let { src } = this.props;
      if (
        this.props.assetType === "PROP" &&
        isCurrentItemSelected &&
        this.props.isAnimated &&
        !isSwap
      ) {
        src = this.props.animsrc;
      }
      this.loadSVGData(src, isSwap);
    } else {
      if (
        isColorsDifferent &&
        this.props.colors &&
        this.svgContainerRef.current &&
        this.svgContainerRef.current.getElementsByTagName("svg")[0]
      ) {
        this.setColors(
          this.props.colors,
          this.svgContainerRef.current.getElementsByTagName("svg")[0]
        );
      }

      if (
        focusChanged &&
        this.t1 &&
        !this.props.isPlay &&
        !this.props.isPlayAll
      ) {
        if (isCurrentItemSelected && !this.t1.isActive()) {
          this.t1.play(0);
        } else if (isCurrentItemSelectedPreviously && this.t1.isActive()) {
          this.t1.pause(0);
        }
      }
    }
  }

  componentWillUnmount() {
    this.clearTimeLineMax();
  }

  setColors(colors, element) {
    try {
      if (this.props.assetSubType === "GRAD") {
        this.setGradient(colors.get("prop1"), colors.get("prop2"), element);
        colors = colors.remove("prop1").remove("prop2");
        if (colors.size > 0) {
          colors.entrySeq().forEach(([_className, _color]) => {
            const cols = element.querySelectorAll(
              "[class*='" + _className + "']"
            );
            for (let i = 0; i < cols.length; i += 1) {
              if (cols[i].getElementsByTagName("path")[0].hasAttribute("fill"))
                cols[i]
                  .getElementsByTagName("path")[0]
                  .setAttribute("fill", _color);
            }
          });
        }
      } else {
        colors.entrySeq().forEach(([_className, _color]) => {
          if (
            _className === "prop1" &&
            element.nodeName === "svg" &&
            this.props.assetSubType === "UPIMAGE" &&
            element.classList.contains("qr-code-container")
          ) {
            element.style.backgroundColor = _color;
          }
          const cols = element.querySelectorAll(
            `[class${
              this.props.assetSubType !== "GETTYICON" ? "*" : ""
            }=${_className}]`
          );
          if (_color !== "") {
            for (let i = 0; i < cols.length; i += 1) {
              let resa = cols[i].className.animVal.replace(/zf/g, ".");
              resa = resa.split("_");
              if (
                cols[i].firstChild &&
                cols[i].firstChild.hasAttribute &&
                !cols[i].className.animVal.includes("Mc")
              ) {
                if (resa.length !== 1) {
                  const newColor = _iHSL10(
                    _color.substr(1),
                    parseFloat(resa[1]),
                    parseFloat(resa[2]),
                    parseFloat(resa[3])
                  );
                  if (cols[i].children.length > 0) {
                    if (cols[i].firstChild.hasAttribute("fill")) {
                      cols[i].firstChild.setAttribute("fill", newColor);
                    }
                    if (cols[i].firstChild.hasAttribute("stroke")) {
                      cols[i].firstChild.setAttribute("stroke", newColor);
                    }
                  }
                } else if (cols[i].children.length > 0) {
                  if (cols[i].firstChild.hasAttribute("fill"))
                    cols[i].firstChild.setAttribute("fill", _color);
                  if (cols[i].firstChild.hasAttribute("stroke"))
                    cols[i].firstChild.setAttribute("stroke", _color);
                }
              } else if (
                cols[i].hasAttribute("fill") ||
                cols[i].hasAttribute("stroke")
              ) {
                if (cols[i].hasAttribute("stroke")) {
                  cols[i].setAttribute("stroke", _color);
                }
                cols[i].setAttribute("fill", _color);
              } else if (
                cols[i].getAttribute("class") &&
                cols[i].getAttribute("class").includes("cls")
              ) {
                if (cols[i].getAttribute("class").includes("applyStroke")) {
                  cols[i].style.stroke = _color;
                } else {
                  cols[i].style.fill = _color;
                }
              } else if (
                cols[i].firstChild !== null &&
                cols[i].firstChild.hasAttribute("fill")
              ) {
                cols[i].firstChild.setAttribute("fill", _color);
              }
            }
          }
        });
      }
    } catch (error) {
      // error
    }
  }

  setGradient(color, subColor, element) {
    const color1 = hex2rgb(color.substr(1));
    const color2 = hex2rgb(subColor.substr(1));

    const r1 = color1[0];
    const g1 = color1[1];
    const b1 = color1[2];
    // color2
    const r2 = color2[0];
    const g2 = color2[1];
    const b2 = color2[2];

    const r_mid = r1 + (r2 - r1) / 2;
    const g_mid = g1 + (g2 - g1) / 2;
    const b_mid = b1 + (b2 - b1) / 2;

    const grads = element.querySelectorAll("linearGradient");
    for (let i = 0; i < grads.length; i += 1) {
      const defs = grads[i].querySelectorAll("stop");
      if (defs && defs[0] !== undefined) {
        defs[0].setAttribute(
          "stop-color",
          "rgb(" + r1 + "," + g1 + "," + b1 + ")"
        );
        defs[1].setAttribute(
          "stop-color",
          "rgb(" + r_mid + "," + g_mid + "," + b_mid + ")"
        );
        defs[2].setAttribute(
          "stop-color",
          "rgb(" + r2 + "," + g2 + "," + b2 + ")"
        );
      }
    }
  }

  clearTimeLineMax() {
    if (this.t1 !== null) {
      clearTimeline(this.t1);
      this.t1 = null;
      for (const name of this.overAllBenzValues) {
        delete Ease.map[name];
      }
      this.overAllBenzValues = [];
    }
  }

  assignRandomId(svgEle) {
    for (let i = 0; i < svgEle.getElementsByTagName("mask").length; i += 1) {
      const random =
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const masks = svgEle.querySelectorAll(
        '[mask="url(#' + svgEle.getElementsByTagName("mask")[i].id + ')"]'
      );
      for (let m = 0; m < masks.length; m += 1) {
        masks[m].setAttribute("mask", "url(#" + random + ")");
      }
      svgEle.getElementsByTagName("mask")[i].id = random;
    }
    for (let i = 0; i < svgEle.getElementsByTagName("filter").length; i += 1) {
      const random =
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const filters = svgEle.querySelectorAll(
        '[filter="url(#' + svgEle.getElementsByTagName("filter")[i].id + ')"]'
      );
      for (let f = 0; f < filters.length; f += 1) {
        filters[f].setAttribute("filter", "url(#" + random + ")");
      }
      svgEle.getElementsByTagName("filter")[i].id = random;
    }
    for (
      let i = 0;
      i < svgEle.getElementsByTagName("clipPath").length;
      i += 1
    ) {
      const random =
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const clips = svgEle.querySelectorAll(
        '[clip-path="url(#' +
          svgEle.getElementsByTagName("clipPath")[i].id +
          ')"]'
      );
      for (let c = 0; c < clips.length; c += 1) {
        clips[c].setAttribute("clip-path", "url(#" + random + ")");
      }
      svgEle.getElementsByTagName("clipPath")[i].id = random;
    }
    for (
      let i = 0;
      i < svgEle.getElementsByTagName("linearGradient").length;
      i += 1
    ) {
      const random =
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const clips = svgEle.querySelectorAll(
        '[fill="url(#' +
          svgEle.getElementsByTagName("linearGradient")[i].id +
          ')"]'
      );
      for (let c = 0; c < clips.length; c += 1) {
        clips[c].setAttribute("fill", "url(#" + random + ")");
      }
      svgEle.getElementsByTagName("linearGradient")[i].id = random;
    }
    for (
      let i = 0;
      i < svgEle.getElementsByTagName("radialGradient").length;
      i += 1
    ) {
      const random =
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const clips = svgEle.querySelectorAll(
        '[fill="url(#' +
          svgEle.getElementsByTagName("radialGradient")[i].id +
          ')"]'
      );
      for (let c = 0; c < clips.length; c += 1) {
        clips[c].setAttribute("fill", "url(#" + random + ")");
      }
      svgEle.getElementsByTagName("radialGradient")[i].id = random;
    }
    const uses = svgEle.getElementsByTagName("use");
    for (let i = 0; i < uses.length; i += 1) {
      const random =
        "a" +
        Math.random().toString(12).substr(0, 10).replace(".", "") +
        new Date().getTime().toString();
      const hrefAttr = uses[i].getAttribute("xlink:href");
      const useTags = svgEle.querySelectorAll("[*|href='" + hrefAttr + "']");
      for (let j = 0; j < useTags.length; j += 1) {
        useTags[j].setAttribute("xlink:href", "#" + random);
      }
      const usePahts = svgEle.querySelectorAll(hrefAttr);
      for (let u = 0; u < usePahts.length; u += 1) {
        usePahts[u].setAttribute("id", random);
      }
    }
  }

  loadSVGData(srcVal, isSwap) {
    const isCurrentItemSelected =
      this.props.selectedItems &&
      this.props.selectedItems.get(0) === this.props.data_id;

    this.setState({ isSVGLoading: true });

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    const abortSignal = this.abortController.signal;

    fetch(srcVal, {
      method: "GET",
      mode: "cors",
      // cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "image/svg+xml",
      },
      signal: abortSignal,
    })
      .then((res) => res.text())
      .then((svgText) => {
        let svgEle = "";
        if (this.props.uploadSVG) {
          const tempEle = document.createElement("div");
          tempEle.innerHTML = svgText;
          svgEle = tempEle.children[0]; // eslint-disable-line prefer-destructuring
        } else {
          svgEle = this.domParser.parseFromString(svgText, "text/xml");
        }

        if (this.props.colors) {
          this.setColors(this.props.colors, svgEle);
        }

        let setClass = "scene-item-inner";
        if (this.props.isBG) {
          setClass = "";
        } else if (this.props.isKeyframe) {
          setClass = "keyframe-item-inner";
        }

        this.assignRandomId(svgEle);
        svgEle = (
          <CustomSVGRenderContainer
            key={this.props.data_id}
            ref={this.svgContainerRef}
            className={setClass}
            id={this.props.data_id + "inner"}
            data-id={this.props.data_id}
            dangerouslySetInnerHTML={{
              __html: svgEle.documentElement.outerHTML,
            }}
          />
        );

        const hasAnimation =
          this.props.isAnimated ||
          this.props.assetType === "SCR" ||
          this.props.assetType === "SCREEN";

        const canAnimate =
          this.props.assetType === "SCR" ||
          this.props.assetType === "SCREEN" ||
          (this.props.assetType === "PROP" && !isSwap);

        const loadAnimation =
          hasAnimation &&
          ((isCurrentItemSelected && canAnimate) ||
            this.props.isPlay ||
            this.props.isPlayAll);

        if (!loadAnimation && (this.props.isPlay || this.props.isPlayAll)) {
          this.props.prefetchComplete(this.props.prefetchToken);
        }

        this.setState(
          {
            svgElement: svgEle,
            isSVGLoading: false,
            isAnimationLoading: loadAnimation,
          },
          () => {
            if (loadAnimation) {
              this.loadSVGAnimation(this.props.animdata, abortSignal);
            }
          }
        );
      })
      .catch(() => {
        if (!abortSignal.aborted) {
          if (this.props.isPlay) {
            this.props.setPlay(false);
          } else if (this.props.isPlayAll) {
            this.props.setPlayAll(false);
          }
        }
      });
  }

  assignTweenValues(res) {
    const { prefetchToken } = this.props;
    const svgContainer = this.svgContainerRef.current;

    const parent = { essentials: res.data.essentials };
    let { animateValues } = res.data;
    let originalValues = {};

    for (const child of Reflect.ownKeys(parent.essentials)) {
      originalValues[child] = {
        positionX: parent.essentials[child].p.split(",")[0],
        positionY: parent.essentials[child].p.split(",")[1],
      };
    }

    let isRepeated = this.props.isRepeated === "0" ? 0 : -1;
    if (!this.props.isRepeated) {
      isRepeated = -1;
    }
    if (this.props.assetType === "PROP" && this.props.isAnimated) {
      isRepeated = this.props.repeatLoop - 1;
    }

    this.t1 = new TimelineMax({
      repeat: isRepeated,
      repeatDelay: 0,
      yoyo: false,
      paused: true,
    });

    const easeVal = Linear.easeNone;
    let characterAnimateVal = [];
    let easeCount = 0;
    let currentEase = {};
    let hipMCPosition;

    for (const idVal of Reflect.ownKeys(animateValues)) {
      for (const valueData of animateValues[idVal]) {
        if (valueData.a === "P") {
          let bezValues;
          if (valueData.e === "no Ease") {
            bezValues = easeVal;
          } else if (currentEase[valueData.e] === undefined) {
            bezValues = valueData.e.split("[")[1].split("]")[0].split(",");
            CustomEase.create(
              "easeName" + this.props.data_id + easeCount,
              '"' +
                bezValues[0] +
                "," +
                bezValues[1] +
                "," +
                bezValues[2] +
                "," +
                bezValues[3] +
                '"'
            );
            this.overAllBenzValues.push(
              "easeName" + this.props.data_id + easeCount
            );
            bezValues = "easeName" + this.props.data_id + easeCount;
            currentEase[valueData.e] = bezValues;
            easeCount += 1;
          } else {
            bezValues = currentEase[valueData.e];
          }

          let posXval = valueData.v.split(",")[0];
          let posYval = valueData.v.split(",")[1];

          if (posXval < 0) posXval = "-=" + Math.abs(posXval);
          else posXval = "+=" + Math.abs(posXval);

          if (posYval < 0) posYval = "-=" + Math.abs(posYval);
          else posYval = "+=" + Math.abs(posYval);

          if (svgContainer.firstElementChild.getElementsByClassName(idVal)[0])
            if (
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
                .firstChild
            ) {
              if (idVal === "hipMc" || idVal === "skateboardMc") {
                if (!hipMCPosition) {
                  hipMCPosition = {
                    x: parseFloat(originalValues[idVal].positionX),
                    y: parseFloat(originalValues[idVal].positionY),
                  };
                } else {
                  hipMCPosition.x += parseFloat(valueData.p.split(",")[0]);
                  hipMCPosition.y += parseFloat(valueData.p.split(",")[1]);
                }
                this.t1.to(
                  svgContainer.firstElementChild.getElementsByClassName(
                    idVal
                  )[0],
                  valueData.d,
                  {
                    immediateRender: false,
                    y: posYval,
                    x: posXval,
                    svgOrigin: hipMCPosition.x + "px " + hipMCPosition.y + "px",
                    ease: bezValues,
                  },
                  valueData.t
                );
                characterAnimateVal.push({
                  domVal:
                    svgContainer.firstElementChild.getElementsByClassName(
                      idVal
                    )[0],
                  duration: valueData.d,
                  toVal: {
                    y: posYval,
                    x: posXval,
                    svgOrigin: hipMCPosition.x + "px " + hipMCPosition.y + "px",
                    ease: bezValues,
                    immediateRender: false,
                  },
                  time: valueData.t,
                });
              } else {
                this.t1.to(
                  svgContainer.firstElementChild.getElementsByClassName(
                    idVal
                  )[0],
                  valueData.d,
                  {
                    immediateRender: false,
                    y: posYval,
                    x: posXval,
                    svgOrigin:
                      parseFloat(originalValues[idVal].positionX) +
                      "px " +
                      parseFloat(originalValues[idVal].positionY) +
                      "px",
                    ease: bezValues,
                    // onComplete: function () {},
                  },
                  valueData.t
                );
                characterAnimateVal.push({
                  domVal:
                    svgContainer.firstElementChild.getElementsByClassName(
                      idVal
                    )[0],
                  duration: valueData.d,
                  toVal: {
                    y: posYval,
                    x: posXval,
                    svgOrigin:
                      parseFloat(originalValues[idVal].positionX) +
                      "px " +
                      parseFloat(originalValues[idVal].positionY) +
                      "px",
                    ease: bezValues,
                    immediateRender: false,
                  },
                  time: valueData.t,
                });
              }
            }
        }

        if (valueData.a === "O") {
          let bezValues;

          if (valueData.e === "no Ease") {
            bezValues = easeVal;
          } else if (currentEase[valueData.e] === undefined) {
            bezValues = valueData.e.split("[")[1].split("]")[0].split(",");
            CustomEase.create(
              "easeName" + this.props.data_id + easeCount,
              '"' +
                bezValues[0] +
                "," +
                bezValues[1] +
                "," +
                bezValues[2] +
                "," +
                bezValues[3] +
                '"'
            );
            this.overAllBenzValues.push(
              "easeName" + this.props.data_id + easeCount
            );
            bezValues = "easeName" + this.props.data_id + easeCount;
            easeCount += 1;
          } else {
            bezValues = currentEase[valueData.e];
          }

          const opacity = valueData.v / 100;
          if (svgContainer.firstElementChild.getElementsByClassName(idVal)[0]) {
            this.t1.to(
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              0.001,
              {
                immediateRender: false,
                opacity,
                ease: Linear.easeNone,
              },
              valueData.t + valueData.d / 2
            );
            characterAnimateVal.push({
              domVal:
                svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              duration: 0.001,
              toVal: {
                immediateRender: false,
                opacity,
                ease: Linear.easeNone,
              },
              time: valueData.t + valueData.d / 2,
            });
          }
        }

        if (valueData.a === "S") {
          let bezValues;
          if (valueData.e === "no Ease") {
            bezValues = easeVal;
          } else if (currentEase[valueData.e] === undefined) {
            bezValues = valueData.e.split("[")[1].split("]")[0].split(",");
            CustomEase.create(
              "easeName" + this.props.data_id + easeCount,
              '"' +
                bezValues[0] +
                "," +
                bezValues[1] +
                "," +
                bezValues[2] +
                "," +
                bezValues[3] +
                '"'
            );
            this.overAllBenzValues.push(
              "easeName" + this.props.data_id + easeCount
            );
            bezValues = "easeName" + this.props.data_id + easeCount;
            easeCount += 1;
          } else {
            bezValues = currentEase[valueData.e];
          }
          const scaleXval = valueData.v.split(",")[0] / 100;
          const scaleYval = valueData.v.split(",")[1] / 100;

          if (svgContainer.firstElementChild.getElementsByClassName(idVal)[0]) {
            this.t1.to(
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              valueData.d,
              {
                scaleY: scaleYval,
                scaleX: scaleXval,
                svgOrigin:
                  parseFloat(originalValues[idVal].positionX) +
                  "px " +
                  parseFloat(originalValues[idVal].positionY) +
                  "px",
                ease: bezValues,
              },
              valueData.t
            );

            characterAnimateVal.push({
              domVal:
                svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              duration: valueData.d,
              toVal: {
                immediateRender: false,
                scaleY: scaleYval,
                scaleX: scaleXval,
                svgOrigin:
                  parseFloat(originalValues[idVal].positionX) +
                  "px " +
                  parseFloat(originalValues[idVal].positionY) +
                  "px",
                ease: bezValues,
              },
              time: valueData.t,
            });
          }
        }

        if (valueData.a === "R") {
          let bezValues;
          if (valueData.e === "no Ease") {
            bezValues = easeVal;
          } else if (currentEase[valueData.e] === undefined) {
            bezValues = valueData.e.split("[")[1].split("]")[0].split(",");
            CustomEase.create(
              "easeName" + this.props.data_id + easeCount,
              '"' +
                bezValues[0] +
                "," +
                bezValues[1] +
                "," +
                bezValues[2] +
                "," +
                bezValues[3] +
                '"'
            );
            this.overAllBenzValues.push(
              "easeName" + this.props.data_id + easeCount
            );
            bezValues = "easeName" + this.props.data_id + easeCount;
            easeCount += 1;
          } else {
            bezValues = currentEase[valueData.e];
          }
          let rotateVal = "";
          if (valueData.v < 0) {
            rotateVal = "-=" + Math.abs(valueData.v);
          } else {
            rotateVal = "+=" + Math.abs(valueData.v);
          }

          if (idVal === "hipMc" || idVal === "skateboardMc") {
            if (!hipMCPosition) {
              hipMCPosition = {
                x: parseFloat(originalValues[idVal].positionX),
                y: parseFloat(originalValues[idVal].positionY),
              };
            } else {
              hipMCPosition.x += parseFloat(valueData.p.split(",")[0]);
              hipMCPosition.y += parseFloat(valueData.p.split(",")[1]);
            }
            if (
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
            ) {
              this.t1.to(
                svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
                valueData.d,
                {
                  rotation: rotateVal,
                  svgOrigin: hipMCPosition.x + "px " + hipMCPosition.y + "px",
                  ease: bezValues,
                },
                valueData.t
              );
              characterAnimateVal.push({
                domVal:
                  svgContainer.firstElementChild.getElementsByClassName(
                    idVal
                  )[0],
                duration: valueData.d,
                toVal: {
                  immediateRender: false,
                  rotation: rotateVal,
                  svgOrigin: hipMCPosition.x + "px " + hipMCPosition.y + "px",
                  ease: bezValues,
                },
                time: valueData.t,
              });
            }
          } else if (
            svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
          ) {
            this.t1.to(
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              valueData.d,
              {
                immediateRender: false,
                rotation: rotateVal,
                svgOrigin:
                  parseFloat(originalValues[idVal].positionX) +
                  "px " +
                  parseFloat(originalValues[idVal].positionY) +
                  "px",
                ease: bezValues,
              },
              valueData.t
            );
            characterAnimateVal.push({
              domVal:
                svgContainer.firstElementChild.getElementsByClassName(idVal)[0],
              duration: valueData.d,
              toVal: {
                immediateRender: false,
                rotation: rotateVal,
                svgOrigin:
                  parseFloat(originalValues[idVal].positionX) +
                  "px " +
                  parseFloat(originalValues[idVal].positionY) +
                  "px",
                ease: bezValues,
              },
              time: valueData.t,
            });
          }
        }

        if (valueData.a === "PA") {
          if (
            svgContainer.firstElementChild.getElementsByClassName(idVal)[0] &&
            svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
              .firstChild !== undefined
          ) {
            /* Few Actions Needs Ease Value's that's why Ease Values Are Updated (ease: Linear.easeNone). If any issues because of this please remove it(ease: Linear.easeNone) */
            this.t1.to(
              svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
                .firstChild,
              valueData.d,
              { attr: { d: valueData.v }, ease: Linear.easeNone },
              Math.abs(valueData.t)
            );
            characterAnimateVal.push({
              domVal:
                svgContainer.firstElementChild.getElementsByClassName(idVal)[0]
                  .firstChild,
              duration: valueData.d,
              toVal: {
                immediateRender: false,
                attr: { d: valueData.v },
                ease: Linear.easeNone,
              },
              time: Math.abs(valueData.t),
            });
          }
        }
      }
    }

    currentEase = {};

    if (this.props.isPlay || this.props.isPlayAll) {
      let startTimeVal = this.props.completeWorkspaceItems.get("enterStart");
      if (this.props.isPlayAll && this.props.assetType !== "SCREEN")
        startTimeVal = this.props.sceneStart + startTimeVal;

      let endTimeVal = this.props.completeWorkspaceItems.get("exitEnd");
      if (this.props.isPlayAll && this.props.assetType !== "SCREEN")
        endTimeVal = this.props.sceneStart + endTimeVal;

      if (this.props.assetType === "SCREEN" && svgContainer) {
        TweenMax.set(svgContainer, {
          opacity: 0,
        });
        this.props.addTween(
          {
            callBack: () => {
              TweenMax.set(svgContainer, { opacity: 1 });
            },
            startTime: startTimeVal,
            // dataArr: [this.props.data_id],
          },
          "addCallback"
        );
      }

      this.props.addTween(
        {
          type: this.props.assetType,
          isRepeated: this.props.isRepeated,
          isAnimated: this.props.isAnimated,
          repeatLoop: this.props.repeatLoop,
          timeline: characterAnimateVal,
          startTime: startTimeVal,
          endTime: endTimeVal,
          idVal: this.props.data_id,
          from: "workspace",
        },
        "addchartween"
      );

      this.props.prefetchComplete(prefetchToken);
    } else {
      this.t1.play();
    }

    // Clearing the objects due to the memeory leak
    characterAnimateVal = {};
    originalValues = {};
    animateValues = {};

    return easeCount;
  }

  loadSVGAnimation(animdataVal, abortSignal) {
    let url = config[this.props.assetType].animdata + animdataVal;

    let projectType = "horizontal";
    if (this.props.workspaceHeight > this.props.workspaceWidth) {
      projectType = "vertical";
    } else if (this.props.workspaceHeight === this.props.workspaceWidth) {
      projectType = "square";
    }

    if (this.props.assetType === "SCR") {
      url =
        config[this.props.assetType].animdata + projectType + "/" + animdataVal;
    } else if (this.props.assetType === "SCREEN") {
      url =
        config[this.props.assetType].animdata + projectType + "/" + animdataVal;
    }

    fetch(url, {
      method: "GET",
      mode: "cors",
      // cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "image/svg+xml",
        Accept: "application/json",
      },
      signal: abortSignal,
    })
      .then((res) => res.json())
      .then((res) => {
        this.assignTweenValues({ data: res });
        this.setState({ isAnimationLoading: false });
      })
      .catch(() => {
        if (this.props.isPlayAll) {
          this.props.setPlayAll(false);
        } else if (this.props.isPlay) {
          this.props.setPlay(false);
        }
      });
  }

  render() {
    return (
      <>
        <CustomSVGRenderContainer
          key="svg-loader"
          className={`csrc-loader ${
            this.state.isSVGLoading || this.state.isAnimationLoading
              ? "is-loading"
              : ""
          }`}
        >
          <img alt="chr-loader" src={config.STATIC_PATH + "chr-loading.gif"} />
        </CustomSVGRenderContainer>
        {this.state.svgElement}
      </>
    );
  }
}

CustomSVGRender.propTypes = {
  isAnimated: PropTypes.bool,
  selectedItems: PropTypes.object,
  data_id: PropTypes.string,
  assetType: PropTypes.string,
  assetSubType: PropTypes.string,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  setPlay: PropTypes.func,
  setPlayAll: PropTypes.func,
  uploadSVG: PropTypes.bool,
  colors: PropTypes.object,
  isBG: PropTypes.bool,
  isKeyframe: PropTypes.bool,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  animdata: PropTypes.string,
  isRepeated: PropTypes.string,
  repeatLoop: PropTypes.number,
  sceneStart: PropTypes.number,
  completeWorkspaceItems: PropTypes.object,
  addTween: PropTypes.func,
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
  animsrc: PropTypes.string,
  src: PropTypes.string,
  swapDetails: PropTypes.object,
};

export default CustomSVGRender;
