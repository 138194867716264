import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Typography from "./Typography";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";
import { ExportExceed } from "../containers/header/header-components";
import Restriction from "./Restriction";
import content from "../constants/content";
import { isEnterPrise } from "../helper/checkUser";

// const ProgressBar = styled.div`
//   width: ${(props) => props.width || "0"};
//   height: 100%;
//   background-color: ${props => props.theme.progressBgColor};
//   transition: width 0.5s;
// `;

const StockWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  label:nth-child(2) {
    background-color: ${(props) => props.theme.propertyColor};
    border-radius: 8px;
  }
`;

const Line = styled.div`
  height: 1px;
  background: ${(props) => props.theme.lineColor};
  margin: 12px 0px;
`;

const ProgressBarComponent = ({
  isExport = false,
  progressArray,
  plan,
  enableUpgrade = false,
  onClick = () => { },
}) => {
  const { exportcount = {} } = useSelector((state) => state.userDetails.credits);
  const balExportCredits = exportcount.totalCredits - exportcount.usedCredits;
  const theme = useSelector((state) => state.app.get("theme"));
  return (
    <>
      {isExport && (
        <>
          <ExportExceed margin="0px" padding="0px">
            <Typography
              content={content.EXPORT_LIMIT}
              font={font.mediumMiniBase}
              display="block"
              color={vmTheme[theme].panelPrimaryColor}
              enableTrim={false}
            />
            <Restriction
              type={"DEFAULT"}
              content={`${balExportCredits <= 0 ? 0 : balExportCredits} / ${exportcount.totalCredits} left`}
              top={"0px"}
              right={"0px"}
            />
          </ExportExceed>
          <Line />
        </>
      )}
      <Typography
        content={content.PREMIUM_ASSETS}
        color={vmTheme[theme].panelPrimaryColor}
        padding="2px 0"
        font={font.mediumMiniBase}
      />
      {progressArray.map((item) => {
        return (
          <StockWrap key={item}>
            <ExportExceed margin="0px" padding="12px 0px 0px">
              <Typography
                content={item.title}
                font={font.mediumMiniBase}
                display="block"
                color={vmTheme[theme].panelPrimaryColor}
                enableTrim={false}
              />
              <Restriction
                content={`${item.value <= 0 ? 0 : item.value} / ${item.max} left`}
                type="DEFAULT"
                top={"0px"}
                right={"0px"}
              />
            </ExportExceed>
          </StockWrap>
        );
      })}
      {enableUpgrade && (
        <>
          <Line />
          <Typography
            content={isEnterPrise(plan) ? content.CONTACT_US : content.UPGRADE}
            color={vmTheme[theme].actionBgColor}
            font={font.boldBase}
            display="block"
            align="center"
            padding="2px 0"
            margin="0"
            cursor="pointer"
            onClick={onClick}
          />
        </>
      )}
    </>
  );
};

ProgressBarComponent.propTypes = {
  progressArray: PropTypes.array,
  isExport: PropTypes.bool,
  enableUpgrade: PropTypes.bool,
  plan: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProgressBarComponent;
