import { useState, useLayoutEffect } from "react";

const usePanelScrollHeight = (props = {}, dependency = []) => {
  const { panelRef, workspaceStage, shouldUpdate } = props;
  const [scrollableHeight, setScrollableHeight] = useState();

  useLayoutEffect(() => {
    if (panelRef.current && shouldUpdate) {
      const scrollableTop = panelRef.current.offsetTop;
      const headerHeight = workspaceStage.top.menu;
      const timelineHeight = workspaceStage.bottom.timeline;
      const panelHeight = window.innerHeight - headerHeight - timelineHeight;
      setScrollableHeight(`${panelHeight - scrollableTop}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceStage, panelRef, shouldUpdate, ...dependency]);

  return scrollableHeight;
};

export default usePanelScrollHeight;
