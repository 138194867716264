import { useDispatch } from "react-redux";
import { addNotification } from "../../redux/actions/notificationUtils";
import uuid from "../uuid";

const useNotify = () => {
    const dispatch = useDispatch();

    const warn = (description, subText) => {
        dispatch(addNotification({
            id: uuid(),
            toastType: "WARNING",
            description,
            subText
        }))
    };

    const success = (description, subText) => {
        dispatch(addNotification({
            id: uuid(),
            toastType: "SUCCESS",
            description,
            subText
        }))
    };

    return {
        warn,
        success
    }
}

export default useNotify;