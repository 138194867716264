import { createGlobalStyle } from 'styled-components';
import PoppinsLightTTF from './fonts/Poppins-Light.ttf';
import PoppinsRegularTTF from './fonts/Poppins-Regular.ttf';
import PoppinsMediumTTF from './fonts/Poppins-Medium.ttf';
import PoppinsSemiBoldTTF from './fonts/Poppins-SemiBold.ttf';
import PoppinsBoldTTF from './fonts/Poppins-Bold.ttf';
import PTSansBoldTTF from './fonts/PTSans-Bold.ttf';
import { TextComponentStyles } from './containers/text/text-components';

const Style = createGlobalStyle`
  html,
  body {
    padding: 0px;
    overflow: hidden;
    margin: 0px;
    -webkit-user-select:none;
    overscroll-behavior: none;
  }
  
  @font-face {
    font-family: 'VM-Poppins';
    src: url(${PoppinsLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'VM-Poppins';
    src: url(${PoppinsRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'VM-Poppins';
    src: url(${PoppinsMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'VM-Poppins';
    src: url(${PoppinsSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'VM-Poppins';
    src: url(${PoppinsBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'VM-PTSans';
    src: url(${PTSansBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  .w-100 {
    width: 100%;
  }

  .d-inline-block {
    display: inline-block;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }
  * {
    outline: 0;
    box-sizing: border-box;
    touch-action: none;
  }

  .unselectable{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .window-mousedrag-cursor {
    cursor: var(--window-mousedrag-cursor, grabbing) !important;
    * {
      cursor: var(--window-mousedrag-cursor, grabbing) !important;
    }
  }

  .lib-text-drop {
    ${TextComponentStyles}
  }

  .allowed {
    cursor: pointer !important;
  }

  .not-allowed {
    cursor: not-allowed !important;
  }
`;
export default Style;
