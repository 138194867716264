import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BackButton, ButtonWrapper } from "./subtitleproperty-components";
import { TabToggleComponent } from "../../../../common-components/TabToggleComponent";
import SubtitlePresets from "./subtitle-preset";
import {
  setPropertyPanel,
  setPropertyWindow,
} from "../../../../redux/actions/appUtils";
import Tabs from "../../../../common-components/Tab";
import SubtitleTextStyle from "./subtitle-textstyle";
import SubtitleAnimation from "./subtitle-animation";
import SubtitleEffects from "./subtitle-effects";
import ViralStyles from "./viral-styles";

const PRESETS = "Presets";
const EDIT_STYLES = "Edit Styles";
const VIRALSTYLES = "Viral Styles";

const styleTabs = [
  { label: "Text Style", content: <SubtitleTextStyle /> },
  { label: "Effects", content: <SubtitleEffects /> },
  { label: "Animation", content: <SubtitleAnimation /> },
];

const SubtitlePropertyWindow = () => {
  const [currentTab, setCurrentTab] = useState(PRESETS);
  const dispatch = useDispatch();

  const toggleTab = (tab) => setCurrentTab(tab);
  const goBack = () => {
    dispatch(setPropertyPanel("SUBTITLE", true));
    dispatch(setPropertyWindow({ component: "", type: "", isOpened: false }));
  };

  return (
    <>
      <ButtonWrapper>
        <BackButton onClick={goBack}>Styles</BackButton>
      </ButtonWrapper>
      <TabToggleComponent
        tab1={PRESETS}
        tab2={EDIT_STYLES}
        tab3={VIRALSTYLES}
        currentTab={currentTab}
        setCurrentTab={toggleTab}
      />
      {currentTab === PRESETS && <SubtitlePresets />}
      {currentTab === EDIT_STYLES && <Tabs tabs={styleTabs} />}
      {currentTab === VIRALSTYLES && <ViralStyles />}
    </>
  );
};

export default SubtitlePropertyWindow;
