import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";
import {
  AnimationContainer,
  Scroll,
  TextAnimOption,
  TextWrapper,
} from "../../panel-components";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import usePanelScrollHeight from "../../../../helper/hooks/useScrollHeight";
import vmTheme from "../../../../constants/theme";
import { EFFECT_TYPES } from "./effects-data";
import { STATIC_PATH } from "../../../../constants/config";
import content from "../../../../constants/content";

const AnimationEffects = ({ textEffect, type, selectedEffect, effectName, isText }) => {
  const [selectedIcon, setSelectedIcon] = useState({
    effect: effectName
  });
  const [filteredEffect, setFilteredEffect] = useState([]);
  const dispatch = useDispatch();
  const panelRef = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const selectedItem = useSelector((state) => {
    return state.app.get("selectedItems");
  });

  const item = useSelector((state) => {
    return state.projectDetails.getIn([
      "workspaceItems",
      selectedItem.get(0)
    ]);
  });

  const workspaceStage = useSelector((state) =>
    state.app.get("workspaceStage")
  );

  const mainScrollableHeight = usePanelScrollHeight({
    panelRef,
    workspaceStage,
    shouldUpdate: true,
  });

  const enterStart = item.get("enterStart");
  const exitEnd = item.get("exitEnd");
  const enterEnd = item.get("enterEnd");
  const exitStart = item.get("exitStart");
  const duration = item.get("exitEnd") - item.get("enterStart");
  const percentage = 30;
  const result = (percentage / 100) * duration;
  const animDuration = duration < 2 ? result : 1;

  const handleEffectName = (effect, src) => {
    selectedEffect(type, src)
    setSelectedIcon({effect, src})
    let toUpdate;
    if (type === EFFECT_TYPES.ENTER) {

      toUpdate = {
        enterEffectName: effect,
        enterEnd: enterEnd > 0 && enterEnd > enterStart ? enterEnd : enterStart + animDuration,
        enterEffectIcon: src
      };

    } else {
      toUpdate = {
        exitEffectName: effect,
        exitStart: exitStart > 0  && exitEnd > exitStart ? exitStart : exitEnd - animDuration,
        exitEffectIcon: src
      };
    }

    dispatch(
      updateTimelineTime({
        toUpdate: [
          {
            container: "workspaceItems",
            id: selectedItem.get(0),
            toUpdate,
          },
        ],
      })
    );
  };

  useEffect(() => {
    if (effectName) {
      setSelectedIcon(prevState => ({
        ...prevState,
        effect: effectName
      }));
    }
  }, [effectName, type])

  useEffect(() => {
    if (isText) {
      setFilteredEffect(textEffect)
    } else {
      const data = textEffect.filter((data) => {
        return (data.type !== content.NEW_TEXT_ANIMATIONS);
      })
      setFilteredEffect(data)
    }
  }, [isText, type])

  return (
    <Scroll height={mainScrollableHeight} ref={panelRef}>
      <AnimationContainer>
        {
        filteredEffect.map((data) => {
          return (
            <>
              <Typography
                content={data.type}
                color={vmTheme[theme].panelPrimaryColor}
                font={font.boldBase}
                padding="16px 0px 0px"
                className="effectTitle"
              />
              <TextAnimOption gap={"15px"}>
                {data.effects.map((animName) => {
                  return (
                    <TextWrapper
                      key={animName}
                      iconSize={"60px"}
                      onClick={() => handleEffectName(animName.effect, animName.src)}
                      selectedIcon={selectedIcon && animName.effect === selectedIcon.effect} >
                      <img src={`${STATIC_PATH}${animName.src}`} alt="" />
                      <Typography
                        content={animName.text}
                        font={font.normalMicro}
                        color={vmTheme[theme].panelPrimaryColor}
                        enableTrim={false}
                        display="block"
                        align="center"
                        cursor="pointer"
                      />
                    </TextWrapper>
                  );
                })}
              </TextAnimOption>
            </>
          );
        })}
      </AnimationContainer>
    </Scroll>
  );
};

AnimationEffects.propTypes = {
  textEffect: PropTypes.array,
  type: PropTypes.string,
  selectedEffect: PropTypes.func,
  effectName: PropTypes.string,
  isText: PropTypes.bool
};

export default AnimationEffects;
