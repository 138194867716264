import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { font } from "../constants/font";
import Typography from "./Typography";
import { STATIC_PATH } from "../constants/config";
import vmTheme from "../constants/theme";
import Action from "./Action";
import Backdrop from "./BackDrop";
import content from "../constants/content";

const Modal = styled.div`
  background: ${(props) => props.theme.rgbaBlack56};
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`;
const floatCloud = keyframes`
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.03);
  }
`;

const CardWrap = styled.div`
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px ${(props) => props.theme.rgbaLightBlack2};
  border-radius: 24px;
  max-width: 600px;
  min-width: 500px;
  min-height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .cancel-btn {
    background: ${(props) => props.theme.uploadCancelBtn};
    margin: 40px auto 0px;
    &:hover {
      background: ${(props) => props.theme.secondaryBorderColor};
      label {
        color: ${(props) => props.theme.polarColor};
      }
    }
    label {
      color: ${(props) => props.theme.panelPrimaryColor};
    }
  }
  @media only screen and (max-width: 767px) {
    min-width: auto;
    flex-wrap: wrap;
    min-height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0px 20px;
  gap: 16px;
  align-items: center;
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const UploadIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50px;
  background: transparent ${(props) => props.theme.uploadLinearColor} 0% 0%
    no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${floatCloud} 2s infinite ease-in-out;
`;

const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  @media only screen and (max-width: 767px) {
    align-items: center;
  }
`;

const Percentage = styled.div`
  margin-left: auto;
  @media only screen and (max-width: 767px) {
    margin: 0 auto;
  }
`;

const move = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100px);
  }
`;

const UploadStriped = styled.div`
  width: 520px;
  overflow: hidden;
  height: 24px;
  margin-right: 10px;
  border-radius: 15px;
  background: ${(props) => props.theme.stripeBgColor};
  position: relative;
  @media only screen and (max-width: 767px) {
    width: 360px;
  }
`;

const UploadBar = styled.div`
  width: ${(props) => props.uploadPercent};
  height: 24px;
  background-image: ${(props) => props.theme.uploadStripeBgColor};
  border-radius: 15px;
  max-width: ${(props) => props.width || 0}%;
  transform: rotate(180deg);
  transition: all 0.4s linear;
  transition-property: width, background-color;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% + 110px);
    transform: rotate(220deg);
    background-image: ${(props) => props.theme.uploadGradientBgColor};
    background-size: 50px 50px;
    animation: 2s linear 0s infinite normal none running ${move};
  }
`;

const SubtitleLoader = ({
  uploadPercent = 0,
  uploadTitle,
  imageSrc,
  show,
  upContent,
  isCanceling = false,
  onCancel,
  isDisablePercentage = false,
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  return (
    <Modal show={show}>
      <Backdrop background={vmTheme[theme].rgbaBlack56} />
      <CardWrap>
        <Wrapper>
          <UploadIcon>
            <img src={`${STATIC_PATH}${imageSrc}`} alt="" />
          </UploadIcon>

          <UploadContent>
            <Typography
              content={uploadTitle}
              font={font.semiBold_45}
              color={vmTheme[theme].panelPrimaryColor}
              align="left"
            />
            <Typography
              content={upContent}
              font={font.normal_24}
              color={vmTheme[theme].labelSubtextColor}
              align="left"
              enableTrim={false}
            />
          </UploadContent>
          {!isDisablePercentage && (
            <Percentage>
              <Typography
                innerContent={`${uploadPercent}%`}
                font={font.bold_28}
                color={vmTheme[theme].uploadPercentageColor}
                align="left"
              />
            </Percentage>
          )}
        </Wrapper>
        {!isDisablePercentage && (
          <UploadStriped>
            <UploadBar width={uploadPercent} />
          </UploadStriped>
        )}
        {onCancel && (
          <Action
            text={isCanceling ? content.CANCELING : content.CANCEL}
            width="172px"
            height="36px"
            className="cancel-btn"
            font={font.boldBase}
            fontColor={vmTheme[theme].panelPrimaryColor}
            background={vmTheme[theme].uploadCancelBtn}
            onClick={onCancel}
            isDisabled={isCanceling}
          />
        )}
      </CardWrap>
    </Modal>
  );
};

SubtitleLoader.propTypes = {
  uploadPercent: PropTypes.number,
  imageSrc: PropTypes.string,
  uploadTitle: PropTypes.string,
  upContent: PropTypes.string,
  show: PropTypes.bool,
  isCanceling: PropTypes.bool,
  isDisablePercentage: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default SubtitleLoader;
