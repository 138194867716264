/// <reference path="../timeline-types.js" />

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  PlayheadIndicatorContainer,
  PlayheadThumbContainer,
  Split,
} from "./timeline-playhead-components";
import { secondsToTimestamp } from "../timeline-helper";
import { RULER_OPTIONS, SLIDER_TYPES } from "../timeline-constants";
import {
  MatchingSnapPointType,
  SliderType,
  ThumbType,
} from "../timeline-proptypes";
import { STATIC_PATH } from "../../../constants/config";
import vmTheme from "../../../constants/theme/index";

const Playhead = (props) => {
  const {
    thumb,
    startPlayheadThumbDrag,
    animatePosition,
    onTransitionEnd,
    matchingSnapPoints,
    sliders
  } = props;
  const theme = useSelector((state) => state.app.get("theme"));

  const isPlayheadSnap = useMemo(() => {
    if (matchingSnapPoints && sliders) {
      return Reflect.ownKeys(matchingSnapPoints).some((snapPointId) => {
        /** @type {MatchingSnapPoint} */
        const matchingSnapPoint = matchingSnapPoints[snapPointId];
        const { sliderId, snapPoint, thumbId } = matchingSnapPoint;

        return (
          sliders[sliderId] &&
          sliders[sliderId][thumbId] &&
          snapPoint.sliderId === SLIDER_TYPES.PLAYHEAD_THUMB
        );
      });
    }

    return false;
  }, [matchingSnapPoints, sliders]);

  let playhead;

  /**
   * function to start playhead drag
   * @param {React.PointerEvent} e
   */
  const startDrag = (e) => {
    if (typeof startPlayheadThumbDrag === "function") {
      startPlayheadThumbDrag({
        event: e,
        thumbId: thumb.id,
        sliderId: thumb.sliderId,
      });
    }
  };

  let { position } = thumb;
  if (thumb.trackPosition) {
    position = thumb.trackPosition;
  }
  const { x, step } = position;

  if (thumb.sliderId === SLIDER_TYPES.PLAYHEAD_INDICATOR) {
    const time = secondsToTimestamp({
      seconds: step * RULER_OPTIONS.interval,
      mode: "indicator",
    });
    playhead = (
      <PlayheadIndicatorContainer
        $x={x}
        $animatePosition={animatePosition}
        onTransitionEnd={onTransitionEnd}
      >
        <div className="phic--time">
          <span>{time}</span>
        </div>
      </PlayheadIndicatorContainer>
    );
  } else if (thumb.sliderId === SLIDER_TYPES.PLAYHEAD_THUMB) {
    playhead = (
      <PlayheadThumbContainer
        $x={x}
        $animatePosition={animatePosition}
        $isPlayheadSnap={isPlayheadSnap}
        onTransitionEnd={onTransitionEnd}
      >
        <Split
          data-tooltip-id={"split-tooltip"}
          src={`${STATIC_PATH}${vmTheme[theme].icons.splitArrowIcon}`}
          draggable={false}
          cursor="ew-resize"
          onPointerDown={startDrag}
        />
      </PlayheadThumbContainer>
    );
  }

  return playhead;
};

Playhead.propTypes = {
  thumb: ThumbType.isRequired,
  startPlayheadThumbDrag: PropTypes.func,
  animatePosition: PropTypes.bool,
  onTransitionEnd: PropTypes.func,
  matchingSnapPoints: PropTypes.objectOf(MatchingSnapPointType),
  sliders: PropTypes.objectOf(SliderType),
  isDragging: PropTypes.bool.isRequired,
  splitHandler: PropTypes.func,
};

Playhead.defaultProps = {
  animatePosition: false,
};

export default Playhead;
