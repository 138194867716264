import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { MOBILE_THRESHOLD } from "../../constants";
import { setIsMobileView } from "../../redux/actions/appUtils";

const useMobileDetection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_THRESHOLD);
      dispatch(setIsMobileView(window.innerWidth <= MOBILE_THRESHOLD));
    };
    handleResize(); // Initial call to set the initial state

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile;
};

export default useMobileDetection;
