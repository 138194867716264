import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { STATIC_PATH } from "../constants/config";
import Typography from "./Typography";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const Container = styled.div`
  letter-spacing: 0px;
  color: ${(props) => props.theme.polarColor};
  opacity: 1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  width: auto !important;
  height: auto !important;
`;

const Upgrade = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 14px;
  position: ${(props) => props.position || "relative"};
  top: ${(props) => props.top || "auto"};
  right: ${(props) => props.right || "auto"};
  padding: 3px 6px !important;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  z-index: 1;
  &:hover {
    ${Image} {
      transform: rotate(360deg) scale(1.02);
      transition-duration: 1s;
    }
  }
`;

const Restriction = ({
  className,
  top,
  right,
  position,
  onClick = () => {},
  content,
  type = "DEFAULT",
  disableBG = false,
  isLowCredit = false,
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  const getContent = () => {
    switch (type) {
      case "UPGRADE":
        return (
          <>
            <Image src={`${STATIC_PATH}star-icons.svg`} alt="" />
            <Typography
              innerContent={content}
              font={font.normalMicro}
              padding="4px"
              color={vmTheme[theme].polarColor}
              cursor="pointer"
              className={type}
            />
          </>
        );
      case "LIMIT_EXCEED_WITH_UPGRADE":
      case "LIMIT_EXCEED":
        return (
          <>
            {content && (
              <Typography
                innerContent={content}
                font={font.boldMini_12}
                padding="3px 8px"
                color={
                  disableBG
                    ? vmTheme[theme].sbSecondaryColor
                    : vmTheme[theme].polarColor
                }
                cursor="pointer"
                className={type}
                textStyles={{
                  height: "19px",
                  backgroundColor: disableBG
                    ? "transparent"
                    : vmTheme[theme].limitDangerColor,
                  borderRadius: "4px",
                }}
              />
            )}
            {type === "LIMIT_EXCEED_WITH_UPGRADE" ? (
              <Image
                src={`${STATIC_PATH}vm-bg-star.svg`}
                position={position}
                right={right}
                alt=""
              />
            ) : null}
          </>
        );
      default: {
        const backgroundColor = isLowCredit
          ? vmTheme[theme].limitDangerColor
          : disableBG
          ? "transparent"
          : vmTheme[theme].panelPrimaryColor;
        return (
          <Typography
            innerContent={content}
            font={font.boldMini_12}
            padding="3px 8px"
            color={
              disableBG
                ? vmTheme[theme].sbSecondaryColor
                : vmTheme[theme].polarColor
            }
            cursor="pointer"
            className={type}
            textStyles={{
              height: "19px",
              backgroundColor,
              borderRadius: "4px",
            }}
          />
        );
      }
    }
  };

  return (
    <Container className={className}>
      <Upgrade
        top={top}
        right={right}
        position={position}
        onClick={onClick}
        className="restriction-btn"
        backgroundColor={
          type === "UPGRADE" && !disableBG
            ? vmTheme[theme].panelPrimaryColor
            : ""
        }
      >
        {getContent()}
      </Upgrade>
    </Container>
  );
};

Restriction.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disableBG: PropTypes.bool,
  isLowCredit: PropTypes.bool,
};

export default Restriction;
