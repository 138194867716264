/**
 * This function is to avoid cors error.
 * @param {string} src
 * @returns source link
 */
export const addCacheClearQuery = (src, shouldUseMediaCache) => {
  if (src.includes("?")) {
    src += "&";
  } else {
    src += "?";
  }
  src += "animakerccq=1";
  if (shouldUseMediaCache) {
    src += "&vmakermcache=1";
  }
  return src;
};
