/* eslint-disable react/no-array-index-key, operator-assignment */
import React from "react";
import { object, number, string } from "prop-types";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { getObjectsBounds } from "../../helper/TransformManagerHelper";
import {
  ITEM_OUTLINE_COLOR,
  getSelectedObjects,
  getSelectedParent,
  getSelectionContainer,
} from "../../helper/IndividualSelectHelper";
import { isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getFlipPosition } from "../workspace/workspace-helper";

const highlightPlotCSS = css`
  position: absolute;
  width: ${({ $plot }) => `${$plot.width}px`};
  height: ${({ $plot }) => `${$plot.height}px`};
  transform: ${({ $plot, $flip }) =>
    `translate(${$plot.x}px, ${$plot.y}px) rotate(${$plot.angleInDeg}deg) ${
      $flip || ""
    }`};

  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};
`;

const SelectionHighlightContainer = styled.div`
  ${highlightPlotCSS}
  pointer-events: none;
`;
SelectionHighlightContainer.displayName = "SelectionHighlightContainer";

const SelectionHighlight = styled.div`
  ${highlightPlotCSS}
  pointer-events: none;
  outline: ${({ $outlineColor }) => `2px solid ${$outlineColor}`};
`;
SelectionHighlight.displayName = "SelectionHighlight";

const FrameClipSelectionStyled = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
  }

  > div > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
FrameClipSelectionStyled.displayName = "FrameClipSelectionStyled";

const FrameClipSelection = (props) => {
  const defaultWidth = props.frameItem.get("defaultWidth");
  const defaultHeight = props.frameItem.get("defaultHeight");
  const frameWidth = props.frameItem.get("width") * props.zoomFactor;
  const frameHeight = props.frameItem.get("height") * props.zoomFactor;
  const clipPath = props.frameItem.getIn([
    "clipDetails",
    props.clipId,
    "clipData",
  ]);

  const frameClipHeightStyles = {
    width: `${defaultWidth}px`,
    height: `${defaultHeight}px`,
    transform: `scaleX(${frameWidth / defaultWidth}) scaleY(${
      frameHeight / defaultHeight
    })`,
  };

  const ASPECT_RATIO = 1.1666666667;
  /**
   * strokeWidth is taken for this {@link ASPECT_RATIO}
   */
  let strokeWidth = 2;

  strokeWidth = (strokeWidth / ASPECT_RATIO) * (defaultWidth / frameWidth);

  return (
    <FrameClipSelectionStyled>
      <div style={frameClipHeightStyles}>
        <svg>
          <path
            d={clipPath}
            strokeWidth={strokeWidth}
            stroke={ITEM_OUTLINE_COLOR.PROP}
            fill="none"
          />
        </svg>
      </div>
    </FrameClipSelectionStyled>
  );
};
FrameClipSelection.propTypes = {
  frameItem: object,
  zoomFactor: number,
  clipId: string,
};

const SelectionHighlightComponent = (props) => {
  const isSelectionBoxTransforming = props.transformStatus.get("transforming");

  const getOutlineColor = (item) => {
    let type = item.get("type");
    if (item.get("type") === "SHAPE" || item.get("type") === "FRAME") {
      type = "PROP";
    } else if (isVideoOnly(item.get("type"), item.get("subType"))) {
      type = "VIDEO";
    } else if (item.get("type") === "IMG" && item.get("GIPHY") === undefined) {
      type = "GIF";
    } else if (isImageOnly(item.get("type"), item.get("subType"))) {
      type = "IMAGE";
    }
    return ITEM_OUTLINE_COLOR[type] !== undefined
      ? ITEM_OUTLINE_COLOR[type]
      : ITEM_OUTLINE_COLOR.DEFAULT;
  };

  const containerPlot = { x: 0, y: 0, width: 0, height: 0, angleInDeg: 0 };
  const highlight = [];

  if (!isSelectionBoxTransforming) {
    if (props.selectionContainer === "workspaceItems") {
      const bounds = getObjectsBounds(props.selectedObjects);
      containerPlot.x = bounds.x * props.zoomFactor;
      containerPlot.y = bounds.y * props.zoomFactor;
      containerPlot.width = bounds.width * props.zoomFactor;
      containerPlot.height = bounds.height * props.zoomFactor;
    } else if (
      props.selectionContainer === "workspaceChildren" &&
      props.selectedParent !== null
    ) {
      containerPlot.x = props.selectedParent.get("x") * props.zoomFactor;
      containerPlot.y = props.selectedParent.get("y") * props.zoomFactor;
      containerPlot.width =
        props.selectedParent.get("width") * props.zoomFactor;
      containerPlot.height =
        props.selectedParent.get("height") * props.zoomFactor;
      containerPlot.angleInDeg = props.selectedParent.get("angle");
    }

    props.selectedObjects.forEach((item, itemId) => {
      const flip = getFlipPosition(item.get("flipPosition"));
      const itemPlot = {
        x: item.get("x") * props.zoomFactor,
        y: item.get("y") * props.zoomFactor,
        width: item.get("width") * props.zoomFactor,
        height: item.get("height") * props.zoomFactor,
        angleInDeg: item.get("angle"),
      };

      itemPlot.x = itemPlot.x - containerPlot.x;
      itemPlot.y = itemPlot.y - containerPlot.y;

      let outlineColor = getOutlineColor(item);
      let frameSelection = null;
      if (
        item.get("type") === "FRAME" &&
        props.selectedFrameClip.get("clipId") &&
        props.selectedFrameClip.get("id") === itemId
      ) {
        frameSelection = (
          <FrameClipSelection
            frameItem={item}
            clipId={props.selectedFrameClip.get("clipId")}
            zoomFactor={props.zoomFactor}
          />
        );
        if (props.selectionContainer === "workspaceItems") {
          outlineColor = ITEM_OUTLINE_COLOR.DEFAULT;
        }
      } else if (
        item.get("type") === "GRID" &&
        props.selectedFrameClip.get("clipId") &&
        props.selectedFrameClip.get("id") === itemId
      ) {
        /*
          frameSelection = (
              <GridHighlightComponent
                  gridItemData={item}
                  clipId={props.selectedFrameClip.get("clipId")}
                  zoomFactor={props.zoomFactor}
                  selectedItemId={itemId}
              />
          )
          */
      }

      highlight.push(
        <SelectionHighlight
          key={itemId}
          $plot={itemPlot}
          $outlineColor={outlineColor}
          $hide={isSelectionBoxTransforming}
          $flip={flip}
        >
          {frameSelection}
        </SelectionHighlight>
      );
    });
  }

  return (
    <SelectionHighlightContainer
      data-html2canvas-ignore="true"
      $plot={containerPlot}
      $hide={isSelectionBoxTransforming}
    >
      {highlight}
    </SelectionHighlightContainer>
  );
};
SelectionHighlightComponent.propTypes = {
  transformStatus: object,
  selectionContainer: string,
  selectedObjects: object,
  zoomFactor: number,
  selectedParent: object,
  selectedFrameClip: object,
};

const mapStateToProps = (state) => ({
  selectedObjects: getSelectedObjects({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
    workspaceChildren: state.projectDetails.get("workspaceChildren"),
    workspaceItems: state.projectDetails.get("workspaceItems"),
  }),
  selectionContainer: getSelectionContainer({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
  }),
  selectedParent: getSelectedParent({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
    workspaceItems: state.projectDetails.get("workspaceItems"),
  }),
  transformStatus: state.app.get("transformStatus"),
  zoomFactor: state.app.get("zoomFactor"),
  selectedFrameClip: state.app.get("selectedFrameClip"),
});

export default connect(mapStateToProps)(SelectionHighlightComponent);
