import { Record } from "immutable";
import content from "./content";
import { STATIC_PATH } from "./config";
import vmTheme from "./theme/index";

const {
  MOVE_TO,
  RENAME,
  DELETE,
  NO_TRANSITION,
  FADE,
  SIDE_LEFT,
  SIDE_TOP,
  SIDE_BOTTOM,
  SIDE_RIGHT,
  LANDSCAPE,
  POETRAIT,
  SQUARE,
} = content;

export const PORTAL_ID = "modal-portal";

export const MOBILE_THRESHOLD = 768;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PANEL = {
  NONE: "NONE",
  VIRALSTYLE: "VIRALSTYLE",
  PROPERTIES: "PROPERTIES",
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  MUSIC: "MUSIC",
  UPLOAD: "UPLOAD",
  TRANSITIONS: "TRANSITIONS",
  FAVOURITE: "FAVOURITE",
  GIPHY: "GIPHY",
  VERSION_HISTORY: "VERSION_HISTORY",
  SUBTITLE: "SUBTITLE",
  UNSPLASH: "UNSPLASH",
  PEXELS: "PEXELS",
  PEXELS_VIDEO: "PEXELS_VIDEO",
  PIXABAY: "PIXABAY",
  PIXABAY_VIDEO: "PIXABAY_VIDEO",
  MORE: "MORE",
  AVATAR: "AVATAR"
};

export const PanelRecord = Record({
  selectedPanel: PANEL.NONE,
  isExpand: false,
});

export const PropertyWindowRecord = Record({
  component: "",
  subWindow: "",
  isTextColor: false,
  type: "",
  isOpened: false,
});

export const PANEL_ITEMS = (theme) => {
  return [
    {
      name: PANEL.AVATAR,
      src: `${STATIC_PATH}${vmTheme[theme].icons.avatar}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.avatarSelected}`,
      content: content.AI_AVATARS,
    },
    {
      name: PANEL.VIDEO,
      src: `${STATIC_PATH}${vmTheme[theme].icons.videoIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.videoIcon}`,
      content: content.VIDEO,
    },
    {
      name: PANEL.IMAGE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.imageIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.imageIconSelected}`,
      content: content.IMAGE,
    },
    {
      name: PANEL.MUSIC,
      src: `${STATIC_PATH}${vmTheme[theme].icons.musicIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.musicIcon}`,
      content: content.MUSIC,
    },
    {
      name: PANEL.SUBTITLE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
      content: content.SUBTITLE,
    },
    {
      name: PANEL.PROPERTIES,
      src: `${STATIC_PATH}${vmTheme[theme].icons.property}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.propertySelected}`,
      content: content.ELEMENTS,
    },
    {
      name: PANEL.TEXT,
      src: `${STATIC_PATH}${vmTheme[theme].icons.textIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.textIcon}`,
      content: content.TEXT,
    },
    {
      name: PANEL.UPLOAD,
      src: `${STATIC_PATH}${vmTheme[theme].icons.uploadIconSelected}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.uploadIcon}`,
      content: content.UPLOAD,
    },

    // {
    //   name: PANEL.GIPHY,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.giphyIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.giphySelectedIcon}`,
    //   content: content.GIPHY,
    // },
    {
      name: PANEL.TRANSITIONS,
      src: `${STATIC_PATH}transitions-panel-icon.svg`,
      selectedSrc: `${STATIC_PATH}transitions-panel-selected.svg`,
      content: content.TRANSITIONS,
    },

    // {
    //   name: PANEL.UNSPLASH,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.UNSPLASH,
    // },
    // {
    //   name: PANEL.PEXELS,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PEXELS,
    // },
    // {
    //   name: PANEL.PEXELS_VIDEO,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PEXELS_VIDEO,
    // },
    // {
    //   name: PANEL.PIXABAY,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PIXABAY,
    // },
    // {
    //   name: PANEL.PIXABAY_VIDEO,
    //   src: `${STATIC_PATH}${vmTheme[theme].icons.subtitleHoverIcon}`,
    //   selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.subtitleIcon}`,
    //   content: content.PIXABAY_VIDEO,
    // }
    {
      name: PANEL.MORE,
      src: `${STATIC_PATH}${vmTheme[theme].icons.moreIcon}`,
      selectedSrc: `${STATIC_PATH}${vmTheme[theme].icons.moreActiveIcon}`,
      content: content.MORE,
    },
  ];
};

export const PROPERTIES_FILTER = [
  {
    category: "Shapes (With Text)",
    source: [
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/1bb3463aa83d4f3899de4dc7eace3694-shape-0009.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/10b95b3a7cef401daadd65d9695c0a13-shape-0011.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/4ce2b2ee54fb45fdb0337c7d6cc10a86-shape-0013.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d121c5aea1084db48125d6924d6f459a-shape-0001.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/9cafd6d1c2e4401f80aa357c1578a8ca-shape-0002.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/d75f1cdd5751457f9c73abb2fb151bce-shape-0003.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/29eae6290a834014b648b0743638a29e-shape-0030.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/e3b66477d06f46e58cc7f56800ace373-shape-0018.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/1bb3463aa83d4f3899de4dc7eace3694-shape-0009.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/10b95b3a7cef401daadd65d9695c0a13-shape-0011.png",
      },
      {
        src: "https://library.animaker.com/animaker/shapes/thumbnails/4ce2b2ee54fb45fdb0337c7d6cc10a86-shape-0013.png",
      },
    ],
  },
];

export const VIDEO_FILTERS = [
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
  {
    src: "https://static.animaker.com/animapp-static/images/filter-icons/original.png",
    title: "Original",
  },
];

export const TRANSITION_PANEL_ITEMS = [
  {
    src: `${STATIC_PATH}no-transition.svg`,
    title: NO_TRANSITION,
  },
  {
    src: `${STATIC_PATH}fade.svg`,
    title: FADE,
  },
  {
    src: `${STATIC_PATH}side-left.svg`,
    title: SIDE_LEFT,
  },
  {
    src: `${STATIC_PATH}side-top.svg`,
    title: SIDE_TOP,
  },
  {
    src: `${STATIC_PATH}side-bottom.svg`,
    title: SIDE_BOTTOM,
  },
  {
    src: `${STATIC_PATH}side-right.svg`,
    title: SIDE_RIGHT,
  },
];

export const MENU_OPTIONS = [MOVE_TO, RENAME, DELETE];

export const CONSTANTS = {
  LIBRARY_TYPE: {
    TEXT: "TEXT",
    PROPERTY: {
      MASPROP: "MASPROP",
      PROP_SHAPE_FRAME: "PROP,SHAPE,FRAME",
    },
    VIDEO: {
      STOCKVID: "STOCKVID",
      MASSTOCKVID: "MASSTOCKVID",
      PEXELS_VIDEO: "PEXELS_VIDEO",
      PIXABAY_VIDEO: "PIXABAY_VIDEO"
    },
    IMAGE: {
      STOCKIMG: "STOCKIMG",
      MASSTOCKIMG: "MASSTOCKIMG",
      UNSPLASH: "UNSPLASH",
      PEXELS: "PEXELS",
      PIXABAY: "PIXABAY"
    },
    MUSIC: {
      MASBGM: "MASBGM",
      MASSFX: "MASSFX",
      SFX: "SFX",
      BGM: "BGM",
    },
    GIPHY: {
      STOCKGIPHY: "GIPHY",
      MASGIPHY: "MASGIPHY",
    },
    VIRALSTYLE: {
      STOCKGIPHY: "VIRALSTYLE",
      MASGIPHY: "MASVIRALSTYLE",
    },
  },
};

export const FILE_FORMATS = [
  { label: "mp4", id: "mp4", value: "mp4" },
  { label: "avi", id: "avi", value: "avi" },
  { label: "mkv", id: "mkv", value: "mkv" },
  { label: "flv", id: "flv", value: "flv" },
  { label: "wmv", id: "wmv", value: "wmv" },
  { label: "webm", id: "webm", value: "webm" },
];

export const PLAN = {
  FREE: "free",
  LITE: "lite",
  STARTER: "starter",
  TEAM: "team",
  ENTERPRISE: "enterprise",
  TIER1: "tier1",
  TIER2: "tier2",
  TIER3: "tier3",
  TIER4: "tier4",
  TIER5: "tier5",
};

export const PLAN_CONFIG = {
  SIZE_OPTIONS: {
    [PLAN.LITE]: 500 * 1024 * 1024,  // 500MB
    [PLAN.STARTER]: 2 * 1024 * 1024 * 1024, // 2GB
    [PLAN.TEAM]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER1]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER2]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER3]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER4]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.TIER5]: 4 * 1024 * 1024 * 1024, // 4GB
    [PLAN.ENTERPRISE]: 50 * 1024 * 1024 * 1024 // 50GB
  }
}

export const QUALITY_OPTIONS = (name, plan, exportquality) => {
  const quality = exportquality || ['4K', '2K', 'FHD', 'HD', 'SD'];
  switch (name) {
    case "horizontal":
      return [
        {
          label: "SD 480p",
          id: "480p",
          value: "pre0001",
          subtext: "Select Resolution",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),
        },
        {
          label: "HD 720p",
          id: "720p",
          value: "pre0002",
          subtext: "Select Resolution",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),
        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0003",
          subtext: "Select Resolution",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0004",
          subtext: "Select Resolution",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0005",
          subtext: "Select Resolution",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
        },
      ];
    case "vertical":
      return [
        {
          label: "SD 360p",
          id: "360p",
          value: "pre0006",
          subtext: "Select Resolution",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),
        },
        {
          label: "HD 720p",
          id: "720p",
          value: "pre0007",
          subtext: "Select Resolution",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),
        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0008",
          subtext: "Select Resolution",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0009",
          subtext: "Select Resolution",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0010",
          subtext: "Select Resolution",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
        },
      ];
    default:
      return [
        {
          label: "SD 400p",
          id: "400p",
          value: "pre0011",
          subtext: "Select Resolution",
          name: "SD",
          isUpgrade: !(quality.indexOf("SD") > -1),
        },
        {
          label: "HD 600p",
          id: "600p",
          value: "pre0012",
          subtext: "Select Resolution",
          name: "HD",
          isUpgrade: !(quality.indexOf("HD") > -1),
        },
        {
          label: "Full HD 1080p",
          id: "1080p",
          value: "pre0013",
          subtext: "Select Resolution",
          name: "FHD",
          isUpgrade: !(quality.indexOf("FHD") > -1),
        },
        {
          label: "2k WQHD 1440p",
          id: "1440p",
          value: "pre0014",
          subtext: "Select Resolution",
          name: "2K",
          isUpgrade: !(quality.indexOf("2K") > -1),
        },
        {
          label: "4k Ultra HD 2160p",
          id: "2160p",
          value: "pre0015",
          subtext: "Select Resolution",
          name: "4K",
          isUpgrade: !(quality.indexOf("4K") > -1),
        },
      ];
  }
};

export const DEFAULT_QUALITY_OPTIONS = (name, plan, exportquality) => {
  let value = null;
  switch (plan) {
    case PLAN.ENTERPRISE:
      value = QUALITY_OPTIONS(name, plan, exportquality)[2].value;
      break;
    case PLAN.TIER1:
    case PLAN.TIER2:
    case PLAN.TIER3:
    case PLAN.TIER4:
    case PLAN.TIER5:
    case PLAN.TEAM:
      value = QUALITY_OPTIONS(name, plan, exportquality)[1].value;
      break;
    case PLAN.STARTER:
      value = QUALITY_OPTIONS(name, plan, exportquality)[1].value;
      break;
    case PLAN.LITE:
      value = QUALITY_OPTIONS(name, plan, exportquality)[0].value;
      break;
    default:
      value = QUALITY_OPTIONS(name, PLAN.LITE, exportquality)[0].value;
      break;
  }
  return value;
};

export const APP_Z_INDICES = {
  workspaceWrapper: {
    default: 0,
    onPlay: 2,
  },
  timeline: {
    default: 3,
  },
  header: {
    default: 1,
    onPlay: "auto",
  },
  itemtoolbar: {
    default: 4,
  },
  itemtoolbarconfirm: {
    default: 5,
  },
  headerPreviewButton: {
    // inside header
    default: 3,
  },
  sidebar: {
    default: 1,
  },
  libraryDragItem: {
    default: 6,
  },
  customtooltip: {
    default: 99999,
  },
  playerbufferloader: {
    // inside workspace
    default: 999,
  },
  playerprogressloader: {
    // inside workspace
    default: 999,
  },
  colorpicker: {
    default: 998,
  },
};

export const KEYBOARD_SHORTCUTS_DATA = {
  Slides: [
    {
      name: "Add Scene",
      keys: ["Cmd", "M"],
    },
    {
      name: "Select Next Scene",
      keys: ["↓"],
    },
    {
      name: "Select Previous Scene",
      keys: ["↑"],
    },
    {
      name: "Move Scene Up",
      keys: ["↑"],
    },
    {
      name: "Move Scene Down",
      keys: ["↓"],
    },
    {
      name: "Move Scene First",
      keys: ["Cmd", "Shift", "↑"],
    },
    {
      name: "Move Scene Last",
      keys: ["Cmd", "Shift", "↓"],
    },
    {
      name: "Duplicate Scene",
      keys: ["Cmd", "D"],
    },
    {
      name: "Delete Scene",
      keys: ["Delete"],
    },
    {
      name: "Select Multiple Scene",
      keys: ["Cmd", "Click"],
    },
    {
      name: "Change Transition",
      keys: ["Shift", "T"],
    },
    {
      name: "Copy Scene With Audio",
      keys: ["Cmd", "Shift", "C"],
    },
  ],
  Timeline: [
    {
      name: "Increase Item Start 1 Sec",
      keys: ["Opt", "S"],
    },
    {
      name: "Decrease Item Start 1 Sec",
      keys: ["Opt", "A"],
    },
    {
      name: "Increase Item End 1 Sec",
      keys: ["Opt", "W"],
    },
    {
      name: "Decrease Item End 1 Sec",
      keys: ["Opt", "Q"],
    },
    {
      name: "Increase Enter Effect Time 1 Sec",
      keys: ["Shift", "Opt", "S"],
    },
    {
      name: "Decrease Enter Effect Time 1 Sec",
      keys: ["Shift", "Opt", "A"],
    },
    {
      name: "Increase Exit Effect Time 1 Sec",
      keys: ["Shift", "Opt", "W"],
    },
    {
      name: "Decrease Exit Effect Time 1 Sec",
      keys: ["Shift", "Opt", "Q"],
    },
    {
      name: "Change Enter Effect",
      keys: ["Opt", "←"],
    },
    {
      name: "Change Exit Effect",
      keys: ["Opt", "→"],
    },
    {
      name: "Increase Scene Duration",
      keys: ["Opt", "L"],
    },
    {
      name: "Decrease Scene Duration",
      keys: ["Opt", "K"],
    },
    {
      name: "Increase Playhead 1 Sec",
      keys: ["→"],
    },
    {
      name: "Decrease Playhead 1 Sec",
      keys: ["←"],
    },
    {
      name: "Increase Playhead 5 Sec",
      keys: ["Shift", "→"],
    },
    {
      name: "Decrease Playhead 5 Sec",
      keys: ["Shift", "←"],
    },
  ],
  Menubar: [
    {
      name: "Export Project",
      keys: ["Cmd", "E"],
    },
    {
      name: "Undo",
      keys: ["Cmd", "Z"],
    },
    {
      name: "Rndo",
      keys: ["Cmd", "Y"],
    },
  ],
  Text: [
    {
      name: "Align Right",
      keys: ["Cmd", "Shift", "R"],
    },
    {
      name: "Align Left",
      keys: ["Cmd", "Shift", "L"],
    },
    {
      name: "Align Center",
      keys: ["Cmd", "Shift", "E"],
    },
    {
      name: "To Uppercase",
      keys: ["Cmd", "Shift", "U"],
    },
    {
      name: "To Lowercase",
      keys: ["Cmd", "Shift", "S"],
    },
    {
      name: "To Capitalize",
      keys: ["Cmd", "Shift", "C"],
    },
    {
      name: "To Removecase",
      keys: ["Cmd", "'"],
    },
    {
      name: "Set Bold",
      keys: ["Cmd", "B"],
    },
    {
      name: "Set Italic",
      keys: ["Cmd", "|"],
    },
    {
      name: "Increase Font Size",
      keys: ["Cmd", "Shift", ">"],
    },
    {
      name: "Decrease Font Size",
      keys: ["Cmd", "Shift", "<"],
    },
    {
      name: "Change Font Family",
      keys: ["Cmd", "Shift", "F"],
    },
  ],
  Player: [
    {
      name: "Play/Pause",
      keys: ["Space"],
    },
    {
      name: "PlayAll/Pause",
      keys: ["Shift", "Space"],
    },
  ],
  Workspace: [
    {
      name: "Move Object Down 10px",
      keys: ["↓"],
    },
    {
      name: "Move Object Top 10px",
      keys: ["↑"],
    },
    {
      name: "Move Object Left 10px",
      keys: ["←"],
    },
    {
      name: "Move Object Right 10px",
      keys: ["→"],
    },
    {
      name: "Move Object Down 1px",
      keys: ["Shift", "↓"],
    },
    {
      name: "Move Object Top 1px",
      keys: ["Shift", "↑"],
    },
    {
      name: "Move Object Left 1px",
      keys: ["Shift", "←"],
    },
    {
      name: "Move Object Right 1px",
      keys: ["Shift", "→"],
    },
    {
      name: "Select All Objects",
      keys: ["Cmd", "A"],
    },
    {
      name: "Deselect All Objects",
      keys: ["Cmd", "Shift", "A"],
    },
    {
      name: "Select Multiple Objects",
      keys: ["Cmd", "Click"],
    },
    {
      name: "Copy Item",
      keys: ["Cmd", "C"],
    },
    {
      name: "Cut Item",
      keys: ["Cmd", "X"],
    },
    {
      name: "Paste Item",
      keys: ["Cmd", "V"],
    },
    {
      name: "Delete Item",
      keys: ["DeleteBackspace"],
    },
    {
      name: "Zoom In",
      keys: ["Cmd", "Opt", "+"],
    },
    {
      name: "Zoom Out",
      keys: ["Cmd", "Opt", "-"],
    },
    {
      name: "Bring Front",
      keys: ["Cmd", "Shift", "↑"],
    },
    {
      name: "Move Last",
      keys: ["Cmd", "Shift", "↓"],
    },
    {
      name: "Bring First",
      keys: ["Cmd", "↑"],
    },
    {
      name: "Move Back",
      keys: ["Cmd", "↓"],
    },
    {
      name: "Flip Item",
      keys: ["Opt", "F"],
    },
    {
      name: "Group Item",
      keys: ["Cmd", "Opt", "G"],
    },
    {
      name: "Ungroup Item",
      keys: ["Cmd", "Opt", "Shift", "G"],
    },
  ],
  Library: [
    {
      name: "Open Prebuild",
      keys: ["Shift", "1"],
    },
    {
      name: "Open Character",
      keys: ["Shift", "2"],
    },
    {
      name: "Open Properties",
      keys: ["Shift", "3"],
    },
    {
      name: "Open Text",
      keys: ["Shift", "4"],
    },
    {
      name: "Open Background",
      keys: ["Shift", "5"],
    },
    {
      name: "Open Image",
      keys: ["Shift", "6"],
    },
    {
      name: "Open Video",
      keys: ["Shift", "7"],
    },
    {
      name: "Open Bgm",
      keys: ["Shift", "8"],
    },
    {
      name: "Open Screen Effects",
      keys: ["Shift", "9"],
    },
    {
      name: "Open Uploads",
      keys: ["Shift", "U"],
    },
  ],
};
export const VIDEO_RESIZE_DETAILS = (theme) => {
  return {
    horizontal: {
      projectWidth: 800,
      projectHeight: 450,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
            <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
            <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
              <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
            </g>
            <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
              <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
            </g>
          </g>
        </svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
          <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
          <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>`,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
          <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78745" data-name="Group 
        78745" transform="translate(98 361)">
          <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg> 
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78735" data-name="Group 78735" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78734" data-name="Group 78734" transform="translate(98 361)">
        <path id="Path_85678" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#e6f8ff" fill-rule="evenodd"/>
      </g>
      <g id="Group_78745" data-name="Group 78745" transform="translate(98 361)">
        <path id="Path_85678-2" data-name="Path 85678" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4v6A2.5,2.5,0,0,1,16,12.5H4A2.5,2.5,0,0,1,1.5,10V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: LANDSCAPE,
      name: "Landscape (16.9)",
    },
    vertical: {
      projectWidth: 450,
      projectHeight: 800,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
          <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
          <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
        <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
        <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
          <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
          <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78737" data-name="Group 78737" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78741" data-name="Group 78741" transform="translate(101 358)">
        <path id="Path_85685" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78744" data-name="Group 78744" transform="translate(101 358)">
        <path id="Path_85685-2" data-name="Path 85685" d="M0,4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5h6A2.5,2.5,0,0,1,12.5,4V16A2.5,2.5,0,0,1,10,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: POETRAIT,
      name: "Portrait (9:16)",
    },
    square: {
      projectWidth: 450,
      projectHeight: 450,
      src:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
          <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
          <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
        <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
        <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#313445" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      selectedSrc:
        theme === "light"
          ? `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
        <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
        <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
          <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
        </g>
        <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
          <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#fdc100" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
    `
          : `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_78739" data-name="Group 78739" transform="translate(-96 -356)">
      <rect id="Rectangle_189815" data-name="Rectangle 189815" width="24" height="24" transform="translate(96 356)" fill="#fff" opacity="0"/>
      <g id="Group_78742" data-name="Group 78742" transform="translate(98 358)">
        <path id="Path_85690" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4Z" fill="#eaebf0" fill-rule="evenodd"/>
      </g>
      <g id="Group_78743" data-name="Group 78743" transform="translate(98 358)">
        <path id="Path_85690-2" data-name="Path 85690" d="M0,4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4ZM4,1.5H16A2.5,2.5,0,0,1,18.5,4V16A2.5,2.5,0,0,1,16,18.5H4A2.5,2.5,0,0,1,1.5,16V4A2.5,2.5,0,0,1,4,1.5Z" fill="#00baff" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
  `,
      title: SQUARE,
      name: "Square (1:1)",
    },
  };
};

export const VALID_IMAGE_TYPES = ["UPIMAGE", "STOCKIMG", "PIXABAY", "PEXELS"];

export const ANIMO_RENDER_TYPES = ["PROP", "SHAPE", "TEXT"];

export const DEFAULT_FILTER_CODE = "6464646464640032";

export const VIDEO_TYPES = ["STOCKVID", "UPVIDEO", "VIDEO"];

export const PROJECT_CONTAINERS = ["workspaceItems", "audios"];

export const isImageSubtypeOnly = (subType) => {
  return (
    subType === "IMAGE" ||
    subType === "ISESS" ||
    subType === "ISSIG" ||
    subType === "GETTY" ||
    subType === "RECORD"
  );
};

export const colors = {
  highlightText: "#54536E",
  Green: "#2AC79A",
  white: "#FFFFFF",
  OrangeDark: "#FF7F00",
};

export const teamBgColor = {
  [PLAN.LITE]: "#ebecf0",
  [PLAN.STARTER]: "rgb(255, 195, 0)",
  [PLAN.TIER1]: "rgb(89, 211, 255)",
  [PLAN.TIER2]: "rgb(89, 211, 255)",
  [PLAN.TIER3]: "rgb(89, 211, 255)",
  [PLAN.TIER4]: "rgb(89, 211, 255)",
  [PLAN.TIER5]: "rgb(89, 211, 255)",
  [PLAN.TEAM]: "rgb(89, 211, 255)",
  [PLAN.ENTERPRISE]: "#C790FA",
};

export const teamTextColor = {
  [PLAN.LITE]: "#000",
  [PLAN.STARTER]: "#fff",
  [PLAN.TIER1]: "#fff",
  [PLAN.TIER2]: "#fff",
  [PLAN.TIER3]: "#fff",
  [PLAN.TIER4]: "#fff",
  [PLAN.TIER5]: "#fff",
  [PLAN.TEAM]: "#fff",
  [PLAN.ENTERPRISE]: "#fff",
};

export const iconPlan = {
  [PLAN.LITE]: "",
  [PLAN.STARTER]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER1]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER2]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER3]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER4]: `${STATIC_PATH}white-star.png`,
  [PLAN.TIER5]: `${STATIC_PATH}white-star.png`,
  [PLAN.TEAM]: `${STATIC_PATH}white-star.png`,
  [PLAN.ENTERPRISE]: `${STATIC_PATH}white-star.png`,
};

export const plans = {
  [PLAN.STARTER]: "#FFC300",
  [PLAN.ENTERPRISE]: "#D4AFFF",
  [PLAN.LITE]: "#D8D8D8",
  [PLAN.TEAM]: "#5AD4FF",
  [PLAN.TIER1]: "#5AD4FF",
  [PLAN.TIER2]: "#5AD4FF",
  [PLAN.TIER3]: "#5AD4FF",
  [PLAN.TIER4]: "#5AD4FF",
  [PLAN.TIER5]: "#5AD4FF",
};

export const STARTER_PLAN = [
  "S_PLAN_1",
  "S_PLAN_2",
  "S_PLAN_3",
  "S_PLAN_4",
  "S_PLAN_5",
];

export const TEAM_PLAN = [
  "T_PLAN_1",
  "T_PLAN_2",
  "T_PLAN_3",
  "T_PLAN_4",
  "T_PLAN_5",
];

const DOWNLOAD_SUBMENU = [
  { name: ".SRT format", actionName: "SRT" },
  { name: ".VTT format", actionName: "VTT" },
  { name: ".SBV format", actionName: "SBV" },
  { name: ".ASS format", actionName: "ASS" },
];

export const SUBTITLE_MENU_OPTIONS = [
  {
    icon: "sm-vm-download.svg",
    name: "Download",
    actionName: "DOWNLOAD",
    subOptions: DOWNLOAD_SUBMENU,
  },
  { icon: "sm-vm-delete.svg", name: "Delete", actionName: "DELETE" },
];

export const HEADER_MENU = [
  { icon: "vm-upload.svg", name: "Upload Subtitle", actionName: "UPLOAD" },
  {
    icon: "sm-vm-download.svg",
    name: "Download",
    actionName: "DOWNLOAD",
    subOptions: DOWNLOAD_SUBMENU,
  },
  {
    icon: "sm-vm-delete.svg",
    name: "Clear subtitle",
    actionName: "CLEAR_ALL",
  },
];

export const PLAN_CONTENT = {
  [PLAN.STARTER]: {
    default: [
      "Everything in Lite plus",
      "Remove green screen",
      "5 GB upload storage limit",
      "Auto Subtitles 60 mins/month",
      "Export in 1080p resolution",
    ],
    durationExceed: [
      "Everything in Lite plus",
      "30 min video export length ",
      "Green screen removal",
      "5 GB upload storage limit",
      "Auto Subtitles 45 mins/month",
      "10 video exports per month",
      "Export in 1080p resolution",
    ],
    resolutionExceed: [
      "Everything in Lite plus",
      "Export in 1080p resolution",
      "30 min video export length ",
      "Green screen removal",
      "5 GB upload storage limit",
      "Auto Subtitles 45 mins/month",
      "10 video exports per month",
    ]
  },
  [PLAN.TEAM]: {
    default: [
      "Everything in Starter plus",
      "Remove video background (15 mins/month)",
      "30 GB upload storage limit",
      "Auto Subtitles 120 mins/month",
      "Export in 2k resolution",
      "30 Video exports per month",
    ],
    durationExceed: [
      "Everything in Starter plus",
      "45 min video export length",
      "Video background Removal 15 mins/month",
      "30 GB upload storage limit",
      "Auto Subtitle 90 min/month",
      "30 video exports per month",
      "Export in 2k resolution"
    ],
    resolutionExceed: [
      "Everything in Starter plus",
      "Export in 2k resolution",
      "45 min video export length",
      "Video background Removal 15 mins/month",
      "30 GB upload storage limit",
      "Auto Subtitle 90 min/month",
      "30 video exports per month",
    ]
  },
  [PLAN.ENTERPRISE]: {
    default: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ],
    durationExceed: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ],
    resolutionExceed: [
      "Everything in Teams plus",
      "Custom credits for video background removal",
      "50+ GB upload storage limit",
      "Custom credits for auto-subtitle and translation",
      "Priority support",
      "Unlimited video exports"
    ]
  }
};

export const PLAN_TYPES = [
  {
    icon: "starter-icon.svg",
    name: "Starter",
    plan: PLAN.STARTER,
    action: "Upgrade to Starter",
    actionBgColor: "#00BAFF",
  },
  {
    icon: "teams-icon.svg",
    name: "Teams",
    plan: PLAN.TEAM,
    action: "Upgrade to Teams",
    actionBgColor: "#00BAFF",
  },
  {
    icon: "enterprise-icon.svg",
    name: "Enterprise Plan",
    plan: PLAN.ENTERPRISE,
    action: "Contact Us",
    description:
      "Go beyond the standard with larger pool of credits and custom features.",
    actionBgColor: "linear-gradient(180deg, #A253FF 0%, #7353FF 100%)",
  },
];

export const APP_INTEGRATION = [
  {
    name: PANEL.PEXELS,
    title: content.PEXELS,
    src: "vm-pexels.svg",
    content: "Import pictures from Pexels",
  },
  {
    name: PANEL.PIXABAY,
    src: "vm-pixabay.svg",
    content: "Import pictures from Pixabay",
    title: content.PIXABAY,
  },
  {
    name: PANEL.PEXELS_VIDEO,
    src: "vm-pexels.svg",
    content: "Import videos from Pexels",
    title: content.PEXELS_VIDEO,
  },
  {
    name: PANEL.PIXABAY_VIDEO,
    src: "vm-pixabay.svg",
    content: "Import videos from Pixabay",
    title: content.PIXABAY_VIDEO,
  },
  {
    name: PANEL.UNSPLASH,
    src: "vm-unsplash.svg",
    content: "Import pictures from Unsplash",
    title: content.UNSPLASH,
  },
  {
    name: PANEL.GIPHY,
    src: "giphy.svg",
    content: "Add gifs from giphy to designs",
    title: content.GIPHY,
  },
  {
    name: PANEL.FAVOURITE,
    src: `left-menu-fav.svg`,
    title: content.FAVORITES,
    content: "Selected favourites display in one place"
  }
];

export const FAVOURITES_LIMIT = {
  PROPERTY: 8,
  MUSIC: 4,
  IMAGE: 4,
  VIDEO: 4
};

export const ONBOARDING_CONTENT = {
  ANYOTHER_PLEASE_SPECIFY: "Any other (please specify)",
  PERSONAL: "Personal use",
  PROFESSIONAL: "Professional use"
};

export const PLAN_PRISING_RES = {
  "starter": {
    "monthly": 24,
    "yearly": 216
  },
  "team": {
    "monthly": 39,
    "yearly": 300
  }
};