/* eslint-disable no-restricted-syntax, prefer-template, no-restricted-globals, operator-assignment, one-var */

import { isImageOnly, isVideoOnly } from "../containers/timeline/timeline-helper";
import { getObjectsBounds } from "./TransformManagerHelper";
import { removeInvalidProjectKeys } from "./projectValidation";
import { SUBTITLE_STYLE_OPTION, putUpdatedSubTitle } from "./subtitleHelper";

export default function getResizedProject(params) {
  let projectWidth = 800,
    projectHeight = 450,
    scale,
    setCenterX = 0,
    setCenterY = 0;
  const newType = params.type;
  let project = params.data;
  let getAllItems, getAllChildren;
  const oldWidth = project.get("width");
  const oldHeight = project.get("height");
  const isBasic =
    (oldWidth === 800 || oldWidth === 450) &&
    (oldHeight === 800 || oldHeight === 450);
  let oldType = "vertical";
  if (oldWidth > oldHeight) {
    oldType = "horizontal";
  } else if (oldWidth === oldHeight) {
    oldType = "square";
  }

  if (isBasic) {
    if (newType === "horizontal") {
      projectWidth = 800;
      projectHeight = 450;
    } else if (newType === "vertical") {
      projectWidth = 450;
      projectHeight = 800;
    } else if (newType === "square") {
      projectWidth = 450;
      projectHeight = 450;
    } else {
      projectWidth = oldWidth;
      projectHeight = oldHeight;
    }
  } else if (newType === "horizontal") {
    if (oldType === "vertical") {
      projectWidth = oldHeight;
      projectHeight = oldWidth;
    } else if (oldType === "square") {
      projectHeight = Math.min(oldWidth, oldHeight);
      projectWidth = projectHeight * 2;
    }
  } else if (newType === "vertical") {
    if (oldType === "horizontal") {
      projectWidth = oldHeight;
      projectHeight = oldWidth;
    } else if (oldType === "square") {
      projectWidth = Math.min(oldWidth, oldHeight);
      projectHeight = projectWidth * 2;
    }
  } else if (newType === "square") {
    projectWidth = Math.min(oldWidth, oldHeight);
    projectHeight = projectWidth;
  } else {
    projectWidth = oldWidth;
    projectHeight = oldHeight;
  }

  const widthScale = projectWidth / oldWidth;
  const heightScale = projectHeight / oldHeight;

  const xScale = false;
  const yScale = false;

  const sceneBounds = getObjectsBounds(project.get("workspaceItems"), true);
  const isReverted = false;

  if (oldType === "vertical") {
    scale = widthScale;
    if (
      ((sceneBounds.width >= oldWidth &&
        sceneBounds.width * scale >= projectWidth) ||
        (sceneBounds.height <= oldHeight &&
          sceneBounds.height * scale >= projectHeight)) &&
      !isReverted
    ) {
      scale = heightScale;
      if (sceneBounds.width * scale <= projectWidth || newType === "square") {
        setCenterX = projectWidth / 2 - (sceneBounds.width * scale) / 2;
        setCenterX =
          setCenterX > sceneBounds.x * scale
            ? setCenterX - sceneBounds.x * scale
            : -(sceneBounds.x * scale - setCenterX);
      }
    }

    setCenterY = projectHeight / 2 - (sceneBounds.height * scale) / 2;
    setCenterY =
      setCenterY > sceneBounds.y * scale
        ? setCenterY - sceneBounds.y * scale
        : -(sceneBounds.y * scale - setCenterY);
  } else if (oldType === "horizontal") {
    scale = widthScale;
    setCenterY = projectHeight / 2 - (sceneBounds.height * scale) / 2;
    setCenterY =
      setCenterY > sceneBounds.y * scale
        ? setCenterY - sceneBounds.y * scale
        : -(sceneBounds.y * scale - setCenterY);
  } else if (oldType === "square") {
    scale = widthScale;
    if (newType === "horizontal") {
      setCenterY = projectHeight / 2 - (sceneBounds.height * scale) / 2;
      setCenterY =
        setCenterY > sceneBounds.y * scale
          ? setCenterY - sceneBounds.y * scale
          : -(sceneBounds.y * scale - setCenterY);
    } else if (newType === "vertical") {
      if (isReverted) {
        scale = widthScale;
      }
      setCenterY = projectHeight / 2 - (sceneBounds.height * scale) / 2;
      setCenterY =
        setCenterY > sceneBounds.y * scale
          ? setCenterY - sceneBounds.y * scale
          : -(sceneBounds.y * scale - setCenterY);
    }
  }

  const newBoundWidth = sceneBounds.width * scale;
  const newBoundHeight = sceneBounds.height * scale;

  if (xScale) {
    if (projectWidth > newBoundWidth) {
      setCenterX = projectWidth / 2 - newBoundWidth / 2;
      setCenterX =
        setCenterX > sceneBounds.x * scale
          ? setCenterX - sceneBounds.x * scale
          : -(sceneBounds.x * scale - setCenterX);
    } else {
      setCenterX = -(sceneBounds.x * scale);
    }
  }

  if (yScale) {
    if (projectHeight > newBoundHeight) {
      setCenterY = projectHeight / 2 - newBoundHeight / 2;
      setCenterY =
        setCenterY > sceneBounds.y * scale
          ? setCenterY - sceneBounds.y * scale
          : -(sceneBounds.y * scale - setCenterY);
    } else {
      setCenterY = -(sceneBounds.y * scale);
    }
  }

  getAllItems = project.get("workspaceItems");
  getAllChildren = project.get("workspaceChildren");

  // if (project.get("workspaceBG") !== undefined) {
  //   let bgWidth = project.getIn(["scenes", sceneNum, "workspaceBG", "width"]) * oldWidth;
  //   let bgHeight = project.getIn(["scenes", sceneNum, "workspaceBG", "height"]) * oldHeight;
  //   let bgWidthScale = 1;
  //   let bgHeightScale = 1;
  //   const oldBgWidth = bgWidth, oldBgHeight = bgHeight;
  //   if (projectWidth >= projectHeight) {
  //     bgWidth = projectWidth;
  //     bgHeight = oldBgHeight * (bgWidth / oldBgWidth);
  //     if (bgHeight <= projectHeight) {
  //       bgHeight = projectHeight;
  //       bgWidth = bgWidth * (bgHeight / (oldBgHeight * (bgWidth / oldBgWidth)));
  //     }
  //   }
  //   else {
  //     bgHeight = projectHeight;
  //     bgWidth = oldBgWidth * (bgHeight / oldBgHeight);
  //     if (bgWidth <= projectWidth) {
  //       bgWidth = projectWidth;
  //       bgHeight = bgHeight * (bgWidth / (oldBgWidth * (bgHeight / oldBgHeight)));
  //     }
  //   }

  //   bgWidthScale = bgWidth / projectWidth;
  //   bgHeightScale = bgHeight / projectHeight;

  //   let dW = projectWidth - bgWidth, dH = projectHeight - bgHeight;
  //   if (isNaN(dW))
  //     dW = 0

  //   if (isNaN(dH))
  //     dH = 0

  //   const x = (dW / 2) / projectWidth, y = (dH / 2) / projectHeight, width = bgWidthScale, height = bgHeightScale;
  //   project = project.setIn(["scenes", sceneNum, "workspaceBG", "x"], x);
  //   project = project.setIn(["scenes", sceneNum, "workspaceBG", "y"], y);
  //   project = project.setIn(["scenes", sceneNum, "workspaceBG", "width"], width);
  //   project = project.setIn(["scenes", sceneNum, "workspaceBG", "height"], height);
  // }

  /* ************** Set ratio *************** */
  project
    .get("workspaceItems")
    .entrySeq()
    .forEach(([key, value]) => {
      if (value.get("type") !== "SCR") {
        getAllItems = getAllItems
          .setIn([key, "x"], value.get("x") * scale + setCenterX)
          .setIn([key, "y"], value.get("y") * scale + setCenterY)
          .setIn([key, "width"], value.get("width") * scale)
          .setIn([key, "height"], value.get("height") * scale);

        const updatedValue = getAllItems.get(key);
        const workspaceBoundsOrg = { w: oldWidth, h: oldHeight };
        const workspaceBounds = { w: projectWidth, h: projectHeight };
        const boundsOrg = {
          x: value.get("x"),
          y: value.get("y"),
          w: value.get("width"),
          h: value.get("height"),
        };
        const bounds = {
          x: updatedValue.get("x"),
          y: updatedValue.get("y"),
          w: updatedValue.get("width"),
          h: updatedValue.get("height"),
        };

        const itemCenterOrg = {
          x: boundsOrg.x + boundsOrg.w / 2,
          y: boundsOrg.y + boundsOrg.h / 2,
        };
        const centerRatioOrg = {
          x: itemCenterOrg.x / workspaceBoundsOrg.w,
          y: itemCenterOrg.y / workspaceBoundsOrg.h,
        };

        const anchor = { x: "c", y: "c" };
        // if (centerRatioOrg.x < 0.5) {
        //   anchor.x = "l";
        // } else if (centerRatioOrg.x > 0.5) {
        //   anchor.x = "r";
        // }
        // if (centerRatioOrg.y < 0.5) {
        //   anchor.y = "t";
        // } else if (centerRatioOrg.y > 0.5) {
        //   anchor.y = "b";
        // }

        let { x, y } = bounds;

        if (anchor.x === "c") {
          const ncx = workspaceBounds.w * centerRatioOrg.x;
          x = ncx - bounds.w / 2;
        } else if (anchor.x === "l") {
          x = workspaceBounds.w * (boundsOrg.x / workspaceBoundsOrg.w);
        } else if (anchor.x === "r") {
          const x2r = (boundsOrg.x + boundsOrg.w) / workspaceBoundsOrg.w;
          const x2 = workspaceBounds.w * x2r;
          x = x2 - bounds.w;
        }

        if (anchor.y === "c") {
          const ncy = workspaceBounds.h * centerRatioOrg.y;
          y = ncy - bounds.h / 2;
        } else if (anchor.y === "t") {
          y = workspaceBounds.h * (boundsOrg.y / workspaceBoundsOrg.h);
        } else if (anchor.y === "b") {
          const y2r = (boundsOrg.y + boundsOrg.h) / workspaceBoundsOrg.h;
          const y2 = workspaceBounds.h * y2r;
          y = y2 - bounds.h;
        }

        getAllItems = getAllItems.setIn([key, "x"], x).setIn([key, "y"], y);

        if (isVideoOnly(value.get("type"), value.get("subType")) || isImageOnly(value.get("type"), value.get("subType"))) {
          const isBG =
            // item's hor edges are close to workspace
            workspaceBoundsOrg.w - (boundsOrg.x + boundsOrg.w) < 10 &&
            boundsOrg.x < 10 &&
            // item's vertical edges are close to workspace
            workspaceBoundsOrg.h - (boundsOrg.y + boundsOrg.h) < 10 &&
            boundsOrg.y < 10;

          if (isBG) {
            // first try to fit width
            let scaledWidth = workspaceBounds.w;
            let scaledHeight = (bounds.h / bounds.w) * scaledWidth;

            // if there is gap, fit height
            if (scaledHeight < workspaceBounds.h) {
              scaledHeight = workspaceBounds.h;
              scaledWidth = (bounds.w / bounds.h) * scaledHeight;
            }

            const scaledCenter = { x: scaledWidth / 2, y: scaledHeight / 2 };
            const workspaceCenter = {
              x: workspaceBounds.w / 2,
              y: workspaceBounds.h / 2,
            };

            const x = workspaceCenter.x - scaledCenter.x;
            const y = workspaceCenter.y - scaledCenter.y;

            getAllItems = getAllItems
              .setIn([key, "x"], x)
              .setIn([key, "y"], y)
              .setIn([key, "width"], scaledWidth)
              .setIn([key, "height"], scaledHeight);
          }
        } else if (
          value.get("type") === "TEXT" ||
          value.get("type") === "SHAPE"
        ) {
          const fontSize = getAllItems.getIn([
            key,
            "textData",
            "formats",
            "containerStyle",
            "fontSize",
          ]);
          if (fontSize !== undefined) {
            getAllItems = getAllItems.setIn(
              [key, "textData", "formats", "containerStyle", "fontSize"],
              (parseFloat(fontSize, 10) * scale).toFixed(2) + "px"
            );
          }
        } else if (value.get("type") === "GROUP") {
          value
            .get("groupChildren")
            .entrySeq()
            .forEach(([, cValue]) => {
              getAllChildren = getAllChildren
                .setIn(
                  [cValue, "width"],
                  getAllChildren.getIn([cValue, "width"]) * scale
                )
                .setIn(
                  [cValue, "height"],
                  getAllChildren.getIn([cValue, "height"]) * scale
                )
                .setIn(
                  [cValue, "x"],
                  getAllChildren.getIn([cValue, "x"]) * scale + setCenterX
                )
                .setIn(
                  [cValue, "y"],
                  getAllChildren.getIn([cValue, "y"]) * scale + setCenterY
                );

              if (
                getAllChildren.getIn([cValue, "type"]) === "TEXT" ||
                getAllChildren.getIn([cValue, "type"]) === "SHAPE"
              ) {
                const fontSize = getAllChildren.getIn([
                  cValue,
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ]);
                if (fontSize !== undefined) {
                  getAllChildren = getAllChildren.setIn(
                    [
                      cValue,
                      "textData",
                      "formats",
                      "containerStyle",
                      "fontSize",
                    ],
                    (parseFloat(fontSize, 10) * scale).toFixed(2) + "px"
                  );
                }
              }
            });
        }
      } else if (getAllItems.getIn([key, "sizes"]) !== undefined) {
        const shortTypes = {
          horizontal: "hor",
          vertical: "ver",
          square: "sqr",
        };

        if (getAllItems.getIn([key, "sizes"]).includes(shortTypes[newType])) {
          getAllItems = getAllItems
            .setIn([key, "width"], projectWidth)
            .setIn([key, "height"], projectHeight);
        } else {
          getAllItems = getAllItems.delete(key);
        }
      } else {
        getAllItems = getAllItems.delete(key);
      }
    });

  project = project.set("workspaceItems", getAllItems);
  project = project.set("workspaceChildren", getAllChildren);

  project = project.set("width", projectWidth).set("height", projectHeight);

  let subtitle = null;
  if (project.get("defaultSubtitle") && project.get("subtitle")) {
    const workspaceBoundsOrg = { w: oldWidth, h: oldHeight };
    const workspaceBounds = { w: projectWidth, h: projectHeight };
    const subtitleOrg = project.get("subtitle");
    const fontSize = `${
      parseFloat(project.getIn(["subtitle", "textStyles", "fontSize"])) * scale
    }px`;
    const globalTextStyles = project.getIn(["subtitle", "textStyles"]);
    const globalTextEffects = project.getIn(["subtitle", "textEffects"]);
    subtitle = subtitleOrg.setIn(["textStyles", "fontSize"], fontSize);

    for (const [dropId, subtitles] of subtitleOrg.get("data")) {
      for (const [subtitleId, subtitleItem] of subtitles.entrySeq()) {
        const boundsOrg = {
          x: subtitleItem.get("x"),
          y: subtitleItem.get("y"),
          w: subtitleItem.get("width"),
          h: subtitleItem.get("height"),
        };
        const itemCenterOrg = {
          x: boundsOrg.x + boundsOrg.w / 2,
          y: boundsOrg.y + boundsOrg.h / 2,
        };
        const centerRatioOrg = {
          x: itemCenterOrg.x / workspaceBoundsOrg.w,
          y: itemCenterOrg.y / workspaceBoundsOrg.h,
        };

        const plot = putUpdatedSubTitle(
          subtitleItem,
          SUBTITLE_STYLE_OPTION.FONT_SIZE.KEY,
          fontSize,
          1,
          projectWidth,
          globalTextStyles,
          globalTextEffects
        );

        const ncx = workspaceBounds.w * centerRatioOrg.x;
        plot.x = ncx - plot.width / 2;
        const ncy = workspaceBounds.h * centerRatioOrg.y;
        plot.y = ncy - plot.height / 2;

        if (plot.x < 0) {
          plot.x = 0;
        }
        if (plot.x + plot.width > projectWidth) {
          plot.x -= plot.x + plot.width - projectWidth;
        }
        if (plot.y < 0) {
          plot.y = 0;
        }
        if (plot.y + plot.height > projectHeight) {
          plot.y -= plot.y + plot.height - projectHeight;
        }

        const newSubtitleItem = subtitleItem
          .set("x", plot.x)
          .set("y", plot.y)
          .set("width", plot.width)
          .set("height", plot.height);

        subtitle = subtitle.setIn(
          ["data", dropId, subtitleId],
          newSubtitleItem
        );
      }
    }

    project = project.set("subtitle", subtitle);
  }

  const result = removeInvalidProjectKeys(project).toJS();
  if (project.get("subtitle")) {
    result.subtitle = project.get("subtitle").toJS(); 
  }
  return result;
}
