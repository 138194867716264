import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector, connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import ContentValue from "../../constants/lang/en.json";
import vmTheme from "../../constants/theme";
import { getCredits, getUserDetails, setProjectDetails } from "../../redux/actions/projectUtils";
import ShortsWrapper from "./shorts-wrapper";
import { Wrapper } from "./shorts-components";
import Header from "../header/header";
import ShortsToolbar from "./shorts-toolbar";
import Loader from "../../common-components/Loader";
import openUrl from "../../helper/openUrl";
import withNotify from "../../helper/hoc/withNotify";
import Toast from "../../common-components/Toast";
import proceedWait from "../../helper/proceedWait";
import { ENVIRONMENT } from "../../constants/config";
import { getPresetItems, setShortsPlayer } from "../../redux/actions/appUtils";

const Shorts = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));
  const projectDetails = useSelector((state) => state.projectDetails);
  const userDetails = useSelector((state) => state.userDetails);

  useLayoutEffect(() => {
    const { params = { id: "" } } = props.match;
    getUserDetails().then((response) => {
      props.getPresetItem();
      props.setCredits();
      dispatch(setProjectDetails(params.id, response, false)).then(async (res) => {
        const { permission, project } = res;
        const hasShorts = project.shorts && Object.keys(project.shorts).length !== 0;
        if (permission === -1) {
          props.notify.warn("You don't have access to this project.");
          openUrl(`${window.location.origin}/dashboard/`, true);
        } else if (!hasShorts) {
          props.notify.warn("This project does not have Shorts.");
          await proceedWait(3000);
          openUrl(`${window.location.origin}${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${params.id}`, true);
        }
        dispatch(setShortsPlayer({ isShortsPlayer: true }));
      }).catch(() => {
        openUrl(`${window.location.origin}${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${params.id}`, true);
      });
    }).catch(() => {
      dispatch(setProjectDetails(params.id, {}, false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match]);

  const { params = { id: "" } } = props.match;

  return (
    <IntlProvider locale="en" messages={{ ...ContentValue }}>
      <ThemeProvider theme={vmTheme[theme]}>
        <Header isShorts={true} />
        <ShortsToolbar isShorts={true} />
        <Wrapper>
          {projectDetails.get("shorts").size > 0 ? (
            projectDetails.get("shorts").entrySeq().map(([, data]) => {
              return (
                <ShortsWrapper
                  key={data.get("id")}
                  params={params}
                  projectDetails={projectDetails}
                  userDetails={userDetails}
                  shortData={data}
                />
              );
            })
          ) : (
            <Loader disableProgress={true} />
          )}
          <Toast />
        </Wrapper>
      </ThemeProvider>
    </IntlProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCredits: bindActionCreators(getCredits, dispatch),
    getPresetItem: bindActionCreators(getPresetItems, dispatch)
  };
};

Shorts.propTypes = {
  match: PropTypes.object,
  notify: PropTypes.func,
  setCredits: PropTypes.func,
  getPresetItem: PropTypes.func
};

export default connect(null, mapDispatchToProps)(withNotify(Shorts));
