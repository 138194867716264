import styled, { css } from "styled-components";
import { PlotType } from "./timeline-proptypes";
import { APP_Z_INDICES } from "../../constants";

export const TimelineContainer = styled.div.attrs((props) => {
  return {
    style: {
      left: `${props.$timelinePlot.x}px`,
      top: `${props.$timelinePlot.y}px`,
      width: `${props.$timelinePlot.width}px`,
      height: `${props.$timelinePlot.height}px`,
    },
  };
})`
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.theme.TIMELINE_BACKGROUND};
  user-select: none;
  box-sizing: border-box;
  touch-action: none;
  z-index: ${APP_Z_INDICES.timeline.default};

  &.tc--animate-container {
    transition: all .4s;
  }

  * {
    user-select: none;
    box-sizing: border-box;
    touch-action: none;
  }

  &.tc--block-events,
  &.tc--block-events * {
    pointer-events: none !important;
  }

  --TimelineHeight: ${props => `${props.$timelinePlot.height}px`};

  @media (max-width: 768px) {
    width: 100vw !important;
    left: 0px !important;
    bottom: 80px;
    top: auto !important;
    z-index: 10;

    ${props =>
      props.$timelinePlot.height > 100 &&
      css`
        height: calc(var(--TimelineHeight) - 100px) !important;
      `}
  }
`;
TimelineContainer.propTypes = {
  $timelinePlot: PlotType.isRequired,
};
TimelineContainer.displayName = "TimelineContainer";

export const TimelineResize = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: transparent;
  cursor: row-resize;
  border-bottom: 1px solid ${(props) => props.theme.boxShadowColor};
  @media (max-width: 768px) {
    height: 20px;
  }
  &::after {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 4px;
    height: 5px;
    width: 32px;
    border-radius: 4px;
    border: 1.5px solid ${(props) => props.theme.secondaryColor};
    background-color: ${(props) => props.theme.polarColor};
  }

  &:hover,
  &.tr--is-dragging {
    border-color: ${(props) => props.theme.secondaryBorderColor};
    ::after {
      background: ${(props) => props.theme.secondaryBorderColor};
      border-color: ${(props) => props.theme.secondaryBorderColor};
    }
  }
`;
TimelineResize.displayName = "TimelineResize";
