import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  OverflowProp,
  WorkspaceBackdropContainer,
  WorkspaceBackdropContent,
} from "./workspace-components";
import ProgressLoaderComponent from "./workspace-progress";
import BufferLoader from "./workspace-bufferloader";

const WorkspaceBackdrop = (props) => {
  const { workspaceStagePlot, isMobile } = props;
  const isPlayerLoaded = useSelector((state) => state.app.get("isLoaded") || state.app.get("isAnimLoaded") );
  const prefetchCompleteCount = useSelector((state) =>
    state.app.get("prefetchCompleteCount")
  );
  const prefetchCount = useSelector((state) => state.app.get("prefetchCount"));
  const isBuffering = useSelector(
    (state) => state.app.get("videoBufferStatus").size
  );

  const workspaceContainerStyles = {
    transform: `translate(${workspaceStagePlot.x}px, ${workspaceStagePlot.y}px)`,
    width: `${workspaceStagePlot.width}px`,
    height: `${workspaceStagePlot.height}px`,
  };

  if (isMobile) {
    workspaceContainerStyles.transform = `translate(calc(${workspaceStagePlot.x}px - 35px), calc(${workspaceStagePlot.y}px - 8px))`;
  }

  let progress = null;
  if (!isPlayerLoaded && prefetchCount > 0) {
    progress = (
      <ProgressLoaderComponent
        key={"progress"}
        progressPercent={(prefetchCompleteCount / prefetchCount) * 100}
      />
    );
  }

  let bufferLoader = null;
  if (isPlayerLoaded && isBuffering) {
    bufferLoader = <BufferLoader key="buffer-loader" />;
  }

  let content = null;

  if (progress || bufferLoader) {
    content = (
      <WorkspaceBackdropContent style={workspaceContainerStyles} id="vmaker-editor-prefetch-loader"> 
        {progress}
        {bufferLoader}
      </WorkspaceBackdropContent>
    );
  }

  const overflowProps = [];
  if (content) {
    overflowProps.push(<OverflowProp key="full" className="overflowProp" />);
  } else {
    overflowProps.push(
      <OverflowProp
        key="left"
        className="overflowProp"
        style={{ width: `${workspaceStagePlot.x}px` }}
      />
    );
    overflowProps.push(
      <OverflowProp
        key="top"
        className="overflowProp"
        style={{
          left: `${workspaceStagePlot.x}px`,
          height: `${workspaceStagePlot.y}px`,
        }}
      />
    );
    overflowProps.push(
      <OverflowProp
        key="right"
        className="overflowProp"
        style={{
          left: `${workspaceStagePlot.x + workspaceStagePlot.width}px`,
          top: `${workspaceStagePlot.y}px`,
        }}
      />
    );
    overflowProps.push(
      <OverflowProp
        key="bottom"
        className="overflowProp"
        style={{
          left: `${workspaceStagePlot.x}px`,
          width: `${workspaceStagePlot.width}px`,
          top: `${workspaceStagePlot.y + workspaceStagePlot.height}px`,
        }}
      />
    );
  }

  return (
    <WorkspaceBackdropContainer>
      {overflowProps}
      {content}
    </WorkspaceBackdropContainer>
  );
};

WorkspaceBackdrop.propTypes = {
  workspaceStagePlot: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default WorkspaceBackdrop;
