/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

const TransparentVideo = ({
    assignRef,
    src,
    className,
    style,
    crossOrigin,
    isPlayAll,
    muted,
    onWaiting,
    mediaElRef,
    imageRef,
    preload = "auto",
}) => {
    const canvasRef = useRef(null);
    const selfieSegmentationRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (mediaElRef.current) {
            const selfieSegmentation = new SelfieSegmentation({
                locateFile: (file) =>
                    `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
            });
            selfieSegmentation.setOptions({
                modelSelection: 1,
            });
            selfieSegmentationRef.current = selfieSegmentation;
        }
        return () => {
            cancelAnimationFrame(timeoutRef.current);
            selfieSegmentationRef.current?.close();
        };
    }, [mediaElRef]);

    const frame = () => {
        if (mediaElRef.current) {
            selfieSegmentationRef.current.send({
                image: mediaElRef.current,
            });
        }
    };

    const onBackgroundRemoval = (results) => {
        const canvasCtx = canvasRef.current?.getContext("2d");
        if (canvasCtx && canvasRef.current) {
            canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            canvasCtx.save();
            const { width, height } = canvasRef.current;
            canvasCtx.drawImage(results.image, 0, 0, width, height);
            canvasCtx.globalCompositeOperation = "destination-atop";
            canvasCtx.drawImage(results.segmentationMask, 0, 0, width, height);
            canvasCtx.globalCompositeOperation = "destination-over";
            canvasCtx.restore();
            timeoutRef.current = requestAnimationFrame(frame);
        }
    };

    const seeked = () => {
        if (mediaElRef.current) {
            if (imageRef?.current && mediaElRef.current.paused) {
                imageRef.current.style.visibility = "hidden";
            }
            let i = 0;
            frame();
            selfieSegmentationRef.current.onResults((res) => {
                onBackgroundRemoval(res, !i);
                i = 1;
            });
        }
    };

    useEffect(() => {
        if (selfieSegmentationRef.current) {
            selfieSegmentationRef.current.onResults(onBackgroundRemoval);
        }
        return () => {
            cancelAnimationFrame(timeoutRef.current);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <video
                ref={assignRef}
                key={src}
                playsInline={true}
                className={className}
                style={{ ...style, visibility: "hidden" }}
                preload={preload}
                crossOrigin={crossOrigin || "anonymous"}
                muted={muted}
                onWaiting={isPlayAll ? onWaiting : undefined}
                onSeeked={seeked}
            >
                <source src={src} />
            </video>
            <canvas ref={canvasRef} style={style} className={className} />
        </>
    );
};

TransparentVideo.propTypes = {
    assignRef: PropTypes.func,
    src: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    crossOrigin: PropTypes.string,
    isPlayAll: PropTypes.bool,
    onWaiting: PropTypes.func,
    muted: PropTypes.bool,
    mediaElRef: PropTypes.object,
    imageRef: PropTypes.object,
    preload: PropTypes.string,
};

export default TransparentVideo;
