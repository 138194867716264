import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

export const SearchInput = styled.input.attrs({ type: "text" })`
  max-width: 100%;
  width: -webkit-fill-available;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-radius: 4px;
  padding: 10px;
  font: ${font.normalMini_17};
  &:focus {
    outline: 0px;
    box-shadow: none;
    border-color: ${(props) => props.theme.primaryBorderColor};
  }
  &:hover {
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
  ::placeholder {
    font: ${font.normalBase};
    color: ${(props) => props.theme.inputTextColor};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputBox = ({ placeholder, onChange, className, onFocus, onBlur }) => {
  return (
    <SearchInput
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
    />
  );
};

InputBox.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default InputBox;
