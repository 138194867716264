/* eslint-disable react/sort-comp, operator-assignment, no-restricted-syntax */
/// <reference path="./timeline-types.js" />

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MouseDrag from "./helper-components/timeline-mousedrag";
import AutoScroll from "./helper-components/timeline-autoscroll";
import timelineTileReducer, { TIMELINE_TILE_ACTIONS, getTimelineTileInitialState } from "./timeline-tile-reducer";
import TimelineScrollProvider from "./contexts/timeline-scroll-context";
import { TimelineContainer, TimelineResize } from "./timeline-components";
import { setTimelineHeight, updateTimelineTime } from "../../redux/actions/timelineUtils";
import { setPlayHeadTime, setPropertyPanel, setSelectedItems } from "../../redux/actions/appUtils";
import { PLAYER_CONTROLS_HEIGHT, RULER_OPTIONS, SLIDER_GAP_TYPES, SLIDER_TYPES, TIMELINE_MODES, TRACK_TYPES } from "./timeline-constants";
import { addDefaultSnapPoints, addGapSlider, adjustTimeScale, calculateStepperSize, calculateTimelinePlot, getGapSliderIds, getMagnetSliderIds, getMouseClientPosition, getScrollOptions, getSliders, getTrackPosition, getTracks, getUpdatedTimeline, isImageOnly, isUpImageSVG, isVideoOnly, iterViewportSliders, memoize, movePlayheadToNearestThumb, moveSliders, moveToTrack, removeDefaultSnapPoints, removeSliderGaps, secondsToStep, shouldExtendRuler, splitSliderHelper, splitSubtitle, updateTrackWidth } from "./timeline-helper";
import PlayerControls from "./playercontrols/timeline-playercontrols";
import TimelineInner from "./timeline-inner/timeline-inner";
import Ruler from "./ruler/timeline-ruler";
import TrackList from "./tracks/timeline-tracks";
import Playhead from "./playhead/timeline-playhead";
import WaveformManager from "./timeline-waveformmanager";
import { PANEL } from "../../constants";
import { addSubtitleTimeline } from "../../helper/subtitleHelper";

const indicatorId = SLIDER_TYPES.PLAYHEAD_INDICATOR;
const playheadId = SLIDER_TYPES.PLAYHEAD_THUMB;
const rulerId = SLIDER_TYPES.RULER;
const tracksOffsetY = PLAYER_CONTROLS_HEIGHT + RULER_OPTIONS.height;

class TimelineComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timelineTile: getTimelineTileInitialState({
                playhead: props.playhead,
                project: props.project,
                sideBarMenuWidth: props.sideBarMenuWidth,
                timelineMode: props.timelineMode,
            }),
            timelinePlotBeforeDrag: null,
            animatePlayhead: false,
            selectedSliders: [],
            timelineAutoScrollData: null,
            isSliderMultiSelect: false,
            sliderMoved: false,
            animateTimelineContainer: false,
            isShowPlayControls: true
        }

        this.dispatchTimelineTile = this.dispatchTimelineTile.bind(this);
        this.setSelectedSliders = this.setSelectedSliders.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.resizeTimeline = this.resizeTimeline.bind(this);
        this.stopTimelineResize = this.stopTimelineResize.bind(this);
        this.handleResizeMouseDown = this.handleResizeMouseDown.bind(this);
        this.changeTimeScale = this.changeTimeScale.bind(this);
        this.onTimelineAutoScroll = this.onTimelineAutoScroll.bind(this);
        this.handleSlidersDrag = this.handleSlidersDrag.bind(this);
        this.handleSlidersDragEnd = this.handleSlidersDragEnd.bind(this);
        this.movePlayheadIndication = this.movePlayheadIndication.bind(this);
        this.handleTimelineMouseMove = this.handleTimelineMouseMove.bind(this);
        this.hidePlayheadIndication = this.hidePlayheadIndication.bind(this);
        this.handleTimelineMouseLeave = this.handleTimelineMouseLeave.bind(this);
        this.selectSlider = this.selectSlider.bind(this);
        this.removeGapSlider = this.removeGapSlider.bind(this);
        this.placePlayheadAtTime = this.placePlayheadAtTime.bind(this);
        this.placePlayheadOnClick = this.placePlayheadOnClick.bind(this);
        this.adjustPlayheadStepBy = this.adjustPlayheadStepBy.bind(this);
        this.updateIsSliderMultiSelect = this.updateIsSliderMultiSelect.bind(this);
        this.handleGapRemove = this.handleGapRemove.bind(this);
        this.autoScrollPlayhead = this.autoScrollPlayhead.bind(this);
        this.scrollToFirstSelection = this.scrollToFirstSelection.bind(this);
        this.updateStorePlayhead = this.updateStorePlayhead.bind(this);
        this.onSeekClick = this.onSeekClick.bind(this);
        this.onContainerTransitionEnd = this.onContainerTransitionEnd.bind(this);
        this.rulerScrollBy = this.rulerScrollBy.bind(this);
        this.splitHandler = this.splitHandler.bind(this);
        this.toggleSubtitleMode = this.toggleSubtitleMode.bind(this);
        this.addSubtitle = this.addSubtitle.bind(this);

        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.timelineInnerRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.trackListContainerRef = React.createRef(null);

        this.dragStartThreshold = 10;
        this.sliderMoved = false;
        this.isSliderMultiSelect = false;
        this.preventSliderDeselect = false;
        this.playSeekTimer = null;

        this.checkGapStatus = memoize(({ selectedSliders, sliders }) => {
            return {
                canUseMagnet: getMagnetSliderIds({ selectedSliders, sliders }).canUseMagnet,
                canDeleteGap: getGapSliderIds({ deleteType: "gap-delete-selected", selectedSliders, sliders }).canDeleteGap,
            };
        });
    }

    componentDidMount() {
        this.props.setTimelineHeight(this.state.timelineTile.timelinePlot);
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentDidUpdate(prevProps) {
        const { timelineTile, } = this.state;
        const { sliders, stepper, timelinePlot } = timelineTile;
        let updatedSliders = sliders;

        if (
            this.props.project !== prevProps.project
            || this.props.timelineMode !== prevProps.timelineMode
            || this.props.timelineResetToken !== prevProps.timelineResetToken
        ) {
            const { project, timelineMode, } = this.props;

            let playheadTime = 0;
            if (sliders && sliders[playheadId] && sliders[playheadId].enterStart) {
                const playheadThumb = sliders[playheadId].enterStart;
                playheadTime = playheadThumb.position.step * RULER_OPTIONS.interval;
                if (playheadTime > project.get("duration")) {
                    playheadTime = project.get("duration");
                }
            }
            if (this.props.playhead !== prevProps.playhead) {
                playheadTime = this.props.playhead
            } else if (this.props.selectedSubtitles !== prevProps.selectedSubtitles && this.props.selectedSubtitles.size) {
                // selectedSubtitles and project will change when subtitles are added/deleted
                // if project is changed and some subtitle is selected, then it might be added one
                // so move playhead to first selected subtitle
                const subtitleItem = project.getIn(["localSubtitle", this.props.selectedSubtitles.first()]);
                if (subtitleItem) {
                    // check subtitle-item.jsx file for reason for ceil
                    playheadTime = Math.ceil((subtitleItem.get("enterStart") / RULER_OPTIONS.interval)) * RULER_OPTIONS.interval;
                }
            }
            const newStepper = adjustTimeScale({ newProject: project, stepper, timelinePlot });
            const newTracks = getTracks({ project, stepper: newStepper, timelineMode, });
            const { sliders: newSliders, timelineSnapPoints: newTimelineSnapPoints } = getSliders({
                playhead: playheadTime,
                project,
                stepper: newStepper,
                tracks: newTracks,
                timelineMode,
            });
            updatedSliders = newSliders;

            this.dispatchTimelineTile({
                type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
                payload: {
                    stepper: newStepper,
                    tracks: newTracks,
                    sliders: newSliders,
                    timelineSnapPoints: newTimelineSnapPoints,
                    matchingSnapPoints: {},
                },
            });
        } else if (this.props.playheadResetToken !== prevProps.playheadResetToken) {
            this.placePlayheadAtTime(this.props.playhead);
        }

        if (
            this.props.isPlayAll !== prevProps.isPlayAll
            || this.props.t1 !== prevProps.t1
            || this.props.isPlayerLoaded !== prevProps.isPlayerLoaded
        ) {
            clearInterval(this.playSeekTimer);
            const { isPlayAll, t1, isPlayerLoaded, playhead } = this.props;

            if (isPlayAll && t1 && isPlayerLoaded) {
                this.playSeekTimer = setInterval(() => {
                    // use recent props for interval callback
                    if (this.props.isPlayAll && this.props.isPlayerLoaded) {
                        const curTime = t1.time();
                        this.dispatchTimelineTile({
                            type: TIMELINE_TILE_ACTIONS.SEEK_PLAYHEAD_CHANGED,
                            payload: { time: curTime },
                        }, this.autoScrollPlayhead);
                    }
                }, 1000 / 30);
            } else if (!isPlayAll) {
                this.dispatchTimelineTile({
                    type: TIMELINE_TILE_ACTIONS.SEEK_PLAYHEAD_CHANGED,
                    payload: { isReset: true, playhead },
                });
            }
        }

        if (
            this.props.selectedItems !== prevProps.selectedItems
            || this.props.selectedAudios !== prevProps.selectedAudios
            || this.props.selectedSubtitles !== prevProps.selectedSubtitles
        ) {
            const { selectedSliders } = this.state;
            const newSelectedSliders = selectedSliders.filter(s => {
                // to retain both playhead and gap selection
                const slider = updatedSliders[s.sliderId];
                return (
                    slider
                    && (
                        slider.sliderType === SLIDER_TYPES.GAP
                        || slider.sliderType === SLIDER_TYPES.PLAYHEAD_THUMB
                    )
                );
            });
            const storeSelectionCount = (
                this.props.selectedItems.size
                + this.props.selectedAudios.size
                + this.props.selectedSubtitles.size
            );
            let matchedWithInternal = 0;
            const selectionToHandle = [this.props.selectedItems, this.props.selectedAudios, this.props.selectedSubtitles];

            for (const selectionList of selectionToHandle) {
                for (const sliderId of selectionList.valueSeq()) {
                    const internalHasSelection = selectedSliders.some((selection) => selection.sliderId === sliderId);
                    if (internalHasSelection) {
                        matchedWithInternal = matchedWithInternal + 1;
                    }
                    newSelectedSliders.push({ sliderId });
                }
            }

            if (newSelectedSliders.length !== selectedSliders.length || matchedWithInternal !== storeSelectionCount) {
                let onSelectionUpdate;

                if (this.props.timelineSelectionAutoScrollToken !== prevProps.timelineSelectionAutoScrollToken) {
                    onSelectionUpdate = this.scrollToFirstSelection;
                }

                this.setSelectedSliders({
                    selectedSliders: newSelectedSliders,
                    updateStore: false,
                    setStateCallback: onSelectionUpdate,
                });
            }
        }

        if (this.props.isEnableVersionHistory !== prevProps.isEnableVersionHistory) {
            this.stopTimelineResize({
                clickType: this.props.isEnableVersionHistory ? "minimize" : "expand"
            })
        }

        if (
            (this.props.isEnableVersionHistory !== prevProps.isEnableVersionHistory &&
                this.props.isEnableVersionHistory) ||
            (this.props.swapDetails !== prevProps.swapDetails &&
                this.props.swapDetails !== null &&
                this.state.isShowPlayControls)
        ) {
            this.setState({ isShowPlayControls: false });
        } else if (
            !this.props.isEnableVersionHistory &&
            this.props.swapDetails === null &&
            !this.state.isShowPlayControls
        ) {
            this.setState({ isShowPlayControls: true });
        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
        clearInterval(this.playSeekTimer);
        this.checkGapStatus.clear();
    }

    /**
     * Function to keep playhead state in redux in-sync with internal state
     * Always use this function to update internal state of timeline
     * @param {TimelineTileProxyAction} action
     * @param {Function | undefined} setStateCallback
     */
    dispatchTimelineTile(action, setStateCallback) {
        const { updateStorePlayhead = true } = action;
        const { timelineTile } = this.state;

        let updatedSliders = null;
        if (action.type === TIMELINE_TILE_ACTIONS.SET_SLIDERS) {
            updatedSliders = action.payload;
        } else if (action.type === TIMELINE_TILE_ACTIONS.MERGE_STATE) {
            updatedSliders = action.payload && action.payload.sliders;
        }

        const newTimelineTile = timelineTileReducer(timelineTile, action);
        if (timelineTile !== newTimelineTile || setStateCallback) {
            this.setState({ timelineTile: newTimelineTile }, setStateCallback);
        }

        if (updatedSliders && updateStorePlayhead) {
            this.updateStorePlayhead(updatedSliders);
        }
    }

    /**
     * Function to keep selectedItems and selectedAudios always in-sync with internal state
     * Always use this function to update internal state of selectedSliders
     * @param {object} params
     * @param {SelectedSliders} params.selectedSliders
     * @param {Function | undefined} params.setStateCallback
     * @param {boolean | undefined} params.updateStore if false, function will skip updating redux
     */
    setSelectedSliders({ selectedSliders = [], setStateCallback, updateStore = true } = {}) {
        const { timelineTile } = this.state;
        const { sliders } = timelineTile;

        if (!selectedSliders) {
            selectedSliders = [];
        }
        if (updateStore) {
            const selectedItems = [];
            const selectedAudios = [];
            let selectedSubtitles = [];

            for (const selection of selectedSliders) {
                if (sliders[selection.sliderId]) {
                    const { sliderType, id } = sliders[selection.sliderId];
                    if (sliderType === SLIDER_TYPES.OBJECT) {
                        selectedItems.push(id);
                    } else if (sliderType === SLIDER_TYPES.AUDIO) {
                        selectedAudios.push(id);
                    } else if (sliderType === SLIDER_TYPES.SUBTITLE) {
                        selectedSubtitles.push(id);
                    }
                }
            }

            if (selectedItems.length || selectedAudios.length) {
                selectedSubtitles = [];
            }

            const selection = {
                selectedItems,
                selectedAudios,
                selectedSubtitles,
            };

            if (selectedItems.length === 1 && selectedAudios.length === 0) {
                const selectedItem = this.props.project.getIn(["workspaceItems", selectedItems[0]]);

                if (selectedItem) {
                    selection.type = selectedItem.get("type");
                    selection.subType = selectedItem.get("subType");

                    if (selectedItem.get("type") === "TEXT") {
                        selection.propWindowType = "TEXT";
                    } else if (isImageOnly(selectedItem.get("type"), selectedItem.get("subType")) && !isUpImageSVG(selectedItem)) {
                        selection.propWindowType = "IMAGE_SETTINGS";
                    } else if (isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))) {
                        selection.propWindowType = "VIDEO_SETTINGS";
                    }
                }
            } else if (selectedItems.length === 0 && selectedAudios.length === 1) {
                const selectedAudio = this.props.project.getIn(["audios", selectedAudios[0]]);

                if (selectedAudio) {
                    selection.propWindowType = "AUDIO_SETTINGS";
                }
            }

            this.props.setSelectedItems(selection);
        }
        this.setState({ selectedSliders }, setStateCallback);
    }

    /**
     * @param {object} params
     * @param {"trackPosition" | "position"} params.positionToUse
     * @param {"play" | "nearest-thumb"} params.autoscrollType
     */
    autoScrollPlayhead(params = {}) {
        const { positionToUse = "trackPosition", autoscrollType = "play" } = params;
        const timelineInnerEl = this.timelineInnerRef.current;
        if (!timelineInnerEl) {
            return;
        }

        const { timelineTile } = this.state;
        const { sliders } = timelineTile;
        const playheadSlider = sliders[playheadId];
        const position = playheadSlider.enterStart[positionToUse];

        if (!position || (autoscrollType === "play" && !playheadSlider.isSeeking)) {
            return;
        }

        const { scrollLeft, offsetWidth } = timelineInnerEl;
        const playheadX = position.x;
        if (scrollLeft + offsetWidth < playheadX || playheadX < scrollLeft) {
            let offsetMultiplier = 1;
            if (autoscrollType === "nearest-thumb") {
                offsetMultiplier = 0.5;
            }
            const moveBy = playheadX - (scrollLeft + offsetWidth) + offsetWidth * offsetMultiplier;
            timelineInnerEl.scrollBy({ left: moveBy });
        }
    }

    scrollToFirstSelection() {
        const timelineInnerEl = this.timelineInnerRef.current;
        const trackListEl = this.trackListContainerRef.current;
        if (!timelineInnerEl || !trackListEl) {
            return;
        }

        const { selectedSliders } = this.state;
        const { timelineTile } = this.state;
        const { sliders } = timelineTile;

        if (selectedSliders.length === 0) {
            return;
        }

        const selection = selectedSliders[0];
        const slider = sliders[selection.sliderId];

        if (!slider || !slider.enterStart) {
            return;
        }

        let { position } = slider.enterStart;
        if (slider.enterStart.trackPosition) {
            position = slider.enterStart.trackPosition;
        }

        const { scrollLeft, offsetWidth } = timelineInnerEl;
        const { scrollTop, offsetHeight } = trackListEl;
        const sliderX = position.x;
        const sliderY = position.y;
        const bufferDistance = 30;

        if ((scrollLeft + offsetWidth - bufferDistance) < sliderX || sliderX < (scrollLeft + bufferDistance)) {
            const moveBy = sliderX - (scrollLeft + offsetWidth) + offsetWidth * 0.5;
            timelineInnerEl.scrollBy({ left: moveBy });
        }

        if ((scrollTop + offsetHeight - bufferDistance) < sliderY || sliderY < (scrollTop + bufferDistance)) {
            const moveBy = sliderY - (scrollTop + offsetHeight) + offsetHeight * 0.5;
            trackListEl.scrollBy({ top: moveBy });
        }
    }

    handleWindowResize() {
        const { isPlayAll, sideBarMenuWidth, project, timelineMode, } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders, stepper, timelinePlot } = timelineTile;
        const { timeScale, excessDuration: stepperExcessDuration } = stepper;

        let setStateCallback;
        const playheadPosition = getTrackPosition({ slider: sliders[playheadId], thumbIds: ["enterStart"] }).enterStart;
        const playheadTime = playheadPosition.step * RULER_OPTIONS.interval;
        const newTimelinePlot = calculateTimelinePlot({ currentTimelinePlot: timelinePlot, sideBarMenuWidth });
        const newStepper = calculateStepperSize({
            duration: project.get("duration"),
            timelinePlot: newTimelinePlot,
            timeScale,
            excessDuration: stepperExcessDuration,
        });
        const newTracks = getTracks({ project, stepper: newStepper, timelineMode, });
        const { sliders: newSliders, timelineSnapPoints: newTimelineSnapPoints } = getSliders({
            playhead: playheadTime,
            project,
            stepper: newStepper,
            tracks: newTracks,
            timelineMode,
        });
        const newSelectedSliders = selectedSliders.filter((selection) => newSliders[selection.sliderId]);
        const newPlayheadThumb = newSliders[playheadId].enterStart;

        // try to keep playhead in same position on screen
        const timelineInnerEl = this.timelineInnerRef.current;
        if (timelineInnerEl) {
            const { scrollLeft, offsetWidth } = timelineInnerEl;

            if (newTimelinePlot.width > timelinePlot.width) {
                // ruler width will increase
                let currentPlayheadPositionX = playheadPosition.x - scrollLeft;
                currentPlayheadPositionX = newTimelinePlot.width * (currentPlayheadPositionX / offsetWidth); // keep proportion to visually keep playhead in same place
                const newPlayheadPositionX = newPlayheadThumb.position.x - scrollLeft;
                setStateCallback = () => timelineInnerEl.scrollBy({ left: newPlayheadPositionX - currentPlayheadPositionX });
            } else if (newTimelinePlot.width < timelinePlot.width) {
                // ruler width will decrease
                const newScrollWidth = RULER_OPTIONS.paddingLeft + newStepper.steps * newStepper.stepSizePx + RULER_OPTIONS.paddingRight;
                let newScrollLeft = scrollLeft;
                if (scrollLeft + newTimelinePlot.width >= newScrollWidth) {
                    newScrollLeft = newScrollWidth - newTimelinePlot.width;
                }
                let currentPlayheadPositionX = playheadPosition.x - scrollLeft;
                currentPlayheadPositionX = newTimelinePlot.width * (currentPlayheadPositionX / offsetWidth); // keep proportion to visually keep playhead in same place
                const newPlayheadPositionX = newPlayheadThumb.position.x - newScrollLeft;
                setStateCallback = () => timelineInnerEl.scrollBy({ left: newPlayheadPositionX - currentPlayheadPositionX });
            }
        }

        this.dispatchTimelineTile(
            {
                type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
                payload: {
                    timelinePlot: newTimelinePlot,
                    stepper: newStepper,
                    tracks: newTracks,
                    sliders: newSliders,
                    timelineSnapPoints: newTimelineSnapPoints,
                },
                updateStorePlayhead: !isPlayAll,
            },
            setStateCallback
        );
        this.setSelectedSliders({ selectedSliders: newSelectedSliders });
        this.props.setTimelineHeight(newTimelinePlot);
    }

    /**
     * handle timeline resize on resize thumb drag
     * @param {MouseDragParams} params
     */
    resizeTimeline(params = {}) {
        const { mouseMovedBy } = params;
        const { project, sideBarMenuWidth, timelineMode, } = this.props;
        const { selectedSliders, timelineTile, timelinePlotBeforeDrag, } = this.state;
        const { sliders, stepper } = timelineTile;
        const { timeScale, excessDuration: stepperExcessDuration } = stepper;

        const playheadThumb = sliders[playheadId].enterStart;
        const playheadTime = playheadThumb.position.step * RULER_OPTIONS.interval;
        const newTimelinePlot = calculateTimelinePlot({
            currentTimelinePlot: timelinePlotBeforeDrag,
            mouseMovedBy,
            sideBarMenuWidth,
        });
        const newStepper = calculateStepperSize({
            duration: project.get("duration"),
            timelinePlot: newTimelinePlot,
            timeScale,
            excessDuration: stepperExcessDuration,
        });
        const newTracks = getTracks({ project, stepper: newStepper, timelineMode, });
        const { sliders: newSliders, timelineSnapPoints: newTimelineSnapPoints } = getSliders({
            playhead: playheadTime,
            project,
            stepper: newStepper,
            prevSliders: sliders,
            tracks: newTracks,
            timelineMode,
        });
        const newSelectedSliders = selectedSliders.filter((selection) => newSliders[selection.sliderId]);
        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
            payload: {
                timelinePlot: newTimelinePlot,
                stepper: newStepper,
                tracks: newTracks,
                sliders: newSliders,
                timelineSnapPoints: newTimelineSnapPoints,
            },
        });
        this.props.setTimelineHeight(newTimelinePlot)
        this.setSelectedSliders({ selectedSliders: newSelectedSliders });
    }

    /**
     * @typedef ResizeClickParams
     * @property {"expand" | "minimize" | undefined} clickType
     *
     * handle timeline resize on resize thumb drag end
     * @param {MouseDragEndParams & ResizeClickParams} params
     */
    stopTimelineResize(params = {}) {
        const { clickType, mouseMovedBy } = params;
        const { project, sideBarMenuWidth, timelineMode } = this.props;
        const { selectedSliders, timelineTile, timelinePlotBeforeDrag, } = this.state;
        const { sliders, stepper } = timelineTile;
        const { timeScale, excessDuration: stepperExcessDuration } = stepper;

        const isButtonClick = clickType === "expand" || clickType === "minimize";

        if (isButtonClick) {
            this.setState({ animateTimelineContainer: true });
        }

        const playheadThumb = sliders[playheadId].enterStart;
        const playheadTime = playheadThumb.position.step * RULER_OPTIONS.interval;
        const newTimelinePlot = calculateTimelinePlot({
            currentTimelinePlot: !isButtonClick
                ? timelinePlotBeforeDrag
                : undefined,
            mouseMovedBy: !isButtonClick ? mouseMovedBy : undefined,
            isExpand: clickType === "expand",
            isMinimize: clickType === "minimize",
            sideBarMenuWidth,
        });
        const newStepper = calculateStepperSize({
            duration: project.get("duration"),
            timelinePlot: newTimelinePlot,
            timeScale,
            excessDuration: stepperExcessDuration,
        });
        const newTracks = getTracks({ project, stepper: newStepper, timelineMode, });
        const { sliders: newSliders, timelineSnapPoints: newTimelineSnapPoints } = getSliders({
            playhead: playheadTime,
            project,
            stepper: newStepper,
            prevSliders: sliders,
            tracks: newTracks,
            timelineMode,
        });
        const newSelectedSliders = selectedSliders.filter((selection) => newSliders[selection.sliderId]);
        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
            payload: {
                timelinePlot: newTimelinePlot,
                stepper: newStepper,
                tracks: newTracks,
                sliders: newSliders,
                timelineSnapPoints: newTimelineSnapPoints,
            },
        });
        this.props.setTimelineHeight(newTimelinePlot);
        this.setSelectedSliders({ selectedSliders: newSelectedSliders });
    }

    handleResizeMouseDown(e) {
        const { timelineTile } = this.state;
        const { timelinePlot } = timelineTile;
        this.setState({ timelinePlotBeforeDrag: timelinePlot });
        this.props.initiateTimelineResize({
            event: e,
            throttle: (1 / 120) * 1000,
            onMouseDrag: this.resizeTimeline,
            onMouseDragEnd: this.stopTimelineResize,
        });
    }

    /**
     * decides how much duration to show on screen
     * @param {React.ChangeEvent<HTMLInputElement> | null} event
     * @param {number} scale
     */
    changeTimeScale(event, scale) {
        const { project, timelineMode, } = this.props;
        const { selectedSliders, timelineTile, } = this.state;
        const { sliders, stepper, timelinePlot } = timelineTile;

        let setStateCallback;
        let newTimeScale = scale;
        // if (event) {
        //     newTimeScale = parseFloat(event.target.value);
        // }
        if (!Number.isFinite(newTimeScale)) {
            newTimeScale = RULER_OPTIONS.timeScale.default;
        }
        if (newTimeScale < RULER_OPTIONS.timeScale.min) {
            newTimeScale = RULER_OPTIONS.timeScale.min;
        }
        if (newTimeScale > RULER_OPTIONS.timeScale.max) {
            newTimeScale = RULER_OPTIONS.timeScale.max;
        }

        const playheadThumb = sliders[playheadId].enterStart;
        const playheadTime = playheadThumb.position.step * RULER_OPTIONS.interval;
        const newStepper = calculateStepperSize({
            duration: project.get("duration"),
            timelinePlot,
            timeScale: newTimeScale,
        });
        const newTracks = getTracks({ project, stepper: newStepper, timelineMode, });
        const { sliders: newSliders, timelineSnapPoints: newTimelineSnapPoints } = getSliders({
            playhead: playheadTime,
            project,
            stepper: newStepper,
            prevSliders: sliders,
            tracks: newTracks,
            timelineMode,
        });
        const newSelectedSliders = selectedSliders.filter((selection) => newSliders[selection.sliderId]);
        const newPlayheadThumb = newSliders[playheadId].enterStart;

        // try to keep playhead in same position on screen
        const timelineInnerEl = this.timelineInnerRef.current;
        if (timelineInnerEl) {
            const { scrollLeft, offsetWidth } = timelineInnerEl;
            if (newTimeScale > stepper.timeScale && newTimeScale >= 0) {
                // zooming in and can scroll
                const currentPlayheadPositionX = playheadThumb.position.x - scrollLeft;
                const newPlayheadPositionX = newPlayheadThumb.position.x - scrollLeft;
                setStateCallback = () => timelineInnerEl.scrollBy({ left: newPlayheadPositionX - currentPlayheadPositionX });
            } else if (newTimeScale < stepper.timeScale && newTimeScale >= 0) {
                // zooming out and can scroll
                const newScrollWidth = RULER_OPTIONS.paddingLeft + newStepper.steps * newStepper.stepSizePx + RULER_OPTIONS.paddingRight;
                let newScrollLeft = scrollLeft;
                if (scrollLeft + offsetWidth >= newScrollWidth) {
                    newScrollLeft = newScrollWidth - offsetWidth;
                }
                const currentPlayheadPositionX = playheadThumb.position.x - scrollLeft;
                const newPlayheadPositionX = newPlayheadThumb.position.x - newScrollLeft;
                setStateCallback = () => timelineInnerEl.scrollBy({ left: newPlayheadPositionX - currentPlayheadPositionX });
            }
        }

        this.dispatchTimelineTile(
            {
                type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
                payload: {
                    stepper: newStepper,
                    tracks: newTracks,
                    sliders: newSliders,
                    timelineSnapPoints: newTimelineSnapPoints,
                },
            },
            setStateCallback
        );
        this.setSelectedSliders({ selectedSliders: newSelectedSliders });
    }

    /**
     * handle ruler auto scroll
     * @param {AutoScrollCallbackParams} params
     */
    onTimelineAutoScroll(params = {}) {
        const { scrollStatus } = params;
        let { scrollLeft, scrollTop, scrollLeftCountAfterEnd } = params;

        const { selectedSliders, timelineTile, timelineAutoScrollData } = this.state;
        const { sliders, stepper, tracks, timelineSnapPoints } = timelineTile;

        const scrollOptions = getScrollOptions({ selectedSliders, sliders });
        let updatedStepper = stepper;
        let updatedTracks = tracks;
        let updatedSliders = sliders;
        /** @type {MatchingSnapPoints | undefined} */
        let matchingSnapPoints;

        if (
            timelineAutoScrollData
            && timelineAutoScrollData.mousePosition
            && timelineAutoScrollData.mouseDownPosition
            && scrollOptions
        ) {
            let { scrollLeftOnMouseDown, scrollTopOnMouseDown } =
                timelineAutoScrollData;
            if (!scrollLeftOnMouseDown) {
                scrollLeftOnMouseDown = 0;
            }
            if (!scrollTopOnMouseDown) {
                scrollTopOnMouseDown = 0;
            }
            if (scrollLeft === undefined) {
                if (this.timelineInnerRef.current) {
                    scrollLeft = this.timelineInnerRef.current.scrollLeft;
                } else {
                    scrollLeft = 0;
                }
            }
            if (scrollTop === undefined) {
                if (this.trackListContainerRef.current) {
                    scrollTop = this.trackListContainerRef.current.scrollTop;
                } else {
                    scrollTop = 0;
                }
            }
            if (scrollLeftCountAfterEnd === undefined) {
                scrollLeftCountAfterEnd = 0;
            }

            if (
                scrollStatus.isHorizontalScroll
                && scrollLeftCountAfterEnd > 0
                && shouldExtendRuler({ selectedSliders, sliders: updatedSliders, stepper: updatedStepper })
            ) {
                let increaseStepBy = Math.floor(scrollStatus.scrollLeftDisPerInterval / stepper.stepSizePx);
                if (increaseStepBy === 0) {
                    increaseStepBy = 1;
                }
                const videoExcessSteps = updatedStepper.videoExcessSteps + increaseStepBy;
                /** @type {StepperState} */
                updatedStepper = {
                    // to increase ruler's space
                    ...updatedStepper,
                    videoExcessSteps,
                };

                /** @type {Slider} */
                const updatedRulerSlider = {
                    // increase ruler's extreme end to give space for video slider to increase
                    ...updatedSliders[rulerId],
                    rulerEnd: {
                        ...updatedSliders[rulerId].rulerEnd,
                        position: {
                            ...updatedSliders[rulerId].rulerEnd.position,
                            step: updatedStepper.steps + updatedStepper.videoExcessSteps,
                            x: (
                                RULER_OPTIONS.paddingLeft
                                + (updatedStepper.steps + updatedStepper.videoExcessSteps) * updatedStepper.stepSizePx
                            ),
                        },
                    },
                };

                updatedSliders = {
                    ...updatedSliders,
                    [rulerId]: updatedRulerSlider,
                };
                updatedTracks = updateTrackWidth({ stepper: updatedStepper, tracks });
            }

            const moveToTrackResult = moveToTrack({
                moveTo: {
                    x: scrollLeft + timelineAutoScrollData.mousePosition.x,
                    y: scrollTop + timelineAutoScrollData.mousePosition.y,
                },
                startedFrom: {
                    x: scrollLeftOnMouseDown + timelineAutoScrollData.mouseDownPosition.x,
                    y: scrollTopOnMouseDown + timelineAutoScrollData.mouseDownPosition.y,
                },
                moveType: "drag",
                selectedSliders,
                sliders: updatedSliders,
                tracks,
            });
            updatedSliders = moveToTrackResult.updatedSliders;
            const moveSlidersResult = moveSliders({
                moveTo: {
                    x: scrollLeft + timelineAutoScrollData.mousePosition.x,
                    y: scrollTop + timelineAutoScrollData.mousePosition.y,
                },
                startedFrom: {
                    x: (
                        scrollLeftOnMouseDown
                        + timelineAutoScrollData.mouseDownPosition.x
                    ),
                    y: scrollTopOnMouseDown + timelineAutoScrollData.mouseDownPosition.y,
                },
                moveType: "drag",
                scrollType: "scrolling",
                selectedSliders,
                tracks: updatedTracks,
                sliders: updatedSliders,
                stepper: updatedStepper,
                timelineSnapPoints,
            });
            updatedSliders = moveSlidersResult.sliders;
            matchingSnapPoints = moveSlidersResult.matchingSnapPoints;
        }

        /** @type {MergeTimelineTilePayload} */
        const tileUpdate = {
            sliders: updatedSliders,
            tracks: updatedTracks,
            stepper: updatedStepper,
        };
        if (matchingSnapPoints) {
            tileUpdate.matchingSnapPoints = matchingSnapPoints;
        }

        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
            payload: tileUpdate,
        });
    }

    /**
     * places playhead thumb based on mouse event
     * @param {MouseDragParams} params
     */
    handleSlidersDrag(params = {}) {
        const { event, mouseMovedBy, mouseCurrentPosition, mouseDownPosition, scrollLeftOnMouseDown, scrollTopOnMouseDown } = params;
        event.preventDefault(); // to prevent built autoscroll in safari

        const DRAG_START_THRESHOLD = this.dragStartThreshold;
        if (
            !this.sliderMoved
            && (Math.abs(mouseMovedBy.x) > DRAG_START_THRESHOLD || Math.abs(mouseMovedBy.y) > DRAG_START_THRESHOLD)
        ) {
            this.sliderMoved = true;
            this.setState({ sliderMoved: true });
        }
        if (!this.sliderMoved) {
            return;
        }

        const { timelineScrollStatus, startTimelineScroll, stopTimelineScroll } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders, stepper, timelinePlot, timelineSnapPoints, tracks } = timelineTile;

        /** @type {StartScrollParams} */
        const timelineScrollParams = {};
        const scrollOptions = getScrollOptions({ selectedSliders, sliders });
        let isThumbDrag = false;
        if (selectedSliders.length === 1 && selectedSliders[0].thumbId) {
            isThumbDrag = true;
        }

        if (scrollOptions) {
            const adjustedMousePosition = { ...mouseCurrentPosition };
            const adjustedMouseDiff = { ...mouseMovedBy };
            const adjustedMouseDownPosition = { ...mouseDownPosition };
            let scrollLeft = 0;
            let scrollTop = 0;
            let willRulerScroll = false;

            if (this.timelineInnerRef.current instanceof HTMLElement && this.trackListContainerRef.current instanceof HTMLElement) {
                // adjust mouse position to prevent playhead jump on scroll
                const thresholdX = scrollOptions.clientExtremeThreshold;
                const thresholdY = scrollOptions.clientExtremeThreshold;
                const dragBounds = {
                    x: thresholdX,
                    y: thresholdY,
                    width: timelinePlot.width - 2 * thresholdX,
                    height: timelinePlot.height - tracksOffsetY - 2 * thresholdY,
                };
                scrollLeft = this.timelineInnerRef.current.scrollLeft;
                scrollTop = this.trackListContainerRef.current.scrollTop;

                const scrollLeftToRight = (
                    mouseMovedBy.x < 0 // moved towards left
                    && scrollLeft > 0 // can scroll towards left
                    && mouseCurrentPosition.x <= dragBounds.x // moved above threshold
                );
                const scrollRightToLeft = (
                    mouseMovedBy.x > 0 // moved towards left
                    && ( // can scroll towards right
                        shouldExtendRuler({ selectedSliders, sliders, stepper })
                        || (scrollLeft + this.timelineInnerRef.current.offsetWidth < this.timelineInnerRef.current.scrollWidth)
                    ) && mouseCurrentPosition.x >= dragBounds.x + dragBounds.width // moved above threshold
                );

                const scrollTopToBottom = (
                    !isThumbDrag
                    && mouseMovedBy.y < 0 // moved towards top
                    && scrollTop > 0 // can scroll towards top
                    && mouseCurrentPosition.y <= dragBounds.y // moved above threshold
                );
                const scrollBottomToTop = (
                    !isThumbDrag
                    && mouseMovedBy.y > 0 // moved towards bottom
                    // can scroll towards bottom
                    && (scrollTop + this.trackListContainerRef.current.offsetHeight < this.trackListContainerRef.current.scrollHeight)
                    && mouseCurrentPosition.y >= dragBounds.y + dragBounds.height // moved above threshold
                );

                if (scrollLeftToRight || scrollRightToLeft) {
                    let scrollBy = scrollOptions.moveBy;
                    // block playhead within client bounds to prevent playhead from moving far from the screen
                    if (scrollLeftToRight) {
                        adjustedMousePosition.x = dragBounds.x;
                        if (mouseDownPosition.x < adjustedMousePosition.x) { // mouse clicked after threshold
                            adjustedMouseDownPosition.x = adjustedMousePosition.x + 1;
                        }
                        adjustedMouseDiff.x = adjustedMousePosition.x - adjustedMouseDownPosition.x;
                        scrollBy = -scrollBy; // change scroll direction to left
                    } else if (scrollRightToLeft) {
                        adjustedMousePosition.x = dragBounds.x + dragBounds.width;
                        if (mouseDownPosition.x > adjustedMousePosition.x) { // mouse clicked after threshold
                            adjustedMouseDownPosition.x = adjustedMousePosition.x - 1;
                        }
                        adjustedMouseDiff.x = adjustedMousePosition.x - adjustedMouseDownPosition.x;
                    }

                    timelineScrollParams.interval = scrollOptions.interval;
                    timelineScrollParams.scrollLeftDisPerInterval = scrollBy;
                    timelineScrollParams.isHorizontalScroll = true;
                    willRulerScroll = true;
                }

                if (scrollTopToBottom || scrollBottomToTop) {
                    let scrollBy = scrollOptions.moveBy;
                    // block slider within client bounds to prevent slider from moving far from the screen
                    if (scrollTopToBottom) {
                        adjustedMousePosition.y = dragBounds.y;
                        if (mouseDownPosition.y < adjustedMousePosition.y) { // mouse clicked after threshold
                            adjustedMouseDownPosition.y = adjustedMousePosition.y + 1;
                        }
                        adjustedMouseDiff.y = adjustedMousePosition.y - adjustedMouseDownPosition.y;
                        scrollBy = -scrollBy; // change scroll direction to left
                    } else if (scrollBottomToTop) {
                        adjustedMousePosition.y = dragBounds.y + dragBounds.height;
                        if (mouseDownPosition.y > adjustedMousePosition.y) { // mouse clicked after threshold
                            adjustedMouseDownPosition.y = adjustedMousePosition.y - 1;
                        }
                        adjustedMouseDiff.y = adjustedMousePosition.y - adjustedMouseDownPosition.y;
                    }

                    timelineScrollParams.interval = scrollOptions.interval;
                    timelineScrollParams.scrollTopDisPerInterval = scrollBy;
                    timelineScrollParams.isVerticalScroll = true;
                }

                let updateScrollData = false;
                if (timelineScrollStatus.isScrolling) {
                    const {
                        isHorizontalScroll = false,
                        isVerticalScroll = false,
                        scrollLeftDisPerInterval,
                        scrollTopDisPerInterval,
                    } = timelineScrollParams;

                    if (!isHorizontalScroll && !isVerticalScroll) { // will not scroll in both x and y axis
                        stopTimelineScroll();
                    } else if (
                        isHorizontalScroll !== timelineScrollStatus.isHorizontalScroll
                        || isVerticalScroll !== timelineScrollStatus.isVerticalScroll
                        || (isHorizontalScroll && scrollLeftDisPerInterval !== timelineScrollStatus.scrollLeftDisPerInterval)
                        || (isVerticalScroll && scrollTopDisPerInterval !== timelineScrollStatus.scrollTopDisPerInterval)
                    ) { // calculated scroll status is different from existing scroll status
                        /** @type {StartScrollParams} */
                        const startScrollParams = {
                            scrollDeps: {
                                onAutoScroll: this.onTimelineAutoScroll,
                                scrollLeftElRef: this.timelineInnerRef,
                                scrollTopElRef: this.trackListContainerRef,
                            },
                            scrollParams: timelineScrollParams,
                        };
                        startTimelineScroll(startScrollParams);
                        updateScrollData = true;
                    } else if (
                        (isHorizontalScroll && timelineScrollStatus.isHorizontalScroll) ||
                        (isVerticalScroll && timelineScrollStatus.isVerticalScroll)
                    ) { // continue scrolling without changing scroll state
                        updateScrollData = true;
                    }
                } else if (timelineScrollParams.isHorizontalScroll || timelineScrollParams.isVerticalScroll) {
                    /** @type {StartScrollParams} */
                    const startScrollParams = {
                        scrollDeps: {
                            onAutoScroll: this.onTimelineAutoScroll,
                            scrollLeftElRef: this.timelineInnerRef,
                            scrollTopElRef: this.trackListContainerRef,
                        },
                        scrollParams: timelineScrollParams,
                    };
                    startTimelineScroll(startScrollParams);
                    updateScrollData = true;
                }

                if (updateScrollData) {
                    this.setState({
                        timelineAutoScrollData: {
                            mouseDownPosition: adjustedMouseDownPosition,
                            mousePosition: adjustedMousePosition,
                            mouseMovedBy: adjustedMouseDiff,
                            scrollLeftOnMouseDown,
                            scrollTopOnMouseDown,
                        },
                    });
                }
            }

            let updatedSliders = sliders;
            /** @type {MatchingSnapPoints | undefined} */
            let matchingSnapPoints;
            if (!timelineScrollParams.isVerticalScroll) {
                const moveToTrackResult = moveToTrack({
                    moveTo: {
                        x: scrollLeft + adjustedMousePosition.x,
                        y: scrollTop + adjustedMousePosition.y,
                    },
                    startedFrom: {
                        x: scrollLeftOnMouseDown + adjustedMouseDownPosition.x,
                        y: scrollTopOnMouseDown + adjustedMouseDownPosition.y,
                    },
                    moveType: "drag",
                    selectedSliders,
                    sliders,
                    tracks,
                });
                updatedSliders = moveToTrackResult.updatedSliders;
            }
            if (!timelineScrollParams.isHorizontalScroll) {
                const moveSlidersResult = moveSliders({
                    moveTo: {
                        x: scrollLeft + adjustedMousePosition.x,
                        y: scrollTop + adjustedMousePosition.y,
                    },
                    startedFrom: {
                        x: scrollLeftOnMouseDown + adjustedMouseDownPosition.x,
                        y: scrollTopOnMouseDown + adjustedMouseDownPosition.y,
                    },
                    moveType: "drag",
                    scrollType: willRulerScroll ? "scrolling" : "not-scrolling",
                    selectedSliders,
                    tracks,
                    sliders: updatedSliders,
                    stepper,
                    timelineSnapPoints,
                });
                updatedSliders = moveSlidersResult.sliders;
                matchingSnapPoints = moveSlidersResult.matchingSnapPoints;
            }
            /** @type {MergeTimelineTilePayload} */
            const tileUpdate = { sliders: updatedSliders };
            if (matchingSnapPoints) {
                tileUpdate.matchingSnapPoints = matchingSnapPoints;
            }

            this.dispatchTimelineTile({
                type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
                payload: tileUpdate,
                updateStorePlayhead: true,
            });
        }
    }

    /**
     * places playhead thumb based on mouse event
     * @param {MouseDragParams} params
     */
    handleSlidersDragEnd(params = {}) {
        const {
            mouseCurrentPosition,
            mouseDownPosition,
            scrollLeftOnMouseDown,
            scrollTopOnMouseDown,
        } = params;

        const { project, timelineScrollStatus, stopTimelineScroll } = this.props;
        const { selectedSliders, timelineAutoScrollData, timelineTile } = this.state;
        const { sliders, stepper, timelineSnapPoints, tracks } = timelineTile;

        stopTimelineScroll();
        this.setState({ timelineAutoScrollData: null });

        if (!this.sliderMoved) {
            return;
        }

        // this.sliderMoved = false; // NOTE: do not set this flag to false on mouseup/click. this flag is being used by other mouseup events
        this.setState({ sliderMoved: false });

        /** @type {MergeTimelineTilePayload} */
        const tileUpdate = {};
        let updatedSliders = sliders;
        const scrollOptions = getScrollOptions({ selectedSliders, sliders: updatedSliders });
        /** @type {MatchingSnapPoints} */
        let matchingSnapPoints = {};
        let scrollLeft = 0;
        if (this.timelineInnerRef.current instanceof HTMLElement) {
            scrollLeft = this.timelineInnerRef.current.scrollLeft;
        }
        let scrollTop = 0;
        if (this.trackListContainerRef.current instanceof HTMLElement) {
            scrollTop = this.trackListContainerRef.current.scrollTop;
        }

        if (scrollOptions) {
            if (timelineScrollStatus.isScrolling) {
                if (
                    timelineAutoScrollData
                    && timelineAutoScrollData.mousePosition
                    && timelineAutoScrollData.mouseDownPosition
                    && scrollOptions
                ) {
                    const moveToTrackResult = moveToTrack({
                        moveTo: {
                            x: scrollLeft + timelineAutoScrollData.mousePosition.x,
                            y: scrollTop + timelineAutoScrollData.mousePosition.y,
                        },
                        startedFrom: {
                            x: scrollLeftOnMouseDown + timelineAutoScrollData.mouseDownPosition.x,
                            y: scrollTopOnMouseDown + timelineAutoScrollData.mouseDownPosition.y,
                        },
                        moveType: "place",
                        selectedSliders,
                        sliders: updatedSliders,
                        tracks,
                    });
                    updatedSliders = moveToTrackResult.updatedSliders;
                    const moveSlidersResult = moveSliders({
                        moveTo: {
                            x: scrollLeft + timelineAutoScrollData.mousePosition.x,
                            y: scrollTop + timelineAutoScrollData.mousePosition.y,
                        },
                        startedFrom: {
                            x: scrollLeftOnMouseDown + timelineAutoScrollData.mouseDownPosition.x,
                            y: scrollTopOnMouseDown + timelineAutoScrollData.mouseDownPosition.y,
                        },
                        moveType: "place",
                        scrollType: "scroll-end",
                        selectedSliders,
                        tracks,
                        sliders: updatedSliders,
                        stepper,
                        timelineSnapPoints,
                    });
                    updatedSliders = moveSlidersResult.sliders;
                    matchingSnapPoints = moveSlidersResult.matchingSnapPoints;
                }
            } else {
                const moveToTrackResult = moveToTrack({
                    moveTo: {
                        x: scrollLeft + mouseCurrentPosition.x,
                        y: scrollTop + mouseCurrentPosition.y,
                    },
                    startedFrom: {
                        x: scrollLeftOnMouseDown + mouseDownPosition.x,
                        y: scrollTopOnMouseDown + mouseDownPosition.y,
                    },
                    moveType: "place",
                    selectedSliders,
                    sliders: updatedSliders,
                    tracks,
                });
                updatedSliders = moveToTrackResult.updatedSliders;
                const moveSlidersResult = moveSliders({
                    moveTo: {
                        x: scrollLeft + mouseCurrentPosition.x,
                        y: scrollTop + mouseCurrentPosition.y,
                    },
                    startedFrom: {
                        x: scrollLeftOnMouseDown + mouseDownPosition.x,
                        y: scrollTopOnMouseDown + mouseDownPosition.y,
                    },
                    moveType: "place",
                    scrollType: "not-scrolling",
                    selectedSliders,
                    tracks,
                    sliders: updatedSliders,
                    stepper,
                    timelineSnapPoints,
                });
                updatedSliders = moveSlidersResult.sliders;
                matchingSnapPoints = moveSlidersResult.matchingSnapPoints;
            }
        }

        if (updatedSliders[playheadId] !== sliders[playheadId]) {
            const updatedTimelineSnapPoints = removeDefaultSnapPoints({
                timelineSnapPoints,
            });
            addDefaultSnapPoints({
                mutableTimelineSnapPoints: updatedTimelineSnapPoints, // it is okay to use mutation logic here as we have removed whole trace of default sliders
                sliders: updatedSliders,
            });
            tileUpdate.timelineSnapPoints = updatedTimelineSnapPoints;
        }

        if (stepper.videoExcessSteps) {
            const updatedStepper = {
                ...stepper,
                videoExcessSteps: 0,
            };

            /** @type {Slider} */
            const updatedRulerSlider = {
                // increase ruler's extreme end to give space for video slider to increase
                ...updatedSliders[rulerId],
                rulerEnd: {
                    ...updatedSliders[rulerId].rulerEnd,
                    position: {
                        ...updatedSliders[rulerId].rulerEnd.position,
                        step: updatedStepper.steps + updatedStepper.videoExcessSteps,
                        x: RULER_OPTIONS.paddingLeft + (updatedStepper.steps + updatedStepper.videoExcessSteps) * updatedStepper.stepSizePx,
                    },
                },
            };

            updatedSliders = {
                ...updatedSliders,
                [rulerId]: updatedRulerSlider,
            };

            tileUpdate.stepper = updatedStepper;
            tileUpdate.tracks = updateTrackWidth({ stepper: updatedStepper, tracks });
        }

        tileUpdate.sliders = updatedSliders;
        tileUpdate.matchingSnapPoints = matchingSnapPoints;

        this.dispatchTimelineTile({ type: TIMELINE_TILE_ACTIONS.MERGE_STATE, payload: tileUpdate });
        const toUpdate = getUpdatedTimeline({ project, selectedSliders, sliders: updatedSliders });
        if (toUpdate.length) {
            this.props.updateTimelineTime({ toUpdate });
        }
    }

    updateStorePlayhead(sliders) {
        if (!sliders) {
            sliders = this.state.timelineTile.sliders;
        }
        const playheadSlider = sliders[playheadId];

        const position = getTrackPosition({ slider: playheadSlider, thumbIds: ["enterStart"] }).enterStart;
        const playheadTime = position.step * RULER_OPTIONS.interval;
        if (this.props.isPlayAll || playheadTime !== this.props.playhead) {
            this.props.setPlayHeadTime({ playhead: playheadTime });
        }
    }

    /**
     * handle playhead indicator position on mouse move
     * @param {PointerEvent | React.PointerEvent} event
     */
    movePlayheadIndication(event) {
        const { timelineTile } = this.state;
        const { sliders, stepper, timelinePlot } = timelineTile;

        const mousePosition = getMouseClientPosition(event);
        const playheadIndicatorThumb = sliders[indicatorId].enterStart;
        const playheadThumb = sliders[playheadId].enterStart;
        let scrollLeft = 0;
        if (this.timelineInnerRef.current instanceof HTMLElement) {
            scrollLeft = this.timelineInnerRef.current.scrollLeft;
        }
        const { matchingSnapPoints, sliders: updatedSliders } = moveSliders({
            moveTo: { x: scrollLeft + mousePosition.x - timelinePlot.x, y: 0 },
            startedFrom: { x: playheadIndicatorThumb.position.x },
            moveType: "drag",
            scrollType: "not-scrolling",
            selectedSliders: [
                { sliderId: playheadIndicatorThumb.sliderId, thumbId: playheadIndicatorThumb.id },
            ], // indicator cannot be selected, so make it up
            sliders,
            stepper,
        });
        let isVisible = true;
        const PLAYHEAD_WIDTH = 30;
        const position = getTrackPosition({ slider: updatedSliders[indicatorId], thumbIds: ["enterStart"] }).enterStart;
        if (
            !this.props.isPlayAll
            && playheadThumb.position.step === position.step
            && Math.abs(playheadThumb.position.x - position.x) < PLAYHEAD_WIDTH / 2
        ) {
            isVisible = false;
        }
        updatedSliders[indicatorId].isVisible = isVisible; // we don't have to spread here as moveSliders returns shallow copy of selected slider and selected thumb
        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
            payload: {
                sliders: updatedSliders,
                matchingSnapPoints,
            },
            updateStorePlayhead: false,
        });
        return updatedSliders;
    }

    /**
     * Function to handle mouse move over timeline ruler and tracks
     * @param {React.PointerEvent} event
     */
    handleTimelineMouseMove(event) {
        const { isPlayAll, timelineMode, } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { timelinePlot, tracks } = timelineTile;

        const mousePosition = getMouseClientPosition(event);
        let scrollLeft = 0;
        if (this.timelineInnerRef.current instanceof HTMLElement) {
            scrollLeft = this.timelineInnerRef.current.scrollLeft;
        }
        let scrollTop = 0;
        if (this.trackListContainerRef.current instanceof HTMLElement) {
            scrollTop = this.trackListContainerRef.current.scrollTop;
        }

        let updatedSliders = this.movePlayheadIndication(event);
        if (!isPlayAll) {
            updatedSliders = addGapSlider({
                mousePosition: {
                    x: scrollLeft + mousePosition.x - timelinePlot.x,
                    y: scrollTop + mousePosition.y - (timelinePlot.y + tracksOffsetY),
                },
                sliders: updatedSliders,
                selectedSliders,
                tracks,
                timelineMode,
            }).updatedSliders;
        }

        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.SET_SLIDERS,
            payload: updatedSliders,
            updateStorePlayhead: false,
        });
    }

    /**
     * handle hide playhead indicator
     * @param {object} params
     * @param {Sliders} params.updatedSliders can be passed if this func is called along with other slider update functions
     */
    hidePlayheadIndication(params = {}) {
        let { updatedSliders } = params;
        const { timelineTile } = this.state;
        const { sliders } = timelineTile;

        if (!updatedSliders) {
            updatedSliders = sliders;
        }
        if (updatedSliders[indicatorId].isVisible) {
            updatedSliders = {
                ...updatedSliders,
                [indicatorId]: {
                    ...updatedSliders[indicatorId],
                    isVisible: false,
                },
            };
            this.dispatchTimelineTile({
                type: TIMELINE_TILE_ACTIONS.SET_SLIDERS,
                payload: updatedSliders,
                updateStorePlayhead: false,
            });
        }

        return updatedSliders;
    }

    handleTimelineMouseLeave() {
        let updatedSliders = this.hidePlayheadIndication();
        const { selectedSliders } = this.state;

        for (const sliderId of Reflect.ownKeys(updatedSliders)) {
            const slider = updatedSliders[sliderId];
            if (slider && slider.sliderType === SLIDER_TYPES.GAP) {
                const isSelected = selectedSliders.some((selection) => selection.sliderId === sliderId);
                if (!isSelected) {
                    updatedSliders = { ...updatedSliders };
                    delete updatedSliders[sliderId];
                }
            }
        }

        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.SET_SLIDERS,
            payload: updatedSliders,
            updateStorePlayhead: false,
        });
    }

    /**
     * function to select and drag sliders
     * @param {object} params
     * @param {React.PointerEvent | PointerEvent | React.MouseEvent | MouseEvent} params.event pointer down or mouse click event
     * @param {string | undefined} params.thumbId id of thumb to drag. should not be passed if whole slider is to be dragged
     * @param {string} params.sliderId id of slider to drag. should be passed even if a specific thumb is to be dragged
     * @param {boolean | undefined} params.canInitiateDrag whether drag can be started on selected slider. defaults to true.
     */
    selectSlider(params = {}) {
        const { event, thumbId, sliderId, canInitiateDrag = true } = params;

        const { initiateSlidersDrag, timelineMode } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders, stepper, timelinePlot, tracks } = timelineTile;

        const selected = selectedSliders.find((s) => s.sliderId === sliderId);
        const selectMultiple = event.ctrlKey || event.metaKey;
        let initiateDrag = false;
        let updatedSliders = sliders;
        const tileUpdate = {};

        if (event.type === "pointerdown") {
            if (sliderId === playheadId) {
                this.setSelectedSliders({ selectedSliders: [{ sliderId, thumbId }] });
                initiateDrag = true;
            } else if (updatedSliders[sliderId] && !updatedSliders[sliderId].preventSelection) {
                const slider = updatedSliders[sliderId];
                const isSliderDraggable = slider.isDraggable;
                /** @type {SelectedSliders} */
                let newSelection = [];
                let canDragThumb = Boolean(thumbId && isSliderDraggable);

                if (selectMultiple || selected) {
                    newSelection = selectedSliders.filter((selection) => {
                        const selectedSlider = updatedSliders[selection.sliderId];
                        return (
                            selectedSlider
                            // to not move playhead along with other sliders
                            && selectedSlider.id !== playheadId
                            // even though it is removed now, this will be added later
                            && selectedSlider.id !== sliderId
                            // remove gap slider selection
                            && selectedSlider.sliderType !== SLIDER_TYPES.GAP
                            // to not mix draggable with non-draggable
                            && selectedSlider.isDraggable === isSliderDraggable
                            // selection should have either subtitle or other types, not both
                            && (
                                slider.sliderType === SLIDER_TYPES.SUBTITLE && selectedSlider.sliderType === SLIDER_TYPES.SUBTITLE
                                || slider.sliderType !== SLIDER_TYPES.SUBTITLE && selectedSlider.sliderType !== SLIDER_TYPES.SUBTITLE
                            )
                        );
                    });

                    if (canDragThumb && newSelection.length) {
                        // check if other selected sliders supports thumb to be selected
                        canDragThumb = newSelection.every((selection) => {
                            const selectedSlider = updatedSliders[selection.sliderId];
                            return Boolean(selectedSlider[thumbId]);
                        });
                    }
                }

                if (canDragThumb) {
                    // single/multiple slider thumb selection
                    newSelection = newSelection.map((selection) => {
                        return {
                            sliderId: selection.sliderId,
                            thumbId,
                        };
                    });
                    newSelection.push({ sliderId, thumbId });
                } else if (selectMultiple || selected) {
                    // multiple slider selection
                    newSelection = newSelection.map((selection) => {
                        return {
                            sliderId: selection.sliderId,
                            thumbId: undefined,
                        };
                    });
                    newSelection.push({ sliderId });
                } else {
                    // single slider/slider-thumb selection
                    // slider-thumb example scenario:
                    //   previously 2 sliders were selected and thumb of another slider is dragged...
                    //   but one of them does not have required thumbId
                    //   in this case we need to allow thumb drag for 3rd slider, deselecting previous 2 sliders
                    newSelection = [{ sliderId, thumbId }];
                }

                if (isSliderDraggable && newSelection.length) {
                    initiateDrag = true;
                }
                if (timelineMode === TIMELINE_MODES.SUBTITLE) {
                    const supportedSlider = (
                        slider.sliderType === SLIDER_TYPES.PLAYHEAD_INDICATOR
                        || slider.sliderType === SLIDER_TYPES.PLAYHEAD_THUMB
                        || slider.sliderType === SLIDER_TYPES.RULER
                        || slider.sliderType === SLIDER_TYPES.SUBTITLE
                        || (slider.sliderType === SLIDER_TYPES.GAP && slider.itemType === SLIDER_GAP_TYPES.SUBTITLE)
                    );
                    if (!supportedSlider) {
                        this.toggleSubtitleMode(event);
                    }
                }
                this.setSelectedSliders({ selectedSliders: newSelection });
                this.preventSliderDeselect = !selected; // this is to make sure selected slider doesn't deselect on mouse up (click)
            }

            for (const currentSliderId of Reflect.ownKeys(updatedSliders)) {
                const currentSlider = updatedSliders[currentSliderId];
                if (currentSlider.id !== sliderId && currentSlider.sliderType === SLIDER_TYPES.GAP) {
                    // remove all deselected gap sliders
                    updatedSliders = { ...updatedSliders };
                    delete updatedSliders[currentSlider.id];
                }
            }
        } else if (event.type === "click") {
            let canMovePlayhead = true;
            let preventSliderSelection = false;
            if (!selectMultiple && updatedSliders[sliderId] && updatedSliders[sliderId].sliderType === SLIDER_TYPES.MINI_SUBTITLE) {
                const slider = updatedSliders[sliderId];
                canMovePlayhead = false;
                this.toggleSubtitleMode(event);
                if (slider.meta && slider.meta.firstSubtitleId) {
                    // select subtitle using redux instead of timeline, as no subtitle sliders will be in internal state at this point
                    this.props.setSelectedItems({ selectedSubtitles: [slider.meta.firstSubtitleId.timelineId] });
                    preventSliderSelection = true;
                }
            }
            if (!preventSliderSelection && !this.preventSliderDeselect && selected && !this.sliderMoved && selectedSliders.length > 1) {
                if (selectMultiple) {
                    const newSelection = selectedSliders.filter((s) => s.sliderId !== sliderId);
                    this.setSelectedSliders({ selectedSliders: newSelection });
                } else {
                    this.setSelectedSliders({ selectedSliders: [{ sliderId }] });
                }
            }
            // Avoid playhead placement on drag and multiple selection.
            if (canMovePlayhead && !this.sliderMoved && !selectMultiple) {
                // Placing the playhead on click position.
                updatedSliders = this.placePlayheadOnClick(event, null, updatedSliders);
            }
            this.preventSliderDeselect = false;
        }

        if (initiateDrag && canInitiateDrag) {
            this.sliderMoved = false;
            this.setState({ sliderMoved: false });
            updatedSliders = this.hidePlayheadIndication({ updatedSliders });
            this.setState({ timelineAutoScrollData: null });
            initiateSlidersDrag({
                event,
                throttle: (1 / 90) * 1000,
                onMouseDrag: this.handleSlidersDrag,
                onMouseDragEnd: this.handleSlidersDragEnd,
                clientOffset: { x: -timelinePlot.x, y: -(timelinePlot.y + tracksOffsetY) },
                scrollLeftElRef: this.timelineInnerRef,
                scrollTopElRef: this.trackListContainerRef,
                cursor: thumbId ? "ew-resize" : "grabbing",
                cursorThreshold: this.dragStartThreshold,
            });
        }

        if (stepper.videoExcessSteps) {
            const updatedStepper = { ...stepper, videoExcessSteps: 0 };
            tileUpdate.stepper = updatedStepper;
            tileUpdate.tracks = updateTrackWidth({ stepper: updatedStepper, tracks });
        }

        tileUpdate.sliders = updatedSliders;
        this.dispatchTimelineTile({ type: TIMELINE_TILE_ACTIONS.MERGE_STATE, payload: tileUpdate });
    }

    /**
     * function to remove selected gap sliders
     * @param {object} params
     * @param {Sliders} params.updatedSliders can be passed if this func is called along with other slider update functions
     * @param {SelectedSliders} params.updatedSelectedSliders can be passed if this func is called along with other selection update functions
     * @param {boolean | undefined} params.removeAll whether all gap sliders are to be removed instead of selected gap sliders
     */
    removeGapSlider(params = {}) {
        const { removeAll = false } = params;
        let { updatedSliders, updatedSelectedSliders } = params;

        const { selectedSliders, timelineTile } = this.state;
        const { sliders } = timelineTile;

        if (!updatedSliders) {
            updatedSliders = sliders;
        }
        if (!updatedSelectedSliders) {
            updatedSelectedSliders = selectedSliders;
        }

        if (removeAll) {
            for (const sliderId of Reflect.ownKeys(updatedSliders)) {
                const currentSlider = updatedSliders[sliderId];
                if (currentSlider.sliderType === SLIDER_TYPES.GAP) {
                    updatedSliders = { ...updatedSliders };
                    delete updatedSliders[currentSlider.id];
                }
            }
        } else {
            updatedSelectedSliders.forEach((selection) => {
                const currentSlider = updatedSliders[selection.sliderId];
                if (currentSlider.sliderType === SLIDER_TYPES.GAP) {
                    updatedSliders = { ...updatedSliders };
                    delete updatedSliders[currentSlider.id];
                }
            });
        }
        updatedSelectedSliders = updatedSelectedSliders.filter((selection) => updatedSliders[selection.sliderId]); // to remove gap selection

        this.dispatchTimelineTile({ type: TIMELINE_TILE_ACTIONS.SET_SLIDERS, payload: updatedSliders });
        this.setSelectedSliders({ selectedSliders: updatedSelectedSliders });

        return {
            updatedSliders,
            updatedSelectedSliders,
        };
    }

    /**
     * @param {number} playhead 
     */
    placePlayheadAtTime(seconds) {
        const { timelineTile } = this.state;
        const { stepper } = timelineTile;
        const step = secondsToStep({ seconds, stepper });
        this.placePlayheadOnClick(null, { x: step.x, y: 0 });
    }

    /**
     * handle playhead thumb position on mouse click
     * @param {PointerEvent | React.PointerEvent | null} event
     * @param {{ x: number, y: number } | undefined} moveTo
     */
    placePlayheadOnClick(event, moveTo, updatedSliders) {
        const { isPlayAll } = this.props;
        const { timelineTile } = this.state;
        const { sliders, stepper, timelineSnapPoints, timelinePlot } = timelineTile;
        if (!updatedSliders) {
            updatedSliders = sliders;
        }

        if (!moveTo) {
            const mousePosition = getMouseClientPosition(event);
            let scrollLeft = 0;
            if (this.timelineInnerRef.current instanceof HTMLElement) {
                scrollLeft = this.timelineInnerRef.current.scrollLeft;
            }
            moveTo = { x: scrollLeft + mousePosition.x - timelinePlot.x, y: 0 };
        }

        const playheadIndicator = updatedSliders[indicatorId];
        const playheadThumb = updatedSliders[playheadId].enterStart;
        const moveSlidersResult = moveSliders({
            moveTo,
            startedFrom: { x: playheadThumb.position.x },
            moveType: isPlayAll ? "drag" : "place",
            scrollType: "not-scrolling",
            selectedSliders: [{ sliderId: playheadThumb.sliderId, thumbId: playheadThumb.id }], // playhead will not be selected on ruler click, so make it up
            sliders: updatedSliders,
            stepper,
        });
        const { matchingSnapPoints } = moveSlidersResult;
        updatedSliders = moveSlidersResult.sliders;
        if (playheadIndicator.isVisible) {
            updatedSliders = {
                // sliders
                ...updatedSliders,
                [indicatorId]: {
                    // slider
                    ...updatedSliders[indicatorId],
                    isVisible: false,
                },
            };
        }
        const updatedTimelineSnapPoints = removeDefaultSnapPoints({ timelineSnapPoints });
        addDefaultSnapPoints({
            mutableTimelineSnapPoints: updatedTimelineSnapPoints, // it is okay to use mutation logic here as we have removed whole trace of default sliders
            sliders: updatedSliders,
        });
        this.dispatchTimelineTile({
            type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
            payload: {
                sliders: updatedSliders,
                timelineSnapPoints: updatedTimelineSnapPoints,
                matchingSnapPoints,
            },
        });

        const newPlayheadThumb = updatedSliders[playheadId].enterStart;
        const willMovePlayhead = playheadThumb.position.step !== newPlayheadThumb.position.step;
        if (willMovePlayhead && !isPlayAll) {
            this.setState({ animatePlayhead: true });
        }

        return updatedSliders;
    }

    /**
     * to increase/decrease playhead step on click
     * @param {number} step
     */
    adjustPlayheadStepBy(step) {
        const { timelineTile } = this.state;
        const { sliders, stepper } = timelineTile;

        const stepOffset = step * stepper.stepSizePx;
        const playheadPosition = getTrackPosition({ slider: sliders[playheadId], thumbIds: ["enterStart"] }).enterStart;
        this.placePlayheadOnClick(null, { x: playheadPosition.x + stepOffset, y: 0 });
    }

    /**
     * Function to set multiselect flag and update other related stuff based on flag
     * @param {object} params
     * @param {PointerEvent | React.PointerEvent} params.event
     * @param {boolean} params.isSelecting
     * @param {SelectedSliders | undefined} params.selectionToSet
     */
    updateIsSliderMultiSelect(params = {}) {
        const { event, isSelecting, isGapSlider, isContextClick } = params;
        let { selectionToSet } = params;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders } = timelineTile;

        const prevIsSliderMultiSelect = this.isSliderMultiSelect;
        this.setState({ isSliderMultiSelect: isSelecting });
        this.isSliderMultiSelect = isSelecting;

        if (isSelecting) {
            const { updatedSliders, updatedSelectedSliders } = this.removeGapSlider({
                updatedSelectedSliders: selectionToSet,
                removeAll: true,
            });
            selectionToSet = updatedSelectedSliders;
            this.hidePlayheadIndication({ updatedSliders });
        } else if (!isContextClick) {
            let updatedSliders = sliders;
            if (!prevIsSliderMultiSelect && !isGapSlider && event.type === "pointerup") {
                updatedSliders = this.placePlayheadOnClick(event);
            } else {
                updatedSliders = this.movePlayheadIndication(event);
            }

            if (
                selectionToSet &&
                selectionToSet.length === 0 &&
                selectedSliders.length > 0
            ) {
                // to remove gap sliders when clicked on track
                const { updatedSelectedSliders } = this.removeGapSlider({
                    updatedSliders,
                    updatedSelectedSliders: selectionToSet,
                    removeAll: true,
                });
                selectionToSet = updatedSelectedSliders;
            }
        }

        if (selectionToSet) {
            this.setSelectedSliders({ selectedSliders: selectionToSet });
        }
    }

    handleGapRemove({ deleteType = "magnet" } = {}) {
        const { project } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders } = timelineTile;

        const { updatedSliderIds, updatedSliders } = removeSliderGaps({
            selectedSliders,
            sliders,
            deleteType,
        });
        const toUpdate = getUpdatedTimeline({
            project,
            selectedSliders: updatedSliderIds,
            sliders: updatedSliders,
        });
        this.dispatchTimelineTile({ type: TIMELINE_TILE_ACTIONS.SET_SLIDERS, payload: updatedSliders });
        if (toUpdate.length) {
            this.props.updateTimelineTime({ toUpdate });
        }
    }

    /**
     * @param {object} params
     * @param {"left" | "right"} params.direction
     * @param {"step" | "nearest-thumb"} params.seekButtonBehaviour
     */
    onSeekClick(params = {}) {
        const { direction = "right", seekButtonBehaviour = RULER_OPTIONS.seekButtonBehaviour } = params;
        const { timelineMode, } = this.props;
        const { selectedSliders, timelineTile } = this.state;
        const { sliders, stepper, timelineSnapPoints } = timelineTile;

        if (seekButtonBehaviour === "step") {
            const step = direction === "left" ? -1 : 1;
            this.adjustPlayheadStepBy(step);
        } else if (seekButtonBehaviour === "nearest-thumb") {
            const result = movePlayheadToNearestThumb({
                direction,
                selectedSliders,
                sliders,
                stepper,
                timelineSnapPoints,
                timelineMode,
            });
            if (result.moveSlidersResult && result.timelineSnapPointsResult) {
                this.dispatchTimelineTile(
                    {
                        type: TIMELINE_TILE_ACTIONS.MERGE_STATE,
                        payload: {
                            sliders: result.moveSlidersResult.sliders,
                            matchingSnapPoints: result.moveSlidersResult.matchingSnapPoints,
                            timelineSnapPoints: result.timelineSnapPointsResult,
                        },
                    },
                    () => {
                        this.autoScrollPlayhead({
                            positionToUse: "position",
                            autoscrollType: "nearest-thumb",
                        });
                    }
                );
            }
            if (result.selectedSlidersResult) {
                this.setSelectedSliders({
                    selectedSliders: result.selectedSlidersResult,
                });
            }
        }
    }

    onContainerTransitionEnd(event) {
        if (event.target === event.currentTarget) {
            this.setState({ animateTimelineContainer: false });
        }
    }

    /**
     * @param {ScrollToOptions} scrollOptions 
     */
    rulerScrollBy(scrollOptions) {
        if (this.timelineInnerRef.current) {
            this.timelineInnerRef.current.scrollBy(scrollOptions);
        }
    }

    splitHandler() {
        const { selectedSliders, timelineTile } = this.state;
        const { project, timelineMode, } = this.props;
        const { sliders, timelinePlot, tracks, } = timelineTile;

        const selectedToSplit = [];
        const thumb = sliders[playheadId].enterStart;
        let { position } = thumb;
        if (thumb.trackPosition) {
            position = thumb.trackPosition;
        }
        const { step } = position;

        const splitTime = step * RULER_OPTIONS.interval;
        const { OBJECT, AUDIO, VIDEO } = SLIDER_TYPES;

        selectedSliders.forEach(({ sliderId }) => {
            // Getting slider data of selected slider
            const { sliderType, id, enterStart, exitEnd } = sliders[sliderId] || {};
            const isTimelineSlider =
                sliderType === OBJECT || sliderType === AUDIO || sliderType === VIDEO;

            if (
                timelineMode === TIMELINE_MODES.SUBTITLE &&
                sliderType === SLIDER_TYPES.SUBTITLE &&
                enterStart.position.step < step &&
                exitEnd.position.step > step
            ) {
                /** @type {Slider} */
                const slider = sliders[sliderId];
                selectedToSplit.push({
                    subtitleId: slider.subtitleId,
                    container: "subtitle",
                });
            }

            if (
                timelineMode === TIMELINE_MODES.MAIN &&
                isTimelineSlider &&
                sliderId === id &&
                enterStart.position.step < step &&
                exitEnd.position.step > step
            ) {
                // checking if slider can be split
                const container =
                    sliderType === OBJECT
                        ? "workspaceItems"
                        : sliderType === VIDEO
                            ? "workspaceBG"
                            : "audios";
                // Pushing selected slider to be splitted.
                selectedToSplit.push({
                    id: sliderId,
                    container,
                });
            }
        });

        if (
            timelineMode === TIMELINE_MODES.SUBTITLE
            && !selectedToSplit.length
            && this.timelineInnerRef.current
        ) {
            const timelineScrollX = this.timelineInnerRef.current.scrollLeft;

            /** @type {IterViewportSlidersCallback} */
            const findSliderToSplit = (currentSlider) => {
                const { subtitleId, enterStart, exitEnd } = currentSlider;
                if (enterStart.position.step < step && exitEnd.position.step > step) {
                    selectedToSplit.push({
                        subtitleId,
                        container: "subtitle",
                    });
                }
                return true;
            };

            iterViewportSliders({
                callback: findSliderToSplit,
                checkItemIds: false,
                checkSubtitleIds: true,
                sliders,
                viewX: timelineScrollX,
                viewY: 0,
                viewWidth: timelinePlot.width,
                viewHeight: 0,
                tracks,
                tracksToCheck: [TRACK_TYPES.OBJECT, TRACK_TYPES.AUDIO],
                axis: 1,
            });
        }

        const ItemToUpdate = [];

        if (selectedToSplit.length) {
            selectedToSplit.forEach((selectedItem) => {
                if (selectedItem.container === "subtitle") {
                    const { subtitleId } = selectedItem;
                    const subtitleItem = project.getIn([
                        "subtitle",
                        "data",
                        subtitleId.dropId,
                        subtitleId.id,
                    ]);
                    const targetItem = project.getIn([
                        subtitleId.itemContainer,
                        subtitleId.itemId,
                    ]);
                    if (subtitleItem && targetItem) {
                        const splitResult = splitSubtitle({
                            splitTime,
                            subtitleItem,
                            subtitleId,
                            targetItem,
                        });
                        if (splitResult.left && splitResult.right) {
                            ItemToUpdate.push(
                                {
                                    container: "subtitleData",
                                    id: splitResult.left.get("id"),
                                    dropId: subtitleId.dropId,
                                    langId: subtitleId.langId,
                                    timelineId: `${subtitleId.itemId}-${subtitleId.dropId}-${splitResult.left.get("id")}`,
                                    toUpdate: splitResult.left.toJS(),
                                },
                                {
                                    container: "subtitleData",
                                    id: splitResult.right.get("id"),
                                    dropId: subtitleId.dropId,
                                    langId: subtitleId.langId,
                                    timelineId: `${subtitleId.itemId}-${subtitleId.dropId}-${splitResult.right.get("id")}`,
                                    isAdd: true,
                                    isSplit: true,
                                    newItemData: splitResult.right.toJS(),
                                }
                            );
                        }
                    }
                } else {
                    const item = project.getIn([
                        selectedItem.container,
                        selectedItem.id,
                    ]);
                    // If there is a selected slider in the playHead path
                    if (item?.get("id") === selectedItem.id) {
                        const { currentItem, newItem } = splitSliderHelper({
                            item,
                            splitTime,
                            container: selectedItem.container,
                        });

                        ItemToUpdate.push(
                            {
                                container: selectedItem.container,
                                id: currentItem.get("id"),
                                toUpdate: currentItem.toJS(),
                            },
                            {
                                container: selectedItem.container,
                                id: newItem.get("id"),
                                newItemData: newItem.toJS(),
                                isAdd: true,
                                isSplit: true,
                            }
                        );
                    }
                }
            });
        } else if (timelineMode === TIMELINE_MODES.MAIN) {
            project.get("workspaceItems").forEach((item) => {
                if (
                    item.get("enterStart") < splitTime &&
                    item.get("exitEnd") > splitTime
                ) {
                    const { currentItem, newItem } = splitSliderHelper({
                        item,
                        splitTime,
                        container: "workspaceItems",
                    });

                    ItemToUpdate.push(
                        {
                            container: "workspaceItems",
                            id: currentItem.get("id"),
                            toUpdate: currentItem.toJS(),
                        },
                        {
                            container: "workspaceItems",
                            id: newItem.get("id"),
                            newItemData: newItem.toJS(),
                            isAdd: true,
                            isSplit: true,
                        }
                    );
                }
            });

            // This loop is to split the audio and workspaceBG.
            ["audios", "workspaceBG"].forEach((container) => {
                project.get(container).forEach((item) => {
                    if (
                        item.get("playStart") < splitTime &&
                        item.get("playEnd") > splitTime
                    ) {
                        const { currentItem, newItem } = splitSliderHelper({
                            item,
                            splitTime,
                            container,
                        });

                        ItemToUpdate.push(
                            {
                                container,
                                id: currentItem.get("id"),
                                toUpdate: currentItem.toJS(),
                            },
                            {
                                container,
                                id: newItem.get("id"),
                                newItemData: newItem.toJS(),
                                isAdd: true,
                                isSplit: true,
                            }
                        );
                    }
                });
            });
        }

        if (ItemToUpdate.length) {
            this.props.updateTimelineTime({ toUpdate: ItemToUpdate });
        }
    }

    toggleSubtitleMode(e, switchTo) {
        const { timelineMode } = this.props;
        if (!Number.isFinite(switchTo)) {
            switchTo = timelineMode === TIMELINE_MODES.SUBTITLE ? TIMELINE_MODES.MAIN : TIMELINE_MODES.SUBTITLE;
        }
        if (switchTo === TIMELINE_MODES.SUBTITLE) {
            this.props.setLibraryPanel(PANEL.SUBTITLE, true);
        } else {
            this.props.setLibraryPanel(PANEL.TEXT, undefined, { retainExpand: true });
        }
    }

    addSubtitle() {
        const { project, } = this.props;
        const { timelineTile, } = this.state;

        const toUpdate = addSubtitleTimeline({
            project,
            timelineTile,
        });

        if (toUpdate) {
            this.props.updateTimelineTime({ toUpdate });
        }
    }

    render() {
        const { animatePlayhead, timelineTile, isSliderMultiSelect, selectedSliders, sliderMoved, animateTimelineContainer } = this.state;
        const { timelinePlot, sliders, stepper, tracks, matchingSnapPoints } = timelineTile;
        const { project,
            projectTimeLimit,
            isPlayAll,
            isPlayerLoaded,
            isWorkspaceDragging,
            playerShortcutName,
            shortcutName,
            timelineMode,
            isEnableVersionHistory
        }
            = this.props;
        const { timelineResizeStatus, slidersDragStatus, timelineScrollStatus } = this.props;

        let isDragging = false;
        if (
            isPlayAll
            || this.props.swapDetails
            || isWorkspaceDragging
            || slidersDragStatus.isDragging
            || timelineResizeStatus.isDragging
            || timelineScrollStatus.isScrolling
            || isSliderMultiSelect
        ) {
            isDragging = true;
        }

        let allowPlayheadClick = !isDragging;
        if (isPlayerLoaded) {
            allowPlayheadClick = true;
        }

        let allowTimelineMouseMove = !isDragging;
        if (isPlayerLoaded) {
            allowTimelineMouseMove = true;
        }

        /** @todo remove eslint disable comment after canUseMagnet & canDeleteGap are implemented */
        /* eslint-disable-next-line */
        const { canUseMagnet, canDeleteGap } = this.checkGapStatus.executor([selectedSliders, sliders], { selectedSliders, sliders });

        let timelineCls = "timeline-tool-bar";
        if (isWorkspaceDragging) {
            timelineCls = `${timelineCls} tc--block-events`;
        }
        if (animateTimelineContainer) {
            timelineCls = `${timelineCls} tc--animate-container`;
        }

        return (
            <TimelineScrollProvider>
                <WaveformManager>
                    <TimelineContainer
                        className={timelineCls}
                        onTransitionEnd={this.onContainerTransitionEnd}
                        $timelinePlot={timelinePlot}
                    >
                        {!isPlayAll && !isEnableVersionHistory && (
                            <TimelineResize
                                className={timelineResizeStatus.isDragging ? "tr--is-dragging" : ""}
                                onPointerDown={!isDragging ? this.handleResizeMouseDown : undefined}
                            />
                        )}
                        <PlayerControls
                            timelineMode={timelineMode}
                            toggleSubtitleMode={this.toggleSubtitleMode}
                            timeScale={stepper.timeScale}
                            onTimeScaleChange={!isDragging ? this.changeTimeScale : undefined}
                            timelineHeight={timelinePlot.height}
                            stopTimelineResize={this.stopTimelineResize}
                            adjustPlayheadStepBy={this.adjustPlayheadStepBy}
                            onSeekClick={this.onSeekClick}
                            playheadSlider={sliders[playheadId]}
                            rulerSlider={sliders[rulerId]}
                            shortcutName={playerShortcutName}
                            selectedSliders={selectedSliders}
                            isShowPlayControls={this.state.isShowPlayControls}
                            splitHandler={this.splitHandler}
                            addSubtitle={this.addSubtitle}
                            onDropdownClick={this.handleDropdownClick}
                            setOuterLayerVisible={this.props.setOuterLayerVisible}
                        />
                        <TimelineInner
                            ref={this.timelineInnerRef}
                            height={`calc(100% - ${PLAYER_CONTROLS_HEIGHT}px)`}
                            onPointerMove={allowTimelineMouseMove ? this.handleTimelineMouseMove : undefined}
                            onPointerLeave={allowTimelineMouseMove ? this.handleTimelineMouseLeave : undefined}
                        >
                            <Ruler
                                stepper={stepper}
                                sliders={sliders}
                                selectedSliders={selectedSliders}
                                timelinePlotWidth={timelinePlot.width}
                                projectTimeLimit={projectTimeLimit}
                                placePlayhead={allowPlayheadClick ? this.placePlayheadOnClick : undefined}
                                rulerScrollBy={this.rulerScrollBy}
                            />
                            <TrackList
                                trackListContainerRef={this.trackListContainerRef}
                                timelineInnerRef={this.timelineInnerRef}
                                tracks={tracks}
                                stepper={stepper}
                                sliders={sliders}
                                selectedSliders={selectedSliders}
                                timelinePlot={timelinePlot}
                                selectSlider={this.selectSlider}
                                isDragging={isDragging}
                                sliderMoved={sliderMoved}
                                isSliderDragging={slidersDragStatus.isDragging}
                                updateIsSliderMultiSelect={this.updateIsSliderMultiSelect}
                                matchingSnapPoints={matchingSnapPoints}
                                handleGapRemove={this.handleGapRemove}
                                canUseMagnet={canUseMagnet}
                                projectDetails={project}
                                shortcutName={shortcutName}
                                setSelectedSliders={this.setSelectedSliders}
                            />
                            {sliders[indicatorId].isVisible && (
                                <Playhead
                                    isDragging={isDragging}
                                    thumb={sliders[indicatorId].enterStart}
                                />
                            )}
                            <Playhead
                                isDragging={isDragging}
                                thumb={sliders[playheadId].enterStart}
                                startPlayheadThumbDrag={!isDragging ? this.selectSlider : undefined}
                                animatePosition={animatePlayhead}
                                onTransitionEnd={() => this.setState({ animatePlayhead: false })}
                                matchingSnapPoints={matchingSnapPoints}
                                sliders={sliders}
                                selectedSliders={selectedSliders}
                                splitHandler={this.splitHandler}
                            />
                        </TimelineInner>
                    </TimelineContainer>
                </WaveformManager>
            </TimelineScrollProvider >
        );
    }
}

TimelineComponent.propTypes = {
    timelineMode: PropTypes.number,
    setLibraryPanel: PropTypes.func,
    project: PropTypes.object,
    timelineResetToken: PropTypes.string,
    playheadResetToken: PropTypes.string,
    timelineSelectionAutoScrollToken: PropTypes.string,
    playhead: PropTypes.number,
    sideBarMenuWidth: PropTypes.number,
    isWorkspaceDragging: PropTypes.bool,
    isPlayAll: PropTypes.bool,
    t1: PropTypes.object,
    isPlayerLoaded: PropTypes.bool,
    selectedItems: PropTypes.object,
    selectedAudios: PropTypes.object,
    selectedSubtitles: PropTypes.object,
    projectTimeLimit: PropTypes.number,
    setTimelineHeight: PropTypes.func,
    setPlayHeadTime: PropTypes.func,
    updateTimelineTime: PropTypes.func,
    setSelectedItems: PropTypes.func,
    initiateTimelineResize: PropTypes.func,
    timelineResizeStatus: PropTypes.object,
    initiateSlidersDrag: PropTypes.func,
    slidersDragStatus: PropTypes.object,
    startTimelineScroll: PropTypes.func,
    stopTimelineScroll: PropTypes.func,
    timelineScrollStatus: PropTypes.object,
    shortcutName: PropTypes.string,
    playerShortcutName: PropTypes.string,
    swapDetails: PropTypes.object,
    isEnableVersionHistory: PropTypes.bool,
    setOuterLayerVisible: PropTypes.func
}

const mapStateToProps = (state) => {
    const propertyPanel = state.app.get("propertyPanel");
    const sideBarMenuWidth = (
        state.app.getIn(["workspaceStage", "left", "menu"])
        + state.app.getIn(["workspaceStage", "left", "menuBorder"])
    );
    const isWorkspaceDragging = (
        state.app.get("isWorkspaceDragging")
        && state.app.getIn(["transformStatus", "transforming"])
    );

    let { projectTimeLimit } = state.userDetails;
    if (!Number.isFinite(projectTimeLimit)) {
        projectTimeLimit = -1;
    }

    const localSubtitle = state.projectDetails.get("localSubtitle");
    let timelineMode = TIMELINE_MODES.MAIN;
    if (localSubtitle.size && propertyPanel.get("selectedPanel") === PANEL.SUBTITLE) {
        timelineMode = TIMELINE_MODES.SUBTITLE;
    }

    return {
        timelineMode,
        project: state.projectDetails,
        timelineResetToken: state.app.get("timelineResetToken"),
        playheadResetToken: state.app.get("playheadResetToken"),
        timelineSelectionAutoScrollToken: state.app.get("timelineSelectionAutoScrollToken"),
        playhead: state.app.get("playhead"),
        sideBarMenuWidth,
        isWorkspaceDragging,
        isPlayAll: state.app.get("isPlayAll"),
        t1: state.app.get("t1"),
        isPlayerLoaded: state.app.get("isLoaded"),
        selectedItems: state.app.get("selectedItems"),
        selectedAudios: state.app.get("selectedAudios"),
        selectedSubtitles: state.app.get("selectedSubtitles"),
        swapDetails: state.app.get("swapDetails"),
        projectTimeLimit,
        isEnableVersionHistory: state.app.get("isEnableVersionHistory"),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setLibraryPanel: (data, isExpand, context) => dispatch(setPropertyPanel(data, isExpand, context)),
    setTimelineHeight: (data) => dispatch(setTimelineHeight(data)),
    setPlayHeadTime: (data) => dispatch(setPlayHeadTime(data)),
    updateTimelineTime: (data) => dispatch(updateTimelineTime(data)),
    setSelectedItems: (data) => dispatch(setSelectedItems(data)),
});

const Timeline = connect(mapStateToProps, mapDispatchToProps)(TimelineComponent);

const TimelineWithEvents = (props) => {
    return (
        <MouseDrag>
            {(timelineResizeProps) => (
                <MouseDrag>
                    {(sliderDragProps) => (
                        <AutoScroll>
                            {(timelineScrollProps) => (
                                <Timeline
                                    {...props}
                                    initiateTimelineResize={timelineResizeProps.initiateDrag}
                                    timelineResizeStatus={timelineResizeProps.dragStatus}
                                    initiateSlidersDrag={sliderDragProps.initiateDrag}
                                    slidersDragStatus={sliderDragProps.dragStatus}
                                    startTimelineScroll={timelineScrollProps.startScroll}
                                    stopTimelineScroll={timelineScrollProps.stopScroll}
                                    timelineScrollStatus={timelineScrollProps.scrollStatus}
                                />
                            )}
                        </AutoScroll>
                    )}
                </MouseDrag>
            )}
        </MouseDrag>
    )
}

export default TimelineWithEvents;
