import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

import { randomString } from "../../helper/uuid";
import btoaConvert from "../../helper/btoaConvert";
import Action from "../../common-components/Action";
import useNotify from "../../helper/hooks/useNotify";
import { Modal } from "../../common-components/modal";
import useScreenshot from "../../helper/hooks/useScreenshot";
import AppConfig, { THUMBNAIL_URL } from "../../constants/config";
import { updateProjectData } from "../../redux/actions/timelineUtils";
import { blobToDataURI, base64ToFile } from "../../helper/blobConvertion";
import Spinner, { SPINNER_TYPE } from "../../common-components/Spinner";
import { getucm, preflightCheck, updateBlob } from "../../redux/actions/appUtils";


const Wrapper = styled.div`
  padding: 20px;
`;

const Root = styled.div`
    height: 300px;
    position: relative;
`;

const SmartThumbnail = (props) => {
    const [isChangingThumbnail, setIsChangingThumbnail] = useState(false);
    const { app, projectDetails } = useSelector((store) => store);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [img, setImg] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const screenshot = useScreenshot({ forInit: false });
    const { takeScreenshot } = screenshot;
    const { success, warn } = useNotify();

    const onScreenshotSuccess = async (arg) => {
        const base64 = await blobToDataURI(arg);
        setImg(base64);
        setLoading(false);
    }

    const onScreenshotFailed = () => {
        setImg(null);
        warn(`Upload Failed`);
        props.setSmartModal(false);
    }

    useEffect(() => {
        const playhead = app.get("playhead");
        const projectThumbnail = projectDetails.get("thumbnail");
        if (projectThumbnail) {
            setIsChangingThumbnail(true);
            setImg(`${THUMBNAIL_URL}${projectThumbnail}`);
            setLoading(false);
        } else {
            takeScreenshot({
                onScreenshotFailed,
                onScreenshotSuccess,
                playhead
            });
        }
    }, [projectDetails, takeScreenshot]);


    const selectFiles = () => {
        fileInputRef.current.click();
    };

    const fileUpload = async (fileObj) => {
        try {
            const { type } = fileObj;
            const randomKey = randomString();
            const chunkSize = 5 * 1024 * 1024;
            const totalParts = Math.ceil(fileObj.size / chunkSize);
            const filename = `${randomKey}.${fileObj.name.substr(
                fileObj.name.lastIndexOf(".") + 1
            )}`;
            const payload = {
                filename: btoaConvert(unescape(encodeURIComponent(filename))),
                partcount: btoaConvert(totalParts),
                fileType: type
            };
            const preFlightResponse = await preflightCheck(payload);
            if (preFlightResponse.sucess) {
                const arr = new Array(totalParts).fill("");
                const promises = arr.map(async (_ele, partNumber) => {
                    const start = partNumber * chunkSize;
                    const end = Math.min(start + chunkSize, fileObj.size);
                    const partBlob = fileObj.slice(start, end);
                    const result = await updateBlob(partBlob, preFlightResponse.part_urls[partNumber], type);
                    return result;
                });
                await Promise.all(promises);
                const finalPayload = {
                    object_key: btoaConvert(preFlightResponse.object_key),
                    uploadid: btoaConvert(preFlightResponse.upload_id),
                };
                const getucmResponse = await getucm(finalPayload);
                const { Key } = getucmResponse.response;
                if (getucmResponse.sucess && Key) {
                    const thumbnailKey = (Key.startsWith("a/u/")) ? Key.slice(4) : Key;
                    return thumbnailKey;
                }
                return false;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const getSmartThumbnail = async () => {
        if (!isLoading) {
            setLoading(true);
            const file = base64ToFile(img, `screenshotThumb.png`, `image/png`);
            const thumbnailKey = await fileUpload(file);
            if (thumbnailKey) {
                const playHeadLocation = app.get("playhead");
                dispatch(updateProjectData({ thumbnail: thumbnailKey }));
                dispatch(updateProjectData({
                    smartThumbnail: {
                        thumbnail: true,
                        timeframe: playHeadLocation,
                    }
                }));
            }
            setLoading(false);
            success(`Saved Successfully`);
            props.setSmartModal(false);
        }
    };

    const handleFileUpload = async (e) => {
        if (!isLoading) {
            setLoading(true);
            const file = e.target.files[0];
            if (!file) {
                return true;
            }
            const thumbnailKey = await fileUpload(file);
            if (thumbnailKey) {
                dispatch(updateProjectData({ thumbnail: thumbnailKey }));
                dispatch(updateProjectData({
                    smartThumbnail: {
                        thumbnail: true,
                        timeframe: -1
                    }
                }));
            }
            setLoading(false);
            success(`Saved Successfully`);
            props.setSmartModal(false);
        }
        return true;
    };

    const changeThumbnail = async () => {
        setImg(null);
        setLoading(true);
        const playhead = app.get("playhead");
        takeScreenshot({
            onScreenshotFailed,
            onScreenshotSuccess,
            playhead
        });
        setIsChangingThumbnail(false);
    }

    return (
        <Modal showModal={props.isModalOpen} width="512px" onClose={() => props.setSmartModal(false)}>
            <Wrapper>
                <Root>
                    {isLoading ? <Spinner type={SPINNER_TYPE.RING_SPINNER} spinnerHeight={"100%"} /> : null}
                    {img ? <img
                        src={img}
                        alt=""
                        style={{ width: "100%", height: "100%", objectFit: "contain", position: "absolute", left: 0, top: 0 }}
                    /> : null}
                </Root>
                {!isChangingThumbnail ? (
                    <> <Action
                        text={"Set Current Frame as Thumbnail"}
                        onClick={getSmartThumbnail}
                    />
                        <Action
                            text={"Upload Thumbnail"}
                            onClick={selectFiles}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                            accept={AppConfig.UPLOADS.UP_IMG}
                        /> </>
                ) : (
                    <Action
                        text={"Change Thumbnail"}
                        onClick={changeThumbnail}
                    />
                )}
            </Wrapper>
        </Modal>
    );
};

SmartThumbnail.propTypes = {
    isModalOpen: PropTypes.bool,
    setSmartModal: PropTypes.func
};

export default SmartThumbnail;
