import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { clamp, scaleWithinRange } from "../timeline/timeline-helper";
import {
  InputSlider,
  InputSliderContainer,
  ValueContainerInput,
} from "./zoom-components";

const Slider = (props) => {
  const valueRef = useRef(null);
  const [sliderValue, setSliderValue] = useState(props.value);
  const rangeValue = sliderValue;
  let { background } = props;
  if (props.isChangeBg) {
    const { progressBackground, doubleSideMedian = 0 } = props;
    const secondaryBg = background;
    let startPercent = 0;
    let endPercent = 100;

    if (props.doubleSide) {
      const medianPercent = scaleWithinRange({
        fromRange: { start: props.min, end: props.max },
        toRange: { start: 0, end: 100 },
        num: doubleSideMedian,
      });
      const valuePercent = scaleWithinRange({
        fromRange: { start: props.min, end: props.max },
        toRange: { start: 0, end: 100 },
        num: rangeValue,
      });

      if (valuePercent <= medianPercent) {
        startPercent = valuePercent;
        endPercent = medianPercent;
      } else {
        startPercent = medianPercent;
        endPercent = valuePercent;
      }
    } else {
      endPercent = ((rangeValue - props.min) / (props.max - props.min)) * 100;
    }

    background = "linear-gradient(to right,";
    background = `${background} ${secondaryBg} 0%, ${secondaryBg} ${startPercent}%,`;
    background = `${background} ${progressBackground} ${startPercent}%, ${progressBackground} ${endPercent}%,`;
    background = `${background} ${secondaryBg} ${endPercent}%, ${secondaryBg} 100%)`;
  }

  const validateSliderValue = (value) => {
    const inputVal = Number(value);
    return inputVal >= props.inputMin && inputVal <= props.inputMax;
  };

  const onValueChange = (e, isRangeSlider = true) => {
    let inputVal = Number(e.target.value);
    const inputFormat = /^[\d]*$/;
    if (
      Number.isNaN(inputVal) ||
      (!isRangeSlider && !inputFormat.test(e.target.value))
    ) {
      // input values other than integers e.g.: 108/ or 10.8
      setSliderValue(sliderValue);
      return;
    }
    if (!isRangeSlider && props.showValue) {
      inputVal = inputVal > props.inputMax ? props.inputMax : inputVal; // keep input value under max but allow under min for typing
    } else if (isRangeSlider) {
      inputVal = clamp(inputVal, props.inputMin, props.inputMax);
    }
    setSliderValue(inputVal);
    const isValid = validateSliderValue(inputVal);
    if (isValid) {
      props.onChangeSliderValue(e, inputVal, !isRangeSlider);
    }
  };

  const onSubmitValue = (e) => {
    if (e.keyCode === 13) {
      let inputVal = Number(sliderValue);
      inputVal = clamp(inputVal, props.inputMin, props.inputMax);

      const isValid = validateSliderValue(inputVal);
      if (isValid) {
        props.onChangeSliderValue(e, inputVal, false);
        e.target.blur();
      }
    }
  };

  const onBlur = (e, isSubmit) => {
    if (isSubmit) {
      return;
    }
    let inputVal = Number(sliderValue);
    inputVal = clamp(inputVal, props.inputMin, props.inputMax);

    const isValid = validateSliderValue(inputVal);
    if (isValid) {
      setSliderValue(inputVal);
      props.onChangeSliderValue(e, inputVal, false);
    }
  };

  useEffect(() => {
    setSliderValue(props.value);
  }, [props.value]);

  const onSliderMouseUp = (e) => {
    if (props.onMouseUp) {
      props.onMouseUp(e, sliderValue);
    }
  };

  const onSliderMouseDown = (e) => {
    if (props.onMouseDown) {
      props.onMouseDown(e, sliderValue);
    }
  };

  return (
    <>
      <InputSliderContainer
        className={`${props.className || ""} zoom-slider--slider-container`}
        data-tooltip-id={props.tooltipId}
        min={props.min}
        max={props.max}
        value={sliderValue}
        thumb={props.thumb}
        showToolTip={props.showToolTip}
        tooltipBottomOffset={props.tooltipBottomOffset}
      >
        <InputSlider
          onChange={(e) => onValueChange(e, true)}
          min={props.min}
          max={props.max}
          step={props.step}
          value={sliderValue}
          width={props.width}
          height={props.height}
          background={background}
          borderRadius={props.borderRadius}
          margin={props.margin}
          thumb={props.thumb}
          hover={props.hover}
          onPointerUp={onSliderMouseUp}
          onPointerDown={onSliderMouseDown}
        />
      </InputSliderContainer>
      {props.showValue ? (
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <ValueContainerInput
            autoComplete="off"
            onClick={props.onInputFocus}
            onFocus={props.onInputFocus}
            input={props.input}
            type={"text"}
            ref={valueRef}
            value={props.isFocused ? sliderValue : `${sliderValue}%`}
            onKeyUp={onSubmitValue}
            onChange={(e) => onValueChange(e, false)}
            onBlur={onBlur}
          />
        </form>
      ) : null}
    </>
  );
};
Slider.propTypes = {
  value: PropTypes.number.isRequired,
  background: PropTypes.string,
  inputMax: PropTypes.number,
  inputMin: PropTypes.number,
  progressBackground: PropTypes.string,
  isChangeBg: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChangeSliderValue: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  showValue: PropTypes.bool,
  showToolTip: PropTypes.bool,
  tooltipBottomOffset: PropTypes.string,
  onInputFocus: PropTypes.func,
  isFocused: PropTypes.func,
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  thumb: PropTypes.object,
  hover: PropTypes.object,
  input: PropTypes.object,
  doubleSide: PropTypes.bool,
  doubleSideMedian: PropTypes.number,
  tooltipId: PropTypes.string,
  className: PropTypes.string,
};

export default Slider;
