import { API, API_URL } from "../../constants/config";
import triggerSimpleAjax from "../../helper/httpHelper";

export function updateEnterprise(reqBody = {}) {
  const apiUrl = API_URL + API.ENTERPRISE_ONBOARDING;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      false,
      reqBody,
      (response) => {
        try {
          if (!response || typeof response !== "object") {
            rej(new Error("Invalid response from server"));
            return;
          }

          res(response);
        } catch (error) {
          rej(error);
        }
      },
      (error) => {
        rej(error);
      }
    );
  });
}
export function fetchCountries() {
  const apiUrl = API_URL + API.COUNTRY;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "GET",
      false,
      {},
      (response) => res(response),
      (error) => rej(error),
      false
    );
  });
}
