/* eslint-disable prefer-template, no-bitwise, one-var */

export function hex2rgb(str) {
  const arr1 = [];
  arr1[0] = parseInt(str.substring(0, 2), 16);
  arr1[1] = parseInt(str.substring(2, 4), 16);
  arr1[2] = parseInt(str.substring(4, 6), 16);
  return arr1;
}

export function rgb2hsl(color) {
  const r = color[0] / 255;
  const g = color[1] / 255;
  const b = color[2] / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s;
  const l = (max + min) / 2;
  if (max === min) {
    h = 0; // achromatic
    s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return [h, s, l];
}

export function hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

export function hsl2rgb(color) {
  let l = color[2];

  if (color[1] === 0) {
    l = Math.round(l * 255);
    return [l, l, l];
  }

  const s = color[1];
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  const r = hue2rgb(p, q, color[0] + 1 / 3);
  const g = hue2rgb(p, q, color[0]);
  const b = hue2rgb(p, q, color[0] - 1 / 3);
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function rgb2hex(red, green, blue) {
  const rgb = blue | (green << 8) | (red << 16);
  return "#" + (0x1000000 + rgb).toString(16).slice(1);
}

export function rgbString2hex(rgb) {
  rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgb && rgb.length === 4) ? "#" +
    ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}

export function _iHSL10(str1, hue, sat, light) {
  const color1 = hex2rgb(str1);
  const hsl1 = rgb2hsl(color1);
  hsl1[0] = Number(hsl1[0]) - Number(hue);
  hsl1[1] = Number(hsl1[1]) - Number(sat);
  hsl1[2] = Number(hsl1[2]) - Number(light);
  hsl1[0] = hsl1[0] > 1 ? 1 : hsl1[0];
  hsl1[1] = hsl1[1] > 1 ? 1 : hsl1[1];
  hsl1[2] = hsl1[2] > 1 ? 1 : hsl1[2];
  hsl1[0] = hsl1[0] < 0 ? 0 : hsl1[0];
  hsl1[1] = hsl1[1] < 0 ? 0 : hsl1[1];
  hsl1[2] = hsl1[2] < 0 ? 0 : hsl1[2];
  const arr3 = hsl2rgb(hsl1);
  return rgb2hex(arr3[0], arr3[1], arr3[2]);
}
