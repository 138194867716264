import content from "../../constants/content";
import useNotify from "./useNotify";

const copyToClipBoard = (txt) => {
    return new Promise((res, rej) => {
        navigator.clipboard
            .writeText(txt)
            .then(() => {
                res(true);
            }).catch(() => {
                rej()
            });
    })
}

const useCopy = () => {
    const { success, warn } = useNotify();

    const copy = async (url) => {
        await copyToClipBoard(url).then(() => {
            success(content.LINK_COPIED);
        }).catch(() => {
            warn(content.LINK_NOT_COPIED);
        })
    }

    return copy;
};

export default useCopy;
