/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { APP_Z_INDICES, PORTAL_ID } from "../constants";
import { font } from "../constants/font";

const ToolTipPositionStyles = css`
  &.default {
    transform: translateY(-50%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &.top {
    transform: translate(-50%, -100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.top-left {
    transform: translateY(-100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      right: 85%;
    }
  }

  &.top-right {
    transform: translate(-100%, -100%);

    &::after {
      border-top-color: ${(props) => props.theme.tooltipBorderColor};
      top: 100%;
      left: 85%;
    }
  }

  &.right {
    transform: translateY(-50%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &.right-top {
    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      top: 0;
      right: 100%;
      transform: translateY(85%);
    }
  }

  &.right-bottom {
    transform: translateY(-100%);

    &::after {
      border-right-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 15%;
      right: 100%;
      transform: translateY(-15%);
    }
  }

  &.left {
    transform: translate(-100%, -50%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }

  &.left-top {
    transform: translateX(-100%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      top: 0;
      left: 100%;
      transform: translateY(85%);
    }
  }

  &.left-bottom {
    transform: translate(-100%, -100%);

    &::after {
      border-left-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 15%;
      left: 100%;
      transform: translateY(-15%);
    }
  }

  &.bottom {
    transform: translateX(-50%);

    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bottom-left {
    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      right: 85%;
    }
  }

  &.bottom-right {
    transform: translateX(-100%);

    &::after {
      border-bottom-color: ${(props) => props.theme.tooltipBorderColor};
      bottom: 100%;
      left: 85%;
    }
  }
`;
const StyledTooltip = styled.div.attrs(props => ({
  style: {
    height: props.height || '25px !important',
    width: props.width || 'max-content !important',
    font: props.font || `${font.normalBase} !important`
  }
}))`
  position: absolute;
  display: flex;
  align-items: center;
  user-select: none;
  z-index: ${APP_Z_INDICES.customtooltip.default};
  background: ${(props) => props.theme.tooltipBorderColor} !important;
  color: white !important;
  padding: 5px 8px;
  border-radius: 0.25rem;
  border: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in;

  &::after {
    content: "";
    border: 3px solid transparent;
    position: absolute;
  }

  ${ToolTipPositionStyles}
`;
const ShortcutContainer = styled.span`
  display: flex;
  margin-left: 8px;
  gap: 4px;
`;
const Shortcut = styled.span`
  height: 16px;
  background: ${(props) => props.theme.tooltipTextColor};
  padding: 2px 4px;
  font-size: 11px;
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

/**
 * @description Checks if the text inside the element is overflowed or not
 * @param {string} elementIdentifier
 * @returns {boolean} is text overflowed
 */
const isTextEllipses = (elementIdentifier) => {
  const textElement = document.querySelector(
    `[data-text-id="${elementIdentifier}"]`
  );
  if (textElement && textElement.offsetWidth < textElement.scrollWidth) {
    return true;
  }
  return false;
};

/**
 * @description Calculates where to place the tooltip
 * @param {Element} targetElement - target element for tooltip
 * @param {string} position - tooltip position
 * @param {Number} offset - how much px away from element
 * @returns {Object} points - tooltip points
 */
const calculateTooltipPoints = (targetElement, position, offset) => {
  const targetElementRect = targetElement.getBoundingClientRect();
  const points = {
    top: 0,
    left: 0,
  };

  // calculating top and left of tooltip based on parent element
  const scrollDistance = window.scrollY;
  switch (position) {
    case "top":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = (targetElementRect.left + targetElementRect.right) / 2;
      break;
    case "top-left":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = targetElementRect.left;
      break;
    case "top-right":
      points.top = targetElementRect.top + scrollDistance - offset;
      points.left = targetElementRect.right;
      break;
    case "right":
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "right-top":
      points.top = targetElementRect.top + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "right-bottom":
      points.top = targetElementRect.bottom + scrollDistance;
      points.left = targetElementRect.right + offset;
      break;
    case "left":
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "left-top":
      points.top = targetElementRect.top + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "left-bottom":
      points.top = targetElementRect.bottom + scrollDistance;
      points.left = targetElementRect.left - offset;
      break;
    case "bottom":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = (targetElementRect.left + targetElementRect.right) / 2;
      break;
    case "bottom-left":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = targetElementRect.left;
      break;
    case "bottom-right":
      points.top = targetElementRect.bottom + offset + scrollDistance;
      points.left = targetElementRect.right;
      break;
    default:
      points.top =
        (targetElementRect.top + targetElementRect.bottom) / 2 + scrollDistance;
      points.left = targetElementRect.right + offset;
  }

  return points;
};

/**
 * Creates a tooltip for the target element
 * @param {object} props
 * @param {string} props.tooltipId To identify target element - Recommended way --> tt-componentname-uniqueIdentifier
 * @param {string} props.dataTooltip ID for Formatted Message
 * @param {"top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right" | "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom"} props.tooltipPosition Position of tooltip
 * @param {number} props.offset how much px away from target element, default to 10
 * @param {Array} props.shortcut shortcut keys for target element if any
 * @param {number} props.delayVisibilityBy Delay (in ms) to make the tooltip visible - Default 500ms
 * @param {boolean} props.nonFormattedMessage is dataTooltip non formatted message? - Default to false
 * @param {string} props.checkTextOverflowFor data-text-id for text to conditionally show tooltip based on text overflowed or not. Can be same as tooltipId.
 * @param {boolean} props.hideOnClick defaults to true
 */
const CustomTooltipComponent = (props) => {
  const {
    tooltipId,
    dataTooltip,
    tooltipPosition = "right",
    offset = 10,
    shortcut,
    delayVisibilityBy = 500,
    nonFormattedMessage = false,
    checkTextOverflowFor = null,
    hideOnClick = true,
    width,
    height,
    font,
    children,
    visible = true
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPoints, setTooltipPoints] = useState({});
  const [adjustedTooltipPosition, setAdjustedTooltipPosition] = useState(null);
  const [blockTooltip, setBlockTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const targetElement = document.querySelector(
      `[data-tooltip-id="${tooltipId}"]`
    ); // select targeted element
    let delayTimer;

    const handleShowTooltip = () => {
      // Calculating only on mouse hover on targeted element
      const points = calculateTooltipPoints(
        targetElement,
        tooltipPosition,
        offset
      );
      setTooltipPoints(points);
      setShowTooltip(true);

      // Logic to flip tooltip position if tooltip overflows out of window
      if (tooltipRef.current && !adjustedTooltipPosition) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const MARGIN = 5;
        if (tooltipRect.x + tooltipRect.width + MARGIN >= windowWidth) {
          setAdjustedTooltipPosition("left");
          const points = calculateTooltipPoints(targetElement, "left", offset);
          setTooltipPoints(points);
        } else if (tooltipRect.x - MARGIN <= 0) {
          setAdjustedTooltipPosition("right");
          const points = calculateTooltipPoints(targetElement, "right", offset);
          setTooltipPoints(points);
        } else if (
          tooltipRect.y + tooltipRect.height + MARGIN >=
          windowHeight
        ) {
          setAdjustedTooltipPosition("top");
          const points = calculateTooltipPoints(targetElement, "top", offset);
          setTooltipPoints(points);
        } else if (tooltipRect.y - MARGIN <= 0) {
          setAdjustedTooltipPosition("bottom");
          const points = calculateTooltipPoints(
            targetElement,
            "bottom",
            offset
          );
          setTooltipPoints(points);
        }
      }

      if (checkTextOverflowFor !== null) {
        const textEllipses = isTextEllipses(checkTextOverflowFor);
        if (!textEllipses) {
          setBlockTooltip(true);
        }
      }

      delayTimer = setTimeout(() => {
        if (tooltipRef.current) tooltipRef.current.style.opacity = "1";
      }, delayVisibilityBy);
    };

    const handleHideTooltip = () => {
      clearTimeout(delayTimer);
      setShowTooltip(false);
      setAdjustedTooltipPosition(null);
    };

    if (targetElement) {
      targetElement.addEventListener("mouseenter", handleShowTooltip);
      targetElement.addEventListener("mouseleave", handleHideTooltip);
      if (hideOnClick) {
        targetElement.addEventListener("click", handleHideTooltip);
      }
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener("mouseenter", handleShowTooltip);
        targetElement.removeEventListener("mouseleave", handleHideTooltip);
        targetElement.removeEventListener("click", handleHideTooltip);
      }
      clearTimeout(delayTimer);
    };
  }, [
    adjustedTooltipPosition,
    checkTextOverflowFor,
    delayVisibilityBy,
    offset,
    tooltipId,
    tooltipPosition,
    hideOnClick,
  ]);

  const tooltipInformation = dataTooltip.includes("langId")
    ? dataTooltip.split("-")[1]
    : dataTooltip;
  const shouldShowTooltip = showTooltip && tooltipPoints && !blockTooltip && visible;

  const className = adjustedTooltipPosition || tooltipPosition || "default";
  const portalDOM = document.getElementById(PORTAL_ID);

  return (
    <>
      {children}
      {shouldShowTooltip &&
        portalDOM &&
        ReactDOM.createPortal(
          <StyledTooltip
            width={width}
            height={height}
            font={font}
            ref={tooltipRef}
            data-tooltip-for={tooltipId}
            className={className}
            style={{
              left: `${tooltipPoints.left}px`,
              top: `${tooltipPoints.top}px`,
            }}
          >
            {!nonFormattedMessage ? (
              <FormattedMessage id={tooltipInformation} />
            ) : (
              tooltipInformation
            )}
            {shortcut && (
              <ShortcutContainer>
                {shortcut.map((item, index) => (
                  <Shortcut key={index}>{item}</Shortcut>
                ))}
              </ShortcutContainer>
            )}
          </StyledTooltip>,
          portalDOM
        )}
    </>
  );
};

CustomTooltipComponent.propTypes = {
  tooltipId: PropTypes.string,
  dataTooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  offset: PropTypes.number,
  shortcut: PropTypes.array,
  delayVisibilityBy: PropTypes.number,
  nonFormattedMessage: PropTypes.bool,
  checkTextOverflowFor: PropTypes.string,
  hideOnClick: PropTypes.bool,
  children: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  font: PropTypes.string,
  visible: PropTypes.bool
};

export default CustomTooltipComponent;
