import React, { useLayoutEffect, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector, connect } from "react-redux";
import { IntlProvider } from "react-intl";
import Header from "./header";
import Footer from "./footer";
import {
  Container,
  LeftSection,
  MainPage,
  RightSection,
  InnerContainer,
  Loader,
  AnimoWrap,
} from "./animo-component";
import PlayerHeader from "./playerHeader";
import UserAccess from "./userAccess";
import {
  setProjectDetails,
  getUserDetails,
  getCredits,
} from "../redux/actions/projectUtils";
import Workspace from "../containers/workspace/workspace";
import ContentValue from "../constants/lang/en.json";
import { setAnimoPlayer, setAnimoPlayerId } from "../redux/actions/appUtils";
import PlayerControlPanel from "./player-controls";
import { validateKeydown } from "../helper/keymap";
import vmTheme from "../constants/theme";
import ActionTypes from "../constants/action-types";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";
import Toast from "../common-components/Toast";
import openUrl from "../helper/openUrl";

const AnimoPlayer = (props) => {
  const dispatch = useDispatch();
  const appReducer = useSelector((state) => state.app);
  const initStages = appReducer.get("initStages");
  const theme = appReducer.get("theme");
  const animoPlayerId = appReducer.get("animoPlayerId");

  const [playerShortcut, setPlayerShortcut] = useState("");

  useLayoutEffect(() => {
    const { params = { id: "" } } = props.match;
    getUserDetails().then((response) => {
      props.setCredits();
      dispatch(setProjectDetails(params.id, response, false)).then((res) => {
        const hasShorts = res.project.shorts && Object.keys(res.project.shorts).length !== 0;
        if (hasShorts) {
          openUrl(`${window.location.origin}/shorts/${params.id}`, true);
        }
      });
    }).catch(() => {
      dispatch(setProjectDetails(params.id, {}, false));
    });
    dispatch(setAnimoPlayer({ isAnimoPlayer: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e) => {
    setPlayerShortcut((prevState) => {
      const optionArr = validateKeydown(e, false, false);
      let tempState = prevState;
      optionArr.forEach(({ component, option }) => {
        if (component === "playerControl") {
          // Prevent the default behaviour on arrow click.
          if (["ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
          let keyoption = option;
          if (keyoption !== "") {
            if (prevState === keyoption) {
              keyoption += "_AGAIN";
            }
            tempState = keyoption;
          }
        }
      });
      return tempState;
    });
  };

  const handleKeyUp = () => {
    if (playerShortcut) {
      setPlayerShortcut("");
    }
  };

  const handleResize = () => {
    dispatch({
      type: ActionTypes.SET_RESIZE,
    });
  };

  useEffect(() => {
    dispatch(setAnimoPlayerId("animo-player"));
    window.addEventListener("keydown", handleKeyDown, false);
    window.addEventListener("keyup", handleKeyUp, false);
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
      window.removeEventListener("keyup", handleKeyUp, false);
      window.removeEventListener("resize", handleResize, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let workspaceComp = null;
  if (initStages.get("projectLoaded")) {
    workspaceComp = <Workspace />;
  }
  return (
    <IntlProvider locale="en" messages={{ ...ContentValue }}>
      <ThemeProvider theme={vmTheme[theme]}>
        <AnimoWrap>
          <Header />
          <MainPage>
            <Container>
              <PlayerHeader />
              <InnerContainer>
                <LeftSection id={animoPlayerId}>
                  {workspaceComp || (
                    <Loader>
                      <Spinner
                        type={SPINNER_TYPE.RING_SPINNER}
                        spinnerHeight={"130px"}
                      />
                    </Loader>
                  )}
                  <PlayerControlPanel shortcutName={playerShortcut} />
                </LeftSection>
                <RightSection>
                  <UserAccess />
                </RightSection>
              </InnerContainer>
            </Container>
          </MainPage>
          <Footer />
        </AnimoWrap>
        <Toast />
      </ThemeProvider>
    </IntlProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCredits: bindActionCreators(getCredits, dispatch)
  };
};

AnimoPlayer.propTypes = {
  match: PropTypes.object,
  setCredits: PropTypes.func
};

export default connect(null, mapDispatchToProps)(withRouter(AnimoPlayer));
