import { Record } from "immutable";

const WorkspaceStageLeftRecord = Record({
  menu: 64,
  menuBorder: 1,
  library: 348,
  toggle: 0,
  padding: 24,
});
const WorkspaceStageTopRecord = Record({
  menu: 48,
  toolbar: 48,
  padding: 38,
});
const WorkspaceStageRightRecord = Record({
  padding: 24,
});
const WorkspaceStageBottomRecord = Record({
  timeline: 270,
  padding: 49,
});

export const WorkspaceStageRecord = Record(
  {
    left: WorkspaceStageLeftRecord({ library: 0 }),
    top: WorkspaceStageTopRecord(),
    right: WorkspaceStageRightRecord(),
    bottom: WorkspaceStageBottomRecord(),
  },
  "WorkspaceStageRecord"
);

export const WorkspaceBoundsRecord = Record(
  {
    cx: 0,
    cy: 0,
    width: 0,
    height: 0,
  },
  "WorkspaceBoundsRecord"
);
