import { PUBLIC_URL } from "../constants/config";

/**
 * @see https://github.com/webpack/webpack/discussions/14648#discussioncomment-7774944
 */
export default class CorsWorker {
    /** @type {string | URL | undefined} */
    url = undefined;

    /** @type {WorkerOptions | undefined} */
    options = undefined;

    /**
     * @param {string | URL} url 
     * @param {WorkerOptions | undefined} options 
     */
    constructor(url, options) {
        let base = PUBLIC_URL;
        if (!base) {
            // localhost
            base = window.location.origin;
        } else {
            const publicUrl = new URL(PUBLIC_URL);
            base = publicUrl.origin;
        }
        if (url instanceof URL) {
            url = new URL(`${base}${url.pathname}`);
        }

        this.url = url;
        this.options = options;
    }

    async createWorker() {
        const f = await fetch(this.url);
        const t = await f.text();
        const b = new Blob([t], {
            type: "application/javascript",
        });
        const url = URL.createObjectURL(b);
        const worker = new Worker(url, this.options);
        URL.revokeObjectURL(url);
        return worker;
    }
}

