/* eslint-disable react/sort-comp */
import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const WaveformManagerContext = createContext({
    getPCMData: null,
    requestWaveformPath: null,
});

class WaveformManagerComponent extends Component {
    static audioContext = new (window.AudioContext || window.webkitAudioContext)();

    constructor(props) {
        super(props);

        this.addWorkerListeners = this.addWorkerListeners.bind(this);
        this.removeWorkerListeners = this.removeWorkerListeners.bind(this);

        this.setPath = this.setPath.bind(this);
        this.requestWaveformPath = this.requestWaveformPath.bind(this);
        this.resolvePathFromWorker = this.resolvePathFromWorker.bind(this);

        this.setPCMItem = this.setPCMItem.bind(this);
        this.loadPCMData = this.loadPCMData.bind(this);
        this.getPCMData = this.getPCMData.bind(this);

        this.pathCache = {};
        this.pathPromises = {};

        this.pcmStore = {};
        this.pcmFetchQueue = [];
        this.pcmProcessing = false;

        this.managerCallbacks = {
            getPCMData: this.getPCMData,
            requestWaveformPath: this.requestWaveformPath,
        };
    }

    componentDidMount() {
        this.addWorkerListeners(this.props.utilityWorker);
    }

    componentDidUpdate(prevProps) {
        if (this.props.utilityWorker !== prevProps.utilityWorker) {
            this.removeWorkerListeners(prevProps.utilityWorker);
            this.addWorkerListeners(this.props.utilityWorker);
        }
    }

    componentWillUnmount() {
        this.removeWorkerListeners(this.props.utilityWorker);
    }

    addWorkerListeners(utilityWorker) {
        if (utilityWorker instanceof Worker) {
            utilityWorker.addEventListener("message", this.resolvePathFromWorker);
        }
    }

    removeWorkerListeners(utilityWorker) {
        if (utilityWorker instanceof Worker) {
            utilityWorker.removeEventListener("message", this.resolvePathFromWorker);
        }
    }

    /**
     * @param {string} pathId
     * @param {object} path
     */
    setPath(pathId, path) {
        const MAX_PATH = 100;

        this.pathCache[pathId] = path;
        const pathIds = Reflect.ownKeys(this.pathCache);

        if (pathIds.length > 0 && pathIds.length > MAX_PATH) {
            delete this.pathCache[pathIds[0]];
        }
    }

    /**
     * @param {object} params
     * @param {string} params.pathId
     * @param {Slider} params.slider
     * @param {number} params.sliderHeight
     * @param {object} params.pcmItem
     */
    requestWaveformPath(params = {}) {
        const { pathId, pcmItem, slider, sliderHeight } = params;

        return new Promise((resolvePath, rejectPath) => {
            try {
                if (this.pathCache[pathId]) {
                    resolvePath(this.pathCache[pathId]);
                } else if (this.pathPromises[pathId]) {
                    this.pathPromises[pathId].queue.push({ resolvePath, rejectPath });
                } else if (this.props.utilityWorker instanceof Worker) {
                    this.pathPromises[pathId] = {
                        queue: [{ resolvePath, rejectPath }],
                    };
                    this.props.utilityWorker.postMessage({
                        type: "GET_WAVEFORM_PATH",
                        payload: {
                            pathId,
                            pcmItem,
                            slider,
                            sliderHeight,
                        },
                    });
                } else {
                    rejectPath(new Error("Worker not initialized"));
                }
            } catch (error) {
                rejectPath(error);
            }
        });
    }

    /**
     * @typedef {{ pathId: string, path: string }} SetWaveFormPayload
     * @param {MessageEvent<{ type: "SET_WAVEFORM_PATH", payload: SetWaveFormPayload }>} event
     */
    resolvePathFromWorker(event) {
        if (event.data && event.data.type === "SET_WAVEFORM_PATH") {
            const { payload } = event.data;

            this.setPath(payload.pathId, payload);

            if (this.pathPromises[payload.pathId]) {
                this.pathPromises[payload.pathId].queue.forEach(({ resolvePath }) => {
                    resolvePath(payload);
                });
                delete this.pathPromises[payload.pathId];
            }
        }
    }

    /**
     * @param {string} pcmSrc
     * @param {object} pcmItem
     */
    setPCMItem(pcmSrc, pcmItem) {
        const MAX_PCM = 50;

        this.pcmStore[pcmSrc] = pcmItem;
        const pcmIds = Reflect.ownKeys(this.pcmStore);

        if (pcmIds.length > 0 && pcmIds.length > MAX_PCM) {
            delete this.pcmStore[pcmIds[0]];
        }
    }

    /** @param {string} src */
    async loadPCMData(src) {
        const res = await fetch(src, {
            method: "GET",
            mode: "cors",
            responseType: "blob",
        });

        const arrayBuffer = await res.arrayBuffer();
        const audioBuffer = await WaveformManagerComponent.audioContext.decodeAudioData(
            arrayBuffer
        );
        const audioPcmData = audioBuffer.getChannelData(0);

        if (this.pcmStore[src] === undefined) {
            this.setPCMItem(src, { source: src, data: audioPcmData });
        }

        return {
            source: src,
            data: audioPcmData,
        };
    }

    /**
     * @param {string} src
     * @param {{ res: Function, rej: Function } | undefined} resolver
     */
    getPCMData(src, resolver) {
        const executor = (res, rej) => {
            try {
                if (this.pcmStore[src]) {
                    // pcm data already fetched
                    res(this.pcmStore[src]);
                } else if (this.pcmProcessing) {
                    // fetching multiple source at same time will block ui thread, so push into queue and deal with it later
                    this.pcmFetchQueue.push({
                        src,
                        resolver: { res, rej },
                    });
                } else if (!this.pcmProcessing) {
                    // currently no other src is being fetched
                    this.pcmProcessing = true;
                    this.loadPCMData(src)
                        .then((pcmItem) => {
                            res(pcmItem);
                        })
                        .catch((error) => {
                            rej(error);
                        })
                        .finally(() => {
                            this.pcmProcessing = false;
                            let fetchParams = this.pcmFetchQueue.shift();

                            while (fetchParams && this.pcmStore[fetchParams.src]) {
                                this.getPCMData(fetchParams.src, fetchParams.resolver);
                                fetchParams = this.pcmFetchQueue.shift();
                            }

                            if (fetchParams) {
                                this.getPCMData(fetchParams.src, fetchParams.resolver);
                            }
                        });
                }
            } catch (error) {
                rej(error);
            }
        }

        if (resolver) {
            return executor(resolver.res, resolver.rej);
        }
        return new Promise(executor);
    }

    render() {
        return (
            <WaveformManagerContext.Provider value={this.managerCallbacks}>
                {this.props.children}
            </WaveformManagerContext.Provider>
        );
    }
}

WaveformManagerComponent.propTypes = {
    utilityWorker: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const WaveformManager = (props) => {
    const utilityWorker = useSelector((state) =>
        state.app.getIn(["workers", "UTILITY_1"])
    );

    return (
        <WaveformManagerComponent
            {...props}
            utilityWorker={utilityWorker}
        />
    );
}

export default WaveformManager;
