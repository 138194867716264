import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";
import {
  RULER_OPTIONS,
} from "../timeline-constants";
import { PlotType } from "../timeline-proptypes";

export const Plot = styled.div.attrs((props) => {
  const { x, y, width, height } = props.$plot;
  return {
    style: {
      transform: `translate(${x}px, ${y}px)`,
      width: `${width}px`,
      height: `${height}px`,
    },
  };
})``;
Plot.propTypes = {
  $plot: PlotType.isRequired,
};
Plot.displayName = "Plot";

export const TrackListContainer = styled.div.attrs((props) => {
  return {
    style: {
      width: `${props.$width}px`,
    },
  };
})`
  position: relative;
  height: calc(100% - ${RULER_OPTIONS.height}px);
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; // mozilla
  ::-webkit-scrollbar {
    display: none;
  }

  .timeline-track--slider-list,
  .timeline-track--snaplinesvg {
    pointer-events: none;
  }

  .timeline-track--track-list,
  .timeline-track--slider-list,
  .timeline-track--snaplinesvg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .timeline-track--snaplinesvg {
    z-index: 3;
    transition: all .4s;
  }
`;
TrackListContainer.propTypes = {
  $width: PropTypes.number.isRequired,
};
TrackListContainer.displayName = "TrackListContainer";

export const TrackContainer = styled(Plot)`
  position: absolute;
  background-color: ${(props) => props.theme.TRACK_BG};
`;
TrackContainer.displayName = "TrackContainer";

export const SliderMultiSelect = styled(Plot)`
  position: absolute;
  transform-origin: 0 0;
  transform: translate(0, 0);
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  background-color: ${(props) => props.theme.SLIDER_MULTISELECT_BG};
  border: 1px solid ${(props) => props.theme.SLIDER_MULTISELECT_BORDER};
`;
SliderMultiSelect.displayName = "SliderMultiSelect";

export const InsertIndicator = styled(Plot)`
  background-color: ${(props) => props.theme.NEW_TRACK_BG};
  border-radius: 4px;
`;
InsertIndicator.displayName = "InsertIndicator";

export const TrackDivider = styled(Plot).attrs((props) => {
  return {
    style: {
      ...props.style,
      backgroundColor: props.theme.TRACK_DIVIDER,
    },
  };
})``;
TrackDivider.displayName = "TrackDivider";

const bubble = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  ${(props) => props.selectedIcon && css`
    animation: ${bubble} 1.2s 2;
  `}`;

export const TransitionLayerContainer = styled.div`
  transform: ${(props) =>
    `translate(${props.xplot - 11}px, ${props.yplot + 10}px) scaleX(${props.scaleX})`};
  width: "24px";
  height: "24px";
  position: absolute;
  cursor: pointer;
  ${(props) =>
    props.sliderWidth &&
    `
    &:hover {
      transform: translate(${props.xplot - 11}px, ${props.yplot + 10}px) scale(1.1);
    }
  `};
`;

export const LayerWrapper = styled.div`
  width: ${(props)=> `${props.width}px`};
  height: 44px;
  position: absolute;
  top: -10px;
  left: ${(props)=> `-${(props.left)-10}px`};
`;