export default function btoaConvert(str) {
    // Convert string to UTF-8 byte array
    const utf8Bytes = new TextEncoder().encode(str);

    // Convert byte array to Latin1 string
    let binaryString = '';
    utf8Bytes.forEach(byte => {
        binaryString += String.fromCharCode(byte);
    });

    // Encode the binary string using btoa
    return window.btoa(binaryString);
}