import { useCallback, useInsertionEffect, useRef } from "react";

/**
 * @typedef UseCustomCallbackParams
 * @property {Function} memoizedCallback
 * callback to save for later use. NOTE: callback should be memoized (e.g. using useCallback)
 */

/**
 * hook to save callback to use later inside listeners or timers without triggering render.
 *
 * NOTE:
 * 1. DO NOT USE THE RETURNED CALLBACK INSIDE "useInsertionEffect"
 * 2. SWITCH TO OFFICIAL "useEffectEvent" ONCE IT BECOMES STABLE. AT THE TIME OF WRITING THIS CODE IT IS STILL EXPERIMENTAL.
 *
 * @param {UseCustomCallbackParams} props
 */
export const useCustomCallback = (props = {}) => {
  const { memoizedCallback } = props;
  const callbackRef = useRef(null);

  /**
   * Using useInsertionEffect as there are way less reasons that we might be needing this hook elsewhere.
   * Also this ensures that passed callback always stays fresh in useLayoutEffect and useEffect hooks.
   * Passed callback might become stale if the returned function is used inside another useInsertionEffect
   */
  useInsertionEffect(() => {
    callbackRef.current = memoizedCallback;
  }, [memoizedCallback]);

  return useCallback((...args) => {
    if (typeof callbackRef.current === "function") {
      callbackRef.current(...args);
    }
  }, []);
};
