import React from "react";
import PropTypes from "prop-types";
import Text from "./text";
import BuildTextEffectDom from "./build-text-effect-dom";

const SpecialTextWrapper = (props) => {
  const { isPlay, isPlayAll } = props;
  const isPlaying = isPlay || isPlayAll;

  return !isPlaying ? <Text {...props} /> : <BuildTextEffectDom {...props} />;
};

SpecialTextWrapper.propTypes = {
  isPlayAll: PropTypes.bool,
  isPlay: PropTypes.bool,
};

export default SpecialTextWrapper;
