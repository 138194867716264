import content from "../../../../constants/content";

const EFFECT_TYPES = {
    ENTER: "Enter",
    EXIT: "Exit",
};

const EXIT_EFFECTS = [
    {
        type: "No Effect",
        effects: [
            {
                effect: "no_Effect",
                text: "No Effect",
                src: "noEffect.svg",
            },
        ],
    },
    {
        type: content.NEW_TEXT_ANIMATIONS,
        effects: [
            {
                effect: "bounceWords",
                text: "Bounce Words",
                src: "bounceWords_property.svg",
            },
            {
                effect: "lettersRise",
                text: "Letters Rise",
                src: "lettersRise_property.svg",
            },
            {
                effect: "pulseWords",
                text: "Pulse Words",
                src: "pulseWords_property.svg",
            },
            {
                effect: "shiftWords",
                text: "Shift Words",
                src: "shiftWords_property.svg",
            },
            {
                effect: "pan",
                text: "Pan Out",
                src: "pan_property.svg",
            },
            {
                effect: "emerge",
                text: "Vanish",
                src: "emerge_property.svg",
            },
            {
                effect: "slideOpen",
                text: "Slide Close",
                src: "slideOpen_property.svg",
            },
            {
                effect: "stompWords",
                text: "Stomp Words",
                src: "stompWords_property.svg",
            },
            {
                effect: "bounceLetters",
                text: "Letters Bounce",
                src: "bounceLetters_property.svg",
            },
            {
                effect: "skateLetters",
                text: "Skate Letters",
                src: "skateLetters_property.svg",
            },
            {
                effect: "skateWords",
                text: "Skate Words",
                src: "skateWords_property.svg",
            },
            {
                effect: "lineSide",
                text: "Side Line",
                src: "lineSide_property.svg",
            },
            {
                effect: "lineSideZigZag",
                text: "Side Line Zig Zag",
                src: "lineSideZigZag_property.svg",
            },
        ],
    },
    {
        type: "Rotate Zoom Effects",
        effects: [
            {
                effect: "rotateZoomOutPopupCw",
                text: "Zoomout Popup Clockwise",
                src: "rotateZoomOutPopupCw_property.svg",
            },
            {
                effect: "rotateZoomOutPopupCcw",
                text: "Zoomout Popup Anti Clockwise",
                src: "rotateZoomOutPopupCcw_property.svg",
            },
            {
                effect: "rotateZoomOutCw",
                text: "Zoomout Clockwise",
                src: "rotateZoomOut.svg",
            },
            {
                effect: "rotateZoomOutCcw",
                text: "Zoomout Anti Clockwise",
                src: "rotateZoomOutCcw_property.svg",
            },
        ],
    },
    {
        type: "Popdown",
        effects: [
            {
                effect: "popDownToLeft",
                text: "Pop Left",
                src: "popDownToLeft.svg",
            },
            {
                effect: "popDownToRight",
                text: "Pop Right",
                src: "popDownToRight.svg",
            },
            {
                effect: "popDownToTop",
                text: "Pop Top",
                src: "popDownToTop.svg",
            },
            {
                effect: "popDownToBottom",
                text: "Pop Bottom",
                src: "popDownToBottom.svg",
            },
            {
                effect: "popDownToTopLeft",
                text: "Pop Top Left",
                src: "popDownToTopLeft.svg",
            },
            {
                effect: "popDownToBottomLeft",
                text: "Pop Bottom Left",
                src: "popDownToBottomLeft.svg",
            },
            {
                effect: "popDownToBottomRight",
                text: "Pop Bottom Right",
                src: "popDownToBottomRight.svg",
            },
            {
                effect: "popDownToTopRight",
                text: "Pop Top Right",
                src: "popDownToTopRight.svg",
            },
            {
                effect: "popDownToCenter",
                text: "Pop Center",
                src: "popDownToCenter.svg",
            },
        ],
    },
    {
        type: "Fade Effects",
        effects: [
            {
                effect: "fadeOut",
                text: "FadeOut",
                src: "fadeOut.svg",
            },
            {
                effect: "fadeoutWithZoom",
                text: "Fade Zoom Out",
                src: "fadeOutZoomOut_property.svg",
            },
        ],
    },
    {
        type: "Exit",
        effects: [
            {
                effect: "exitToLeft",
                text: "Left",
                src: "exitToLeft.svg",
            },
            {
                effect: "exitToTop",
                text: "Top",
                src: "exitToTop.svg",
            },
            {
                effect: "exitToRight",
                text: "Right",
                src: "exitToRight.svg",
            },
            {
                effect: "exitToBottom",
                text: "Bottom",
                src: "exitToBottom.svg",
            },
            {
                effect: "exitToLeftBottom",
                text: "Left Bottom",
                src: "exitToLeftBottom.svg",
            },
            {
                effect: "exitToRightTop",
                text: "Right Top",
                src: "exitToRightTop.svg",
            },
            {
                effect: "exitToRightBottom",
                text: "Right Bottom",
                src: "exitToRightBottom.svg",
            },
            {
                effect: "exitToLeftTop",
                text: "Left Top",
                src: "exitToLeftTop.svg",
            },
        ],
    },
    {
        type: "Hide",
        effects: [
            {
                effect: "slideDown",
                text: "Down",
                src: "hideDown.svg",
            },
            {
                effect: "slideRight",
                text: "Right",
                src: "hideRight.svg",
            },
            {
                effect: "slideUp",
                text: "Top",
                src: "hideTop.svg",
            },
            {
                effect: "slideLeft",
                text: "Left",
                src: "hideLeft.svg",
            },
        ],
    },
    {
        type: "Specials",
        effects: [
            {
                effect: "pixel",
                text: "Pixel",
                src: "pixel.svg",
            },
            {
                effect: "brightSquare",
                text: "Bright_square",
                src: "brightSquare_property.svg",
            },
            {
                effect: "normalToBig",
                text: "Normal To Big",
                src: "normalToBig_property.svg",
            },
        ],
    },
    {
        type: "Wipe",
        effects: [
            {
                effect: "wipeUp",
                text: "Top",
                src: "wipeTop.svg",
            },
            {
                effect: "wipeDown",
                text: "Down",
                src: "wipeDown.svg",
            },
            {
                effect: "wipeRight",
                text: "Right",
                src: "wipeRight.svg",
            },
            {
                effect: "wipeLeft",
                text: "Left",
                src: "wipeLeft.svg",
            },
        ],
    },
];

const ENTER_EFFECTS = [
    {
        type: "No Effect",
        effects: [
            {
                effect: "no_Effect",
                text: "No Effect",
                src: "noEffect.svg",
            },
        ],
    },
    {
        type: content.NEW_TEXT_ANIMATIONS,
        effects: [
            {
                effect: "bounceWords",
                text: "Bounce Words",
                src: "bounceWords.svg",
            },
            {
                effect: "lettersRise",
                text: "Letters Rise",
                src: "lettersRise.svg",
            },
            {
                effect: "pulseWords",
                text: "Pulse Words",
                src: "pulseWords.svg",
            },
            {
                effect: "shiftWords",
                text: "Shift Words",
                src: "shiftWords.svg",
            },
            {
                effect: "stompWords",
                text: "Stomp Words",
                src: "stompWords.svg",
            },
            {
                effect: "pan",
                text: "Pan In",
                src: "pan.svg",
            },
            {
                effect: "emerge",
                text: "Emerge",
                src: "emerge.svg",
            },
            {
                effect: "slideOpen",
                text: "Slide Open",
                src: "slideOpen.svg",
            },
            {
                effect: "bounceLetters",
                text: "Letters Bounce",
                src: "bounceLetters.svg",
            },
            {
                effect: "skateLetters",
                text: "Skate Letters In",
                src: "skateLetters.svg",
            },
            {
                effect: "skateWords",
                text: "Skate Words In",
                src: "skateWords.svg",
            },
            {
                effect: "lineSide",
                text: "Side Line",
                src: "lineSide.svg",
            },
            {
                effect: "lineSideZigZag",
                text: "Side Line Zig Zag",
                src: "lineSideZigZag.svg",
            },
        ],
    },
    {
        type: "Rotate Zoom Effects",
        effects: [
            {
                effect: "rotateZoomInPopupCw",
                text: "Zoomin popup Clockwise",
                src: "rotateZoomInPopupCw.svg",
            },
            {
                effect: "rotateZoomInPopupCcw",
                text: "Zoomin popup Anti Clockwise",
                src: "rotateZoomInPopupCcw.svg",
            },
            {
                effect: "rotateZoomInCw",
                text: "ZoomIn Clockwise",
                src: "rotateZoomInCw.svg",
            },
            {
                effect: "rotateZoomInCcw",
                text: "ZoomIn Anti Clockwise",
                src: "rotateZoomInCcw.svg",
            },
        ],
    },
    {
        type: "Popup",
        effects: [
            {
                effect: "popupFromLeft",
                text: "Pop Left",
                src: "popupFromLeft.svg",
            },
            {
                effect: "popupFromRight",
                text: "Pop Right",
                src: "popupFromRight.svg",
            },
            {
                effect: "popupFromTop",
                text: "Pop Top",
                src: "popupFromTop.svg",
            },
            {
                effect: "popupFromBottom",
                text: "Pop Bottom",
                src: "popupFromBottom.svg",
            },
            {
                effect: "popupFromBottomRight",
                text: "Pop Bottom Right",
                src: "popupFromBottomRight.svg",
            },
            {
                effect: "popupFromBottomLeft",
                text: "Pop Bottom Left",
                src: "popupFromBottomLeft.svg",
            },
            {
                effect: "popupFromTopLeft",
                text: "Pop Top Left",
                src: "popupFromTopLeft.svg",
            },
            {
                effect: "popupFromTopRight",
                text: "Pop Top Right",
                src: "popupFromTopRight.svg",
            },
            {
                effect: "popupFromCenter",
                text: "Pop center",
                src: "popupFromCenter.svg",
            },
        ],
    },
    {
        type: "Fade Effects",
        effects: [
            {
                effect: "fadeIn",
                text: "FadeIn",
                src: "fadeOut.svg",
            },
            {
                effect: "fadeinWithZoom",
                text: "Fade In Zoom",
                src: "fadeInZoomIn.svg",
            },
        ],
    },
    {
        type: "Enter",
        effects: [
            {
                effect: "enterFromLeftBottom",
                text: "Left Bottom",
                src: "enterFromLeftBottom.svg",
            },
            {
                effect: "enterFromLeftTop",
                text: "Left Top",
                src: "enterFromLeftTop.svg",
            },
            {
                effect: "enterFromRightBottom",
                text: "Right Bottom",
                src: "enterFromRightBottom.svg",
            },
            {
                effect: "enterFromRightTop",
                text: "Right Top",
                src: "enterFromRightTop.svg",
            },
            {
                effect: "enterFromTop",
                text: "Top",
                src: "enterFromTop.svg",
            },
            {
                effect: "enterFromLeft",
                text: "Left",
                src: "enterFromLeft.svg",
            },
            {
                effect: "enterFromBottom",
                text: "Bottom",
                src: "enterFromBottom.svg",
            },
            {
                effect: "enterFromRight",
                text: "Right",
                src: "enterFromRight.svg",
            },
        ],
    },
    {
        type: "Move Effects",
        effects: [
            {
                effect: "slideDown",
                text: "Bottom",
                src: "moveFromDown.svg",
            },
            {
                effect: "slideUp",
                text: "Top",
                src: "moveFromTop.svg",
            },
            {
                effect: "slideLeft",
                text: "Left",
                src: "moveFromLeft.svg",
            },
            {
                effect: "slideRight",
                text: "Right",
                src: "moveFromRight.svg",
            },
        ],
    },
    {
        type: "Specials",
        effects: [
            {
                effect: "enterPixel",
                text: "Pixel",
                src: "pixel.svg",
            },
            {
                effect: "brightSquare",
                text: "Bright Square",
                src: "brightSquare_property.svg",
            },
            {
                effect: "bounce",
                text: "Bounce",
                src: "bounce_property.svg",
            },
            {
                effect: "bigToNormal",
                text: "Big To Normal",
                src: "bigToNormal_property.svg",
            },
        ],
    },
    {
        type: "Wipe",
        effects: [
            {
                effect: "wipeUp",
                text: "Top",
                src: "wipeTop_property.svg",
            },
            {
                effect: "wipeDown",
                text: "Down",
                src: "wipeDown_property.svg",
            },
            {
                effect: "wipeRight",
                text: "Right",
                src: "wipeRight_property.svg",
            },
            {
                effect: "wipeLeft",
                text: "Left",
                src: "wipeLeft_property.svg",
            },
        ],
    },
];

export const ENTER_VIDEO_IMAGE_EFFECTS = [
    {
        type: "No Effect",
        effects: [
            {
                effect: "no_Effect",
                text: "No Effect",
                src: "noEffect.svg",
            },
        ],
    },
    {
        type: "Fade",
        effects: [
            {
                effect: "fadeIn",
                text: "FadeIn",
                src: "fadeOut.svg",
            },
        ],
    },
    {
        type: "Squeeze",
        effects: [
            {
                effect: "squeezev",
                text: "Vertical",
                src: "verticalSplit.svg",
            },
            {
                effect: "squeezeh",
                text: "Horizontal",
                src: "horizontalSplit.svg",
            },
        ],
    },
    // {
    //     type: "Irish",
    //     effects: [
    //         {
    //             effect: "squareCrop",
    //             text: "Square",
    //             src: "irishSquare.svg",
    //         },
    //         {
    //             effect: "circleCrop",
    //             text: "Circle",
    //             src: "irishCircle.svg",
    //         },
    //     ],
    // },
    {
        type: "blinds",
        effects: [
            {
                effect: "verticalUpSlice",
                text: "Up",
                src: "blindsBottom.svg",
            },
            {
                effect: "verticalDownSlice",
                text: "Down",
                src: "blindsTop.svg",
            },
            {
                effect: "horizontalRightSlice",
                text: "Right",
                src: "blindsLeft.svg",
            },
            {
                effect: "horizontalLeftSlice",
                text: "Left",
                src: "blindsRight.svg",
            },
        ],
    },
    {
        type: "Slide",
        effects: [
            {
                effect: "slideUp",
                text: "Up",
                src: "moveFromDown.svg",
            },
            {
                effect: "slideDown",
                text: "Down",
                src: "moveFromTop.svg",
            },
            {
                effect: "slideRight",
                text: "Right",
                src: "moveFromLeft.svg",
            },
            {
                effect: "slideLeft",
                text: "Left",
                src: "moveFromRight.svg",
            },
        ],
    },
    {
        type: "Wipe",
        effects: [
            {
                effect: "wipeDown",
                text: "Up",
                src: "wipeDown.svg",
            },
            {
                effect: "wipeUp",
                text: "Down",
                src: "wipeTop.svg",
            },
            {
                effect: "wipeLeft",
                text: "Right",
                src: "wipeLeft.svg",
            },
            {
                effect: "wipeRight",
                text: "Left",
                src: "wipeRight.svg",
            },
            {
                effect: "wipeBottomRight",
                text: "Bottom Right",
                src: "enter-wipeTopRight.svg",
            }, {
                effect: "wipeBottomLeft",
                text: "Bottom Left",
                src: "enter-wipeTopLeft.svg",
            }, {
                effect: "wipeTopRight",
                text: "Top Right",
                src: "enter-wipeBottomRight.svg",
            },
            {
                effect: "wipeTopLeft",
                text: "Top Left",
                src: "enter-wipeBottomLeft.svg",
            },
        ],
    },
];

export const EXIT_VIDEO_IMAGE_EFFECTS = [
    {
        type: "No Effect",
        effects: [
            {
                effect: "no_Effect",
                text: "No Effect",
                src: "noEffect.svg",
            },
        ],
    },
    {
        type: "Fade",
        effects: [
            {
                effect: "fadeOut",
                text: "FadeOut",
                src: "fadeOut.svg",
            },
        ],
    },
    {
        type: "Zoom",
        effects: [
            {
                effect: "exitZoomIn",
                text: "ZoomIn",
                src: "fadeOut.svg",
            },
        ],
    },
    // {
    //     type: "Irish",
    //     effects: [
    //         {
    //             effect: "squareCrop",
    //             text: "Square",
    //             src: "irishSquareExit.svg",
    //         },
    //         {
    //             effect: "circleCrop",
    //             text: "Circle",
    //             src: "irishCircleExit.svg",
    //         },
    //     ],
    // },
    {
        type: "Blinds",
        effects: [
            {
                effect: "verticalUpSlice",
                text: "Up",
                src: "exitBlindsTop.svg",
            },
            {
                effect: "verticalDownSlice",
                text: "Down",
                src: "exitBlindsBottom.svg",
            },
            {
                effect: "horizontalRightSlice",
                text: "Right",
                src: "exitBlindsRight.svg",
            },
            {
                effect: "horizontalLeftSlice",
                text: "Left",
                src: "exitBlindsLeft.svg",
            },
        ],
    },
    {
        type: "Hide",
        effects: [
            {
                effect: "slideUp",
                text: "Up",
                src: "hideTop.svg",
            },
            {
                effect: "slideDown",
                text: "Down",
                src: "hideDown.svg",
            },
            {
                effect: "slideRight",
                text: "Right",
                src: "hideRight.svg",
            },
            {
                effect: "slideLeft",
                text: "Left",
                src: "hideLeft.svg",
            },
        ],
    },
    {
        type: "Wipe",
        effects: [
            {
                effect: "wipeDown",
                text: "Up",
                src: "wipeDown.svg",
            },
            {
                effect: "wipeUp",
                text: "Down",
                src: "wipeTop.svg",
            },
            {
                effect: "wipeLeft",
                text: "Right",
                src: "wipeLeft.svg",
            },
            {
                effect: "wipeRight",
                text: "Left",
                src: "wipeRight.svg",
            },
            {
                effect: "wipeBottomRight",
                text: "Bottom Right",
                src: "exit-wipeBottomRight.svg",
            },
            {
                effect: "wipeBottomLeft",
                text: "Bottom Left",
                src: "exit-wipeBottomLeft.svg",
            }, 
            {
                effect: "wipeTopRight",
                text: "Top Right",
                src: "exit-wipeTopRight.svg",
            },
            {
                effect: "wipeTopLeft",
                text: "Top Left",
                src: "exit-wipeTopLeft.svg",
            },
        ],
    },
];

export { EXIT_EFFECTS, ENTER_EFFECTS, EFFECT_TYPES };
