import html2canvas from "html2canvas";
import { dataURItoBlob } from "./blobConvertion";

export function saveScreenshot(elem, width = 600 /* , height = 450 */) {
    return new Promise((resolve, reject) => {
        try {
            let element = elem;
            if (typeof elem === 'string') {
                element = document.querySelector(elem);
            }
            if (element) {
                const bounds = element.getBoundingClientRect();
                html2canvas(element, {
                    useCORS: true,
                    logging: false,
                    scale: width / bounds.width,
                })
                    .then((canvas) => {
                        const projectUrl = canvas.toDataURL();
                        resolve(dataURItoBlob(projectUrl), projectUrl);
                    })
                    .catch(reject)
            } else {
                resolve(null);
            }

        } catch (err) {
            reject(err);
        }


    })
}
export const convertBlobToBase64URL = (blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        resolve(reader.readAsDataURL(blob));
    });
}