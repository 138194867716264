import React from "react";
import PropTypes from "prop-types";

export const ReturnSvgFilter = (props) => {
  const filters = props.filterCode;
  const filterItem = [];
  let tintFilter;

  let brightness = 0;
  let contrast = 0;
  let hue = 0;
  let saturate = 0;
  let tint = 0;
  let blur = 0;
  //   let vignette = 0;

  if (filters !== null && filters !== undefined) {
    brightness = parseInt(filters.substr(0, 2), 16) - 100;
    contrast = parseInt(filters.substr(2, 2), 16) - 100;
    hue = parseInt(filters.substr(4, 2), 16) - 100;
    saturate = parseInt(filters.substr(6, 2), 16) - 100;
    tint = parseInt(filters.substr(8, 2), 16) - 100;
    blur = parseInt(filters.substr(10, 2), 16) - 100;
    // vignette = parseInt(filters.substr(12, 2), 16);
  }

  if (brightness !== 0) {
    filterItem.push(
      <feComponentTransfer key={1}>
        <feFuncR
          type="linear"
          id={"RbrightnessSVG"}
          slope={1}
          intercept={brightness / 200}
        />
        <feFuncG
          type="linear"
          id={"GbrightnessSVG"}
          slope={1}
          intercept={brightness / 200}
        />
        <feFuncB
          type="linear"
          id={"BbrightnessSVG"}
          slope={1}
          intercept={brightness / 200}
        />
      </feComponentTransfer>
    );
  } else {
    filterItem.push(
      <feComponentTransfer key={1}>
        <feFuncR type="linear" id={"RbrightnessSVG"} />
        <feFuncG type="linear" id={"GbrightnessSVG"} />
        <feFuncB type="linear" id={"BbrightnessSVG"} />
      </feComponentTransfer>
    );
  }
  if (contrast !== 0) {
    filterItem.push(
      <feComponentTransfer key={2}>
        <feFuncR
          type="linear"
          id={"RcontrastSVG"}
          slope={
            contrast < 0
              ? (contrast + 100) * 0.006 + 0.4
              : (contrast * 1.5) / 100 + 1
          }
          intercept={
            contrast > 0
              ? -((contrast * 75) / 100 / 100)
              : -((contrast * 30) / 100) / 100
          }
        />
        <feFuncG
          type="linear"
          id={"GcontrastSVG"}
          slope={
            contrast < 0
              ? (contrast + 100) * 0.006 + 0.4
              : (contrast * 1.5) / 100 + 1
          }
          intercept={
            contrast > 0
              ? -((contrast * 75) / 100 / 100)
              : -((contrast * 30) / 100) / 100
          }
        />
        <feFuncB
          type="linear"
          id={"BcontrastSVG"}
          slope={
            contrast < 0
              ? (contrast + 100) * 0.006 + 0.4
              : (contrast * 1.5) / 100 + 1
          }
          intercept={
            contrast > 0
              ? -((contrast * 75) / 100 / 100)
              : -((contrast * 30) / 100) / 100
          }
        />
      </feComponentTransfer>
    );
  } else {
    filterItem.push(
      <feComponentTransfer key={2}>
        <feFuncR type="linear" id={"RcontrastSVG"} />
        <feFuncG type="linear" id={"GcontrastSVG"} />
        <feFuncB type="linear" id={"BcontrastSVG"} />
      </feComponentTransfer>
    );
  }
  if (hue !== 0) {
    filterItem.push(
      <feColorMatrix key={3} type="hueRotate" id={"HueSVG"} values={hue} />
    );
  } else {
    filterItem.push(<feColorMatrix key={3} type="hueRotate" id={"HueSVG"} />);
  }

  if (saturate !== 0) {
    // saturate

    const filterValue = [
      ((saturate + 100) * (1.6914 - 0.3086)) / 200 + 0.3086,
      ((saturate + 100) * (-0.6094 - 0.6094)) / 200 + 0.6094,
      ((saturate + 100) * (-0.082 - 0.082)) / 200 + 0.082,
      0,
      0,
      ((saturate + 100) * (-0.3086 - 0.3086)) / 200 + 0.3086,
      ((saturate + 100) * (1.3906 - 0.6094)) / 200 + 0.6094,
      ((saturate + 100) * (-0.082 - 0.082)) / 200 + 0.082,
      0,
      0,
      ((saturate + 100) * (-0.3086 - 0.3086)) / 200 + 0.3086,
      ((saturate + 100) * (-0.6094 - 0.6094)) / 200 + 0.6094,
      ((saturate + 100) * (1.918 - 0.082)) / 200 + 0.082,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ].join(",");

    filterItem.push(
      // <feColorMatrix key={4} type="saturate" id={"saturateSVG"} values={((saturate + 100)* 2 / 100)}/>

      <feColorMatrix
        key={4}
        type="matrix"
        id={"saturateSVG"}
        values={filterValue}
      />
    );
  } else {
    filterItem.push(
      // <feColorMatrix key={4} type="saturate" id={"saturateSVG"} values="2"/>

      <feColorMatrix key={4} type="matrix" id={"saturateSVG"} />
    );
  }
  if (tint !== 0) {
    if (tint > 0 && tint <= 33) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR type="linear" id={"RtintSVG"} slope={0.9} intercept={0} />
          <feFuncG type="linear" id={"GtintSVG"} slope={0.9} intercept={0.1} />
          <feFuncB
            type="linear"
            id={"BtintSVG"}
            slope={0.9}
            intercept={tint / 330}
          />
        </feComponentTransfer>
      );
    } else if (tint > 33 && tint < 66) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR type="linear" id={"RtintSVG"} slope={0.9} intercept={0} />
          <feFuncG
            type="linear"
            id={"GtintSVG"}
            slope={0.9}
            intercept={(33 - (tint % 33)) / 330}
          />
          <feFuncB type="linear" id={"BtintSVG"} slope={0.9} intercept={0.1} />
        </feComponentTransfer>
      );
    } else if (tint >= 66) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR
            type="linear"
            id={"RtintSVG"}
            slope={0.9}
            intercept={(tint - 66) / 330}
          />
          <feFuncG type="linear" id={"GtintSVG"} slope={0.9} intercept={0} />
          <feFuncB type="linear" id={"BtintSVG"} slope={0.9} intercept={0.1} />
        </feComponentTransfer>
      );
    } else if (tint < 0 && tint >= -33) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR
            type="linear"
            id={"RtintSVG"}
            slope={0.9}
            intercept={-(tint / 330)}
          />
          <feFuncG type="linear" id={"GtintSVG"} slope={0.9} intercept={0.1} />
          <feFuncB type="linear" id={"BtintSVG"} slope={0.9} intercept={0} />
        </feComponentTransfer>
      );
    } else if (tint < -33 && tint > -66) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR type="linear" id={"RtintSVG"} slope={0.9} intercept={0.1} />
          <feFuncG
            type="linear"
            id={"GtintSVG"}
            slope={0.9}
            intercept={(33 - (-tint % 33)) / 330}
          />
          <feFuncB type="linear" id={"BtintSVG"} slope={0.9} intercept={0} />
        </feComponentTransfer>
      );
    } else if (tint <= -66) {
      tintFilter = (
        <feComponentTransfer id="tintSVG">
          <feFuncR type="linear" id={"RtintSVG"} slope={0.9} intercept={0.1} />
          <feFuncG type="linear" id={"GtintSVG"} slope={0.9} intercept={0} />
          <feFuncB
            type="linear"
            id={"BtintSVG"}
            slope={0.9}
            intercept={-((tint + 66) / 330)}
          />
        </feComponentTransfer>
      );
    }
  } else {
    tintFilter = (
      <feComponentTransfer id="tintSVG">
        <feFuncR type="linear" id={"RtintSVG"} />
        <feFuncG type="linear" id={"GtintSVG"} />
        <feFuncB type="linear" id={"BtintSVG"} />
      </feComponentTransfer>
    );
  }

  if (blur !== 0) {
    if (blur > 0) {
      filterItem.push(
        <React.Fragment key={6}>
          <feComponentTransfer result="preblur" />
          <feGaussianBlur stdDeviation={(blur * 3) / 10} />
          <feComposite />
        </React.Fragment>
      );
    } else if (blur < 0) {
      filterItem.push(
        <React.Fragment key={7}>
          <feComponentTransfer result="preblur" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite
            operator="arithmetic"
            k1="0"
            k2={(blur * 6) / 100}
            k3={((blur * 6) / 100) * -1 + 1}
            k4="0"
            in2="preblur"
          />
        </React.Fragment>
      );
    }
  } else {
    filterItem.push(
      <React.Fragment key={7.5}>
        <feComponentTransfer result="preblur" />
        <feGaussianBlur stdDeviation={0} />
        <feComposite />
      </React.Fragment>
    );
  }

  //   if (
  //     document.getElementById(props.vignetteId) !== undefined &&
  //     document.getElementById(props.vignetteId) !== null
  //   ) {
  //     const vignetteElement = document.getElementById(props.vignetteId);
  //     vignetteElement.style.backgroundImage = `radial-gradient(circle farthest-side at center center, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, ${
  //       vignette * 0.007
  //     }) 130%)`;
  //   }

  return (
    <>
      {filterItem}
      {tintFilter}
    </>
  );
};
ReturnSvgFilter.propTypes = {
  filterCode: PropTypes.string.isRequired,
  // vignetteId: PropTypes.string,
};

const FilterItem = (props) => {
  const { filterId, svgId, filterCode, browserName } = props;

  return (
    <div>
      <svg
        id={svgId}
        style={
          browserName === "Firefox"
            ? { width: "0", height: "0", display: "block" }
            : { display: "none" }
        }
      >
        <defs>
          <filter colorInterpolationFilters="sRGB" id={filterId}>
            <ReturnSvgFilter filterCode={filterCode} />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
FilterItem.propTypes = {
  filterId: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  filterCode: PropTypes.string.isRequired,
  browserName: PropTypes.string.isRequired,
};

export default FilterItem;
