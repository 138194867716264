import ActionTypes from "../../constants/action-types";

const completeActionCreater = (actionType) => (payload, isCurrentUser) => ({
  type: actionType,
  payload,
  isCurrentUser,
});

export const updateProjectData = (payload) => ({
  type: ActionTypes.UPDATE_PROJECT_DATA,
  payload,
});

export const updateProjectDataComplete = completeActionCreater(
  ActionTypes.UPDATE_PROJECT_DATA_COMPLETE
);

export const updateTimelineTime = (payload) => ({
  type: ActionTypes.UPDATE_TIMELINE,
  payload,
});

export const moveSubtitle = (payload) => ({
  type: ActionTypes.MOVE_SUBTITLE,
  payload,
});

export const updateTimelineTimeComplete = completeActionCreater(
  ActionTypes.UPDATE_TIMELINE_COMPLETE
);

export const addObject = (payload) => ({
  type: ActionTypes.ADD_OBJECT,
  payload,
});

export const addReplaceObject = (payload) => ({
  type: ActionTypes.ADD_REPLACE_OBJECT,
  payload,
});

export const updateBlobItem = (payload) => ({
  type: ActionTypes.UPDATE_BLOB_ITEM,
  payload,
});

export const deleteObject = (payload) => ({
  type: ActionTypes.DELETE_OBJECT,
  payload,
});

export const undoTimelineTimeUpdate = (payload) => ({
  type: ActionTypes.UNDO_TIMELINE_UPDATE,
  payload,
});

export const bulkObjectUpdate = (payload) => ({
  type: ActionTypes.BULK_OBJECT_UPDATE,
  payload,
});

export const bulkObjectUpdateComplete = completeActionCreater(
  ActionTypes.BULK_OBJECT_UPDATE_COMPLETE
);

export const moveUpdate = (payload) => ({
  type: ActionTypes.MOVE_UPDATE,
  payload,
});

export const moveUpdateComplete = completeActionCreater(
  ActionTypes.MOVE_UPDATE_COMPLETE
);

export const undoMove = (payload) => ({
  type: ActionTypes.UNDO_MOVE,
  payload,
});

export const rotationUpdate = (payload) => ({
  type: ActionTypes.ROTATION_UPDATE,
  payload,
});

export const resizeUpdate = (payload) => ({
  type: ActionTypes.RESIZE_UPDATE,
  payload,
});

export const updateObjectById = (payload) => ({
  type: ActionTypes.UPDATE_OBJECT_BY_ID,
  payload,
});

export const updateItem = (payload) => ({
  type: ActionTypes.UPDATE_ITEM,
  payload,
});

export const cropImage = (payload) => ({
  type: ActionTypes.CROP_IMAGE,
  payload,
});

export const updateObject = (payload) => ({
  type: ActionTypes.UPDATE_OBJECT,
  payload,
});

export const updateTextEffects = (payload) => ({
  type: ActionTypes.UPDATE_TEXT_EFFECTS,
  payload,
});

export const undoTextEffects = (payload) => ({
  type: ActionTypes.UNDO_TEXT_EFFECTS,
  payload,
});

export const updateObjectComplete = completeActionCreater(
  ActionTypes.UPDATE_OBJECT_COMPLETE
);

export const changeText = (payload) => ({
  type: ActionTypes.CHANGE_TEXT,
  payload,
});

export const updateGroupText = (payload) => ({
  type: ActionTypes.UPDATE_GROUP_TEXT,
  payload,
});

export const updateGroupTextComplete = completeActionCreater(
  ActionTypes.UPDATE_GROUP_TEXT_COMPLETE
);

export const syncText = (payload) => ({
  type: ActionTypes.SYNC_TEXT,
  payload,
});

export const writeText = (payload) => ({
  type: ActionTypes.WRITE_TEXT,
  payload,
});

export const applyFrameClipPlot = (payload) => ({
  type: ActionTypes.APPLY_FRAME_CLIP_PLOT,
  payload,
});

export const applyFrameClipPlotComplete = completeActionCreater(
  ActionTypes.APPLY_FRAME_CLIP_PLOT_COMPLETE
);

export const applyFlip = (payload) => ({
  type: ActionTypes.APPLY_FLIP,
  payload,
});

export const undoFlip = (payload) => ({
  type: ActionTypes.UNDO_FLIP,
  payload,
});

export const applyFlipComplete = completeActionCreater(
  ActionTypes.APPLY_FLIP_COMPLETE
);

export const applyColor = (payload) => ({
  type: ActionTypes.APPLY_COLOR,
  payload,
});

export const undoColor = (payload) => ({
  type: ActionTypes.UNDO_COLOR,
  payload,
});

export const applyColorComplete = completeActionCreater(
  ActionTypes.APPLY_COLOR_COMPLETE
);

export const setTimelineHeight = (payload) => ({
  type: ActionTypes.SET_TIMELINE_HEIGHT,
  payload,
});

export const changeAudioVolume = (payload) => ({
  type: ActionTypes.CHANGE_AUDIO_VOLUME,
  payload,
});

export const updateText = (data) => ({
  type: ActionTypes.UPDATE_TEXT,
  data,
});

export const updateAllTexts = () => ({
  type: ActionTypes.UPDATE_ALL_TEXTS,
});

export const updateSubtitleList = (payload) => ({
  type: ActionTypes.UPDATE_SUBTITLE_LIST,
  payload,
});

export const undoUpdateSubtitleList = (payload) => ({
  type: ActionTypes.UNDO_UPDATE_SUBTITLE_LIST,
  payload,
});

export const updateSubtitleListComplete = completeActionCreater(
  ActionTypes.UPDATE_SUBTITLE_LIST_COMPLETE
);