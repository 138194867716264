/// <reference path="../timeline-types.js" />

/* eslint-disable react/sort-comp */

import React from "react";
import { func } from "prop-types";
import { MOUSE_DRAG_INITIAL_STATE } from "../timeline-constants";
import { getMouseClientPosition } from "../timeline-helper";

class MouseDrag extends React.Component {
    constructor(props) {
        super(props);
        this.state = MOUSE_DRAG_INITIAL_STATE;

        this.initiateDrag = this.initiateDrag.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.attachDragEvents = this.attachDragEvents.bind(this);
        this.removeDragEvents = this.removeDragEvents.bind(this);

        this.lastMouseMove = 0;
    }

    componentDidMount() {
        if (this.state.isDragging) {
            this.attachDragEvents();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isDragging && !prevState.isDragging) {
            this.attachDragEvents();
        } else if (!this.state.isDragging && prevState.isDragging) {
            this.removeDragEvents();
        }
    }

    componentWillUnmount() {
        this.removeDragEvents();
    }

    attachDragEvents() {
        window.addEventListener("pointermove", this.handleDrag);
        window.addEventListener("pointerup", this.stopDrag);
    }

    removeDragEvents() {
        window.removeEventListener("pointermove", this.handleDrag);
        window.removeEventListener("pointerup", this.stopDrag);
    }

    /**
     * Initiates dragging.
     * @param {InitiateMouseDragParams} params
     */
    initiateDrag({ event, onMouseDrag, onMouseDragEnd, clientOffset, scrollLeftElRef, scrollTopElRef, cursor, cursorThreshold, throttle = 0 } = {}) {
        const mousePos = getMouseClientPosition(event, clientOffset);

        let scrollLeft = 0;
        if (scrollLeftElRef && scrollLeftElRef.current instanceof HTMLElement) {
            scrollLeft = scrollLeftElRef.current.scrollLeft;
        }
        let scrollTop = 0;
        if (scrollTopElRef && scrollTopElRef.current instanceof HTMLElement) {
            scrollTop = scrollTopElRef.current.scrollTop;
        }

        this.setState({
            isDragging: true,
            mouseDownStart: mousePos,
            scrollLeftOnMouseDown: scrollLeft,
            scrollTopOnMouseDown: scrollTop,
            clientOffset,
            onMouseDrag,
            onMouseDragEnd,
            cursor,
            cursorThreshold,
            throttle,
        });
    }

    /**
     * Calls drag handler on mouse move if provided
     * @param {PointerEvent} e pointer move event
     */
    handleDrag(e) {
        const { clientOffset, mouseDownStart, onMouseDrag, scrollLeftOnMouseDown, scrollTopOnMouseDown, throttle } = this.state;

        if (e.timeStamp - this.lastMouseMove < throttle) {
            return;
        }
        this.lastMouseMove = e.timeStamp;

        const { cursor, cursorThreshold } = this.state;
        const mousePos = getMouseClientPosition(e, clientOffset);
        const mouseMovedBy = {
            x: mousePos.x - mouseDownStart.x,
            y: mousePos.y - mouseDownStart.y,
        };

        if (typeof cursor === "string" && Number.isFinite(cursorThreshold)) {
            const cursorClass = "window-mousedrag-cursor";
            if (Math.abs(mouseMovedBy.x) > cursorThreshold || Math.abs(mouseMovedBy.y) > cursorThreshold) {
                if (!document.body.classList.contains(cursorClass)) {
                    document.body.classList.add(cursorClass);
                }
                if (document.body.style.getPropertyValue("--window-mousedrag-cursor") !== cursor) {
                    document.body.style.setProperty("--window-mousedrag-cursor", cursor);
                }
            }
        }

        if (typeof onMouseDrag === "function") {
            onMouseDrag({
                event: e,
                mouseDownPosition: mouseDownStart,
                mouseCurrentPosition: mousePos,
                mouseMovedBy,
                scrollLeftOnMouseDown,
                scrollTopOnMouseDown,
            });
        }
    }

    /**
     * Calls drag handler on mouse up if provided
     * NOTE: Attaching this function to pointerup event will take care of touch devices. No need to use touchend separately
     * @param {PointerEvent} e pointer move event
     */
    stopDrag(e) {
        const { clientOffset, mouseDownStart, onMouseDragEnd, scrollLeftOnMouseDown, scrollTopOnMouseDown } = this.state;
        const { cursor } = this.state;
        this.lastMouseMove = 0;

        const mousePos = getMouseClientPosition(e, clientOffset);
        const mouseMovedBy = {
            x: mousePos.x - mouseDownStart.x,
            y: mousePos.y - mouseDownStart.y,
        };

        if (typeof cursor === "string") {
            document.body.classList.remove("window-mousedrag-cursor");
            if (document.body.style.getPropertyValue("--window-mousedrag-cursor") === cursor) {
                document.body.style.removeProperty("--window-mousedrag-cursor");
            }
        }

        if (typeof onMouseDragEnd === "function") {
            onMouseDragEnd({
                event: e,
                mouseDownPosition: mouseDownStart,
                mouseCurrentPosition: mousePos,
                mouseMovedBy,
                scrollLeftOnMouseDown,
                scrollTopOnMouseDown,
            });
        }

        this.setState(MOUSE_DRAG_INITIAL_STATE);
    }

    render() {
        return this.props.children({ initiateDrag: this.initiateDrag, dragStatus: this.state });
    }
}

MouseDrag.propTypes = {
    children: func,
};

export default MouseDrag;
