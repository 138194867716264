import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  FitButton,
  ZoomContainer,
  ZoomSliderContainer,
} from "./zoom-components";
import { STATIC_PATH } from "../../constants/config";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import Slider from "./zoom-slider";
import {
  changeZoom,
  setWorkspaceTextFocus,
} from "../../redux/actions/appUtils";
import content from "../../constants/content";
import CustomTooltipComponent from "../../common-components/CustomTooltip";
import vmTheme from "../../constants/theme";

const ZoomDropDown = (props) => {
  const { setWorkspaceTextFocus, setZoomOpen, triggerRef } = props;
  const [zoomVal, setZoomVal] = useState((props.zoomFactor * 100).toFixed(0));
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));

  useEffect(() => {
    setZoomVal(Math.round(props.zoomFactor * 100));
  }, [props.zoomFactor]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        triggerRef &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target) &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        setWorkspaceTextFocus(false);
        setIsFocused(false);
        setZoomOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setWorkspaceTextFocus(false);
    };
  }, [setWorkspaceTextFocus, setZoomOpen, triggerRef]);

  const onChangeSliderValue = (e, value, isFocus) => {
    try {
      if (value > 0) {
        props.updateZoom(false, value / 100);
        if (isFocus === false) {
          props.setWorkspaceTextFocus(false);
          setIsFocused(false);
          setZoomVal(value);
        }
      }
    } catch (error) {
      //
    }
  };

  const onChangeFocus = () => {
    props.setWorkspaceTextFocus(true);
    setIsFocused(true);
  };

  const assignContainerRef = (r) => {
    containerRef.current = r;
    if (typeof props.dropdownRef === "function") {
      props.dropdownRef(r);
    } else if (props.dropdownRef && typeof props.dropdownRef === "object") {
      props.dropdownRef.current = r;
    }
  };

  return (
    <ZoomSliderContainer ref={assignContainerRef}>
      <Slider
        min={0}
        max={300}
        inputMin={25}
        inputMax={300}
        value={zoomVal}
        step={25}
        showValue={true}
        contentEditable={true}
        onChangeSliderValue={onChangeSliderValue}
        onInputFocus={onChangeFocus}
        isFocused={isFocused}
        showPercent={true}
        progressBackground={vmTheme[theme].ZoomDropDownbgColor}
        background={vmTheme[theme].zoomSliderBgColor}
        isChangeBg={true}
        height="4px"
        borderRadius={"3px"}
        thumb={{
          background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
          border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
          height: "14px",
          width: "14px",
          hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
          hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
        }}
      />
      <FitButton
        onClick={() => props.updateZoom(true)}
        isFitValue={props.isFitValue}
      >
        <Typography
          content={content.FIT}
          color={
            props.isFitValue
              ? vmTheme[theme].zoomSliderTextColor
              : vmTheme[theme].polarColor
          }
          font={font.normalMicroLarge}
          cursor={"pointer"}
        />
      </FitButton>
    </ZoomSliderContainer>
  );
};
ZoomDropDown.propTypes = {
  dropdownRef: PropTypes.object,
  triggerRef: PropTypes.object,
  zoomFactor: PropTypes.number.isRequired,
  updateZoom: PropTypes.func,
  setWorkspaceTextFocus: PropTypes.func,
  setZoomOpen: PropTypes.func,
  isFitValue: PropTypes.bool,
};

const Zoom = () => {
  const zoomFactor = useSelector((state) => state.app.get("zoomFactor"));
  const isFitValue = useSelector((state) => state.app.get("isFit"));
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));
  const toggleZoomSlider = (e) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(e.target)) {
      setIsOpen(!isOpen);
    }
  };

  const updateZoom = (isFit, zoomFactor) =>
    dispatch(changeZoom({ isFit, zoomFactor }));

  const updateWorkspaceTextFocus = (isWorkspaceTextFocus) =>
    dispatch(setWorkspaceTextFocus(isWorkspaceTextFocus));

  return (
    <CustomTooltipComponent
      tooltipId={"zoom-tooltip"}
      dataTooltip={content.ZOOM}
      tooltipPosition="bottom"
    >
      <ZoomContainer
        ref={triggerRef}
        data-tooltip-id={"zoom-tooltip"}
        $isOpen={isOpen}
        onClick={toggleZoomSlider}
      >
        <Typography
          innerContent={`${Math.round(zoomFactor * 100)}%`}
          color={vmTheme[theme].polarColor}
          font={font.mediumMini_13}
          cursor={"pointer"}
        />
        <img src={`${STATIC_PATH}zoom-drop.svg`} alt="change-zoom" />
        {isOpen && (
          <ZoomDropDown
            dropdownRef={dropdownRef}
            triggerRef={triggerRef}
            setZoomOpen={setIsOpen}
            updateZoom={updateZoom}
            setWorkspaceTextFocus={updateWorkspaceTextFocus}
            zoomFactor={zoomFactor}
            isFitValue={isFitValue}
          />
        )}
      </ZoomContainer>
    </CustomTooltipComponent>
  );
};

export default Zoom;
