import React from "react";
import PropTypes from "prop-types";
import {
  AmountSection,
  Head,
  Image,
  List,
  UnorderList,
  UpgradCard,
  Wrapper,
} from "./upgrade-components";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import { STATIC_PATH } from "../../constants/config";
import Action from "../../common-components/Action";
import { PLAN } from "../../constants";

const PlanCard = ({
  iconSrc,
  planName,
  width,
  height,
  content,
  action,
  onClick,
  actionBgColor,
  data,
  enterpriseContent,
  monthlyPrice,
  yearlyPrice,
}) => {
  return (
    <UpgradCard
      width={width}
      height={height}
      enterprise={data.plan === PLAN.ENTERPRISE}
      onClick={onClick}
    >
      <Wrapper>
        <Head>
          <Image src={`${STATIC_PATH}${iconSrc}`} />
          <Typography
            content={planName}
            font={font.bold_16}
            color={"#313345"}
            padding={"3px 0px"}
            cursor={"pointer"}
            enableTrim={false}
          />
        </Head>
        {data.plan === PLAN.ENTERPRISE ? (
          <Typography
            content={enterpriseContent}
            font={font.normal_14_23}
            color={"#313345"}
            cursor={"pointer"}
            enableTrim={false}
            padding="10px 0px 5px"
          />
        ) : (
          <>
            <AmountSection>
              <Typography
                content={monthlyPrice || yearlyPrice}
                font={font.largeBold_40}
                padding="3px 0"
                color={"#0D0F0F"}
                cursor={"pointer"}
                enableTrim={false}
              />

              <Typography
                innerContent="/mo"
                font={font.normal_13_22}
                padding="1px 0"
                color={"#0D0F0F"}
                cursor={"pointer"}
              />
            </AmountSection>
            <Typography
              innerContent={`${yearlyPrice} billed yearly`}
              font={font.mediumBase_21}
              padding="1px 0"
              color={"#707380"}
              cursor={"pointer"}
            />
          </>
        )}
      </Wrapper>
      <UnorderList>
        {content.map((data) => {
          return (
            <List key={data}>
              <Typography
                content={data}
                color={"#313345"}
                font={font.normalBase_21}
                enableTrim={false}
                cursor={"pointer"}
              />
            </List>
          );
        })}
      </UnorderList>
      <Action
        text={action}
        background={actionBgColor}
        borderRadius={"8px"}
      />
    </UpgradCard>
  );
};

PlanCard.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  iconSrc: PropTypes.string,
  planName: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
  action: PropTypes.string,
  actionBgColor: PropTypes.string,
  enterpriseContent: PropTypes.string,
  data: PropTypes.object,
  monthlyPrice: PropTypes.number,
  yearlyPrice: PropTypes.number,
};
export default PlanCard;
