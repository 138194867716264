import styled, { css } from "styled-components";

export const TextComponentStyles = css`
  outline: none;
  display: block;
  word-break: break-word;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  [contenteditable],
  [contenteditable] span {
    -webkit-user-select: text;
    user-select: text;
  }

  span {
    font-size: inherit !important;
  }

  div {
    pointer-events: none;
    word-break: break-word;
  }

  &.bulletted > div {
    display: list-item;
    list-style-position: inside;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  & ul li,
  & ol li {
    list-style: unset;
  }

  &:not(.rtl-text) ul,
  &:not(.rtl-text) ol {
    padding-left: 1.3em;
  }

  &.rtl-text ul,
  &.rtl-text ol {
    padding-right: 1.3em;
  }

  &.selected {
    cursor: text;
  }
  ul {
    list-style: disc !important;
  }
  ol {
    list-style: decimal !important;
  }

  &:not(.is-child-text) {
    white-space: wrap;
  }

  & font,
  & span {
    font-size: inherit !important;
    letter-spacing: inherit !important;
  }

  &.bspace li {
    padding-left: var(--paddingSpace);
  }
  &.bspace5 li {
    padding-left: var(--paddingSpace, 5px);
  }
  &.bspace10 li {
    padding-left: var(--paddingSpace, 10px);
  }
  &.bspace15 li {
    padding-left: var(--paddingSpace, 15px);
  }
  &.bspace20 li {
    padding-left: var(--paddingSpace, 20px);
  }
  &.bspace25 li {
    padding-left: var(--paddingSpace, 25px);
  }
  &.bspace30 li {
    padding-left: var(--paddingSpace, 30px);
  }
  &.bspace35 li {
    padding-left: var(--paddingSpace, 35px);
  }
  &.bspace40 li {
    padding-left: var(--paddingSpace, 40px);
  }
  &.bspace45 li {
    padding-left: var(--paddingSpace, 45px);
  }
  &.bspace50 li {
    padding-left: var(--paddingSpace, 50px);
  }
`;

export const TextContentEditable = styled.div`
  ${TextComponentStyles}
`;
TextContentEditable.displayName = "TextContentEditable";
