/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { getToolBarStyle } from "./itemtoolbar-helper";
import { ItemToolConfirmationStyled } from "./itemtoolbar-components";
import content from "../../constants/content";
import { PORTAL_ID } from "../../constants";

export const ItemToolConfirmation = (props) => {
    const itemToolStyle = getToolBarStyle(props.workspaceStage);
    const intl = useIntl();

    const confirm = (
        <ItemToolConfirmationStyled
            data-html2canvas-ignore="true"
            style={itemToolStyle}
            className={"item-tool-confirmation itc--tool-bar"}
        >
            <div
                className={"itc--apply"}
                style={{
                    "--apply-btn-background": props.backgroundColor,
                }}
                onClick={props.onApply}
            >
                {intl.formatMessage({ id: content.APPLY })}
            </div>
            {
                props.onCancel && (
                    <div
                        className={"itc--cancel"}
                        onClick={props.onCancel}
                    >
                        {intl.formatMessage({ id: content.CLOSE })}
                    </div>
                )
            }
        </ItemToolConfirmationStyled>
    );

    const portalDOM = document.getElementById(PORTAL_ID);
    if (portalDOM) {
        return createPortal(confirm, portalDOM);
    }
    return null;
}

ItemToolConfirmation.propTypes = {
    workspaceStage: PropTypes.object,
    backgroundColor: PropTypes.string,
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
};
