import fetch from 'node-fetch';
import ActionTypes from "../../constants/action-types";
import AppConfig, { API, API_URL, ENVIRONMENT } from "../../constants/config";
import triggerSimpleAjax from "../../helper/httpHelper";
import { CONSTANTS, PANEL } from "../../constants";
import openUrl from "../../helper/openUrl";
import getResizedProject from "../../helper/ResizeHelper";
import { addNotification } from "./notificationUtils";
import uuid from "../../helper/uuid";
import content from "../../constants/content";


const { MASPROP } = CONSTANTS.LIBRARY_TYPE.PROPERTY;
const { STOCKIMG, MASSTOCKIMG, UNSPLASH } = CONSTANTS.LIBRARY_TYPE.IMAGE;
const { MASSTOCKVID, STOCKVID } = CONSTANTS.LIBRARY_TYPE.VIDEO;
const { MASBGM, MASSFX, BGM } = CONSTANTS.LIBRARY_TYPE.MUSIC;
const { STOCKGIPHY, MASGIPHY } = CONSTANTS.LIBRARY_TYPE.GIPHY;
const { TEXT } = CONSTANTS.LIBRARY_TYPE;

export function setPropertyPanel(name, isExpand, context) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_PROPERTY_PANEL,
      payload: {
        selectedPanel: name,
        isExpand,
        context
      },
    });
}

export function setLoader(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_LOADER,
      payload,
    });
}

export function setVerionHistory(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_VERSION_HISTORY,
      payload,
    });
}

export function setPreviewData(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_PREVIEW_PROJECT,
      payload,
    });
}

export function setExpandPanel(isExpand) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_PROPERTY_PANEL,
      payload: {
        isExpand,
      },
    });
}

const getPanelAction = (type) => {
  switch (type) {
    case MASPROP:
      return ActionTypes.SET_PROPERTY_DATASOURCE;
    case MASBGM:
      return ActionTypes.SET_MUSIC_DATASOURCE;
    case BGM:
      return ActionTypes.SET_MUSIC_DATASOURCE;
    case MASSFX:
      return ActionTypes.SET_SOUND_EFFECT_DATASOURCE;
    case MASSTOCKIMG:
      return ActionTypes.SET_IMAGE_DATASOURCE;
    case MASSTOCKVID:
      return ActionTypes.SET_VIDEO_DATASOURCE;
    case UNSPLASH:
      return ActionTypes.SET_UNSPLASH_DATASOURCE
    default:
      return ActionTypes.SET_PROPERTY_DATASOURCE;
  }
};

const getCategoryApiData = (payload) => {
  const { type, categories, size, tags, filter, projectId, exclusive } =
    payload;
  const reqBody = {
    type,
    size,
  };
  switch (type) {
    case STOCKVID:
      return {
        apiUrl: `${API_URL}${API.SVCOL}`,
        reqBody: {
          ...reqBody,
          tags,
          exclusive,
          filter,
          projectId,
        },
      };
    case STOCKIMG:
      return {
        apiUrl: `${API_URL}${API.SICOL}`,
        reqBody: {
          ...reqBody,
          tags,
          filter,
          projectId,
          exclusive,
        },
      };
    default:
      return {
        apiUrl: `${API_URL}${API.GLL}`,
        reqBody: {
          ...reqBody,
          categories,
          filter,
          projectId,
          exclusive,
        },
      };
  }
};

/**
 * This function is the master data api call for all pannel except text
 * @param {*} pagination
 * @param {*} payload
 * @returns
 */
export function getPanelData(pagination = 0, payload) {
  const { type, size } = payload;
  const apiUrl = type === BGM ? `${API_URL}${API.FPROP}` : `${API_URL}${API.MST}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        {
          ...payload,
          type,
          from: pagination,
          size: size || 5,
        },
        (response) => {
          if (type === MASPROP) {
            if (response && response.hits) {
              response.hits = response.hits.map((e) => {
                if (
                  e._source.category_en !== "GRID" &&
                  e._source.category_en !== "Frame" &&
                  e._source.category_en !== "Animprop" &&
                  e._source.category_en !== "Visualizer"
                ) {
                  e._source.data = e._source.data.filter(
                    (item, index, self) => {
                      return (
                        index === self.findIndex((i) => i.id === item.id) &&
                        !item.animsrc // Remove items with "animsrc" property
                      );
                    }
                  );
                  if (e._source.data.length) {
                    return e;
                  }
                } else {
                  e._source.data = [];
                }
                return e;
              });
            }
          }
          dispatch({
            type: getPanelAction(type),
            payload: response,
          });
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
}

/**
 * This function is to get the images in the text panel
 * @param {*} pagination
 * @returns
 */
export function getTextLibrary(pagination = 0) {
  const apiUrl = `${API_URL}${API.FPROP}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        {
          type: TEXT,
          from: pagination,
          size: 50,
          categories: "All Items",
        },
        (response) => {
          dispatch({
            type: ActionTypes.SET_TEXT_DATASOURCE,
            payload: response,
          });
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
}
/**
 * All the datas under the category on clicking view all
 * @param {*} pagination
 * @param {*} payload
 * @returns API response
 */
export function getCategoryData(pagination = 0, payload) {
  const { reqBody, apiUrl } = getCategoryApiData(payload);
  reqBody.from = pagination;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        reqBody,
        (response) => {
          if (payload.type === STOCKVID) {
            response.hits.type = payload.type;
          }
          if (payload.type === STOCKIMG) {
            // The below line is to set the total data in response
            response.total = response.aggs[0].doc_count;
          }
          response.type = payload.type;
          dispatch({
            type: ActionTypes.SET_DATASOURCE_BY_CATEGORY,
            payload:
              payload.type === STOCKVID
                ? { ...response.hits, isLoading: false }
                : { ...response, isLoading: false },
          });
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
}

export function getSearchApi(pagination = 0, payload) {
  const { tags, type, projectId, filter, size } = payload;
  const reqBody = {
    projectId,
    fromScroll: false,
    from: pagination,
    size: size || 50,
    action: "default",
    tags,
    type,
  };
  if (filter) {
    reqBody.filter = filter;
  }

  const apiUrl = `${API_URL}${API.SOAK}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        reqBody,
        (response) => {
          response.total = !response.aggs.length
            ? 0
            : response.aggs[0].doc_count;
          dispatch({
            type: ActionTypes.SET_DATASOURCE_BY_CATEGORY,
            payload: { ...response.hits, isLoading: false, type },
          });
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
}

export const getSearchFiles = (pagination, payload) => {
  const apiUrl = `${API_URL}${API.SOAK}`;

  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {
        from: pagination,
        size: 100,
        action: "uploaded",
        fromScroll: false,
        ...payload,
      },
      (response) => {
        response.total = response.aggs[0].doc_count;
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const getucm = (payload) => {
  const apiUrl = `${API_URL}editor/ucm/`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      },
      false
    );
  });
};

export const updateBlob = (payload, url, type) => {
  const requestDetails = {
    method: "PUT",
    mode: 'cors',
    headers: {
      'Content-Type': type
    },
    body: payload
  }
  return new Promise((res, rej) => {
    fetch(url, requestDetails).then((data) => {
      if (data.status === 200) {
        res(data)
      }
    }).catch((err) => {
      rej(err);
    })
  });
};

export const preflightCheck = (payload) => {
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      AppConfig.UPLOADS.PREFLIGHT_URL,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export function getGiphyData(pagination = 0, payload) {
  const { type, tags } = payload;
  const reqBody = {
    type,
    from: pagination,
    size: 50,
  };
  if (type === STOCKGIPHY) {
    reqBody.tags = tags;
  }
  const apiUrl = `${API_URL}${API.GIPHY_API}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        reqBody,
        (response) => {
          if (type === MASGIPHY) {
            dispatch({
              type: ActionTypes.SET_GIPHY_DATASOURCE,
              payload: response,
            });
          } else {
            dispatch({
              type: ActionTypes.SET_DATASOURCE_BY_CATEGORY,
              payload: { ...response, type, isLoading: false },
            });
          }
          res(response);
        },
        (error) => {
          rej(error);
        }
      );
    });
}

export function getFavouritesData(pagination, payload = {}) {
  const { size } = payload;
  const apiUrl = `${API_URL}${API.FAVOURITES}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        {
          ...payload,
          from: pagination,
          size: size || 5,
        },
        (response) => {
          const { context, ...rest } = response;
          const result = {
            hits: context,
            ...rest
          }
          dispatch({
            type: ActionTypes.SET_FAVOURITE_DATASOURCE,
            payload: result,
          });
          res(result);
        },
        (error) => {
          rej(error);
        }
      );
    });
}


export function getLibraryData(pagination, payload = {}) {
  const apiUrl = `${API_URL}${API.LIBRARY_API}`;
  let actionName = ActionTypes.SET_PEXELS_VIDEO_DATASOURCE;
  const { query, type } = payload;
  const reqBody = {
    from: pagination,
    size: 50,
    ...(payload || {})
  };
  switch (type) {
    case PANEL.UNSPLASH:
      reqBody.type = "unsplash";
      actionName = ActionTypes.SET_UNSPLASH_DATASOURCE;
      break;
    case PANEL.PEXELS_VIDEO:
      reqBody.type = "pexels_videos";
      actionName = ActionTypes.SET_PEXELS_VIDEO_DATASOURCE;
      break
    case PANEL.PEXELS:
      reqBody.type = "pexels";
      actionName = ActionTypes.SET_PEXELS_DATASOURCE;
      break;
    case PANEL.PIXABAY:
      reqBody.type = "pixabay";
      actionName = ActionTypes.SET_PIXABAY_DATASOURCE;
      break;
    case PANEL.PIXABAY_VIDEO:
      reqBody.type = "pixabay_videos";
      actionName = ActionTypes.SET_PIXABAY_VIDEO_DATASOURCE;
      break;
    default:
      break;
  }
  return (dispatch) => new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      false,
      reqBody,
      (response) => {
        if (query) {
          dispatch({
            type: ActionTypes.SET_DATASOURCE_BY_CATEGORY,
            payload: {
              ...response, type: reqBody.type, isLoading: false
            },
          });
        } else {
          dispatch({
            type: actionName,
            payload: {
              ...response
            },
          });
        }
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  })
}

export function getViralStyles() {
  const apiUrl = `${API_URL}${API.VIRAL_STYLES}`;
  return (dispatch, getState) => new Promise((res, rej) => {
    let dimension = getState().userDetails.dimensionName.substring(0, 3);
    dimension = dimension === 'squ' ? 'sqr' : dimension;
    triggerSimpleAjax(
      `${apiUrl}?type=${dimension}`,
      "GET",
      false,
      {},
      (response) => {
        dispatch({
          type: ActionTypes.SET_VIRAL_STYLES,
          payload: response.data.hits.hits
        });
        res(response);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

export function getLanguages() {
  const apiUrl = `${API_URL}${API.SUBTITLE.LANGUAGES}`;
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "GET",
        false,
        {},
        (response) => {
          const languagesArr = Object.entries(response.data).map(
            ([key, value]) => {
              return {
                label: key,
                value: value.code,
              };
            }
          );
          dispatch({
            type: ActionTypes.SET_LANGUAGES,
            payload: languagesArr,
          });
          res(response);
        },
        (e) => {
          rej(e);
        }
      );
    });
}

export function getViralStyleDataById(id) {
  const apiUrl = `${API_URL}${API.VIRAL_STYLES}${id}`;

  return (_, getState) => new Promise((res, rej) => {
    const dimension = getState().userDetails.dimensionName.substring(0, 3);
    triggerSimpleAjax(
      apiUrl,
      "GET",
      false,
      {
        type: dimension
      },
      (response) => {
        res(response);
      },
      (e) => {
        rej(e);
      }
    );
  });
}

/**
 * This function is triggred to add and remove favourite
 * @param {boolean} isFav
 * @param {string} assetid
 * @param {object} typedata
 * @returns
 */
export function toggleFavourite(isFav, assetid, typedata, favouriteObj) {
  const apiUrl = `${API_URL}${isFav ? API.SFT : API.RFT}`;
  const payload = {
    assetid,
    typedata,
    imgvid: favouriteObj ? JSON.stringify(favouriteObj) : null
  };
  if (!isFav) {
    delete payload.typedata;
    payload.type = typedata || "";
  }
  return (dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        payload,
        (response) => {
          res(response);
        },
        (error) => {
          dispatch(
            addNotification({
              id: uuid(),
              toastType: "WARNING",
              description:
                error.message || isFav
                  ? content.FAILED_TO_FAVOURITE
                  : content.FAILED_TO_REMOVE_FAVOURITE,
            })
          );
          rej(error);
        }
      );
    });
}

export const changeZoom = (payload) => ({
  type: ActionTypes.CHANGE_ZOOM,
  payload,
});

export const setInitStages = (payload) => ({
  type: ActionTypes.SET_INIT_STAGES,
  payload,
});

export const setScreenshotStatus = (payload) => ({
  type: ActionTypes.SET_SCREENSHOT_STATUS,
  payload,
});

export const initWebWorker = (payload) => ({
  type: ActionTypes.WEB_WORKER_INIT,
  payload,
});

export const fontLoaded = (payload) => ({
  type: ActionTypes.FONT_LOADED,
  payload,
});

export const setWorkspaceTextFocus = (payload) => ({
  type: ActionTypes.SET_WORKSPACE_TEXT_FOCUS,
  payload,
});

export const setPlayHeadTime = (payload) => ({
  type: ActionTypes.SET_PLAYHEAD_TIME,
  payload,
});

export const prefetchComplete = (token, count = 1) => ({
  type: ActionTypes.PREFETCH_LOADED,
  payload: {
    data: count,
    token,
  },
});

export const setPlayAll = (isPlayAll, isFullPreview) => ({
  type: ActionTypes.SET_PLAY_ALL,
  payload: {
    isPlayAll,
    isFullPreview,
  },
});

export const updateVideoBufferStatus = (payload) => ({
  type: ActionTypes.UPDATE_VIDEO_BUFFER_STATUS,
  payload,
});

export const addTween = (data, tweenType) => ({
  type: ActionTypes.ADD_TWEEN,
  data,
  tweenType,
});

export const startPlay = (payload) => ({
  type: ActionTypes.START_PLAY,
  payload,
});

export const updateLibraryDragItem = (payload) => ({
  type: ActionTypes.UPDATE_LIBRARY_DRAG_ITEM,
  payload,
});

export const setColorThemeData = (payload) => ({
  type: ActionTypes.SET_COLOR_THEME_DATA,
  payload,
});

export const setSubtitleStatus = (payload) => ({
  type: ActionTypes.SET_SUBTITLE_STATUS,
  payload,
});

export const insertSubtitleData = (payload) => ({
  type: ActionTypes.INSERT_SUBTITLE_DATA,
  payload,
});

export const insertSubtitleDataComplete = (payload) => ({
  type: ActionTypes.INSERT_SUBTITLE_DATA_COMPLETE,
  payload,
});

export const updateSubtitleData = (payload) => ({
  type: ActionTypes.UPDATE_SUBTITLE_DATA,
  payload,
});

export const updateIntroOutroText = (payload, shouldHoldDispatch = true) => ({
  type: ActionTypes.UPDATE_INRO_OUTRO_TEXT,
  payload,
  shouldHoldDispatch
});

export const updateSubtitleDataComplete = (payload) => ({
  type: ActionTypes.UPDATE_SUBTITLE_DATA_COMPLETE,
  payload,
});

/**
 * To trigger the resize video api call
 * @param {string} type
 * @param {object} userDetails
 * @param {number} projectDuration
 */
export function resizeVideo(
  type,
  projectDetails,
  projectId,
  projectDuration,
  dispatch
) {
  dispatch(setLoader(true));
  const api = `${API_URL}${API.RESIZE_VIDEO}`;

  const resizedProjectDetails = getResizedProject({
    type,
    data: projectDetails,
  });

  const payload = {
    type,
    project: resizedProjectDetails,
    projectId,
    projectDuration,
  };

  triggerSimpleAjax(api, "POST", false, payload, (res) => {
    dispatch(setLoader(false));
    openUrl(
      `${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${res.projectid}`
    );
  });
}

export const setWorkspaceDragging = (payload) => ({
  type: ActionTypes.SET_WORKSPACE_DRAGGING,
  payload,
});

export const setSelectedItems = (data) => ({
  type: ActionTypes.SET_SELECTED_ITEMS,
  data,
});

export const setChildrenSelection = (data) => ({
  type: ActionTypes.SET_CHILDREN_SELECTION,
  data,
});

export const updateTextStatus = (data) => ({
  type: ActionTypes.UPDATE_TEXT_STATUS,
  data,
});

export const setTextOptions = (data) => ({
  type: ActionTypes.SET_TEXT_OPTIONS,
  data,
});

export const setLastEditText = (data) => ({
  type: ActionTypes.SET_LAST_EDIT_TEXT,
  data,
});

export const selectFrameClip = (data) => ({
  type: ActionTypes.SELECT_FRAME_CLIP,
  data,
});

export const cancelFraming = () => ({ type: ActionTypes.CANCEL_FRAMING });

export const updateTransformStatus = (data) => ({
  type: ActionTypes.UPDATE_TRANSFORM_STATUS,
  data,
});

export const toggleCrop = () => ({ type: ActionTypes.TOGGLE_CROP });

export const updateSwapHoverDrop = (data) => ({
  type: ActionTypes.UPDATE_SWAP_HOVER_DROP,
  data,
});

export const updateImperativeRotate = (payload) => ({
  type: ActionTypes.UPDATE_IMPERATIVE_ROTATE,
  payload,
});

export const setPropertyWindow = (data) => ({
  type: ActionTypes.SET_PROPERTY_WINDOW,
  data,
});

export const updateRecentColors = (payload) => ({
  type: ActionTypes.UPDATE_RECENT_COLORS,
  payload,
});

export const updateActiveFontFamily = (data) => ({
  type: ActionTypes.UPDATE_ACTIVE_FONT_FAMILY,
  data,
});

export const updateActiveFontName = (data) => ({
  type: ActionTypes.UPDATE_ACTIVE_FONT_NAME,
  data,
});

export const updateFontLoadStatus = (data) => ({
  type: ActionTypes.UPDATE_FONT_LOAD_STATUS,
  data,
});

export const chooseFont = (data) => ({
  type: ActionTypes.CHOOSE_FONT,
  data,
});

export const updateTextApplyAllData = (data) => ({
  type: ActionTypes.UPDATE_TEXT_APPLY_ALL_DATA,
  data,
});

export const updateTextApplyAllOptions = (data) => ({
  type: ActionTypes.UPDATE_TEXT_APPLY_ALL_OPTIONS,
  data,
});

export const setClipboardData = (payload) => ({
  type: ActionTypes.SET_CLIPBOARD_DATA,
  payload,
});

export const setSwapItem = (payload) => ({
  type: ActionTypes.SET_SWAP_ITEM,
  payload,
});

export const setSwapInfo = (payload) => ({
  type: ActionTypes.SET_SWAP_INFO,
  payload,
});

export const resetSwap = () => ({ type: ActionTypes.RESET_SWAP });

export const setIsMobileView = (payload) => ({
  type: ActionTypes.SET_MOBILE_VIEW,
  payload,
});

export const getColorThemes = () => (dispatch) => {
  const defaultColorThemes = {
    colorPalettes: {},
    themes: {},
    brand: {},
  };
  const colorAPI = `${API_URL}${API.COLOR}`;

  const onFailure = () => {
    dispatch(setColorThemeData(defaultColorThemes));
  };

  const onSuccess = (response) => {
    dispatch(setColorThemeData(response));
  };

  triggerSimpleAjax(
    colorAPI,
    "POST",
    true,
    {
      bf: 0, // is brand - 0: false, 1: true
      id: "colorthemes_en", // TODO: replace en with user's language
    },
    onSuccess,
    onFailure
  );
};

export function duplicateProject(projectId, dispatch) {
  const apiUrl = API_URL + API.COPY_VIDEO;
  dispatch(setLoader(true));
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      {
        projectId,
      },
      (response) => {
        dispatch(setLoader(false));
        res(response);
        openUrl(
          `${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${response.projectId
          }`
        );
      },
      (error) => {
        dispatch(setLoader(false));
        dispatch(
          addNotification({
            id: uuid(),
            toastType: "WARNING",
            description: error.message || content.FAILED_TO_DUPLICATE_PROJECT,
          })
        );
        rej(error);
      }
    );
  });
}

const newProjApi = (res, rej, dispatch, type = "_blank") => {
  const apiUrl = API_URL + API.NEW_BLANK_VIDEO;
  dispatch(setLoader(true));
  triggerSimpleAjax(
    apiUrl,
    "POST",
    true,
    {},
    (response) => {
      dispatch(setLoader(false));
      res(response);
      openUrl(
        `${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${response.projectid
        }`,
        type
      );
    },
    (error) => {
      dispatch(setLoader(false));
      dispatch(
        addNotification({
          id: uuid(),
          toastType: "WARNING",
          description: error.message || content.FAILED_TO_CREATE_BLANK_PROJECT,
        })
      );
      rej(error);
    }
  );
};

export function createNewProject(dispatch) {
  return new Promise((res, rej) => {
    newProjApi(res, rej, dispatch);
  });
}

export function createNew() {
  return (dispatch) =>
    new Promise((res, rej) => {
      newProjApi(res, rej, dispatch, true);
    });
}

export function enterpriseForm(reqBody = {}) {
  const apiUrl = API_URL + API.ENTERPRISE_FORM;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      reqBody,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}
export function supportForm(reqBody = {}) {
  const apiUrl = API_URL + API.SUPPORT;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      reqBody,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export const setFavourite = (payload) => ({
  type: ActionTypes.SET_FAVOURITE,
  payload,
});

export const setExitEffectName = (payload) => ({
  type: ActionTypes.SET_EXIT_EFFECT_NAME,
  payload,
});

export const setEnterEffectName = (payload) => ({
  type: ActionTypes.SET_ENTER_EFFECT_NAME,
  payload,
});

export function contactSales(reqBody = {}) {
  const apiUrl = API_URL + API.CONTACTSALES;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      reqBody,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export const setAnimoPlayer = (payload) => ({
  type: ActionTypes.SET_ANIMO_PLAYER,
  payload,
});

export const setShortsPlayer = (payload) => ({
  type: ActionTypes.SET_SHORTS_PLAYER,
  payload,
});

export const setAnimoPlayerId = (payload) => ({
  type: ActionTypes.SET_ANIMO_ID,
  payload,
});

export const setShortContainerId = (payload) => ({
  type: ActionTypes.SET_SHORT_CONTAINER_ID,
  payload,
});

export const setGlobalVolume = (payload) => ({
  type: ActionTypes.SET_GLOBAL_VOLUME,
  payload,
});

export const playPausePlayer = (payload) => ({
  type: ActionTypes.SET_PAUSE,
  payload,
});

export const switchTheme = (newTheme) => ({
  type: ActionTypes.SWITCH_THEME,
  payload: newTheme,
});

export const setAnimoFullScreen = (payload) => ({
  type: ActionTypes.SET_ANIMO_FULL_SCREEN,
  payload,
});

export const setDetachData = (payload) => ({
  type: ActionTypes.SET_DETACH_DATA,
  payload,
});

export const setDraggedTransition = (payload) => ({
  type: ActionTypes.SET_DRAGGED_TRANSITION,
  payload,
});

export function setBackdropLoader(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_BACKDROP_LOADER,
      payload,
    });
}

export function addUploadHistory(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.ADD_UPLOAD_HISTORY,
      payload,
    });
}

export const setSubtitleCallback = (payload) => ({
  type: ActionTypes.SET_SUBTITLE_CALLBACK,
  payload,
});

export const resetDetachData = () => ({ type: ActionTypes.RESET_DETACH_DATA });

export const setSubtitleReconcileStatus = (payload) => ({
  type: ActionTypes.SET_SUBTITLE_STATUS,
  payload: {
    type: "reconcileStatus",
    toMerge: payload,
  },
});

export const reconcileSubtitleData = (payload) => ({
  type: ActionTypes.RECONCILE_SUBTITLE_DATA,
  payload,
});

export const switchSubtitle = (payload) => ({
  type: ActionTypes.SWITCH_SUBTITLE,
  payload,
});

export const updatePanelItems = (items) => ({
  type: ActionTypes.UPDATE_PANEL_ITEMS,
  payload: items,
});

export const getPresetItems = () => {
  const apiUrl = `${API_URL}${API.GET_PRESETS}`;
  return ((dispatch) =>
    new Promise((resolve, reject) => {
      triggerSimpleAjax(
        apiUrl,
        "GET",
        false,
        {},
        (response) => {
          if (response.success) {
            dispatch({
              type: ActionTypes.SET_PRESET_EFFECT,
              payload: response.data
            });
            resolve(response);
          }
        },
        (error) => {
          reject(error);
        }
      );
    })
  )
}

export function getAvatarData(pagination = 0, type, pageSize) {
  const apiUrl = `${AppConfig.API_URL}${AppConfig.API.AVATAR.GET}?page=${pagination}&type=${type}${pageSize ? `&page_size=${pageSize}` : ''}`;
  return ((dispatch) =>
    new Promise((res, rej) => {
      triggerSimpleAjax(
        apiUrl,
        "GET",
        false,
        {},
        (response) => {
          dispatch({
            type: ActionTypes.SET_AVATAR_DATA,
            payload: {
              type,
              data: response
            }
          });
          res(response);
        },
        (error) => {
          rej(error)
        }
      );
    })
  )
}

export const postAvatar = (payload) => {
  const url = `${AppConfig.API_URL}${AppConfig.API.AVATAR.CREATE}`;
  return new Promise((resolve, reject) => {
    triggerSimpleAjax(
      url,
      "POST",
      true,
      payload,
      (data) => {
        resolve(data)
      },
      (error) => {
        reject(error);
      }
    );
  })
};

export function getAvatarById(jobId) {
  const apiUrl = `${AppConfig.API_URL}${AppConfig.API.AVATAR.STATUS}`;

  return new Promise((resolve, reject) => {
    const checkStatus = () => {
      triggerSimpleAjax(
        apiUrl,
        "POST",
        true,
        { id: jobId },
        (data) => {
          if (data.status === "completed") {
            resolve(data.result);
          } else if (data.status === "error") {
            reject();
          } else {
            setTimeout(checkStatus, 5000);
          }
        },
        (error) => {
          reject(error);
        }
      );
    }
    checkStatus();
  });
}

export const sendMail = (payload) => {
  const url = `${AppConfig.API_URL}${AppConfig.API.SEND_MAIL}`;
  return new Promise((resolve, reject) => {
    triggerSimpleAjax(
      url,
      "POST",
      true,
      payload,
      (data) => {
        resolve(data)
      },
      (error) => {
        reject(error);
      }
    );
  })
};