import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "./Typography";
import { font } from "../constants/font";

const Dropdown = styled.div`
  position: absolute;
  z-index: ${(props) => props.zIndex || "auto"};
  display: inline-block;
  min-width: auto;
  font: ${font.normal};
  cursor: pointer;
  inset: ${(props) => props.inset || "auto"};
  padding: ${(props) => props.padding || "0px"};
`;

const Options = styled.ul`
  display: ${(props) => props.display || "block"};
  background-color: ${props => props.theme.polarColor};
  box-shadow: 0px 0px 4px ${props => props.theme.boxShadowColor};
  width: 100%;
  padding: 0px;
  position: absolute;
  z-index: 99;
  /* margin-top: 6px;
  left: auto;
  right: 16px; */
  ${(props) => props.style}
  min-width: 13ch;
`;

const Option = styled.li`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  a {
    display: inline-block;
    width: 100%;
    padding: 8px 13px;
    text-align: left;
    text-decoration: none;
    color: ${props => props.theme.propertyColor};
    cursor: pointer;
  }

  &:hover {
    background: ${props => props.theme.primaryBorderColor};
  }
`;
const DotMenu = ({
  subMenu = [],
  onSelect,
  icon,
  inset,
  padding,
  onOpen = () => {},
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onOpen(isOpen);
  }, [isOpen]);

  return (
    <Dropdown
      onClick={() => setIsOpen(!isOpen)}
      inset={inset}
      padding={padding}
      zIndex={isOpen ? 9 : 1}
      onMouseLeave={() => setIsOpen(false)}
    >
      <img src={icon} width="20px" alt="new" />
      <Options display={isOpen ? "block" : "none"} style={style}>
        {(subMenu || []).map((m) => {
          return (
            <Option key={m} onClick={() => onSelect(m)}>
              <Typography isAnchor={true} content={m} />
            </Option>
          );
        })}
      </Options>
    </Dropdown>
  );
};

DotMenu.propTypes = {
  subMenu: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.string,
  padding: PropTypes.string,
  inset: PropTypes.string,
  style: PropTypes.object,
};

export default DotMenu;
