import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { undoHistoryReducer } from "redux-undo-redo";
import { IS_LOCAL } from "../constants/config";
import appReducer, {
  initialState as appInitialState,
} from "./reducers/appReducer";
import libraryReducer, {
  initialState as libraryInitialState,
} from "./reducers/libraryReducer";
import userReducer, {
  initialState as userInitialState,
} from "./reducers/userReducer";
import projectReducer, {
  initialState as projectInitialState,
} from "./reducers/projectReducer";
import modalReducer, {
  initialState as modalInitialState,
} from "./reducers/modalReducer";
import notificationReducer, {
  initialState as notificationInitialState,
} from "./reducers/notificationReducer";
import autosaveMiddleware from "./middlewares/autosaveMiddleware";
import undoMiddleware from "./middlewares/undoMiddleware";

let store = null;

/**
 * If redux devtools crashes on any state update, exclude them here
 */
const stateSanitizer = (state) => {
  const EXCLUDE_TIMELINE_ONLY = true;

  if (EXCLUDE_TIMELINE_ONLY) {
    if (state.app.get("t1")) {
      state = {
        ...state,
        app: state.app.set("t1", "<<TIMELINE_OBJECT_EXCLUDED>>"),
      };
    }
  } else if (state.app.get("isPlayAll")) {
    state = { ...state, app: "<<APP_STATE_EXCLUDED>>" };
  }

  return state;
};

const DEV_TOOLS_CONFIG = {
  stateSanitizer,
  actionsBlacklist: ["ADD_TWEEN", "PREFETCH_LOADED"],
};

export default function createStore(isNewStore = false) {
  store = configureStore({
    reducer: {
      app: appReducer,
      library: libraryReducer,
      userDetails: userReducer,
      projectDetails: projectReducer,
      modal: modalReducer,
      undoHistory: undoHistoryReducer,
      notification: notificationReducer,
    },
    preloadedState: {
      app: appInitialState,
      library: libraryInitialState,
      userDetails: userInitialState,
      projectDetails: projectInitialState,
      modal: modalInitialState,
      notification: notificationInitialState,
    },
    middleware: [thunk, undoMiddleware, autosaveMiddleware],
    devTools: IS_LOCAL ? DEV_TOOLS_CONFIG : false,
  });

  let newStore;
  if (isNewStore) {
    newStore = store;
  }

  return isNewStore ? newStore : store;
}

export const getStore = () => {
  return store;
};
