import styled from "styled-components";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";

export const AnimoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  @media screen and (max-width: 767px) {
    align-items: unset;
  }
`;

export const MainPage = styled.div`
  height: auto;
  margin: 0px 20px;
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-height: 60px;
  height: 60px;
  background: ${(props) => props.theme.polarColor};
  padding: 24px;
  img {
    max-width: 100%;
    height: auto;
  }
  .prof-icon {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

export const Anchor = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  a {
    margin-right: 16px;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.paymentCardTextColor};
    }
  }
  .animo-header-main {
    img {
      border-radius: 4px;
    }
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px;
  width: 100%;
  max-height: 60px;
  height: 60px;
  background: ${(props) => props.theme.menuBgColor} 0% 0% no-repeat padding-box;
  p {
    font: ${font.normalBase_21};
  }
  span {
    color: ${(props) => props.theme.animoTextColor};
    font-weight: 600;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
    height: auto;
    text-align: center;
    max-height: 100%;
    padding: 12px;
  }
  .footer-link {
    @media screen and (max-width: 767px) {
      margin-left: unset;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0px auto;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0px auto;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    margin: 0px auto;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 30px;
  @media screen and (max-width: 1044px) {
    gap: 15px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ProfilePicContainer = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .profile-pic {
    border-radius: 8px;
  }
`;

export const EditVideoTitle = styled.div`
  width: fit-content;
  font: ${font.boldSmall};
  letter-spacing: 0px;
  padding: 0 25px 0 0px;
  color: ${(props) => props.theme.animoTextColor};
  cursor: pointer;
  position: relative;
  &:hover {
    &::after {
      content: url(${STATIC_PATH}editPurple.svg);
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      top: 1px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  gap: 24px;
  .copy-link {
    &:hover {
      transform: scale(1.1);
    }
  }
  .share-link {
    label {
      margin-left: 18px;
    }
    &:before {
      content: url(${STATIC_PATH}share-white.svg);
      left: -5px;
      top: 2px;
      width: 22px;
      height: 22px;
      left: 18px;
      top: 10px;
      position: absolute;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const TitleTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SubUserDetails = styled.div`
  text-align: left;
  font: ${font.normalBase_21};
  letter-spacing: 0px;
  opacity: 1;
  gap: 5px;
  display: flex;
  align-items: center;
`;

export const SubUserDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
  color: ${(props) => props.theme.veryDarkGrayColor};
  font: ${font.normalBase_21};
  .slash {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const VideoShareOptionLayout = styled.div`
  width: 384px;
  box-shadow: rgba(162, 162, 162, 0.16) 2px 10px 35px;
  border: 1px solid ${(props) => props.theme.videoBorder};
  border-color: ${(props) => props.theme.liBgColor};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  background: 0% 0% no-repeat padding-box padding-box
    ${(props) => props.theme.polarColor};
  position: relative;
  justify-content: center;
`;

export const LeftSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 760px;
  height: 428px;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    height: 170px;
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    /* width: 100%; */
  }
  @media screen and (min-width: 1600px) {
    width: 897px;
    height: 504.562px;
  }
`;

export const RightSection = styled.div`
  @media screen and (max-width: 1044px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AccessWrap = styled.div`
  @media screen and (max-width: 1044px) {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .openProject {
    background-color: ${(props) => props.theme.projectBgColor};
    color: ${(props) => props.theme.polarColor};
    width: 232px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    margin: 0;
    cursor: pointer;
    position: relative;
    transition: all 0.4s;
    &:hover {
      background-color: ${(props) => props.theme.polarColor};
      color: ${(props) => props.theme.paymentCardTextColor};
      border: 1px solid ${(props) => props.theme.paymentCardTextColor};
      label {
        color: ${(props) => props.theme.paymentCardTextColor};
      }
      svg {
        * {
          fill: ${(props) => props.theme.paymentCardTextColor};
        }
      }
    }
    .cutIcon {
      position: absolute;
      left: 35px;
      top: 8px;
    }
    label {
      padding: 3px 0px;
    }
  }
  .copyLink {
    background-color: ${(props) => props.theme.polarColor};
    border: 1px solid ${(props) => props.theme.paymentBorderColor};
    width: 232px;
    height: 40px;
    color: ${(props) => props.theme.paymentCardTextColor};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 12px 0px 24px;
    position: relative;
    transition: all 0.4s;
    label {
      padding: 3px 0px;
    }
    &:hover {
      background-color: ${(props) => props.theme.paymentBorderColor};
    }
    @media screen and (max-width: 1044px) {
      margin: 0px;
    }
    @media screen and (max-width: 767px) {
      margin: 12px 0px;
    }
    &:after {
      content: url(${STATIC_PATH}copy-link.svg);
      position: absolute;
      left: 35px;
      top: 8px;
    }
  }
`;

export const ImageWrap = styled.div`
  width: 100%;
  height: 312px;
  @media screen and (max-width: 1044px) {
    display: none;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 72px;
  border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
  padding: 0 50px;
  width: 100%;
  @media (max-width: 992px) {
    gap: 10px;
  }
  @media (max-width: 767px) {
    padding: 0px 20px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0px 30px;
  }
`;

export const SectionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  a {
    text-decoration: none;
  }
  .mobile-icon {
    width: 45px;
    height: 16px;
    display: none;
    @media (max-width: 576px) {
      display: block;
      z-index: 9999;
      position: relative;
      top: -2px;
    }
  }
  .login-link {
    border: 1px solid ${(props) => props.theme.headerWhiteBorder};
    padding: 5px 15px;
    border-radius: 4px;
    height: 36px;
    &:hover {
      background-color: ${(props) => props.theme.actionBgColor};
      color: ${(props) => props.theme.polarColor};
    }
  }
  .profile-icon {
    @media (max-width: 576px) {
      display: none;
    }
  }
  .download-btn {
    border-radius: 4px;
    &::before {
      content: url(${STATIC_PATH}download-icon.svg);
      position: absolute;
      left: 12px;
      top: 12px;
      transition: all 0.4s;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .contact-btn {
    background-color: ${(props) => props.theme.polarColor};
    color: ${(props) => props.theme.animoTextColor};
    border: 1px solid ${(props) => props.theme.contactBtnColor};
    border-radius: 4px;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(props) => props.theme.modalButtonBgColor};
      label {
        color: ${(props) => props.theme.polarColor};
      }
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  .upgrade-btns {
    position: relative;
    border-radius: 4px;
    label {
      padding: 2px 10px 2px 30px;
    }
    &::before {
      content: url(${STATIC_PATH}star.svg);
      position: absolute;
      left: 8px;
      top: 3px;
      transition: all 0.4s;
    }
    :hover::before {
      transform: scale(1.2);
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  .pricing {
    text-decoration: none;
  }
`;

export const Image = styled.img`
  width: 36px;
  object-fit: contain;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
`;

export const MobileHeader = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 65%;
  }
  @media (max-width: 576px) {
    width: 100%;
    display: none;
  }
`;

export const HeaderMain = styled.div`
  .header-toggle {
    @media (max-width: 576px) {
      padding: 0px 53px;
    }
  }
`;

export const ProfileWrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 60px;
  right: 10px;
  border-radius: 8px;
  width: 275px;
  box-shadow: 0px 4px 20px ${(props) => props.theme.menuBoxShawdow};
  z-index: 9999;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    right: 10px;
  }
  @media (max-width: 767px) {
    right: 8px;
  }
  @media (max-width: 576px) {
    width: 100%;
    right: 0;
    top: -17px;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 10px 0px 5px;
  margin: 10px;
  &::after {
    content: "";
    width: 250px;
    height: 1px;
    background-color: ${(props) => props.theme.secondaryColor};
    position: absolute;
    bottom: -10px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const UserImage = styled.img`
  width: 50px;
  height: 50px;
`;

export const OtherLink = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 15px;
  margin-bottom: 0px;
  list-style: none;
  border-radius: 0px 0px 8px 8px;
`;

export const Links = styled.li`
  padding: 12px 20px;
  cursor: pointer;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  &:hover {
    background-color: ${(props) => props.theme.liBgColor};
  }
`;

export const Scroll = styled.div`
  max-height: 200px;
  overflow: auto;
  @media screen and (max-width: 576px) {
    height: 430px;
    touch-action: auto;
  }
`;

export const UserPlan = styled.div`
  background: ${(props) =>
    `${props.theme.polarColor} 0% 0% no-repeat padding-box`};
  box-shadow: 0px 0px 1px 1px ${(props) => props.theme.headerWhiteBorder};
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 9;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "4px"};
  cursor: pointer;
  transform: all 0.4s;
  .user-plan {
    background-color: ${(props) => props.background};
    color: ${(props) => props.color};
    border-radius: 8px;
    width: fit-content;
    padding: ${(props) => (props.icons ? "4px 10px 4px 25px" : "0px 0px 5px")};
    margin-top: 5px;
    position: relative;
    &:before {
      content: url(${(props) => props.icons});
      left: 1px;
      position: absolute;
      top: -1px;
    }
  }
  &:hover {
    label:first-child {
      font-weight: 600;
    }
  }
`;

export const ProjectIcons = styled.div`
  width: 38px;
  height: 38px;
  background: 0% 0% no-repeat padding-box padding-box rgb(24, 178, 113);
  border-radius: 6px;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const CreateWorkspace = styled.div`
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${(props) => props.theme.headerWhiteBorder};
  box-shadow: rgba(0, 0, 0, 0.12) 0px -2px 8px;
  label {
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    padding: 10px 10px 10px 65px;
    &:hover {
      font-weight: 600;
    }
    &::before {
      content: "+";
      background-color: ${(props) => props.theme.contactBtnColor};
      position: absolute;
      width: 35px;
      height: 35px;
      text-align: center;
      font-size: 20px;
      color: ${(props) => props.theme.polarColor};
      border-radius: 8px;
      line-height: 33px;
      top: 2px;
      left: 10px;
    }
  }
`;

export const MobileWrapper = styled.div`
  position: fixed;
  z-index: 99;
  background-color: ${(props) => props.theme.polarColor};
  top: 72px;
  right: 10%;
  border-radius: 4px;
  width: 175px;
  border: 1px solid ${(props) => props.theme.headerWhiteBorder};
  z-index: 8;
  @media screen and (min-width: 577px) and (max-width: 767px) {
    right: 45%;
  }
`;

export const ContactSalesFormWrap = styled.div`
  width: 512px;
  height: 90%;
  position: absolute;
  padding: 20px;
  overflow: auto;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: ${(props) => props.theme.polarColor};
  .contact-btn {
    background: ${(props) => props.theme.headerColor};
    color: rgb(255, 255, 255);
    padding: 5px 20px;
  }
  ::-webkit-scrollbar {
    scrollbar-width: thin;
    display: none;
  }
  @media screen and (max-width: 767px) {
    left: 0%;
    touch-action: auto;
  }
  @media screen and (max-width: 576px) {
    left: 0%;
  }
`;

export const Error = styled.div`
  text-align: left;
  margin: 2px 0 10px;
  color: ${(props) => props.theme.warningColor};
  font: ${font.normalMicroLarge};
  transition: all 0.4s;
`;

export const DownloadHeader = styled.div`
  background: transparent ${(props) => props.theme.headerColor} 0% 0% no-repeat
    padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 65px;
`;

export const DownloadRow = styled.div`
  ${(props) =>
    props.as === "a" &&
    `
    text-decoration: none !important;
`}
  text-decoration: none !important;
  width: 280px;
  border: 1px solid ${(props) => props.theme.headerWhiteBorder};
  color: ${(props) => props.theme.animoTextColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s;
  p {
    font: ${font.normal_12};
    text-align: center;
    padding: 0px 25px;
  }
  .download-btn {
    background: ${(props) => props.theme.headerColor};
    color: ${(props) => props.theme.polarColor};
    padding: 5px 20px;
    width: 200px;
    label {
      padding: 3px 0px;
    }
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 13px ${(props) => props.theme.menuBoxShawdow};
  }
  @media screen and (max-width: 767px) {
    height: 100%;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: fit-content;
  width: max-content;
  box-shadow: 0px 0px 16px ${(props) => props.theme.rgbaLightBlack};
  border-radius: 8px !important;
  margin-bottom: 60px;
  background-color: ${(props) => props.theme.polarColor} !important;
  position: relative;
  max-width: 845px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  scrollbar-width: thin;
  padding: 0px 0px 30px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: fit-content;
    max-width: 100%;
    @media screen and (min-width: 577px) and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      left: 38%;
    }
    @media screen and (max-width: 576px) {
      left: 50%;
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 25px;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    gap: 35px;
    justify-content: unset;
    width: 100%;
    margin: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 5px;
    flex-direction: column;
    width: 100%;
  }
`;

export const CloseIcon = styled.img`
  float: right;
  width: 17px;
  height: 17px;
  margin: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

// Player controls components

export const PlayerContainer = styled.div`
  display: ${(props) => props.display || "flex"};
  position: absolute;
  z-index: 4;
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "94%"};
  background: ${(props) => props.theme.volumeBgColor} 0% 0% no-repeat
    padding-box;
  border-radius: 8px;
  opacity: 0.98;
  bottom: 8px;
  left: ${(props) => (props.isShorts ? "1%" : "3%")};
  justify-content: space-between;
  align-items: center;
  padding: 0px 2%;
  @media screen and (max-width: 767px) {
    height: 25px;
    #vmaker-icon {
      display: none;
    }
  }
`;

export const PlayTimer = styled.div`
  width: auto;
  height: 17px;
  text-align: left;
  font: ${font.normalMicroLarge_18};
  letter-spacing: 0px;
  color: ${(props) => props.theme.polarColor};
  opacity: 1;
  @media screen and (max-width: 767px) {
    font: ${font.normalMicroMini_18};
  }
`;

export const SeekBar = styled.div`
  width: ${(props) => (props.isFullScreen ? "82%" : "70%")};
  height: 4px;
  background-color: rgba(217, 223, 226, 0.48);
  border-radius: 2px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 65%;
  }
`;

export const Seek = styled.div`
  width: ${(props) => props.width || "0%"};
  height: inherit;
  background: ${(props) => props.theme.projectBgColor} 0% 0% no-repeat
    padding-box;
  border-radius: 2px 0px 0px 2px;
`;

export const Icons = styled.img`
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "24px"};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 767px) {
    width: ${(props) => props.mobileWidth || "15px"};
    height: ${(props) => props.mobileHeight || "15px"};
  }
`;

export const VolumeSlider = styled.div`
  position: absolute;
  bottom: 48px;
  height: 30px;
  width: 0px;
  overflow: hidden;
  background-color: ${(props) => props.theme.volumeBgColor};
  display: flex;
  justify-content: center;
  border-radius: 4px;
  transform: rotate(270deg);
  &:hover {
    width: 80px;
  }
`;

export const VolumeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    ${VolumeSlider} {
      width: 80px;
      transition: width 0.2s;
    }
  }
`;

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const BigPlayButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background: ${(props) => props.theme.projectBgColor};
  border-radius: 8px;
  width: 100px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

export const PlayerControls = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    ${BigPlayButton} {
      display: flex;
    }
  }
`;

export const Loader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
