/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */

import { createUndoMiddleware } from "redux-undo-redo";
import { fromJS } from "immutable";
import ActionTypes from "../../constants/action-types";
import {
  applyFrameClipPlot,
  syncText,
  undoColor,
  undoFlip,
  undoMove,
  undoTextEffects,
  undoTimelineTimeUpdate,
  undoUpdateSubtitleList,
  updateObject,
} from "../actions/timelineUtils";
import { ITEM_CONFIG } from "../../constants/config";
import { switchSubtitle } from "../actions/appUtils";

const TIMELINE_UNDO_ACTION = {
  action: (action, meta) => {
    const payload = { data: meta };
    if (action.payload.actionRecovery) {
      payload.actionRecovery = action.payload.actionRecovery;
    }
    return undoTimelineTimeUpdate(payload);
  },
  createArgs: (state, action) => {
    let undoResult = fromJS({});
    const { projectDetails } = state;
    let updaterObject = action.autosaveResult;

    if (action.type === ActionTypes.BULK_OBJECT_UPDATE) {
      updaterObject = fromJS(action.payload);
    }

    for (const [container, containerData] of updaterObject.entrySeq()) {
      if (container === "subtitle") {
        undoResult = undoResult.setIn(["subtitle", "id"], containerData.get("id"));
        if (containerData.has("subtitleGlobal")) {
          for (const key of containerData.get("subtitleGlobal").keySeq()) {
            const value = projectDetails.getIn(["subtitle", key]) || null;
            undoResult = undoResult.setIn(["subtitle", "subtitleGlobal", key], value);
          }
        }
        if (containerData.has("subtitleDropData")) {
          for (const [dropId, subtitleDropUpdater] of containerData.get("subtitleDropData").entrySeq()) {
            if (subtitleDropUpdater.get("isNew")) {
              if (projectDetails.getIn(["subtitle", "data", dropId])) {
                // handle auto generate overwrite
                const dropData = projectDetails.getIn(["subtitle", "data", dropId]);
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleDropData", dropId, "isNew"],
                  true
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleDropData", dropId, "data"],
                  dropData
                );
              } else {
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleDropData", dropId, "isDelete"],
                  true
                );
              }
            } else if (subtitleDropUpdater.get("isDelete")) {
              const dropData = projectDetails.getIn([
                "subtitle",
                "data",
                dropId,
              ]);

              undoResult = undoResult.setIn(
                ["subtitle", "subtitleDropData", dropId, "isNew"],
                true
              );
              undoResult = undoResult.setIn(
                ["subtitle", "subtitleDropData", dropId, "data"],
                dropData
              );
            } else {
              // no need to implement this else case, pls use `subtitleData` container
            }
          }
        }
        if (containerData.has("subtitleData")) {
          for (const [dropId, subtitleItems] of containerData.get("subtitleData").entrySeq()) {
            for (const [
              subtitleItemId,
              subtitleUpdater,
            ] of subtitleItems.entrySeq()) {
              const timelineId = subtitleUpdater.get("timelineId");
              if (subtitleUpdater.get("isNew") && subtitleUpdater.get("isReplace")) {
                const item = projectDetails.getIn([
                  "subtitle",
                  "data",
                  dropId,
                  subtitleItemId,
                ]);
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "timelineId"],
                  timelineId
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "isNew"],
                  true
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "isReplace"],
                  true
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "data"],
                  item
                );
              } else if (subtitleUpdater.get("isNew")) {
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "timelineId"],
                  timelineId
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "isDelete"],
                  true
                );
              } else if (subtitleUpdater.get("isDelete")) {
                const item = projectDetails.getIn([
                  "subtitle",
                  "data",
                  dropId,
                  subtitleItemId,
                ]);

                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "timelineId"],
                  timelineId
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "isNew"],
                  true
                );
                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "data"],
                  item
                );
              } else {
                const item = projectDetails.getIn([
                  "subtitle",
                  "data",
                  dropId,
                  subtitleItemId,
                ]);

                undoResult = undoResult.setIn(
                  ["subtitle", "subtitleData", dropId, subtitleItemId, "timelineId"],
                  timelineId
                );
                for (const dataKey of subtitleUpdater.get("data").keySeq()) {
                  const currentValue = item.get(dataKey);
                  undoResult = undoResult.setIn(
                    [
                      "subtitle",
                      "subtitleData",
                      dropId,
                      subtitleItemId,
                      "data",
                      dataKey,
                    ],
                    currentValue
                  );
                }
              }
            }
          }
        }
      } else if (container === "project") {
        for (const key of containerData.get("data").keySeq()) {
          if (key === "duration") {
            continue;
          }
          const keyInStore = key === "subtitle_data" ? "subtitleData" : key;
          const currentData = projectDetails.get(keyInStore);
          undoResult = undoResult.setIn([container, "data", key], currentData);
        }
      } else {
        for (const [itemId, itemUpdater] of containerData.entrySeq()) {
          let item;
          if (state.app.get("swapDetails") && itemId === state.app.getIn(["swapDetails", "id"])) {
            item = state.app.get("swapDetails");
          } else {
            item = projectDetails.getIn([container, itemId]);
          }
          if (itemUpdater.get("isDelete")) {
            let deletedItem = item;

            if (
              action.payload.isTextEmptyData &&
              deletedItem.get("type") === "TEXT" &&
              action.payload.toUpdate.length === 1
            ) {
              const beforeEmpty = state.app.getIn([
                "lastEditText",
                "beforeEmpty",
              ]);
              const prevX = state.app.getIn(["lastEditText", "prevX"]);
              const prevY = state.app.getIn(["lastEditText", "prevY"]);
              const prevWidth = state.app.getIn(["lastEditText", "prevWidth"]);
              const prevHeight = state.app.getIn([
                "lastEditText",
                "prevHeight",
              ]);

              if (
                beforeEmpty &&
                beforeEmpty !== "" &&
                // undefined/null
                prevX != null &&
                prevY != null &&
                prevWidth != null &&
                prevHeight != null
              ) {
                deletedItem = deletedItem
                  .setIn(["textData", "htmlText"], beforeEmpty)
                  .set("x", prevX)
                  .set("y", prevY)
                  .set("width", prevWidth)
                  .set("height", prevHeight);
              }
            }

            undoResult = undoResult.setIn(
              [container, itemId, "data"],
              deletedItem
            );
            undoResult = undoResult.setIn([container, itemId, "isNew"], true);
          } else if (itemUpdater.get("isNew") && itemUpdater.get("isReplace")) {
            undoResult = undoResult.setIn(
              [container, itemId, "data"],
              item
            );
            undoResult = undoResult.setIn([container, itemId, "isNew"], true);
            undoResult = undoResult.setIn([container, itemId, "isReplace"], true);
          } else if (itemUpdater.get("isNew")) {
            undoResult = undoResult.setIn(
              [container, itemId, "isDelete"],
              true
            );
          } else {
            const itemUpdateData = itemUpdater.get("data");
            let itemUndoUpdater = fromJS({});

            for (const [dataKey, value] of itemUpdateData.entrySeq()) {
              let currentData = item.get(dataKey);

              if (dataKey === "frameClipImage") {
                const imgDetails = item.getIn([
                  "clipDetails",
                  value.get("clipId"),
                  "imgDetails",
                ]);
                currentData = value.set("imgDetails", imgDetails);
              } else if (dataKey === "radius" && !currentData) {
                currentData = fromJS({
                  tl: 0,
                  tr: 0,
                  br: 0,
                  bl: 0,
                  linked: true,
                });
              } else if (dataKey === "detachedAudioId" && currentData === undefined) {
                currentData = null;
              } else if (dataKey === "isAudioDetached" && currentData === undefined) {
                currentData = false;
              } else if (dataKey === "transitionExitId" && currentData === undefined) {
                currentData = "none"
              } else if (dataKey === "transitionEnterId" && currentData === undefined) {
                currentData = "none"
              } else if (dataKey === "chromaKey" && currentData === undefined) {
                currentData = null
              } else if (dataKey === "enableBgRemoval" && currentData === undefined) {
                currentData = false
              } else if (dataKey === "tolerance" && currentData === undefined) {
                currentData = 100
              }

              itemUndoUpdater = itemUndoUpdater.setIn(
                ["data", dataKey],
                currentData
              );
            }

            undoResult = undoResult.setIn([container, itemId], itemUndoUpdater);
          }
        }
      }
    }
    return undoResult;
  },
};

const UPDATE_ITEM_UNDO_ACTION = {
  action: (action, meta) => {
    return updateObject(meta);
  },
  createArgs: (state, action) => {
    const { selectedItem, container } = action.payload;
    let toUpdate = fromJS({});

    for (const key of Reflect.ownKeys(action.payload.toUpdate)) {
      const selectedItemData = state.projectDetails.getIn([
        container,
        selectedItem,
      ]);
      if (key === "isCropped" && !selectedItemData.has("isCropped")) {
        toUpdate = toUpdate.set(key, false);
      } else if (key === "original" && !selectedItemData.has("original")) {
        toUpdate = toUpdate.set(
          key,
          fromJS({ x: 0, y: 0, width: 1, height: 1 })
        );
      } else {
        toUpdate = toUpdate.set(key, selectedItemData.get(key));
      }
    }

    toUpdate = toUpdate.toJS();
    return { selectedItem, container, toUpdate };
  },
};

const UPDATE_SUBTITLE_LIST_UNDO_ACTION = {
  action: (action, meta) => {
    return undoUpdateSubtitleList(meta);
  },
  createArgs: (state) => {
    return {
      subtitleList: state.projectDetails.get("subtitleData"),
    };
  },
};

export default createUndoMiddleware({
  revertingActions: {
    [ActionTypes.UPDATE_TIMELINE]: TIMELINE_UNDO_ACTION,
    [ActionTypes.BULK_OBJECT_UPDATE]: TIMELINE_UNDO_ACTION,
    [ActionTypes.MOVE_SUBTITLE]: TIMELINE_UNDO_ACTION,
    [ActionTypes.ADD_OBJECT]: TIMELINE_UNDO_ACTION,
    [ActionTypes.DELETE_OBJECT]: TIMELINE_UNDO_ACTION,
    [ActionTypes.INSERT_SUBTITLE_DATA]: {
      action: (action, meta) => {
        if (meta.switchSubtitle) {
          return switchSubtitle(meta);
        }
        return TIMELINE_UNDO_ACTION.action(action, meta);
      },
      createArgs: (state, action) => {
        const currentSubtitleId = state.projectDetails.get("defaultSubtitle");
        if (
          currentSubtitleId &&
          currentSubtitleId !== action.payload.subtitleId
        ) {
          // language is switched
          action.switchSubtitle = true; // used for redo
          return {
            switchSubtitle: true,
            subtitleId: currentSubtitleId,
          };
        }
        return TIMELINE_UNDO_ACTION.createArgs(state, action);
      },
    },
    [ActionTypes.UPDATE_SUBTITLE_DATA]: TIMELINE_UNDO_ACTION,
    [ActionTypes.MOVE_UPDATE]: {
      action: (action, meta) => {
        return undoMove(meta);
      },
      createArgs: (state, action) => {
        const prevData = action.payload;
        const { currentContainer } = prevData;
        const workspaceItems = {};
        const workspaceChildren = {};

        prevData.selectedItems.entrySeq().forEach(([, id]) => {
          const item = state.projectDetails.getIn(["workspaceItems", id]);
          workspaceItems[id] = { x: item.get("x"), y: item.get("y") };

          if (item.get("type") === "GROUP") {
            item
              .get("groupChildren")
              .entrySeq()
              .forEach(([, childId]) => {
                const childItem = state.projectDetails.getIn([
                  "workspaceChildren",
                  childId,
                ]);
                const childToUpdate = {
                  x: childItem.get("x"),
                  y: childItem.get("y"),
                };
                workspaceChildren[childId] = childToUpdate;
              });
          }
        });

        return { workspaceItems, workspaceChildren, currentContainer };
      },
    },
    [ActionTypes.RESIZE_UPDATE]: {
      action: (action, meta) => {
        return undoMove(meta);
      },
      createArgs: (state, action) => {
        const prevData = action.payload;
        const { currentContainer } = prevData;
        const workspaceItems = {};
        const workspaceChildren = {};

        prevData.selectedItems.entrySeq().forEach(([, id]) => {
          const item = state.projectDetails.getIn(["workspaceItems", id]);
          workspaceItems[id] = {
            x: item.get("x"),
            y: item.get("y"),
            width: item.get("width"),
            height: item.get("height"),
          };

          if (prevData.isOneDirectionalScale) {
            workspaceItems[id].isCropped =
              item.get("isCropped") !== undefined
                ? item.get("isCropped")
                : false;
            if (item.get("original") !== undefined) {
              workspaceItems[id].original = item.get("original").toJS();
            } else {
              workspaceItems[id].original = { x: 0, y: 0, width: 1, height: 1 }; // sending undefined will not remove this from project json but will get removed in local
            }
          }

          if (item.get("type") === "TEXT" || item.get("type") === "SHAPE")
            workspaceItems[id].textData = item.get("textData").toJS();

          if (item.get("type") === "GRID") {
            workspaceItems[id].gridData = item.get("gridData").toJS();
          }

          if (item.get("type") === "GROUP") {
            item
              .get("groupChildren")
              .entrySeq()
              .forEach(([, childId]) => {
                const childItem = state.projectDetails.getIn([
                  "workspaceChildren",
                  childId,
                ]);
                workspaceChildren[childId] = {
                  x: childItem.get("x"),
                  y: childItem.get("y"),
                  width: childItem.get("width"),
                  height: childItem.get("height"),
                };

                if (
                  childItem.get("type") === "TEXT" ||
                  childItem.get("type") === "SHAPE"
                )
                  workspaceChildren[childId].textData = childItem
                    .get("textData")
                    .toJS();

                if (childItem.get("type") === "GRID") {
                  workspaceItems[id].gridData = childItem
                    .get("gridData")
                    .toJS();
                }
              });
          }
        });

        return {
          workspaceItems,
          workspaceChildren,
          currentContainer,
          resizing: true,
        };
      },
    },
    [ActionTypes.ROTATION_UPDATE]: {
      action: (action, meta) => {
        return undoMove(meta);
      },
      createArgs: (state, action) => {
        const prevData = action.payload;
        const { currentContainer } = prevData;
        const workspaceItems = {};
        const workspaceChildren = {};

        prevData.selectedItems.entrySeq().forEach(([, id]) => {
          const item = state.projectDetails.getIn(["workspaceItems", id]);
          workspaceItems[id] = {
            x: item.get("x"),
            y: item.get("y"),
            angle: item.get("angle"),
          };

          if (item.get("type") === "GROUP") {
            item
              .get("groupChildren")
              .entrySeq()
              .forEach(([, childId]) => {
                const childItem = state.projectDetails.getIn([
                  "workspaceChildren",
                  childId,
                ]);
                workspaceChildren[childId] = {
                  x: childItem.get("x"),
                  y: childItem.get("y"),
                  angle: childItem.get("angle"),
                };
              });
          }
        });

        return { workspaceItems, workspaceChildren, currentContainer };
      },
    },
    [ActionTypes.CROP_IMAGE]: UPDATE_ITEM_UNDO_ACTION,
    [ActionTypes.UPDATE_ITEM]: UPDATE_ITEM_UNDO_ACTION,
    [ActionTypes.UPDATE_GROUP_TEXT]: {
      action: (action, meta) => {
        return undoMove(meta);
      },
      createArgs: (state, action) => {
        const { groupId, currentId } = action.payload;
        const currentContainer = "workspaceItems";

        const childIds = state.projectDetails.getIn([
          "workspaceItems",
          groupId,
          "groupChildren",
        ]);
        const children = state.projectDetails
          .getIn(["workspaceChildren"])
          .filter((obj, key) => childIds.keyOf(key) !== undefined);
        let workspaceItems = fromJS({});
        let workspaceChildren = fromJS({});

        if (action.payload.isGrouped) {
          children.entrySeq().forEach(([key, item]) => {
            workspaceChildren = workspaceChildren.setIn(
              [key, "y"],
              item.get("y")
            );
            workspaceChildren = workspaceChildren.setIn(
              [key, "height"],
              item.get("height")
            );
            if (key === currentId && action.payload.textData !== undefined) {
              if (action.payload.prevHtml != null) {
                item = item.setIn(
                  ["textData", "htmlText"],
                  action.payload.prevHtml
                );
              }
              workspaceChildren = workspaceChildren.setIn(
                [key, "textData"],
                item.get("textData")
              );
            }
          });

          workspaceItems = workspaceItems.setIn(
            [groupId, "y"],
            state.projectDetails.getIn(["workspaceItems", groupId, "y"])
          );
          workspaceItems = workspaceItems.setIn(
            [groupId, "height"],
            state.projectDetails.getIn(["workspaceItems", groupId, "height"])
          );
        }

        return { workspaceItems, workspaceChildren, currentContainer };
      },
    },
    [ActionTypes.SYNC_TEXT]: {
      action: (action, meta) => {
        return syncText(meta);
      },
      createArgs: (state, action) => {
        const { selectedItem, container } = action.payload;
        let toUpdate = fromJS({});
        const item = state.projectDetails.getIn([container, selectedItem]);
        for (const key of Reflect.ownKeys(action.payload.toUpdate)) {
          toUpdate = toUpdate.set(key, item.get(key));
        }

        // code taken from animaker deck. not sure why type checking is not done, please dont try to fix it!
        /* eslint-disable eqeqeq */
        if (action.payload.prevHtml != "" && action.payload.prevHtml != null) {
          toUpdate = toUpdate.setIn(
            ["textData", "htmlText"],
            action.payload.prevHtml
          );
        }

        toUpdate = toUpdate.toJS();
        toUpdate.width = item.get("width");
        toUpdate.height = item.get("height");
        toUpdate.x = item.get("x");
        toUpdate.y = item.get("y");

        if (
          action.payload.prevWidth != "" &&
          action.payload.prevWidth != null &&
          action.payload.prevHeight != "" &&
          action.payload.prevHeight != null &&
          action.payload.prevX != "" &&
          action.payload.prevX != null &&
          action.payload.prevY != "" &&
          action.payload.prevY != null
        ) {
          toUpdate.width = action.payload.prevWidth;
          toUpdate.height = action.payload.prevHeight;
          toUpdate.x = action.payload.prevX;
          toUpdate.y = action.payload.prevY;
        }
        /* eslint-enable eqeqeq */

        return {
          selectedItem,
          container,
          toUpdate,
          isTyping: action.payload.isTyping,
          fromUndo: true,
        };
      },
    },
    [ActionTypes.APPLY_FRAME_CLIP_PLOT]: {
      action: (action, meta) => {
        return applyFrameClipPlot(meta);
      },
      createArgs: (state, action) => {
        const data = { ...action.payload };
        data.original = state.projectDetails.getIn([
          action.payload.container,
          action.payload.id,
          "clipDetails",
          action.payload.clipId,
          "imgDetails",
          "original",
        ]);
        return data;
      },
    },
    [ActionTypes.APPLY_FLIP]: {
      action: (action, meta) => {
        return undoFlip(meta);
      },
      createArgs: (state, action) => {
        const updateList = fromJS(action.payload);
        const toUpdate = [];

        for (const updater of updateList.valueSeq()) {
          const id = updater.get("id");
          const container = updater.get("container");
          const clipId = updater.get("clipId");
          const data = updater.get("data");

          let itemData = state.projectDetails.getIn([container, id]);
          if (clipId) {
            itemData = state.projectDetails.getIn([
              container,
              id,
              "clipDetails",
              clipId,
              "imgDetails",
            ]);
          }

          const undoUpdater = {
            id,
            container,
            clipId,
            data: {},
          };

          for (const key of data.keySeq()) {
            let prevVal = itemData.get(key);

            if (prevVal === undefined && key === "filter") {
              prevVal = ITEM_CONFIG.IMAGE.DEFAULT_FILTER;
            } else if (prevVal === undefined && key === "flipPosition") {
              prevVal = 0;
            }

            undoUpdater.data[key] = prevVal;
          }

          toUpdate.push(undoUpdater);
        }

        return toUpdate;
      },
    },
    [ActionTypes.CHANGE_AUDIO_VOLUME]: {
      action: (action, meta) => {
        return undoFlip(meta);
      },
      createArgs: (state, action) => {
        const { id } = action.payload;
        const audioItem = state.projectDetails.getIn(["audios", id]);

        const undoObj = {
          container: "audios",
          id,
          data: {
            volume: audioItem.get("volume"),
            fadeDetails: audioItem.get("fadeDetails"),
          },
        };

        return [undoObj];
      },
    },
    [ActionTypes.APPLY_COLOR]: {
      action: (action, meta) => {
        return undoColor(meta);
      },
      createArgs: (state, action) => {
        const updateList = fromJS(action.payload);
        const toUpdate = [];
        const { projectDetails } = state;

        for (const updater of updateList.valueSeq()) {
          const container = updater.get("container");
          const replaceAllColor = updater.get("replaceAllColor"); // for theme
          const colorKey = updater.get("colorKey");

          const undoUpdater = {
            container,
            replaceAllColor,
            colorKey,
          };

          if (
            container === "workspaceItems" ||
            container === "workspaceChildren"
          ) {
            const id = updater.get("id");
            const isFrameClip = updater.get("isFrameClip");
            const isFrameSVG = updater.get("isFrameSVG");

            undoUpdater.id = id;
            undoUpdater.isFrameClip = isFrameClip;
            undoUpdater.isFrameSVG = isFrameSVG;

            if (isFrameClip) {
              // frame clip
              undoUpdater.color = projectDetails.getIn([
                container,
                id,
                "clipDetails",
                colorKey,
                "imgDetails",
                "color",
              ]);
              if (!undoUpdater.color) {
                undoUpdater.color = "";
              }
            } else if (isFrameSVG) {
              // frame svg path
              undoUpdater.color = projectDetails.getIn([
                container,
                id,
                "d",
                colorKey,
                "fill",
              ]);
            } else if (replaceAllColor) {
              // item theme change or reset
              undoUpdater.colors = projectDetails.getIn([
                container,
                id,
                "colors",
              ]);
            } else {
              // item specific color change
              undoUpdater.color = projectDetails.getIn([
                container,
                id,
                "colors",
                colorKey,
              ]);
            }
          } else if (container === "bgColor") {
            if (replaceAllColor) {
              // reset
              undoUpdater.colors = projectDetails.getIn(["bgColor", "colors"]);
            } else {
              undoUpdater.color = projectDetails.getIn([
                "bgColor",
                "colors",
                colorKey,
              ]);
            }
          }

          toUpdate.push(undoUpdater);
        }

        return toUpdate;
      },
    },
    [ActionTypes.UPDATE_TEXT]: {
      action: (action, meta) => {
        return updateObject(meta);
      },
      createArgs: (state, action) => {
        const { id, container } = action.data;
        const item = state.projectDetails.getIn([container, id]);
        const toUpdate = {
          textData: item.get("textData").toJS(),
          x: item.get("x"),
          y: item.get("y"),
          width: item.get("width"),
          height: item.get("height"),
        };

        if (item.get("yRatio") !== undefined)
          toUpdate.yRatio = item.get("yRatio");
        if (item.get("heightRatio") !== undefined)
          toUpdate.heightRatio = item.get("heightRatio");

        return { selectedItem: id, container, toUpdate };
      },
    },
    [ActionTypes.UPDATE_ALL_TEXTS]: {
      action: (action, meta) => {
        return undoFlip(meta);
      },
      createArgs: (state) => {
        const textApplyAllUndoData = [];
        const processedTextIds = {};
        const textApplyAllData = state.app.get("textApplyAllData");

        Array.from(textApplyAllData).forEach((data) => {
          if (processedTextIds[data.id] !== undefined) return;

          const textItem = state.projectDetails.getIn([
            data.container,
            data.id,
          ]);
          let newTextData = fromJS({});

          // reverting the html content of the concerned text container
          const textCont = document.querySelector(
            `#${data.id} .text-container`
          );
          const prevHtmlText = textItem.getIn(["textData", "htmlText"]);
          textCont.innerHTML = prevHtmlText;
          //

          newTextData = newTextData.set(
            "htmlText",
            textItem.getIn(["textData", "htmlText"])
          );
          newTextData = newTextData.set(
            "formats",
            textItem.getIn(["textData", "formats"])
          );
          if (textItem.getIn(["textData", "effects"])) {
            newTextData = newTextData.set(
              "effects",
              textItem.getIn(["textData", "effects"])
            );
          } else {
            newTextData = newTextData.set("effects", fromJS({ None: "" }));
          }

          const toUpdate = { textData: newTextData.toJS() };
          toUpdate.width = textItem.get("width");
          toUpdate.height = textItem.get("height");

          textApplyAllUndoData.push({
            id: data.id,
            container: "workspaceItems",
            data: toUpdate,
          });

          processedTextIds[data.id] = 1;
        });

        return textApplyAllUndoData;
      },
    },
    [ActionTypes.UPDATE_TEXT_EFFECTS]: {
      action: (action, meta) => {
        return undoTextEffects(meta);
      },
      createArgs: (state, action) => {
        const { id, container } = action.payload;
        const item = state.projectDetails.getIn([container, id]);
        const textData = item.get("textData").toJS();

        return { id, container, textData };
      },
    },
    [ActionTypes.UPDATE_SUBTITLE_LIST]: UPDATE_SUBTITLE_LIST_UNDO_ACTION,
    [ActionTypes.SWITCH_SUBTITLE]: UPDATE_SUBTITLE_LIST_UNDO_ACTION,
  },
});
