/* eslint-disable operator-assignment */

/**
 * Function to get toolbar position in workspace wrapper
 * @param {object} workspaceStage
 */
export const getToolBarStyle = (workspaceStage) => {
    const toolTop = workspaceStage.getIn(["top", "menu"]);
    const toolLeft = workspaceStage.getIn(["left", "menu"]) + workspaceStage.getIn(["left", "menuBorder"]) + workspaceStage.getIn(["left", "library"]);
    const toolRight = 0;
    const toolWidth = (window.innerWidth - toolRight) - toolLeft;

    return {
        left: toolLeft,
        top: toolTop,
        width: toolWidth,
    };
}

/**
 * @param {number} flipPosition 
 */
export const flipPositionToState = (flipPosition) => {
    const flipState = {
        isHorizontalFlip: false,
        isVerticalFlip: false,
    };

    if (!Number.isFinite(flipPosition)) {
        flipPosition = 0;
    }

    if (flipPosition === 1 || flipPosition === 3) {
        flipState.isHorizontalFlip = true;
    }
    if (flipPosition === 2 || flipPosition === 3) {
        flipState.isVerticalFlip = true;
    }

    return flipState;
}

/**
 * @param {{ isHorizontalFlip: boolean, isVerticalFlip: boolean }} flipState 
 */
export const flipStateToPosition = (flipState) => {
    let flipPosition = 0;

    if (flipState.isHorizontalFlip && flipState.isVerticalFlip) {
        flipPosition = 3;
    } else if (flipState.isHorizontalFlip) {
        flipPosition = 1;
    } else if (flipState.isVerticalFlip) {
        flipPosition = 2;
    }

    return flipPosition;
}

/**
 * @param {object} params
 * @param {number} params.angle
 * @param {"clockwise" | "anticlockwise"} params.direction
 * @param {number} params.interval
 */
export const getNextRotation = (params) => {
    const { angle, direction = "clockwise", interval = 90 } = params;
    let newAngle = angle;

    if (direction === "anticlockwise") {
        newAngle = newAngle - interval;
    } else if (direction === "clockwise") {
        newAngle = newAngle + interval;
    }

    newAngle = newAngle % 360;
    if (newAngle < 0) {
        newAngle = 360 + newAngle;
    }

    return newAngle;
}

/**
 * @param {number} angle
 * @param {boolean} reverse true - display value to state value, false - vise versa
 */
export const rotationDisplayValue = (angle, reverse = false) => {
    if (reverse) {
        if (angle < 0) {
            angle = angle + 360;
        }
    } else if (!reverse) {
        if (angle > 180) {
            angle = angle - 360;
        }
    }
    return angle;
}
