import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import CustomTooltipComponent from "./CustomTooltip";
import vmTheme from "../constants/theme";

const Button = styled.div`
  width: ${(props) => props.width || "70px"};
  height: 36px;
  background: ${(props) => `${props.background}0% 0% no-repeat padding-box`};
  box-shadow: 0px 0.5px 1px #00000029;
  border: 1px solid #e3e4e8;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
  ${(props) =>
    props.isActive &&
    css`
      background: ${(props) => props.activeBg};
      path {
        fill: #ffffff;
      }
    `};
  ${(props) =>
    !props.isDisabled &&
    css`
      &:hover {
        border-color: ${(props) => props.activeBg};
      }
    `}
  img {
    width: 20px;
    height: 20px;
  }
`;

const TextStyleButton = ({
  iconType,
  tooltipLangId,
  src,
  isActive,
  onClick,
  isDisabled,
  width,
}) => {
  const TOOLTIP_ID = `tt-txtsettings-${iconType}`;
  const theme = useSelector((state) => state.app.get("theme"));
  return tooltipLangId ? (
    <CustomTooltipComponent
      tooltipId={TOOLTIP_ID}
      dataTooltip={tooltipLangId}
      tooltipPosition={"bottom"}
    >
      <Button
        data-tooltip-id={TOOLTIP_ID}
        isDisabled={isDisabled}
        background={vmTheme[theme].polarColor}
        activeBg={vmTheme[theme].secondaryBorderColor}
        isActive={isActive}
        onClick={!isDisabled ? onClick : undefined}
        width={width}
      >
        <img src={src} alt="icon-btn" />
      </Button>
    </CustomTooltipComponent>
  ) : (
    <Button
      isDisabled={isDisabled}
      background={vmTheme[theme].polarColor}
      activeBg={vmTheme[theme].secondaryBorderColor}
      isActive={isActive}
      onClick={!isDisabled ? onClick : undefined}
      width={width}
    >
      <img src={src} alt="icon-btn" />
    </Button>
  );
};

TextStyleButton.propTypes = {
  iconType: PropTypes.string,
  tooltipLangId: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  src: PropTypes.string,
  width: PropTypes.string,
};

export default TextStyleButton;
