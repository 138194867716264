import React, { useMemo } from "react";
import { Provider } from "react-redux";
import ShortsPlayer from "./shorts-player";
import createStore from "../../redux/createStore";
import Upgrade from "../upgrade/upgrade";
import SupportForm from "../upgrade/supportForm";

/**
 * Component to create seperate store for each player.
 * @param {params: string, projectDetails: object, userDetails: object, shortData: object} props
 * @returns
 */
const ShortsWrapper = (props) => {
  const newStore = useMemo(() => createStore(true), []);
  return (
    <Provider store={newStore}>
      <ShortsPlayer {...props} />
      <Upgrade />
      <SupportForm />
    </Provider>
  );
};

export default ShortsWrapper;
