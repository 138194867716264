import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Modal } from "../../common-components/modal";
import SubtitlePreset from "../panels/propertywindow/subtitle/subtitle-preset";
import Typography from "../../common-components/Typography";
import content from "../../constants/content";
import { font } from "../../constants/font";
import vmTheme from "../../constants/theme";

const ShortsPreset = ({ isShowModal, onClose }) => {
  const theme = useSelector((state) => state.app.get("theme"));
  return (
    <Modal
      showModal={isShowModal}
      width="484px"
      height="512px"
      onClose={onClose}
    >
      <Typography
        content={content.SUBTITLE_STYLE}
        color={vmTheme[theme].tabTextColor}
        font={font.bold_14}
        display="block"
        padding="24px"
      />
      <SubtitlePreset height="82px" width="144px" />
    </Modal>
  );
};

ShortsPreset.propTypes = {
  isShowModal: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ShortsPreset;
