export const hexToRGB = (hex) => {
  const normalizedHex = hex.replace("#", "");
  const r = parseInt(normalizedHex.substr(0, 2), 16);
  const g = parseInt(normalizedHex.substr(2, 2), 16);
  const b = parseInt(normalizedHex.substr(4, 2), 16);
  return [r, g, b];
};

export const RGBToHex = (rgb) => {
  const [r, g, b] = rgb;
  const hexR = r.toString(16).padStart(2, "0");
  const hexG = g.toString(16).padStart(2, "0");
  const hexB = b.toString(16).padStart(2, "0");
  return `#${hexR}${hexG}${hexB}`;
};

export const interpolateColors = (startColor, endColor, factor) => {
  const startRGB = hexToRGB(startColor);
  const endRGB = hexToRGB(endColor);

  const interpolatedRGB = startRGB.map((startValue, index) => {
    const endValue = endRGB[index];
    const interpolatedValue = Math.round(
      startValue + factor * (endValue - startValue)
    );
    return interpolatedValue;
  });

  return RGBToHex(interpolatedRGB);
};
const getColorAtPercentage = (gradient, percentage) => {
  const colorStops = gradient.match(/#[a-fA-F0-9]{6}\s*\d*%?/g);

  let startColorStop;
  let endColorStop;
  for (let i = 0; i < colorStops.length - 1; i += 1) {
    const currentStop = colorStops[i];
    const nextStop = colorStops[i + 1];

    const currentPercentage = parseFloat(currentStop.match(/\d+\.?\d*%/)[0]);
    const nextPercentage = parseFloat(nextStop.match(/\d+\.?\d*%/)[0]);

    if (percentage >= currentPercentage && percentage <= nextPercentage) {
      startColorStop = currentStop;
      endColorStop = nextStop;
      break;
    }
  }

  const startColor = startColorStop.match(/#[a-fA-F0-9]{6}/)[0];
  const endColor = endColorStop.match(/#[a-fA-F0-9]{6}/)[0];

  const startPercentage = parseFloat(startColorStop.match(/\d+\.?\d*%/)[0]);
  const endPercentage = parseFloat(endColorStop.match(/\d+\.?\d*%/)[0]);

  const interpolationFactor =
    (percentage - startPercentage) / (endPercentage - startPercentage);

  const interpolatedColor = interpolateColors(
    startColor,
    endColor,
    interpolationFactor
  );

  return interpolatedColor;
};

export default getColorAtPercentage;

export const isValidHexColor = (hexCode) => {
  // Define a regular expression pattern for a valid hex color code.
  const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/;

  // Use the test() method to check if the provided string matches the pattern.
  return hexColorPattern.test(hexCode);
};
