import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import Backdrop from "./BackDrop";
import Spinner, { SPINNER_TYPE } from "./Spinner";
import Typography from "./Typography";
import { font } from "../constants/font";

const Container = styled.div`
  .spinner-wrapper {
    height: 50px;
    width: 75px;
    margin: auto;
  }
`;

const BackdropLoader = ({ content }) => {
  return (
    <Backdrop
      background="rgba(0,0,0,0.5)"
      height="100vh"
      width="100vw"
      zIndex={4}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <div className="spinner-wrapper">
          <Spinner type={SPINNER_TYPE.ELLIPSIS_SPINNER} />
        </div>
        <Typography
          innerContent={content}
          display="block"
          color={"white"}
          font={font.boldSmall_22}
        />
      </Container>
    </Backdrop>
  );
};

BackdropLoader.propTypes = {
  content: PropTypes.string,
};

export default BackdropLoader;
