import React from "react";
import Loadable from "react-loadable";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";

export const PropertyPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'property-panel' */ "../containers/panels/library-panels/property-panel/property-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const VideoPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'video-panel' */ "../containers/panels/library-panels/video-panel/video-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const ImagePanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'image-panel' */ "../containers/panels/library-panels/image-panel/image-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const UnsplashPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'unsplash-panel' */ "../containers/panels/library-panels/unsplash-panel/unsplash-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const PexelsPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'pexels-panel' */ "../containers/panels/library-panels/pexels-panel/pexels-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const PixabayPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'pixabay-panel' */ "../containers/panels/library-panels/pixabay-panel/pixabay-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const PexelsVideoPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'pexels-video-panel' */ "../containers/panels/library-panels/pexels-video-panel/pexels-video-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const PixabayVideoPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'pixabay-video-panel' */ "../containers/panels/library-panels/pixabay-video-panel/pixabay-video-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const UploadPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'upload-panel' */ "../containers/panels/library-panels/upload-panel/upload-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const TextPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'text-panel' */ "../containers/panels/library-panels/text-panel/text-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const MusicPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'music-panel' */ "../containers/panels/library-panels/music-panel/music-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const TransitionPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'transition-panel' */ "../containers/panels/library-panels/transition-panel/transition-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const FavoritesPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'favorites-panel' */ "../containers/panels/library-panels/favorites-panel/favorites-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const GiphyPanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'giphy-loader'  */ "../containers/panels/library-panels/giphy-panel/giphy-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const VersionHistoryLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'version-history'  */ "../containers/version-history/version"
    ),
  loading: () => <> </>,
});

export const SubtitlePanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'subtitle-loader'  */ "../containers/panels/library-panels/subtitle-panel/subtitle-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const MorePanelLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'more-loader'  */ "../containers/panels/library-panels/more-panel/more-panel"
    ),
  loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
});

export const AvatarPanelLoadable = Loadable({
  loader: () => 
    import (
      /* webpackChunkName: 'avatar-loader' */ "../containers/panels/library-panels/avatar-panel/avatar-panel"
    ),
    loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />,
})

// export const DashboardLoadable = Loadable({
//     loader: () => import(/* webpackChunkName: 'Dashboard-Component' */ '../containers/dashboard'),
//     loading: () => <Spinner type={SPINNER_TYPE.RING_SPINNER} />
// });
