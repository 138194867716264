import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Slider from "./Slider";
import { PORTAL_ID } from "../constants";
import { STATIC_PATH } from "../constants/config";

const OFFSET = 16.5;
const OptionWrapper = styled.div.attrs((props) => {
  return {
    style: {
      top: props.position.y,
      left: props.position.x,
    },
  };
})`
  width: ${(props) => props.width};
  position: absolute;
  z-index: 11;
  background: ${(props) => props.theme.btnBgLightColor} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 4px ${(props) => props.theme.rgbaBlack};
  border: 1px solid ${(props) => props.theme.veryLightGray};
  border-radius: 4px;
  overflow: hidden;
`;

const Option = styled.div`
  width: ${(props) => props.width};
  min-height: 36px;
  padding: 8px 12px;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  color: ${(props) => props.theme.tertiaryColor};
  font: normal normal normal 14px/21px VM-Poppins;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.btnBgLightColor};
  }
  ${(props) =>
    props.isEnableBorder &&
    css`
      border-top: 1px solid ${(props) => props.theme.primaryBorderColor};
    `}
`;

const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 150px;
  max-height: 36px;
  padding: 8px;
  border-top: 1px solid ${(props) => props.theme.primaryBorderColor};
  .slider-root {
    margin-top: 7px;
  }
  img {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
`;

const ContextMenu = (
  { options, positionEvent, width, closeContextMenu },
  ref
) => {
  const optionRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMute, setIsMute] = useState(false);

  useLayoutEffect(() => {
    if (optionRef.current && positionEvent) {
      const optionBound = optionRef.current.getBoundingClientRect();
      setPosition({
        x:
          positionEvent.x -
          (positionEvent.eventType === "contextmenu"
            ? 0
            : optionBound.width / 2 - OFFSET),
        y: positionEvent.y - optionBound.height,
      });
    }
  }, [positionEvent]);

  useEffect(() => {
    if (options[options.length - 1].actionName === "VOLUME") {
      setIsMute(options[options.length - 1].defaultVolume === 0);
    }
  }, [options]);

  const handleOutsideClick = (e) => {
    const preventClosing =
      optionRef.current && optionRef.current.contains(e.target);
    if (!preventClosing && e.target.id !== "context-menu-icon") {
      closeContextMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("pointerdown", handleOutsideClick);

    return () => {
      window.removeEventListener("pointerdown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignRef = (r) => {
    optionRef.current = r;
    if (typeof ref === "function") {
      ref(r);
    } else if (ref && typeof ref === "object") {
      ref.current = r;
    }
  };

  return ReactDOM.createPortal(
    <>
      <OptionWrapper
        className="contextMenu-tool-bar"
        ref={assignRef}
        position={position}
        width={width}
      >
        {options.map(
          ({
            title,
            clickHandler,
            changeHandler,
            actionName,
            defaultVolume,
            isEnableBorder,
          }) => {
            return title ? (
              <Option
                key={title}
                onClick={() => clickHandler(actionName)}
                width={width}
                isEnableBorder={isEnableBorder || title === "Delete"}
              >
                {title}
              </Option>
            ) : (
              <AudioContainer key={actionName}>
                <Slider
                  height="16px"
                  onMouseUp={(volume) => {
                    changeHandler(volume);
                    setIsMute(volume === 0);
                  }}
                  defaultValue={defaultVolume}
                  step="0.01"
                  max={1}
                  iconSrc={`${STATIC_PATH}${isMute || defaultVolume === 0 ? "mute.svg" : "un-mute.svg"}`}
                  iconValue={isMute ? 1 : 0}
                  onIconAction={() => {
                    setIsMute(!isMute);
                    changeHandler(!isMute ? 0 : 1);
                  }}
                />
              </AudioContainer>
            );
          }
        )}
      </OptionWrapper>
      <div className="backdrop" />
    </>,
    document.getElementById(PORTAL_ID)
  );
};

ContextMenu.propTypes = {
  options: PropTypes.array,
  positionEvent: PropTypes.object,
  slider: PropTypes.object,
  width: PropTypes.string,
  closeContextMenu: PropTypes.func,
};

export default forwardRef(ContextMenu);
