import styled, { css } from "styled-components";
import { STATIC_PATH } from "../../constants/config";
import { font } from "../../constants/font";

export const BgSelectionHighlight = styled.div.attrs((props) => {
    return {
        style: {
            width: `${props.$width}px`,
            height: `${props.$height}px`,
            transform: `translate(${props.$x}px, ${props.$y}px)`,
        }
    }
})`
    position: absolute;
    pointer-events: none;
    user-select: none;
    outline: 2px solid ${(props) => props.theme.ITEM_OUTLINE_COLOR.DEFAULT};
`;
BgSelectionHighlight.displayName = "BgSelectionHighlight";

export const TMOverlayContainer = styled.div`
    .snap-line {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        z-index: 1002;
        display: none;
        border-width: 0;
        border-style: dashed;
        border-color: ${(props) => props.theme.tmBgColor};
        /* box-shadow: inset 0 0 6px rgba(0,0,0,0.5), 0 0 6px rgba(0,0,0,0.5); */
        pointer-events: none;
    }

    .snap-line.vertical-line {
        border-left-width: 1px;
        width: 1px;
    }

    .snap-line.horizontal-line {
        border-top-width: 1px;
        height: 1px;
    }

    .snap-line.rotationDisplay {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: ${(props) => props.theme.snapColor} 0% 0% no-repeat padding-box;
        font: ${font.normalMicro_11};
        color: ${(props) => props.theme.secondaryBorderColor};
        align-items: center;
        justify-content: center;
    }

    .rotationLine { border-left-width: 1px; }
`;
TMOverlayContainer.displayName = "TMOverlayContainer";

const BottomHolderStyles = css`
    .transform-bottom-holder {
        position: absolute;
        left: 50%; top: calc(100% + 11px);
        transform: translateX(-50%);

        display: flex;
        column-gap: 4px;
    }
    .transform-bottom-handle {
        display: block;
        position: relative;
        cursor: pointer;
        width: 16px;
        height: 16px;
        background: ${(props) => props.theme.polarColor};
        border-radius: 100%;
        opacity: 1;
        border: none;
        background-position: center;
        box-shadow: 0px 0px 6px ${(props) => props.theme.rgbaBlack};
    }
    .transform-bottom-handle:hover {
        /* box-shadow: 0 0 0 1px ${(props) => props.theme.polarColor};, 0px 0px 6px ${(props) => props.theme.rgbaBlack}; */
    }
`;

const MoveIconStyles = css`
    .move-handle:hover {
        content: url(${STATIC_PATH}transform-icons/move-hover.svg);
    }
    .move-handle {
        pointer-events: auto;
    }
`;

const RotateStyles = css`
    .rotate-handle {
        position: absolute;
        padding: 2px;
        opacity: 0;
        border: 3px solid ${(props) => props.theme.blackColor};
        pointer-events: auto;
    }

    .hide {
        display: none;
    }

    .rotate-handle.top-right { top: -14px; right: -14px; }
    .rotate-handle.bottom-right { bottom: -14px; right: -14px; }
    .rotate-handle.bottom-left { bottom: -14px; left:  -14px; }
    .rotate-handle.top-left { top: -14px; left: -14px; }
    .rotate-handle.right { top: 49%; right: -14px; }

    .rotate-handle.bottom {
        background-image: url(${STATIC_PATH}transform-icons/rotate.svg);
        cursor: pointer;
    }
    .rotate-handle.bottom:hover, .rotate-handle.bottom.active {
        background-image: url(${STATIC_PATH}transform-icons/rotate-active.svg);
    }
`;

const ResizeStyles = css`
    .resize-handle {
        position: absolute;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        background: ${(props) => props.theme.polarColor};
        outline: 1px solid ${(props) => props.theme.tmResizeOutlinColor};
        z-index: 1;
        pointer-events: auto;
    }

    .hide {
        display: none;
    }

     /* .resize-handle:hover, .resize-handle.active {
        box-shadow: 0 0 0 1px ${(props) => props.theme.polarColor};
        background-color: ${(props) => props.theme.filterBtnOutline};
    }  */

    /* .resize-handle.top:hover, .resize-handle.left:hover, .resize-handle.right:hover, .resize-handle.bottom:hover{
        border: none;
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.polarColor};
    } */

    .resize-handle.top, .resize-handle.bottom {
        width: 32px;
        height: 6px;
        border-radius: 3px;
    }

    .resize-handle.left, .resize-handle.right {
        width: 6px;
        height: 32px;
        border-radius: 3px;
    }

    .hide-handle, &.is-short .resize-handle.top-left, &.is-short .resize-handle.top-right, &.is-short .resize-handle.bottom-right {
        opacity: 0;
    }

    .resize-handle.disabled {
        border: none;
        outline: none;
        padding: 0;
        background: none;
        pointer-events: none;
    }

    .resize-handle.top-left { top: -7px; left: -7px; cursor: nwse-resize }
    .resize-handle.top-right { top: -7px; right: -7px; cursor: nesw-resize }
    .resize-handle.bottom-right { bottom: -7px; right: -7px; cursor: nwse-resize }
    .resize-handle.bottom-left { bottom: -7px; left: -7px; cursor: nesw-resize }
    .resize-handle.top { left: calc(50% - 16px); top: -4px; cursor: ns-resize }
    .resize-handle.right { top: calc(50% - 16px); right: -4px; cursor: ew-resize }
    .resize-handle.bottom { left: calc(50% - 16px); bottom: -4px; cursor: ns-resize }
    .resize-handle.left { top: calc(50% - 16px); left: -4px; cursor: ew-resize }

    .crop-handle {
        position: absolute;
        left: 0px;
        top: 0px;
        pointer-events: auto;

        &.top-left, &.top-right, &.bottom-left, &.bottom-right {
            width: 13px;
            height: 13px;
        }
        &.top, &.right, &.bottom, &.left {
            width: 13px;
            height: 3px;
        }

        &.top-left {
            transform: translate(-2.5px, -2.5px);
            cursor: nwse-resize;
        }
        &.top {
            left: 50%;
            transform: translate(-50%, -2px);
            cursor: ns-resize;
        }
        &.top-right {
            left: 100%;
            transform: translate(-10.5px, -2.5px) scaleX(-1);
            cursor: nesw-resize;
        }
        &.left {
            top: 50%;
            transform: translate(-7px, -50%) rotate(90deg);
            cursor: ew-resize;
        }
        &.bottom-left {
            top: 100%;
            transform: translate(-2.5px, -10.5px) scaleY(-1);
            cursor: nesw-resize;
        }
        &.bottom {
            left: 50%;
            top: 100%;
            transform: translate(-50%, -1px);
            cursor: ns-resize;
        }
        &.bottom-right {
            left: 100%;
            top: 100%;
            transform: translate(-10.5px, -10.5px) scale(-1);
            cursor: nwse-resize;
        }
        &.right {
            left: 100%;
            top: 50%;
            transform: translate(-6px, -50%) rotate(90deg);
            cursor: ew-resize;
        }
    }

    &.se .resize-handle-cursor.top-left, &.se .resize-handle.top-left { cursor: ns-resize }
    &.se .resize-handle-cursor.top, &.se .resize-handle.top { cursor: nesw-resize }
    &.se .resize-handle-cursor.top-right, &.se .resize-handle.top-right { cursor: ew-resize }
    &.se .resize-handle-cursor.right, &.se .resize-handle.right { cursor: nwse-resize }
    &.se .resize-handle-cursor.bottom-right, &.se .resize-handle.bottom-right { cursor: ns-resize }
    &.se .resize-handle-cursor.bottom, &.se .resize-handle.bottom { cursor:nesw-resize }
    &.se .resize-handle-cursor.bottom-left, &.se .resize-handle.bottom-left { cursor: ew-resize }
    &.se .resize-handle-cursor.left, &.se .resize-handle.left { cursor: nwse-resize }

    &.s .resize-handle-cursor.top-left, &.s .resize-handle.top-left { cursor: nesw-resize }
    &.s .resize-handle-cursor.top, &.s .resize-handle.top { cursor: ew-resize }
    &.s .resize-handle-cursor.top-right, &.s .resize-handle.top-right { cursor: nwse-resize }
    &.s .resize-handle-cursor.right, &.s .resize-handle.right { cursor: ns-resize }
    &.s .resize-handle-cursor.bottom-right, &.s .resize-handle.bottom-right { cursor: nesw-resize }
    &.s .resize-handle-cursor.bottom, &.s .resize-handle.bottom { cursor:ew-resize }
    &.s .resize-handle-cursor.bottom-left, &.s .resize-handle.bottom-left { cursor: nwse-resize }
    &.s .resize-handle-cursor.left, &.s .resize-handle.left { cursor: ns-resize }

    &.sw .resize-handle-cursor.top-left, &.sw .resize-handle.top-left { cursor: ew-resize }
    &.sw .resize-handle-cursor.top, &.sw .resize-handle.top { cursor: nwse-resize }
    &.sw .resize-handle-cursor.top-right, &.sw .resize-handle.top-right { cursor: ns-resize }
    &.sw .resize-handle-cursor.right, &.sw .resize-handle.right { cursor: nesw-resize }
    &.sw .resize-handle-cursor.bottom-right, &.sw .resize-handle.bottom-right { cursor: ew-resize }
    &.sw .resize-handle-cursor.bottom, &.sw .resize-handle.bottom { cursor: nwse-resize }
    &.sw .resize-handle-cursor.bottom-left, &.sw .resize-handle.bottom-left { cursor: ns-resize }
    &.sw .resize-handle-cursor.left, &.sw .resize-handle.left { cursor: nesw-resize }

    &.w .resize-handle-cursor.top-left, &.w .resize-handle.top-left { cursor: nwse-resize }
    &.w .resize-handle-cursor.top, &.w .resize-handle.top { cursor: ns-resize }
    &.w .resize-handle-cursor.top-right, &.w .resize-handle.top-right { cursor: nesw-resize }
    &.w .resize-handle-cursor.right, &.w .resize-handle.right { cursor: ew-resize }
    &.w .resize-handle-cursor.bottom-right, &.w .resize-handle.bottom-right { cursor: nwse-resize }
    &.w .resize-handle-cursor.bottom, &.w .resize-handle.bottom { cursor: ns-resize }
    &.w .resize-handle-cursor.bottom-left, &.w .resize-handle.bottom-left { cursor: nesw-resize }
    &.w .resize-handle-cursor.left, &.w .resize-handle.left { cursor: ew-resize }

    &.nw .resize-handle-cursor.top-left, &.nw .resize-handle.top-left { cursor: ns-resize }
    &.nw .resize-handle-cursor.top, &.nw .resize-handle.top { cursor: nesw-resize }
    &.nw .resize-handle-cursor.top-right, &.nw .resize-handle.top-right { cursor: ew-resize }
    &.nw .resize-handle-cursor.right, &.nw .resize-handle.right { cursor: nwse-resize }
    &.nw .resize-handle-cursor.bottom-right, &.nw .resize-handle.bottom-right { cursor: ns-resize }
    &.nw .resize-handle-cursor.bottom, &.nw .resize-handle.bottom { cursor: nesw-resize }
    &.nw .resize-handle-cursor.bottom-left, &.nw .resize-handle.bottom-left { cursor: ew-resize }
    &.nw .resize-handle-cursor.left, &.nw .resize-handle.left { cursor: nwse-resize }

    &.n .resize-handle-cursor.top-left, &.n .resize-handle.top-left { cursor: nesw-resize }
    &.n .resize-handle-cursor.top, &.n .resize-handle.top { cursor: ew-resize }
    &.n .resize-handle-cursor.top-right, &.n .resize-handle.top-right { cursor: nwse-resize }
    &.n .resize-handle-cursor.right, &.n .resize-handle.right { cursor: ns-resize }
    &.n .resize-handle-cursor.bottom-right, &.n .resize-handle.bottom-right { cursor: nesw-resize }
    &.n .resize-handle-cursor.bottom, &.n .resize-handle.bottom { cursor: ew-resize }
    &.n .resize-handle-cursor.bottom-left, &.n .resize-handle.bottom-left { cursor: nwse-resize }
    &.n .resize-handle-cursor.left, &.n .resize-handle.left { cursor: ns-resize }

    &.ne .resize-handle-cursor.top-left, &.ne .resize-handle.top-left { cursor: ew-resize }
    &.ne .resize-handle-cursor.top, &.ne .resize-handle.top { cursor: nwse-resize }
    &.ne .resize-handle-cursor.top-right, &.ne .resize-handle.top-right { cursor: ns-resize }
    &.ne .resize-handle-cursor.right, &.ne .resize-handle.right { cursor: nesw-resize }
    &.ne .resize-handle-cursor.bottom-right, &.ne .resize-handle.bottom-right { cursor: ew-resize }
    &.ne .resize-handle-cursor.bottom, &.ne .resize-handle.bottom { cursor: nwse-resize }
    &.ne .resize-handle-cursor.bottom-left, &.ne .resize-handle.bottom-left { cursor: ns-resize }
    &.ne .resize-handle-cursor.left, &.ne .resize-handle.left { cursor: nesw-resize }
`;

const ShapeControlPointStyles = css`
    .control-point{
        position: absolute;
        width: 15px;
        height: 15px;
        z-index: 2;
        pointer-events: auto;
    }
`;

const SelectionAreaColorStyles = css`
    &.select-txt {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.TEXT};
    }
    &.select-prop {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.PROP};
    }
    &.select-prop-dashed {
        outline-style: dashed;
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.PROP};
    }
    &.select-img {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.IMAGE};
    }
    &.select-scr {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.SCR};
    }
    &.select-group {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.GROUP};
    }
    &.select-gif {
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.GIF};
    }
    &.select-multi-select {
        outline-style: dashed;
        outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.GROUP};
    }
`;

export const SelectionBox = styled.div`
    position: absolute;
    outline: 2px solid ${(props) => props.theme.resizeBorderColor};
    box-shadow: inset 0 0 6px ${(props) => props.theme.menuBoxShawdow}, 0 0 6px ${(props) => props.theme.menuBoxShawdow};
    opacity: 1;
    transition: opacity 0.15s linear;
    user-select: none;
    touch-action: none;

    ${ResizeStyles}
    ${RotateStyles}
    ${MoveIconStyles}
    ${BottomHolderStyles}
    ${ShapeControlPointStyles}
    ${SelectionAreaColorStyles}

    * {
        user-select: none;
        touch-action: none;
    }

    &.invisible {
        opacity: 0;
    }

    .inner-box {
        position: absolute;
        left: -3px;
        right: -3px;
        top: -3px;
        bottom: -3px;
        cursor: move;
    }

    .text-inner-box {
        cursor: move;
        pointer-events: auto;
        position: absolute;
    }

    &.pointer-events-none, .pointer-events-none {
        pointer-events: none;
    }
    &.pointer-events-auto, .pointer-events-auto {
        pointer-events: auto;
    }
`;
SelectionBox.displayName = "SelectionBox";

export const UnCropB = styled.div`
    touch-action: "none";
    cursor: move;
    outline: 2px solid ${(props) => props.theme.resizeBorderColor};
    box-shadow: inset 0 0 6px ${(props) => props.theme.menuBoxShawdow}, 0 0 6px ${(props) => props.theme.menuBoxShawdow};
    opacity: 1;
    transition: opacity 0.15s linear;
`;
UnCropB.displayName = "UnCropB";

export const FrameResizeHandleContainer = styled.div`
    ${ResizeStyles}
`;
FrameResizeHandleContainer.displayName = "FrameResizeHandleContainer";

export const CropSelectionBox = styled.div`
    position: absolute;
    touch-action: none;
    pointer-events: none;

    * {
        touch-action: none;
    }

    .crop-imgHolder, .crop-imgHolder > img, .crop-imgHolder > video {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .crop-imgHolder {
        overflow: hidden;
    }

    .cropBox {
        pointer-events: auto;
        position: absolute;
        overflow: hidden;
        cursor: move;
    }

    .unCroppedBox {
        pointer-events: auto;
        position: absolute;
        border: none;
        outline: 1px solid ${(props) => props.theme.resizeBorderColor};
        cursor: move;

        &::after {
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: ${(props) => props.theme.blackColor};
            opacity: 0.32;
        }
    }

    .crop-handles {
        position: absolute;
        pointer-events: none;
        z-index: 1;
        outline: 1px solid ${(props) => props.theme.secondaryBorderColor};
    }

    ${ResizeStyles}
`;
CropSelectionBox.displayName = "CropSelectionBox";
