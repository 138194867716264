import styled from 'styled-components';
import PropTypes from 'prop-types';

const Backdrop = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: ${props => props.background};
    position: ${props => props.position};
    left: ${props => props.left};
    right: ${props => props.right};
    top: ${props => props.top};
    z-index: ${props => props.zIndex}
`;

/**
 * Default Props Value for Backdrop
 */
Backdrop.defaultProps = {
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: '0px',
    right: '0px',
    top: '0px',
    zIndex: 'auto'
};

/**
 * Initialize Prop Types for Backdrop
 */
Backdrop.propTypes = {
    background: PropTypes.string,
    position: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
    zIndex: PropTypes.number,
};

export default Backdrop;
