import * as Sentry from "@sentry/react";
import AppConfig from "../constants/config";

const { SENTRY_URL, ENVIRONMENT } = AppConfig;

const initSentry = () => {
  if (ENVIRONMENT !== "PRODUCTION")
    return null;
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/,],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: AppConfig.IS_LOCAL ? "development" : "production",
    // Filter out specific errors
    beforeSend(event) {
      if (event.exception && event.exception.values) {
        // Array of errors to be ignored.
        const ignoredErrors = ["Request timed out!", "Load failed"];

        // Check if any of the ignored errors are present in the event
        const shouldIgnore = event.exception.values.some(value =>
          value.value && ignoredErrors.some(error => value.value.includes(error))
        );

        if (shouldIgnore) {
          return null; // Drop this event
        }
      }
      return event; // Send all other events to Sentry
    },
  });
  return true;
};

export default initSentry;