import React from "react";
import { PropTypes } from "prop-types";
import Typography from "../common-components/Typography";
import { STATIC_PATH, API_URL } from "../constants/config";
import { font } from "../constants/font";
import Action from "../common-components/Action";
// import vmakerAction from "../helper/vmakerAction";
import {
  CloseIcon,
  DownloadHeader,
  DownloadRow,
  DownloadWrapper,
  MiddleSection,
} from "./animo-component";
import Backdrop from "../common-components/BackDrop";
import content from "../constants/content";

const { LAUNCH_PLUGIN, ADD_TO_CHROME } = content;
const Download = ({ setShowDownload }) => {
  const vmElement = document.getElementsByTagName("vmaker-container").length;
  return (
    <>
      <Backdrop
        background="rgba(0,0,0,0.5)"
        zIndex={6}
        onClick={() => setShowDownload(false)}
      />
      <DownloadWrapper>
        <DownloadHeader>
          <CloseIcon
            src={`${STATIC_PATH}close-icon.svg`}
            alt="close"
            onClick={() => setShowDownload(false)}
            style={{ top: "0px", position: "absolute", right: "0px" }}
          />

          <Typography
            innerContent="Download Vmaker"
            color="#ffffff"
            font={font.largeBold}
          />
        </DownloadHeader>
        <Typography
          innerContent="To record, edit and share videos using Vmaker you need to download install Vmaker on the platform of your choice"
          color="#32304A"
          align="center"
          padding="15px 100px 25px"
          enableTrim={false}
          font={font.normal_24}
        />
        <MiddleSection>
          <DownloadRow as="a" href={`${API_URL}download/?file=intel`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#545458" />
                  <stop offset="1" stopColor="#212122" />
                </linearGradient>
              </defs>
              <g id="Desktop" transform="translate(-1001 -540)">
                <rect
                  id="Rectangle_20186"
                  data-name="Rectangle 20186"
                  width="64"
                  height="64"
                  transform="translate(1001 540)"
                  fill="#fff"
                  opacity="0"
                />
                <path
                  id="laptop_chromebook_FILL0_wght400_GRAD200_opsz48_1_"
                  data-name="laptop_chromebook_FILL0_wght400_GRAD200_opsz48 (1)"
                  d="M1.383,48.683a2.117,2.117,0,0,1-1.6-.664A2.2,2.2,0,0,1-.85,46.45a2.1,2.1,0,0,1,.634-1.539,2.168,2.168,0,0,1,1.6-.634H4.039V9.206A4.33,4.33,0,0,1,8.446,4.8H49.854a4.33,4.33,0,0,1,4.406,4.406v35.07h2.716A2.164,2.164,0,0,1,59.15,46.45a2.168,2.168,0,0,1-.634,1.6,2.1,2.1,0,0,1-1.539.634Zm24.024-4.406h7.606a1.389,1.389,0,1,0,0-2.777H25.408a1.389,1.389,0,1,0,0,2.777ZM8.687,37.094H50.1V9.206H8.687Zm0,0v0Z"
                  transform="translate(1003.85 545.259)"
                  fill="#212122"
                />
              </g>
            </svg>
            <Typography
              innerContent="Vmaker for Desktop"
              color="#32304A"
              font={font.boldRegular_25}
              padding="20px 0 0"
              cursor="pointer"
            />
            <p>
              Works on <strong>Mac OS 10.14 (Mojave)</strong> and{" "}
              <strong>newer versions</strong>
            </p>
            <Action
              text={content.DOWNLOAD}
              className="download-btn"
              hoverScale={1.05}
            />
          </DownloadRow>
          <DownloadRow>
            <img src={`${STATIC_PATH}chrome.svg`} alt="" />
            <Typography
              innerContent="Vmaker for Chrome"
              color="#32304A"
              font={font.boldRegular_25}
              padding="20px 0 0"
              cursor="pointer"
            />
            <p>
              Works on <strong>Google Chrome</strong> with{" "}
              <strong>any operating system</strong>
            </p>
            <Action
              className="download-btn"
              hoverScale={1.05}
              text={vmElement ? LAUNCH_PLUGIN : ADD_TO_CHROME}
            />
          </DownloadRow>
        </MiddleSection>
      </DownloadWrapper>
    </>
  );
};

Download.propTypes = {
  setShowDownload: PropTypes.func,
};

export default Download;
