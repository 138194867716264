/* eslint-disable no-continue, prefer-destructuring, no-restricted-syntax, no-plusplus */
/// <reference path="../common-components/Editable/editable-types.js" />
/// <reference path="../containers/timeline/timeline-types.js" />

import { List, fromJS } from "immutable";
import { SPACE, NEWLINE, NEWLINE_DELTA_TEMPLATE } from "../common-components/Editable/editable-constants";
import { iterViewportSliders, randomString, secondsToTimestamp } from "../containers/timeline/timeline-helper";
import { buildSubtitleDomElement, checkHasSplitLineEffect } from "../containers/text/subtitle-text/subtitleTextHelper";
import { fontFamilies } from "../containers/text/text-constants";
import { RULER_OPTIONS, SLIDER_TYPES, TRACK_TYPES } from "../containers/timeline/timeline-constants";

export const SUB_INNER_OFFSET = 16; // 16 / 2
export const SUB_OUTER_OFFSET = 15; // 15 * 2
export const WORKSPACE_PADDING_OFFSET = SUB_OUTER_OFFSET * 2;
export const DEFAULT_TEXT_EFFECT = { hasTextEffect: false, effectIndex: 0, key: 'NONE' };
export const DEFAULT_ANIMDATA = { isAnimation: false, animation: "default", displayName: 'None' };
export const TEXT_ALIGN = { LEFT: 'left', RIGHT: 'right', CENTER: 'center' }
export const TEXT_TRANSFORM = ['none', 'uppercase', 'lowercase', 'capitalize']
export const SUBTITLE_STYLE_OPTION = {
    COLOR: { STYLE_NAME: "color", STATE_NAME: null, KEY: "COLOR", DEFAULT_VALUE: "#ffffff", },
    BG_COLOR: { STYLE_NAME: "background", STATE_NAME: null, KEY: "BG_COLOR", DEFAULT_VALUE: "#00000080", },
    BOLD: { STYLE_NAME: "fontWeight", STATE_NAME: "isBold", KEY: "BOLD" },
    ITALIC: { STYLE_NAME: "fontStyle", STATE_NAME: "isItalic", KEY: "ITALIC" },
    UNDERLINE: { STYLE_NAME: "textDecoration", STATE_NAME: "isUnderline", KEY: "UNDERLINE", },
    TEXT_ALIGN: { STYLE_NAME: "textAlign", STATE_NAME: null, KEY: "TEXT_ALIGN", DEFAULT_VALUE: TEXT_ALIGN.CENTER, },
    TEXT_TRANSFORM: { STYLE_NAME: "textTransform", STATE_NAME: null, KEY: "TEXT_TRANSFORM", DEFAULT_VALUE: TEXT_TRANSFORM[0], },
    FONT_FAMILY: { STYLE_NAME: "fontFamily", STATE_NAME: "fontFamilyName", KEY: "FONT_FAMILY", DEFAULT_VALUE: `'PT Sans', sans-serif`, },
    FONT_SIZE: { STYLE_NAME: "fontSize", STATE_NAME: null, KEY: "FONT_SIZE", DEFAULT_VALUE: "18px", },
    LETTER_SPACING: { STYLE_NAME: "letterSpacing", STATE_NAME: null, KEY: "LETTER_SPACING", DEFAULT_VALUE: 0, },
    LINE_HEIGHT: { STYLE_NAME: "lineHeight", STATE_NAME: null, KEY: "LINE_HEIGHT", DEFAULT_VALUE: 1.2, },
    COLOR_ALPHA: { STYLE_NAME: "colorAlpha", KEY: "COLOR_ALPHA" },
    BG_ALPHA: { STYLE_NAME: "bgAlpha", KEY: "BG_ALPHA" },
};
export const commonStyle = {
    isItalic: 0,
    isBold: 0,
    isUnderline: 0,
    bgAlpha: 100,
    colorAlpha: 100,
    textAlign: "center",
    textTransform: "none",
    others: { isUserFont: false, cssUrl: "" },
    background: "transparent",
    borderRadius: "0px",
    letterSpacing: "0em",
    textShadow: "",
    webkitTextStroke: "",
};
export const TEXT_STYLES = { BOLD: "bold", ITALIC: "italic", UNDERLINE: "underline", TEXT_ALIGN: "textAlign", TOGGLE_CASE: "toggleCase", FONT_SIZE: "fontSize" };

/**
 * @description subtitle textEffects meta data.
 * @note don't disturb the existing INDEX key.
 * @note if you want to add a Effect without backgound include the INDEX in getShouldPreventBgChange() method.
 * @note if you want to add a Effect witht the fill Width without any offSet include the INDEX in getShouldPreventOuterOffSet() method.
 * that's it play with REC_TEXTSTYLES key to get your desire textEffect.
 */

export const SUBTITLE_ANIMATIONS = [
    {
        name: "default",
        displayName: "None",
        thumb: "",
        gif: "",
    },
    {
        name: "textReveal",
        displayName: "Text Reveal",
        thumb: "",
        gif: "",
    },
    {
        name: "textHighlight",
        displayName: "Text Highlight",
        thumb: "",
        gif: "",
        colorProp: "#8253f9",
        colorPropAlpha: 100,
    },
    {
        name: "textFlip",
        displayName: "Text Flip",
        thumb: "",
        gif: "",
    },
    {
        name: "textDropIn",
        displayName: "Text Drop In",
        thumb: "",
        gif: "",
    },
    {
        name: "textAlphaFadeOut",
        displayName: "Text Alpha Fade Out",
        thumb: "",
        gif: "",
    },
    {
        name: "textAlphaFadeIn",
        displayName: "Text Fade In",
        thumb: "",
        gif: "",
    },
    {
        name: "textRevealWordbyWord",
        displayName: "Text Reveal Word by Word",
        thumb: "",
        gif: "",
    },
    {
        name: "textFloatDown",
        displayName: "Text Float Down",
        thumb: "",
        gif: "",
    },
    {
        name: "textFloatUp",
        displayName: "Text Float Up",
        thumb: "",
        gif: "",
    },
    {
        name: "textColorHighlight",
        displayName: "Text Color Highlight",
        thumb: "",
        gif: "",
        colorProp: "#000000",
        colorPropAlpha: 100,
    },
    {
        name: "textPopupWordbyWord",
        displayName: "Text Popup",
        thumb: "",
        gif: "",
    },
];

export const SUBTITLE_BACKGROUND_EFFECTS = [
    { KEY: "NONE", BG_STYLES: { background: "transparent" } },
    { KEY: "BG_EFF_1", BG_STYLES: {}, },
    { KEY: "BG_EFF_2", BG_STYLES: {}, },
    { KEY: "BG_EFF_3", BG_STYLES: { background: "#000000", borderRadius: "0px" } },
    { KEY: "BG_EFF_4", BG_STYLES: { background: "#000000", borderRadius: "8px" } },
]

/**
 * Function to generate an array
 * @param {number} minValue 
 * @param {number} maxValue 
 * @returns returns an array with the min and max values.
 */
export const getArrayOfNumbers = (minValue, maxValue) => {
    return Array.from({ length: maxValue - minValue + 1 }, (_, index) => index + minValue);
}

/**  
 * @param {Array} subtitleList 
 * @returns sample text of the subtitleList to load fallbackFontFamily
 */
export const getSampleSubtitleText = (subtitlesData) => {
    let sampleSubText = '';
    const dropIds = Object.keys(subtitlesData);
    for (let i = 0; i < dropIds.length; i += 1) {
        const subtitles = subtitlesData[dropIds[i]];
        let text = "";
        for (let j = 0; j < subtitles.length; j += 1) {
            text += subtitles[j].text;
            if (text.length > 50) {
                break;
            }
        }
        sampleSubText += text;
    }
    return sampleSubText;
}

/**
 * @description some textEffects doesn't maintain subtitle outer offset. this will detect the textEffect based on effectIndex.
 * @param {*} effectIndex 
 * @returns {Boolean}
 */
export function getShouldPreventOuterOffSet(effectIndex) {
    const EFFECTS = [9, 11]
    return EFFECTS.includes(effectIndex);
}

/**
 * @description returns width and height of the subtitle based on textEffect
 * @param {*} subtitleText 
 * @param {*} clientRect 
 * @param {*} innerPadSpace 
 * @param {*} hasFullWidthEffect 
 * @param {*} hasSplitLineEffect 
 * @param {*} projectWidth 
 * @param {*} projectHeight 
 * @returns 
 */
export function getSubtitleRect(clientRect, innerPadSpace, hasFullWidthEffect, hasSplitLineEffect, projectWidth) {
    let { height, width } = clientRect;
    if (hasSplitLineEffect === false) {
        height += innerPadSpace;
    }
    if (hasFullWidthEffect) {
        width = projectWidth;
    } else {
        width += innerPadSpace;
    }
    return {
        height,
        width
    }
}

/** 
 * @param {HTMLElement} HTMLElement 
 * @param {Object} textStyles 
 * @param {Number} maxWidth 
 * @returns 
 */
export function applyStyleAndGetPosition(HTMLElement, textStyles, maxWidth, hasSplitLineEffect = false) {
    const fontFamily = `${textStyles.get('fontFamily')}, gfs`;
    const fontStyle = textStyles.get('fontStyle');
    const textAlign = textStyles.get('textAlign');
    const letterSpacing = textStyles.get('letterSpacing');
    const textTransform = textStyles.get('textTransform');
    let lineHeight = textStyles.get('lineHeight');
    const fontSize = Math.round(parseFloat(textStyles.get('fontSize')));

    if (hasSplitLineEffect) {
        lineHeight = `${Math.ceil((lineHeight + 0.2) * fontSize + SUB_INNER_OFFSET)}px`
    }

    const subtitleStyles = {
        fontSize: `${fontSize}px`,
        fontStyle, textAlign,
        fontFamily: fontFamily || `"PT Sans", sans-serif`,
        maxWidth: `${maxWidth}px`,
        textTransform: textTransform || "none",
        letterSpacing: letterSpacing || "0em",
        lineHeight: lineHeight || 1.2,
        wordBreak: 'break-word',
        width: 'max-content',
        height: 'auto',
        position: "fixed",
        zIndex: 1000,
        left: '250px',
        top: '250px',
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in subtitleStyles)
        HTMLElement.style[property] = subtitleStyles[property];

    return HTMLElement.getBoundingClientRect();
}


/**
 * @summary function to get position of subtitleList for autoSubtitle
 * @param {Object} subtitleList Immutable Object
 * @param {Number} workspaceWidth
 * @param {Number} workspaceHeight
 * @param {Object} globalTextStyles Immutable Object
 * @param {Object} globalTextEffects Immutable Object
 * @param {Object} position Object
 * @note remove support index flow
 */
export const getSubtitleListPosition = (subtitleStoreData, workspaceWidth, workspaceHeight, globalTextStyles, globalTextEffects, position) => {
    const updatedDataList = {};
    const hasFullWidthEffect = getShouldPreventOuterOffSet(globalTextEffects.get('effectIndex'));
    const hasSplitLineEffect = checkHasSplitLineEffect(globalTextEffects.get('effectIndex'));
    const maxWidth = workspaceWidth - (hasFullWidthEffect ? 0 : WORKSPACE_PADDING_OFFSET);
    const subtitleElement = document.createElement('div');
    const textElement = document.createElement('p');
    textElement.style.whiteSpace = 'pre-wrap';
    textElement.style.margin = '0px';
    subtitleElement.style.fontKerning = 'none';
    subtitleElement.appendChild(textElement);
    document.body.prepend(subtitleElement);

    const emptyDropList = [];

    const updatedSubtitles = subtitleStoreData.map((subtitleList, dropId) => {
        if (subtitleList && subtitleList.size === 0) {
            emptyDropList.push(dropId);
        }
        return subtitleList.map((subtitle, subtitleItemId) => {
            textElement.innerHTML = buildSubtitleDomElement(subtitle.get('text'))
            let textStyles = globalTextStyles;
            if (subtitle.get('textStyles') !== undefined) { /** @Note overRide the style value if the individual subtitle has subtitle textStyles. */
                textStyles = textStyles.merge(subtitle.get('textStyles'))
            }
            const innerPadSpace = subtitle.get('text').length === 0 ? 0 : SUB_INNER_OFFSET;
            const subElmBoundBox = applyStyleAndGetPosition(subtitleElement, textStyles, maxWidth - innerPadSpace, hasSplitLineEffect);
            const { width, height } = getSubtitleRect(subElmBoundBox, innerPadSpace, hasFullWidthEffect, hasSplitLineEffect, workspaceWidth);
            subtitle = subtitle.set('height', height);
            subtitle = subtitle.set('width', width);

            if (hasFullWidthEffect) {
                subtitle = subtitle.set('x', 0);
            } else if (textStyles.get('textAlign') === TEXT_ALIGN.CENTER) {
                subtitle = subtitle.set('x', workspaceWidth / 2 - subtitle.get('width') / 2);
            } else if (textStyles.get('textAlign') === TEXT_ALIGN.RIGHT) {
                subtitle = subtitle.set('x', workspaceWidth - SUB_OUTER_OFFSET);
            } else {
                subtitle = subtitle.set('x', SUB_OUTER_OFFSET);
            }
            if (!position) {
                subtitle = subtitle.set('y', workspaceHeight - (subtitle.get('height') + (SUB_OUTER_OFFSET * 2)));
            } else {
                const { yLoc, offset } = position;
                subtitle = subtitle.set('y', (yLoc * workspaceHeight) - (subtitle.get('height') / 2) + offset);
                if (subtitle.get("y") + subtitle.get('height') > workspaceHeight) {
                    const yOffset = workspaceHeight - (subtitle.get("y") + subtitle.get('height'));
                    subtitle = subtitle.update('y', (data) => data + yOffset);
                } else if (subtitle.get("y") < 0) {
                    subtitle = subtitle.set('y', 0);
                }
            }
            subtitle = subtitle.set('type', "SUBTITLE");

            if (updatedDataList[dropId] === undefined) {
                updatedDataList[dropId] = {};
            }

            updatedDataList[dropId][subtitleItemId] = {
                x: subtitle.get('x'),
                y: subtitle.get('y'),
                width: subtitle.get('width'),
                height: subtitle.get('height'),
            }

            return subtitle
        })
    })
    document.body.removeChild(subtitleElement);
    return { updatedSubtitles, updatedDataList, emptyDropList };
}

const findFilthyIndex = (wordData, filthyData) => {
    return filthyData.findIndex((filthyWord) => {
        return (
            filthyWord.get("startIndex") === wordData.get("startIndex")
            && filthyWord.get("endIndex") === wordData.get("endIndex")
        );
    });
}

export const subtitleTextToEditableJSON = (text, wordsDurData, filthyData) => {
    /** @type {EditableDeltaList} */
    const wordList = [];
    const newlineExp = new RegExp(`(${NEWLINE}+)`);
    const spaceExp = new RegExp(`(${SPACE}+)`);

    const lines = text.split(newlineExp);
    let startIndex = 0;
    let wordIndex = 0;
    for (let l = 0; l < lines.length; l += 1) {
        const line = lines[l];
        if (!line.length) {
            continue;
        }

        const words = line.split(spaceExp);
        for (let w = 0; w < words.length; w += 1) {
            const word = words[w];
            if (!word.length) {
                continue;
            }

            /** @type {EditableDelta | null} */
            let textDelta = null;
            if (word.includes(NEWLINE)) {
                // newline
                const wordLength = word.length;
                for (let c = 0; c < wordLength; c += 1) {
                    /** @type {EditableDelta} */
                    const textDelta = { ...NEWLINE_DELTA_TEMPLATE };
                    textDelta.startIndex = startIndex;
                    wordList.push(textDelta);
                    startIndex = textDelta.startIndex + textDelta.length;
                }
            } else if (word.includes(SPACE)) {
                // space
                textDelta = {
                    startIndex,
                    length: word.length,
                    text: word,
                    allowedChar: SPACE,
                };
            } else {
                // word
                textDelta = {
                    startIndex,
                    length: word.length,
                    text: word,
                    meta: {
                        isManual: true,
                    },
                };
                if (wordsDurData) {
                    const wordData = wordsDurData.get(wordIndex);
                    if (wordData) {
                        textDelta.meta = {
                            ...textDelta.meta,
                            st: wordData.get("st"),
                            et: wordData.get("et"),
                            isManual: wordData.get("isManual"),
                        };
                        if (filthyData) {
                            const filthyIndex = findFilthyIndex(wordData, filthyData);
                            if (filthyIndex > -1) {
                                textDelta.meta.isFilthy = true;
                                textDelta.meta.filthyIndex = filthyIndex.toString();
                                if (!textDelta.dataset) {
                                    textDelta.dataset = {};
                                }
                                textDelta.dataset.f = "true";
                                textDelta.dataset.fi = textDelta.meta.filthyIndex;
                            }
                        }
                    }
                }
                wordIndex += 1;
            }

            if (textDelta) {
                wordList.push(textDelta);
                startIndex = textDelta.startIndex + textDelta.length;
            }
        }
    }

    return wordList;
}

/**
 * @param {EditableDelta} delta
 */
export const isDeltaDelimiter = (delta) => {
    return (
        typeof delta.allowedChar === "string"
        && (delta.allowedChar.includes(NEWLINE) || delta.allowedChar.includes(SPACE))
    );
}

/** @type {OnDeltaChangeHandler} */
export const onDeltaChange = (delta) => {
    if (delta.meta && delta.meta.isFilthy) {
        delta = { ...delta };
        delta.meta = { ...delta.meta, isFilthy: false, };
        delta.meta.filthyIndex = undefined;
    }
    return delta;
}

/** @type {OnDeltaJoinHandler} */
export const onDeltaJoin = (_left, _right) => {
    /** @type {EditableDelta | undefined} */
    let left = _left;
    /** @type {EditableDelta | undefined} */
    let right = _right;
    const leftIsDelimiter = isDeltaDelimiter(left);
    const rightIsDelimiter = isDeltaDelimiter(right);

    if (!leftIsDelimiter && !rightIsDelimiter) {
        left = { ...left };
        left.text += right.text;
        left.meta = { ...left.meta };
        right.meta = { ...right.meta };

        if (!left.meta.isManual && !right.meta.isManual) {
            // both left and right word is auto word (word with start and end time)
            // so combine their text and time
            left.meta.et = right.meta.et;
        } else if (left.meta.isManual && !right.meta.isManual) {
            // left is newly added word but right has start and end time
            // so combine text but use time from right (auto word)
            left.meta = right.meta;
        } /* else if (!left.meta.isManual && right.meta.isManual) {
            // left has start and end time but right is newly added word
            // do nothing, this is handled implicitily
        } else {
            // both word is manual
            // do nothing, this is handled implicitily
        } */

        if (left.meta.isFilthy) {
            left.meta.isFilthy = false;
            left.meta.filthyIndex = undefined;
        }

        right = undefined;
    } else if (leftIsDelimiter && rightIsDelimiter && left.allowedChar === right.allowedChar) {
        left = { ...left };
        // if (left.meta) {
        //     left.meta = { ...left.meta, isFilthy: false };
        // }
        left.text += right.text;
        right = undefined;
    }

    return { left, right };
}

/** @type {OnDeltaSplitHandler} */
export const onDeltaSplit = (_left, _right) => {
    /** @type {EditableDelta | undefined} */
    let left = _left;
    /** @type {EditableDelta | undefined} */
    let right = _right;

    if (
        left.meta
        && !left.meta.isManual
        && right.meta
        && !right.meta.isManual
    ) {
        right = { ...right };
        right.meta = { isManual: true };
        if (left.meta.isFilthy) {
            left = { ...left };
            left.meta = { ...left.meta };
            left.meta.isFilthy = false;
            left.meta.filthyIndex = undefined;
        }
    }

    return { left, right };
}

/** @type {OnTextInsertHandler} */
export const onTextInsert = (event, insertedText) => {
    const { inputType } = event;
    /** @type {EditableDeltaList} */
    let deltaList = [];

    if (inputType === "insertParagraph" || inputType === "insertLineBreak") {
        /** @type {EditableDelta} */
        const delta = { ...NEWLINE_DELTA_TEMPLATE };
        deltaList.push(delta);
    } else if (insertedText.length) {
        deltaList = subtitleTextToEditableJSON(insertedText);
    }

    return deltaList;
}

/**
 * @param {EditableDeltaList} deltaList
 */
export const editableJSONToSubtitleText = (deltaList) => {
    let wordsDurData = List();
    let filthyData = List();
    let text = "";

    for (let idx = 0; idx < deltaList.length; idx += 1) {
        const delta = deltaList[idx];
        text += delta.text;

        if (isDeltaDelimiter(delta)) {
            continue;
        }

        if (delta.meta && delta.meta.st && delta.meta.et) {
            const wordData = fromJS({
                st: delta.meta.st,
                et: delta.meta.et,
                word: delta.text,
                startIndex: delta.startIndex,
                endIndex: delta.length - 1,
            });
            wordsDurData = wordsDurData.push(wordData);
            if (delta.meta.isFilthy) {
                const filthy = fromJS({
                    startIndex: delta.startIndex,
                    endIndex: delta.length - 1,
                });
                filthyData = filthyData.push(filthy);
            }
        } else if (delta.meta && delta.meta.isManual) {
            const wordData = fromJS({
                isManual: true,
                word: delta.text,
                startIndex: delta.startIndex,
                endIndex: delta.length - 1,
            });
            wordsDurData = wordsDurData.push(wordData);
        }
    }

    return {
        wordsDurData,
        filthyData,
        text,
    };
}

export const secondsToSubtitleTimestamp = (seconds) => {
    return secondsToTimestamp({ mode: "subtitle-edit", seconds });
};

export const subtitleTimestampToSeconds = (hms, isPartial) => {
    let seconds = Number.NaN;
    if (
        hms.length === 0
        // allowing user to enter any text except on submit
        || isPartial
    ) {
        return seconds;
    }
    const numberPattern = /^\d*\.?\d*$/;

    const timeSplit = hms.split(":").map(t => {
        const num = Number.parseFloat(t);
        if (Number.isFinite(num) && numberPattern.test(t)) {
            return num;
        }
        if (t.length || isPartial) {
            return Number.NaN;
        }
        return 0;
    });
    if (timeSplit.length !== 3 && isPartial) {
        return seconds;
    }

    if (timeSplit.length === 3) {
        seconds = timeSplit[0] * 60 * 60 + timeSplit[1] * 60 + timeSplit[2];
    } else if (timeSplit.length === 2) {
        seconds = timeSplit[0] * 60 + timeSplit[1];
    } else if (timeSplit.length === 1) {
        seconds = timeSplit[0];
    }
    return seconds;
}

export const isPartialSubtitleTimestampInput = () => {
    return true; // allowing user to enter any text except on submit
    // const partialInput = /^\d*:?\d*:?\d*?\.?\d*$/;
    // return partialInput.test(hms);
}

/**
 * function that applies respective [style || textvalue] to the text and get updated position
 * @param {Object} subtitle
 * @param {string} property 
 * @param {string || number} value 
 * @param {number} zoomFactor,
 * @param {number} projectWidth
 * @param {Object} globalTextStyle
 * @param {Object} globalTextEffects
 */
export const putUpdatedSubTitle = (
    subtitle, property, value, zoomFactor, projectWidth, globalTextStyles, globalTextEffects
) => {
    try {
        /** @description for Specific textEffects we won't maintain outerPadSpace */
        const hasFullWidthEffect = getShouldPreventOuterOffSet(globalTextEffects.get('effectIndex'));
        const hasSplitLineEffect = checkHasSplitLineEffect(globalTextEffects.get('effectIndex'));
        const maxWidth = projectWidth - (hasFullWidthEffect ? 0 : WORKSPACE_PADDING_OFFSET);
        let subtitleAlign = globalTextStyles.get(SUBTITLE_STYLE_OPTION.TEXT_ALIGN.STYLE_NAME)
        if (subtitle.get('textStyles') !== undefined && subtitle.get('textStyles') !== null)
            subtitleAlign = subtitle.getIn(['textStyles', SUBTITLE_STYLE_OPTION.TEXT_ALIGN.STYLE_NAME]);

        const prevX = subtitle.get('x');
        let currX = subtitle.get('x');
        let currY = subtitle.get('y')
        // let prevWidth = parseFloat(subtitle.get('width'));
        // let prevHeight = parseFloat(subtitle.get('height'));
        let INNER_PAD_SPACE = subtitle.get("text").length === 0 ? 0 : SUB_INNER_OFFSET;
        const PREV_INNER_PAD_SPACE = subtitle.get("text").length === 0 ? 0 : SUB_INNER_OFFSET;
        if (property === 'innerText') {
            INNER_PAD_SPACE = value.length === 0 ? 0 : SUB_INNER_OFFSET;
        }

        const subtitleId = subtitle.getIn(["subtitleId", "timelineId"]);
        const subtitleElement = document.getElementById(subtitleId);

        const subDummyElement = document.createElement('div');
        const textElement = document.createElement('p');
        textElement.style.whiteSpace = 'pre-wrap';
        textElement.style.margin = '0px';
        subDummyElement.style.fontKerning = 'none';
        subDummyElement.appendChild(textElement);
        document.body.prepend(subDummyElement);

        textElement.innerHTML = buildSubtitleDomElement(subtitle.get('text'))
        let textStyles = globalTextStyles;
        if (subtitle.get('textStyles') !== undefined && subtitle.get('textStyles') !== null) { /** @Note overRide the style value if the individual subtitle has subtitle textStyles. */
            textStyles = textStyles.merge(subtitle.get('textStyles'))
        }

        let subElmBoundBox = applyStyleAndGetPosition(subDummyElement, textStyles, maxWidth - INNER_PAD_SPACE, hasSplitLineEffect);
        const {
            width: prevWidth,
            height: prevHeight
        } = getSubtitleRect(subElmBoundBox, PREV_INNER_PAD_SPACE, hasFullWidthEffect, hasSplitLineEffect, projectWidth);
        if (property === 'innerText') {
            textElement.innerHTML = buildSubtitleDomElement(value)
        } else if (property === SUBTITLE_STYLE_OPTION.LETTER_SPACING.KEY) { /** @For LineSpacing and letterSpacing */
            subDummyElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = value;
        } else if (property === SUBTITLE_STYLE_OPTION.LINE_HEIGHT.KEY) {
            if (hasSplitLineEffect) {
                const fontSize = parseFloat(textStyles.get('fontSize'));
                const lhInPx = `${Math.ceil(fontSize * (value + 0.2) + SUB_INNER_OFFSET)}px`;
                subDummyElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = lhInPx;
            } else {
                subDummyElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = value;
            }
        } else if (property === SUBTITLE_STYLE_OPTION.FONT_SIZE.KEY) {
            subDummyElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = value;
        }
        subElmBoundBox = subDummyElement.getBoundingClientRect();
        const {
            width: curWidth, height: curHeight
        } = getSubtitleRect(subElmBoundBox, INNER_PAD_SPACE, hasFullWidthEffect, hasSplitLineEffect, projectWidth);
        const widthDiff = (curWidth - prevWidth);
        const heightDiff = (curHeight - prevHeight);
        document.body.removeChild(subDummyElement);
        // Apply [style || value] to current subtitle element
        if (property === 'innerText' && subtitleElement) {
            // subtitleElement.childNodes[0].innerText = value;
        } else if (subtitleElement) {
            subtitleElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = value;
        }
        if (subtitleElement && property === SUBTITLE_STYLE_OPTION.LINE_HEIGHT.KEY && hasSplitLineEffect) {
            const fontSize = zoomFactor * parseFloat(textStyles.get('fontSize'));
            const lhInPx = `${Math.ceil((fontSize * (value + 0.2)) + (SUB_INNER_OFFSET * zoomFactor))}px`;
            subtitleElement.style[SUBTITLE_STYLE_OPTION[property].STYLE_NAME] = value;
            subtitleElement.childNodes[0].style.lineHeight = lhInPx
        }

        if (subtitleAlign === TEXT_ALIGN.CENTER) {
            currX -= widthDiff / 2;
            currY -= heightDiff;
        } else if (subtitleAlign === TEXT_ALIGN.RIGHT) {
            currX -= widthDiff;
            currY -= heightDiff;
        } else if (subtitleAlign === TEXT_ALIGN.LEFT) {
            currX = prevX;
            currY -= heightDiff;
        }

        if (subtitleElement) {
            subtitleElement.style.transform = `translate(${currX * zoomFactor}px, ${currY * zoomFactor}px)`
            subtitleElement.style.width = `${curWidth * zoomFactor}px`
            subtitleElement.style.height = `${curHeight * zoomFactor}px`
        }

        return {
            x: currX,
            y: currY,
            width: curWidth,
            height: curHeight
        }
    } catch (err) { }
    return null;
}

export const addSubtitle = (prevSubtitle, projectDim, globalTextStyles, start, end) => {
    let newX = projectDim.width / 2;
    let newY = projectDim.height - SUB_OUTER_OFFSET;
    const globalTextAlign = globalTextStyles.get("textAlign");

    if (globalTextAlign === TEXT_ALIGN.LEFT) {
        newX = SUB_OUTER_OFFSET;
    } else if (globalTextAlign === TEXT_ALIGN.RIGHT) {
        newX = projectDim.width - SUB_OUTER_OFFSET;
    }

    if (prevSubtitle !== undefined) {
        const prevY = prevSubtitle.get("y") + prevSubtitle.get("height");
        if (prevY > 0 && prevY < projectDim.height) {
            newY = prevY;
        }
    }

    const newSubtitleItem = fromJS({
        start,
        end,
        text: "",
        textStyles: null,
        x: newX,
        y: newY,
        width: 0,
        height: 0,
        angle: 0,
        opacity: 1,
        flipPosition: 0,
        type: "SUBTITLE",
        hasFilthy: false,
        wordsDurData: [],
        filthyData: [],
        id: randomString("subadd"),
    });

    return newSubtitleItem;
}

/**
 * @param {object} params
 * @param {object} params.project
 * @param {TimelineTileState} params.timelineTile
 */
export const addSubtitleTimeline = (params = {}) => {
    const { project, timelineTile, } = params;
    const { sliders, tracks, } = timelineTile;

    const playheadId = SLIDER_TYPES.PLAYHEAD_THUMB;

    const playheadSlider = sliders[playheadId];
    const playheadEnterStartPos = playheadSlider.enterStart.position;

    const orgSubtitleData = project.getIn(["subtitle", "data"]);
    if (!orgSubtitleData) {
        return undefined;
    }

    /** @type {Slider | null} */
    let targetSlider = null;
    let item = null;
    let itemContainer = "";
    iterViewportSliders({
        callback: (slider) => {
            if (slider.sliderType === SLIDER_TYPES.OBJECT) {
                itemContainer = "workspaceItems";
            } else if (slider.sliderType === SLIDER_TYPES.AUDIO) {
                itemContainer = "audios";
            } else {
                itemContainer = "";
            }

            item = project.getIn([itemContainer, slider.itemId]);
            if (!item) {
                return true; // ignore this slider
            }

            const dropId = item.get("dropId");
            if (!orgSubtitleData.get(dropId)) {
                return true; // ignore this slider
            }

            targetSlider = slider;
            return false;
        },
        checkItemIds: true,
        checkSubtitleIds: false,
        sliders,
        viewX: playheadEnterStartPos.x,
        viewY: 0,
        viewWidth: 0,
        viewHeight: 0,
        tracks,
        tracksToCheck: [TRACK_TYPES.OBJECT, TRACK_TYPES.AUDIO],
        axis: 1,
        reverseObjectTrack: true,
        reverseAudioTrack: false,
    });

    if (!targetSlider || !item || !itemContainer) {
        return undefined;
    }

    const dropId = item.get("dropId");
    const orgSubtitleItems = orgSubtitleData.get(dropId);

    let itemStart = item.get("playStart");
    let itemEnd = item.get("playEnd");
    if (itemContainer === "workspaceItems") {
        itemStart = item.get("enterStart");
        itemEnd = item.get("exitEnd");
    }

    let mediaStart = item.get("videoStart");
    if (itemContainer === "audios") {
        mediaStart = item.get("musicStart");
    }

    let speed = item.get("speed");
    if (!Number.isFinite(speed)) {
        speed = 1;
    }

    let playheadTime = playheadEnterStartPos.step * RULER_OPTIONS.interval;

    // remove speed as original subtitle will not have speed applied
    itemEnd = itemStart + (itemEnd - itemStart) * speed;
    playheadTime = itemStart + (playheadTime - itemStart) * speed;

    let newSubtitleEnd = itemEnd;
    let prevSubtitle;

    for (const subtitleItem of orgSubtitleItems.valueSeq()) {
        // relative to item's time without applying speed
        const subtitleStart = itemStart + (subtitleItem.get("start") - mediaStart);
        const subtitleEnd = itemStart + (subtitleItem.get("end") - mediaStart);

        prevSubtitle = subtitleItem;

        if (subtitleEnd > playheadTime) {
            // apply speed to check if playhead is closer to subtitle end in UI
            const subtitleEndWithSpeed = itemStart + (subtitleEnd - itemStart) / speed;
            const playheadTimeWithSpeed = itemStart + (playheadTime - itemStart) / speed;

            const diff = subtitleEndWithSpeed - playheadTimeWithSpeed;
            if (diff >= 0 && diff < RULER_OPTIONS.interval) {
                // playhead is closer to end of subtitle when speed is applied
                // so move playhead so that it is easier for user to add subtitle
                playheadTime = subtitleEnd;
            }
        }

        if (subtitleEnd > playheadTime) {
            // search for subtitle after playhead
            if (subtitleStart - playheadTime >= RULER_OPTIONS.interval) {
                // can use this space
                newSubtitleEnd = subtitleStart;
            } else {
                // no space to add subtitle
                newSubtitleEnd = null;
            }
            break;
        }
    }

    let newSubtitleStart = playheadTime;
    if (!Number.isFinite(newSubtitleStart) || !Number.isFinite(newSubtitleEnd)) {
        return undefined;
    }
    newSubtitleEnd = Math.min(itemEnd, newSubtitleEnd, newSubtitleStart + RULER_OPTIONS.newSubtitleDuration);

    // translate to video duration from project duration
    newSubtitleStart = (newSubtitleStart - itemStart) + mediaStart;
    newSubtitleEnd = (newSubtitleEnd - itemStart) + mediaStart;

    const newSubtitle = addSubtitle(
        prevSubtitle,
        { width: project.get("width"), height: project.get("height"), },
        project.getIn(["subtitle", "textStyles"]),
        newSubtitleStart,
        newSubtitleEnd,
    );

    const toUpdate = [
        {
            container: "subtitleData",
            id: newSubtitle.get("id"),
            dropId,
            langId: project.get("defaultSubtitle"),
            timelineId: `${item.get("id")}-${dropId}-${newSubtitle.get("id")}`,
            isAdd: true,
            newItemData: newSubtitle.toJS(),
        }
    ];

    return toUpdate;
};

export const mergeSubtitle = ({ left, localLeft }, { right }, zoomFactor, projectWidth, globalTextStyle, globalTextEffects) => {
    try {
        const originalLeft = left;
        if (left.get("text") && right.get("text")) {
            let newSubtitle = left.set("text", `${left.get("text")} ${right.get("text")}`);
            const rightIndexStart = left.get("text").length; // left end index = left text length - 1, right index start = left end index + 1 space

            if (right.get("wordsDurData")) {
                const rightWordsDurData = right.get("wordsDurData").map((wordData) => {
                    return (
                        wordData
                            .set("startIndex", rightIndexStart + wordData.get("startIndex"))
                            .set("endIndex", rightIndexStart + wordData.get("endIndex"))
                    );
                });
                newSubtitle = newSubtitle.set(
                    "wordsDurData",
                    newSubtitle.get("wordsDurData").concat(rightWordsDurData)
                );
            }
            if (right.get("filthyData")) {
                const rightFilthyData = right.get("filthyData").map((filthyWord) => {
                    return (
                        filthyWord
                            .set("startIndex", rightIndexStart + filthyWord.get("startIndex"))
                            .set("endIndex", rightIndexStart + filthyWord.get("endIndex"))
                    );
                });
                newSubtitle = newSubtitle.set(
                    "filthyData",
                    newSubtitle.get("filthyData").concat(rightFilthyData)
                );
            }

            let pos;
            if (newSubtitle.get("text").length > 0) {
                pos = putUpdatedSubTitle(
                    localLeft,
                    "innerText",
                    newSubtitle.get("text"),
                    zoomFactor, projectWidth,
                    globalTextStyle, globalTextEffects
                );
            } else {
                pos = { x: originalLeft.get("x"), y: originalLeft.get("y"), width: originalLeft.get("width"), height: originalLeft.get("height") };
            }

            newSubtitle = newSubtitle.set("x", pos.x);
            newSubtitle = newSubtitle.set("y", pos.y);
            newSubtitle = newSubtitle.set("width", pos.width);
            newSubtitle = newSubtitle.set("height", pos.height);
            newSubtitle = newSubtitle.set("end", right.get("end"));

            left = newSubtitle;
            right = null;
        } else if (left.get("text")) {
            left = left.set("end", right.get("end"));
            right = null;
        } else if (right.get("text")) {
            right = right.set("start", left.get("start"));
            left = null;
        } else {
            left = left.set("end", right.get("end"));
            right = null;
        }
    } catch (error) {
        left = null;
        right = null;
    }
    return { left, right };
}

export function validateSubtitlePosition(currX, currY, curWidth, curHeight, projectWidth, projectHeight) {
    const leftOffSet = 15;
    const rightOffSet = 15;
    const topOffSet = 15;
    const bottomOffSet = 15;
    if (currY > projectHeight - bottomOffSet) // validate bottomY
        currY = projectHeight - bottomOffSet - curHeight;
    if (currX > projectWidth - rightOffSet) // validate rightX
        currX = projectWidth - rightOffSet - curWidth;
    if (currX + curWidth < leftOffSet) // validate leftX
        currX = leftOffSet;
    if (currY + curHeight < topOffSet) // validate topY
        currY = topOffSet;
    return {
        x: currX,
        y: currY,
        width: curWidth,
        height: curHeight,
    };
}

/**
 * @description function that applies respective style to the dummy text and get updated position
 * @param {Object} subtitleStoreData
 * @param {Object} toUpdateStyle 
 * @param {Number} projectWidth
 * @param {Number} projectHeight
 * @param {Object} globalTextStyles
 * @param {Object} globalTextEffects
 * @param {Boolean} forTextEffects
 * @param {Object} position
 */
export const getUpdatedSubTitleList = (
    subtitleStoreData,
    toUpdateStyle = {},
    projectWidth,
    projectHeight,
    globalTextStyles = fromJS({}),
    globalTextEffects,
    forTextEffects = false,
    position = null,
) => {
    try {
        /** @description for Specific textEffects we won't maintain outerPadSpace */
        const hasFullWidthEffect = getShouldPreventOuterOffSet(
            globalTextEffects.get("effectIndex")
        );
        const hasSplitLineEffect = checkHasSplitLineEffect(
            globalTextEffects.get("effectIndex")
        );
        const maxWidth =
            projectWidth - (hasFullWidthEffect ? 0 : WORKSPACE_PADDING_OFFSET);
        const subtitleElement = document.createElement("div");
        const textElement = document.createElement("p");
        textElement.style.whiteSpace = "pre-wrap";
        textElement.style.margin = "0px";
        subtitleElement.style.fontKerning = "none";
        subtitleElement.appendChild(textElement);
        document.body.prepend(subtitleElement);
        const updatedSubtitleList = subtitleStoreData.map((subtitleList) => {
            return subtitleList.map((subtitle, index) => {
                if (position) {
                    const { yLoc, offset } = position;
                    subtitle = subtitle.set('y', (yLoc * projectHeight) - (subtitle.get('height') / 2) + offset);
                    if (subtitle.get("y") + subtitle.get('height') > projectHeight) {
                        const yOffset = projectHeight - (subtitle.get("y") + subtitle.get('height'));
                        subtitle = subtitle.update('y', (data) => data + yOffset);
                    } else if (subtitle.get("y") < 0) {
                        subtitle = subtitle.set('y', 0);
                    }
                }
                const prevX = subtitle.get("x");
                let currX = subtitle.get("x");
                let currY = subtitle.get("y");
                textElement.innerHTML = buildSubtitleDomElement(subtitle.get("text"));
                let textStyles = globalTextStyles;
                if (
                    subtitle.get("textStyles") !== undefined &&
                    subtitle.get("textStyles") !== null
                ) {
                    /** @Note overRide the style value if the individual subtitle has subtitle textStyles. */
                    textStyles = textStyles.merge(subtitle.get("textStyles"));
                }
                const innerPadSpace =
                    subtitle.get("text").length === 0 ? 0 : SUB_INNER_OFFSET;
                let subElmBoundBox = applyStyleAndGetPosition(
                    subtitleElement,
                    textStyles,
                    maxWidth - innerPadSpace,
                    hasSplitLineEffect
                );
                const { width: prevWidth, height: prevHeight } = getSubtitleRect(
                    subElmBoundBox,
                    innerPadSpace,
                    hasFullWidthEffect,
                    hasSplitLineEffect,
                    projectWidth
                );

                for (const property in toUpdateStyle) {
                    if (
                        hasSplitLineEffect &&
                        property === SUBTITLE_STYLE_OPTION.LINE_HEIGHT.STYLE_NAME
                    ) {
                        const fontSize = parseFloat(textStyles.get("fontSize"));
                        const lhInPx = `${Math.ceil(
                            (toUpdateStyle[property] + 0.2) * fontSize + SUB_INNER_OFFSET
                        )}px`;
                        subtitleElement.style[property] = lhInPx;
                    } else if (
                        hasSplitLineEffect &&
                        property === SUBTITLE_STYLE_OPTION.FONT_SIZE.STYLE_NAME
                    ) {
                        const fontSize = parseFloat(toUpdateStyle[property]);
                        const lhInPx = `${Math.ceil(
                            (textStyles.get("lineHeight") + 0.2) * fontSize + SUB_INNER_OFFSET
                        )}px`;
                        subtitleElement.style[property] = toUpdateStyle[property];
                        subtitleElement.style.lineHeight = lhInPx;
                    } else {
                        subtitleElement.style[property] = toUpdateStyle[property];
                    }
                }
                subElmBoundBox = subtitleElement.getBoundingClientRect();
                const { width: curWidth, height: curHeight } = getSubtitleRect(
                    subElmBoundBox,
                    innerPadSpace,
                    hasFullWidthEffect,
                    hasSplitLineEffect,
                    projectWidth
                );
                const widthDiff = curWidth - prevWidth;
                const heightDiff = curHeight - prevHeight;
                let subtitleAlign = textStyles.get("textAlign");
                if (forTextEffects === false) {
                    if (subtitleAlign === TEXT_ALIGN.CENTER) {
                        currX -= widthDiff / 2;
                        currY -= heightDiff;
                    } else if (subtitleAlign === TEXT_ALIGN.RIGHT) {
                        currX -= widthDiff;
                        currY -= heightDiff;
                    } else if (subtitleAlign === TEXT_ALIGN.LEFT) {
                        currX = prevX;
                        currY -= heightDiff;
                    }
                } else {
                    const offSet = hasFullWidthEffect ? 0 : SUB_OUTER_OFFSET;
                    if (toUpdateStyle.textAlign !== undefined)
                        subtitleAlign = toUpdateStyle.textAlign;
                    if (subtitleAlign === TEXT_ALIGN.CENTER) {
                        currX = projectWidth / 2 - curWidth / 2;
                    } else if (subtitleAlign === TEXT_ALIGN.RIGHT) {
                        currX = projectWidth - offSet - curWidth;
                    } else if (subtitleAlign === TEXT_ALIGN.LEFT) {
                        currX = offSet;
                    }
                    currY = projectHeight - curHeight - offSet * 2;
                }
                return {
                    index,
                    toUpdatePos: validateSubtitlePosition(
                        currX,
                        currY,
                        curWidth,
                        curHeight,
                        projectWidth,
                        projectHeight
                    ),
                };
            });
        });
        document.body.removeChild(subtitleElement);
        return updatedSubtitleList.toJS();
    } catch (err) { }
    return null;
};

/**
 * @description function that applies respective style (TEXT_ALIGN) to the dummy text and get updated position
 * @param {Object} subtitleStoreData // Immutable object
 * @param {String} subtitleAlign 
 * @param {Number} projectWidth 
 * @param {Object} globalTextEffects // Immutable object
 */
export const alignMentChangeOnSubTitleList = (
    subtitleStoreData,
    subtitleAlign,
    projectWidth,
    globalTextEffects
) => {
    try {
        const shouldPreventOuterOffSet = getShouldPreventOuterOffSet(
            globalTextEffects.get("effectIndex")
        );
        const updatedSubtitleList = subtitleStoreData.map((subtitleList) => {
            return subtitleList.map((subtitle, index) => {
                let currX = subtitle.get("x");
                const currY = subtitle.get("y");
                const currWidth = subtitle.get("width");
                if (subtitleAlign !== undefined && subtitleAlign !== "") {
                    if (shouldPreventOuterOffSet) {
                        currX = 0;
                    } else if (subtitleAlign === TEXT_ALIGN.CENTER) {
                        currX = Math.abs(projectWidth / 2 - currWidth / 2);
                    } else if (subtitleAlign === TEXT_ALIGN.RIGHT) {
                        currX =
                            projectWidth -
                            SUB_OUTER_OFFSET -
                            currWidth -
                            (subtitle.get("text") === "" ? SUB_INNER_OFFSET : 0);
                    } else if (subtitleAlign === TEXT_ALIGN.LEFT) {
                        currX = SUB_OUTER_OFFSET;
                    }
                    return {
                        index,
                        toUpdatePos: {
                            x: currX,
                            y: currY,
                        },
                    };
                }
                return null;
            });
        });
        return updatedSubtitleList.toJS();
    } catch (err) {
    }
    return null;
};

/**
 * Converts BGR color code to HEX
 * @param {string} bgr 
 */
export const bgr2hex = (bgr) => {
    if (!bgr) return undefined;
    let formattedBGR = bgr.replace("&H", "").replace("#", "");

    /** @description fallback sometimes bgr length is not 8 that we omit alpha value. */
    formattedBGR = formattedBGR.length === 7 ? formattedBGR.substr(1) : formattedBGR;
    if (formattedBGR.length > 6) {
        // const A = formattedBGR.substring(0, 2);
        const B = formattedBGR.substring(2, 4);
        const G = formattedBGR.substring(4, 6);
        const R = formattedBGR.substring(6, 8);
        // const hexInDecimal = parseInt(A, 16);
        return `#${R}${G}${B}` // + (255 - hexInDecimal).toString(16); // reversing color for render
    }
    const B = formattedBGR.substring(0, 2);
    const G = formattedBGR.substring(2, 4);
    const R = formattedBGR.substring(4, 6);
    return `#${R}${G}${B}`;

}

/**
 * 
 * @description check if fontFamily is exist in our admin fonts, if exist return it's fontFamilyName
 * @param {string} fontFamily 
 * @returns 
 */
export const getFontDetials = (fontFamily) => {
    let fontFamilyName = 'Roboto'
    const isFontFamilyExist = fontFamilies.getIn(['default', 'fonts']).some(fontData => {
        if (fontData.get('regular') === fontFamily || fontData.get('bold') === fontFamily) {
            fontFamilyName = fontData.get('fontFamily')
            return true
        }
        return false

    })
    return {
        fontFamilyName,
        isFontFamilyExist
    }
}

/**
* @summary function used the extract globalStyale from subtitles and set individual style if required.
* @return  Object {subtitlesList, textStyles}
*/
export function parseSubtitleStyles(subtitles, defaultTextStyles, shouldPrevParseTextStyles = false) {
    const DEFAULT_TEXT_STYLE = defaultTextStyles.toJS();
    const textStylesArr = shouldPrevParseTextStyles ? [] : Object.values(subtitles).map(subtitle => subtitle.textStyles)
    let globalStylesRecord = fromJS({
        /** @Note data-structures */
        // color: [{ value: '#fffff', count: 1 }] 
    })
    let globalStyles = fromJS({})

    /** @summary check the value of the key count is taken */
    function isCountTaken(key, value) {
        return globalStylesRecord.get(key) ? globalStylesRecord.get(key).some(data => data.get('value') === value) : false
    }

    /** @summary linearly loopThrough subtitlesStyles and update globalStylesRecord value */
    function setGloabalStyleRecordes(key, subtitlesStyles) {
        for (let i = 0; i < subtitlesStyles.length; i++) {
            let currStyleValue = subtitlesStyles[i][key];
            currStyleValue = currStyleValue === undefined ? DEFAULT_TEXT_STYLE[key] : currStyleValue;
            if (isCountTaken(key, currStyleValue))
                continue;
            let count = 1;
            for (let j = i + 1; j < subtitlesStyles.length; j++) {
                const nxtStyleValue = subtitlesStyles[j][key];
                if (currStyleValue === nxtStyleValue) {
                    count++;
                }
            }
            if (globalStylesRecord.get(key) === undefined)
                globalStylesRecord = globalStylesRecord.set(key, fromJS([]))
            if (count >= subtitlesStyles.length / 2) { /* if value count is > half the len the consider that to be globalStyle */
                globalStylesRecord = globalStylesRecord.set(key, fromJS([{ value: currStyleValue, count }]))
                break;
            } else {
                globalStylesRecord = globalStylesRecord.set(key, globalStylesRecord.get(key).push(fromJS({ value: currStyleValue, count })))
            }
        }
    }

    /** 
     * @summary set globalStyles value using reduce method on globalStylesRecord.
     * the value whick has maximum count will be considered. 
     */
    function setGloablStyle() {
        for (const key in DEFAULT_TEXT_STYLE) {
            if (key === 'others') {
                globalStyles = globalStyles.set(key, fromJS(DEFAULT_TEXT_STYLE[key]))
            } else if (shouldPrevParseTextStyles === true) {
                globalStyles = defaultTextStyles;
            } else {
                globalStyles = globalStyles.set(key, globalStylesRecord.get(key)
                    // eslint-disable-next-line array-callback-return, consistent-return
                    .reduce((accumulator, currentValue) => {
                        if (accumulator.get('count') < currentValue.get('count')) {
                            return currentValue
                        }
                    }, fromJS({ count: 0 })).get('value'))
            }
        }
    }

    /** @summary loop throught DEFAULT_TEXT_STYLE and sets the globalStylesRecords value */
    for (const key in DEFAULT_TEXT_STYLE) {
        if (key === 'others') continue;
        setGloabalStyleRecordes(key, textStylesArr)
    }

    /** @summary from globalStylesRecords value segregate globalStyle based on value count */
    setGloablStyle()
    const subtitlesList = shouldPrevParseTextStyles ? subtitles : {};
    /** @summary set individual subtitle style */
    if (!shouldPrevParseTextStyles) {
        Object.entries(subtitles).forEach(([subtitleId, subtitle]) => {
            const textStyles = {}
            for (const key in DEFAULT_TEXT_STYLE) {
                if (key === 'others') continue;
                if (subtitle.textStyles[key] !== undefined && subtitle.textStyles[key] !== globalStyles.get(key)) {
                    textStyles[key] = subtitle.textStyles[key];
                }
            }
            subtitle.textStyles = Object.keys(textStyles).length > 0 ? textStyles : undefined;
            subtitlesList[subtitleId] = subtitle;
        })
    }

    return {
        textStyles: globalStyles.toJS(),
        subtitlesList
    }
}

export function time2sec(time) {
    const hms = time;   // your input string
    const a = hms.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
}

/**
 * @description based on video aspect the fontSize get decided
 * @param {Number} projectWidth 
 * @param {Number} projectHeight 
 * @returns {Number}
 */
export function getInitialFontSizeValue(projectWidth, projectHeight) {
    let fontSize;
    if (projectWidth >= projectHeight) {
        // horizontal and square;
        fontSize = parseInt((5.333333333333334 * projectHeight) / 100, 10);
    } else if (projectWidth < projectHeight) {
        // vertical;
        fontSize = parseInt((5.333333333333334 * projectWidth) / 100, 10);
    }
    const MIN = 7;
    const MAX = 50;
    // eslint-disable-next-line no-nested-ternary
    return fontSize < MIN ? MIN : fontSize > MAX ? MAX : fontSize;
}

/**
 * @param {Number} projectWidth
 * @param {Number} projectHeight
 * @returns {Object}
 */
export function getDefaultTextStyles(projectWidth = undefined, projectHeight = undefined) {
    return fromJS({
        fontFamily: "Roboto-Regular",
        fontFamilyName: "Roboto",
        fontSize:
            projectWidth !== undefined && projectHeight !== undefined
                ? `${getInitialFontSizeValue(projectWidth, projectHeight)}px`
                : "24px",
        textAlign: "center",
        lineHeight: 1.2,
        color: "#FFFFFF",
        background: "#000000",
        name: "default",
        letterSpacing: "0em",
        isBold: 0,
        isItalic: 0,
        isUnderline: 0,
        isStrikeThrough: 0,
        bgAlpha: 100,
        colorAlpha: 100,
        textTransform: TEXT_TRANSFORM[0],
        others: {
            isUserFont: false,
            cssUrl: "",
        },
    });
}

/**
 * Function to return the language name with language code.
 * @param {Array} languages 
 * @param {string} value 
 * @returns string of language name.
 */
export const getLanguageLabel = (languages, value) => {
    const language = languages ? languages.find((lang) => lang.value === value) : null;
    return language ? language.label : value;
};

/**
 * Checks whether all the subtitles are transcribed.
 * @param {Object} workspaceItems - The items in the workspace.
 * @param {Object} audios - The audio data.
 * @param {Array} subtitleDatas - The array of subtitle data.
 * @returns {boolean} True if all subtitles are generated, false otherwise.
 */
export const isAllSubtitlesGenerated = (workspaceItems, audios, subtitleDatas) => {
    // Check if every item in `items` has a corresponding subtitle in `subtitleDatas`
    const isAllGenerated = Object.values({ ...workspaceItems, ...audios }).every((item) => {
        // Check if there is at least one subtitleData where the item.assetId matches
        return subtitleDatas?.some((subtitleData) => {
            return subtitleData?.assetDetails?.some((data) => item.isDetachedAudio ?
                (data.assetId === item.assetId && data.itemId === item.dropId) :
                data.assetId === item.assetId);
        });
    });
    return isAllGenerated;
}