import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { font } from "../constants/font";
import { setWorkspaceTextFocus } from "../redux/actions/appUtils";
import CustomTooltipComponent from "./CustomTooltip";
import {STATIC_PATH} from "../constants/config";

const Container = styled.div`
  margin: 13px 0 10px;
`;

const Label = styled.label`
  display: flex;
  gap: 8px;
  font: ${font.normalBase_16};
  margin-bottom: 10px;
  position: relative;
`;

const Icon = styled.img`
  width: "12px";
  height: "7px";
`;

const RequiredStar = styled.span`
  color: ${(props) => props.theme.warningColor};
  font: ${font.normalBase_16};
`;

const Input = styled.input`
  max-width: 100%;
  width: -webkit-fill-available;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-color: ${(props) =>
    props.hasError
      ? `${props.theme.warningColor}`
      : `${props.theme.primaryBorderColor}`};
  border-radius: 4px;
  padding: 10px;
  font: ${font.normalMini_17};
  &:focus {
    outline: 0px;
    box-shadow: none;
    border-color: ${(props) => props.theme.mediumGray};
  }
  &:hover {
    border-color: ${(props) => props.theme.mediumGray};
  }
  ::placeholder {
    font: ${font.normalBase};
    color: ${(props) => props.theme.inputTextColor};
  }
`;

const LabeledInput = ({
  label,
  value,
  type,
  onChange,
  hasError = false,
  tooltipContent,
  requiredStar = true,
  ...inputProps
}) => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Label>
        {label}
        {requiredStar && <RequiredStar>*</RequiredStar>}
        {tooltipContent && (
          <CustomTooltipComponent
            tooltipId={"red-tooltip"}
            dataTooltip={tooltipContent}
            tooltipPosition="top"
          >
            <span data-tooltip-id={"red-tooltip"}>
              <Icon
                src={`${STATIC_PATH}vm-info.svg`}
                data-tooltip-id={"credits-tooltip"}
                height="15px"
                width="15px"
              />
            </span>
          </CustomTooltipComponent>
        )}
      </Label>
      <Input
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => dispatch(setWorkspaceTextFocus(true))}
        onBlur={() => dispatch(setWorkspaceTextFocus(false))}
        {...inputProps}
        hasError={hasError}
      />
    </Container>
  );
};

LabeledInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.string,
  hasError: PropTypes.bool,
  tooltipContent: PropTypes.string,
  requiredStar: PropTypes.bool,
  type: PropTypes.oneOf(["text", "email", "number", "textarea"]),
};

export default LabeledInput;
