import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { SettingsWindowStyled } from "./propertywindow-components";
import content from "../../../constants/content";
import ImageFilter from "./propertywindow-imagefilter";
import AudioSection from "./propertywindow-audio";
import TextSettings from "./text/propertywindow-text";
import { getSelectedItemIds, getSelectedObjects } from "../../../helper/IndividualSelectHelper";
import TextEffects from "./text/propertywindow-texteffects";
import VideoSection from "./propertywindow-video";
import ImageSection from "./propertywindow-image";

const ImageSettings = () => {
    const windowLevel = 1;

    return (
        <SettingsWindowStyled className="settings-tool-bar" $windowLevel={windowLevel}>
            <div className="sw--header">
                <FormattedMessage id={content.SETTINGS} />
            </div>
            <div className="sw--body">
                <div className="sw--section">
                    <ImageFilter
                        parentWindowLevel={windowLevel}
                    />
                </div>
                <div className="sw--section">
            <p className="sw-section--heading">
              <FormattedMessage id={content.IMAGE} />
            </p>
            <ImageSection />
          </div>
            </div>
        </SettingsWindowStyled>
    );
}

const VideoSettings = () => {
    const windowLevel = 1;

    return (
      <SettingsWindowStyled
        className="settings-tool-bar"
        $windowLevel={windowLevel}
      >
        <div className="sw--header">
          <FormattedMessage id={content.SETTINGS} />
        </div>
        <div className="sw--body">
          <div className="sw--section">
            <ImageFilter parentWindowLevel={windowLevel} />
          </div>
          <div className="sw--section">
            <p className="sw-section--heading">
              <FormattedMessage id={content.AUDIO} />
            </p>
            <AudioSection />
          </div>
          <div className="sw--section">
            <p className="sw-section--heading">
              <FormattedMessage id={content.VIDEO} />
            </p>
            <VideoSection />
          </div>
        </div>
      </SettingsWindowStyled>
    );
}

const AudioSettings = () => {
    const windowLevel = 1;

    return (
        <SettingsWindowStyled className="settings-tool-bar" $windowLevel={windowLevel}>
            <div className="sw--header">
                <FormattedMessage id={content.SETTINGS} />
            </div>
            <div className="sw--body">
                <div className="sw--section">
                    <p className="sw-section--heading">
                        <FormattedMessage id={content.AUDIO} />
                    </p>
                    <AudioSection />
                </div>
            </div>
        </SettingsWindowStyled>
    );
}

const SettingsWindow = (props) => {
    const { textPropertyShortcut } = props;

    const propertyWindow = useSelector(state => state.app.get("propertyWindow"));
    const _selectedItems = useSelector(state => state.app.get("selectedItems"));
    const childrenSelection = useSelector(state => state.app.get("childrenSelection"));
    const workspaceItems = useSelector(state => state.projectDetails.get("workspaceItems"));
    const workspaceChildren = useSelector(state => state.projectDetails.get("workspaceChildren"));
    const textStatus = useSelector(state => state.app.get("textStatus"));

    const selectedItems = useMemo(() => {
        return getSelectedItemIds({
            childrenSelection,
            selectedItems: _selectedItems,
        });
    }, [
        childrenSelection,
        _selectedItems,
    ]);
    const selectedObjects = useMemo(() => {
        return getSelectedObjects({
            childrenSelection,
            selectedItems: _selectedItems,
            workspaceChildren,
            workspaceItems,
        });
    }, [
        childrenSelection,
        _selectedItems,
        workspaceChildren,
        workspaceItems,
    ]);

    let settings;

    let showTextSettings = false;
    let showTextEffects = false;
    if (selectedItems.size === 1) {
        const selectedItem = selectedObjects.get(selectedItems.get(0));

        showTextSettings = (
            textStatus.get("isFocused")
            || (textStatus.get("isSelected") && propertyWindow.get("type") === "TEXT" && textStatus.get("id") === selectedItems.get(0))
        );
        showTextEffects = (
            selectedItem.get("type") === "TEXT"
            && textStatus.get("id") !== null
            && selectedItems.get(0) === textStatus.get("id")
            && !propertyWindow.get("isTextColor")
        );
    }

    if (propertyWindow.get("type") === "IMAGE_SETTINGS") {
        settings = <ImageSettings />;
    } else if (propertyWindow.get("type") === "VIDEO_SETTINGS") {
        settings = <VideoSettings />;
    } else if (propertyWindow.get("type") === "AUDIO_SETTINGS") {
        settings = <AudioSettings />;
    } else if (showTextSettings || showTextEffects) {
        const textOptions = [];

        if (showTextSettings) {
            textOptions.push(
                <div key={"text-settings"} className="sw--section">
                    <TextSettings
                        shortcutName={textPropertyShortcut}
                        isTextColor={propertyWindow.get("isTextColor")}
                        selectedObjects={selectedObjects}
                        selectedTextId={selectedItems.get(0)}
                        selectedTextData={selectedObjects.get(selectedItems.get(0))}
                    // transparency={transparency}
                    // onTransparencyPaste={this.handleTransparencyPaste}
                    // pushPasteData={this.handlePasteDataUpdate}
                    // incrementPasteCount={this.incrementPasteCount}
                    // resetPasteData={this.resetPasteData}
                    />
                </div>
            );
        }

        if (showTextEffects) {
            textOptions.push(
                <div key={"text-effects"} className="sw--section">
                    <TextEffects />
                </div>
            );
        }

        let headerLangId = content.SETTINGS;
        if (propertyWindow.get("isTextColor")) {
            headerLangId = content.COLOR_CH;
        }

        settings = (
            <SettingsWindowStyled className="settings-tool-bar" $windowLevel={1}>
                <div className="sw--header">
                    <FormattedMessage id={headerLangId} />
                </div>
                <div className="sw--body">
                    {textOptions}
                </div>
            </SettingsWindowStyled>
        );
    }

    return settings;
}

export default SettingsWindow;
