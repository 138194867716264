/* eslint-disable jsx-a11y/no-static-element-interactions, react/no-array-index-key, prefer-template */

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { fontFamilies } from '../../../text/text-constants';
import { chooseFont, setWorkspaceTextFocus, updateTextStatus } from '../../../../redux/actions/appUtils';
import { ASSET_URL, STATIC_PATH } from '../../../../constants/config';
import SearchInputFilter from '../../../../common-components/SearchInputFilter';
import content from '../../../../constants/content';
import { FontFamilyContainer } from './textproperty-components';

class FontFamilyPropertyWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchVal: ''
        };

        this.clickedFontItem = null;
        this.fontFamilies = fontFamilies;
    }

    handleFontFamilyClicked = (e, item) => {
        const { textStatus, chooseFont, clickHandlerCallback } = this.props;
        if (typeof clickHandlerCallback === "function") {
            clickHandlerCallback(item)
        } else {
            const toUpdate = item.set("id", textStatus.get("id"));
            chooseFont(toUpdate);
        }
        this.clickedFontItem = item;
    }

    handleFontSearch = e => {
        this.setState({
            searchVal: e.target.value
        })
    }

    handleSearchFocus = () => {
        if (this.props.textStatus.get('isFocused'))
            this.props.updateTextStatus({ id: this.props.textStatus.get('id'), isFocused: false, isSelected: true, fontSearch: true });

        this.props.setWorkspaceTextFocus(true);
    };

    handleSearchBlur = () => {
        this.props.setWorkspaceTextFocus(false);
    };

    render() {
        const { windowLevel = 2, intl, activeFontFamily, activeFontName, userFonts } = this.props;

        const selectedIconSrc = STATIC_PATH + "text-property/font-selected.svg";
        const loaderSrc = STATIC_PATH + "chr-loading.gif";

        const fonts = [];
        const brandFontList = [];
        const fontChecking = [];
        let BrandLi = null;
        let fontFamilyList = null;

        const fontData = this.fontFamilies.getIn(["default", "fonts"]).find(x => x.get("fontFamily") === activeFontName);
        const userFontData = userFonts.find(x => x.get("fontFamily") === activeFontName || x.get('fontname') === activeFontName);

        userFonts.forEach((item) => {
            if (item && item.get("subtype") === "BRAND") {
                brandFontList.push(item.get("fontname"))
            }
        });

        if (this.fontFamilies.size > 0) {
            this.fontFamilies.forEach((items, index) => {
                const fontTypes = [];
                if (items.get("fonts").size > 0) {
                    items.get("fonts").forEach((item, idx) => {
                        if (!(item.get('fontFamily').toLowerCase()).includes(this.state.searchVal.toLowerCase()))
                            return;

                        if ((!fontChecking.includes(item.get("image"))) && (!brandFontList.includes(item.get("fontFamily")))) {
                            fontChecking.push(item.get("image"))
                            const isActive = (
                                (
                                    !fontData
                                    && (
                                        this.props.textOptions.getIn(["fontFamily", "value"]) === item.get("regular")
                                        || `${this.props.textOptions.getIn(["fontFamily", "value"])}-Regular` === item.get("regular")
                                    )
                                ) || (
                                    fontData
                                    && fontData.get("regular") === item.get("regular")
                                )
                            );
                            const isLoading = (
                                this.clickedFontItem === item
                                && this.props.isFontLoading
                            );

                            let src;
                            if (isActive) {
                                src = selectedIconSrc;
                            } else if (isLoading) {
                                src = loaderSrc;
                            }

                            let fontCls = "ffc--font-style";
                            if (isActive) {
                                fontCls += " active";
                            } else if (isLoading) {
                                fontCls += " loading";
                            }

                            fontTypes.push(
                                <li
                                    key={idx}
                                    className={fontCls}
                                    onClick={(e) => this.handleFontFamilyClicked(e, item)}
                                >
                                    {
                                        (item.get("displayName") === undefined)
                                            ? <img alt="font name" src={ASSET_URL + "animaker/admin-fonts/" + item.get("image")} />
                                            : <span>{item.get("displayName")}</span>
                                    }
                                    {
                                        src && (
                                            <img src={src} className="loader" alt="loader" />
                                        )
                                    }
                                </li>
                            );
                        }
                    });

                    if (fontTypes.length > 0) {
                        fonts.push(
                            <li className="ffc--font-styles" key={index} >
                                {
                                    (items.get('name').toLowerCase() === 'default fonts')
                                        ? <h3>{intl.formatMessage({ id: content.DEFAULT_FONTS })}</h3>
                                        : <h3>{items.get("name")}</h3>
                                }
                                <ul>{fontTypes}</ul>
                            </li>
                        );
                    }
                }
            });
        }

        if (userFonts.size > 0) {
            const fontTypes = [];
            const brandTypes = [];
            let FontLi = '';
            const fontCheckingCustom = [];

            userFonts.forEach((item, index) => {
                if (!(item.get('fontFamily').toLowerCase()).includes(this.state.searchVal.toLowerCase()))
                    return;
                if (item.get('subtype') === 'BRAND') {
                    const isActive = (
                        (
                            !fontData
                            && this.props.textOptions.getIn(["fontFamily", "value"]) === item.get("fontFamily")
                        )
                        || item.get('fontname') === activeFontName
                        || item.get('fontname') === activeFontFamily.replace(/^[\"]|[\"]$/g, "") // eslint-disable-line
                        || item.get("fontFamily") === activeFontFamily.replace(/^[\"]|[\"]$/g, "") // eslint-disable-line
                    );
                    const isLoading = (
                        this.clickedFontItem === item
                        && this.props.isFontLoading
                    );

                    let src;
                    if (isActive) {
                        src = selectedIconSrc;
                    } else if (isLoading) {
                        src = loaderSrc;
                    }

                    let fontCls = "ffc--font-style";
                    if (isActive) {
                        fontCls += " active";
                    } else if (isLoading) {
                        fontCls += " loading";
                    }

                    brandTypes.push(
                        <li
                            key={index}
                            className={fontCls}
                            onClick={(e) => { this.handleFontFamilyClicked(e, item) }}
                        >
                            {
                                (item.get("displayName") === undefined)
                                    ? <img alt="font name" src={item.get("thumbnail")} />
                                    : <span>{item.get("displayName")}</span>
                            }
                            {
                                src && (
                                    <img src={src} className="loader" alt="loader" />
                                )
                            }
                        </li>);
                } else if ((!fontCheckingCustom.includes(item.get("fontname"))) && (!fontChecking.includes(item.get("fontname"))) && (!brandFontList.includes(item.get("fontFamily")))) {
                    const isActive = (
                        (
                            !userFontData
                            && this.props.textOptions.getIn(["fontFamily", "value"]) === item.get("fontFamily")
                        )
                        || item.get('fontname') === activeFontName
                        || item.get('fontname') === activeFontFamily.replace(/^[\"]|[\"]$/g, "") // eslint-disable-line
                        || item.get("fontFamily") === activeFontFamily.replace(/^[\"]|[\"]$/g, "") // eslint-disable-line
                    );
                    const isLoading = (
                        this.clickedFontItem === item
                        && this.props.isFontLoading
                    );

                    let src;
                    if (isActive) {
                        src = selectedIconSrc;
                    } else if (isLoading) {
                        src = loaderSrc;
                    }

                    let fontCls = "ffc--font-style";
                    if (isActive) {
                        fontCls += " active";
                    } else if (isLoading) {
                        fontCls += " loading";
                    }

                    fontCheckingCustom.push(item.get("fontname"));
                    fontTypes.push(
                        <li
                            key={index}
                            className={fontCls}
                            onClick={(e) => { this.handleFontFamilyClicked(e, item) }}
                        >
                            {
                                (item.get("displayName") === undefined)
                                    ? <img alt="font name" src={item.get("thumbnail")} />
                                    : <span>{item.get("displayName")}</span>
                            }
                            {
                                src && (
                                    <img src={src} className="loader" alt="loader" />
                                )
                            }
                        </li>
                    );
                }
            });
            if (brandTypes.length) {
                BrandLi = (
                    <li className="ffc--font-styles user-fonts" key="user-uploaded-brand-font">
                        <h3>{intl.formatMessage({ id: content.BRAND_FONTS })}</h3>
                        <ul>{brandTypes}</ul>
                    </li>
                );
            }
            if (fontTypes.length) {
                FontLi = (
                    <>
                        <h3>{intl.formatMessage({ id: content.UPLOADED_FONTS })}</h3>
                        <ul>{fontTypes}</ul>
                    </>
                );
            }
            fonts.push(
                <li className="ffc--font-styles user-fonts" key="user-uploaded-font">
                    {FontLi}
                </li>
            );
        }

        if (BrandLi) {
            fonts.splice(0, 0, BrandLi);
        }

        if (fonts.length > 0) {
            fontFamilyList = (
                <div className="ffc--body">
                    <ul>{fonts}</ul>
                </div>
            );
        }

        return (
            <FontFamilyContainer $windowLevel={windowLevel}>
                <div className="ffc--top-section">
                    <div className="ffc--header" onClick={this.props.closeFontFamilyWindow}>
                        <img draggable={false} src={`${STATIC_PATH}downArrow.svg`} alt="back" />
                        <FormattedMessage id={content.CH_FONT} />
                    </div>
                    <div className='ffc--search-container'>
                        <form
                            autoComplete="off"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <SearchInputFilter
                                autoComplete="off"
                                placeholder={intl.formatMessage({ id: content.SEARCH_FONTS })}
                                inputValue={this.state.searchVal}
                                inputHandler={this.handleFontSearch}
                                onFocus={this.handleSearchFocus}
                                onBlur={this.handleSearchBlur}
                            />
                        </form>
                    </div>
                </div>
                {fontFamilyList}
            </FontFamilyContainer>
        );
    }
}

FontFamilyPropertyWindow.propTypes = {
    closeFontFamilyWindow: PropTypes.object,
    textStatus: PropTypes.object,
    userFonts: PropTypes.object,
    isFontLoading: PropTypes.object,
    activeFontName: PropTypes.object,
    activeFontFamily: PropTypes.object,
    textOptions: PropTypes.object,
    chooseFont: PropTypes.func,
    updateTextStatus: PropTypes.func,
    setWorkspaceTextFocus: PropTypes.func,
    intl: PropTypes.object,
    windowLevel: PropTypes.number,
    clickHandlerCallback: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    textStatus: state.app.get("textStatus"),
    userFonts: state.app.get("userFonts"),
    isFontLoading: state.app.get("isFontLoading"),
    activeFontName: ownProps.activeFontName || state.app.get("activeFontName"),
    activeFontFamily: ownProps.activeFontFamily || state.app.get("activeFontFamily"),
    textOptions: state.app.get("textOptions"),
});

const mapDispatchToProps = (dispatch) => ({
    chooseFont: data => dispatch(chooseFont(data)),
    updateTextStatus: (data) => dispatch(updateTextStatus(data)),
    setWorkspaceTextFocus: (data) => dispatch(setWorkspaceTextFocus(data)),
});

const FontFamilyProperty = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FontFamilyPropertyWindow));

export default FontFamilyProperty;
