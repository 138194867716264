/* eslint-disable no-restricted-syntax */
import { randomString } from "../../helper/uuid";

/**
 * Function to return the project data by modefying the id of a workspace item.
 * @param {object} projectData
 * @returns An object of a new project.
 */
export const getNewProject = (projectData) => {
  const updatedData = JSON.parse(JSON.stringify(projectData)); // Deep copy the data to avoid mutation
  const { workspaceItems } = updatedData;

  for (const key in workspaceItems) {
    // eslint-disable-next-line no-prototype-builtins
    if (workspaceItems.hasOwnProperty(key)) {
      const newId = randomString();
      const item = workspaceItems[key];
      // Update the item's id
      item.id = newId;

      // Update the key in the workspaceItems
      delete workspaceItems[key];
      workspaceItems[newId] = item;
    }
  }

  return updatedData;
};

/**
 * Function to get the subtitle within the range.
 * @param {number} start
 * @param {number} end
 * @param {object} subtitleDatas
 * @returns Array of subtitles within the given range.
 */
export const getSubtitlesByDuration = (start, end, subtitleDatas) => {
  return Object.values(subtitleDatas)
    .filter(
      (subtitle) =>
        (subtitle.enterStart >= start && subtitle.exitEnd <= end) ||
        (subtitle.enterStart <= start && subtitle.exitEnd > start) ||
        (subtitle.enterStart < end && subtitle.enterStart > start)
    )
    .map(({ text }) => text);
};
