import styled, { css } from "styled-components";
import { font } from "../../../../constants/font";
import { STATIC_PATH } from "../../../../constants/config";
import { SettingsWindowStyled } from "../propertywindow-components";

export const BackButton = styled.div`
  position: relative;
  padding-left: 44px;
  cursor: pointer;
  font: ${font.boldBase};
  &::before {
    content: url(${STATIC_PATH}st-left-arrow.svg);
    position: absolute;
    left: 20px;
    top: -3px;
  }
`;

export const ButtonWrapper = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  padding: 0px 10px 16px 16px;
  .tile-container {
    border-radius: 6px;
  }
  ${(props) =>
    props.isAnimation &&
    css`
      .tile-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .smart-img {
          width: auto;
        }
      }
    `}
  @media only screen and (max-width: 767px) {
    padding: 0px 0px 16px 6px;
    justify-content: space-evenly;
  }
`;
export const SubtitleStyleWrapper = styled.div.attrs((props) => {
  return {
    style: {
      "--sp-veryLightGray": props.theme.veryLightGray,
      "--sp-propertyColor": props.theme.propertyColor,
      "--sp-checkBoxTickColor": props.theme.checkBoxTickColor,
      "--sp-lightSolitudeColor": props.theme.lightSolitudeColor,
      "--sp-secondaryBorderColor": props.theme.secondaryBorderColor,
      "--sp-subtitleBtnBorder": props.theme.subtitleBtnBorder,
    },
  };
})`
  padding: 0px 16px 16px;
  .font-family-wrapper {
    display: flex;
  }
  .text-color-btn {
    height: 36px;
    width: 36px;
    margin-right: 12px;
    border-radius: 6px;
    border: 1px solid var(--sp-subtitleBtnBorder);
    > div {
      height: 100%;
      width: 100%;
      outline: none;
      grid-template-rows: 20px 8px !important;
    }
    &:hover {
      border-color: var(--sp-secondaryBorderColor);
    }
  }
  .font-family-size-container {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 72px;
    align-items: center;
    gap: 16px;
    margin: 0px 12px 16px 0px;

    > .font-family-container {
      height: 36px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      border: 1px solid var(--sp-veryLightGray);
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;

      > .font-family-name {
        display: block;
        font-size: 14px;
        color: var(--sp-propertyColor);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 12px;
      }

      > .font-family-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6.65px;

        img.fontname-loader {
          width: 20px;
          height: 20px;
          display: none;
        }

        img.font-family-toggle-icon {
          width: 10.704px;
          height: 6.037px;
          display: inline-block;
        }

        &.font-loading {
          img.fontname-loader {
            display: inline-block;
          }
          img.font-family-toggle-icon {
            display: none;
          }
        }
      }
    }

    > .font-size-container {
      position: relative;
      width: 72px;
      height: 36px;

      > .font-size {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 48px 24px;
        grid-template-rows: 18px 18px;
        border-radius: 4px;
        outline: 1px solid var(--sp-veryLightGray);
        overflow: hidden;

        &:focus-within {
          outline-color: var(--sp-secondaryBorderColor);
        }

        > .numberinput--wrapper {
          grid-row: 1 / span 2;
          padding: 8px;
          font: ${font.normalBase};
          color: var(--sp-propertyColor);
          border-right: 1px solid var(--sp-veryLightGray);
        }

        > .font-size-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--sp-lightSolitudeColor);
          cursor: pointer;

          img {
            width: 10px;
            height: 5.64px;
          }
        }
      }
    }
  }
  > .spacing-settings {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 16px 0px;
    > .spacing-wrapper {
      height: 79px;
      padding: 16px 12px;
      border: 1px solid ${(props) => props.theme.primaryBorderColor};
      border-radius: 8px;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > span {
        color: ${(props) => props.theme.propertyColor};
        font: ${font.mediumMini};
      }

      > div {
        width: 100%;
        height: 16px;

        > input {
          width: 100%;
        }
      }
    }
  }
  .ffc--font-style {
    justify-content: start !important;
    img {
      width: auto !important;
    }
  }
`;

export const ColorSelector = styled.div.attrs((props) => {
  return {
    style: {
      "--cs-polarColor": props.theme.polarColor,
      "--cs-subtitleBtnBorder": props.theme.subtitleBtnBorder,
      "--cs-panelPrimaryColor": props.theme.panelPrimaryColor,
    },
  };
})`
  width: 568px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background: var(--cs-polarColor);
  border: 1px solid var(--cs-subtitleBtnBorder);
  border-radius: 6px;
  cursor: pointer;
  font: ${font.normalBase};
  color: var(--cs-panelPrimaryColor);
  margin: 16px;
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
  @media screen and (max-width: 767px) {
    width: auto;
  }
`;

export const ColorBox = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => props.bgColor || props.theme.colorBoxColor};
  border-radius: 4px;
`;

export const ColorPaletteWrapper = styled.div`
  position: absolute;
  top: ${(props) => props.top || "270px"};
  left: ${(props) => props.left || "232px"};
`;

export const Loader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  cursor: progress;
`;

export const TextButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 16px; */
`;

export const ColorPaletteContainer = styled(SettingsWindowStyled)`
  .sw--header {
    cursor: pointer;
    position: relative;
    padding-left: 45px;
    width: fit-content;
    ::before {
      content: url(${STATIC_PATH}st-left-arrow.svg);
      position: absolute;
      left: 20px;
      top: 17px;
    }
  }
`;

export const ColorPickerButton = styled.div.attrs((props) => {
  return {
    style: {
      "--sp-subtitleBtnBorder": props.theme.subtitleBtnBorder,
      "--sp-secondaryBorderColor": props.theme.secondaryBorderColor,
    },
  };
})`
  width: 36px;
  height: 36px;
  background: ${(props) => props.background};
  box-shadow: 0px 0.5px 1px #00000029;
  padding: 6px;
  outline: 1px solid var(--sp-subtitleBtnBorder);
  border: 6px solid #ffffff;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    outline-color: var(--sp-secondaryBorderColor);
  }
  ${props=> props.isDisabled && css`
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

export const SubtitleEffectsWrapper = styled.div.attrs((props) => {
  return {
    style: {
      "--sp-veryLightGray": props.theme.veryLightGray,
      "--sp-propertyColor": props.theme.propertyColor,
      "--sp-lightSolitudeColor": props.theme.lightSolitudeColor,
      "--sp-secondaryBorderColor": props.theme.secondaryBorderColor,
    },
  };
})`
  padding: 8px 16px 16px;
  height: ${(props) => props.height || "680px"};
  width: ${(props) => props.width || "100%"};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    transform: matrix(0, 1, -1, 0, 0, 0);
    background: ${(props) => props.theme.primaryBorderColor} 0% 0% no-repeat
      padding-box;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    height: calc(500px - 50px);
    padding: 0px 20px 0px 10px;
    touch-action: auto;
  }
  .font-size-container {
    position: relative;
    width: 72px;
    height: 36px;

    .font-size {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 48px 24px;
      grid-template-rows: 18px 18px;
      border-radius: 4px;
      outline: 1px solid var(--sp-veryLightGray);
      overflow: hidden;

      &:focus-within {
        outline-color: var(--sp-secondaryBorderColor);
      }

      .numberinput--wrapper {
        grid-row: 1 / span 2;
        padding: 8px;
        font: ${font.normalBase};
        color: var(--sp-propertyColor);
        border-right: 1px solid var(--sp-veryLightGray);
      }

      .font-size-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--sp-lightSolitudeColor);
        cursor: pointer;

        img {
          width: 10px;
          height: 5.64px;
        }
      }
    }
  }
  label {
    margin-bottom: 16px;
    padding: 2px 0px;
  }
  .outline-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
`;
export const EffectWrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.5px 1px #00000029;
  border: 1px solid #e3e4e8;
  border-radius: 16px;
  margin: 16px 0px;
`;
export const BackgroundWrapper = styled.div`
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
  border: 1px solid ${(props) => props.theme.subtitleBtnBorder};
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0px;
`;

export const SubWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  border-top: 1px solid ${(props) => props.theme.subtitleBtnBorder};
  .numberinput--wrapper {
    padding: 2px !important;
  }
`;
export const Column = styled.div`
  width: 50%;
`;
export const SubtitleSliderWrap = styled.div`
  padding: 16px;
  position: relative;
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${props.theme.subtitleBtnBorder}` : "none"};
  .title {
    position: relative;
    padding: ${(props) => (props.titleIcon ? "2px 24px" : "2px 0px")};
    &::before {
      content: url(${(props) => `${STATIC_PATH}${props.titleIcon}` || "unset"});
      position: absolute;
      left: 0px;
      top: -1px;
    }
  }
  .font-size-container {
    width: ${(props) => props.numberWidth || "72px"};
    .font-size {
      grid-template-columns: ${(props) =>
        props.numberWidth ? "36px 24px" : "48px 24px"};
    }
  }
`;
