/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-escape */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-const */
/* eslint-disable react/sort-comp */
/* eslint-disable one-var */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-loop-func */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fromJS } from "immutable";
import { FormattedMessage, useIntl } from "react-intl";
import ColorPalette from "../color/propertywindow-colorpalette";
import CustomTooltipComponent from "../../../../common-components/CustomTooltip";
import { fontFamilies, fontSizes } from "../../../text/text-constants";
import { getTextColors, isAllTextSelected, isTextSelected } from "../../../text/TextHelper";
import { ApplyAll } from "../propertywindow-components";
import { ASSET_URL, ITEM_CONFIG, STATIC_PATH, UPLOADS } from "../../../../constants/config";
import Slider from "../../../zoom/zoom-slider";
import { fontLoaded, setWorkspaceTextFocus, updateActiveFontFamily, updateActiveFontName, updateFontLoadStatus, updateTextApplyAllData, updateTextApplyAllOptions, updateTextStatus } from "../../../../redux/actions/appUtils";
import { updateAllTexts, updateGroupText, updateText, writeText } from "../../../../redux/actions/timelineUtils";
import content from "../../../../constants/content";
import NumberInput from "../../../../common-components/NumberInputComponent";
import { FontSizeList, FontStyleImg, TextApplyAllContainer, TextSettingsContainer } from "./textproperty-components";
import { Modal } from "../../../../common-components/modal";
import CheckBox from "../../../../common-components/CheckBox";
import FontFamilyProperty from "./propertywindow-fontfamily";
import vmTheme from "../../../../constants/theme";

const BROWSERS = {
    SAFARI: "Safari",
    FIREFOX: "Firefox",
};

const ColorPaletteWrapper = styled.div`
    > div {
        padding: 0px 0px 16px 0px;
        overflow: auto;
        @media screen and (max-width: 767px) {
            touch-action: auto;
        }
    }
`;
ColorPaletteWrapper.displayName = "ColorPaletteWrapper";

export const FontStyleIcon = (props) => {
    let {
        iconType,
        tooltipLangId,
        isActive,
        isDisabled,
        onClick,
        isLoading,
        prefix = STATIC_PATH,
        src,
        hoverSrc,
        activeSrc,
        disabledSrc,
        className,
        tooltipPosition = "bottom",
    } = props;

    const TOOLTIP_ID = `tt-txtsettings-${iconType}`;

    const style = {};

    if (isActive && activeSrc) {
        src = activeSrc;
        hoverSrc = activeSrc;
    }

    if (isDisabled && disabledSrc) {
        src = disabledSrc;
        hoverSrc = disabledSrc;
    }

    if (hoverSrc) {
        style["--font-style-img-hoversrc"] = `url('${prefix}${hoverSrc}')`;
    }

    return (
        <CustomTooltipComponent
            tooltipId={TOOLTIP_ID}
            dataTooltip={tooltipLangId}
            tooltipPosition={tooltipPosition}
        >
            <FontStyleImg
                className={className}
                onClick={onClick}
                data-tooltip-id={TOOLTIP_ID}
                style={style}
            >
                <img
                    id={`${iconType}-font-style-icon`}
                    className="font-style-icon"
                    alt="font style icon"
                    src={`${prefix}${src}`}
                />
                <img
                    id={`${iconType}-font-style-loader`}
                    className="font-style-loader"
                    alt="font style loader"
                    src={`${STATIC_PATH}chr-loading.gif`}
                    style={{ display: isLoading ? "inline-block" : "none" }}
                />
            </FontStyleImg>
        </CustomTooltipComponent>
    );
}
FontStyleIcon.propTypes = {
    iconType: PropTypes.string,
    tooltipLangId: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    src: PropTypes.string,
    hoverSrc: PropTypes.string,
    activeSrc: PropTypes.string,
    disabledSrc: PropTypes.string,
    className: PropTypes.string,
    tooltipPosition: PropTypes.string,
    prefix: PropTypes.string,
};

const TextApplyAll = (props) => {
    const {
        applyAllOpen,
        onApplyAllCancel,
        onApplyAll,
    } = props;

    const txtApplyAllOptions = useSelector(state => state.app.get("txtApplyAllOptions"));
    const theme = useSelector((state) => state.app.get('theme'));
    const dispatch = useDispatch();
    const intl = useIntl();
    const toggleFontOption = useCallback((key, isEnabled) => {
        dispatch(
            updateTextApplyAllOptions({
                options: {
                    [key]: isEnabled,
                },
            })
        );
    }, [dispatch]);

    const fontDetails = useMemo(() => {
        const fontOptionsDetails = {
            fontSize: {
                key: "isFontSizeApplyAll",
                langId: content.CURRENT_FONT_SIZE,
            },
            fontStyle: {
                key: "isFontFamilyApplyAll",
                langId: content.CURRENT_FONT_STYLE,
            },
            textFx: {
                key: "isTextEffectsApplyAll",
                langId: content.CURRENT_TEXT_FX,
            },
        };

        const _fontDetails = [];

        for (const optionKey of Reflect.ownKeys(fontOptionsDetails)) {
            const optionDetail = fontOptionsDetails[optionKey];
            const { key: stateKey, langId } = optionDetail;
            const isEnabled = txtApplyAllOptions.get(stateKey);

            let optionCls = "apply-all--option";
            if (isEnabled) {
                optionCls = `${optionCls} active`;
            }

            _fontDetails.push(
                <div
                    key={optionKey}
                    className={optionCls}
                    onClick={() => toggleFontOption(stateKey, !isEnabled)}
                >
                    <CheckBox
                        isChecked={isEnabled}
                        borderRadius="4px"
                        checkBoxWidth="18px"
                        checkBoxHeight="18px"
                        checkBoxColor={vmTheme[theme].propertyCheckBoxColor}
                        checkBoxActiveColor={vmTheme[theme].propertyCheckBoxActiveColor}
                        id={`apply-all--check-${optionKey}`}
                        label={intl.formatMessage({ id: langId })}
                    />
                </div>
            );
        }

        return _fontDetails;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [txtApplyAllOptions, intl, toggleFontOption]);


    return (
        <Modal
            isDisabled={true}
            onClose={onApplyAllCancel}
            showModal={applyAllOpen}
        >
            <TextApplyAllContainer>
                <p className="apply-all--heading">
                    <FormattedMessage id={content.TEXT_APPLYALL_H} />
                </p>
                <div className="apply-all--body">
                    {fontDetails}
                </div>
                <div className="apply-all--btn-grp">
                    <div
                        className="apply-all--btn apply-all--cancel"
                        onClick={onApplyAllCancel}
                    >
                        <FormattedMessage id={content.CANCEL} />
                    </div>
                    <div
                        className="apply-all--btn apply-all--apply"
                        onClick={onApplyAll}
                    >
                        <FormattedMessage id={content.APPLY_ALL} />
                    </div>
                </div>
            </TextApplyAllContainer>
        </Modal>
    );
}
TextApplyAll.propTypes = {
    applyAllOpen: PropTypes.bool,
    onApplyAll: PropTypes.func,
    onApplyAllCancel: PropTypes.func,
};

class TextSettingsComponent extends React.Component {
    constructor(props) {
        super(props);

        let defaultColorStates = {
            containerColor: null,
            multiColors: [],
        };
        if (props.selectedTextId && props.selectedTextData) {
            defaultColorStates = getTextColors({
                selectedItemId: props.selectedTextId,
                selectedItem: props.selectedTextData,
                getAllColors: true,
            });
        }

        this.NESTING_TEXT_ELEMENTS = ["DIV", "U", "FONT", "OL", "UL", "LI"];
        this.userFontUrl = UPLOADS.font_src;
        this.assetUrl = ASSET_URL;
        this.FONT_ATTRIBUTE = "data-ff";
        this.STYLE_ATTRIBUTE = "data-s";
        this.URL_ATTRIBUTE = "data-src";
        this.USER_FONT_ATTRIBUTE = "data-uf";
        this.RANGE_BOUNDARY = "rangeboundary";
        this.state = {
            isFontFamilyOpen: false,
            fontSizeFocused: false,
            fontSizeContainerBounds: null,
            fontSize: null,
            letterSpace: 0,
            lineHeight: null,
            minLineHeight: ITEM_CONFIG.TEXT.limit.lineHeight.min,
            isApplyAllOpen: false,
            ...defaultColorStates
        };
        this.fontFamilies = fontFamilies;
        this.savedRange = null;
        this.fontSizes = fontSizes;
        this.toggleFontFamilyWindow = this.toggleFontFamilyWindow.bind(this);
        this.toggleSizes = this.toggleSizes.bind(this);
        this.handleFontFamilyClicked = this.handleFontFamilyClicked.bind(this);
        this.changeStyle = this.changeStyle.bind(this);
        this.fontSettingsChanged = this.fontSettingsChanged.bind(this);
        this.addFont = this.addFont.bind(this);
        this.textTransforms = fromJS({ "none": "No", "uppercase": "AA", "lowercase": "aa", "capitalize": "Aa" });
        this.textAlignments = fromJS({ "left": "leftalign", "center": "centeralign", "right": "rightalign" });
        this.bulletOptions = fromJS({ "none": "", "orderedList": "insertOrderedList", "unOrderedList": "insertUnorderedList" });
        this.handleShortcuts = this.handleShortcuts.bind(this);
        this.changeFontFamily = this.changeFontFamily.bind(this);
        this.colorSelected = this.colorSelected.bind(this);
        this.handleFontsizeChange = this.handleFontsizeChange.bind(this);
        this.updateLS = this.updateLS.bind(this);
        this.lsMouseUp = this.lsMouseUp.bind(this);
        this.flattenNodes = this.flattenNodes.bind(this);
        this.normalizeDOM = this.normalizeDOM.bind(this);
        this.applyFont = this.applyFont.bind(this);
        this.preloadStyles = this.preloadStyles.bind(this);
        this.applyStyle = this.applyStyle.bind(this);
        this.getSelectedRange = this.getSelectedRange.bind(this);
        this.setFont = this.setFont.bind(this);
        this.setStyle = this.setStyle.bind(this);
        this.walkDOM = this.walkDOM.bind(this);
        this.getNode = this.getNode.bind(this);
        this.markSelection = this.markSelection.bind(this);
        this.unmarkSelection = this.unmarkSelection.bind(this);
        this.removeEmptyNodes = this.removeEmptyNodes.bind(this);
        this.saveSelection = this.saveSelection.bind(this);
        this.restoreSelection = this.restoreSelection.bind(this);
        this.isPartial = this.isPartial.bind(this);
        this.cleanText = this.cleanText.bind(this);
        this.styleDecoratedNodes = this.styleDecoratedNodes.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.getUpdatedText = this.getUpdatedText.bind(this)
        this.updateLH = this.updateLH.bind(this);
        this.lhMouseUp = this.lhMouseUp.bind(this);

        this.inputFontSizeElm = React.createRef();
        this.fontSizeContainerRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        let toReturn = null;
        let fontSize = props.textOptions.getIn(["fontSize", "value"]);
        let letterSpace = parseFloat(props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]), 10);
        if (letterSpace == null || Number.isNaN(letterSpace))
            letterSpace = null;

        let lineHeight = props.textOptions.getIn(["lineHeight", "value"]);

        if (Number(fontSize) === fontSize && fontSize % 1 !== 0)
            fontSize = parseFloat(fontSize, 10);
        if (Number(letterSpace) === letterSpace && letterSpace % 1 !== 0)
            letterSpace = parseFloat(letterSpace, 10);
        if (Number(lineHeight) === lineHeight && lineHeight % 1 !== 0)
            lineHeight = parseFloat(lineHeight, 10);

        if (letterSpace === null)
            letterSpace = 0;
        if (lineHeight === null)
            lineHeight = 1;
        if (state.prevFontSize !== fontSize || state.prevLetterSpace !== letterSpace || state.prevLineHeight !== lineHeight) {
            toReturn = {};

            if (state.prevFontSize !== fontSize) {
                toReturn.fontSize = fontSize;
                toReturn.prevFontSize = state.fontSize;
            }

            if (state.prevLetterSpace !== letterSpace) {
                toReturn.letterSpace = letterSpace;
                toReturn.prevLetterSpace = state.letterSpace;
            }
            if (state.prevLineHeight !== lineHeight) {
                toReturn.lineHeight = lineHeight;
                toReturn.prevLineHeight = state.lineHeight;
            }
        }

        return toReturn;
    }

    handleShortcuts(option) {
        let checkIndex;
        switch (option) {
            case "SET_BOLD":
            case "SET_BOLD_AGAIN":
                this.changeStyle('bold');
                break;
            case "SET_ITALIC":
            case "SET_ITALIC_AGAIN":
                this.changeStyle('italic');
                break;
            // case "SET_UNDERLINE":
            // case "SET_UNDERLINE_AGAIN":
            //     this.fontSettingsChanged("textDecoration", (this.props.textOptions.getIn(["textDecoration", "value"]) !== null) ? null : "underline");
            // break;
            case "ALIGN_RIGHT":
                this.fontSettingsChanged("textAlign", "right");
                break;
            case "ALIGN_LEFT":
                this.fontSettingsChanged("textAlign", "left");
                break;
            case "ALIGN_CENTER":
                this.fontSettingsChanged("textAlign", "center");
                break;
            case "TO_UPPERCASE":
                this.fontSettingsChanged("textTransform", "uppercase");
                break;
            case "TO_SMALLCASE":
                this.fontSettingsChanged("textTransform", "lowercase");
                break;
            case "TO_CAPITALCASE":
                this.fontSettingsChanged("textTransform", "capitalize");
                break;
            case "REMOVE_CASE":
                this.fontSettingsChanged("textTransform", "none");
                break;
            case "INCREASE_FONT_SIZE":
            case "INCREASE_FONT_SIZE_AGAIN": {
                let value = this.props.textOptions.getIn(["fontSize", "value"]), sizeArray = this.fontSizes.slice(0);
                let closestValue = sizeArray.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
                checkIndex = (val) => {
                    return val === closestValue;
                }
                let closestPosition = this.fontSizes.findIndex(checkIndex);
                if (closestPosition < this.fontSizes.length - 1)
                    this.fontSettingsChanged("fontSize", this.fontSizes[closestPosition + 1]);
                break;
            }
            case "DECREASE_FONT_SIZE":
            case "DECREASE_FONT_SIZE_AGAIN": {
                let value = this.props.textOptions.getIn(["fontSize", "value"]), sizeArray = this.fontSizes.slice(0);
                let closestValue = sizeArray.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
                checkIndex = (val) => {
                    return val === closestValue;
                }
                let closestPosition = this.fontSizes.findIndex(checkIndex);
                if (closestPosition > 0)
                    this.fontSettingsChanged("fontSize", this.fontSizes[closestPosition - 1]);
                break;
            }
            case "CHANGE_FONT_FAMILY":
            case "CHANGE_FONT_FAMILY_AGAIN":
                this.changeFontFamily();
                break;
            default:
                break;
        }
    }

    changeFontFamily(type) {
        let fontFamilies = this.fontFamilies.getIn(["default", "fonts"]);
        let randomPosition = Math.floor((Math.random() * (fontFamilies.size - 1)));

        if (randomPosition >= 0 && randomPosition < fontFamilies.size - 1) {
            if (type !== "all")
                this.handleFontFamilyClicked(null, fontFamilies.get(randomPosition));
            else {
                // return fontFamilies.get(randomPosition);
            }
        }
    }

    componentDidMount() {
        if (this.props.browserName === BROWSERS.SAFARI) {
            this.safariThrottleId = null;
            document.addEventListener('selectionchange', this.safariSaveSelection);
        }
        document.addEventListener('selectionchange', this.setSelection);
        if (!this.state.activeNode && this.props.textStatus.get('isFocused'))
            this.setSelection()

        // save letter spacing on mount
        this.setState({}, () => this.lsMouseUp());
    }

    safariSaveSelection = () => {

        this.safariThrottleId && clearTimeout(this.safariThrottleId);

        this.safariThrottleId = setTimeout(() => {
            if (window.getSelection().containsNode(this.textRoot, true) && !window.getSelection().isCollapsed) {
                this.safariSavedRange = window.getSelection().getRangeAt(0);
                this.safariHasPartialChange = false;
            }
        }, 300);
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.textOptions !== prevProps.textOptions) {
            this.setSelection()
        }

        if (this.activeFontName !== this.props.activeFontName) {
            this.props.updateActiveFontName(this.activeFontName);
        }
        if (this.activeFontFamily !== this.props.activeFontFamily) {
            this.props.updateActiveFontFamily(this.activeFontFamily);
        }
        if (this.props.selectedFontItem !== null && this.props.selectedFontItem !== prevProps.selectedFontItem)
            this.handleFontFamilyClicked(null, this.props.selectedFontItem);

        // if (this.props.copyToken !== prevProps.copyToken)
        //     this.handleTextSettingsCopy();

        // if (this.props.pasteToken !== prevProps.pasteToken)
        //     this.handleTextSettingsPaste();

        if (this.props.shortcutName !== prevProps.shortcutName && this.props.shortcutName !== "")
            this.handleShortcuts(this.props.shortcutName);

        if (this.props.browserName === BROWSERS.SAFARI && this.safariHasPartialChange && window.getSelection().isCollapsed && this.safariSavedRange) {
            this.restoreSelection(this.textRoot)
        }

        if (this.props.textStatus.get("id") !== prevProps.textStatus.get('id')) {
            window.getSelection().getRangeAt(0).collapse();
            window.getSelection().removeAllRanges();
        }

        if (this.props.textStatus.get("id") === prevProps.textStatus.get('id')) {
            let currentText, prevText;
            if (this.props.textStatus.get("container") === "workspaceItems") {
                currentText = this.props.selectedObjects.get(this.props.textStatus.get("id"))
                prevText = prevProps.selectedObjects.get(prevProps.textStatus.get("id"))
            } else {
                currentText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
                prevText = prevProps.selectedChildren.get(prevProps.textStatus.get("id"));
            }
            if (currentText.getIn(["textData", "htmlText"]) !== prevText.getIn(["textData", "htmlText"])) {
                this.setSelection();
            }
        }

        let fontSize = this.props.textOptions.getIn(["fontSize", "value"]);
        if (Number(fontSize) === fontSize && fontSize % 1 !== 0)
            fontSize = parseFloat(fontSize, 10);
        if (prevState.prevFontSize !== fontSize) {
            this.setState({
                prevFontSize: fontSize
            })
        }

        if ( // check whether we need to get updated colors and update it if needed
            this.props.selectedTextData !== prevProps.selectedTextData
            && this.props.selectedTextId
            && this.props.selectedTextData // current data available
            && (
                !prevProps.selectedTextData // previous data not available (this will not happen but just in case...)
                || prevProps.selectedTextData && (
                    // text is changed, might contain color changes
                    this.props.selectedTextData.getIn(["textData", "htmlText"]) !== prevProps.selectedTextData.getIn(["textData", "htmlText"])
                    // container color is changed
                    || this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "color"]) !== prevProps.selectedTextData.getIn(["textData", "formats", "containerStyle", "color"])
                )
            )
        ) {
            this.setState(
                getTextColors({
                    selectedItemId: this.props.selectedTextId,
                    selectedItem: this.props.selectedTextData,
                    getAllColors: true,
                })
            );
        }
    }

    componentWillUnmount() {
        if (this.props.browserName === BROWSERS.SAFARI) {
            document.removeEventListener('selectionchange', this.safariSaveSelection);
            clearTimeout(this.safariThrottleId);
        }
        document.removeEventListener('selectionchange', this.setSelection);
    }

    setSelection(shouldSave = true) {
        // if(!this.props.isFocused || this.props.isWorkspaceTextFocus) return;
        const selection = window.getSelection();
        const selectedRange = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
        if (selectedRange) {
            let { startContainer } = selectedRange
            let modalPortalEle = document.querySelector('#modal-portal');
            if (startContainer.nodeName === 'FORM') {
                return undefined;
            }
            if (modalPortalEle instanceof HTMLElement && modalPortalEle.contains(startContainer)) {
                return undefined;
            }
        }
        if (this.props.textStatus.get('isSelected') && !this.props.isWorkspaceTextFocus) {
            if (this.props.textStatus.get('isFocused') && selectedRange && !this.textRoot.contains(selectedRange.startContainer)) {
                selectedRange.selectNode(this.textRoot);
            }

            /**
             * Trim selection to contents if selection contains textRoot
             */
            if (this.textRoot.firstChild && selection.anchorNode === this.textRoot) {
                let startNode = this.textRoot.firstChild || this.textRoot;
                while (startNode.nodeType !== Node.TEXT_NODE && startNode.childNodes.length > 0) {
                    if (startNode.firstChild) {
                        startNode = startNode.firstChild;
                    }
                }
                selectedRange.setStart(startNode, 0);
            }

            if (this.textRoot.firstChild && selection.focusNode === this.textRoot) {
                let endNode = this.textRoot.lastChild;
                while (endNode.nodeType !== Node.TEXT_NODE && endNode.childNodes.length > 0) {
                    if (endNode.lastChild) {
                        endNode = endNode.lastChild;
                    }
                }
                selectedRange.setEnd(endNode, endNode.length);
            }
        }

        let activeNode;
        selectedRange && (activeNode = this.getNode(selectedRange))
        activeNode = (activeNode && activeNode.nodeType === Node.TEXT_NODE) ? activeNode.parentNode : activeNode;
        activeNode && (activeNode = activeNode.closest(`[${this.FONT_ATTRIBUTE}]`));
        shouldSave && this.setState({
            activeNode,
            selectedRange,
        })

        return activeNode;
    }

    updateFontFamily(obj) {
        this.props.updateText(obj);

        // document.getElementById("fontname-loader").style.display = "none";
        this.setState({ fontIsLoading: false });
    }


    addFont(font, updateText, link) {
        this.setState({ fontIsLoading: true });

        let fontFileName = font; // font.replace(new RegExp(" ", 'g'), "-"); font.replace(" ", "-");

        if (!document.getElementById(font)) {
            let cssLink = document.createElement("link");
            cssLink.id = font;
            cssLink.rel = "stylesheet";
            if (updateText) {
                if (cssLink.readyState) { // IE
                    cssLink.onreadystatechange = () => {
                        if (cssLink.readyState === "loaded" || link.readyState === "complete") {
                            cssLink.onreadystatechange = null;
                            if (this.props.textStatus.get("id") !== null) {
                                this.onFontLoad(font, this.updateFontFamily.bind(this), this.props.textStatus.get("id"));
                            }
                        }
                    };
                } else { // Others
                    cssLink.onload = () => {
                        this.onFontLoad(font, this.updateFontFamily.bind(this), this.props.textStatus.get("id"));
                    };
                }
            }
            if (link === undefined)
                cssLink.href = ASSET_URL + "animaker/admin-fonts/" + fontFileName + ".css";
            else
                cssLink.href = link;
            //   document.getElementById("fontname-loader").style.display = "none";
            document.getElementsByTagName("head")[0].appendChild(cssLink);
        }
        else if (updateText) {
            this.setState({ fontIsLoading: false });
        }
    }

    isFontLoaded(fontFamily, element) {
        let _aFallbackFonts = ['serif', 'sans-serif', 'monospace', 'cursive', 'fantasy'];
        let wThisFont = 0;
        let wPrevFont = 0;

        for (let ix = 0; ix < _aFallbackFonts.length; ix += 1) {
            element.style.fontFamily = '"' + fontFamily + '",' + _aFallbackFonts[ix];
            wThisFont = element.offsetWidth;
            if (ix > 0 && wThisFont !== wPrevFont) {
                return false;
            }

            wPrevFont = wThisFont;
        }

        return true;
    }

    onFontLoad(toAdd, loadStatus) {

        let fontFamily = toAdd.get("load");

        let span = null;
        // var eLang = 'en';
        let aChars = {
            'en': 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
            'ar': 'ابپتجچحخدذرزسشصضططعغفقکگلمنوهی'
        };
        let { body } = document;
        let { firstChild } = document.body;
        // var msInterval = 100, msTimeout = 3000;

        let div = document.createElement('div');
        div.id = 'fontdetectHelper';
        span = document.createElement('span');
        // span.innerText = aChars[eLang];
        span.innerText = aChars["en"] + aChars["ar"];
        div.appendChild(span);

        body.insertBefore(div, firstChild);

        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.top = '-200px';
        div.style.left = '-100000px';
        div.style.width = '100000px';
        div.style.height = '200px';
        div.style.fontSize = '100px';
        div.style.fontFamily = fontFamily;

        let hasLoaded = document.fonts.check(`12px ${fontFamily}`);
        if (hasLoaded) {
            div.remove();
            loadStatus({ success: true });
        } else {
            let maxTries = 6;
            let tries = 0;
            let interval = 500;
            let timer = setInterval(() => {
                hasLoaded = document.fonts.check(`12px ${fontFamily}`);

                if (hasLoaded) {
                    clearInterval(timer);
                    div.remove();
                    loadStatus({ success: true });
                } else if (tries >= maxTries) {
                    clearInterval(timer);
                    div.remove();
                    loadStatus({ success: false });
                }

                tries = tries + 1;
            }, interval);
        }
    }

    handleFontFamilyClicked(e, item, textSettingsPaste = false) {
        try {
            let isPartial = false;
            if (!this.isPartialText(this.textRoot)) {
                isPartial = true;
            }

            let currentText = (this.props.textStatus.get("container") === "workspaceItems") ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
            let textData = currentText.get("textData");
            // var isUserFont = textData.getIn([ "formats", "others", "isUserFont"]);
            let fontFamily = '';

            let toUpdate = {
                id: this.props.textStatus.get("id"),
                container: this.props.textStatus.get("container"),
                isGrouped: this.props.textStatus.get("isGrouped"),
                fontSizeScale: 1
            };
            if (item.get("cssUrl") !== undefined) {
                // if(isUserFont !== true )
                item = item.get("uploadsrc") === "user" ? item : item.set("regular", item.get("fontFamily"));
                toUpdate.value = item.get("fontFamily");
                fontFamily = item.get('uploadsrc') === "user" ? item.get("fontFamily") : item.get("name");
                textData = textData.setIn(["formats", "others", "isUserFont"], item.get("uploadsrc") === "user").setIn(["formats", "others", "cssUrl"], item.get('uploadsrc') === "user" ? item.get("cssUrl").slice(item.get("cssUrl").indexOf(this.userFontUrl) + this.userFontUrl.length) : fontFamily);
                if (item.get("isItalic") && item.get("isBold"))
                    textData = textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                else if (item.get("isBold"))
                    textData = textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                else if (item.get("isItalic"))
                    textData = textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                else
                    textData = textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);
            }
            else {
                fontFamily = item.get("fontFamily");
                toUpdate.value = item.get("regular");
                if (item.get("isItalic") && item.get("isBold"))
                    textData = textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                else if (item.get("isBold"))
                    textData = textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                else if (item.get("isItalic"))
                    textData = textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                else
                    textData = textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);

                textData = textData.setIn(["formats", "others", "isUserFont"], item.get("uploadsrc") === "user")
            }

            // toUpdate.textData = textData;

            let toAdd = fromJS({ load: toUpdate.value, fontFamily, byUser: (textData.getIn(["formats", "others", "isUserFont"]) && (item.get('uploadsrc') === "user")), url: `${textData.getIn(["formats", "others", "isUserFont"]) ? this.userFontUrl : ''}${textData.getIn(["formats", "others", "cssUrl"])}` });
            let fontFileName = fontFamily; // item.get("fontFamily");//font.replace(new RegExp(" ", 'g'), "-"); font.replace(" ", "-");
            let link = document.createElement("link");
            link.id = toAdd.get("load");
            link.rel = "stylesheet";
            // document.getElementById("fontname-loader").style.display = "inline-block";
            this.setState({ fontIsLoading: true });
            link.onload = () => {
                this.onFontLoad(toAdd, (data) => {
                    if (data.success) {

                        if (this.props.browserName === BROWSERS.SAFARI) {
                            window.getSelection().removeAllRanges();
                            this.safariSavedRange && window.getSelection().addRange(this.safariSavedRange);
                            this.safariHasPartialChange = true;
                        }

                        const selectedRange = this.getSelectedRange();
                        this.saveSelection(selectedRange, this.textRoot);
                        this.markSelection(selectedRange);
                        this.cleanText(this.textRoot);
                        if (!toAdd.get("byUser") && !item.get("uploadsrc")) {
                            this.preloadStyles(item);
                        }
                        toUpdate.textData = currentText.get('textData');

                        let isTextTransform = currentText.getIn(['textData', 'formats', 'containerStyle', 'textTransform']) !== 'none';

                        if (!this.props.textStatus.get('isFocused') || selectedRange.toString() === this.textRoot.innerText || (isTextTransform && selectedRange.toString().toLowerCase() === this.textRoot.innerText.toLowerCase()) || selectedRange.toString() === this.textRoot.textContent || (isTextTransform && selectedRange.toString().toLowerCase() === this.textRoot.textContent.toLowerCase())) {
                            toUpdate.fontLoaded = true;
                            toUpdate.fontFamily = toAdd.get("fontFamily");
                            toUpdate.value = toAdd.get("load");
                            toUpdate.property = "fontFamily";
                            toUpdate.textData = textData.setIn(["formats", "others", "family"], item.get("uploadsrc") === "admin" ? item.get("name") : item.get("fontFamily"));

                            if (item.get("cssUrl") !== undefined) {
                                // if(isUserFont !== true )
                                item = item.get("uploadsrc") === "user" ? item : item.set("regular", item.get("fontFamily"));
                                toUpdate.value = item.get("fontFamily");
                                fontFamily = item.get('uploadsrc') === "user" ? item.get("fontFamily") : item.get("name");
                                toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isUserFont"], item.get("uploadsrc") === "user").setIn(["formats", "others", "cssUrl"], item.get('uploadsrc') === "user" ? item.get("cssUrl").slice(item.get("cssUrl").indexOf(this.userFontUrl) + this.userFontUrl.length) : fontFamily);
                                if (item.get("isItalic") && item.get("isBold"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                                else if (item.get("isBold"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                                else if (item.get("isItalic"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                                else
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);
                            } else {
                                fontFamily = item.get("fontFamily");
                                toUpdate.value = item.get("regular");
                                if (item.get("isItalic") && item.get("isBold"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                                else if (item.get("isBold"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                                else if (item.get("isItalic"))
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                                else
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);

                                toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isUserFont"], item.get("uploadsrc") === "user")
                            }
                        } else {
                            toUpdate.value = textData.getIn(["formats", "containerStyle", "fontFamily"]);
                            if (!item.get("cssUrl")) {
                                toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isUserFont"], item.get("uploadsrc") === "user")
                            }
                        }
                        let lineHeight = 0;
                        if (!isTextSelected() || isAllTextSelected(this.textRoot)) {
                            lineHeight = item.get("lineHeight");
                        } else {
                            this.walkDOM(this.textRoot, node => {
                                const currentLineHeight = parseFloat(node.parentNode.style.lineHeight) || lineHeight;
                                lineHeight = currentLineHeight > lineHeight ? currentLineHeight : lineHeight;
                            })
                        }

                        this.walkDOM(this.selectionRoot, (node) => {
                            if (lineHeight < item.get('lineHeight') || !isTextSelected() || isAllTextSelected(this.textRoot)) {
                                lineHeight = item.get('lineHeight')
                            }
                            const styledElement = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}="${item.get("fontFamily")}"]`);

                            if (!(styledElement && this.textRoot.contains(styledElement))) {

                                this.applyFont(node, item, toAdd.get("byUser"));
                            }
                        })
                        this.textRoot.style.lineHeight = lineHeight;
                        if (isPartial === false) {
                            this.textRoot.style.fontFamily = item.get("regular") + ",gfs";
                        }
                        this.textRoot.querySelectorAll(`[${this.FONT_ATTRIBUTE}]`).forEach(node => {
                            node.style.lineHeight = lineHeight;
                        })

                        this.unmarkSelection(selectedRange);


                        /**
                         * Optimization
                         * 1. remove nesting of nodes and flatten sub-tree.
                         * 2. merge similar sibling nodes.
                         */
                        this.flattenNodes(this.textRoot);
                        this.normalizeDOM(this.textRoot);
                        this.removeEmptyNodes(this.textRoot);

                        // document.getElementById("fontname-loader").style.display = "none";
                        toUpdate.fontLoaded = true;
                        toUpdate.htmlText = this.textRoot.innerHTML;

                        toUpdate.htmlText = this.textRoot.innerHTML;
                        lineHeight = parseFloat(lineHeight > 0 ? lineHeight : this.textRoot.style.lineHeight);
                        toUpdate.lineHeight = lineHeight;

                        if (textSettingsPaste) {
                            // this.props.pushPasteData({ ...toUpdate, id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container"), property: 'fontFamily' });
                        } else if (this.props.textStatus.get("container") === "workspaceItems" || (this.props.textStatus.get("type") === "SHAPE" && !this.props.textStatus.get("isGrouped")) || this.props.textStatus.get("textGroup") === true) {
                            let { width, height, x, textData } = this.getUpdatedText()
                            if (width !== undefined && height !== undefined && x !== undefined && textData !== undefined) {
                                let isFontSizeChanged = textData.get("isfontSizeChanged")
                                if (isFontSizeChanged) {
                                    toUpdate.textData = toUpdate.textData.setIn(["formats", "containerStyle", "fontSize"], textData.getIn(["formats", "containerStyle", "fontSize"]))
                                    toUpdate.textData = toUpdate.textData.set("isfontSizeChanged", isFontSizeChanged)
                                }
                                toUpdate = { ...toUpdate, width, height, x }
                            }
                            this.props.updateText({ ...toUpdate, id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container"), property: 'fontFamily' });
                        } else {
                            let toUpdateGroup = this.updateGroupedText()
                            if (toUpdateGroup && toUpdateGroup.groupText) {
                                let formats = fromJS(toUpdateGroup.textData.formats)
                                formats = formats.setIn(["containerStyle", 'fontWeight'], null);
                                formats = formats.setIn(["containerStyle", 'fontStyle'], null);
                                formats = formats.setIn(["containerStyle", 'fontFamily'], toUpdate.value);
                                formats = formats.setIn(["containerStyle", 'lineHeight'], lineHeight);

                                if (toUpdate.textData !== undefined) {
                                    formats = formats.set("others", toUpdate.textData.getIn(["formats", "others"]));
                                }
                                if (toUpdate.htmlText !== undefined)
                                    toUpdateGroup.textData.htmlText = toUpdate.htmlText
                                toUpdateGroup.textData.formats = formats.toJS()

                                this.props.updateGroupText(toUpdateGroup);
                            } else if (toUpdateGroup) {
                                if (
                                    toUpdateGroup.width !== undefined
                                    && toUpdateGroup.height !== undefined
                                    && toUpdateGroup.x !== undefined
                                    && toUpdateGroup.textData !== undefined
                                ) {
                                    toUpdate.width = toUpdateGroup.width;
                                    toUpdate.height = toUpdateGroup.height;
                                    toUpdate.x = toUpdateGroup.x;
                                    if (toUpdateGroup.yRatio) {
                                        toUpdate.yRatio = toUpdateGroup.yRatio
                                    }
                                    let isFontSizeChanged = toUpdateGroup.textData.isfontSizeChanged
                                    if (isFontSizeChanged) {
                                        toUpdate.textData = toUpdate.textData.setIn(["formats", "containerStyle", "fontSize"], toUpdateGroup.textData.formats.containerStyle.fontSize)
                                        toUpdate.textData = toUpdate.textData.set("isfontSizeChanged", isFontSizeChanged)
                                    }
                                }
                                this.props.updateText({ ...toUpdate, id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container"), property: 'fontFamily' });
                            }
                        }
                        this.restoreSelection(this.textRoot);
                        this.setSelection();
                    } else {
                        // document.getElementById("fontname-loader").style.display = "none";
                    }
                    this.setState({ fontIsLoading: false });
                    this.props.updateFontLoadStatus(false);
                }, this.props.textStatus.get("id"));

            };

            if (toAdd.get("byUser") !== true)
                link.href = this.assetUrl + "animaker/admin-fonts/" + fontFileName + ".css";
            else
                link.href = toAdd.get("url");
            document.getElementsByTagName("head")[0].appendChild(link);

        } catch (error) { }
    }

    /**
     * @summary Preload the given fonts and it's style variants in the network
     * @param {Object} fontData Font data fetched from server or retrieved from fontFamilies settings
     */
    preloadStyles(fontData) {
        const dummyNode = document.createElement("div").appendChild(document.createTextNode("...")).parentNode;
        dummyNode.style.position = "fixed";
        dummyNode.style.opacity = 0;
        document.body.insertBefore(dummyNode, document.body.firstChild);
        this.applyFont(dummyNode.firstChild, fontData);
        const STYLES = ["bold", "italic", "boldItalic"];

        for (const currentStyle of STYLES) {
            if (!fontData.get(currentStyle)) {
                continue;
            }
            this.setStyle(dummyNode.firstChild, fontData.get("fontFamily"), currentStyle);
            let hasLoaded = fontData.get(currentStyle) === undefined;
            let fontLoadThrottleStart = Date.now();

            while (!hasLoaded && (Date.now() - fontLoadThrottleStart) <= 3000) {
                hasLoaded = this.isFontLoaded(fontData.get(currentStyle), dummyNode);
            }
        }

        document.body.removeChild(dummyNode);
    }

    isPartial(commonAncestorNode) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStartBefore(commonAncestorNode.firstChild);
        range.setEndAfter(commonAncestorNode.lastChild);

        return !(selection.getRangeAt(0).toString() === range.toString());
    }

    /**
     * @summary Apply the font for the given node
     * @param {Node} node The node on which the font is to be applied
     * @param {Map} fontData Font Object for computing font application
     * @param {Boolean} isUserFont To denote if the given font in fontData is user uploaded or default
     */
    applyFont(node, fontData, isUserFont = false, customCurrTextRoot = null) {
        const localStyledElement = node.parentNode.closest(`span[${this.FONT_ATTRIBUTE}]`);
        const hasLocalStyledElement =
            localStyledElement
            && (customCurrTextRoot !== null ? customCurrTextRoot.contains(localStyledElement) : this.selectionRoot.contains(localStyledElement));
        const fontFamily = isUserFont ? fontData.get("fontFamily") : fontData.get("regular");
        const fontName = fontData.get("uploadsrc") === "admin" ? fontData.get("name") : fontData.get("fontname") || (fontData.get('fontFamily').includes(fontData.get('id')) ? fontData.get('fontFamily').split('-').slice(0, -1).join('-') : fontData.get('fontFamily'));
        const url = isUserFont ? fontData.get("cssUrl").slice(fontData.get("cssUrl").indexOf(this.userFontUrl) + this.userFontUrl.length) : null;

        if (!hasLocalStyledElement) {

            const fontElement = document.createElement('span');
            node.parentNode.insertBefore(fontElement, node);
            fontElement.appendChild(node);

            this.setFont(fontElement, {
                fontName,
                style: "regular",
                fontFamily,
                lineHeight: fontData.get("lineHeight"),
                url,
                isUserFont
            });
        } else if (hasLocalStyledElement && localStyledElement.getAttribute(this.FONT_ATTRIBUTE) !== fontData.get("fontFamily")) {
            this.setFont(localStyledElement, {
                fontName,
                style: "regular",
                fontFamily,
                lineHeight: fontData.get("lineHeight"),
                url,
                isUserFont
            });
        }
    }

    /**
     * @summary create a container and apply the given style to it
     * @param {Node} node The node on which the font is set
     * @param {String} param1.fontName Name of font-family
     * @param {String} param1.style Style of the text node to be applied
     * @param {String} param1.fontFamily Name of the style to be applied
     * @param {Number} param1.lineHeight Line height for the given font style
     * @param {String} param1.url Src URL for the font
     * @param {Boolean} param1.isUserFont A flag to denote if the font is default or not
     */
    setFont(node, { fontName, style, fontFamily, lineHeight, url, isUserFont }) {
        node.setAttribute(this.FONT_ATTRIBUTE, fontName);
        node.setAttribute(this.STYLE_ATTRIBUTE, style);
        node.setAttribute(this.USER_FONT_ATTRIBUTE, isUserFont);

        if (url) {
            node.setAttribute(this.URL_ATTRIBUTE, url);
        }

        node.style.fontFamily = `${fontFamily.includes("gfs") ? fontFamily : `"${fontFamily}"`}${!fontFamily.includes("gfs") ? ",gfs" : ''}`;
        node.style.lineHeight = lineHeight;
    }

    checkFontFamilyLoadedPromise = (fontName, styleValue, boldActive, italicActive) => {
        let fontFamData = fontFamilies.getIn(["default", "fonts"]).find(x => x.get("fontFamily") === fontName)
        let tempText = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ, ابپتجچحخدذرزسشصضططعغفقکگلمنوهی`
        return new Promise((resolve, reject) => {
            if (styleValue === 'bold' && fontFamData) {
                let fontFamilyName = italicActive ? fontFamData.get('boldItalic') : fontFamData.get('bold');
                if (document.fonts.check(`12px ${fontFamilyName}`, tempText)) {
                    resolve({ isLoaded: true })
                } else {
                    document.fonts.load(`12px ${fontFamilyName}`, tempText)
                        .then(() => {
                            resolve({ isLoaded: true })
                        })
                        .catch(() => reject({ isLoaded: false, message: "Font loading failed" }))
                }
            } else if (styleValue === 'italic' && fontFamData) {
                let fontFamilyName = boldActive ? fontFamData.get('boldItalic') : fontFamData.get('italic');
                if (document.fonts.check(`12px ${fontFamilyName}`, tempText)) {
                    resolve({ isLoaded: true })
                } else {
                    document.fonts.load(`12px ${fontFamilyName}`, tempText)
                        .then(() => resolve({ isLoaded: true }))
                        .catch(() => reject({ isLoaded: false, message: "Font loading failed" }))
                }
            } else if (fontFamData) {
                let fontFamilyName = null;
                if (italicActive) {
                    fontFamilyName = fontFamData.get('italic');
                } else if (boldActive) {
                    fontFamilyName = fontFamData.get('bold');
                }
                if (fontFamilyName) {
                    if (document.fonts.check(`12px ${fontFamilyName}`, tempText)) {
                        resolve({ isLoaded: true })
                    } else {
                        document.fonts.load(`12px ${fontFamilyName}`, tempText)
                            .then(() => resolve({ isLoaded: true }))
                            .catch(() => reject({ isLoaded: false, message: "Font loading failed" }))
                    }
                } else {
                    resolve({ isLoaded: true })
                }
            } else {
                reject({ isLoaded: false, message: "Font loading failed" })
            }
        })
    }

    changeStyle(style, textSettingsPaste = false) {
        try {
            let isPartial = false;

            if (!this.isPartialText(document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0])) {
                isPartial = true;
            }

            if (this.props.browserName === BROWSERS.SAFARI) {
                window.getSelection().removeAllRanges();
                this.safariSavedRange && window.getSelection().addRange(this.safariSavedRange);
                this.safariHasPartialChange = true;
            }

            const selectedRange = this.getSelectedRange();
            this.saveSelection(selectedRange, this.textRoot);
            this.markSelection(selectedRange);
            this.cleanText(this.textRoot);

            let shouldRemove = true;

            this.walkDOM(this.selectionRoot, node => {

                if (node.textContent.length === 0) {
                    return;
                }

                const styledAncestor = node.parentNode.closest(`[${this.STYLE_ATTRIBUTE}*="${style}"]`) || node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                let hasStyle = false;

                if (
                    styledAncestor
                    && styledAncestor.getAttribute(this.STYLE_ATTRIBUTE)
                    && styledAncestor.getAttribute(this.STYLE_ATTRIBUTE).toLowerCase().indexOf(style) >= 0
                ) {
                    hasStyle = true;
                } else if (styledAncestor && (!styledAncestor.getAttribute(this.STYLE_ATTRIBUTE) || styledAncestor.getAttribute(this.STYLE_ATTRIBUTE) === "regular") && styledAncestor.getAttribute(this.FONT_ATTRIBUTE)) {
                    if (styledAncestor.getAttribute(this.USER_FONT_ATTRIBUTE) === "true") {
                        hasStyle = true;
                    } else {
                        const currentFont = this.fontFamilies.getIn(["default", "fonts"]).find(fontItem => fontItem.get("fontFamily") === styledAncestor.getAttribute(this.FONT_ATTRIBUTE));
                        hasStyle = style === "bold" ? !currentFont.get("isBold") : !currentFont.get("isItalic");
                    }
                } else if (!styledAncestor || !styledAncestor.getAttribute(this.FONT_ATTRIBUTE)) {
                    const selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id")) || this.props.selectedObjects.get(this.props.textStatus.get("id"));
                    hasStyle = selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]).toLowerCase().indexOf(style) >= 0;
                }

                shouldRemove = shouldRemove && hasStyle;
            })

            this.walkDOM(this.selectionRoot, node => {
                const styledAncestor = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                const selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id")) || this.props.selectedObjects.get(this.props.textStatus.get("id"));
                const hasUserFont = styledAncestor ? styledAncestor.getAttribute(this.USER_FONT_ATTRIBUTE) === "true" : selectedText.getIn(["textData", "formats", "others", "isUserFont"]);

                if (!hasUserFont) {
                    this.applyStyle(node, style, shouldRemove);
                }
            })

            this.unmarkSelection(selectedRange);
            this.flattenNodes(this.textRoot);
            this.normalizeDOM(this.textRoot);
            this.removeEmptyNodes(this.textRoot);
            this.restoreSelection(this.textRoot);
            if (textSettingsPaste) {
                // this.props.pushPasteData({ htmlText: this.textRoot.innerHTML, id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container") });
            } else if (this.props.textStatus.get("container") === "workspaceItems" || (this.props.textStatus.get("type") === "SHAPE" && !this.props.textStatus.get("isGrouped")) || this.props.textStatus.get("textGroup") === true) {
                let { width, height, x, textData } = this.getUpdatedText()
                this.props.updateText({ isPartial, htmlText: this.textRoot.innerHTML, id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container"), width, height, x, textData });
            } else {
                let toUpdate = this.updateGroupedText()
                if (toUpdate && toUpdate.groupText) {
                    this.props.updateGroupText(toUpdate);
                } else {
                    this.props.updateText({
                        isPartial, htmlText: this.textRoot.innerHTML,
                        id: this.props.textStatus.get('id'), container: this.props.textStatus.get("container"), toUpdate
                    });
                }
            }
        } catch (error) { }
    }

    cleanText(rootNode) {

        rootNode.normalize();
        this.styleDecoratedNodes(rootNode);
    }

    styleDecoratedNodes(rootNode) {
        if (rootNode.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        ["U", "FONT"].forEach(elementName => {
            rootNode.querySelectorAll(`[${this.FONT_ATTRIBUTE}]>${elementName}`).forEach(node => {
                node.childNodes.forEach(currentNode => {
                    const styledNode = document.createElement('span');
                    this.setFont(styledNode, {
                        fontName: node.parentNode.getAttribute(this.FONT_ATTRIBUTE),
                        style: node.parentNode.getAttribute(this.STYLE_ATTRIBUTE),
                        fontFamily: node.parentNode.style.fontFamily,
                        lineHeight: node.parentNode.style.lineHeight,
                        url: node.parentNode.getAttribute(this.URL_ATTRIBUTE),
                        isUserFont: node.parentNode.getAttribute(this.USER_FONT_ATTRIBUTE)
                    })

                    node.insertBefore(styledNode, currentNode);
                    styledNode.appendChild(currentNode);
                })

            })
        })
    }

    /**
     * @summary get the current range in the current text
     */
    getSelectedRange() {
        const selection = window.getSelection();
        /**
         * If The selection is empty or there's no selection
         */
        if (selection.rangeCount === 0 || selection.isCollapsed) {
            selection.selectAllChildren(this.textRoot);
        }

        let range = selection.getRangeAt(0);
        /**
         * if selected node not in textRoot
         */
        if (!this.textRoot.contains(range.commonAncestorContainer)) {

            range.setStartBefore(this.textRoot.firstChild);
            range.setEndAfter(this.textRoot.lastChild);
        }

        return range;
    }

    /**
     * @summary Saves the offset of the given selection
     * @param {Range} range Seletected range of the active selection
     * @param {Node} container Node that contains the current selection
     */
    saveSelection(range, container) {
        const preSelectionRange = range.cloneRange();
        preSelectionRange.selectNodeContents(container);
        preSelectionRange.setEnd(range.startContainer, range.startOffset);
        const start = preSelectionRange.toString().length;

        this.savedRange = {
            start,
            end: start + range.toString().length
        };
    }

    /**
     * @summary clears all range in the current selection and restores the saved range
     * @param {Node} container Node in which the saved range is to be restored
     */
    restoreSelection(container) {
        if (!this.savedRange) {
            return;
        }

        let charIndex = 0;
        const range = document.createRange()

        range.setStart(container, 0);
        range.collapse(true);
        let foundStart = false,
            stop = false;

        this.walkDOM(this.textRoot, node => {
            if (stop) {
                return;
            }

            const nextCharIndex = charIndex + node.length;
            if (!foundStart && this.savedRange.start >= charIndex && this.savedRange.start <= nextCharIndex) {
                range.setStart(node, this.savedRange.start - charIndex);
                foundStart = true;
            }

            if (foundStart && this.savedRange.end >= charIndex && this.savedRange.end <= nextCharIndex) {
                range.setEnd(node, this.savedRange.end - charIndex);
                stop = true;
            }
            charIndex = nextCharIndex;
        });

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * @summary Isolates the given range in a `rangeboundary` element
     * @param {Range} range Active range in the current selection
     */
    markSelection(range) {
        const rangeElement = document.createElement(this.RANGE_BOUNDARY);
        rangeElement.appendChild(range.extractContents());
        range.insertNode(rangeElement);

        return this.selectionRoot;
    }

    /**
     * @summary Removes the isolation of `rangeboundary` element
     * @param {Range} range Active range of the current selection
     */
    unmarkSelection(range) {

        while (this.selectionRoot.childNodes.length) {
            range.insertNode(this.selectionRoot.lastChild);
        }
        this.selectionRoot.parentNode.removeChild(this.selectionRoot);
        range.collapse();
    }

    /**
     * @summary Wrap the node with style or remove the style surrounding the node
     * @param {Node} node The target node for which style is applied or removed
     * @param {"bold" | "italic"} style The style that is to be applied or removed
     * @param {Map} fontData Font data for the various styles of the current font family
     * @param {Boolean} shouldRemove When true the given style is removed from the node, else style is applied
     */
    applyStyle(node, style, shouldRemove = false) {
        const styledAncestor = node.parentNode.closest(`[${this.STYLE_ATTRIBUTE}]`) || node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
        const selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id")) || this.props.selectedObjects.get(this.props.textStatus.get("id"));
        const currentFont = (styledAncestor && styledAncestor.getAttribute(this.FONT_ATTRIBUTE)) ? styledAncestor.getAttribute(this.FONT_ATTRIBUTE) : selectedText.getIn(["textData", "formats", "others", "family"]) ? selectedText.getIn(["textData", "formats", "others", "family"]) : selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]);
        const currentStyle = styledAncestor ? styledAncestor.getAttribute(this.STYLE_ATTRIBUTE) : "regular";
        const alternateStyle = style === "bold" ? "italic" : "bold";

        if (shouldRemove) {

            if (currentStyle === style) {

                this.setStyle(node, currentFont);
            } else if (currentStyle.toLowerCase().indexOf(style) >= 0) {

                this.setStyle(node, currentFont, alternateStyle);
            }
        } else if (currentStyle === "regular") {

            this.setStyle(node, currentFont, style);
        } else if (currentStyle === alternateStyle) {

            this.setStyle(node, currentFont, "boldItalic");
        }
    }

    /**
     * 
     * @param {Node} node Node for which the style is to be applied
     * @param {String} fontFamily current font family of the give node
     * @param {String} style Style variant to be applied
     */
    setStyle(node, fontFamily, style = "regular") {
        const fontData = this.fontFamilies.getIn(["default", "fonts"]).find(fontItem => fontItem.get("fontFamily") === fontFamily);

        /**
         * Ignore if given font does not have given style variant
         */
        if (!fontData.get(style)) {
            return;
        }

        if (!node.parentNode.getAttribute(this.FONT_ATTRIBUTE) || (node.nodeType === Node.TEXT_NODE && node.textContent !== node.wholeText)) {
            const fontElement = document.createElement('span');
            node.parentNode.insertBefore(fontElement, node);
            fontElement.appendChild(node);

            this.setFont(fontElement, {
                fontName: fontFamily,
                style,
                fontFamily: fontData.get(style),
                lineHeight: fontData.get("lineHeight"),
                isUserFont: false
            })
        } else {
            node.parentNode.style.fontFamily = fontData.get(style);
            node.parentNode.setAttribute(this.STYLE_ATTRIBUTE, style);
        }

    }

    get textRoot() {
        return document.querySelector(`#${this.props.textStatus.get("id")} .text-container`);
    }

    get selectionRoot() {
        return this.textRoot.querySelector(this.RANGE_BOUNDARY);
    }

    /**
     * @summary Traverses through the subtree of given rootNode and applies nodeAction on every leaf node
     * @param {Node} rootNode The node whose subtree is to be traversed
     * @param {Function} nodeAction Function that is passed each leaf node
     */
    walkDOM(rootNode, nodeAction) {
        rootNode.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
                nodeAction(node);
            } else {
                this.walkDOM(node, nodeAction);
            }
        })
    }

    /**
     * @description Walks the tree under the rootNode in a LRR fashion and moves all inner nodes to top level, essentially flattening the tree.
     * @summary Walks through the child nodes of the root recursively and moves all inner level nodes to top level.
     * @param {Node} rootNode Node whose childNodes are traversed and flattened
     */
    flattenNodes(rootNode) {
        if (rootNode.nodeType === Node.ELEMENT_NODE) {

            for (let i = 0; i < rootNode.childNodes.length; i += 1) {

                let node = rootNode.childNodes.item(i);
                const styledAncestor = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                if (node.nodeType === Node.TEXT_NODE && node.textContent !== '' && styledAncestor && node.textContent !== node.parentNode.innerText) {
                    /**
                     * Node is,
                     * - Text node
                     * - Not empty node
                     * - Not inside a nesting node
                     * - Node is only a part of parent
                     */

                    const fontElement = document.createElement('span');
                    node.parentNode.insertBefore(fontElement, node);

                    this.setFont(fontElement, {
                        fontName: styledAncestor.getAttribute(this.FONT_ATTRIBUTE),
                        style: styledAncestor.getAttribute(this.STYLE_ATTRIBUTE),
                        fontFamily: styledAncestor.style.fontFamily,
                        lineHeight: styledAncestor.style.lineHeight,
                        url: styledAncestor.getAttribute(this.URL_ATTRIBUTE),
                        isUserFont: styledAncestor.getAttribute(this.USER_FONT_ATTRIBUTE)
                    });
                    fontElement.appendChild(node);
                    rootNode.parentNode.insertBefore(fontElement, rootNode);
                    i -= 1;
                } else if (node.nodeType === Node.ELEMENT_NODE) {

                    if (!this.NESTING_TEXT_ELEMENTS.includes(rootNode.tagName)) {
                        let firstSibling = node;
                        let currentSibling = rootNode.lastChild;

                        while (currentSibling) {
                            let c = currentSibling;
                            currentSibling = currentSibling.previousSibling;
                            if (c === firstSibling) {
                                currentSibling = null;
                            }

                            if (c.nodeType === Node.TEXT_NODE && c.textContent === "") { // no need to process empty text
                                continue;
                            }

                            if (c.nodeType === Node.TEXT_NODE) {
                                // apply root node styling - AN-6825
                                let rootClone = rootNode.cloneNode(false);
                                rootClone.appendChild(c);
                                c = rootClone;
                            } else if (rootNode.tagName !== c.tagName && this.NESTING_TEXT_ELEMENTS.includes(c.tagName)) {
                                // apply root node styling - AN-6825, AN-9744
                                if (c.tagName === "UL" || c.tagName === "OL") {
                                    for (const li of c.childNodes) {
                                        let clone = rootNode.cloneNode(false);
                                        for (const child of li.childNodes) {
                                            clone.appendChild(child);
                                        }
                                        li.appendChild(clone);
                                    }
                                } else {
                                    let clone = rootNode.cloneNode(false);
                                    for (const child of c.childNodes) {
                                        clone.appendChild(child);
                                    }
                                    c.appendChild(clone);
                                }
                            }

                            rootNode.parentNode.insertBefore(c, rootNode.nextSibling);
                        }

                        i -= 1;
                    }
                    this.flattenNodes(node);
                }
            }
        }
    }

    /**
     * @summary Merges adjacent nodes that are similar in style.
     * @param {Node} rootNode Node whose subtree are tested for merging.
     */
    normalizeDOM(rootNode) {
        rootNode.normalize();
        let referenceNode = null;
        for (let i = 0; i < rootNode.childNodes.length; i += 1) {

            let scanningNode = rootNode.childNodes.item(i) || referenceNode;

            if (scanningNode.nodeType === Node.TEXT_NODE) {
                referenceNode = null;
                continue;
            } else if (scanningNode.innerText === '' && scanningNode.tagName !== "BR") {

                rootNode.removeChild(scanningNode);
                i -= 1;
            } else if (scanningNode.nodeType === Node.ELEMENT_NODE && this.NESTING_TEXT_ELEMENTS.includes(scanningNode.tagName)) {
                referenceNode = null;
                this.normalizeDOM(scanningNode);
            } else if (
                referenceNode &&
                scanningNode && // Both nodes are not null/empty
                referenceNode !== scanningNode && // Both Nodes are pointing to the same DOM node
                referenceNode.nodeType === Node.ELEMENT_NODE &&
                scanningNode.nodeType === Node.ELEMENT_NODE && // Both Nodes are of type ELEMENT
                referenceNode.getAttribute(this.FONT_ATTRIBUTE) === scanningNode.getAttribute(this.FONT_ATTRIBUTE) && // Both Nodes have same fontName
                referenceNode.getAttribute(this.STYLE_ATTRIBUTE) === scanningNode.getAttribute(this.STYLE_ATTRIBUTE) // Both Nodes have same styles applied
            ) {
                scanningNode.childNodes.forEach(node => {
                    if (node.innerText !== '') {
                        referenceNode.appendChild(node);
                    }
                })
                rootNode.removeChild(scanningNode);
                i -= 1;
            } else {
                referenceNode = scanningNode;
            }
        }
        rootNode.normalize();
    }

    /**
     * @summary Modifies the tree under the given root node
     * @param {Node} rootNode Node whose subtree is considered for cleaning
     */
    removeEmptyNodes(rootNode) {
        if (rootNode.nodeType !== Node.ELEMENT_NODE) {
            return;
        }

        for (let i = 0; i < rootNode.children.length; i += 1) {
            const currentNode = rootNode.children[i];
            if (currentNode.innerText === "" && currentNode.tagName !== "BR") {
                rootNode.removeChild(currentNode);
                i -= 1;
            } else if (currentNode.parentNode === this.textRoot && currentNode.tagName === "BR") {
                rootNode.removeChild(currentNode);
                i -= 1;
            } else {
                this.removeEmptyNodes(currentNode);
            }
        }
    }

    /**
     * @summary Returns the node that is pointed to by the parameters
     * @param {Node} container Container node which holds the target node
     * @param {Number} offset Offset of the target node
     */
    getNode(range) {
        if (range.collapsed) {
            let { startContainer } = range;
            if (startContainer.nodeType !== Node.ELEMENT_NODE) {
                startContainer = startContainer.parentElement;
            }
            return startContainer.getAttribute(this.FONT_ATTRIBUTE) ? startContainer : startContainer.querySelector(`[${this.FONT_ATTRIBUTE}]`);
        }
        const fragment = document.createElement('div');
        let selectedContents = range.cloneContents();
        if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
            const extractNode = range.commonAncestorContainer.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
            extractNode && (selectedContents = extractNode.cloneNode(true));
        }
        fragment.appendChild(selectedContents);
        for (let i = 0; i < fragment.childNodes.length; i += 1) {
            const nodeText = (fragment.childNodes[i].innerText || fragment.childNodes[i].textContent).replace(/\s+/, "");
            if (nodeText.length > 0) {
                if (fragment.childNodes[i].nodeType !== Node.ELEMENT_NODE) {
                    return fragment.childNodes[i].parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                }
                return fragment.childNodes[i].getAttribute(this.FONT_ATTRIBUTE) ? fragment.childNodes[i] : fragment.childNodes[i].querySelector(`[${this.FONT_ATTRIBUTE}]`);
            }
        }
        return undefined;
    }

    /* eslint-disable consistent-return, no-labels, no-unused-labels */
    getFontSize(text) {
        let container = document.getElementById(text.id);
        let ourText = container.getElementsByClassName("text-container")[0];
        if (ourText.style.fontSize === "")
            ourText.style.fontSize = "16px";
        let fontSize = parseFloat(ourText.style.fontSize, 10).toFixed(2);
        let maxHeight = text.height * this.props.zoomFactor;
        let textHeight = ourText.clientHeight, i, j;
        if (textHeight > maxHeight) {
            reached:
            for (i = 0; i < 500; i += 1) {
                if (textHeight < maxHeight) {
                    fontSize = fontSize + 1
                    for (j = 0; j < 10; j += 1) {
                        let decFontSize = fontSize - ((j + 1) / 10);
                        ourText.style.fontSize = decFontSize + "px";
                        textHeight = ourText.clientHeight;
                        if (textHeight <= maxHeight) {
                            ourText.style.fontSize = decFontSize + "px";
                            return decFontSize + "px";
                        }
                    }
                    ourText.style.fontSize = fontSize + "px";
                    return fontSize + "px";
                }
                fontSize -= 1;
                ourText.style.fontSize = fontSize + "px";
                textHeight = ourText.clientHeight;
            }
        } else if (textHeight < maxHeight) {
            reached:
            for (i = 0; i < 500; i += 1) {
                if (textHeight > maxHeight) {
                    for (j = 0; j < 10; j += 1) {
                        let decFontSize = fontSize - ((j + 1) / 10);
                        ourText.style.fontSize = decFontSize + "px";
                        textHeight = ourText.clientHeight;
                        if (textHeight <= maxHeight) {
                            ourText.style.fontSize = decFontSize + "px";
                            return decFontSize + "px";
                        }
                    }
                    fontSize = fontSize - 1;
                    ourText.style.fontSize = fontSize + "px";
                    return fontSize + "px";
                }
                fontSize += 1;
                ourText.style.fontSize = fontSize + "px";
                textHeight = ourText.clientHeight;
            }
        }
        else {
            return ourText.style.fontSize + "px";
        }
    }
    /* eslint-enable consistent-return, no-labels, no-unused-labels */

    handleFontSizeClicked(e, size, textSettingsPaste = false) {
        try {
            this.removeStyle("fontSize", this.props.textStatus.get("id"));
            let width, height, yRatio, hRatio;
            let fontSize = size;
            let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
            document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].style.fontSize = (fontSize * this.props.zoomFactor).toFixed(2) + "px";
            width = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetWidth / this.props.zoomFactor;
            height = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;
            if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined) {
                width += this.props.textOffset;
                height += this.props.textOffset;
            }

            if (this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                let widthRatio = textItem.get("maxWidthRatio") !== undefined && textItem.get("maxWidthRatio") !== null ? Math.round(textItem.get("maxWidthRatio")) : 90;
                let heightRatio = textItem.get("maxHeightRatio") !== undefined && textItem.get("maxHeightRatio") !== null ? Math.round(textItem.get("maxHeightRatio")) : 90;

                let textWidth = (widthRatio / 100) * textItem.get("width");
                let textHeight = (heightRatio / 100) * textItem.get("height");

                if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined && height - this.props.textOffset > textHeight) {
                    fontSize = (parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: textWidth, height: textHeight }), 10) / this.props.zoomFactor).toFixed(2);
                }
                document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].style.fontSize = (fontSize * this.props.zoomFactor).toFixed(2) + "px";

                let innerHeight = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;
                width = textItem.get("width");
                height = textItem.get("height");

                yRatio = (((textItem.get("height") / 2) - innerHeight / 2) / textItem.get("height")) * 100;
                hRatio = (innerHeight / textItem.get("height")) * 100;

            }
            if (this.props.textStatus.get("container") !== "workspaceChildren" || this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                if (textSettingsPaste) {
                    // this.props.pushPasteData({ id: this.props.textStatus.get("id"), property: "fontSize", value: fontSize + "px", container: this.props.textStatus.get("container"), isGrouped: this.props.textStatus.get("isGrouped"), fontSizeScale: 1, width: width, height: height, yRatio: yRatio, heightRatio: hRatio });
                } else {
                    this.props.updateText({ id: this.props.textStatus.get("id"), property: "fontSize", value: fontSize + "px", container: this.props.textStatus.get("container"), isGrouped: this.props.textStatus.get("isGrouped"), fontSizeScale: 1, width, height, yRatio, heightRatio: hRatio });
                }
            } else {
                let currentText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
                let parentItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));

                let dW = (width - currentText.get("width")), dH = (height - currentText.get("height"));
                let groupUpdate = { dW, dH, itemY: currentText.get("yRatio") ? (currentText.get("yRatio") * parentItem.get("height")) / 100 : currentText.get("y"), verticalAlign: currentText.get("verticalAlign"), currentId: this.props.textStatus.get("id"), groupId: this.props.selectedItems.get(0), isGrouped: true, selectedItems: this.props.selectedItems, propertyUpdate: true, property: "fontSize", value: size + "px" };
                groupUpdate.textData = currentText.setIn(["textData", "htmlText"], document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].innerHTML).get("textData").toJS();

                groupUpdate.textData.formats.containerStyle["fontSize"] = fontSize + "px";

                let newText = currentText.merge(groupUpdate);
                if (!currentText.equals(newText)) {
                    if (textSettingsPaste) {
                        // this.props.pushPasteData({ ...groupUpdate, isGroupUpdate: true });
                    } else {
                        this.props.updateGroupText(groupUpdate);
                    }
                }
            }
        } catch (error) { }
    }

    toggleFontFamilyWindow() {
        try {
            this.setState(p => ({ isFontFamilyOpen: !p.isFontFamilyOpen }));
        } catch (error) { }
    }

    isPartialText(editableElem) {
        let allTextSelected = false;

        if (window.getSelection) {
            allTextSelected = window.getSelection().toString().trim() === editableElem.innerText.trim() || window.getSelection().toString().trim() === '';
        } else if (document.getSelection) {
            allTextSelected = document.getSelection().toString().trim() === editableElem.innerText.trim();
        } else if (document.selection) {
            allTextSelected = document.selection.createRange().text.trim() === editableElem.innerText.trim();
        }
        return allTextSelected;
    }

    getNext(map, value) {
        let nextKey;
        let keys = map.keySeq().toArray();
        if (value !== null) {
            if (value === 'disc')
                value = 'none';
            let keyIndex = keys.indexOf(value);
            nextKey = keyIndex;
            if (keyIndex !== -1) {
                nextKey = keyIndex + 1;
                if (keys.length === nextKey)
                    nextKey = 0;
            }
            else
                nextKey = 0;
        }
        else
            nextKey = 1;
        return keys[nextKey];
    }

    selectAll(id) {
        document.getElementById(id).getElementsByClassName("text-container")[0].setAttribute("contentEditable", true);
        document.getElementById(id).getElementsByClassName("text-container")[0].focus();
        let range, selection;
        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(id).getElementsByClassName("text-container")[0]);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(document.getElementById(id).getElementsByClassName("text-container")[0]);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    getElements(el) {
        let siblings = [];
        let s = el.firstChild;
        if (s !== null) {
            do {
                if (s !== null && s.nodeType !== Node.TEXT_NODE) {
                    siblings.push(s);
                    this.totalChilds.push(s);
                }
            } // eslint-disable-next-line 
            while (s = s.nextSibling);


            for (let i = 0; i < siblings.length; i += 1) {
                if (siblings[i].nodeType !== Node.TEXT_NODE && siblings[i].nodeName !== "BR")
                    this.getElements(siblings[i]);
            }
        }

    }

    removeStyle(property, id) {
        let nodeEq = { fontWeight: "B", fontStyle: "I", textDecoration: "U" };
        let propEq = { fontWeight: "font-weight", fontStyle: "font-style", textDecoration: "text-decoration", color: "color", fontFamily: "font-family", fontSize: "font-size", textAlign: "text-align", lineHeight: 'line-height' };
        this.totalChilds = [];
        this.getElements(document.getElementById(id).getElementsByClassName("text-container")[0]);

        for (let i = 0; i < this.totalChilds.length; i += 1) {
            if (this.totalChilds[i].nodeName === nodeEq[property]) {
                let parent = this.totalChilds[i].parentNode;
                while (this.totalChilds[i].firstChild) parent.insertBefore(this.totalChilds[i].firstChild, this.totalChilds[i]);
                parent.removeChild(this.totalChilds[i]);
            }
            else if (this.totalChilds[i].nodeName === "FONT") {
                if (property === "color")
                    this.totalChilds[i].removeAttribute("color");
                else if (property === "fontFamily")
                    this.totalChilds[i].removeAttribute("face");
                else if (property === "fontSize")
                    this.totalChilds[i].removeAttribute("size");
                if (this.totalChilds[i].style) {
                    this.totalChilds[i].style.removeProperty(propEq[property]);
                }
            }
            else if (this.totalChilds[i].style) {
                this.totalChilds[i].style.removeProperty(propEq[property]);
            }
        }
    }

    fontSettingsChanged(property, value, textSettingsPaste = false) {
        let obj = {}, currentText;
        obj[property] = value;
        if (property === "isBullet" && this.props.textOptions.getIn(["bullet", "value"]) === null)
            obj.bullet = "&#9679;";

        // let fontSizeScale = 1;
        if (property === "fontSize") {
            // fontSizeScale = value / this.props.textOptions.getIn(["fontSize", "value"]);
            value = value + "px";
        }
        if (property === "letterSpacing") {
            value = value + "em";
        }

        if (property === "color" && this.props.textStatus.get("isGrouped"))
            obj.colors = [value];

        let toUpdate = { id: this.props.textStatus.get("id"), property, value, container: this.props.textStatus.get("container"), isGrouped: this.props.textStatus.get("isGrouped") };
        let bulletType = "";
        let isPartial = false;
        if (property === "bullet" || ((property === "color" || property === "textDecoration") && isTextSelected())) {
            if (property === "bullet") {
                this.selectAll(this.props.textStatus.get("id"));
                if (value === "none" && this.props.textOptions.getIn(["bullet", "value"]) !== "") {
                    bulletType = this.bulletOptions.get(this.props.textOptions.getIn(["bullet", "value"]));
                }
                else
                    bulletType = this.bulletOptions.get(value);

                if (bulletType !== "") {
                    currentText = (this.props.textStatus.get("container") === "workspaceItems") ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style.textTransform = "none";
                    if (value === "none") {
                        const textContainer = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0];
                        let elementsWithoutList = "";
                        const li = textContainer.querySelectorAll("li");
                        if (li.length > 0) {
                            li.forEach((item) => {
                                if (item.children.length === 1 && item.children[0] instanceof HTMLBRElement) {
                                    elementsWithoutList += item.innerHTML;
                                } else {
                                    elementsWithoutList += item.innerHTML + "<br />";
                                }
                            })
                        } else {
                            elementsWithoutList += textContainer.innerHTML;
                        }
                        textContainer.innerHTML = elementsWithoutList;
                    } else {
                        document.execCommand(bulletType, false);
                    }

                    /* preSetting the paddingSpace to get the desired fontSize start */
                    let curLetterSpacing = 0;
                    if (this.props.selectedTextData.getIn(["textData", "formats", "others", "dirRTL"]) && (value === "orderedList" || value === "unOrderedList")) {
                        if (typeof this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]) !== "undefined") {
                            if (value === "orderedList") {
                                curLetterSpacing = (parseFloat(this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]), 10) * 6) > 2.52 ? 2.52 : (parseFloat(this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]), 10) * 6);
                            }
                        }
                        document
                            .getElementById(this.props.textStatus.get("id"))
                            .getElementsByClassName("text-container")[0].style.paddingRight = curLetterSpacing > 1.3 ? `${curLetterSpacing.toFixed(2)}em` : "1.3em";
                        document
                            .getElementById(this.props.textStatus.get("id"))
                            .getElementsByClassName("text-container")[0].style.paddingLeft = 0;
                    }
                    else {
                        document
                            .getElementById(this.props.textStatus.get("id"))
                            .getElementsByClassName("text-container")[0].style.paddingRight = 0;
                        if (value === "orderedList" && typeof this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]) !== "undefined") {
                            curLetterSpacing = (parseFloat(this.props.selectedTextData.getIn(["textData", "formats", "containerStyle", "letterSpacing"]), 10) * 3);
                        }
                        document
                            .getElementById(this.props.textStatus.get("id"))
                            .getElementsByClassName("text-container")[0].style.paddingLeft = (value === "orderedList" && curLetterSpacing !== 0) ? `${curLetterSpacing.toFixed(2)}em` : 0;
                    }/* End */

                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].setAttribute("contentEditable", false);
                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style.textTransform = currentText.getIn(["textData", "formats", "containerStyle", "textTransform"]);
                    toUpdate.htmlText = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].innerHTML;
                    // toUpdate.selectedAll = false;
                    this.selectAll(this.props.textStatus.get("id"));
                }
            } else {

                if (isAllTextSelected(document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0])) {
                    this.removeStyle(property, this.props.textStatus.get("id"));
                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style[property] = value;
                }
                else {
                    isPartial = true;
                    if (property === "textDecoration")
                        document.execCommand('underline');
                    else if (property === "color")
                        document.execCommand('foreColor', false, value);
                }

                toUpdate.htmlText = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].innerHTML;

            }
        } else if (property !== 'lineHeight') {
            this.removeStyle(property, this.props.textStatus.get("id"));
            document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style[property] = value;
            toUpdate.htmlText = this.textRoot.innerHTML;
        }
        let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
        toUpdate.isPartial = isPartial;
        toUpdate.width = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetWidth / this.props.zoomFactor);
        toUpdate.height = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetHeight / this.props.zoomFactor);
        if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined && textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== '0px') {
            toUpdate.width += this.props.textOffset;
            toUpdate.height += this.props.textOffset;
        }

        if (this.props.textStatus.get("isFocused"))
            document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].setAttribute("contentEditable", true);
        if (this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
            let widthRatio = textItem.get("maxWidthRatio") !== undefined && textItem.get("maxWidthRatio") !== null ? Math.round(textItem.get("maxWidthRatio")) : 90;
            let heightRatio = textItem.get("maxHeightRatio") !== undefined && textItem.get("maxHeightRatio") !== null ? Math.round(textItem.get("maxHeightRatio")) : 90;

            let textWidth = (widthRatio / 100) * textItem.get("width");
            let textHeight = (heightRatio / 100) * textItem.get("height");

            if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined && toUpdate.height - this.props.textOffset > textHeight) {
                let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: textWidth, height: textHeight }), 10) / this.props.zoomFactor;
                fontSize = fontSize.toFixed(2);
                document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].style.fontSize = (fontSize * this.props.zoomFactor).toFixed(2) + "px";
                toUpdate.textData = textItem.get("textData").toJS();
                toUpdate.textData.formats.containerStyle.fontSize = fontSize + "px";
            }


            let innerHeight = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;
            toUpdate.width = textItem.get("width");
            toUpdate.height = textItem.get("height");

            toUpdate.yRatio = (((textItem.get("height") / 2) - innerHeight / 2) / textItem.get("height")) * 100;
            toUpdate.heightRatio = (innerHeight / textItem.get("height")) * 100;

        }

        if (this.props.textStatus.get("container") === "workspaceItems" || this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
            if (textSettingsPaste) {
                // this.props.pushPasteData(toUpdate);
            } else {
                let widthRestricted = false;
                if (property === 'letterSpacing')
                    widthRestricted = true
                if (property === 'lineHeight')
                    toUpdate.htmlText = this.textRoot.innerHTML;

                let { width, height, x, textData } = this.getUpdatedText(false, widthRestricted)
                if (width !== undefined && height !== undefined && x !== undefined && textData !== undefined) {
                    let isFontSizeChanged = textData.get("isfontSizeChanged");
                    toUpdate.textData = toUpdate.textData === undefined ? fromJS({}) : fromJS(toUpdate.textData)
                    if (isFontSizeChanged) {
                        toUpdate.textData = toUpdate.textData.setIn(["formats", "containerStyle", "fontSize"], textData.getIn(["formats", "containerStyle", "fontSize"]))
                        toUpdate.textData = toUpdate.textData.set("isfontSizeChanged", isFontSizeChanged)
                    }
                    if (widthRestricted && this.props.textStatus.get("container") === "workspaceItems") {
                        toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isFixedWidth"], true)
                    }
                    toUpdate = { ...toUpdate, width, height, x }
                }
                this.props.updateText(toUpdate);
            }
        } else {
            let currentText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
            let parentItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));

            let dW = (toUpdate.width - currentText.get("width")), dH = (toUpdate.height - currentText.get("height"));
            let groupUpdate = { dW, dH, itemY: currentText.get("yRatio") ? (currentText.get("yRatio") * parentItem.get("height")) / 100 : currentText.get("y"), verticalAlign: currentText.get("verticalAlign"), currentId: this.props.textStatus.get("id"), groupId: this.props.selectedItems.get(0), isGrouped: true, selectedItems: this.props.selectedItems, propertyUpdate: true, property, value, isPartial: toUpdate.isPartial, selectedAll: toUpdate.selectedAll };
            groupUpdate.textData = currentText.setIn(["textData", "htmlText"], document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].innerHTML).get("textData").toJS();
            if (property === "bullet") {
                groupUpdate.textData.formats.bullet.type = value;
            } else if (!isPartial) {
                groupUpdate.textData.formats.containerStyle[property] = value;
            }

            if (property === 'textDirection') {
                groupUpdate.textData.formats.others['isRTL'] = value;
                groupUpdate.dW = 0;
                groupUpdate.dH = 0;
                groupUpdate.itemY = 0;
            }

            let newText = currentText.merge(groupUpdate);
            if (!currentText.equals(newText)) {
                if (textSettingsPaste) {
                    // this.props.pushPasteData({ ...groupUpdate, isGroupUpdate: true });
                } else {
                    this.props.updateGroupText(groupUpdate);
                }
            }
        }

    }

    getWorkspaceBounds = () => {
        return document.getElementById("workspace").getBoundingClientRect();
    }

    // eslint-disable-next-line consistent-return
    getUpdatedText(fontSizeChanged = false, isFixedWidthProp = false) {
        let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
        let parentItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));
        let textElement = document.getElementById(this.props.textStatus.get("id"));
        let itemX = textItem.get("x"), itemY = textItem.get("y");
        if (this.props.textStatus.get('isGrouped')) {
            itemX = itemX - parentItem.get("x"); itemY = itemY - parentItem.get("y");
        }
        let textContainer = textElement.getElementsByClassName('text-container')[0];
        if (!isFixedWidthProp || textItem.get("type") !== "SHAPE")
            textContainer.style.fontSize = (parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10).toFixed(2) * this.props.zoomFactor) + "px";
        let textHeight = textItem.get("height");
        let verticalAlign = (textItem.get("verticalAlign") !== undefined && textItem.get("verticalAlign") !== "") ? textItem.get("verticalAlign") : "top";
        let preWidth = textElement.style.width;
        let isFixedWidth = textItem.getIn(["textData", "formats", "others", "isFixedWidth"]);
        if (this.props.selectedItems.size > 1)
            isFixedWidth = true;
        let minWidth = textItem.getIn(["textData", "formats", "others", "minWidth"]);
        minWidth = (minWidth !== null && minWidth !== undefined) ? minWidth : 100;
        let widthRestricted = fontSizeChanged;
        if (textItem.get("type") === "SHAPE" || this.props.textStatus.get('isGrouped') || isFixedWidthProp)
            widthRestricted = true;
        if (!isFixedWidth && !widthRestricted) {
            textElement.style.width = "auto";
        }
        let flip = "";
        if (textItem.get("type") === "SHAPE") {
            let flipPos = textItem.get("flipPosition");
            flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
        }
        textElement.style.transform = "translate(" + (itemX * this.props.zoomFactor).toFixed(2) + "px, " + (itemY * this.props.zoomFactor).toFixed(2) + "px) rotateZ(0deg)" + flip;
        if (this.props.textStatus.get('isGrouped'))
            textElement.style.transform = "translate(" + (itemX * this.props.zoomFactor).toFixed(2) + "px, " + (itemY * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + (-(textItem.get("angle") + parentItem.get("angle"))) + "deg)" + flip;
        let range = document.createRange();
        range.selectNode(textContainer);
        let rangeBounds = range.getBoundingClientRect();
        let elementBounds = textContainer.getBoundingClientRect();
        let aHeight = elementBounds.height;
        textElement.style.transform = "translate(" + (itemX * this.props.zoomFactor).toFixed(2) + "px, " + (itemY * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + textItem.get("angle") + "deg)" + flip;
        textElement.style.width = preWidth;

        let workspace = this.getWorkspaceBounds();
        let elementWidth = rangeBounds.width;
        if (this.props.textStatus.get('isGrouped') || textItem.get("type") === "SHAPE") {
            let toUpdate = fromJS({ textData: textItem.get("textData") });
            let minXRatio = textItem.get("minXRatio") !== undefined && textItem.get("minXRatio") !== null ? Math.round(textItem.get("minXRatio")) : (textItem.get("type") === "SHAPE") ? 5 : 0;
            let minYRatio = textItem.get("minYRatio") !== undefined && textItem.get("minYRatio") !== null ? Math.round(textItem.get("minYRatio")) : (textItem.get("type") === "SHAPE") ? 5 : 0;
            let maxWidthRatio = textItem.get("maxWidthRatio") !== undefined && textItem.get("maxWidthRatio") !== null ? Math.round(textItem.get("maxWidthRatio")) : (textItem.get("type") === "SHAPE") ? 90 : 100;
            let maxHeightRatio = textItem.get("maxHeightRatio") !== undefined && textItem.get("maxHeightRatio") !== null ? Math.round(textItem.get("maxHeightRatio")) : (textItem.get("type") === "SHAPE") ? 90 : 100;

            if (textItem.getIn(["pathData", "maxBounds"]) !== undefined) {
                minXRatio = textItem.getIn(["pathData", "maxBounds", "x"]);
                minYRatio = textItem.getIn(["pathData", "maxBounds", "y"]);
                maxWidthRatio = textItem.getIn(["pathData", "maxBounds", "width"]);
                maxHeightRatio = textItem.getIn(["pathData", "maxBounds", "height"]);
            }

            let textWidth = textItem.get("width"), textHeight = textItem.get("height");
            let minX = (minXRatio * textWidth) / 100, minY = (minYRatio * textHeight) / 100, maxWidth = (maxWidthRatio * textWidth) / 100, maxHeight = (maxHeightRatio * textHeight) / 100;
            let autoWidthBounds, preAutotWidth;
            if (textItem.get("type") === "SHAPE") {
                preAutotWidth = textElement.getElementsByClassName("callout-text-container")[0].style.width;
                textElement.getElementsByClassName("callout-text-container")[0].style.width = "auto";
                autoWidthBounds = textContainer.getBoundingClientRect();
                textElement.getElementsByClassName("callout-text-container")[0].style.width = preAutotWidth;
            } else {
                preAutotWidth = textElement.style.width;
                textElement.style.width = "auto";
                autoWidthBounds = textContainer.getBoundingClientRect();
                textElement.style.width = preAutotWidth;
            }

            let innerX = (((elementBounds.x - workspace.x) / this.props.zoomFactor) - textItem.get("x")) - ((minXRatio * textWidth) / 100), innerY = (((elementBounds.y - workspace.y) / this.props.zoomFactor) - textItem.get("y")) - ((minYRatio * textHeight) / 100), innerWidth = parseFloat((autoWidthBounds.width / this.props.zoomFactor).toFixed(2)), innerHeight = parseFloat((elementBounds.height / this.props.zoomFactor).toFixed(2));
            if (this.props.textStatus.get('isGrouped')) {
                innerHeight = parseFloat((rangeBounds.height / this.props.zoomFactor).toFixed(2));
                innerWidth = parseFloat((rangeBounds.width / this.props.zoomFactor).toFixed(2));
            }

            if (innerX < 0)
                innerX = 0;

            if (innerY < 0)
                innerY = 0;

            if (innerWidth === 0 || innerHeight === 0) {
                innerWidth = maxWidth;
                textContainer.innerHTML = "a";
                innerHeight = textContainer.getBoundingClientRect().height;
                textContainer.innerHTML = " ";

                innerX = minX;
                let iDH = maxHeight - innerHeight;
                innerY = innerY + iDH / 2;

                if (innerHeight > maxHeight)
                    innerHeight = maxHeight
            }

            if (textItem.get("type") === "SHAPE" || textItem.getIn(["textData", "formats", "others", "sizeFixed"]) === true) {
                if (innerWidth <= maxWidth && innerHeight <= maxHeight) {
                    if (innerWidth <= maxWidth) {
                        let iDW = maxWidth - innerWidth;
                        innerX = innerX + iDW / 2;
                    }
                    let iDH = maxHeight - innerHeight;
                    innerY = innerY + iDH / 2;

                } else if (/* innerWidth > maxWidth ||  */innerHeight > maxHeight) {

                    let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: maxWidth, height: maxHeight }), 10) / this.props.zoomFactor
                    if (isNaN(fontSize))
                        fontSize = 10;
                    if (fontSizeChanged) {
                        if (fontSize > parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10)) {
                            fontSize = parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                            let container = document.getElementById(this.props.textStatus.get('id'));
                            if (container.className.indexOf("callout-text") !== -1) {
                                container = container.getElementsByClassName("callout-text-container")[0];
                            }
                            let ourText = container.getElementsByClassName("text-container")[0];

                            ourText.style.fontSize = (fontSize * this.props.zoomFactor) + "px";

                        }
                    }
                    toUpdate = toUpdate.setIn(["textData", "isfontSizeChanged"], true)
                    toUpdate = toUpdate.setIn(["textData", "formats", "containerStyle", "fontSize"], fontSize + "px");
                }
                if (isFixedWidthProp && textItem.get("type") === "SHAPE") { // for shape when letterSpacing property is updated.
                    let container = document.getElementById(this.props.textStatus.get('id'));
                    if (container.className.indexOf("callout-text") !== -1) {
                        container = container.getElementsByClassName("callout-text-container")[0];
                    }
                    let ourText = container.getElementsByClassName("text-container")[0];
                    let fontSize = parseFloat(ourText.style.fontSize, 10) / this.props.zoomFactor
                    if (isNaN(fontSize))
                        fontSize = 10;
                    toUpdate = toUpdate.setIn(["textData", "isfontSizeChanged"], true)
                    toUpdate = toUpdate.setIn(["textData", "formats", "containerStyle", "fontSize"], fontSize + "px");
                }
            } else {
                let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: maxWidth, height: maxHeight }), 10) / this.props.zoomFactor

                if (isNaN(fontSize))
                    fontSize = 10;
                if (fontSizeChanged) {
                    if (fontSize > parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10)) {
                        fontSize = parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                        let container = document.getElementById(this.props.textStatus.get('id'));
                        if (container.className.indexOf("callout-text") !== -1) {
                            container = container.getElementsByClassName("callout-text-container")[0];
                        }
                        let ourText = container.getElementsByClassName("text-container")[0];

                        ourText.style.fontSize = (fontSize * this.props.zoomFactor) + "px";
                    }
                }
                toUpdate = toUpdate.setIn(["textData", "isfontSizeChanged"], true)
                toUpdate = toUpdate.setIn(["textData", "formats", "containerStyle", "fontSize"], parseFloat(fontSize, 10).toFixed(2) + "px");
            }


            let yRatio = (((innerY + (minY + maxHeight / 2) - (innerY + innerHeight / 2))) / textHeight) * 100;
            if (yRatio < minYRatio)
                yRatio = minYRatio;

            toUpdate = toUpdate.set("yRatio", yRatio).set("heightRatio", (innerHeight / textHeight) * 100);

            toUpdate = toUpdate.setIn(["textData", "htmlText"], textContainer.innerHTML);

            if (textContainer.innerHTML.trim() === "" && textItem.get("type") === "SHAPE") {
                innerWidth = maxWidth;
                let iDW = maxWidth - innerWidth;
                innerX = innerX + iDW / 2;
                let iDH = maxHeight - innerHeight;
                innerY = innerY + iDH / 2;
                yRatio = (((innerY + (minY + maxHeight / 2) - (innerY + innerHeight / 2))) / textItem.get("height")) * 100;

                if (yRatio < minYRatio)
                    yRatio = minYRatio;

                toUpdate = toUpdate.set("yRatio", yRatio).set("heightRatio", (innerHeight / textItem.get("height")) * 100).set("xRatio", (((innerX + (minX + maxWidth / 2) - (innerX + innerWidth / 2))) / textItem.get("width")) * 100).set("widthRatio", (innerWidth / textItem.get("width")) * 100);
                toUpdate = toUpdate.setIn(["textData", "htmlText"], " ");
            }
            if (!toUpdate.isEmpty()) {
                let newText = textItem.merge(toUpdate);
                // if(!textItem.equals(newText) || !isTyping){
                toUpdate = toUpdate.set("x", newText.get("x"));
                toUpdate = toUpdate.set("y", newText.get("y"));
                toUpdate = toUpdate.set("width", newText.get("width"));
                toUpdate = toUpdate.set("height", newText.get("height"));
                return {
                    x: toUpdate.get('x'),
                    width: toUpdate.get('width'),
                    height: toUpdate.get('height'),
                    textData: toUpdate.get('textData')
                }
                // }
            }
        } else {
            if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined) {
                elementWidth += this.props.textOffset * this.props.zoomFactor;
                aHeight += this.props.textOffset * this.props.zoomFactor;
            }
            let alignment = textItem.getIn(["textData", "formats", "containerStyle", "textAlign"]);
            let isRTL = textItem.getIn(["textData", "formats", "others", "isRTL"]);

            if (!alignment)
                alignment = "left";

            if (isRTL)
                alignment = (alignment === "left") ? "right" : alignment;

            let toUpdate = fromJS({ textData: textItem.get("textData") });
            let xToUpdate;

            if (!widthRestricted) {
                let bufferWidth = 0;
                let centerBufferWidth = 0;
                if (textItem.getIn(["textData", "formats", "containerStyle", "margin"]) === "0px" && !isFixedWidth) {
                    bufferWidth = 3;
                    centerBufferWidth = 1.5;
                }
                let dW = (elementWidth - parseInt(preWidth, 10)), widthToUpdate = textItem.get("width") + ((dW) / this.props.zoomFactor) + bufferWidth;
                if (alignment === "left") {

                    if ((textItem.get("x") + textItem.get("width")) < this.props.workspaceWidth) {
                        if ((textItem.get("x") + widthToUpdate) > this.props.workspaceWidth)
                            widthToUpdate = this.props.workspaceWidth - textItem.get("x");
                        if (widthToUpdate > minWidth) {
                            toUpdate = toUpdate.set("width", widthToUpdate);
                        } else {
                            toUpdate = toUpdate.set("width", minWidth);
                        }
                    }
                    else if (!isFixedWidth) {
                        toUpdate = toUpdate.setIn(["textData", "formats", "others", "isFixedWidth"], true);
                    }
                }
                else if (alignment === "right") {

                    if (textItem.get("x") > 0) {
                        xToUpdate = textItem.get("x") - (dW / this.props.zoomFactor) - bufferWidth;
                        if (xToUpdate < 0)
                            xToUpdate = 0;
                        if (widthToUpdate > minWidth) {
                            toUpdate = toUpdate.set("x", xToUpdate).set("width", widthToUpdate);
                        } else {
                            xToUpdate = (textItem.get('x') + (textItem.get('width'))) - minWidth;
                            toUpdate = toUpdate.set("width", minWidth).set("x", xToUpdate);
                        }
                    }
                    else if (!isFixedWidth) {
                        toUpdate = toUpdate.setIn(["textData", "formats", "others", "isFixedWidth"], true);
                    }
                }
                else if (alignment === "center") {

                    if (textItem.get("x") > 0 && (textItem.get("x") + textItem.get("width")) < this.props.workspaceWidth) {
                        xToUpdate = textItem.get("x") - ((dW / 2) / this.props.zoomFactor) - centerBufferWidth;
                        if (xToUpdate < 0)
                            xToUpdate = 0;
                        if ((xToUpdate + widthToUpdate) > this.props.workspaceWidth)
                            widthToUpdate = this.props.workspaceWidth - xToUpdate;
                        if (widthToUpdate > minWidth) {
                            toUpdate = toUpdate.set("width", widthToUpdate).set("x", xToUpdate);
                        } else {
                            xToUpdate = (textItem.get('x') + (textItem.get('width') / 2)) - minWidth / 2;
                            toUpdate = toUpdate.set("width", minWidth).set("x", xToUpdate);
                        }
                    }
                    else if (!isFixedWidth) {
                        toUpdate = toUpdate.setIn(["textData", "formats", "others", "isFixedWidth"], true);
                    }
                }
            }

            if (textHeight !== aHeight && aHeight !== 0) {
                aHeight = aHeight > 20 ? aHeight : 20;
                let dH = (aHeight / this.props.zoomFactor) - textItem.get("height"), curY, curHeight;
                if (verticalAlign === "middle") {
                    curY = -1 * (dH / 2); curHeight = dH;
                }
                else if (verticalAlign === "bottom") {
                    curY = -1 * dH; curHeight = dH;
                }
                else {
                    curY = 0; curHeight = dH;
                }
                toUpdate = toUpdate.set("y", textItem.get("y") + curY);
                toUpdate = toUpdate.set("height", textItem.get("height") + curHeight);
            }
            toUpdate = toUpdate.setIn(["textData", "htmlText"], textContainer.innerHTML);

            let newText = textItem.merge(toUpdate);

            document.getElementById(this.props.textStatus.get("id")).style.transform = "translate(" + parseFloat(newText.get("x") * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(newText.get("y") * this.props.zoomFactor).toFixed(2) + ") rotateZ(" + newText.get("angle") + "deg)";
            document.getElementById(this.props.textStatus.get("id")).style.width = parseFloat(newText.get("width") * this.props.zoomFactor).toFixed(2) + "px";
            document.getElementById(this.props.textStatus.get("id")).style.height = parseFloat(newText.get("height") * this.props.zoomFactor).toFixed(2) + "px";

            toUpdate = toUpdate.set("x", newText.get("x"));
            toUpdate = toUpdate.set("y", newText.get("y"));
            toUpdate = toUpdate.set("width", newText.get("width"));
            toUpdate = toUpdate.set("height", newText.get("height"));

            return {
                x: toUpdate.get('x'),
                width: toUpdate.get('width'),
                height: toUpdate.get('height'),
                textData: toUpdate.get('textData')
            }
        }
    }

    updateGroupedText = (fontSizeChanged = false) => {
        let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
        let textElement = document.getElementById(this.props.textStatus.get("id"));
        let textContainer = textElement.getElementsByClassName('text-container')[0];
        let parentX, parentY, parentHeight, parentAngle, parentElement, parentItem;
        if (this.props.textStatus.get("isGrouped")) {
            parentElement = document.getElementById(this.props.selectedItems.get(0))
            parentItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            parentX = (parentItem.get("x") * this.props.zoomFactor).toFixed(2); parentY = (parentItem.get("y") * this.props.zoomFactor).toFixed(2); parentHeight = parentItem.get("height"); parentAngle = parentItem.get("angle");
        } else {
            parentX = (textItem.get("x") * this.props.zoomFactor).toFixed(2); parentY = (textItem.get("y") * this.props.zoomFactor).toFixed(2); parentHeight = textItem.get("height"); parentAngle = textItem.get("angle");
            parentElement = textElement;
        }

        let itemY = textItem.get("yRatio") ? (textItem.get("yRatio") * parentHeight) / 100 : textItem.get("y"),
            itemHeight = textItem.get("heightRatio") ? (textItem.get("heightRatio") * parentHeight) / 100 : textItem.get("height");

        let parentFlip = "";
        if (!this.props.textStatus.get("isGrouped")) {
            if (textItem.get("type") === "SHAPE") {
                let flipPos = textItem.get("flipPosition");
                parentFlip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
            }
        }
        parentElement.style.transform = "translate(" + parentX + "px, " + parentY + "px) rotateZ(0deg)" + parentFlip;
        let flip = "";
        if (this.props.textStatus.get("isGrouped")) {
            if (textItem.get("type") === "SHAPE") {
                let flipPos = textItem.get("flipPosition");
                flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
            }
            textElement.style.transform = "translate(" + ((textItem.get("x") - parentItem.get("x")) * this.props.zoomFactor).toFixed(2) + "px, " + ((textItem.get("y") - parentItem.get("y")) * this.props.zoomFactor).toFixed(2) + "px) rotateZ(0deg)" + flip;
            textElement.style.height = (textItem.get("height") * this.props.zoomFactor) + "px";
        }
        let range = document.createRange();
        range.selectNode(textContainer);

        let rangeBounds = range.getBoundingClientRect();

        parentElement.style.transform = "translate(" + parentX + "px, " + parentY + "px) rotateZ(" + parentAngle + "deg)" + parentFlip;

        if (this.props.textStatus.get("isGrouped")) {
            flip = "";
            if (textItem.get("type") === "SHAPE") {
                let flipPos = textItem.get("flipPosition");
                flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
            }
            textElement.style.transform = "translate(" + ((textItem.get("x") - parentItem.get("x")) * this.props.zoomFactor).toFixed(2) + "px, " + ((textItem.get("y") - parentItem.get("y")) * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + textItem.get("angle") + "deg)" + flip;
        }
        let textHeight = (rangeBounds.height / this.props.zoomFactor);

        let newText = textItem;
        if (textItem.get("type") !== "SHAPE") {
            textHeight += this.props.textOffset;
            if (itemHeight !== parseFloat(textHeight.toFixed(2))) {
                let dH = (textHeight - itemHeight), dW = 0;
                let toUpdate = { dW, dH, itemY, verticalAlign: textItem.get("verticalAlign"), currentId: this.props.textStatus.get("id"), groupId: this.props.selectedItems.get(0), isGrouped: this.props.textStatus.get('isGrouped'), selectedItems: this.props.selectedItems };
                toUpdate.textData = textItem.setIn(["textData", "htmlText"], textContainer.innerHTML).get("textData").toJS();
                toUpdate.groupText = true;
                newText = textItem.merge(toUpdate);
                // if(!textItem.equals(newText) || !isTyping)
                return toUpdate
            } else {
                let toUpdate = fromJS({ textData: textItem.get("textData") });
                toUpdate = toUpdate.setIn(["textData", "htmlText"], textContainer.innerHTML);

                newText = textItem.merge(toUpdate);
                // if(!textItem.equals(newText)){
                toUpdate = toUpdate.set("x", newText.get("x"));
                toUpdate = toUpdate.set("y", newText.get("y"));
                toUpdate = toUpdate.set("width", newText.get("width"));
                toUpdate = toUpdate.set("height", newText.get("height"));
                return toUpdate.toJS()
                // }
            }
        }
        else {
            let toUpdate = fromJS({ textData: textItem.get("textData") });
            let minYRatio = textItem.get("minYRatio") !== undefined ? textItem.get("minYRatio") : 5;
            let maxWidthRatio = textItem.get("maxWidthRatio") !== undefined ? textItem.get("maxWidthRatio") : 90;
            let maxHeightRatio = textItem.get("maxHeightRatio") !== undefined ? textItem.get("maxHeightRatio") : 90;

            let maxWidth = (maxWidthRatio * textItem.get("width")) / 100;
            let maxHeight = (maxHeightRatio * textItem.get("height")) / 100;

            if (textHeight > maxHeight) {
                let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: maxWidth, height: maxHeight }), 10) / this.props.zoomFactor;

                if (isNaN(fontSize))
                    fontSize = 10;
                if (fontSizeChanged) {
                    if (fontSize > parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10)) {
                        fontSize = parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                        let container = document.getElementById(this.state.textId).getElementsByClassName("callout-text-container")[0];
                        let ourText = container.getElementsByClassName("text-container")[0];
                        ourText.style.fontSize = (fontSize * this.props.zoomFactor) + "px";
                    }
                }
                let cy = (maxHeight / 2) + ((minYRatio * textItem.get("height")) / 100);
                let yRatio = ((cy - (maxHeight / 2)) / textItem.get("height")) * 100;
                let heightRatio = ((maxHeight / textItem.get("height")) * 100);
                toUpdate = toUpdate.set("yRatio", yRatio).set("heightRatio", heightRatio);
                toUpdate = toUpdate.setIn(["textData", "isfontSizeChanged"], true)
                toUpdate = toUpdate.setIn(["textData", "formats", "containerStyle", "fontSize"], fontSize + "px");
            } else {
                let cy = (maxHeight / 2) + ((minYRatio * textItem.get("height")) / 100);
                let yRatio = ((cy - (textHeight / 2)) / textItem.get("height")) * 100;
                let heightRatio = ((textHeight / textItem.get("height")) * 100);
                toUpdate = toUpdate.set("yRatio", yRatio).set("heightRatio", heightRatio);
            }
            toUpdate = toUpdate.setIn(["textData", "htmlText"], textContainer.innerHTML);
            newText = textItem.merge(toUpdate);

            toUpdate = toUpdate.set("x", newText.get("x"));
            toUpdate = toUpdate.set("y", newText.get("y"));
            toUpdate = toUpdate.set("width", newText.get("width"));
            toUpdate = toUpdate.set("height", newText.get("height"));

            return toUpdate.toJS()
        }
    }

    toggleSizes() {
        const { fontSizeFocused } = this.state;

        const toUpdate = {
            fontSizeFocused: !fontSizeFocused,
        };

        if (toUpdate.fontSizeFocused && this.fontSizeContainerRef.current) {
            toUpdate.fontSizeContainerBounds = this.fontSizeContainerRef.current.getBoundingClientRect();
        } else {
            toUpdate.fontSizeFocused = false;
            toUpdate.fontSizeContainerBounds = null;
        }

        this.setState(toUpdate);
    }

    colorSelected(color, textSettingsPaste = false) {
        this.fontSettingsChanged("color", color, textSettingsPaste);
    }

    getFontSizeValue = () => {
        if (this.inputFontSizeElm && this.inputFontSizeElm.current) {
            let value = this.inputFontSizeElm.current.value;
            return value
        }
        return "";
    }

    handleFontsizeChange(e = undefined) {
        this.setState({ fontSizeFocused: false });

        let fontSizeValue = this.getFontSizeValue()

        if (fontSizeValue !== "" && parseFloat(fontSizeValue) >= 6 && parseFloat(fontSizeValue) <= 999) {
            let sizeValue = fontSizeValue;
            let splitValue = sizeValue.split(".");
            if (splitValue.length > 1 && splitValue[1].length > 2)
                sizeValue = splitValue[0] + "." + splitValue[1].substr(0, 2);
            this.handleFontSizeClicked(e, sizeValue);
            // this.fontSettingsChanged("fontSize", parseFloat(sizeValue));
        } else if (fontSizeValue === "") {
            // parsing empty string results in NaN
            // this.setState({ fontSize: parseFloat(fontSizeValue) });
        } else {
            let fontSize = parseFloat(fontSizeValue);
            if (parseFloat(fontSizeValue) > 999) {
                fontSize = this.props.textOptions.getIn(["fontSize", "value"]);
                if (Number(fontSize) === fontSize && fontSize % 1 !== 0)
                    fontSize = parseFloat(fontSize, 10);
            }
            this.setState({ fontSize });
        }
    }

    updateLS(value, shouldSave) {

        value = parseFloat(parseFloat(value).toFixed(2));
        this.setState({ letterSpace: value });
        let textElement = document.getElementById(this.props.textStatus.get("id"))

        if (this.props.selectedItems.size === 1) {
            let selectedText;
            if (this.props.textStatus.get("container") === "workspaceChildren")
                selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
            else
                selectedText = this.props.selectedObjects.get(this.props.textStatus.get("id"));
            let textData = selectedText.get("textData");

            textData = textData.setIn(["formats", "containerStyle", "letterSpacing"], value + "em");
            let isOrderList = (textData.getIn(["formats", "bullet", "type"]) === "orderedList")
            let isRtl = (textData.getIn(["formats", "others", "isRTL"]))
            document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style["letterSpacing"] = value + "em";

            let flip = "";
            if (selectedText.get("type") === "SHAPE") {
                let flipPos = selectedText.get("flipPosition");
                flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
            }

            if (isOrderList && !isRtl) {
                textElement.getElementsByClassName("text-container")[0].style.paddingLeft = (parseFloat(value, 10) * 3).toFixed(2) + "em";
            } else if (isOrderList && isRtl) {
                textElement.getElementsByClassName("text-container")[0].style.paddingRight = (parseFloat(value, 10) * 3).toFixed(2) + "em";
            }

            let width = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetWidth / this.props.zoomFactor);
            let height = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetHeight / this.props.zoomFactor);

            if (textData.getIn(["formats", "containerStyle", "margin"]) !== undefined) {
                width += this.props.textOffset;
                height += this.props.textOffset;
            }

            let toUpdate = { textData: textData.toJS(), width, height }

            if (this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
                let widthRatio, heightRatio, textWidth, textHeight;
                if (this.props.textStatus.get("type") === "SHAPE") {
                    widthRatio = textItem.get("maxWidthRatio") !== undefined && textItem.get("maxWidthRatio") !== null ? Math.round(textItem.get("maxWidthRatio")) : 90;
                    heightRatio = textItem.get("maxHeightRatio") !== undefined && textItem.get("maxHeightRatio") !== null ? Math.round(textItem.get("maxHeightRatio")) : 90;
                    textWidth = (widthRatio / 100) * textItem.get("width");
                    textHeight = (heightRatio / 100) * textItem.get("height");
                } else {
                    textWidth = textItem.get("width");
                    textHeight = textItem.get("height");
                }
                if ((toUpdate.height - this.props.textOffset > textHeight || this.props.textStatus.get("textGroup") === true) && !shouldSave) {
                    let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: textWidth, height: textHeight }), 10) / this.props.zoomFactor;
                    fontSize = fontSize.toFixed(2);
                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].style.fontSize = (fontSize * this.props.zoomFactor).toFixed(2) + "px";
                    toUpdate.textData.formats.containerStyle.fontSize = fontSize + "px";
                }


                let innerHeight = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;
                toUpdate.width = textItem.get("width");
                toUpdate.height = textItem.get("height");

                toUpdate.yRatio = (((textItem.get("height") / 2) - innerHeight / 2) / textItem.get("height")) * 100;
                toUpdate.heightRatio = (innerHeight / textItem.get("height")) * 100;

            }

            if (this.props.textStatus.get("container") === "workspaceItems" || this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                // this.props.writeText({selectedItem : this.props.textStatus.get("id"), container : this.props.textStatus.get("container"), toUpdate : toUpdate, isTyping : !shouldSave},this.props.socket);
            }
            if (this.props.textStatus.get("type") === "SHAPE" && shouldSave) {
                this.props.writeText({ selectedItem: this.props.textStatus.get("id"), container: this.props.textStatus.get("container"), toUpdate, isTyping: !shouldSave });
            }

            if (this.props.textStatus.get("type") === "SHAPE") {
                let xRatio = this.props.selectedTextData.get("xRatio") !== undefined ? this.props.selectedTextData.get("xRatio") : 5;
                let widthRatio = this.props.selectedTextData.get("widthRatio") !== undefined ? this.props.selectedTextData.get("widthRatio") : 90;

                let textX = ((xRatio * toUpdate.width) / 100) * this.props.zoomFactor;
                let textY = ((toUpdate.yRatio * toUpdate.height) / 100) * this.props.zoomFactor;

                let textWidth = ((widthRatio * toUpdate.width) / 100) * this.props.zoomFactor;
                let textHeight = ((toUpdate.heightRatio * toUpdate.height) / 100) * this.props.zoomFactor;

                let callOutTextElm = textElement.getElementsByClassName("callout-text-container")[0];
                if (callOutTextElm) {
                    callOutTextElm.style.transform = `translate(${textX}px,${textY}px) ${flip}`
                    callOutTextElm.style.width = textWidth + 'px'
                    callOutTextElm.style.height = textHeight + 'px'
                }
            }
            if (this.props.textStatus.get("container") === "workspaceItems") {
                document.getElementsByClassName('selectionBox')[0].style.width = `${toUpdate.width * this.props.zoomFactor}px`
                document.getElementsByClassName('selectionBox')[0].style.height = `${toUpdate.height * this.props.zoomFactor}px`
                textElement.style.width = `${toUpdate.width * this.props.zoomFactor}px`
                textElement.style.height = `${toUpdate.height * this.props.zoomFactor}px`
            }
        }
    }

    lsMouseUp() {
        this.fontSettingsChanged("letterSpacing", this.state.letterSpace);
    }

    /** return next state value of isRTL */
    getNextRTL = () => {
        if (this.props.textStatus.get("container") === "workspaceItems") {
            return !this.props.selectedObjects.get(this.props.selectedItems.get(0)).getIn(["textData", "formats", "others", "isRTL"])
        } else {
            return !this.props.selectedChildren.get(this.props.textStatus.get("id")).getIn(["textData", "formats", "others", "isRTL"])
        }
    }

    /*
    handleTextSettingsCopy = () => {

        this.props.resetPasteData();

        let bulletinVal = this.props.textOptions.getIn(["bullet", "value"]);
        let fontData = this.fontFamilies.getIn(["default", "fonts"]).find(x => x.get("fontFamily") === this.activeFontName);
        if (!fontData) { // if font data is not present in default, check in userFonts - fontFamily
            fontData = this.props.userFonts.find(x => {
                return ((x.get("fontFamily") === this.activeFontName.replace(/^[\"]|[\"]$/g, "")) ||
                    (x.get("fontname") === this.activeFontName.replace(/^[\"]|[\"]$/g, "")))
            });
        }
        let copyData = {
            fontData: fontData,
            color: this.state.containerColor,
            size: this.state.fontSize,
            isBold: this.isBoldActive,
            isItalic: this.isItalicActive,
            isUnderline: this.isUnderlineActive,
            bulletinVal: bulletinVal,
            textAlign: this.textAlign,
            textTransform: this.textTransform,
            letterSpace: this.state.letterSpace,
            transparency: this.props.transparency,
            key: "VMAKER_CLIPBOARD"
        };

        let encoded = encodeURIComponent(JSON.stringify(copyData));

        navigator.clipboard.writeText(encoded);
    }

    handleTextSettingsPaste = e => {

        if (!this.props.copyDone)
            return;

        navigator.clipboard.readText().then(pasteText => {

            var pasteData = JSON.parse(decodeURIComponent(pasteText));

            var fontData = pasteData.fontData;
            var color = pasteData.color;
            var size = pasteData.size;
            var isBold = pasteData.isBold;
            var isItalic = pasteData.isItalic;
            var isUnderline = pasteData.isUnderline;
            var bulletinVal = pasteData.bulletinVal;
            var textAlign = pasteData.textAlign;
            var textTransform = pasteData.textTransform;
            var letterSpace = pasteData.letterSpace;
            var transparency = pasteData.transparency;

            var skipCount = 0;

            if (fontData !== undefined && fontData !== null && fontData.fontFamily !== this.activeFontName) {
                this.handleFontFamilyClicked(null, fromJS(fontData), true);
            } else {
                skipCount++;
            }

            if (color !== undefined && color !== null && color !== this.state.containerColor) {
                this.colorSelected(color, true);
            } else {
                skipCount++;
            }

            if (size !== undefined && size !== null && !isNaN(size) && size !== this.state.fontSize) {
                this.handleFontSizeClicked(null, size, true);
            } else {
                skipCount++;
            }

            if (isBold !== undefined && isBold !== null && isBold !== this.isBoldActive) {
                this.changeStyle("bold", true);
            } else {
                skipCount++;
            }

            if (isItalic !== undefined && isItalic !== null && isItalic !== this.isItalicActive) {
                this.changeStyle("italic", true);
            } else {
                skipCount++;
            }

            if (isUnderline !== undefined && isUnderline !== null && isUnderline !== this.isUnderlineActive) {
                this.fontSettingsChanged("textDecoration", isUnderline ? "underline" : null, true);
            } else {
                skipCount++;
            }

            let bulletinValCurr = this.props.textOptions.getIn(["bullet", "value"]);

            if (bulletinVal !== undefined && bulletinVal !== null && bulletinVal !== bulletinValCurr) {
                this.fontSettingsChanged("bullet", bulletinVal, true);
            } else {
                skipCount++;
            }

            if (textAlign !== undefined && textAlign !== null && textAlign !== this.textAlign) {
                this.fontSettingsChanged("textAlign", textAlign, true);
            } else {
                skipCount++;
            }

            if (textTransform !== undefined && textTransform !== null && textTransform !== this.textTransform) {
                this.fontSettingsChanged("textTransform", textTransform, true);
            } else {
                skipCount++;
            }

            let letterSpaceReady = letterSpace !== undefined && letterSpace !== null && !isNaN(letterSpace);

            if (letterSpaceReady && letterSpace !== this.state.letterSpace) {
                this.fontSettingsChanged("letterSpacing", parseFloat(letterSpace), true);
            } else {
                skipCount++;
            }

            if (transparency !== undefined && transparency !== null && !isNaN(transparency) && transparency !== this.props.transparency) {
                this.props.onTransparencyPaste(transparency);
            } else {
                skipCount++;
            }

            this.props.incrementPasteCount(skipCount);

            this.setState({
                letterSpace: letterSpaceReady ? parseFloat(letterSpace) : this.state.letterSpace
            });
        });

    }
    */

    handleFontSizeFocus = () => {
        this.toggleSizes();
        if (this.props.textStatus.get('isFocused'))
            this.props.updateTextStatus({ id: this.props.textStatus.get('id'), isFocused: false, isSelected: true });
        if (this.inputFontSizeElm.current) {
            this.inputFontSizeElm.current.select();
        }
        this.props.setWorkspaceTextFocus(true);
    }

    handleFontSizeBlur = () => {
        this.props.setWorkspaceTextFocus(false);
    }

    updateLH(value, shouldSave) {
        let lineHeight = parseFloat(parseFloat(value).toFixed(2));
        if (lineHeight === this.state.lineHeight) return

        let textElement = document.getElementById(this.props.textStatus.get("id"))
        if (this.props.selectedItems.size === 1) {
            let selectedText;
            if (this.props.textStatus.get("container") === "workspaceChildren")
                selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
            else
                selectedText = this.props.selectedObjects.get(this.props.textStatus.get("id"));

            let textData = selectedText.get("textData");

            // textData = textData.setIn(["formats", "containerStyle", "letterSpacing"], value + "em");
            // let isOrderList = (textData.getIn(["formats", "bullet", "type"]) === "orderedList")
            // let isRtl = (textData.getIn(["formats", "others","isRTL"]))
            document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].style["lineHeight"] = lineHeight;
            this.textRoot.querySelectorAll(`[${this.FONT_ATTRIBUTE}]`).forEach(node => {
                node.style.lineHeight = lineHeight;
            })

            let flip = "";
            if (selectedText.get("type") === "SHAPE") {
                let flipPos = selectedText.get("flipPosition");
                flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
            }

            // if(isOrderList && !isRtl){
            // textElement.getElementsByClassName("text-container")[0].style.paddingLeft = (parseFloat( value, 10) * 3).toFixed(2) + "em";
            // }else if(isOrderList && isRtl){
            // textElement.getElementsByClassName("text-container")[0].style.paddingRight = (parseFloat( value, 10) * 3).toFixed(2) + "em";
            // }

            let width = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetWidth / this.props.zoomFactor);
            let height = (document.getElementById(this.props.textStatus.get("id")).getElementsByClassName("text-container")[0].offsetHeight / this.props.zoomFactor);

            if (textData.getIn(["formats", "containerStyle", "margin"]) !== undefined) {
                width += this.props.textOffset;
                height += this.props.textOffset;
            }

            let toUpdate = { textData: textData.toJS(), width, height }

            if (this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                let textItem = (!this.props.textStatus.get("isGrouped")) ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
                let widthRatio, heightRatio, textWidth, textHeight;
                if (this.props.textStatus.get("type") === "SHAPE") {
                    widthRatio = textItem.get("maxWidthRatio") !== undefined && textItem.get("maxWidthRatio") !== null ? Math.round(textItem.get("maxWidthRatio")) : 90;
                    heightRatio = textItem.get("maxHeightRatio") !== undefined && textItem.get("maxHeightRatio") !== null ? Math.round(textItem.get("maxHeightRatio")) : 90;
                    textWidth = (widthRatio / 100) * textItem.get("width");
                    textHeight = (heightRatio / 100) * textItem.get("height");
                } else {
                    textWidth = textItem.get("width");
                    textHeight = textItem.get("height");
                }
                if ((toUpdate.height - this.props.textOffset > textHeight || this.props.textStatus.get("textGroup") === true) && !shouldSave) {
                    let fontSize = parseFloat(this.getFontSize({ id: this.props.textStatus.get("id"), width: textWidth, height: textHeight }), 10) / this.props.zoomFactor;
                    fontSize = fontSize.toFixed(2);
                    document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].style.fontSize = (fontSize * this.props.zoomFactor).toFixed(2) + "px";
                    toUpdate.textData.formats.containerStyle.fontSize = fontSize + "px";
                }


                let innerHeight = document.getElementById(this.props.textStatus.get("id")).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;
                toUpdate.width = textItem.get("width");
                toUpdate.height = textItem.get("height");

                toUpdate.yRatio = (((textItem.get("height") / 2) - innerHeight / 2) / textItem.get("height")) * 100;
                toUpdate.heightRatio = (innerHeight / textItem.get("height")) * 100;

            }

            if (this.props.textStatus.get("container") === "workspaceItems" || this.props.textStatus.get("type") === "SHAPE" || this.props.textStatus.get("textGroup") === true) {
                // this.props.writeText({selectedItem : this.props.textStatus.get("id"), container : this.props.textStatus.get("container"), toUpdate : toUpdate, isTyping : !shouldSave},this.props.socket);
            }

            if (this.props.textStatus.get("type") === "SHAPE") {
                let xRatio = this.props.selectedTextData.get("xRatio") !== undefined ? this.props.selectedTextData.get("xRatio") : 5;
                let widthRatio = this.props.selectedTextData.get("widthRatio") !== undefined ? this.props.selectedTextData.get("widthRatio") : 90;

                let textX = ((xRatio * toUpdate.width) / 100) * this.props.zoomFactor;
                let textY = ((toUpdate.yRatio * toUpdate.height) / 100) * this.props.zoomFactor;

                let textWidth = ((widthRatio * toUpdate.width) / 100) * this.props.zoomFactor;
                let textHeight = ((toUpdate.heightRatio * toUpdate.height) / 100) * this.props.zoomFactor;

                let callOutTextElm = textElement.getElementsByClassName("callout-text-container")[0];
                if (callOutTextElm) {
                    callOutTextElm.style.transform = `translate(${textX}px,${textY}px) ${flip}`
                    callOutTextElm.style.width = textWidth + 'px'
                    callOutTextElm.style.height = textHeight + 'px'
                }
            }
            if (this.props.textStatus.get("container") === "workspaceItems") {
                document.getElementsByClassName('selectionBox')[0].style.width = `${toUpdate.width * this.props.zoomFactor}px`
                document.getElementsByClassName('selectionBox')[0].style.height = `${toUpdate.height * this.props.zoomFactor}px`
                textElement.style.width = `${toUpdate.width * this.props.zoomFactor}px`
                textElement.style.height = `${toUpdate.height * this.props.zoomFactor}px`
            }
        }
        // ignore if same lineHeight is chosen
        if (lineHeight.toFixed(2) === this.state.lineHeight.toFixed(2)) return;

        this.setState({ lineHeight });
    }

    lhMouseUp() {
        this.fontSettingsChanged("lineHeight", this.state.lineHeight);
    }

    handleChangeFontSize = (action) => {
        let minValue = 6, maxValue = 999;
        if (this.inputFontSizeElm && this.inputFontSizeElm.current) {
            let value = this.getFontSizeValue();
            value = Math.round(value); // empty string returns 0
            let updateValue = minValue;
            if (value !== "" && parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                if (action === 'INCREASE' && parseFloat(value) !== maxValue) {
                    updateValue = value + 1;
                } else if (action === 'DECREASE' && parseFloat(value) !== minValue) {
                    updateValue = value - 1;
                }
                if ((action === 'INCREASE' && parseFloat(value) === maxValue) || (action === 'DECREASE' && parseFloat(value) === minValue)) {
                    updateValue = parseFloat(value);
                }
            } else if (value === "" && parseFloat(value) < minValue && parseFloat(value) > maxValue) {
                if (action === 'INCREASE' && parseFloat(value) < minValue) {
                    updateValue = minValue;
                } else if (action === 'DECREASE' && parseFloat(value) > maxValue) {
                    updateValue = maxValue;
                }
            }
            this.inputFontSizeElm.current.value = updateValue;
            this.handleFontsizeChange()
        }
    }

    handleTextSettingsApplyAll = () => {
        try {
            const textApplyAllData = [];

            // this.props.confirmPopup({ show: true, width: '348px', height: '284px', textSettingsApplyAll: true, message: "txt.apply.all.conf", okAction: { type: "UPDATE_ALL_TEXTS", socket: this.props.socket }, cancelAction: { type: "CONFIRM_POPUP", data: { show: false, applyAllCancel: true } }, formatId: 'msg' });

            let { workspaceItems } = this.props;

            workspaceItems.entrySeq().forEach(([key, value]) => {
                let isText = value.get('type') === 'TEXT';
                let isApplicable = isText && (key !== this.props.selectedItems.get(0)) && !value.get('isLocked');

                if (isApplicable) {
                    let currentText = (this.props.textStatus.get("container") === "workspaceItems") ? this.props.selectedObjects.get(this.props.textStatus.get("id")) : this.props.selectedChildren.get(this.props.textStatus.get("id"));
                    let toUpdate = { id: key, container: this.props.textStatus.get("container"), isGrouped: this.props.textStatus.get("isGrouped"), fontSizeScale: 1 }, fontFamily;

                    // current font data retrieved
                    let fontData = this.fontFamilies.getIn(["default", "fonts"]).find(x => x.get("fontFamily") === this.activeFontName);
                    if (!fontData && this.props.userFonts) { // if font data is not present in default, check in userFonts - fontFamily
                        fontData = this.props.userFonts.find(x => {
                            return ((x.get("fontFamily") === this.activeFontName.replace(/^[\"]|[\"]$/g, "")) ||
                                (x.get("fontname") === this.activeFontName.replace(/^[\"]|[\"]$/g, "")))
                        });
                    }

                    let textData = currentText.get('textData');

                    toUpdate.textData = textData.setIn(["formats", "others", "family"], fontData.get("uploadsrc") === "admin" ? fontData.get("name") : fontData.get("fontFamily"));

                    if (fontData.get("cssUrl") !== undefined) {
                        fontFamily = fontData.get('uploadsrc') === "user" ? fontData.get("fontFamily") : fontData.get("name");
                        toUpdate.value = fontData.get('fontFamily')

                        toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isUserFont"], fontData.get("uploadsrc") === "user").setIn(["formats", "others", "cssUrl"], fontData.get('uploadsrc') === "user" ? fontData.get("cssUrl").slice(fontData.get("cssUrl").indexOf(this.userFontUrl) + this.userFontUrl.length) : fontFamily);

                        if (fontData.get("isItalic") && fontData.get("isBold"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                        else if (fontData.get("isBold"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                        else if (fontData.get("isItalic"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                        else
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);
                    }
                    else {
                        fontFamily = fontData.get("fontFamily");
                        toUpdate.value = fontData.get('regular');

                        if (fontData.get("isItalic") && fontData.get("isBold"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], true);
                        else if (fontData.get("isBold"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], true);
                        else if (fontData.get("isItalic"))
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], true).setIn(["formats", "others", "isBold"], false);
                        else
                            toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isItalic"], false).setIn(["formats", "others", "isBold"], false);

                        toUpdate.textData = toUpdate.textData.setIn(["formats", "others", "isUserFont"], fontData.get("uploadsrc") === "user")
                    }

                    toUpdate.fontFamily = fontFamily;

                    toUpdate.lineHeight = toUpdate.textData.getIn(["formats", "containerStyle", "lineHeight"]);

                    // font size handling starts
                    let sizeValue = this.state.fontSize + 'px';

                    let prevContFontSize = document.getElementById(key).getElementsByClassName('text-container')[0].style.fontSize;

                    document.getElementById(key).getElementsByClassName('text-container')[0].style.fontSize = (this.state.fontSize * this.props.zoomFactor).toFixed(2) + "px";

                    let sWidth = document.getElementById(key).getElementsByClassName('text-container')[0].offsetWidth / this.props.zoomFactor;

                    let sHeight = document.getElementById(key).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;

                    document.getElementById(key).getElementsByClassName('text-container')[0].style.fontSize = prevContFontSize;

                    if (value.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined) {
                        sWidth += this.props.textOffset;
                        sHeight += this.props.textOffset;
                    }
                    // font size handling ends

                    // text root manipulation
                    let currTextRoot = document.querySelector(`#${key} .text-container`);
                    let prevInnerHtml = currTextRoot.innerHTML;
                    this.cleanText(currTextRoot);

                    // font style node handling starts

                    // bold setting handling
                    let shouldRemoveBold = !this.isBoldActive;

                    this.walkDOM(currTextRoot, node => {
                        const styledAncestor = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                        const selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id")) || this.props.selectedObjects.get(this.props.textStatus.get("id"));
                        const hasUserFont = styledAncestor ? styledAncestor.getAttribute(this.USER_FONT_ATTRIBUTE) === "true" : selectedText.getIn(["textData", "formats", "others", "isUserFont"]);

                        if (!hasUserFont) {
                            this.applyStyle(node, "bold", shouldRemoveBold);
                        }
                    })
                    //

                    // italic setting handling
                    let shouldRemoveItalic = !this.isItalicActive;

                    this.walkDOM(currTextRoot, node => {
                        const styledAncestor = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}]`);
                        const selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id")) || this.props.selectedObjects.get(this.props.textStatus.get("id"));
                        const hasUserFont = styledAncestor ? styledAncestor.getAttribute(this.USER_FONT_ATTRIBUTE) === "true" : selectedText.getIn(["textData", "formats", "others", "isUserFont"]);

                        if (!hasUserFont) {
                            this.applyStyle(node, "italic", shouldRemoveItalic);
                        }
                    })
                    //

                    // underline setting handling
                    this.removeStyle('textDecoration', key);
                    let prevUnderlineSetting = document.getElementById(key).getElementsByClassName("text-container")[0].style['textDecoration'];
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textDecoration'] = this.isUnderlineActive ? 'underline' : null;
                    //

                    // textAlign handling
                    this.removeStyle('textAlign', key);
                    let prevTxtAlignSetting = document.getElementById(key).getElementsByClassName("text-container")[0].style['textAlign'];
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textAlign'] = this.textAlign;
                    //

                    // text transform handling
                    this.removeStyle('textTransform', key);
                    let prevTxtTransformSetting = document.getElementById(key).getElementsByClassName("text-container")[0].style['textTransform'];
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textTransform'] = this.textTransform;
                    //

                    // text direction handling
                    this.removeStyle('textDirection', key);
                    let prevTextDirSetting = document.getElementById(key).getElementsByClassName("text-container")[0].style['textDirection'];
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textDirection'] = !this.getNextRTL();
                    //

                    // letter spacing handling
                    this.removeStyle('letterSpacing', key);
                    let prevLsSetting = document.getElementById(key).getElementsByClassName("text-container")[0].style['letterSpacing'];
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['letterSpacing'] = this.state.letterSpace + "em";
                    //

                    // font style node handling ends

                    this.walkDOM(currTextRoot, (node) => {
                        const styledElement = node.parentNode.closest(`[${this.FONT_ATTRIBUTE}="${fontData.get("fontFamily")}"]`);

                        if (!(styledElement && currTextRoot.contains(styledElement))) {

                            this.applyFont(node, fontData, (toUpdate.textData.getIn(["formats", "others", "isUserFont"]) && (fontData.get('uploadsrc') === "user")), currTextRoot);
                        }
                    })

                    currTextRoot.querySelectorAll(`[${this.FONT_ATTRIBUTE}]`).forEach(node => {
                        node.style.lineHeight = toUpdate.lineHeight;
                    })

                    // bullet setting handling starts here
                    let tempHtml = currTextRoot.innerHTML, finalStr;
                    let newBulletType = this.props.textOptions.getIn(["bullet", "value"]);

                    if (tempHtml.includes('<li>')) { // OL or UL type
                        let isMultiLine = tempHtml.match(/<li>/ig).length > 1;
                        if (tempHtml.includes('<ol>')) {// OL type
                            if (newBulletType === 'unOrderedList') {
                                tempHtml = tempHtml.replace('<ol>', '<ul>');
                                tempHtml = tempHtml.replace('</ol>', '</ul>');
                            } else if (newBulletType === 'none') {
                                tempHtml = tempHtml.replace('<ol>', '');
                                tempHtml = tempHtml.replace('</ol>', '');
                                if (isMultiLine) {
                                    // t2 - multi OL to NONE

                                    finalStr = "";

                                    let splitArr = tempHtml.split('<li>');
                                    splitArr.forEach(ele => {
                                        if (ele.length !== 0) {
                                            ele = ele.replace('</li>', '<br>');
                                            finalStr += ele;
                                        }
                                    });

                                    tempHtml = finalStr;

                                } else {
                                    // t1 - single OL to NONE

                                    tempHtml = tempHtml.replace('<li>', '');
                                    tempHtml = tempHtml.replace('</li>', '');
                                }
                            }
                        } else if (tempHtml.includes('<ul>')) {// UL type 
                            if (newBulletType === 'orderedList') {
                                tempHtml = tempHtml.replace('<ul>', '<ol>');
                                tempHtml = tempHtml.replace('</ul>', '</ol>');
                            } else if (newBulletType === 'none') {
                                tempHtml = tempHtml.replace('<ul>', '');
                                tempHtml = tempHtml.replace('</ul>', '');
                                if (isMultiLine) {
                                    // t4 - multi UL to NONE

                                    finalStr = "";

                                    let splitArr = tempHtml.split('<li>');
                                    splitArr.forEach(ele => {
                                        if (ele.length !== 0) {
                                            ele = ele.replace('</li>', '<br>');
                                            finalStr += ele;
                                        }
                                    });

                                    tempHtml = finalStr;

                                } else {
                                    // t3 - single UL to NONE

                                    tempHtml = tempHtml.replace('<li>', '');
                                    tempHtml = tempHtml.replace('</li>', '');
                                }
                            }
                        }
                    } else {// NORMAL type
                        let isMultiLine = false;
                        if (tempHtml.includes('<div><br></div>')) {
                            tempHtml = tempHtml.replaceAll('<div><br></div>', '');
                        }
                        if (tempHtml.includes('<div>')) {
                            let divSplitArr = tempHtml.split('<div>');
                            let eleCount = 0;
                            divSplitArr.forEach(ele => {
                                if (ele.length !== 0) {
                                    eleCount += 1;
                                }
                            });
                            isMultiLine = eleCount > 1;
                        } else if (tempHtml.includes('<br>')) {
                            let brSplitArr = tempHtml.split('<br>');
                            let eleCount = 0;
                            brSplitArr.forEach(ele => {
                                if (ele.length !== 0) {
                                    eleCount += 1;
                                }
                            });
                            isMultiLine = eleCount > 1;
                        }

                        if (isMultiLine) {

                            finalStr = "";

                            if (tempHtml.includes('<br>')) {
                                let brSplitArr = tempHtml.split('<br>');
                                brSplitArr.forEach(ele => {
                                    if (ele.length !== 0) {
                                        if (ele.includes('<div>')) {
                                            let divSplitArr = ele.split('<div>');
                                            divSplitArr.forEach(elem => {
                                                if (elem.length !== 0) {
                                                    if (elem.includes('</div>'))
                                                        elem = elem.replace('</div>', '');

                                                    let liWrapEle = "<li>" + elem + "</li>";
                                                    finalStr += liWrapEle;
                                                }
                                            });
                                        } else {
                                            let liWrapEle = "<li>" + ele + "</li>";
                                            finalStr += liWrapEle;
                                        }
                                    }
                                });
                            } else if (tempHtml.includes('<div>')) {
                                let divSplitArr = tempHtml.split('<div>');
                                divSplitArr.forEach(ele => {
                                    if (ele.length !== 0) {
                                        if (ele.includes('</div>'))
                                            ele = ele.replace('</div>', '');

                                        let liWrapEle = "<li>" + ele + "</li>";
                                        finalStr += liWrapEle;
                                    }
                                });
                            }

                            if (newBulletType === 'orderedList') {
                                // t7 - multi Normal to OL

                                finalStr = "<ol>" + finalStr + "</ol>";
                                tempHtml = finalStr;

                            } else if (newBulletType === 'unOrderedList') {
                                // t8 - multi Normal to UL

                                finalStr = "<ul>" + finalStr + "</ul>";
                                tempHtml = finalStr;
                            }
                        } else if (newBulletType === 'orderedList') {
                            // t5 - single Normal to OL

                            tempHtml = tempHtml.replaceAll('<br>', '');
                            tempHtml = "<ol><li>" + tempHtml + "</li></ol>";
                        } else if (newBulletType === 'unOrderedList') {
                            // t6 - single Normal to UL

                            tempHtml = tempHtml.replaceAll('<br>', '');
                            tempHtml = "<ul><li>" + tempHtml + "</li></ul>";
                        }
                    }

                    currTextRoot.innerHTML = tempHtml;
                    // bullet setting handling ends here

                    this.flattenNodes(currTextRoot);
                    this.normalizeDOM(currTextRoot);
                    this.removeEmptyNodes(currTextRoot);

                    toUpdate.htmlText = currTextRoot.innerHTML;

                    let fWidth = document.getElementById(key).getElementsByClassName('text-container')[0].offsetWidth / this.props.zoomFactor;

                    let fHeight = document.getElementById(key).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;

                    toUpdate.fWidth = fWidth;
                    toUpdate.fHeight = fHeight;
                    // text root manipulation ends

                    // font size manipulation again, to calculate cumulative width and height
                    document.getElementById(key).getElementsByClassName('text-container')[0].style.fontSize = (this.state.fontSize * this.props.zoomFactor).toFixed(2) + "px";

                    let cWidth = document.getElementById(key).getElementsByClassName('text-container')[0].offsetWidth / this.props.zoomFactor;

                    let cHeight = document.getElementById(key).getElementsByClassName('text-container')[0].offsetHeight / this.props.zoomFactor;

                    document.getElementById(key).getElementsByClassName('text-container')[0].style.fontSize = prevContFontSize;

                    if (value.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined) {
                        cWidth += this.props.textOffset;
                        cHeight += this.props.textOffset;
                    }
                    // font size manipulation, and cumulative width & height calculation ends

                    // cumulative params handling
                    toUpdate.cWidth = cWidth;
                    toUpdate.cHeight = cHeight;
                    //

                    // revert handling for some settings (as we're yet to confirm from the user)
                    // text root revert - reverts the font family, bold and italic settings
                    currTextRoot.innerHTML = prevInnerHtml;
                    //
                    // underline revert
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textDecoration'] = prevUnderlineSetting;
                    //
                    // text align revert
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textAlign'] = prevTxtAlignSetting;
                    //
                    // text transform revert
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textTransform'] = prevTxtTransformSetting;
                    //
                    // text direction revert
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['textDirection'] = prevTextDirSetting;
                    //
                    // letter space setting revert
                    document.getElementById(key).getElementsByClassName("text-container")[0].style['letterSpacing'] = prevLsSetting;
                    //
                    //

                    // font size updation done
                    textApplyAllData.push({
                        id: key,
                        property: 'fontSize',
                        value: sizeValue,
                        container: this.props.textStatus.get("container"),
                        fontSizeScale: 1,
                        isGrouped: this.props.textStatus.get("isGrouped"),
                        sWidth, sHeight,
                        cWidth, cHeight
                    });

                    // font family updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'fontFamily' });

                    // underline setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'textDecoration', value: this.isUnderlineActive ? 'underline' : null });

                    // bullet setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'bullet', value: this.props.textOptions.getIn(["bullet", "value"]) });

                    // textAlign setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'textAlign', value: this.textAlign });

                    // textTransform setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'textTransform', value: this.textTransform });

                    // textDirection setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'textDirection', value: !this.getNextRTL() });

                    // letterSpacing setting updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'letterSpacing', value: this.state.letterSpace + 'em' });

                    // text effects updation done
                    textApplyAllData.push({ ...toUpdate, id: key, container: this.props.textStatus.get("container"), property: 'textEffects' });
                }
            });

            if (textApplyAllData.length) {
                this.props.updateTextApplyAllData(textApplyAllData);
                this.setState({ isApplyAllOpen: true });
            }
        } catch (e) { }
    }

    cancelTextApplyAll = () => {
        this.setState({ isApplyAllOpen: false });
        this.props.updateTextApplyAllData([]);
        this.props.updateTextApplyAllOptions({ reset: true });
    }

    onTextApplyAll = () => {
        this.setState({ isApplyAllOpen: false });
        this.props.updateAllTexts();
    }

    render() {
        let settings = null;
        const { theme } = this.props

        if (this.props.isTextColor) {
            let currentColor = "#000000";
            if (this.state.multiColors && this.state.multiColors.length > 0) {
                currentColor = this.state.multiColors[0];
            } else if (this.state.containerColor) {
                currentColor = this.state.containerColor;
            }

            settings = (
                <ColorPaletteWrapper>
                    <ColorPalette
                        type="TEXT"
                        itemId={this.props.textStatus.get("id")}
                        currentColor={currentColor}
                        colorSelected={this.colorSelected}
                        showTitle={true}
                    />
                </ColorPaletteWrapper>
            );
        } else {
            let fontSizeList = null;

            let selectedText;
            if (this.props.textStatus.get("container") === "workspaceChildren")
                selectedText = this.props.selectedChildren.get(this.props.textStatus.get("id"));
            else
                selectedText = this.props.selectedObjects.get(this.props.textStatus.get("id"));

            let isApplyAllEnabled = this.props.textStatus.get("container") === 'workspaceItems' && selectedText && selectedText.get('type') === 'TEXT';

            let { activeNode } = this.state;
            if (!window.getSelection().anchorNode && !activeNode && this.textRoot.firstChild && this.textRoot.firstChild.nodeType === Node.ELEMENT_NODE) {
                activeNode = this.textRoot.firstChild.getAttribute(this.FONT_ATTRIBUTE) ? this.textRoot.firstChild : this.textRoot.firstChild.querySelector(`[${this.FONT_ATTRIBUTE}]`);
            }
            let activeFontName = activeNode && activeNode.getAttribute(this.FONT_ATTRIBUTE) ? activeNode.getAttribute(this.FONT_ATTRIBUTE) : selectedText.getIn(["textData", "formats", "others", "family"]) && !selectedText.getIn(["textData", "formats", "others", "isUserFont"]) ? selectedText.getIn(["textData", "formats", "others", "family"]) : selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]);

            let activeFontFamily = activeNode ? activeNode.style.fontFamily : selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]);
            if (!activeNode) {
                let { selectedRange } = this.state;
                if (selectedRange) {
                    let { commonAncestorContainer } = selectedRange
                    let parentNode = (commonAncestorContainer && commonAncestorContainer.nodeType === Node.TEXT_NODE) ? commonAncestorContainer.parentNode : commonAncestorContainer;
                    if (parentNode.nodeName === 'FONT') {
                        let fontFace = parentNode.face;
                        if (fontFace) {
                            activeFontFamily = fontFace;
                            activeFontName = fontFace.split(',')[0]
                        }
                    }
                }
            }

            this.activeFontName = activeFontName;
            this.activeFontFamily = activeFontFamily;

            const fontData = this.fontFamilies.getIn(["default", "fonts"]).find(x => x.get("fontFamily") === activeFontName);

            if (this.state.fontSizeFocused && this.state.fontSizeContainerBounds) {
                fontSizeList = (
                    <FontSizeList
                        className="font-size-list"
                        open={this.state.fontSizeFocused}
                        onOutsideMouseDown={this.toggleSizes}
                        style={{
                            left: this.state.fontSizeContainerBounds.left + "px",
                            top: this.state.fontSizeContainerBounds.bottom + 4 + "px",
                        }}
                    >
                        <ul>
                            {
                                this.fontSizes.map((item) => {
                                    return (
                                        <li
                                            key={item}
                                            onClick={(e) => { this.handleFontSizeClicked(e, item) }}
                                        >
                                            {item}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </FontSizeList>
                );
            }

            let textTransform = (this.props.textOptions.getIn(["textTransform", "value"]) !== null) ? this.props.textOptions.getIn(["textTransform", "value"]) : "none";
            let textAlign = (this.props.textOptions.getIn(["textAlign", "value"]) !== null) ? this.props.textOptions.getIn(["textAlign", "value"]) : !this.props.textStatus.get("isRTL") ? "left" : "right";

            let textDecoration = this.props.textOptions.getIn(["textDecoration", "value"]) === null ? "underline" : null;

            let isBoldActive = this.props.textOptions.getIn(["fontWeight", "isActive"]) !== undefined ? this.props.textOptions.getIn(["fontWeight", "isActive"]) : false;
            let isBoldDisabled = false, isItalicDisabled = false;
            let isItalicActive = this.props.textOptions.getIn(["fontStyle", "isActive"]) !== undefined ? this.props.textOptions.getIn(["fontStyle", "isActive"]) : false;
            let isUnderlineActive = this.props.textOptions.getIn(["textDecoration", "isActive"]) !== undefined ? this.props.textOptions.getIn(["textDecoration", "isActive"]) : false;
            let istextAlignActive = this.props.textOptions.getIn(["textAlign", "isActive"]) !== undefined ? this.props.textOptions.getIn(["textAlign", "isActive"]) : false;
            let isTextTransformActive = this.props.textOptions.getIn(["textTransform", "isActive"]) !== undefined ? this.props.textOptions.getIn(["textTransform", "isActive"]) : false;

            if (!isBoldActive) {
                if ((activeNode && activeNode.getAttribute(this.USER_FONT_ATTRIBUTE) === "false") || !selectedText.getIn(["textData", "formats", "others", "isUserFont"])) {
                    isBoldActive = false;
                    if (fontData && !fontData.get("isBold"))
                        isBoldDisabled = true;
                    else {

                        if (fontData !== undefined && (fontData.get("bold") === selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]) || fontData.get("boldItalic") === selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]))) {
                            isBoldActive = true;
                        }

                        if (activeNode && activeNode.nodeType === Node.ELEMENT_NODE && activeNode.getAttribute(this.STYLE_ATTRIBUTE)) {
                            isBoldActive = activeNode.getAttribute(this.STYLE_ATTRIBUTE).indexOf("bold") >= 0;
                        }
                    }
                } else {
                    isBoldDisabled = true;
                }
            }

            if (!isItalicActive) {
                if ((activeNode && activeNode.getAttribute(this.USER_FONT_ATTRIBUTE) === "false") || !selectedText.getIn(["textData", "formats", "others", "isUserFont"])) {
                    isItalicActive = false;
                    if (fontData && !fontData.get("isItalic"))
                        isItalicDisabled = true;
                    else {

                        if (fontData !== undefined && (fontData.get("italic") === selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]) || fontData.get("boldItalic") === selectedText.getIn(["textData", "formats", "containerStyle", "fontFamily"]))) {
                            isItalicActive = true;
                        }

                        if (activeNode && activeNode.nodeType === Node.ELEMENT_NODE && activeNode.getAttribute(this.STYLE_ATTRIBUTE)) {
                            isItalicActive = activeNode.getAttribute(this.STYLE_ATTRIBUTE).toLowerCase().indexOf("italic") >= 0;
                        }
                    }
                } else {
                    isItalicDisabled = true;
                }
            }

            let nextRTL = this.getNextRTL();

            this.isBoldActive = isBoldActive;
            this.isItalicActive = isItalicActive;
            this.isUnderlineActive = isUnderlineActive;
            this.textAlign = textAlign;
            this.textTransform = textTransform;

            let bulletActiveSrc;
            if (this.props.textOptions.getIn(["bullet", "value"]) === "orderedList") {
                bulletActiveSrc = `${vmTheme[this.props.theme].icons.orderedList}`;
            } else if (this.props.textOptions.getIn(["bullet", "value"]) === "unOrderedList") {
                bulletActiveSrc = `${vmTheme[this.props.theme].icons.unorderedList}`;
            }
            let isBulletIconActive = (
                this.props.textOptions.getIn(["bullet", "value"]) !== "none"
                && this.props.textOptions.getIn(["bullet", "value"]) !== "disc"
            );

            settings = (
                <TextSettingsContainer>
                    {
                        isApplyAllEnabled && (
                            <>
                                <ApplyAll onClick={this.handleTextSettingsApplyAll}>
                                    <FormattedMessage id={content.APPLY_TO_ALL_TEXTS} />
                                </ApplyAll>
                                <TextApplyAll
                                    applyAllOpen={this.state.isApplyAllOpen}
                                    onApplyAllCancel={this.cancelTextApplyAll}
                                    onApplyAll={this.onTextApplyAll}
                                />
                            </>
                        )
                    }
                    <div className="font-family-size-container">
                        <CustomTooltipComponent
                            tooltipId={"tt-txtsettings-ff"}
                            dataTooltip={content.FONT}
                            tooltipPosition="bottom"
                        >
                            <div
                                data-tooltip-id={"tt-txtsettings-ff"}
                                onClick={this.toggleFontFamilyWindow}
                                className="font-family-container"
                                style={{ fontFamily: activeFontFamily }}
                            >
                                <span className="font-family-name">{activeFontName && activeFontName.replace(/^[\"]|[\"]$/g, "")}</span>
                                <div className={`font-family-toggle ${this.state.fontIsLoading ? "font-loading" : ""}`}>
                                    <img
                                        className="fontname-loader"
                                        src={STATIC_PATH + "chr-loading.gif"}
                                        alt="font-name-loader"
                                    />
                                    <img
                                        className="font-family-toggle-icon"
                                        alt="font-family-toggle"
                                        src={STATIC_PATH + "text-property/font-family-toggle.svg"}
                                    />
                                </div>
                            </div>
                        </CustomTooltipComponent>
                        <div
                            ref={this.fontSizeContainerRef}
                            className={"font-size-container"}
                        >
                            <CustomTooltipComponent
                                tooltipId={"tt-txtsettings-fsize"}
                                dataTooltip={content.FONT_SIZE}
                                tooltipPosition="bottom"
                            >
                                <div
                                    className="font-size"
                                    data-tooltip-id={"tt-txtsettings-fsize"}
                                >
                                    <NumberInput
                                        inputRef={this.inputFontSizeElm}
                                        min={6}
                                        max={999}
                                        step={1}
                                        value={Math.round(this.state.fontSize)}
                                        precision={1}
                                        onChange={this.handleFontsizeChange}
                                        onFocus={this.handleFontSizeFocus}
                                        onBlur={this.handleFontSizeBlur}
                                        suffix=""
                                        saveOnUnmount={false}
                                    />
                                    <div
                                        className="font-size-btn"
                                        onClick={() => this.handleChangeFontSize("INCREASE")}
                                    >
                                        <img
                                            className="font-size-increase"
                                            alt="font-size-increase"
                                            src={STATIC_PATH + "text-property/font-size-increase.svg"}
                                        />
                                    </div>
                                    <div
                                        className="font-size-btn"
                                        onClick={() => this.handleChangeFontSize("DECREASE")}
                                    >
                                        <img
                                            className="font-size-decrease"
                                            alt="font-size-decrease"
                                            src={STATIC_PATH + "text-property/font-size-decrease.svg"}
                                        />
                                    </div>
                                </div>
                            </CustomTooltipComponent>
                            {fontSizeList}
                        </div>
                    </div>
                    <div className="text-formats-container">
                        <FontStyleIcon
                            iconType={"bold"}
                            tooltipLangId={content.BOLD}
                            tooltipPosition="bottom"
                            isActive={isBoldActive}
                            isDisabled={isBoldDisabled}
                            src={`${vmTheme[theme].icons.boldIcon}`}
                            hoverSrc={`${vmTheme[theme].icons.boldHoverIcon}`}
                            activeSrc={`${vmTheme[theme].icons.boldHoverIcon}`}
                            onClick={() => {
                                if (isBoldDisabled)
                                    return;
                                this.setState({ isBoldLoading: true })
                                let val = this.isBoldActive ? null : 'bold'
                                this.checkFontFamilyLoadedPromise(this.props.activeFontName, val, this.isBoldActive, this.isItalicActive)
                                    .finally(() => {
                                        this.setState({ isBoldLoading: false })
                                        this.changeStyle("bold");
                                    })
                            }}
                            isLoading={this.state.isBoldLoading}
                            className={"bold has-hover-icon" + (isBoldActive ? " active" : "") + (isBoldDisabled ? " disabled" : "")}
                        />
                        <FontStyleIcon
                            iconType={"italic"}
                            tooltipLangId={content.ITALIC}
                            tooltipPosition="bottom"
                            isActive={isItalicActive}
                            isDisabled={isItalicDisabled}
                            src={`${vmTheme[theme].icons.italicIcon}`}
                            hoverSrc={`${vmTheme[theme].icons.italicHoverIcon}`}
                            activeSrc={`${vmTheme[theme].icons.italicHoverIcon}`}
                            onClick={() => {
                                if (isItalicDisabled)
                                    return;
                                this.setState({ isItalicLoading: true })
                                let val = this.isItalicActive ? null : 'italic';
                                this.checkFontFamilyLoadedPromise(this.props.activeFontName, val, this.isBoldActive, this.isItalicActive)
                                    .finally(() => {
                                        this.setState({ isItalicLoading: false })
                                        this.changeStyle("italic");
                                    })
                            }}
                            isLoading={this.state.isItalicLoading}
                            className={"italic has-hover-icon" + (isItalicActive ? " active" : "") + (isItalicDisabled ? " disabled" : "")}
                        />
                        <FontStyleIcon
                            iconType={"underline"}
                            tooltipLangId={content.UNDERLINE}
                            tooltipPosition="bottom"
                            isActive={isUnderlineActive}
                            src={`${vmTheme[theme].icons.underlineIcon}`}
                            hoverSrc={`${vmTheme[theme].icons.underlineHoverIcon}`}
                            activeSrc={`${vmTheme[theme].icons.underlineHoverIcon}`}
                            onClick={() => this.fontSettingsChanged("textDecoration", textDecoration)}
                            className={"underline has-hover-icon" + (isUnderlineActive ? " active" : "")}
                        />
                        <FontStyleIcon
                            useSrcFromProps={true}
                            iconType={"bullet"}
                            tooltipLangId={content.BULLET}
                            tooltipPosition="bottom"
                            isActive={isBulletIconActive}
                            src={`${vmTheme[theme].icons.listNoneIcon}`}
                            hoverSrc={`${vmTheme[theme].icons.listNoneHoverIcon}`}
                            activeSrc={bulletActiveSrc}
                            onClick={() => {
                                this.fontSettingsChanged("bullet", this.getNext(this.bulletOptions, this.props.textOptions.getIn(["bullet", "value"])))
                            }}
                            className={"bulletin has-hover-icon" + (isBulletIconActive ? " active" : "") + " " + this.props.textOptions.getIn(["bullet", "value"])}
                        />
                        <FontStyleIcon
                            useSrcFromProps={true}
                            tooltipPosition="bottom"
                            iconType={"align"}
                            tooltipLangId={content.ALIGNMENT}
                            isActive={istextAlignActive}
                            src={`${vmTheme[theme].icons.textLeftAlign}-${textAlign}.svg`}
                            hoverSrc={`${vmTheme[theme].icons.textLeftAlign}-${textAlign}.svg`}
                            activeSrc={`${vmTheme[theme].icons.textLeftAlign}-${textAlign}.svg`}
                            onClick={() => {
                                this.fontSettingsChanged("textAlign", this.getNext(this.textAlignments, textAlign));
                            }}
                            className={"align has-hover-icon" + (istextAlignActive ? " active" : "") + " " + textAlign}
                        />
                        <FontStyleIcon
                            useSrcFromProps={true}
                            iconType={"TT"}
                            tooltipLangId={content.TEXT_TRANSFORM}
                            tooltipPosition="bottom-right"
                            isActive={isTextTransformActive && textTransform !== "none"}
                            src={`${vmTheme[theme].icons.textTransformNone}`}
                            hoverSrc={`${vmTheme[theme].icons.capitalizeIcon}`}
                            activeSrc={vmTheme[theme].themeName === 'light' ? `light/${textTransform}.svg` : `material/vm-${textTransform}.svg`}
                            onClick={() => {
                                this.fontSettingsChanged("textTransform", this.getNext(this.textTransforms, textTransform));
                            }}
                            className={"text-transform has-hover-icon" + (isTextTransformActive ? " active" : "") + " " + textTransform}
                        />
                        <FontStyleIcon
                            useSrcFromProps={true}
                            iconType={"Dir"}
                            tooltipLangId={content.DIRECTION}
                            tooltipPosition="bottom"
                            src={!nextRTL ? `${vmTheme[theme].icons.rtoLIcon}` : `${vmTheme[theme].icons.ltoRIcon}`}
                            hoverSrc={!nextRTL ? `${vmTheme[theme].icons.rtoLIcon}` : `${vmTheme[theme].icons.ltoRIcon}`}
                            onClick={() => {
                                this.fontSettingsChanged("textDirection", nextRTL);
                            }}
                            className={"text-direction" + (!nextRTL ? " right" : " left")}
                        />
                    </div>
                    <div className="spacing-settings">
                        <div className="spacing-wrapper">
                            <span><FormattedMessage id={content.LETTER_SPACING} /></span>
                            <Slider
                                min={-0.2}
                                max={1}
                                inputMin={-0.2}
                                inputMax={1}
                                step={0.1}
                                value={this.state.letterSpace}
                                onChangeSliderValue={(e, value) => this.updateLS(value)}
                                onMouseUp={this.lsMouseUp}
                                border={"none"}
                                borderRadius={"3px"}
                                background={vmTheme[theme].veryLightGray}
                                margin={"0"}
                                height={"5px"}
                                thumb={{
                                    background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                                    border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                                    height: "16px",
                                    width: "16px",
                                    hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                                    hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                                }}
                                progressBackground={vmTheme[theme].secondaryBorderColor}
                                isChangeBg={true}
                                showValue={false}
                                showToolTip={true}
                                tooltipBottomOffset={"11px"}
                                doubleSide={false}
                            />
                        </div>
                        <div className="spacing-wrapper">
                            <span><FormattedMessage id={content.LINE_HEIGHT} /></span>
                            <Slider
                                min={this.state.minLineHeight}
                                max={ITEM_CONFIG.TEXT.limit.lineHeight.max}
                                inputMin={this.state.minLineHeight}
                                inputMax={ITEM_CONFIG.TEXT.limit.lineHeight.max}
                                step={0.1}
                                value={this.state.lineHeight}
                                onChangeSliderValue={(e, value) => this.updateLH(value)}
                                onMouseUp={this.lhMouseUp}
                                border={"none"}
                                borderRadius={"3px"}
                                background={vmTheme[theme].veryLightGray}
                                margin={"0"}
                                height={"5px"}
                                thumb={{
                                    background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                                    border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                                    height: "16px",
                                    width: "16px",
                                    hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                                    hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                                }}
                                progressBackground={vmTheme[theme].secondaryBorderColor}
                                isChangeBg={true}
                                showValue={false}
                                showToolTip={true}
                                tooltipBottomOffset={"11px"}
                                doubleSide={false}
                            />
                        </div>
                    </div>
                    {
                        this.state.isFontFamilyOpen && (
                            <FontFamilyProperty
                                closeFontFamilyWindow={this.toggleFontFamilyWindow}
                            />
                        )
                    }
                </TextSettingsContainer>
            );
        }
        return settings;
    }
}

TextSettingsComponent.propTypes = {
    selectedTextId: PropTypes.string,
    selectedTextData: PropTypes.object,
    textOptions: PropTypes.object,
    browserName: PropTypes.string,
    textStatus: PropTypes.object,
    activeFontName: PropTypes.string,
    activeFontFamily: PropTypes.string,
    updateActiveFontName: PropTypes.func,
    updateActiveFontFamily: PropTypes.func,
    selectedFontItem: PropTypes.object,
    shortcutName: PropTypes.string,
    selectedObjects: PropTypes.object,
    selectedChildren: PropTypes.object,
    isWorkspaceTextFocus: PropTypes.bool,
    updateText: PropTypes.func,
    updateTextApplyAllData: PropTypes.func,
    updateTextApplyAllOptions: PropTypes.func,
    updateAllTexts: PropTypes.func,
    updateGroupText: PropTypes.func,
    updateFontLoadStatus: PropTypes.func,
    zoomFactor: PropTypes.number,
    textOffset: PropTypes.number,
    selectedItems: PropTypes.object,
    workspaceWidth: PropTypes.number,
    writeText: PropTypes.func,
    updateTextStatus: PropTypes.func,
    setWorkspaceTextFocus: PropTypes.func,
    workspaceItems: PropTypes.object,
    userFonts: PropTypes.object,
    isTextColor: PropTypes.bool,
    theme: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
    theme: state.app.get('theme'),
    activeFontName: state.app.get('activeFontName'),
    activeFontFamily: state.app.get('activeFontFamily'),
    // copyToken: state.app.get('copyTextStyleToken'),
    // pasteToken: state.app.get('pasteTextStyleToken'),
    // copyDone: state.app.get('textStyleCopied'),
    selectedItems: state.app.get("selectedItems"),
    selectedFontItem: state.app.get("selectedFontItem"),
    selectedObjects: state.projectDetails.get("workspaceItems").filter((obj, key) => { return state.app.get("selectedItems").keyOf(key) !== undefined; }),
    selectedChildren: state.projectDetails.get("workspaceChildren"),
    workspaceItems: state.projectDetails.get("workspaceItems"),
    textStatus: state.app.get('textStatus'),
    textOptions: state.app.get('textOptions'),
    userFonts: state.app.get("userFonts"),
    zoomFactor: state.app.get("zoomFactor"),
    textOffset: (ownProps.selectedTextData.getIn(["textData", "formats", "containerStyle", "margin"]) === "0px" || !ownProps.selectedTextData.getIn(["textData", "formats", "containerStyle", "margin"])) ? state.app.get('textOffset') : 30,
    browserName: state.app.get('browserName'),
    isWorkspaceTextFocus: state.app.get('isWorkspaceTextFocus'),
    workspaceWidth: state.projectDetails.get("width"),
    isFontSizeApplyAll: state.app.get("isFontSizeApplyAll"),
});

const mapDispatchToProps = (dispatch) => ({
    updateActiveFontFamily: (data) => { dispatch(updateActiveFontFamily(data)) },
    updateActiveFontName: (data) => { dispatch(updateActiveFontName(data)) },
    updateFontLoadStatus: (data) => { dispatch(updateFontLoadStatus(data)) },
    updateText: (data) => { dispatch(updateText(data)) },
    updateAllTexts: () => dispatch(updateAllTexts()),
    updateTextApplyAllData: (data) => dispatch(updateTextApplyAllData(data)),
    updateTextApplyAllOptions: (data) => dispatch(updateTextApplyAllOptions(data)),
    writeText: (data) => dispatch(writeText(data)),
    fontLoaded: (data) => dispatch(fontLoaded(data)),
    updateGroupText: (data, socket) => dispatch(updateGroupText(data, socket)),
    updateTextStatus: (data) => { dispatch(updateTextStatus(data)) },
    setWorkspaceTextFocus: (data) => {
        dispatch(setWorkspaceTextFocus(data))
    },
});

const TextSettings = connect(mapStateToProps, mapDispatchToProps)(TextSettingsComponent);

export default TextSettings;
