import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderComponent,
  Container,
  ImageContainer,
  Button,
  TitleInput,
  IconContainer,
  // Icon,
  FileModal,
  FileContainer,
  List,
  ListItem,
  Profile,
  ProfileDetails,
  PlayIcon,
  IconSVG,
  // FlexContainer,
  SaveIcon,
  AutoSaveWrap,
  ResizeDropdown,
  ResizeDropdownLi,
  Icon,
} from "./header-components";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import content from "../../constants/content";
import Action from "../../common-components/Action";
import { STATIC_PATH, API_URL } from "../../constants/config";
import { Backdrop } from "../modal/modal-components";
import Export from "../modal/export";
import Zoom from "../zoom/zoom";
import UndoRedo from "./header-undoredo";
// import { setModalProps } from "../../redux/actions/modalAction";
import {
  setPlayAll,
  resizeVideo,
  duplicateProject,
  createNewProject,
  // setPropertyPanel,
  // setExpandPanel,
  setWorkspaceTextFocus,
} from "../../redux/actions/appUtils";
import { PROJECT_CONTAINERS, VIDEO_RESIZE_DETAILS } from "../../constants/index";
import Share from "./share/share";
import CustomTooltipComponent from "../../common-components/CustomTooltip";
import useScreenshot from "../../helper/hooks/useScreenshot";
import useMobileDetection from "../../helper/hooks/useMobileDetection";
import { useCustomCallback } from "../../helper/hooks/useCustomCallback";
import getAwsAccess, { uploadFile } from "../../helper/getAwsAccess";
import { updateProjectData } from "../../redux/actions/timelineUtils";
// import { saveProject } from "../../redux/actions/projectUtils";
import vmTheme from "../../constants/theme";
import useNotify from "../../helper/hooks/useNotify";
import { showUpgrade } from "../../redux/actions/modalAction";
import { isEnterPrise } from "../../helper/checkUser";
import SmartThumbnail from "./smartThumbnail"

const {
  DASHBOARD,
  CREATE_NEW_VIDEO,
  SMART_THUMBNAIL,
  MAKE_COPY,
  MY_EXPORTS,
  HELP,
  // SAVE,
  RESIZE,
  RENAME,
  PREVIEW,
  SAVING,
  SAVED,
  SHARE,
  EXPORT,
  // SHARE,
  // VERSION_HISTORY,
} = content;

const FileExpand = ({ setIsFileOpen, isShorts, handleSmartThumbnail }) => {
  const [userProfile, setUserProfile] = useState({ username: "", plan: "" });
  const dispatch = useDispatch();
  const userDetails = useSelector((store) => store.userDetails);
  const theme = useSelector((state) => state.app.get("theme"));
  const projectDetails = useSelector((store) => store.projectDetails);
  const width = projectDetails.get("width");
  const height = projectDetails.get("height");

  useEffect(() => {
    if (userDetails && userDetails.plan && userDetails.teams[userDetails.currentTeamId]) {
      setUserProfile({
        username: userDetails.teams[userDetails.currentTeamId],
        plan: userDetails.plan,
        profileImage: userDetails.profileimage
      });
    }
  }, [userDetails]);

  const resizeProject = useCallback(
    (name) => {
      resizeVideo(
        name,
        projectDetails,
        userDetails.projectId,
        projectDetails.get("duration"),
        dispatch
      );
    },
    [dispatch, projectDetails, userDetails]
  );

  // const ShowExpand = () => {
  //   dispatch(setPropertyPanel(VERSION_HISTORY));
  //   dispatch(setExpandPanel(true));
  //   setIsFileOpen(false);
  // };

  // const setModalDispatcher = (payload) => {
  //   dispatch(setModalProps(payload));
  // };

  const RESIZETHEME = VIDEO_RESIZE_DETAILS(theme);
  return (
    <FileModal className="file-modal-tool-bar" isShorts={isShorts}>
      {!isShorts && (
        <FileContainer>
          <Typography
            innerContent={projectDetails.get("name")}
            font={font.boldSmall_22}
            display="block"
          />
          <Typography
            innerContent={`${width}px x ${height}px`}
            font={font.normalMicro}
          />
        </FileContainer>
      )}
      <List>
        <ListItem>
          <Typography
            isAnchor={true}
            content={DASHBOARD}
            cursor="pointer"
            href={`${API_URL}dashboard`}
            target="_blank"
            display="block"
            padding="5px 0"
            color={vmTheme[theme].primaryColor}
          />
        </ListItem>
        <ListItem>
          <Typography
            content={CREATE_NEW_VIDEO}
            cursor="pointer"
            display="block"
            padding="5px 0"
            onClick={() => {
              setIsFileOpen(false);
              createNewProject(dispatch);
            }}
          />
        </ListItem>
        <ListItem>
          <Typography
            content={SMART_THUMBNAIL}
            cursor="pointer"
            display="block"
            padding="5px 0"
            onClick={() => {
              setIsFileOpen(false);
              handleSmartThumbnail()
            }}
          />
        </ListItem>
        {!isShorts && (
          <ListItem>
            <Typography
              content={RESIZE}
              cursor="pointer"
              display="block"
              padding="5px 0"
              className="resizeDropdown"
            />
            <ResizeDropdown className="resizeMenu">
              {Object.keys(RESIZETHEME).map((key) => {
                const isSelected = key === userDetails.dimensionName;
                return (
                  <ResizeDropdownLi key={key} isHovered={isSelected}>
                    <IconSVG
                      isSelected={isSelected}
                      dangerouslySetInnerHTML={{
                        __html: isSelected
                          ? RESIZETHEME[key].selectedSrc
                          : RESIZETHEME[key].src,
                      }}
                    />
                    <Typography
                      content={RESIZETHEME[key].title}
                      display="block"
                      color={vmTheme[theme].primaryColor}
                      padding="10px"
                      font={font.normalBase_21}
                      cursor="pointer"
                      onClick={() => {
                        setIsFileOpen(false);
                        resizeProject(key);
                      }}
                      isHovered={isSelected}
                      isSelected={key === userDetails.dimensionName}
                    />
                  </ResizeDropdownLi>
                );
              })}
            </ResizeDropdown>
          </ListItem>
        )}
      </List>
      <List>
        <ListItem>
          <Typography
            content={MAKE_COPY}
            cursor="pointer"
            display="block"
            padding="5px 0"
            onClick={() => {
              setIsFileOpen(false);
              duplicateProject(userDetails.projectId, dispatch);
            }}
          />
        </ListItem>
        <ListItem>
          <Typography
            content={MY_EXPORTS}
            cursor="pointer"
            isAnchor
            href={`${API_URL}manageexports`}
            target="_blank"
            display="block"
            padding="5px 0"
            color={vmTheme[theme].primaryColor}
          />
        </ListItem>
        {/* <ListItem>
          <Typography
            content={KEYBOARD_SHORTCUTS}
            cursor="pointer"
            display="block"
            padding="5px 0"
            onClick={() =>
              setModalDispatcher({
                isShowModal: true,
                isDisableDrag: false,
                child: <Keyboards setModal={setModalDispatcher} />,
              })
            }
          />
        </ListItem>  */}
        {/* <ListItem>
          <Typography
            content={VERSION_HISTORY}
            cursor="pointer"
            display="block"
            padding="5px 0"
            onClick={ShowExpand}
          />
        </ListItem> */}
      </List>
      <List>
        <ListItem>
          <Typography
            content={HELP}
            cursor="pointer"
            display="block"
            padding="5px 0"
            isAnchor
            href={`${API_URL}support`}
            target="_blank"
            color={vmTheme[theme].primaryColor}
          />
        </ListItem>
      </List>
      <FileContainer display="flex">
        <Profile
          src={
            userProfile.profileImage
              ? userProfile.profileImage
              : `${STATIC_PATH}profile-image.png`
          }
          alt={userProfile.username}
        />
        <ProfileDetails>
          <Typography
            innerContent={userProfile.username}
            font={font.boldBase}
            display="block"
            enableTrim={true}
            width={isShorts ? "110px" : "200px"}
          />
          <Typography
            innerContent={userProfile.plan}
            color={vmTheme[theme].planTextColor}
            font={font.normalMicroLarge}
          />
        </ProfileDetails>
      </FileContainer>
    </FileModal>
  );
};

FileExpand.propTypes = {
  setIsFileOpen: PropTypes.bool,
  isShorts: PropTypes.bool,
  handleSmartThumbnail: PropTypes.func
};

const Header = ({ shortcutName, isShorts }) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const isMobile = useMobileDetection();
  const { warn } = useNotify();

  const [title, setTitle] = useState("Vmaker Video recording");
  const [isShowModal, setIsShowModal] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isFirstWorkSpaceItem, setFirstWorkSpaceItem] = useState(false);

  const screenshot = useScreenshot({ forInit: true });
  const { canTakeInitialScreenshot, takeScreenshot } = screenshot;

  const userDetails = useSelector((store) => store.userDetails);
  const projectDetails = useSelector((store) => store.projectDetails);
  const appReducer = useSelector((state) => state.app);
  const isPlayAll = appReducer.get("isPlayAll");
  const theme = appReducer.get("theme");
  const isSaving = appReducer.get("isSaving");
  const [smartModal, setSmartModal] = useState(false);

  const { projectId, userId, permission } = userDetails;
  const _onScreenshotSuccess = useCallback(
    async (blob) => {
      try {
        const key = `a/u/${userId}/projThumbnail/${projectId}.png`;
        const awsAccess = await getAwsAccess(userId);
        await uploadFile(awsAccess, blob, key);

        dispatch(
          updateProjectData({
            thumbnail: `${userId}/projThumbnail/${projectId}.png`,
          })
        );
      } catch (error) { }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, userId]
  );

  const onScreenshotSuccess = useCustomCallback({
    memoizedCallback: _onScreenshotSuccess,
  });

  useEffect(() => {
    if (canTakeInitialScreenshot) {
      takeScreenshot({
        takeFirstVisibleItem: true,
        onScreenshotSuccess,
      });
    }
  }, [canTakeInitialScreenshot, takeScreenshot, onScreenshotSuccess]);

  useEffect(() => {
    if (isSaving) {
      setSaving(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setSaving(false);
      }, 3000);
    }
  }, [isSaving]);

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onBlur = useCallback(() => {
    dispatch(setWorkspaceTextFocus(false));
    dispatch(
      updateProjectData({
        name: title,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    if(!isFirstWorkSpaceItem && projectDetails.get("workspaceItems").size === 1 && !projectDetails.get("thumbnail")) {
      setFirstWorkSpaceItem(true);
      takeScreenshot({
        takeFirstVisibleItem: true,
        onScreenshotSuccess,
      });
    }
    setTitle(projectDetails.get("name"));
  }, [projectDetails]);

  const closeModal = () => setIsShowModal(false);

  const saveAction = () => {
    // saveProject(projectId, projectDetails).then(() => {
    //   // eslint-disable-next-line no-alert
    //   // alert(`Project data saved.`);
    // });
  };

  const toggleExportModal = async () => {
    // Don't allow export if file is inprogress
    const avoidExport = PROJECT_CONTAINERS.some((container) => {
      if (projectDetails.get(container)) {
        return projectDetails.get(container).some((item) => {
          return !!item.get("isBlob");
        });
      }
      return false;
    });

    if (avoidExport) {
      warn(content.UNABLE_TO_EXPORT);
    } else {
      const projectThumbnail = projectDetails.get("thumbnail");
      if (!projectThumbnail) {
        await takeScreenshot({
          takeFirstVisibleItem: true,
          onScreenshotSuccess,
        });
      }
      setIsShowModal(!isShowModal);
    }
  };

  const shareModalClose = useCallback(() => {
    setIsShare(false);
  }, []);

  const handlePlayClick = () => {
    if (appReducer.get("swapDetails")) {
      // block play while swapping.
      return;
    }
    if (!isPlayAll) {
      dispatch(setPlayAll(true, true));
    } else {
      dispatch(setPlayAll(false));
    }
  };

  const handleShortcuts = (shortcutKey) => {
    switch (shortcutKey) {
      case "SAVE_PROJECT":
        saveAction();
        break;
      case "EXPORT_PROJECT":
        toggleExportModal();
        break;
      default:
        break;
    }
  };

  const handleSmartThumbnail = async () => {
    setSmartModal(true);
  };

  useEffect(() => {
    if (shortcutName && shortcutName !== "") {
      handleShortcuts(shortcutName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutName]);

  const playIcon = isPlayAll
    ? vmTheme[theme].icons.headerPauseIcon
    : vmTheme[theme].icons.headerPlayIcon;

  return (
    <>
      <HeaderComponent
        $isPlayAll={isPlayAll}
        className="header-tool-bar"
        isShorts={isShorts}
      >
        <ImageContainer>
          <img
            src={`${STATIC_PATH}Vmaker-logo.svg`}
            height="40px"
            alt="Vmaker AI logo"
          />
        </ImageContainer>
        <Container>
          <Button onClick={() => setIsFileOpen(!isFileOpen)}>File</Button>
          {permission ? <UndoRedo shortcutName={shortcutName} /> : null}
          <CustomTooltipComponent
            tooltipId={"title-tooltip"}
            dataTooltip={RENAME}
            tooltipPosition="bottom"
          >
            <TitleInput
              className="title-input-tool-bar"
              onFocus={() => dispatch(setWorkspaceTextFocus(true))}
              onBlur={onBlur}
              value={title}
              onChange={handleTitleChange}
              data-tooltip-id={"title-tooltip"}
            />
          </CustomTooltipComponent>
        </Container>
        {!isShorts && <Zoom />}
        <Container className="header-last">
          {permission ? (
            <>
              <AutoSaveWrap>
                <CustomTooltipComponent
                  tooltipId={"save-tooltip"}
                  dataTooltip={saving ? SAVING : SAVED}
                  tooltipPosition="bottom"
                >
                  <SaveIcon
                    data-tooltip-id={"save-tooltip"}
                    isSaving={saving}
                  />
                </CustomTooltipComponent>
              </AutoSaveWrap>
              {isEnterPrise(userDetails.plan) ? null : <Action
                text={"Upgrade"}
                background={vmTheme[theme].rgbaWhite24}
                fontColor={vmTheme[theme].polarColor}
                customClass="upgrade--btn"
                font={font.mediumBase_21}
                height={"32px"}
                width={"98px"}
                marginBottom={"0px"}
                marginTop={"0px"}
                onClick={() => {
                  dispatch(showUpgrade({ upgradesource: 'Editor-header' }));
                }}
              />}
            </>
          ) : null}
          {permission ? (
            <IconContainer>
              {!isShorts && (
                <li onClick={handlePlayClick}>
                  <CustomTooltipComponent
                    tooltipId={"preview-tooltip"}
                    dataTooltip={PREVIEW}
                    tooltipPosition="bottom"
                  >
                    <PlayIcon
                      data-tooltip-id={"preview-tooltip"}
                      src={`${STATIC_PATH}${playIcon}`}
                      alt="timer-icon"
                    />
                  </CustomTooltipComponent>
                </li>
              )}

              <li
                data-tooltip-id={"share-tooltip"}
                onClick={() => {
                  setIsShare(!isShare);
                }}
              >
                <CustomTooltipComponent
                  tooltipId={"share-tooltip"}
                  dataTooltip={SHARE}
                  tooltipPosition="bottom"
                >
                  <Icon src={`${STATIC_PATH}share.svg`} alt="timer-icon" />
                </CustomTooltipComponent>
              </li>
            </IconContainer>
          ) : null}
          {!isMobile ? (
            <>
              {/* {ENVIRONMENT !== "PRODUCTION" && (
                <Action
                  background={vmTheme[theme].hoverBorderColor}
                  hover
                  borderRadius="5px"
                  fontColor={vmTheme[theme].polarColor}
                  width="90px"
                  height="30px"
                  margin="9px 16px"
                  text={SAVE}
                  font={font.boldMini}
                  onClick={saveAction}
                />
              )} */}
              {!isShorts && (
                <Action
                  background={vmTheme[theme].hoverBorderColor}
                  hover
                  borderRadius="5px"
                  fontColor={vmTheme[theme].polarColor}
                  width="90px"
                  height="30px"
                  margin="9px 16px"
                  text={EXPORT}
                  font={font.boldMini}
                  onClick={toggleExportModal}
                />
              )}
            </>
          ) : (
            !isShorts && (
              <IconContainer>
                <li
                  data-tooltip-id={"export-tooltip"}
                  onClick={toggleExportModal}
                >
                  <CustomTooltipComponent
                    tooltipId={"export-tooltip"}
                    dataTooltip={EXPORT}
                    tooltipPosition="bottom"
                  >
                    <img
                      src={`${STATIC_PATH}download-icon.svg`}
                      onClick={toggleExportModal}
                      height="20px"
                      width="20px"
                      alt="download-icon"
                    />
                  </CustomTooltipComponent>
                </li>
              </IconContainer>
            )
          )}
        </Container>
      </HeaderComponent>
      <Export
        modalHandler={closeModal}
        isShowModal={isShowModal}
        projectTitle={title}
        onCloseModal={() => setIsShowModal(false)}
      />
      {smartModal ? <SmartThumbnail
        isModalOpen={smartModal}
        setSmartModal={setSmartModal}
      /> : null}
      {isFileOpen && (
        <>
          <Backdrop
            className="header-backdrop-toolbar"
            onClick={() => setIsFileOpen(false)}
            zIndex={1}
          />
          <FileExpand
            handleSmartThumbnail={handleSmartThumbnail}
            setSmartModal={setSmartModal}
            title={title}
            isFileOpen={isFileOpen}
            setIsFileOpen={setIsFileOpen}
            isShorts={isShorts}
          />
        </>
      )}
      {isShare && <Share onClose={shareModalClose} isShorts={isShorts} />}
    </>
  );
};

Header.propTypes = {
  shortcutName: PropTypes.string,
  isShorts: PropTypes.bool,
};

export default Header;
