/* eslint-disable consistent-return */

import { PLAN } from "../constants";

/**
 * This function will get the plan array and returns the plan name.
 * @param {Array} plan array [1, 2, ...]
 * @returns Plan name.
 */
export const getPlanName = (plan) => {
  const planNumber = Math.min(...plan);

  switch (planNumber) {
    case 1:
      return PLAN.FREE;
    case 2:
    case 3:
      return PLAN.STARTER;
    case 4:
      return PLAN.TEAM;
    case 5:
      return PLAN.ENTERPRISE;
    default:
  }
};

/**
 * This Function will return the corresponding plan number.
 * @param {string} planName
 * @returns Plan number
 */
export const getPlanNum = (planName) => {
  switch (planName) {
    case PLAN.LITE:
    case PLAN.FREE:
      return 1;
    case PLAN.STARTER:
      return 3;
    case PLAN.TEAM:
      return 4;
    case PLAN.ENTERPRISE:
      return 5;
    default:
  }
};
