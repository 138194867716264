import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { hexToRGB } from '../../helper/getColorAtPercentage';

const getBase64Image = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
    });
};

const CanvasImage = ({
    className, style, src,
    isBackgroundRemoval,
    chromaKey,
    tolerance,
    videoRef
}) => {
    const c1 = isBackgroundRemoval ? videoRef : useRef(null);
    const c2 = videoRef;
    const [bgR, bgG, bgB] = hexToRGB(chromaKey || "");

    const selfieSegmentation = useRef(null);

    useEffect(() => {
        selfieSegmentation.current = new SelfieSegmentation({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
            }
        });
        selfieSegmentation.current.setOptions({
            modelSelection: 1,
        });
    }, []);

    const computeFrame = useCallback(() => {
        const ctx1 = c1.current.getContext("2d");
        const ctx2 = c2.current?.getContext("2d");
        if (!ctx1) return;

        const image = new Image();
        getBase64Image(src).then((res) => {
            image.onload = () => {
                c1.current.width = image.width;
                c1.current.height = image.height;
                if (ctx2) {
                    c2.current.width = image.width;
                    c2.current.height = image.height;
                }
                ctx1.drawImage(image, 0, 0, c1.current.width, c1.current.height);
                const frame = ctx1.getImageData(0, 0, c1.current.width, c1.current.height);
                const l = frame.data.length / 4;

                for (let i = 0; i < l; i += 1) {
                    const r = frame.data[i * 4];
                    const g = frame.data[i * 4 + 1];
                    const b = frame.data[i * 4 + 2];
                    const diff = Math.abs(r - bgR) + Math.abs(g - bgG) + Math.abs(b - bgB);
                    if (diff < tolerance) {
                        frame.data[i * 4 + 3] = 0;
                    }
                }
                if (ctx2) {
                    ctx2.putImageData(frame, 0, 0);
                    // Enable antialiasing
                    ctx1.imageSmoothingEnabled = true;
                    ctx1.imageSmoothingQuality = 'high';
                    ctx2.imageSmoothingEnabled = true;
                    ctx2.imageSmoothingQuality = 'high';

                    // Adjust color space if necessary
                    ctx1.webkitImageSmoothingEnabled = true; // For Safari
                    ctx1.mozImageSmoothingEnabled = true; // For Firefox
                    ctx2.webkitImageSmoothingEnabled = true; // For Safari
                    ctx2.mozImageSmoothingEnabled = true; // For Firefox
                }
            }
            image.src = res;
        });
    }, [c1, c2, src, bgR, bgG, bgB, tolerance]);

    const frame = useCallback(() => {
        const image = new Image();
        getBase64Image(src).then((res) => {
            image.onload = () => {
                selfieSegmentation.current.send({ image });
            }
            image.src = res;
        });
    }, [src])

    const onBackgroundRemoval = useCallback((results) => {
        const canvasCtx = c1.current?.getContext("2d");
        canvasCtx.save();
        const { width, height } = c1.current;
        if (width && height) {
            canvasCtx.drawImage(results.image, 0, 0, width, height);
            canvasCtx.globalCompositeOperation = 'destination-atop';
            canvasCtx.drawImage(results.segmentationMask, 0, 0, width, height);
            canvasCtx.globalCompositeOperation = 'destination-over';
            canvasCtx.restore();
        } else {
            setTimeout(() => {
                onBackgroundRemoval(results)
            }, 1000)
        }
    }, [c1])

    useEffect(() => {
        if (isBackgroundRemoval) {
            frame();
            selfieSegmentation.current.onResults((res) => {
                onBackgroundRemoval(res, true);
            });
        } else {
            computeFrame();
        }
    }, [computeFrame, isBackgroundRemoval, frame, onBackgroundRemoval])

    return src ? (
        <>
            <canvas ref={c1} style={chromaKey ? { ...style, visibility: 'hidden' } : { ...style }} className={className} />
            {chromaKey ? <canvas ref={c2} style={style} className={className} /> : null}
        </>
    ) : null;
};

CanvasImage.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string,
    isBackgroundRemoval: PropTypes.bool,
    chromaKey: PropTypes.string,
    tolerance: PropTypes.number,
    videoRef: PropTypes.object,
};

export default CanvasImage;