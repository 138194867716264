import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scroll } from "../../panel-components";
import usePanelScrollHeight from "../../../../helper/hooks/useScrollHeight";
import Tile from "../../../../common-components/Tile";
import { STATIC_PATH } from "../../../../constants/config";
import { SUBTITLE_ANIMATIONS } from "../../../../helper/subtitleHelper";
import {
  ColorBox,
  ColorPaletteWrapper,
  ColorSelector,
  FlexWrapper,
} from "./subtitleproperty-components";
import vmTheme from "../../../../constants/theme";
import ColorPaletteWindow from "../color/propertywindow-colorpalette";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import { PORTAL_ID } from "../../../../constants";

const SubtitleAnimation = () => {
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [colorCode, setColorCode] = useState(null);
  const panelRef = useRef();
  const dispatch = useDispatch();
  const workspaceStage = useSelector((state) =>
    state.app.get("workspaceStage")
  );
  const theme = useSelector((state) => state.app.get("theme"));
  const projectDetails = useSelector((state) => state.projectDetails);
  const mainScrollableHeight = usePanelScrollHeight({
    panelRef,
    workspaceStage,
    shouldUpdate: true,
  });

  useEffect(() => {
    const color = projectDetails.getIn(["subtitle", "animData", "colorProp"]);
    if (color) {
      setColorCode(color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colorSelectionHandler = (color) => {
    const animData = {
      colorProp: color,
    };
    const toUpdate = [
      {
        container: "subtitleGlobal",
        langId: projectDetails.get("defaultSubtitle"),
        toUpdate: { animData },
      },
    ];
    dispatch(updateTimelineTime({ toUpdate }));
    setColorCode(color);
  };

  const toggleColorPalette = () => {
    setShowColorPalette(!showColorPalette);
  };

  const applySubtitleAnimation = (animation) => {
    if (
      projectDetails.getIn(["subtitle", "animData", "animation"]) ===
      animation.name
    ) {
      return;
    }
    const toUpdate = [];
    const animData = {
      isAnimation: animation.displayName !== "None",
      displayName: animation.displayName,
      animation: animation.name,
    };
    if (animation.colorProp) {
      animData.colorProp = animation.colorProp;
      setColorCode(animation.colorProp);
    } else {
      setColorCode(null);
    }
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { animData },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  return (
    <>
      <ColorSelector
        isDisabled={!colorCode}
        onClick={colorCode ? toggleColorPalette : undefined}
      >
        Color
        <ColorBox bgColor={colorCode} />
        {showColorPalette &&
          ReactDOM.createPortal(
            <ColorPaletteWrapper>
              <ColorPaletteWindow
                colorSelected={colorSelectionHandler}
                closePopup={toggleColorPalette}
                currentColor={colorCode}
                itemId={"colorSelector"}
                stopSlidePropogation={true}
                from="TextEffectsSection"
                componentFrom="TXT_EFF_SEC"
              />
            </ColorPaletteWrapper>,
            document.getElementById(PORTAL_ID)
          )}
      </ColorSelector>
      <Scroll ref={panelRef} height={mainScrollableHeight} width="100%">
        <FlexWrapper isAnimation>
          {SUBTITLE_ANIMATIONS.map((animation) => {
            const border =
              projectDetails.getIn(["subtitle", "animData", "animation"]) ===
              animation.name
                ? `2px solid ${vmTheme[theme].secondaryBorderColor}`
                : `1px solid ${vmTheme[theme].subtitleBtnBorder}`;

            return (
              <Tile
                key={animation.name}
                src={`${STATIC_PATH}subtitle-animations/${animation.name}.svg`}
                height="70px"
                width="130px"
                onTileClick={() => applySubtitleAnimation(animation)}
                border={border}
                isHoverEffect
              />
            );
          })}
        </FlexWrapper>
      </Scroll>
    </>
  );
};

export default SubtitleAnimation;
