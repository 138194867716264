import React from "react";
import { WORKSPACE_LOADER } from "../../constants/config";
import content from "../../constants/content";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import { BufferLoaderContainer } from "./workspace-components";

const BufferLoader = () => {
  return (
    <BufferLoaderContainer className="buffer-loader-wrapper">
      <div>
        <div className="buffer-spinner-wrapper">
          <img alt="loader-spinner" src={WORKSPACE_LOADER} />
        </div>
        <div className="buffer-percent">
          <Typography
            as="span"
            color={"white"}
            className="loading-message loading"
            content={content.BUFFERING}
            font={font.normalMicroLarge}
          />
        </div>
      </div>
    </BufferLoaderContainer>
  );
};

export default BufferLoader;
