import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const LoadingBars = keyframes`
    0% {
        background-color: ${(props) => props.theme.loaderBgWhite};
        opacity: 1;
    }
    50% {
        background-color: ${(props) => props.theme.loaderBgWhiteSecondary};
        opacity: 0.5;
    }
    100% {
        background-color: ${(props) => props.theme.loaderBgFullWhite};
        opacity: 1;
    }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;
  width: ${(props) => props.width || "100%"};
  overflow: hidden;
  position: relative;
  bottom: ${(props) => props.bottom || "unset"};
`;

const TileElement = styled.div`
  width: 48%;
  height: 90px;
  border-radius: 4px;
  margin: 0px;
  background-color: ${(props) => props.theme.loaderBgWhite};
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
`;

const SmallElemnt = styled.div`
  width: 95%;
  height: 20px;
  border-radius: 4px;
  margin: 10px 0px;
  background-color: ${(props) => props.theme.loaderBgWhite};
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
`;

const ListElement = styled.div`
  width: 400px;
  height: 38px;
  border-radius: 4px;
  margin: 4px 0px;
  background-color: ${(props) => props.theme.loaderBgWhite};
  animation: 1s ease-out 0s infinite normal none running ${LoadingBars};
`;

const Placeholder = ({ isList, bottom, width }) => {
  return !isList ? (
    <Wrapper bottom={bottom} width={width}>
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <SmallElemnt />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <SmallElemnt />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <SmallElemnt />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <TileElement />
      <SmallElemnt />
    </Wrapper>
  ) : (
    <Wrapper bottom={bottom} width={width}>
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
      <ListElement />
    </Wrapper>
  );
};

Placeholder.propTypes = {
  isList: PropTypes.bool,
  bottom: PropTypes.string,
  width: PropTypes.string,
};

export default Placeholder;
