import styled, { css } from "styled-components";
import { ApplyAll, ApplyAllContainer, PropertyWindowContainer, ScrollbarCSS } from "../propertywindow-components";
import { font } from "../../../../constants/font";
import { DialogBox } from "../color/propertywindow-dialogbox";

export const TextSettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    > ${ApplyAll} {
        height: 52px;
        margin: 0;
    }

    > .font-family-size-container {
        display: grid;
        grid-template-columns: 1fr 72px;
        align-items: center;
        gap: 16px;

        > .font-family-container {
            height: 36px;
            display: flex;
            justify-content: space-between;
            gap: 8px;
            align-items: center;
            border: 1px solid ${(props)=>props.theme.veryLightGray};
            border-radius: 4px;
            cursor: pointer;
            overflow: hidden;

            > .font-family-name {
                display: block;
                font-size: 14px;
                color: ${(props)=>props.theme.propertyColor};
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 12px;
            }

            > .font-family-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6.65px;

                img.fontname-loader {
                    width: 20px;
                    height: 20px;
                    display: none;
                }

                img.font-family-toggle-icon {
                    width: 10.704px;
                    height: 6.037px;
                    display: inline-block;
                }

                &.font-loading {
                    img.fontname-loader {
                        display: inline-block;
                    }
                    img.font-family-toggle-icon {
                        display: none;
                    }
                }
            }
        }

        > .font-size-container {
            position: relative;
            width: 72px;
            height: 36px;

            > .font-size {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: 48px 24px;
                grid-template-rows: 18px 18px;
                border-radius: 4px;
                outline: 1px solid ${(props)=>props.theme.veryLightGray};
                overflow: hidden;

                &:focus-within {
                    outline-color: ${(props) => props.theme.secondaryBorderColor};
                }

                > .numberinput--wrapper {
                    grid-row: 1 / span 2;
                    padding: 8px;
                    font: ${font.normalBase};
                    color: ${(props)=>props.theme.propertyColor};
                    border-right: 1px solid ${(props)=>props.theme.veryLightGray};
                }

                > .font-size-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${(props) => props.theme.lightSolitudeColor};
                    cursor: pointer;

                    img {
                        width: 10px;
                        height: 5.64px;
                    }
                }
            }
        }
    }

    > .text-formats-container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 11.04px;
    }

    > .spacing-settings {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;

        > .spacing-wrapper {
            height: 79px;
            padding: 16px 12px;
            border: 1px solid ${(props) => props.theme.primaryBorderColor};
            border-radius: 8px;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > span {
                color: ${(props)=>props.theme.propertyColor};
                font: ${font.mediumMini}
            }

            > div {
                width: 100%;
                height: 16px;

                > input {
                    width: 100%;
                }
            }
        }
    }
`;
TextSettingsContainer.displayName = "TextSettingsContainer";

export const FontSizeList = styled(DialogBox)`
    width: 72px;
    max-height: 370px;
    padding: 4px 2px 4px 0px;
    box-shadow: 0px 0px 4px ${(props)=>props.theme.arrangeMenuColor};
    border: 1px solid ${(props)=>props.theme.veryLightGray};
    background-color: ${(props)=>props.theme.polarColor};
    border-radius: 4px;
    display: flex;

    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul {
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        ${ScrollbarCSS}
        &::-webkit-scrollbar {
            width: 4px;
        }

        li {
            height: 28px;
            display: flex;
            align-items: center;
            padding: 0px 12px 0 12px;
            font: ${font.normalBase_21};
            color: ${(props)=>props.theme.tertiaryColor};

            background-color: transparent;
            transition: background-color 0.3s ease-out;

            &:hover {
                cursor: pointer;
                background-color: ${(props)=>props.theme.outerLayerBgColor};
            }
        }
    }
`;
FontSizeList.displayName = "FontSizeList";

export const FontStyleImg = styled.div`
    display: block;
    position: relative;
    width: 100%;
    aspect-ratio: 34.86 / 36;
    box-shadow: 0px 0.5px 1px ${(props) => (props.theme.themeName === 'light' ? props.theme.rgbaBlack : 'none' )};
    border-radius: 4px;
    border: 1px solid ${(props) => (props.theme.themeName === 'light' ? props.theme.primaryBorderColor : 'none')};
    overflow: hidden;
    cursor: pointer;

    > img.font-style-loader {
        position: absolute;
        width: 20px;
        height: 20px;

        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    > img.font-style-icon {
        display: block;
        width: 100%;
    }

    &:hover:not(.disabled) {
        > img.font-style-icon {
            content: var(--font-style-img-hoversrc);
        }
    }

    &.has-hover-icon:not(.disabled) {
        &.active:not(.text-transform.none), &:hover {
            /* border-color: ${(props) => props.theme.fontStyleImgHover}; */
            /* background-color: ${(props) => props.theme.secondaryBorderColor}; */
        }
    }

    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;
FontStyleImg.displayName = "FontStyleImg";

export const TextEffectsContainer = styled.div`
    height: 100%;
    width: 100%;
    background: ${(props)=>props.theme.polarColor};
`;
TextEffectsContainer.displayName = "TextEffectsContainer";

export const Header = styled.div`
    font: ${font.boldMini_15};
    width: 100%;
    margin-bottom: 16px;
    color: ${(props)=>props.theme.panelPrimaryColor};
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
Header.displayName = "Header";

export const EffectsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
`;
EffectsWrapper.displayName = "EffectsWrapper";

export const EffectBox = styled.div`
    width: 100%;
    aspect-ratio: 1;

    border: 1px solid ${(props)=>props.theme.veryLightGray};
    border-radius: 5px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 19px;
    align-items: center;
    padding: 12px 10px;

    box-shadow: ${props => props.isActive && `${props.theme.secondaryBorderColor} 0px 0px 0px 2px, ${props.theme.polarColor} 0px 0px 0px 0.5px inset`};
    :hover {
        border: 1px solid ${(props) => props.theme.secondaryBorderColor};
        cursor: pointer;
    }
`;
EffectBox.displayName = "EffectBox";

export const EffectText = styled.div`
        font: ${font.bold_45_57_ptsans};
        text-align: center;
        text-shadow: ${props => props.effectNumber === 1 ? 'rgb(0 0 0 / 40%) 1.96612px 2.1836px 0px'
        : props.effectNumber === 2 ? 'rgb(0 0 0 / 38%) 0px 0px 18.07px'
            : props.effectNumber === 4 ? 'rgb(0 255 255) -0.84717px -0.84717px 0px, rgb(255 0 255) 0.84717px 0.84717px 0px'
                : props.effectNumber === 5 ? 'rgb(0 0 0 / 50%) 2.14486px 2.30008px 0px, rgb(0 0 0 / 30%) 4.28972px 4.60016px 0px'
                    : 'rgb(255 255 255) 0px 0px 0px'};
        ${props => props.effectNumber && props.effectNumber === 3 ?
        css`
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1.5px;
        `
        : props.effectNumber === 6 ?
            css`
            text-shadow: none;
            -webkit-text-fill-color: rgb(225, 195, 223);
            filter: drop-shadow(rgba(145, 38, 138, 0.95) 0px 0px 1.72px)
                    drop-shadow(rgba(145, 38, 138, 0.75) 0px 0px 2.94px)
                    drop-shadow(rgba(145, 38, 138, 0.44) 0px 0px 4.44px);
        `
            : props.effectNumber === 7 ?
                css`
            text-shadow: rgb(170 170 170) 3.39215px 0.598128px 0px;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1.5px;
        `
                : props.effectNumber === 8 ?
                    css`
            text-shadow: rgb(255 165 0 / 40%) 0.876px 0px 1.6px,
                        rgb(255 165 0 / 40%) 0.871795px 0.085848px 1.6px,
                        rgb(255 165 0 / 40%) 0.859181px 0.170908px 1.6px,
                        rgb(255 165 0 / 40%) 0.838244px 0.254303px 1.6px,
                        rgb(255 165 0 / 40%) 0.809336px 0.335245px 1.6px,
                        rgb(255 165 0 / 40%) 0.772544px 0.412946px 1.6px,
                        rgb(255 165 0 / 40%) 0.728394px 0.486706px 1.6px,
                        rgb(255 165 0 / 40%) 0.677148px 0.555734px 1.6px,
                        rgb(255 165 0 / 40%) 0.61942px 0.61942px 1.6px,
                        rgb(255 165 0 / 40%) 0.555734px 0.677148px 1.6px,
                        rgb(255 165 0 / 40%) 0.486706px 0.728394px 1.6px,
                        rgb(255 165 0 / 40%) 0.412946px 0.772544px 1.6px,
                        rgb(255 165 0 / 40%) 0.335245px 0.809336px 1.6px,
                        rgb(255 165 0 / 40%) 0.254303px 0.838244px 1.6px,
                        rgb(255 165 0 / 40%) 0.170908px 0.859181px 1.6px,
                        rgb(255 165 0 / 40%) 0.085848px 0.871795px 1.6px,
                        rgb(255 165 0 / 40%) 0px 0.876px 1.6px,
                        rgb(255 165 0 / 40%) -0.085848px 0.871795px 1.6px,
                        rgb(255 165 0 / 40%) -0.170908px 0.859181px 1.6px,
                        rgb(255 165 0 / 40%) -0.254303px 0.838244px 1.6px,
                        rgb(255 165 0 / 40%) -0.335245px 0.809336px 1.6px,
                        rgb(255 165 0 / 40%) -0.412946px 0.772544px 1.6px,
                        rgb(255 165 0 / 40%) -0.486706px 0.728394px 1.6px,
                        rgb(255 165 0 / 40%) -0.555734px 0.677148px 1.6px,
                        rgb(255 165 0 / 40%) -0.61942px 0.61942px 1.6px,
                        rgb(255 165 0 / 40%) -0.677148px 0.555734px 1.6px,
                        rgb(255 165 0 / 40%) -0.728394px 0.486706px 1.6px,
                        rgb(255 165 0 / 40%) -0.772544px 0.412946px 1.6px,
                        rgb(255 165 0 / 40%) -0.809336px 0.335245px 1.6px,
                        rgb(255 165 0 / 40%) -0.838244px 0.254303px 1.6px,
                        rgb(255 165 0 / 40%) -0.859181px 0.170908px 1.6px,
                        rgb(255 165 0 / 40%) -0.871795px 0.085848px 1.6px,
                        rgb(255 165 0 / 40%) -0.876px 0px 1.6px,
                        rgb(255 165 0 / 40%) -0.871795px -0.085848px 1.6px,
                        rgb(255 165 0 / 40%) -0.859181px -0.170908px 1.6px,
                        rgb(255 165 0 / 40%) -0.838244px -0.254303px 1.6px,
                        rgb(255 165 0 / 40%) -0.809336px -0.335245px 1.6px,
                        rgb(255 165 0 / 40%) -0.772544px -0.412946px 1.6px,
                        rgb(255 165 0 / 40%) -0.728394px -0.486706px 1.6px,
                        rgb(255 165 0 / 40%) -0.677148px -0.555734px 1.6px,
                        rgb(255 165 0 / 40%) -0.61942px -0.61942px 1.6px,
                        rgb(255 165 0 / 40%) -0.555734px -0.677148px 1.6px,
                        rgb(255 165 0 / 40%) -0.486706px -0.728394px 1.6px,
                        rgb(255 165 0 / 40%) -0.412946px -0.772544px 1.6px,
                        rgb(255 165 0 / 40%) -0.335245px -0.809336px 1.6px,
                        rgb(255 165 0 / 40%) -0.254303px -0.838244px 1.6px,
                        rgb(255 165 0 / 40%) -0.170908px -0.859181px 1.6px,
                        rgb(255 165 0 / 40%) -0.085848px -0.871795px 1.6px,
                        rgb(255 165 0 / 40%) 0px -0.876px 1.6px,
                        rgb(255 165 0 / 40%) 0.085848px -0.871795px 1.6px,
                        rgb(255 165 0 / 40%) 0.170908px -0.859181px 1.6px,
                        rgb(255 165 0 / 40%) 0.254303px -0.838244px 1.6px,
                        rgb(255 165 0 / 40%) 0.335245px -0.809336px 1.6px,
                        rgb(255 165 0 / 40%) 0.412946px -0.772544px 1.6px,
                        rgb(255 165 0 / 40%) 0.486706px -0.728394px 1.6px,
                        rgb(255 165 0 / 40%) 0.555734px -0.677148px 1.6px,
                        rgb(255 165 0 / 40%) 0.61942px -0.61942px 1.6px,
                        rgb(255 165 0 / 40%) 0.677148px -0.555734px 1.6px,
                        rgb(255 165 0 / 40%) 0.728394px -0.486706px 1.6px,
                        rgb(255 165 0 / 40%) 0.772544px -0.412946px 1.6px,
                        rgb(255 165 0 / 40%) 0.809336px -0.335245px 1.6px,
                        rgb(255 165 0 / 40%) 0.838244px -0.254303px 1.6px,
                        rgb(255 165 0 / 40%) 0.859181px -0.170908px 1.6px,
                        rgb(255 165 0 / 40%) 0.871795px -0.085848px 1.6px;
        ` : ''
    }
`;
EffectText.displayName = "EffectText";

export const EffectName = styled.div`
    color: ${(props)=>props.theme.propertyColor};
    font: ${font.normalMini};
    text-align: center;
`;
EffectName.displayName = "EffectName";

export const AttributesContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 16px 0 33px 0;
    grid-column: 1 / span 2;

    ::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${(props)=>props.theme.veryLightGray};
        border-radius: 4px;
        left: 0;
        bottom: 12px;
    }

    &:last-of-type {
        padding-bottom: 20px;

        ::after {
            display: none;
        }
    }
`;
AttributesContainer.displayName = "AttributesContainer";

export const AttributeTitle = styled.div`
    font: ${font.mediumMini};
    color: ${(props)=>props.theme.propertyColor};
`;
AttributeTitle.displayName = "AttributeTitle";

export const SliderAttribute = styled.div`
    display: grid;
    grid-template-columns: 1fr 52px;
    grid-template-rows: 28px 14px;
    align-items: center;
    gap: 12px;

    > .zoom-slider--slider-container {
        grid-column: 1 / span 2;
        width: 100%;
        height: 14px;

        > input {
            width: 100%;
        }
    }

    > .numberinput--wrapper {
        width: 100%;
        font: ${font.normalBase};
        padding: 4px 8px;
        border: 1px solid ${(props) => props.theme.primaryBorderColor};
        border-radius: 4px;
        cursor: text;

        input {
            text-align: right;
        }
    }
`;
SliderAttribute.displayName = "SliderAttribute";

export const ColorAttribute = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .palette-color-picker {
        top: auto;
        right: 0;
        bottom: calc(100% + 6px);
        left: auto;

        @media(max-width: 768px){
            min-width: 100%;
        }
    }
`;
ColorAttribute.displayName = "ColorAttribute";

export const ColorSelector = styled.div`
    height: 33px;
    width: 33px;
    border: 1px solid ${(props)=>props.theme.veryLightGray};
    border-radius: 4px;
    box-shadow: ${props => props.active ? `${props.theme.secondaryBorderColor} 0px 0px 0px 1px` : ''};

    :hover {
        box-shadow: ${(props) => props.theme.secondaryBorderColor} 0px 0px 0px 1px;
    }
`;
ColorSelector.displayName = "ColorSelector";

export const GlitchButtons = styled.div`
    display: flex;
    column-gap: 16px;
`;
GlitchButtons.displayName = "GlitchButtons";

export const GlitchBtn = styled.button`
    background: ${props => props.variant === 2 ? 'linear-gradient(to right, rgb(0, 255, 255) 0%, rgb(0, 255, 255) 50%, rgb(255, 0, 255) 50%, rgb(255, 0, 255) 100%)'
        : 'linear-gradient(to right, rgb(0, 255, 255) 0%, rgb(0, 255, 255) 50%, rgb(255, 0, 0) 50%, rgb(255, 0, 0) 100%)'};
    height: 33px;
    width: 33px;
    border: 1px solid ${(props)=>props.theme.veryLightGray};
    border-radius: 4px;
    opacity: 1;
    box-shadow: ${props => props.active ? `${props.theme.secondaryBorderColor} 0px 0px 0px 1px` : ''};
    cursor: pointer;

    :hover {
        box-shadow: ${(props) => props.theme.secondaryBorderColor} 0px 0px 0px 1px;
    }
`;
GlitchBtn.displayName = "GlitchBtn";

export const TextApplyAllContainer = styled(ApplyAllContainer)`
    > .apply-all--heading {
        font: ${font.boldSmall};
        text-align: center;
    }

    > .apply-all--body {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        > .apply-all--option {
            outline: 1px solid ${(props) => props.theme.paymentBorderColor};
            border-radius: 8px;
            height: 52px;
            padding: 17px 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: ${(props) => props.theme.tertiaryColor};
            font: ${font.normalBase};

            &.active {
                outline: 2px solid ${(props) => props.theme.checkBoxTickColor};
            }

            > div {
                gap: 12px;
            }

            * {
                pointer-events: none;
                margin: 0;
            }
        }
    }
`;
TextApplyAllContainer.displayName = "TextApplyAllContainer";

export const FontFamilyContainer = styled(PropertyWindowContainer)`
    display: grid;
    grid-template-rows: 99px 1fr;
    grid-template-columns: 1fr;

    > .ffc--top-section {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;

        > .ffc--header {
            display: flex;
            align-items: center;
            gap: 4px;
            font: ${font.boldMini_15};
            color: ${(props) => props.theme.panelPrimaryColor};
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
            }
        }

        > .ffc--search-container > form > div {
            padding: 8px;
            justify-content: flex-start;
            gap: 4px;

            &:focus-within {
                border-color: ${(props) => props.theme.secondaryBorderColor};
            }

            input {
                width: 100%;
            }
        }
    }

    > .ffc--body {
        padding: 8px 8px 16px 16px;
        margin-right: 2px;

        overflow-x: hidden;
        overflow-y: scroll;

        ${ScrollbarCSS}

        ul {
            list-style: none;
        }

        .ffc--font-styles > h3 {
            font: ${font.mediumMini};
            color: ${(props) => props.theme.secondaryColor};
            margin-bottom: 16px;
        }

        .ffc--font-styles > ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .ffc--font-style {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img:not(.loader) {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            img.loader {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
            }
        }
    }
`;
FontFamilyContainer.displayName = "FontFamilyContainer";
