import { useDispatch } from "react-redux";
import ActionTypes from "../../constants/action-types";

const useUpgrade = () => {
    const dispatch = useDispatch();

    const showUpgrade = (arg = {}) => {
        dispatch({
            type: ActionTypes.SHOW_UPGRADE_MODAL,
            payload: {
                show: true,
                ...arg
            },
        });
    }


    const hideUpgrade = () => {
        dispatch({
            type: ActionTypes.SHOW_UPGRADE_MODAL,
            payload: {
                show: false,
                headerText: null,
                subText: null,
                customForm: null,
                enterpriseContent: null,
                isAvatarUpgrade: false,
                upgradesource: '',
                type: "default"
            },
        });
    }

    return {
        showUpgrade,
        hideUpgrade,
    };
};

export default useUpgrade;
