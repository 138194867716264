import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { APP_Z_INDICES, PORTAL_ID } from "../../../../constants";

const Dialog = styled.div`
    height: 345px;
    width: 335px;
    z-index: ${APP_Z_INDICES.colorpicker.default};
    position: fixed;
    transition: transform 200ms linear;
    @media(max-width: 768px){
        min-width: 100%;
        left: 0px !important;
        .palette-color-picker.tool-bar{
            min-width: 100%;
            border-radius: 20px 20px 0px 0px;
            box-shadow: 0px -4px 32px ${(props) => props.theme.rgbaBlack};
        }
    }
`;
Dialog.displayName = "Dialog";

export const DialogBox = React.forwardRef((dialogProps, ref) => {
    const {
        children,
        open,
        className = "",
        onOutsideMouseDown, // WARN: should pass memoized function
        ...otherProps
    } = dialogProps;
    const dialogRef = useRef(null);

    useEffect(() => {
        let windowClickHandler = null;

        if (open && typeof onOutsideMouseDown === "function") {
            windowClickHandler = (event) => {
                if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                    onOutsideMouseDown(event);
                }
            }
            window.addEventListener("mousedown", windowClickHandler);
        }

        return () => {
            if (windowClickHandler) {
                window.removeEventListener("mousedown", windowClickHandler);
            }
        }
    }, [onOutsideMouseDown, open]);

    const assignRef = (r) => {
        dialogRef.current = r;
        if (typeof ref === "function") {
            ref(r);
        } else if (ref && typeof ref === "object") {
            ref.current = r;
        }
    }

    if (!open) return null;

    const dialog = (
        <Dialog
            {...otherProps}
            ref={assignRef}
            className={`${className} dialog-tool-bar`}
        >
            {children}
        </Dialog>
    );

    const portalDOM = document.getElementById(PORTAL_ID);
    if (portalDOM) {
        return createPortal(dialog, portalDOM);
    }

    return null;
});
DialogBox.displayName = "DialogBox";
