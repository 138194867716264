import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ExpandableButton from "../../../common-components/ExpandableButton";
import { STATIC_PATH } from "../../../constants/config";
import content from "../../../constants/content";
import { font } from "../../../constants/font";
import NumberInput from "../../../common-components/NumberInputComponent";
import Typography from "../../../common-components/Typography";
import vmTheme from "../../../constants/theme";
import {
  ColorPaletteWrapper,
  ColorPickerButton,
} from "./subtitle/subtitleproperty-components";
import Slider from "../../zoom/zoom-slider";
import ColorPaletteWindow from "./color/propertywindow-colorpalette";
import { PORTAL_ID } from "../../../constants";
import { Flex, Sensitivity } from "../panel-components";

const GreenScreen = ({
  isChromaRemoval,
  toggleCheckBox,
  chromaKey,
  toggleColorPalette,
  tolerance,
  handleTolerance,
  isShowColorPicker,
  colorSelectionHandler,
  onPickerClick,
  onFocus,
  onBlur,
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  return (
    <ExpandableButton
      isExpandable
      icon={`${STATIC_PATH}green-screen.svg`}
      heading={content.GREEN_SCREEN_MAIN}
      subHeading={content.GREEN_SCREEN_SUBTEXT}
      checked={isChromaRemoval}
      handleCheckBox={(isChecked) => toggleCheckBox(isChecked)}
    >
      <>
        <Flex className="hr-line" style={{padding: "16px"}}>
          <Typography
            innerContent="Color to remove"
            color={vmTheme[theme].contactLabelTextColor}
            display="block"
            font={font.mediumMini}
          />
          <ColorPickerButton
            background={chromaKey}
            onClick={toggleColorPalette}
          />
        </Flex>
        <Sensitivity style={{padding: "16px"}}>
          <div className="sensitivity-sec">
            <p className="heading">
              <FormattedMessage id={content.SENSITIVITY} />
            </p>
            <NumberInput
              min={0}
              max={100}
              step={1}
              value={tolerance}
              precision={1}
              onChange={() => {}}
              onFocus={onFocus}
              onBlur={onBlur}
              suffix="%"
              saveOnUnmount={true}
            />
          </div>
          <div className="slider">
            <Slider
              min={0}
              max={100}
              inputMin={0}
              inputMax={100}
              value={tolerance}
              step={1}
              onChangeSliderValue={() => {}}
              onMouseUp={handleTolerance}
              border={"none"}
              borderRadius={"3px"}
              background={vmTheme[theme].veryLightGray}
              margin={"0"}
              height={"5px"}
              thumb={{
                background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                height: "16px",
                width: "16px",
                hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
              }}
              progressBackground={vmTheme[theme].secondaryBorderColor}
              isChangeBg={true}
            />
          </div>
        </Sensitivity>
        {isShowColorPicker &&
          ReactDOM.createPortal(
            <ColorPaletteWrapper top="440px" left="135px">
              <ColorPaletteWindow
                colorSelected={colorSelectionHandler}
                closePopup={toggleColorPalette}
                onPickerClick={onPickerClick}
                currentColor={chromaKey}
                itemId={"colorSelector"}
                stopSlidePropogation={true}
                from="TextEffectsSection"
                componentFrom="TXT_EFF_SEC"
              />
            </ColorPaletteWrapper>,
            document.getElementById(PORTAL_ID)
          )}
      </>
    </ExpandableButton>
  );
};
GreenScreen.propTypes = {
  isChromaRemoval: PropTypes.bool,
  toggleCheckBox: PropTypes.func,
  chromaKey: PropTypes.string,
  toggleColorPalette: PropTypes.func,
  tolerance: PropTypes.number,
  handleTolerance: PropTypes.func,
  isShowColorPicker: PropTypes.bool,
  colorSelectionHandler: PropTypes.func,
  onPickerClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default GreenScreen;
