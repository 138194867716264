import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import vmTheme from "../constants/theme";
import Toggle from "./Toggle";
import Typography from "./Typography";
import { font } from "../constants/font";
import CustomTooltipComponent from "./CustomTooltip";
import Restriction from "./Restriction";
import content from "../constants/content";
import { STATIC_PATH } from "../constants/config";
import { PLAN } from "../constants";
import useUpgrade from "../helper/hooks/useUpgrade";

const Wrapper = styled.div`
  width: 316px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
  border: ${(props) =>
    props.isChecked
      ? `1px solid ${props.theme.secondaryBorderColor}`
      : `1px solid ${props.theme.iconsBorder}`};
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
  ${(props) =>
    props.isExpand &&
    css`
      height: auto;
    `}
    transition: height 0.1s;
`;

const Flex = styled.div`
  display: flex;
  justify-content: start;
  gap: 16px;
  align-items: center;
  height: 70px;
  padding: 16px;
  border-top: 1px solid #e3e4e8;
  .bgRestriction {
    justify-content: start;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 6px;
    }
  }
  .bg-toggle {
    margin-left: auto;
  }
  + div .restriction-btn {
    padding: 3px 8px !important;
  }
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const UpgradeSection = styled.div`
  height: 49px;
  border-radius: ${(props) => (props.isChecked ? "8px" : "0px")};
  background: ${(props) =>
    props.isChecked
      ? props.theme.expandableUpgradActivebg
      : props.theme.expandableUpgradbg};
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: ${(props) => (props.isChecked ? "0px 16px 16px" : "auto")};
  position: relative;
  opacity: 1;
  overflow: hidden;
  transition:
    height 0.1s ease,
    opacity 0.1s ease;

  &::before {
    content: url(${(props) => props.isChecked ? `${STATIC_PATH}vm-info.svg` : "unset"});
    position: absolute;
    left: 8px;
  }
  .premiunContent {
    padding: ${(props) => (props.isChecked ? "0px 12px 0px 32px" : "0px")};
  }
`;

const ExpandableButton = ({
  isExpandable,
  heading,
  subHeading,
  checked,
  handleCheckBox,
  icon,
  children,
  isDisabled,
  tooltipMessage,
  isUpgrade,
  isLimitExceed,
  info,
  modalContent
}) => {
  const theme = useSelector((state) => state.app.get("theme"));
  const [isChecked, setIsChecked] = useState(false);
  const { plan } = useSelector((store) => store.userDetails);
  const { showUpgrade } = useUpgrade();

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const clickHandler = (e) => {
    e && e.preventDefault() && e.stopPropagation();
    if (!isDisabled) {
      if (isUpgrade || isLimitExceed) {
        setIsChecked(false);
        showUpgrade({ modalContent, upgradesource: "Background-removal" });
      } else {
        setIsChecked(!isChecked);
        handleCheckBox(!isChecked);
      }
    }
  };


  const Tooltip = isDisabled ? CustomTooltipComponent : React.Fragment;

  return (
    <Tooltip
      tooltipId={`expand-tooltip-${heading}`}
      dataTooltip={tooltipMessage}
      tooltipPosition={"bottom"}
    >
      <Wrapper
        isExpand={isChecked && isExpandable}
        plan={plan === PLAN.LITE}
        isChecked={isChecked}
        isDisabled={isDisabled}
        data-tooltip-id={`expand-tooltip-${heading}`}
      >
        <Flex onClick={clickHandler}>
          <Icon src={icon} />
          <div>
            <Typography
              content={heading}
              color={vmTheme[theme].tabTextColor}
              display="block"
              font={font.boldBase}
              cursor={"pointer"}
            />
            {subHeading ? (
              <Typography
                content={subHeading}
                color={vmTheme[theme].labelSubtextColor}
                font={font.normalMicro_11}
                cursor={"pointer"}
              />
            ) : null}
            {isUpgrade || isLimitExceed ? (
              <Restriction
                className="bgRestriction"
                content={isLimitExceed ? "Limit Exceeded" : "Upgrade"}
                type={isLimitExceed ? "LIMIT_EXCEED" : "UPGRADE"}
                top={isLimitExceed ? "0px" : "4px"}
                right={isLimitExceed ? "0px" : "4px"}
                position={isLimitExceed ? "relative" : "absolute"}
              />
            ) : null}
          </div>
          {!isUpgrade ? (
            <Toggle
              style={{ marginLeft: "auto" }}
              toggleColor={vmTheme[theme].checkBoxTickColor}
              value={isChecked}
              clickHandler={clickHandler}
              isDisabled={isDisabled}
            />
          ) : null}
        </Flex>
        {isLimitExceed || (isChecked && info) ? (
          <UpgradeSection isChecked={isChecked}>
            <Typography
              content={isChecked && info ? info : content.WHOOPS}
              className="premiunContent"
              color={vmTheme[theme].panelPrimaryColor}
              font={font.mediumMini_12}
              enableTrim={false}
              width={isChecked ? "auto" : isLimitExceed ? "160px" : null}
            />
            {isChecked ? null : (
              <Restriction
                content={"Upgrade"}
                type="UPGRADE"
                backgroundColor={vmTheme[theme].primaryColor}
                onClick={() => showUpgrade({ modalContent, upgradesource: 'Background-removal' })}
              />
            )}
          </UpgradeSection>
        ) : null}

        {isExpandable && children}
      </Wrapper>
    </Tooltip>
  );
};
ExpandableButton.propTypes = {
  isExpandable: PropTypes.bool,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  checked: PropTypes.bool,
  handleCheckBox: PropTypes.func,
  icon: PropTypes.string,
  children: PropTypes.element,
  isDisabled: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  isUpgrade: PropTypes.bool,
  isLimitExceed: PropTypes.bool,
  info: PropTypes.string,
  modalContent: PropTypes.object
};

export default ExpandableButton;
