import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fromJS } from "immutable";
import { SubtitleTextWrapper, SubtitleText } from "./subtitle-text-components";
import {
  validateAlpha,
  convertAlphaRange,
  buildSubtitleDomElement,
  checkHasSplitLineEffect,
  checkHasOutLineEffect,
  shouldPreventColorAndAddOutLine,
} from "./subtitleTextHelper";

const SUB_INNER_OFFSET = 16;

const SubtitleTextComponent = ({
  workspaceZoom,
  subtitle,
  id,
  onPointerDown,
  onPointerUp,
  opacity,
}) => {
  const subtitleTextRef = useRef(null);
  const subtitleData = useSelector((state) =>
    state.projectDetails.get("subtitle")
  );
  const appReducer = useSelector((state) => state.app);
  const presetEffects = appReducer.get("preset");
  const textEffects = subtitleData.get("textEffects");
  const textStyles = subtitleData.get("textStyles");
  const animData = subtitleData.get("animData");
  // Full Width Single Word Animation
  const fwswAnimationApplied =
    animData &&
    (animData.get("animation") === "textPopupWordbyWord" ||
      animData.get("animation") === "textRevealWordbyWord");
  const isAnimationApplied = animData && animData.get("isAnimation");

  let subtitleStyles = textStyles || fromJS({});
  if (subtitle.get("textStyles")) {
    /** @Note overRide the style value if the individual subtitle has subtitle textStyles. */
    subtitleStyles = subtitleStyles.merge(subtitle.get("textStyles"));
  }
  let textDecoration = "auto";

  if (!isAnimationApplied) {
    if (
      subtitleStyles.get("isUnderline") &&
      subtitleStyles.get("isLineThrough")
    ) {
      textDecoration = `underline line-through`;
    } else if (subtitleStyles.get("isUnderline")) {
      textDecoration = `underline`;
    } else if (subtitleStyles.get("isLineThrough")) {
      textDecoration = `line-through`;
    }
  }

  const fontFamily = `${subtitleStyles.get("fontFamily")}, gfs`;
  const fontStyle = subtitleStyles.get("isItalic") ? "italic" : "normal";
  const textAlign = subtitleStyles.get("textAlign");
  const letterSpacing = subtitleStyles.get("letterSpacing");
  const textTransform = subtitleStyles.get("textTransform");
  const lineHeight = subtitleStyles.get("lineHeight");
  let fontColor = subtitleStyles.get("color")
    ? subtitleStyles.get("color").substring(0, 7)
    : "#FFFFFF";
  let fontBgColor = subtitleStyles.get("background")
    ? subtitleStyles.get("background").substring(0, 7)
    : "#000000";
  const colorAlpha = validateAlpha(subtitleStyles.get("colorAlpha"));
  const bgAlpha = validateAlpha(subtitleStyles.get("bgAlpha"));
  const fontSize = workspaceZoom * parseFloat(subtitleStyles.get("fontSize"));

  /** @description sets the alpha value to color and bg */
  fontColor += convertAlphaRange(colorAlpha);
  fontBgColor += convertAlphaRange(bgAlpha);

  /** @start textEffects */
  let textEffectsStyleData = textEffects;
  if (subtitle && subtitle.get("textEffects")) {
    /** @note overRide the currentSubtitle textEffect */
    textEffectsStyleData = textEffectsStyleData.merge(
      subtitle.get("textEffects")
    );
  }
  let textEffectsStyle = {};
  const hasTextEffect = textEffectsStyleData.get("hasTextEffect");
  const effectIndex = textEffectsStyleData.get("effectIndex");
  const effectKey = textEffectsStyleData.get("key");
  if (hasTextEffect) {
    const subtitleTextEffect = presetEffects && presetEffects.find(item => item._source.KEY === effectKey);
    if (subtitleTextEffect) {
      textEffectsStyle = subtitleTextEffect._source.data.TEXTEFFECTS;
      if (
        Number.isFinite(effectIndex) &&
        shouldPreventColorAndAddOutLine(effectIndex)
      ) {
        textEffectsStyle.WebkitTextStrokeColor = fontColor;
      }
      if (Number.isFinite(effectIndex) && checkHasOutLineEffect(effectIndex)) {
        textEffectsStyle.WebkitTextStrokeColor = fontBgColor;
      }
    }
  }
  const borderRadius =
    parseFloat(textStyles.get("borderRadius") || "0px") * workspaceZoom;
  textEffectsStyle.borderRadius = `${borderRadius}px`;
  /** @end textEffects */

  /** @start bgEffects */
  const bgEffectStyle = {};
  bgEffectStyle.background = textStyles.get("background");
  const backgroundAlpha = validateAlpha(textStyles.get("bgAlpha"));
  if (textStyles.get("background") !== "transparent") {
    bgEffectStyle.background += convertAlphaRange(backgroundAlpha);
  }
  if (textStyles.get("webkitTextStroke")) {
    bgEffectStyle.webkitTextStroke = textStyles.get("webkitTextStroke");
  }
  if (textStyles.get("textShadow")) {
    const textShadowArr = textStyles.get("textShadow").split(" ");
    bgEffectStyle.textShadow = `${
      parseFloat(textShadowArr[0]) * workspaceZoom
    }px ${parseFloat(textShadowArr[1]) * workspaceZoom}px ${
      parseFloat(textShadowArr[2]) * workspaceZoom
    }px ${textShadowArr[3]}`;
  }
  /** @end bgEffects */

  /** @description newLine splitted effect start */
  const hasSplitLineEffect =
    checkHasSplitLineEffect(textEffects.get("effectIndex")) &&
    fwswAnimationApplied !== true;

  /** @description AN-6785 - Due to space after newLine subtitle animation gets break. */
  const textContent = useMemo(() => {
    const preventFormat = !isAnimationApplied || fwswAnimationApplied;
    return buildSubtitleDomElement(
      subtitle.get("text"),
      preventFormat,
      hasSplitLineEffect
    );
  }, [subtitle, hasSplitLineEffect, fwswAnimationApplied, isAnimationApplied]);

  let splittedText;
  if (hasSplitLineEffect) {
    splittedText = (
      <SubtitleText
        id={`splittedText-${0}`}
        className="splittedText"
        fontBgColor={fontBgColor}
        lineHeight={`${Math.ceil(
          fontSize * (lineHeight + 0.2) + SUB_INNER_OFFSET * workspaceZoom
        )}px`}
        width={
          parseFloat(subtitle.get("width"))
            ? `${workspaceZoom * parseFloat(subtitle.get("width"))}px`
            : "max-content"
        }
        paddingSpace={
          subtitle.get("text").length === 0
            ? "0px"
            : `${Math.floor((SUB_INNER_OFFSET / 2) * workspaceZoom)}px`
        } // Equally spread the innerOffset to all the side.
        textDecoration={textDecoration}
        dangerouslySetInnerHTML={{ __html: textContent }}
      />
    );
  }
  /** @end newLine splitted effect End  */

  const subtitleWrapperStyle = {
    fontSize: `${fontSize}px`,
    fontWeight: subtitle.getIn(["textStyles", "fontWeight"]),
    width: `${workspaceZoom * parseFloat(subtitle.get("width"))}px`,
    height: `${workspaceZoom * parseFloat(subtitle.get("height"))}px`,
    fontStyle,
    fontFamily: fontFamily || `"PT Sans", sans-serif`,
    textDecoration,
    transform: `translate(${subtitle.get("x") * workspaceZoom}px, ${
      subtitle.get("y") * workspaceZoom
    }px)`,
    maxWidth: `calc(100% - ${workspaceZoom * 30}px)`,
    textAlign,
    textTransform: textTransform || "none",
    background: hasSplitLineEffect
      ? "transparent"
      : fontBgColor || "rgba(0, 0, 0, 0.8)",
    color: fontColor || "#ffffff",
    letterSpacing: letterSpacing || "0px",
    lineHeight: lineHeight || 1.2,
    wordBreak: "break-word",
    padding: hasSplitLineEffect
      ? "0px"
      : subtitle.get("text").length === 0
      ? "0px"
      : `${Math.floor((SUB_INNER_OFFSET / 2) * workspaceZoom)}px`, // Equally spread the innerOffset to all the side.
    opacity,
    ...textEffectsStyle,
    ...bgEffectStyle,
  };

  return (
    <SubtitleTextWrapper
      key={id}
      className={`scene-item subtitle-wrapper ${id}`}
      id={id}
      data-id={id}
      data-drop-id={subtitle.getIn(["subtitleId", "dropId"])}
      data-subtitle-id={subtitle.get("id")}
      workspaceZoom={workspaceZoom}
      ref={subtitleTextRef}
      style={{ ...subtitleWrapperStyle }}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      {hasSplitLineEffect ? (
        splittedText
      ) : (
        <SubtitleText
          textDecoration={textDecoration}
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
      )}
    </SubtitleTextWrapper>
  );
};

SubtitleTextComponent.propTypes = {
  workspaceZoom: PropTypes.number,
  subtitle: PropTypes.object,
  id: PropTypes.string,
  opacity: PropTypes.string,
  onPointerDown: PropTypes.func,
  onPointerUp: PropTypes.func,
};

export default SubtitleTextComponent;
