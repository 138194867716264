import ActionTypes from "../../constants/action-types";

const { SET_MODAL } = ActionTypes;

export const initialState = {
  isShowModal: false,
  isDisableDrag: true,
  child: null,
  showUpgradeModal: false,
  showEnterpriseForm: false
};

export default function modalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MODAL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
