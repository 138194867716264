import { OrderedMap } from "immutable";
import { ANIMO_RENDER_TYPES, DEFAULT_FILTER_CODE, VALID_IMAGE_TYPES, isImageSubtypeOnly } from "../constants";

export const animoProjectGeneration = (projectJson, isAnimoSubtitle) => {
  let workspaceItems;

  if (isAnimoSubtitle) {
    workspaceItems = OrderedMap();
  } else {
    workspaceItems = projectJson.get("workspaceItems").filter((item) => {
      if (VALID_IMAGE_TYPES.includes(item.get("type"))) {
        if (!isImageSubtypeOnly(item.get("subType")) && (!item.get("filter") || item.get("filter") === DEFAULT_FILTER_CODE) && item.get("sourceType") !== 'svg') {
          return false;
        }
  
      } else if (!ANIMO_RENDER_TYPES.includes(item.get("type"))) {
        return false;
      }
      return true;
    });
  }

  projectJson = projectJson.set("workspaceItems", workspaceItems);
  return projectJson;
}