import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

export const LabelInput = styled.input.attrs({ type: "text" })`
  max-width: 100%;
  width: ${(props) => props.width || "100%"};
  border-radius: 8px;
  padding: 8px;
  margin: 14px 0 8px;
  height: 40px;
  font: ${font.normalBase};
  color: ${font.spanGrey};
  margin-bottom: 0px;
  padding-left: ${(props) => (props.icon ? "40px" : "0")};
  border: 1px solid ${(props) => props.theme.paymentBorderColor};
  &:focus {
    outline: 0px;
    box-shadow: none;
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
  &:hover {
    border-color: ${(props) => props.theme.hoverBorderColor};
  }
  ::placeholder {
    font: ${font.normalBase};
    color: ${(props) => props.theme.placeholderColor};
  }
`;
const WrapperInput = styled.div`
  position: relative;
`;
const InputImage = styled.img`
  position: absolute;
  left: 10px;
  top: 21.5px;
`;

const PaymentInput = ({
  width,
  placeholder,
  onChange,
  icon,
  onFocus,
  onBlur,
}) => {
  return (
    <WrapperInput>
      <LabelInput
        placeholder={placeholder}
        onChange={onChange}
        width={width}
        icon={icon}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <InputImage src={icon} />
    </WrapperInput>
  );
};

PaymentInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  icon: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default PaymentInput;
