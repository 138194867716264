import { Map, isImmutable } from "immutable";

export const removeInvalidProjectKeys = (projectDetails) => {
  let subtitleData;
  if (isImmutable(projectDetails)) {
    subtitleData = projectDetails.get("subtitleData");
    projectDetails = projectDetails.delete("subtitleData");
    projectDetails = projectDetails.delete("defaultSubtitle");
    projectDetails = projectDetails.delete("subtitle");
    projectDetails = projectDetails.delete("localSubtitle");
    let result = Map({ project: projectDetails });
    if (subtitleData) {
      result = result.set("subtitle_data", subtitleData);
    }
    return result;
  }

  subtitleData = projectDetails.subtitleData;
  projectDetails = { ...projectDetails };
  delete projectDetails.subtitleData;
  delete projectDetails.defaultSubtitle;
  delete projectDetails.subtitle;
  delete projectDetails.localSubtitle;
  const result = {
    project: projectDetails,
  };
  if (subtitleData) {
    result.subtitle_data = subtitleData;
  }
  return result;
};
