const openUrl = (url, isSame = false) => {
    if (isSame) {
        window.location.href = url;
        return true;
    }
    const newTab = window.open(url, "_blank");
    if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
        window.location.href = url;
    } else {
        newTab.focus();
    }
    return true;
};

export default openUrl;
