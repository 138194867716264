/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { useSelector } from "react-redux";
import Typography from "./Typography";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";
import PricingTooltipComponent from "./PricingTooltip";
import { getPlanName, getPlanNum } from "../helper/getPlanName";
import proceedWait from "../helper/proceedWait";
import { PLAN } from "../constants";
import { isValidHexColor } from "../helper/getColorAtPercentage";
import { secondsToMinute } from "../containers/timeline/timeline-helper";
import vmTheme from "../constants/theme";
import { addCacheClearQuery } from "../helper/addCacheClearQuery";

const TileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 316px;
  height: 48px;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-radius: 8px;
  padding: 6px 9px 6px 12px;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 0.2s;

  img {
    z-index: 1;
  }

  .upgradeAndFavWrapper {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  .upgrade-star-icon {
    margin-right: 8px;
    display: none;
    rect {
      fill: ${(props) => props.starColor};
    }
  }

  .star-icon {
    margin-right: 8px;
    circle {
      fill: ${(props) => props.starColor};
    }
  }

  &:hover {
    box-shadow: 0 0 11px ${(props) => props.theme.rgbaBlack26};
    border-color: ${(props) => props.theme.secondaryBorderColor};
    .title {
      font: ${font.boldMini_14};
    }
    .favourite-icon {
      opacity: 1;
    }
    .star-icon {
      display: none;
    }
    .upgrade-star-icon {
      display: block;
    }
  }

  & .favourite-icon {
    opacity: 0;
    height: 20px;
    width: 20px;
    &:hover {
      ${(props) =>
        !props.favourite &&
        css`
          opacity: 1;
          circle {
            fill: none;
            stroke: ${(props) => props.theme.secondaryBorderColor};
          }
          & #heart-path {
            fill: ${(props) => props.theme.favoriteIconColor};
            stroke: none;
          }
        `}
    }
    ${(props) =>
      props.favourite &&
      css`
        opacity: 1;
        circle {
          opacity: 1;
          fill: ${(props) => props.theme.favoriteIconColor};
        }
        & #heart-path {
          fill: ${(props) => props.theme.polarColor};
          stroke: none;
        }
      `}
  }

  ${(props) =>
    props.isDisable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
TileWrapper.displayName = "TileWrapper";

const TextWrapper = styled.div`
  z-index: 1;
  width: 162px;
  margin: 0px 16px 0px 6px;
`;
TextWrapper.displayName = "TextWrapper";

export const ProgressBar = styled.div`
  position: absolute;
  height: 100%;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color || "rgba(253, 193, 0, 0.3)"};
  left: 0;
  transition: all 0.4s linear;
  opacity: ${(props) => props.opacity};
  border-right: ${(props) => props.borderRight};
`;
ProgressBar.displayName = "ProgressBar";

const WavesContainer = styled.div`
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 2px;
  background: ${(props) => props.background || props.theme.musicBgColor};
  border-radius: 50%;
  z-index: 1;
`;
WavesContainer.displayName = "WavesContainer";

const wavesKeyframes = keyframes`
    0%{transform: scale(0.5);}
    50%{transform: scale(1);}
    100%{transform: scale(0.5);}
`;

const Wave = styled.div`
  height: ${(props) => props.height};
  width: 2px;
  background: ${(props) => props.theme.polarColor};
  border-radius: 4px;
  animation: ${wavesKeyframes} 1s linear infinite;
`;
Wave.displayName = "Wave";

const AudioContainer = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  transition: ease 0.5s;
  width: 152px;
  ${(props) =>
    props.isDisable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Audio = styled.div`
  position: relative;
  width: 140px;
  height: 125px;
  max-height: 125px;
  background: ${(props) => props.theme.audioBgColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  &:hover {
    box-shadow: 0 0 11px ${(props) => props.theme.rgbaBlack26};
    border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  }
`;

const AudioCircle = styled.div`
  width: 88px;
  height: 88px;
  position: relative;
  > svg {
    circle:nth-child(2) {
      transform-origin: 50% 50%;
      transform: rotate(270deg);
    }
  }
  > img {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Waves = () => {
  const waveHeights = [4, 8, 12, 20, 12, 8, 4];

  return (
    <>
      {waveHeights.map((wave, index) => (
        <Wave key={`wave-${index * 0.1}`} height={`${wave}px`} />
      ))}
    </>
  );
};

const MusicTile = ({
  src,
  icon,
  title,
  assetid,
  duration,
  background,
  isShowStar,
  isFavourite,
  toggleFavourite,
  isListView = true,
  containerRef,
  className = "",
  onPointerDown,
  onTileClick,
  style,
  libItemData,
  currentPlan,
  isDisable,
}) => {
  const [favourite, setFavourite] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [pricingInfo, setPrisingInfo] = useState(null);
  const [isFree, setIsFree] = useState(true);
  const [planName, setPlanName] = useState("");
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isError, setIsError] = useState(false);
  const audioRef = useRef();
  const musicTileRef = useRef();
  const theme = useSelector((state) => state.app.get("theme"));
  useEffect(() => {
    setFavourite(isFavourite);
  }, [isFavourite]);

  useEffect(() => {
    if (libItemData) {
      const { title, artist, collection_name, cost, plan } = libItemData;

      if (plan) {
        setIsUpgrade(Math.min(...plan) <= getPlanNum(currentPlan));
        setPlanName(getPlanName(plan));
        setIsFree(Math.min(...plan) === 1 || getPlanName(plan) === currentPlan);
      }
      const note = `Note: You must be on a ${planName} plan or above to use this Premium Music Track.`;
      const pricingData = {
        title,
        artist,
        collection_name: collection_name || "iStock",
        cost,
        note,
      };
      setPrisingInfo(pricingData);
    }
  }, [currentPlan, libItemData, planName]);

  /**
   * Function to set the progress bar percentage
   * @param {*} currentDuration
   * @returns
   */
  const propressPercentage = (currentDuration) => {
    if (currentDuration) {
      const percentageValue =
        (parseFloat(secondsToMinute(currentDuration)) /
          parseFloat(secondsToMinute(duration))) *
        100;
      return percentageValue;
    }
    return null;
  };

  /**
   * To start play audio on mouse enter
   */
  const mouseEnterHandler = async () => {
    if (!isError) {
      await proceedWait(1000);
      if (musicTileRef.current && musicTileRef.current.matches(":hover")) {
        audioRef.current.src = addCacheClearQuery(src);
        audioRef.current.load();
        audioRef.current
          .play()
          .then(() => setIsPlay(true))
          .catch(() => {});
      }
    }
  };

  /**
   * To stop audio on mouse leave
   */
  const mouseLeaveHandler = () => {
    if (isPlay && audioRef.current.currentTime > 0) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current.src = "";
    audioRef.current.removeAttribute("src");
    audioRef.current.load();
    setIsPlay(false);
    setPercentage(0);
  };

  /**
   * Event to get the current time of the audio
   * @param {Event} e
   */
  const timeUpdateHandler = (e) => {
    if (musicTileRef.current && !musicTileRef.current.matches(":hover")) {
      // stop audio on mouse leave
      mouseLeaveHandler();
    } else {
      if (e.target.currentTime) {
        setCurrentTime(e.target.currentTime);
        setPercentage(propressPercentage(e.target.currentTime));
      }
      if (percentage === 100 || e.target.currentTime === e.target.duration) {
        setIsPlay(false);
        setPercentage(0);
      }
    }
  };

  const audioErrorHandler = (errorEvent) => {
    const audioEl = errorEvent.target;
    const error = audioEl?.error;
    const isMediaError =
      audioEl instanceof HTMLAudioElement && error instanceof MediaError;
    let isError = true;

    if (isMediaError) {
      isError = error.code !== error.MEDIA_ERR_ABORTED;
    }

    setIsError(isError);
  };

  const circlePerimeter = 2 * Math.PI * 33;
  const progressDashoffset = circlePerimeter * (1 - percentage / 100);

  const assignRef = (r) => {
    musicTileRef.current = r;
    if (typeof containerRef === "function") {
      containerRef(r);
    } else if (containerRef && typeof containerRef === "object") {
      containerRef.current = r;
    }
  };
  return isListView ? (
    <TileWrapper
      ref={assignRef}
      className={className}
      favourite={favourite}
      onMouseEnter={mouseEnterHandler}
      onPointerDown={onPointerDown}
      onClick={onTileClick}
      style={style}
      isDisable={isDisable}
      starColor={
        planName === PLAN.TEAM
          ? vmTheme[theme].teamPlanColor
          : planName === PLAN.ENTERPRISE
          ? vmTheme[theme].enterpriseColor
          : ""
      }
    >
      <ProgressBar
        className="progress"
        width={`${percentage}%`}
        opacity={isPlay ? 1 : 0}
      />
      <WavesContainer
        background={isValidHexColor(background) ? background : vmTheme[theme].waveBgColor}
      >
        {isPlay ? (
          <Waves />
        ) : (
          <img
            draggable="false"
            src={icon || `${STATIC_PATH}music-play.svg`}
            alt="music"
            height="12px"
            width="12px"
          />
        )}
      </WavesContainer>
      <TextWrapper>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={audioRef}
          crossOrigin="anonymous"
          onTimeUpdate={timeUpdateHandler}
          onError={audioErrorHandler}
        />
        <Typography
          className="title"
          innerContent={title}
          font={font.mediumMini}
          color={vmTheme[theme].panelPrimaryColor}
          display="block"
          maxWidth="185px"
          cursor="pointer"
        />
        <Typography
          innerContent={secondsToMinute(isPlay ? currentTime : duration)}
          font={font.normalMicro}
          color={vmTheme[theme].panelPrimaryColor}
          display="block"
          cursor="pointer"
        />
      </TextWrapper>
      <div className="upgradeAndFavWrapper">
        {isShowStar && !isFree && !isUpgrade && (
          <PricingTooltipComponent
            tooltipId={`${src}-tooltip`}
            tooltipPosition="top-left"
            pricingInfo={pricingInfo || null}
          >
            <div data-tooltip-id={`${src}-tooltip`}>
              <img
                className="star-icon"
                src={`${STATIC_PATH}star-icon.svg`}
                data-lib-ignore={true}
                height="20px"
                width="20px"
                alt=""
              />
              <img
                className="upgrade-star-icon"
                src={`${STATIC_PATH}upgrade-star.svg`}
                data-lib-ignore={true}
                height="24px"
                width="70px"
                alt="upgrade-star-icon"
              />
            </div>
          </PricingTooltipComponent>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="favourite-icon"
          data-lib-ignore={true}
          onClick={() => {
            setFavourite(!favourite);
            toggleFavourite(!favourite, assetid);
          }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g
            id="Group_64287"
            data-name="Group 64287"
            transform="translate(22582 -994)"
          >
            <circle
              id="Ellipse_4172"
              data-name="Ellipse 4172"
              cx="10"
              cy="10"
              r="10"
              transform="translate(-22582 994)"
              opacity="0.6"
            />
            <g
              id="Group_56273"
              data-name="Group 56273"
              transform="translate(-22578.666 998.167)"
            >
              <rect
                id="Rectangle_10897"
                data-name="Rectangle 10897"
                width="13.333"
                height="13.333"
                fill="none"
              />
              <path
                id="heart-path"
                data-name="Path 59540"
                d="M75.776,109.97a3.244,3.244,0,0,0-2.684,1.407l-.124.158-.083-.106a3.2,3.2,0,0,0-2.69-1.459,3.1,3.1,0,0,0-3.21,3.209c0,2.745,4.971,6.792,5.333,7.083a.916.916,0,0,0,1.265,0c.364-.294,5.4-4.4,5.4-7.083A3.1,3.1,0,0,0,75.776,109.97Z"
                transform="translate(-66.318 -108.636)"
                fill="none"
                stroke="#fff"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>

        {/* <img alt="favourite" /> */}
      </div>
    </TileWrapper>
  ) : (
    <AudioContainer
      ref={assignRef}
      className={className}
      onMouseEnter={mouseEnterHandler}
      onPointerDown={onPointerDown}
      onClick={onTileClick}
      style={style}
      isDisable={isDisable}
    >
      <Audio>
        <AudioCircle>
          <svg viewBox="0 0 70 70" width="88" height="88">
            <circle
              cx="35"
              cy="35"
              r="33"
              fill={vmTheme[theme].audioSvgColor}
              strokeWidth="1.5"
              stroke={vmTheme[theme].veryLightGray}
            />
            <circle
              cx="35"
              cy="35"
              r="33"
              fill="transparent"
              strokeWidth="4"
              stroke={vmTheme[theme].secondaryBorderColor}
              strokeDasharray={circlePerimeter}
              strokeDashoffset={progressDashoffset}
            />
          </svg>
          <img
            draggable="false"
            src={`${STATIC_PATH}music-symbol.svg`}
            width="89px"
            height="89px"
            alt={title}
          />
        </AudioCircle>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={audioRef}
          crossOrigin="anonymous"
          onTimeUpdate={timeUpdateHandler}
        />
      </Audio>
      <Typography
        innerContent={title}
        color={vmTheme[theme].panelPrimaryColor}
        font={font.normalMini}
        display="block"
        padding="0 0 4px 0"
        enableTrim={true}
      />
    </AudioContainer>
  );
};

MusicTile.propTypes = {
  src: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  assetid: PropTypes.string,
  duration: PropTypes.number,
  background: PropTypes.string,
  isFavourite: PropTypes.bool,
  isShowStar: PropTypes.bool,
  toggleFavourite: PropTypes.func,
  isListView: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onPointerDown: PropTypes.func,
  onTileClick: PropTypes.func,
  libItemData: PropTypes.object,
  currentPlan: PropTypes.string,
  isDisable: PropTypes.bool,
};

export default MusicTile;
