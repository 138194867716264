/* eslint-disable react/prop-types */
import React from "react";
import ImagePrimitive from "./ImagePremitive";
import { STATIC_PATH } from "../constants/config";

export const SmartImage = ({
  imageSource = "",
  placeHolderImage = "",
  altImage = `${STATIC_PATH}defaultplaceholder.svg`,
  children = null,
  className = "",
  ...otherImgProps
}) => {
  return (
    <ImagePrimitive
      src={imageSource}
      render={({ src, loaded, error }) => {
        if (loaded) {
          return <img {...otherImgProps} draggable={false} src={src} alt="" className={className} />;
        }
        if (error) {
          return <img {...otherImgProps} draggable={false} src={altImage} alt="" className={className} />;
        }
        return <img {...otherImgProps} draggable={false} src={placeHolderImage} alt="" className={className} />;
      }}
    >
      {children}
    </ImagePrimitive>
  );
};
