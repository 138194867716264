import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import ProgressiveImage from "./workspace-progressive-image";
import Media from "../player/player-media";
import { APP_Z_INDICES } from "../../constants";
import AppConfig from "../../constants/config";

export const WorkspaceWrapper = styled.div.attrs((props) => {
  const { $isPlayAll } = props;

  const style = {};
  if ($isPlayAll) {
    style.zIndex = APP_Z_INDICES.workspaceWrapper.onPlay;
  }

  return {
    style,
  };
})`
  position: absolute;
  width: ${(props) => (props.isAnimoPlayer ? "100%" : "100vw")};
  height: ${(props) => (props.isAnimoPlayer ? "100%" : "100vh")};
  top: 0;
  left: 0;
  z-index: ${APP_Z_INDICES.workspaceWrapper.default};
  @media (max-width: 768px) {
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: ${(props) =>
        AppConfig.ANIMO_RENDER
          ? "transparent"
          : props.isAnimoPlayer
          ? "rgb(0, 0, 0)"
          : "rgb(247, 247, 248)"};
      z-index: -1;
    }
  }
`;
WorkspaceWrapper.displayName = "WorkspaceWrapper";

export const WorkspaceContainer = styled.div`
  position: absolute;
  transform-origin: 0 0;
  left: 0;
  top: 0;
  background-color: ${AppConfig.ANIMO_RENDER
    ? "transparent"
    : `${(props) => props.theme.polarColor}`};

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.scrollbarThumbBgColor};
    border-radius: 16px;
    background-clip: content-box;
    border: 4px solid transparent;
  }
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  // mozilla scrollbar
  scrollbar-width: auto;
  scrollbar-color: ${(props) => props.theme.scrollbarThumbBgColor} transparent;

  box-sizing: border-box;
  user-select: none;
  touch-action: none;

  * {
    box-sizing: border-box;
    user-select: none;
    touch-action: none;
  }
`;
WorkspaceContainer.displayName = "WorkspaceContainer";

export const SceneContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  box-sizing: border-box;
  user-select: none;
  touch-action: none;

  * {
    box-sizing: border-box;
    user-select: none;
    touch-action: none;
  }
`;
SceneContainer.propTypes = {
  $width: PropTypes.number,
  $height: PropTypes.number,
};
SceneContainer.displayName = "SceneContainer";

export const WorkspaceBGColorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
WorkspaceBGColorContainer.displayName = "WorkspaceBGColorContainer";

export const CustomBGRenderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  * {
    pointer-events: none;
  }

  > img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
`;
CustomBGRenderContainer.displayName = "CustomBGRenderContainer";

export const WorkspaceBGContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  * {
    pointer-events: none;
  }

  > div {
    position: absolute;
    left: 0;
    top: 0;

    > img,
    > video {
      position: absolute;
      left: 0;
      top: 0;

      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
`;
WorkspaceBGContainer.propTypes = {
  $width: PropTypes.string,
  $height: PropTypes.string,
  $transform: PropTypes.string,
  $filter: PropTypes.string,
};

export const CustomSVGRenderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &.csrc-loader {
    display: none;
    justify-content: center;
    align-items: center;

    > img {
      display: inline-block;
      width: 100%;
      max-height: 48px;
      object-fit: scale-down;
    }
  }

  &.csrc-loader.is-loading {
    display: flex;

    & ~ & {
      visibility: hidden;
    }
  }
`;
CustomSVGRenderContainer.displayName = "CustomSVGRenderContainer";

export const Vignette = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: radial-gradient(
    circle farthest-side at center center,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, var(--vignette)) 130%
  );
`;
Vignette.displayName = "Vignette";

export const WorkspaceItemContainer = styled.div`
  position: absolute;
  overflow: hidden;
  outline: 2px solid transparent;
  & .splEff-wrapper {
    position: absolute;
    left: 0;
    right: 0;
  }
  & .mask-split-collide {
    position: absolute;
    left: 0;
    right: 0;
  }
  &.ws-hover-default:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.DEFAULT};
  }
  &.ws-hover-chr:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.CHR};
  }
  &.ws-hover-txt:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.TEXT};
  }
  &.ws-hover-prop:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.PROP};
  }
  &.ws-hover-img:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.IMAGE};
  }
  &.ws-hover-scr:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.SCR};
  }
  &.ws-hover-group:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.GROUP};
  }
  &.ws-hover-gif:hover {
    outline-color: ${(props) => props.theme.ITEM_OUTLINE_COLOR.GIF};
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;
WorkspaceItemContainer.displayName = "WorkspaceItemStyled";

export const ProgressiveWorkspaceImage = styled(ProgressiveImage)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  &.progressive-img-loader.default-loader-style {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    max-height: 48px;
    object-fit: contain;
  }
`;
ProgressiveWorkspaceImage.displayName = "ProgressiveWorkspaceImage";

export const WorkspaceVideo = styled(Media)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
`;
WorkspaceVideo.displayName = "WorkspaceVideo";

export const WorkspaceBackdropContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
WorkspaceBackdropContainer.displayName = "WorkspaceBackdropContainer";

export const OverflowProp = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: transparent;

  &.scene-overflow {
    background-color: ${(props) => props.theme.outerLayerBgColor};
    opacity: ${AppConfig.ANIMO_RENDER ? 0 : 0.5};
    pointer-events: none;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
OverflowProp.displayName = "OverflowProp";

export const WorkspaceBackdropContent = styled(WorkspaceContainer)`
  /* background-color: transparent; */
  background-color: rgba(0, 0, 0, 0.5);
`;
WorkspaceBackdropContent.displayName = "WorkspaceBackdropContent";

const DotsKeyframe = keyframes`
  0%,
  20% {
    color: rgba(116, 106, 106, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
`;

export const BufferLoaderContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;

  > div {
    width: 5rem;
    height: 5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    z-index: ${APP_Z_INDICES.playerbufferloader.default};

    .buffer-spinner-wrapper {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .buffer-percent {
      color: white;
      display: block;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .loading::after {
      content: ".";
      animation: ${DotsKeyframe} 1s steps(5, end) infinite;
    }
  }
`;
BufferLoaderContainer.displayName = "BufferLoaderContainer";

export const ProgressLoaderContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;

  > div {
    width: 5rem;
    height: 5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${APP_Z_INDICES.playerprogressloader.default};

    .player-progress-percent {
      position: absolute;
      color: ${(props) => props.theme.primaryColor};
      display: block;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .player-progress-bar-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .player-progress-bar-wrapper .player-progress-bar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.35s stroke-dashoffset, 0.7s stroke;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }
`;
ProgressLoaderContainer.displayName = "ProgressLoaderContainer";

export const MultiSelectBounds = styled.div.attrs((props) => {
  const { $width, $height, $x, $y } = props;

  return {
    style: {
      width: `${$width}px`,
      height: `${$height}px`,
      transform: `translate(${$x}px, ${$y}px)`,
    },
  };
})`
  pointer-events: none;
  position: absolute;
  outline: 1px solid ${(props) => props.theme.secondaryBorderColor};
  background-color: ${(props) => props.theme.MultiSelectBgColor};
  left: 0;
  top: 0;
  transform-origin: 0 0;
`;
MultiSelectBounds.propTypes = {
  $width: PropTypes.number,
  $height: PropTypes.number,
  $x: PropTypes.number,
  $y: PropTypes.number,
};
MultiSelectBounds.displayName = "MultiSelectBounds";

export const WatermarkWrapper = styled.div`
  position: absolute;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
`;
WatermarkWrapper.displayName = "WatermarkWrapper";

export const WatermarkHolder = styled.div`
  position: absolute;
  z-index: inherit;
  cursor: pointer;
  img {
    transition: all 0.4s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;
WatermarkHolder.displayName = "WatermarkHolder";
