/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubtitleReconcile } from "../../../../redux/actions/subtitleUtils";
import { setSubtitleReconcileStatus } from "../../../../redux/actions/appUtils";
import withNotify from "../../../../helper/hoc/withNotify";
import SubtitleLoader from "../../../../common-components/SubtitleLoader";
import content from "../../../../constants/content";

class SubtitleReconcileComponent extends Component {
    constructor(props) {
        super(props);

        this.reconcileSubtitle = this.reconcileSubtitle.bind(this);

        /** @type {AbortController | null} */
        this.abortController = null;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.reconcileStatus.get("token") !== prevProps.reconcileStatus.get("token")
            && this.props.reconcileStatus.get("subtitleId")
        ) {
            this.reconcileSubtitle();
        }
    }

    async reconcileSubtitle() {
        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();
        const token = this.props.reconcileStatus.get("token");
        const { warn, success } = this.props.notify;
        try {
            await this.props.reconcileSubtitle(
                { projectId: this.props.projectId, subtitleId: this.props.reconcileStatus.get("subtitleId"), fromApi: this.props.reconcileStatus.get("fromApi") },
                { isReconcile: true, abortSignal: this.abortController.signal, notify: { warn, success } },
            );
        } catch (error) {
        } finally {
            const currentToken = this.props.reconcileStatus.get("token");
            if (token === currentToken) {
                this.props.setSubtitleReconcileStatus({ status: "idle" });
            }
        }
    }

    render() {
        return <SubtitleLoader
            isDisablePercentage
            show={this.props.reconcileStatus.get("status") === "pending"}
            imageSrc={"auto_cc.svg"}
            uploadTitle={content.LOADING_SUBTITLE}
            upContent={content.AUTO_CC_CN}
        />;
    }
}

SubtitleReconcileComponent.propTypes = {
    projectId: PropTypes.string.isRequired,
    reconcileStatus: PropTypes.object.isRequired,
    reconcileSubtitle: PropTypes.func.isRequired,
    setSubtitleReconcileStatus: PropTypes.func,
    notify: PropTypes.object,
}

const mapStateToProps = (state) => ({
    reconcileStatus: state.app.getIn(["subtitle", "reconcileStatus"]),
    projectId: state.userDetails.projectId,
});

const mapDispatchToProps = (dispatch) => ({
    reconcileSubtitle: (data, params) => dispatch(getSubtitleReconcile(data, params)),
    setSubtitleReconcileStatus: (payload) => dispatch(setSubtitleReconcileStatus(payload)),
});

const SubtitleReconcile = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubtitleReconcileComponent);

export default withNotify(SubtitleReconcile);
