/* eslint-disable no-restricted-syntax, no-unused-vars */

import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScreenshotStatus } from "../../redux/actions/appUtils";
import { saveScreenshot } from "../screenshot";
import { useCustomCallback } from "./useCustomCallback";
import proceedWait from "../proceedWait";

/**
 * @typedef TakeScreenshotParams
 * @property {Function | undefined} onScreenshotFail callback to call when screenshot is failed
 * @property {Function | undefined} onScreenshotSuccess callback to call when screenshot is successful
 * @property {number | undefined} playhead defaults to 2
 * @property {boolean | undefined} takeFirstVisibleItem
 * if true, ignores playhead and takes screenshot of first visible item.
 * falls back to playhead if no visible item is found.
 * defaults to false.
 * @property {number | undefined} maxRes defaults to 352
 */

/**
 * @callback TakeScreenshotCallback
 * @param {TakeScreenshotParams} params
 * @returns {Promise<Blob | undefined>}
 */

const useScreenshot = (props) => {
    const { forInit = false } = props;

    const [time, setTime] = useState(null);
    const dispatch = useDispatch();
    const initStages = useSelector((state) => state.app.get("initStages"));
    /** @type {"idle" | "success" | "failed" | "pending"} */
    const screenshotStatus = useSelector((state) => state.app.get("screenshotStatus"));
    const projectDetails = useSelector((state) => state.projectDetails);
    const canTakeScreenshot = Boolean(
        initStages.get("projectLoaded")
        && initStages.get("fontsLoaded")
        && (screenshotStatus === "idle" || screenshotStatus === "success" || screenshotStatus === "failed")
    );
    const canTakeInitialScreenshot = Boolean(forInit && canTakeScreenshot && screenshotStatus === "idle");

    const projectWidth = projectDetails.get("width");
    const projectHeight = projectDetails.get("height");
    const projectDuration = projectDetails.get("duration");
    const workspaceBG = projectDetails.get("workspaceBG");
    const workspaceItems = projectDetails.get("workspaceItems");

    const getFirstVisibleTimeRange = useCallback(() => {
        /** @type {null | { start: number, end: number }} */
        let timeRange = null;

        if (workspaceBG) {
            for (const item of workspaceBG.valueSeq()) {
                if (timeRange === null || item.get("playStart") < timeRange.start) {
                    timeRange = {
                        start: item.get("playStart"),
                        end: item.get("playEnd"),
                    };
                }
            }
        }

        for (const item of workspaceItems.valueSeq()) {
            if (timeRange === null || item.get("enterStart") < timeRange.start) {
                timeRange = {
                    start: item.get("enterStart"),
                    end: item.get("exitEnd"),
                };
            }
        }

        return timeRange;
    }, [
        workspaceBG,
        workspaceItems,
    ]);

    const _takeScreenshot = useCallback(
        /**
         * @param {TakeScreenshotParams} params
         */
        async (params = {}) => {
            const {
                onScreenshotFail,
                onScreenshotSuccess,
                playhead = 2,
                takeFirstVisibleItem = false,
                maxRes = 352,
            } = params;
            /** @type {Blob | undefined} */
            let res;

            try {
                let screenshotPlayhead = playhead > projectDuration ? projectDuration : playhead;
                if (takeFirstVisibleItem) {
                    const visibleTimeRange = getFirstVisibleTimeRange();
                    if (visibleTimeRange) {
                        screenshotPlayhead = visibleTimeRange.start;
                    }
                }

                dispatch(setScreenshotStatus({ status: "pending", playhead: screenshotPlayhead }));
                await proceedWait(100);

                let thumbWidth;
                let thumbHeight;
                const aspectRatio = projectWidth / projectHeight;

                if (projectWidth > projectHeight) {
                    // hor
                    thumbWidth = maxRes;
                    thumbHeight = thumbWidth / aspectRatio;
                } else if (projectWidth < projectHeight) {
                    // ver
                    thumbHeight = maxRes;
                    thumbWidth = aspectRatio * thumbHeight;
                } else {
                    // sqr
                    thumbWidth = maxRes;
                    thumbHeight = thumbWidth;
                }

                res = await saveScreenshot("#workspace", thumbWidth, thumbHeight);

                dispatch(setScreenshotStatus({ status: "done" }));
                if (typeof onScreenshotSuccess === "function") {
                    onScreenshotSuccess(res);
                }
            } catch (error) {
                dispatch(setScreenshotStatus({ status: "failed" }));
                if (typeof onScreenshotFail === "function") {
                    onScreenshotFail(error);
                }
            }

            return res;
        },
        [
            dispatch,
            projectWidth,
            projectHeight,
            projectDuration,
            getFirstVisibleTimeRange,
        ]
    );

    /** @type {TakeScreenshotCallback} */
    const takeScreenshot = useCustomCallback({
        memoizedCallback: _takeScreenshot,
    });

    const status = useMemo(() => {
        return {
            screenshotStatus,
            canTakeScreenshot,
            canTakeInitialScreenshot,
            takeScreenshot,
        };
    }, [
        screenshotStatus,
        canTakeScreenshot,
        canTakeInitialScreenshot,
        takeScreenshot,
    ]);

    return status;
}

export default useScreenshot;
