import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CloseIcon,
  Footer,
  Overlay,
  OverlayContainer,
  Wrapper,
  Backdrop,
} from "./modal-components";
import { STATIC_PATH } from "../../constants/config";
import Typography from "../../common-components/Typography";
import content from "../../constants/content";
import { font } from "../../constants/font";
import InputBox from "../../common-components/InputBox";
import Action from "../../common-components/Action";
import { setModalProps } from "../../redux/actions/modalAction";
import vmTheme from "../../constants/theme";

const { CREATE_FOLDER, CANCEL, CREATE } = content;

const Modal = ({ isShowModal, isDisableDrag, setModal, child }) => {
  const closeModal = () => {
    setModal({ isShowModal: false, isDisableDrag: true });
  };
  const theme = useSelector((state) => state.app.get("theme"));
  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OverlayContainer display={isShowModal ? "flex" : "none"}>
      <Backdrop onClick={closeModal} zIndex={0} />
      {child || (
        <Draggable disabled={isDisableDrag}>
          <Overlay>
            <CloseIcon
              src={`${STATIC_PATH}close-icon.svg`}
              alt="close"
              onClick={closeModal}
            />
            <Wrapper>
              <Typography
                content={CREATE_FOLDER}
                font={font.boldSmall}
                display="block"
                margin="0px 0px 32px 0px"
              />
              <InputBox placeholder="Enter a name" onChange={() => {}} />
              <Footer>
                <Action
                  background={vmTheme[theme].btnBgLightColor}
                  showHoverColor
                  hover
                  borderRadius="4px"
                  font={font.boldMini_14}
                  fontColor={vmTheme[theme].tertiaryColor}
                  width="110px"
                  height="36px"
                  margin="20px 16px 20px 0px"
                  onClick={closeModal}
                  text={CANCEL}
                />
                <Action
                  background={`transparent ${vmTheme[theme].headerColor} 0% 0% no-repeat padding-box`}
                  showHoverColor
                  borderRadius="4px"
                  font={font.boldMini_14}
                  fontColor={vmTheme[theme].polarColor}
                  width="110px"
                  height="36px"
                  margin="20px 0px"
                  onClick={() => {}}
                  text={CREATE}
                />
              </Footer>
            </Wrapper>
          </Overlay>
        </Draggable>
      )}
    </OverlayContainer>
  );
};

Modal.propTypes = {
  isShowModal: PropTypes.bool,
  isDisableDrag: PropTypes.bool,
  setModal: PropTypes.func,
  child: PropTypes.element,
};

const mapStateToProps = ({ modal }) => {
  const { isShowModal, isDisableDrag, child } = modal;
  return {
    isShowModal,
    isDisableDrag,
    child,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: bindActionCreators(setModalProps, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
