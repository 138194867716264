import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import vmTheme from "../constants/theme";
import ModalFooter from "./ModalFooter";
import Typography from "./Typography";
import { Modal } from "./modal";
import { font } from "../constants/font";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 24px 24px;
  justify-content: space-between;
`;

const ApplyAllModal = ({
  isOpen,
  onClose,
  modalData,
  onSave,
  confirmText,
  cancelText,
  headerText,
  textFont,
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  return (
    <Modal
      isDisabled={true}
      showModal={isOpen}
      useWrapper={false}
      onClose={onClose}
      borderRadius="16px"
    >
      <Wrapper padding="48px 24px 24px">
        {headerText && (
          <Typography
            content={headerText}
            enableTrim={false}
            color={vmTheme[theme].panelPrimaryColor}
            className="applyModal"
            font={font.largeBold}
            padding={"0px 0px 20px 0px"}
          />
        )}
        <Typography
          content={modalData}
          enableTrim={false}
          color={vmTheme[theme].panelPrimaryColor}
          className="applyModal"
          font={textFont}
        />
        <ModalFooter
          onSave={onSave}
          confirmText={confirmText}
          cancelText={cancelText}
          onClose={onClose}
          fontsColor={vmTheme[theme].polarC}
          widthVal={"110px"}
          backgroundColor={vmTheme[theme].secondaryBorderColor}
          cancelBackground={vmTheme[theme].btnBgLightColor}
        />
      </Wrapper>
    </Modal>
  );
};

ApplyAllModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  modalData: PropTypes.string,
  onSave: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  headerText: PropTypes.string,
  textFont: PropTypes.string
};

export default ApplyAllModal;
