/* eslint-disable */
const strokeWidth = 4;
export function getPath(item, zoomFactor, storedItem) {
    let pathData = item.get("pathData").toJS();
    pathData.colors = item.get("colors").toJS();
    let width = item.get("width") * zoomFactor;
    let height = item.get("height") * zoomFactor;

    if(pathData.isStroke === true){
        width -= strokeWidth;
        height -= strokeWidth;
    }
    let paths = [];
    if(item.get("subType") === "CSTMPTH"){
        if(pathData.type === "rectangle")
            paths = getRectangle(width, height, pathData);
        else if(pathData.type === "star" || pathData.type === "gons"){
            var spikes = pathData.spikes;
            var deg = 0;
            var rx = 0, ry = 0, cx = 0, cy = 0;
            switch (spikes){
                case 4:
                    deg=45;
                break;
                case 5:
                    cy=26
                    rx=12
                break;
                case 6:
                    deg=30;
                    cy=0;
                    rx=37.5;
                    ry=-12.5;
                break;
                case 7:
                    cy=12
                    rx=6			
                break;
                case 8:
                    rx=18
                    ry=18
                break;
                case 9:
                    rx=3;
                    ry=-5;
                    cy=8;
                break;
                case 10:
                    ry=11
                break;		
                case 12:
                    rx=10
                    ry=10
                break;
                case 16:
                    rx=5
                    ry=5
                break;
                case 24:
                    ry=-12;
                break;
                case 32:
                    ry=-12;
                    rx=-4
                break;
                default:
                break;
            }
         
            paths =  getStar(width, height, pathData, {spikes : spikes, rx : rx, ry : ry, cx : cx, cy : cy, deg : deg});
        }
        else if(pathData.type === "right_arrow" || pathData.type === "left_arrow" || pathData.type === "up_arrow" || pathData.type === "down_arrow" || pathData.type === "left_right" || pathData.type === "up_down" || pathData.type === "pentagon_arrow" || pathData.type === "chevron" || pathData.type === "flat_arrow_down" || pathData.type === "quad_arrow" || pathData.type === "notched_right_arrow" || pathData.type === "bent_up_arrow" || pathData.type === "left_up_arrow" || pathData.type === "bent_arrow" || pathData.type === "u_turn_arrow")
            paths =  getArrow(width, height, pathData);
        if(pathData.type === "ribbon" || pathData.type === "dumbbell" || pathData.type === "circle_dumbbell" || pathData.type === "line")
            paths =  getLine(width, height, pathData);
        else if(pathData.type === "circle")
            paths =  getCircle(width, height, pathData);
        else if(pathData.type === "l")
            paths =  getLShape(width, height, pathData);
        else if(pathData.type === "stripe")
            paths =  getStripe(width, height, pathData);
        else if(pathData.type === "diamond" || pathData.type === "pentagon")
            paths =  getSimplePath(width, height, pathData);
        else if(pathData.type === "hexagon")
            paths =  getHexagon(width, height, pathData);
        else if(pathData.type === "lightning")
            paths =  getLightning(width, height, pathData);
        else if(pathData.type === "parallelogram")
            paths =  getParallelogram(width, height, pathData);
        else if(pathData.type === "trapezoid")
            paths =  getTrapezoid(width, height, pathData);
        else if(pathData.type === "collate")
            paths = getCollate(width, height, pathData);
        else if(pathData.type === "pie" || pathData.type === "chord" || pathData.type === "blockarc")
            paths = getPie(width, height, pathData);
        else if(pathData.type === "half_frame")
            paths = getHalfFrame(width, height, pathData);
        else if(pathData.type === "frame")
            paths = getFrame(width, height, pathData);
        else if(pathData.type === "triangle")
            paths = getTriangle(width, height, pathData);
        else if(pathData.type === "plus")
            paths = getPlus(width, height, pathData);
        else if(pathData.type === "multiply")
            paths = getMultiply(width, height, pathData);
        else if(pathData.type === "minus")
            paths = getMinus(width, height, pathData);
        else if(pathData.type === "cube" || pathData.type === "cylinder" || pathData.type === "grouped_triangle" || pathData.type === "grouped_rectangle" || pathData.type === "equal_to" || pathData.type === "curved_right_arrow" || pathData.type === "curved_left_arrow" || pathData.type === "curved_up_arrow" || pathData.type === "curved_down_arrow" || pathData.type === "striped_right_arrow")
            paths = getGroupPath(width, height, pathData, storedItem);
        else if(pathData.type === "plaque")
            paths = getPlaque(width, height, pathData);
        else if(pathData.type === "moon")
            paths = getMoon(width, height, pathData);
        else if(pathData.type === "donut")
            paths = getDonut(width, height, pathData);
        else if(pathData.type === "file")
            paths = getFile(width, height, pathData);
        else if(pathData.type === "flowchart_connector")
            paths = getFlowchart(width, height, pathData);
        else if(pathData.type === "tear_drop")
            paths = getTearDrop(width, height, pathData);
        else if(pathData.type === "circle_callout" || pathData.type === "rectangle_callout" || pathData.type === "rounded_rectangle_callout" || pathData.type === "bubble_callout")
            paths = getCallout(width, height, pathData, storedItem);
        else if(pathData.type === "no_symbol")
            paths = getSymbol(width, height, pathData);
        else if(pathData.type === "scalene_triangle")
            paths = getScalene(width, height, pathData);
        else if(pathData.type === "repeated_arrow" || pathData.type === "repeated_bar" || pathData.type === "repeated_double" || pathData.type === "repeated_triple")
            paths = getRepeated(width, height, pathData);
       
    }else if(item.get("subType") === "BANNER"){
        paths = getBanner(item, zoomFactor);
    }
    return paths;
}



function getRectangle(width, height, pathData){
    
    var data = "";
    var pathPoints = pathData.points;
    var controlPoints = pathData.controlPoints;

    var tl = 0, tr = 0, bl = 0, br = 0, tlRounded = true, trRounded = true, blRounded = true, brRounded = true;
    var newControlPoints = {};
    for(var key in controlPoints){
        if(key === "tl"){
            if(controlPoints.tl.moveType === "x"){
                tl = (controlPoints.tl.x /100) * width;
            }
            else if(controlPoints.tl.moveType === "y"){
                tl = (controlPoints.tl.y /100) * height;
            }
            if(controlPoints.tl.isRounded === false)
                tlRounded = false;

            if(controlPoints.tl.mirror === "full"){
                tr = bl = br = tl;
                if(controlPoints.tl.isRounded === false)
                    trRounded = brRounded = blRounded = false;
            }
            else if(controlPoints.tl.mirror === "x"){
                tr = tl;
                if(controlPoints.tl.isRounded === false)
                    trRounded = false;
            }
            else if(controlPoints.tl.mirror === "y"){
                bl = tl;
                if(controlPoints.tl.isRounded === false)
                    blRounded = false;
            }
            else if(controlPoints.tl.mirror === "diagonal"){
                br = tl;
                if(controlPoints.tl.isRounded === false)
                    brRounded = false;
            }
        }
        else if(key === "tr"){
            if(controlPoints.tr.moveType === "x"){
                tr = ((100 - controlPoints.tr.x) /100) * width;
            }
            else if(controlPoints.tr.moveType === "y"){
                tr = (controlPoints.tr.y /100) * height;
            }
            if(controlPoints.tr.isRounded === false)
                trRounded = false;

            if(controlPoints.tr.mirror === "full"){
                tl = bl = br = tr;
                if(controlPoints.tr.isRounded === false)
                    tlRounded = brRounded = blRounded = false;
            }
            else if(controlPoints.tr.mirror === "x"){
                tl = tr;
                if(controlPoints.tr.isRounded === false)
                    tlRounded = false;
            }
            else if(controlPoints.tr.mirror === "y"){
                br = tr;
                if(controlPoints.tr.isRounded === false)
                    brRounded = false;
            }
            else if(controlPoints.tr.mirror === "diagonal"){
                bl = tr; 
                if(controlPoints.tr.isRounded === false)
                    blRounded = false;
            }
        }
        else if(key === "br"){
            if(controlPoints.br.moveType === "x"){
                br = ((100 - controlPoints.br.x) /100) * width;
            }
            else if(controlPoints.br.moveType === "y"){
                br = (controlPoints.br.y /100) * height;
            }

            if(controlPoints.tl.isRounded === false)
                tlRounded = false;

            if(controlPoints.br.mirror === "full"){
                tl = tr = bl = br;
                if(controlPoints.br.isRounded === false)
                    tlRounded = trRounded = blRounded = false;
            }
            else if(controlPoints.br.mirror === "x"){
                bl = br;
                if(controlPoints.br.isRounded === false)
                    blRounded = false;
            }
            else if(controlPoints.br.mirror === "y"){
                tr = br;
                if(controlPoints.br.isRounded === false)
                    trRounded = false;
            }
            else if(controlPoints.br.mirror === "diagonal"){
                tl = tr;
                if(controlPoints.br.isRounded === false)
                    tlRounded = false;
            }
        }
        else if(key === "bl"){
            if(controlPoints.bl.moveType === "x"){
                bl = (controlPoints.bl.x /100) * width;
            }
            else if(controlPoints.bl.moveType === "y"){
                bl = (controlPoints.bl.y /100) * height;
            }

            if(controlPoints.bl.isRounded === false)
                blRounded = false;

            if(controlPoints.bl.mirror === "full"){
                tl = tr = br = bl;
                if(controlPoints.bl.isRounded === false)
                    tlRounded = trRounded = brRounded = false;
            }
            else if(controlPoints.bl.mirror === "x"){
                br = bl;
                if(controlPoints.bl.isRounded === false)
                    brRounded = false;
            }
            else if(controlPoints.bl.mirror === "y"){
                 tl = bl;
                 if(controlPoints.bl.isRounded === false)
                    tlRounded = false;
            }
            else if(controlPoints.bl.mirror === "diagonal"){
                tr = bl;
                if(controlPoints.bl.isRounded === false)
                    trRounded = false;
           }
        }
    }

    var least = (height < width) ? height : width
   
    tl = (tl / width) * least;
    tr = (tr / width) * least;
    bl = (bl / width) * least;
    br = (br / width) * least;

    tl = (tl<=0)?0:(tl<=width)?tl:width;
    tr = (tr<=0)?0:(tr<=width)?tr:width;
    bl = (bl<=0)?0:(bl<=width)?bl:width;
    br = (br<=0)?0:(br<=width)?br:width;

    if(pathData.controlPoints.tl !== undefined){
        newControlPoints.tl = {};
        newControlPoints.tl.x = (tl / width) * 100;
        newControlPoints.tl.y = pathData.controlPoints.tl.y;
    }

    if(pathData.controlPoints.tr !== undefined){
        newControlPoints.tr = {};
        newControlPoints.tr.x = ((width - tr) / width) * 100;
        newControlPoints.tr.y = pathData.controlPoints.tr.y;
    }

    if(pathData.controlPoints.bl !== undefined){
        newControlPoints.bl = {};
        newControlPoints.bl.x = (bl / width) * 100;
        newControlPoints.bl.y = pathData.controlPoints.bl.y;
    }

    if(pathData.controlPoints.br !== undefined){
        newControlPoints.br = {};
        newControlPoints.br.x = ((width - br) / width) * 100;
        newControlPoints.br.y = pathData.controlPoints.br.y;
    }
    
    var ratio = 1/2;
    var xAdder = 0, yAdder = 0, outTanX = 0, outTanY = 0;
    for(var i = 0; i < pathPoints.length;i++){
        let xpos = ((pathPoints[i].x/100) * width);
        let ypos = ((pathPoints[i].y/100) * height);
        
        if(i === 0){
            xAdder = tl; yAdder = 0;
            data ="M"+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder);
        }else{
            outTanX = 0; outTanY = 0;
            if(i===1){
                xAdder=-tr
                yAdder=0
                outTanX = trRounded?tr * ratio:0
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder + outTanX) +","+(ypos + yAdder);
            }else if(i===2){
                xAdder=0
                yAdder=tr
                outTanY = trRounded?tr * ratio:0	
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder - outTanY) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder);
            }else if(i===3){
                xAdder=0;
                yAdder=-br;
                outTanY = brRounded?br * ratio:0
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder + outTanY);
            }else if(i===4){
                xAdder=-br
                yAdder=0
                outTanX = brRounded?-br * ratio:0
                data += " "+ (xpos + xAdder - outTanX) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder);
            }else if(i===5){
                xAdder=bl
                yAdder=0
                outTanX = blRounded?-bl * ratio:0
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder + outTanX) +","+(ypos + yAdder);
            }else if(i===6){
                xAdder=0
                yAdder=-bl	
                outTanY = blRounded?-bl * ratio:0
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder - outTanY) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder);
            }else if(i===7){
                xAdder=0
                yAdder=tl	
                outTanY = tlRounded?tl * ratio:0
                data += " "+ (xpos + xAdder) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +" C"+ (xpos + xAdder) +","+(ypos + yAdder - outTanY);
            }
            
        }

    }

    let xpos = ((pathPoints[0].x/100) * width);
    let ypos = ((pathPoints[0].y/100) * height);
    xAdder = tl; yAdder = 0;
    outTanX = tlRounded?tl * ratio:0
    data += " "+ (xpos + xAdder - outTanX) +","+ (ypos + yAdder) +" "+ (xpos + xAdder) +","+ (ypos + yAdder) +"z";

    let dataP = {data : data, color : pathData.colors[Object.keys(pathData.colors)[0]]};
   
    if(pathData.isStroke === true){
        dataP.isStroke = true;
        let shapeX = -strokeWidth, shapeY = -strokeWidth, shapeWidth = width + (strokeWidth * 2), shapeHeight = height + (strokeWidth * 2);
        dataP.pathStyle = {left : strokeWidth / 2, top : strokeWidth / 2};
        var shapeStyle = {width : width + strokeWidth, height : height + strokeWidth};
    }
    else 
        dataP.isFill = true;

    return {data : data, pathDetails : [dataP], pathData : pathData, shapeStyle : shapeStyle, newControlPoints : newControlPoints};
    
}

function getStar(width, height, pathData, sData){
   
    var data = "";
    var controlPoints = pathData.controlPoints;
    
    var offset = 0;
    
    if(pathData.type === "star"){
        offset = ((controlPoints.c.y / 100) * 500 ) / 250;
        if(offset <= 0)
            offset = 0;
     
        if(offset >= 0.95)
            offset = 0.95;
    }
    else if(pathData.type === "gons")
        offset = ((pathData.offset / 100) * 500 ) / 250;

    var spikes = sData.spikes;

    var rxInc = sData.rx, ryInc = sData.ry, cxInc = sData.cx, cyInc = sData.cy;
    var centerX = 250 + cxInc;
    var centerY = 250 + cyInc;
    var radiusX =  centerX + rxInc;
    var radiusY = centerY + ryInc;
    // var offset = 0;
    var angle = 0;
    var deg = 360/(spikes*2);

    var x_percentArr = [];
    var y_percentArr = [];

    var spikeLen = 360/deg;
	var degree = deg + sData.deg;
  
    for (var i=0;i<spikeLen;i++){
        var rx,ry;
        if ((i%2)===0) {
            rx =  radiusX;
            ry = radiusY;		
        }else {
            rx = radiusX - (radiusX * offset)
            ry = radiusY - (radiusY * offset)
        }
        angle = degree*(Math.PI/180)
        var  boxx  = centerX + Math.sin(angle) * rx;
        var  boxy = centerY + Math.cos(angle) * ry;
        
        x_percentArr.push(((boxx/500)*100).toFixed(2))
        y_percentArr.push(((boxy/500)*100).toFixed(2))
        degree = degree + deg;
    }

    var xpos2 = ((x_percentArr[0]/100) * width);
    var ypos2 = ((y_percentArr[0]/100) * height);
    
    data = "M"+(xpos2)+","+ypos2+" C"+(xpos2)+","+ypos2;

    for(i=1; i < x_percentArr.length;i++){
        var xpos=((x_percentArr[i]/100) * width);
        var ypos=((y_percentArr[i]/100) * height);
        data = data+" "+(xpos)+","+(ypos)+" "+xpos+","+ypos+" C"+(xpos)+","+(ypos);
    }
    data = data+" "+(xpos2)+","+ypos2+" "+(xpos2)+","+(ypos2)+"z";

    if(pathData.controlPoints.c !== undefined){
        var positions = {c : {}};
        positions.c.x = pathData.controlPoints.c.x; 
        positions.c.y = ((offset / height)) * 2 * 100 * 100;
    }

    let pathD = {data : data, color : pathData.colors[Object.keys(pathData.colors)[0]]};

    if(pathData.isStroke === true){
        pathD.isStroke = true;
        pathD.pathStyle = {left : strokeWidth / 2, top : strokeWidth / 2};
        var shapeStyle = {width : width + strokeWidth, height : height + strokeWidth}
    }
    else 
        pathD.isFill = true;
    
	return {data : data, pathDetails : [pathD], pathData : pathData, shapeStyle : shapeStyle, newControlPoints : positions};
}

function getArrow(width, height, pathData){
    var data = "", xpos, ypos;
    var controlPoints = pathData.controlPoints;
    var midX = width / 2;
    var midY = height / 2;

    if(pathData.type === "right_arrow"){
        var least = height;
        var tr = width - (((100 - controlPoints.t.x) * least) / 100)// width - ((controlPoints.t.x / 100) * least);
        
        if(tr < 0)
            tr = 0;
        
        if(tr >= width)
            tr = width;

        var currentXValue = tr;
        var _thickn = (least/2) - (controlPoints.l.y / 100) * least;
    
        if(_thickn >= least / 2)
            _thickn = least / 2;
        
        if(_thickn <= 0.01 * least)
            _thickn = 0.01 * least

        data = "M"+ currentXValue +",0 C"+ currentXValue +",0"

		xpos = width; ypos = height / 2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = midY - _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        var newControlPoints = {t : {}, l : {}};
        newControlPoints.t.x = (tr / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((midY - _thickn)) / height) * 100;
    
    }else if(pathData.type === "left_arrow"){
        var least = height;
    
        var tr = (((controlPoints.t.x) * least) / 100)
    
        if(tr > width)
            tr = width;
        
        if(tr < 0)
            tr = 0;
            
        var currentXValue = tr;
        var _thickn = (least/2) - (controlPoints.r.y / 100) * least;

        if(_thickn <= 0.01 * least)
            _thickn = least * 0.01;
        
        if(_thickn >= least/2)
            _thickn = least/2

        xpos = 0; ypos = height / 2;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = midY - _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = currentXValue; ypos = midY + _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height / 2;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {t : {}, r : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (((midY - _thickn)) / height) * 100;
    }else if(pathData.type === "up_arrow"){
        var least = width;
    
        var tr = (((controlPoints.l.y) * least) / 100)
    
        if(tr > height)
            tr = height;
        if(tr <= 0)
            tr = 0;
            
        var currentYValue = tr;
        
        var _thickn = (least/2) - (controlPoints.b.x / 100) * least;

        if(_thickn >= least/2)
            _thickn = least/2

        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        xpos = 0; ypos = currentYValue;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width / 2; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + _thickn; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + _thickn; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - _thickn; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - _thickn; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = 0; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width / 2; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {l : {}, b : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (currentYValue / height) * 100;
        newControlPoints.b.x = (((midX - _thickn)) / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;
        
    }else if(pathData.type === "down_arrow"){
        var least = width;
    
        var tr = height - (((100 - controlPoints.l.y) * least) / 100)// width - ((controlPoints.t.x / 100) * least);
    
        if(tr < 0)
            tr = 0;
        if(tr >= height)
            tr = height;
            
        var currentYValue = tr;
        
        var _thickn = (least/2) - (controlPoints.t.x / 100) * least;

        if(_thickn >= least/2)
            _thickn = least/2;
        
        if(_thickn <= 0.01 * least)
            _thickn = 0.01 * least

        xpos = width; ypos = currentYValue;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width / 2; ypos = height;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - _thickn; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - _thickn; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + _thickn; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + _thickn; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = width; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width / 2; ypos = height;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {l : {}, t : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (currentYValue / height) * 100;
        newControlPoints.t.x = (((midX - _thickn)) / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        
    }else if(pathData.type === "left_right"){
        var least = height;
    
        var tr =(((controlPoints.t.x) * least) / 100)// width - ((controlPoints.t.x / 100) * least);
    
        if(tr > width / 2)
            tr = width / 2;

        if(tr <= 0)
            tr = 0
            
        var currentXValue = tr;
        
        var _thickn = (least/2) - (controlPoints.r.y / 100) * least;

        if(_thickn >= least/2)
            _thickn = least/2

        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        xpos = currentXValue; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = height / 2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width - currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width - currentXValue; ypos = midY + _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = currentXValue; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {r : {}, t : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        newControlPoints.r.x = ((width - currentXValue) / width) * 100;
        newControlPoints.r.y = (((midY - _thickn)) / height) * 100;
    }
    else if(pathData.type === "up_down"){
        var least = width;
    
        var tr =(((controlPoints.l.y) * least) / 100)// width - ((controlPoints.t.x / 100) * least);
    
        if(tr > height / 2)
            tr = height / 2;
        if(tr <= 0)
            tr = 0;
            
        var currentYValue = tr;
        
        var _thickn = (least/2) - (controlPoints.b.x / 100) * least;

        if(_thickn >= least/2)
            _thickn = least/2

        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        xpos = 0; ypos = currentYValue;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width / 2; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + _thickn; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + _thickn; ypos = height - currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = height - currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width/2; ypos = height;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

		xpos = 0; ypos = height - currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - _thickn; ypos = height - currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - _thickn; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {l : {}, b : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (currentYValue / height) * 100;
        newControlPoints.b.x = (((midX - _thickn)) / width) * 100;
        newControlPoints.b.y = ((height - currentYValue) / height) * 100;
    }
    else if(pathData.type === "pentagon_arrow"){
        var least = height;
    
        var tr = width - (((100 - controlPoints.t.x) * least) / 100)// width - ((controlPoints.t.x / 100) * least);
    
        if(tr < 0)
            tr = 0;
        if(tr >= width)
            tr = width;
            
        var currentXValue = tr;
            
        xpos = 0; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height / 2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {t : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
    }
    else if(pathData.type === "chevron"){
        var least = height;
    
        var tr = width - (((100 - controlPoints.t.x) * least) / 100)
    
        if(tr < 0.1 * width)
            tr = 0.1 * width;

        if(tr >= width)
            tr = width;
            
        var currentXValue = tr;
            
        xpos = 0; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height / 2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = height /2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {t : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
    }
    else if(pathData.type === "flat_arrow_down"){
        var least = width;
    
        var tr = height - (((100 - controlPoints.l.y) * least) / 100)
    
        if(tr < 0)
            tr = 0;

        if(tr >= height)
            tr = height
            
        var currentYValue = tr;
            
        xpos = 0; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = currentYValue;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width/2; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = currentYValue;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {l : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (currentYValue / height) * 100;
    }
    else if(pathData.type === "quad_arrow"){
        var least = height < width ? height : width;
       
        var thick1 = (least/2) - (controlPoints.t.x / 100) * least;

        var thick2 = (least/2) - (controlPoints.m.x / 100) * least;
    
        var r = (controlPoints.r.y * least) / 100;

        if(r >=  ((least / 2) - thick1))
            r = ((least / 2) - thick1);

        if(r < 0)
            r = 0;

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least;
        
        if(thick1 >= least / 2)
            thick1 = least / 2;

        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least;
        
        if(thick2 >= thick1)
            thick2 = thick1;

       
        xpos = midX; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = midX + thick1; ypos = r;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick2; ypos = r;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick2; ypos = midY - thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick1; ypos = midY - thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width - r; ypos = midY - thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - r; ypos = midY - thick1;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = midY;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;


        xpos = width - r; ypos = midY + thick1;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - r; ypos = midY + thick2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick2; ypos = midY + thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + thick2; ypos = height - r;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick1; ypos = height - r;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        
        xpos = midX - thick1; ypos = height - r;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - thick2; ypos = height - r;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - thick2; ypos = midY + thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = r; ypos = midY + thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = r; ypos = midY + thick1;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = midY;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;


        xpos = r; ypos = midY - thick1;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = r; ypos = midY - thick2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - thick2; ypos = midY - thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - thick2; ypos = r;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - thick1; ypos = r;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {t : {}, m : {}, r : {}};
        newControlPoints.t.x = ((midX - thick1) / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.m.x = ((midX - thick2) / width) * 100;
        newControlPoints.m.y = (r/height) * 100;

        newControlPoints.r.x = controlPoints.r.x;           
        newControlPoints.r.y = (r/height) * 100;
        
    }
    else if(pathData.type === "notched_right_arrow"){
        var least = height;
    
        var tr = width - (((100 - controlPoints.t.x) * least) / 100)
        var _thickn = (least/2) - (controlPoints.l.y / 100) * least;

        if(tr < 0)
            tr = 0;
        if(tr >= width)
            tr = width;

        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        if(_thickn >= least/2)
            _thickn = least/2
            
        var currentXValue = tr;
            
        xpos = 0; ypos = midY - _thickn;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = midY;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = midY - _thickn;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {t : {}, l : {}};
        newControlPoints.t.x = (tr / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((midY - _thickn)) / height) * 100;
    }
    
    else if(pathData.type === "bent_up_arrow"){
        var least = height < width ? height : width;
        
        var thick1 = least - ((controlPoints.t.x / 100) * least);

        var thick2 = least - ((controlPoints.l.y / 100) * least);
    
        var m = (controlPoints.m.y * least) / 100; 

        if(m < 0)
            m = 0;

        if(m >=  least / 2)
            m = least / 2;

        if(thick2 > least / 2)
            thick2 = least / 2
        
        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick1 >= least)
            thick1 = least

        if(thick2 >= thick1)
            thick2 = thick1

        let midX = width - thick1 / 2;
        let midY = height - thick2 / 2;

        xpos = 0; ypos = midY - thick2/2;
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

        xpos = midX - thick2/2; ypos = midY - thick2/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - thick2/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - thick1/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + thick2/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick2/2; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = midY - thick2/2;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {t : {}, l : {}, m : {}};
        newControlPoints.t.x = ((width - thick1) / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((height - thick2)) / height) * 100;

        newControlPoints.m.x = ((midX - thick2/2) / width) * 100;
        newControlPoints.m.y = (m / height) * 100;
    }
    else if(pathData.type === "left_up_arrow"){

        var least = height < width ? height : width;
        
        var thick1 = least - ((controlPoints.t.x / 100) * least);

        var thick2 = least - ((controlPoints.m2.y / 100) * least);
    
        var m = (controlPoints.m1.y * least) / 100; 

        if(m >=  ((least) - thick1))
            m = ((least) - thick1)

        if(m < 0)
            m = 0;

        if(thick2 > least / 2)
            thick2 = least / 2
        
        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick1 >= least)
            thick1 = least

        if(thick2 >= thick1)
            thick2 = thick1

        let midX = width - thick1 / 2;
        let midY = height - thick1 / 2;

        
        xpos = 0; ypos = midY;
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

        xpos = m; ypos = midY - thick1/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = m; ypos = midY - thick2/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - thick2/2; ypos = midY - thick2/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX - thick2/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX - thick1/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = midX + thick2/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = midX + thick2/2; ypos = midY + thick2/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = m; ypos = midY + thick2/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = m; ypos = midY + thick1/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = midY;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {t : {}, m1 : {}, m2 : {}};
        newControlPoints.t.x = ((width - thick1) / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.m2.x = (((midX - thick2/2)) / width) * 100;
        newControlPoints.m2.y = (((midY - thick2/2)) / height) * 100;

        newControlPoints.m1.x = ((midX - thick2/2) / width) * 100;
        newControlPoints.m1.y = (m / height) * 100;
    }
    else if(pathData.type === "bent_arrow"){
      
        var least = height < width ? height : width;
        
        var t1 = (controlPoints.t1.x * least) / 100;
       
        var t2 = width - (((100 - controlPoints.t2.x) * least) / 100)

        var thick1 = (controlPoints.r.y / 100) * least;
        var thick2 = (controlPoints.b.x / 100) * least;

        if(t1 >= least/2)
            t1 = least/2
        
        if(t1 <= 0)
            t1 = 0;

        if(t2 >= width)
            t2 = width

        if((width - t2) >= least/2 )
            t2 = width - least / 2

        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick1 >=  least)
            thick1 = least;

        if(thick2 >= thick1)
            thick2 = thick1

        let midX = thick2 / 2;
        let midY = thick1 / 2;

        var curve2 = (t1>thick2)?t1 - thick2:0

        xpos = t2; ypos = 0;
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

        xpos = width; ypos = midY;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = t2; ypos = thick1;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = t2; ypos = midY + midX;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = thick2 + curve2; ypos = (midY + midX);
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos - curve2) +","+(ypos);//Curve point

        xpos = thick2; ypos = (midY + midX) + curve2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos + curve2);//Curve point

        xpos = thick2; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = (midY - midX) + t1;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +"," + (ypos - t1); //Curve point

        xpos = t1; ypos = (midY - midX);
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos + t1) +","+ypos; //Curve point

        xpos = t2; ypos = midY - midX;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = t2; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        var newControlPoints = {t1 : {}, t2 : {}, r : {}, b : {}};
        newControlPoints.t1.x = (t1 / width) * 100;
        newControlPoints.t1.y = controlPoints.t1.y;

        newControlPoints.t2.x = (t2 / width) * 100;
        newControlPoints.t2.y = controlPoints.t2.y;

        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (thick1 / height) * 100;

        newControlPoints.b.x = (thick2 / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;

    }
    else if(pathData.type === "u_turn_arrow"){
        var least = height < width ? height : width;
        
        var t = (controlPoints.t.x * least) / 100;
        var r = (controlPoints.r.y * height) / 100;
       
        var thick1 = least - (((controlPoints.b2.x) * least) / 100)

        var thick2 = (controlPoints.b1.x / 100) * least;

        var m = least - (((controlPoints.m.y) * least) / 100)

        if(t >= (least/2) * .95)
            t = (least/2) * .95
        
        if(t <= 0)
            t = 0

        if(thick1 >= least / 2)
            thick1 = least / 2;

        if(thick1 <= least * 0.01)
            thick1 = least * 0.01;
            
        if(thick2 >= thick1)
            thick2 = thick1;

        if(thick2 >= least / 2)
            thick2 = least / 2;

        if(thick2 <= least * 0.01)
            thick2 = least * 0.01;
        
        if(r >= height)
            r = height

        if(r <= thick1)
            r = thick1

        m = r - m;
        if(m >= r)
            m = r
            
        if(m <= thick2)
            m = thick2
       
        if(t >=  m)
            t =  m
       
        let midX = thick2 / 2;
        let midY = width - thick1 / 2;

        var curve2 = (t>thick2)?t - thick2:0;

        var newControlPoints = {t : {}, m : {}, r : {}, b1 : {}, b2 : {}};
        newControlPoints.t.x = (t / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.m.x = ((width - thick1) / width) * 100;
        newControlPoints.m.y = (m / height) * 100;

        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (r / height) * 100;

        newControlPoints.b1.x = (thick2 / width) * 100;
        newControlPoints.b1.y = controlPoints.b1.y;

        newControlPoints.b2.x = ((width - thick1) / width) * 100;
        newControlPoints.b2.y = controlPoints.b2.y;
       
        xpos = thick2; ypos = height;
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = t;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+(ypos - t);//Curve point

        xpos = t; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos + t) +","+(ypos);//Curve point

        xpos = midY + midX - t; ypos = 0;
        data += " "+ (xpos) +","+ (ypos) +" "+ xpos +","+ ypos +" C"+ (xpos + t) +","+(ypos);//Curve point
        
        xpos = midY + midX; ypos = t;
        data += " "+ xpos +","+ (ypos) +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos - t);//Curve point

        xpos = midY + midX; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        xpos = width; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        xpos = midY; ypos = r;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        xpos = midY - thick1/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        xpos = midY - thick2/2; ypos = m;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        // xpos = midY - thick2/2, ypos = thick2;
        // data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos);

        xpos = midY - thick2/2; ypos = thick2 + curve2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos - curve2);

        xpos = midY - (thick2/2) - curve2; ypos = thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos + curve2) +","+(ypos);


        xpos = thick2 + curve2; ypos = thick2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos - curve2) +","+(ypos);//Curve point

        xpos = thick2; ypos = thick2 + curve2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ (xpos) +","+(ypos + curve2);//Curve point

        xpos = thick2; ypos = height;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
    }

    let dataP = {data : data, color : pathData.colors[Object.keys(pathData.colors)[0]]};

    if(pathData.isStroke === true){
        dataP.isStroke = true;
        dataP.pathStyle = {left : strokeWidth / 2, top : strokeWidth / 2};
        var shapeStyle = {width : width + strokeWidth, height : height + strokeWidth};
    }
    else 
        dataP.isFill = true;
    
	return {data : data, pathDetails : [dataP],  pathData : pathData, shapeStyle : shapeStyle, newControlPoints : newControlPoints};
}

function getLine(width, height, pathData){
    var data = "", xpos, ypos;
    var controlPoints = pathData.controlPoints;
    var midX = width / 2;
    var midY = height / 2;

    if(pathData.type === "ribbon"){
        var least = height < width ? height : width;
    
        var tr = (controlPoints.t.x * least) / 100;
    
        if(tr < 0)
            tr = 0;

        if(tr >= width)
            tr = width;
            
        var currentXValue = tr;
            
        xpos = 0; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = height/2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height / 2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {t : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        
    }
    else if(pathData.type === "dumbbell"){
        var least = height;
    
        var tr = (controlPoints.t.x * least) / 100;
    
        if(tr < 0)
            tr = 0;

        if(tr >= width/2)
            tr = width/2;
            
        var currentXValue = tr;

        var _thickn = (least/2) - ((controlPoints.r.y / 100) * least);

        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        if(_thickn >= least/2)
            _thickn = least/2
            
        xpos = 0; ypos = 0;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = midY - _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = width - currentXValue; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = midY + _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";
        
        var newControlPoints = {t : {}, r : {}};
        newControlPoints.t.x = (currentXValue / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.r.x = ((width - tr) / width) * 100;
        newControlPoints.r.y = ((midY - _thickn)/height) * 100;
    }
    else if(pathData.type === "circle_dumbbell"){
        var least = height;

        var t = (controlPoints.t.x * least) / 100;
      
        if(t >= least)
            t = least

        if(t >= width/2)
            t = width/2
        
        if(t <= (least/2) * 0.01)
            t = (least/2) * 0.01

        var thickness = (least/2) - ((controlPoints.l.y / 100) * least);
       
        if(thickness >= t)
            thickness = t

        if(thickness <= 10)
            thickness = 10
        

        let ang1 = (360 - thickness/2) * Math.PI/180;
        let ang2 = (thickness/2) * Math.PI/180;
        var circleVal = createPie(width, height, pathData, t, t, 0, (height/2) - t /2, ang1, ang2);
        var circle1 = String(circleVal.data);

        circle1 = circle1.slice(0, -1);
        circle1 += " C"+ circleVal.x2 +","+ circleVal.y2 +" "+ circleVal.x1 +","+ circleVal.y1 +" "+ circleVal.x1 +","+ circleVal.y1+"z";

        var x1 = circleVal.x1, y1 = circleVal.y1, x2 = circleVal.x2, y2 = circleVal.y2;


        ang1 = (-180 + thickness/2) * Math.PI/180;
        ang2 = (180 - thickness/2) * Math.PI/180;

        circleVal = createPie(width, height, pathData, t, t, width - t, (height/2) - t /2, ang1, ang2);

        var x3 = circleVal.x1, y3 = circleVal.y1, x4 = circleVal.x2, y4 = circleVal.y2;
        
       
        var circle2 = String(circleVal.data);

        circle2 = circle2.slice(0, -1);
        circle2 += " C"+ circleVal.x2 +","+ circleVal.y2 +" "+ circleVal.x1 +","+ circleVal.y1 +" "+ circleVal.x1 +","+ circleVal.y1+"z";


        var line1 = "M"+ x1 +","+ y1 +" "+ x1 +","+ y1

        xpos = x3; ypos = y3;
        line1 += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = x4; ypos = y4;
        line1 += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = x2; ypos = y2;
        line1 += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        line1 += " "+ x1 +","+ y1 +" "+ x1 +","+ y1 +"z";

       
        data = circle1 + line1 + circle2

        var newControlPoints = {t : {}, l : {}};
        newControlPoints.t.x = (t / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.l.x = (x1 / width) * 100;
        newControlPoints.l.y = (y1/height) * 100;

        // data = [circle1, circle2, line1]

    }
    else if(pathData.type === "line"){
        var data = "";
        var data = "M0,0 C0,0";
        data += drawData(width, 0);
        data += drawData(width, height);
        data += drawData(0, height);
        data += " 0,0 0,0z";
    }
    

	return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getCircle(width, height, pathData){
    var data = "";
    var pathPoints = pathData.points;
    var outTanX = 0
    var outTanY = 0
    var inTanX  = 0
    var inTanY  = 0
    for(var i = 0; i < pathPoints.length;i++){
        let xpos = ((pathPoints[i].x/100) * width);
        let ypos = ((pathPoints[i].y/100) * height);
        
        if(i === 0){
            outTanX = (1.1/4);
            data ="M"+ xpos +","+ ypos +" C"+ (xpos + (outTanX * width)) +","+ ypos;
        }else{
            outTanX = outTanY = inTanX = inTanY = 0;
            if(i===1){
                inTanY = -(1.1/4)
                outTanY = (1.1/4)
            }else if(i===2){
                inTanX = (1.1/4)
                outTanX = -(1.1/4)
            }else if(i===3){
                inTanY = (1.1/4)
                outTanY =- (1.1/4)
            }

            outTanX *= width
            outTanY *= height
            inTanX  *= width
            inTanY  *= height
            data += " "+ (xpos + inTanX) +","+ (ypos + inTanY) +" "+ xpos +","+ ypos +" C"+ (xpos + outTanX) +","+ (ypos + outTanY);
        }
    }

    let xpos = ((pathPoints[0].x/100) * width);
    let ypos = ((pathPoints[0].y/100) * height);
    inTanX = -(1.1/4)
    data += " "+ (xpos + (inTanX * width)) +","+ ypos +" "+ xpos +","+ ypos +"z";

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData};
}

function getLShape(width, height, pathData){
    var data = "";
    var pathPoints = pathData.points;
   
    for(var i = 0; i < pathPoints.length;i++){
        let xpos = ((pathPoints[i].x/100) * width);
        let ypos = ((pathPoints[i].y/100) * height);
        
        var controlPoints = pathData.controlPoints;
       
        var least = (height < width) ? height : width

        var currentXValue = (controlPoints.t.x / 100) * least;
        var currentYValue = (controlPoints.l.y / 100) * least;

        if(currentXValue >= width)
            currentXValue = width

        if(currentXValue <= 0)
            currentXValue = 0

        if(currentYValue >= height)
        currentYValue = height

        if(currentYValue <= 0)
        currentYValue = 0

        if(i === 0){
            data ="M"+ xpos +","+ ypos +" C"+ xpos +","+ ypos;
        }else{
            if(i===1){
                xpos = currentXValue
                data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
            }else if(i===2){
                xpos = currentXValue
                ypos = currentYValue
                data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
            }else if(i===3){
                ypos = currentYValue
                data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
            }else{
                data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
            }
        }
    }

    let xpos = ((pathPoints[0].x/100) * width);
    let ypos = ((pathPoints[0].y/100) * height);

    data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +"z";

    // pathData.controlPoints.t.x = (currentXValue / width) * 100;
    // pathData.controlPoints.l.y = (currentYValue / height) * 100;
    var newControlPoints = {t : {}, l : {}};
    newControlPoints.t.x = (currentXValue / width) * 100;
    newControlPoints.t.y = controlPoints.t.y;

    newControlPoints.l.x = controlPoints.l.x;
    newControlPoints.l.y = (currentYValue / height) * 100;

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getStripe(width, height, pathData){
    var data = "";
    var pathPoints = pathData.points;
    var controlPoints = pathData.controlPoints;
    var currentYValue = (controlPoints.l.y / 100) * height;

    if(currentYValue >= 0.99 * height)
        currentYValue = 0.99 * height

    if(currentYValue <= 0)
        currentYValue = 0

    var xpos_rt = (pathPoints[1].x/100) * width;
    var ypos_rt = (pathPoints[1].y/100) * height
    var xpos_lb = (pathPoints[2].x/100) * width;
    var ypos_lb = (pathPoints[2].y/100) * height;		
    var angle = Math.atan2(ypos_rt - ypos_lb,xpos_rt - xpos_lb);
    var xpos3 = (pathPoints[3].x/100) * width;
    var ypos3 = currentYValue;
    var xpos1 =(xpos3)+ (700 * Math.cos(angle));
    var ypos1 =(ypos3)+ (700 * Math.sin(angle));

    var res = checkLineIntersection(xpos3, ypos3, xpos1, ypos1, 0, 0, xpos_rt, ypos_rt);

    xpos1 = res.x
	ypos1 = res.y
    
    var data = "M"+ xpos1 +","+ ypos1 +" C"+ xpos1 +","+ ypos1;
    for(var i=1; i < pathPoints.length; i++){
        var xpos = (pathPoints[i].x/100) * width;
        var ypos = (pathPoints[i].y/100) * height;
        if(i===3){
            ypos = currentYValue;
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }else{
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }
    }

    data += " "+ xpos1 +","+ ypos1 +" "+ xpos1 +","+ ypos1 +"z";

    var newControlPoints = {l : {}};

    newControlPoints.l.x = controlPoints.l.x;
    newControlPoints.l.y = (currentYValue / height) * 100;
   
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function checkLineIntersection(line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    var denominator, a, b, numerator1, numerator2, result = {
        x: null,
        y: null
    };
    denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
    if (denominator === 0) {
        return result;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;
    numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
    numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    result.x = line1StartX + (a * (line1EndX - line1StartX));
    result.y = line1StartY + (a * (line1EndY - line1StartY));
    
    return result;
};

function getSimplePath(width, height, pathData){
    var pathPoints = pathData.points;
    var data = "", xpos, ypos;
    for(var i = 0; i < pathPoints.length;i++){
        xpos = ((pathPoints[i].x/100) * width);
        ypos = ((pathPoints[i].y/100) * height);
        if(i === 0)
            data ="M"+ xpos +","+ ypos +" C"+ xpos +","+ ypos;
        else
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
    }

    xpos = ((pathPoints[0].x/100) * width);
    ypos = ((pathPoints[0].y/100) * height);

    data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +"z";

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}],  pathData : pathData};
}

function getHexagon(width, height, pathData){
    var data = "", xpos;
    
    var least = (height < width) ? height : width

    var _thickn = (pathData.controlPoints.t.x / 100) * least;

    if(_thickn <= 0)
        _thickn = 0
    
    if(_thickn >= width)
        _thickn = width

    var data = "M"+ _thickn +",0 C"+ _thickn +",0"

		xpos = width - _thickn;
		data += " "+ xpos +",0 "+ xpos +",0 C"+ xpos +",0";

		data += " "+ width +","+ (height/2) +" "+ width +","+ (height/2) +" C"+ width +","+ (height/2);

		data += " "+ xpos +","+ height +" "+ xpos +","+ height +" C"+ xpos +","+ height;

		data += " "+ _thickn +","+ height +" "+ _thickn +","+ height +" C"+ _thickn +","+ height;

		data += " 0,"+ (height/2) +" 0,"+ (height/2) +" C0,"+ (height/2);

        data += " "+ _thickn +",0 "+_thickn+",0z";

        

    var positions = {t : {}};
    positions.t.x = (_thickn / width) * 100;
    positions.t.y = pathData.controlPoints.t.y;

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : positions};
}

function getLightning(width, height, pathData){
    var data = "", xpos, ypos;
   
    var x_percentArr = [0.4,0.6,0.51,0.77,0.69,1,0.47,0.57,0.24,0.35,0]
    var y_percentArr = [0,0.29,0.32,0.56,0.6,1,0.69,0.65,0.45,0.39,0.19]

    for(var i = 0; i < x_percentArr.length;i++){
        xpos = x_percentArr[i] * width;
        ypos = y_percentArr[i] * height;
        if(i === 0)
            data ="M"+ xpos +","+ ypos +" C"+ xpos +","+ ypos;
        else
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
    }

    xpos = x_percentArr[0] * width
    ypos = y_percentArr[0] * height;

    data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +"z";
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData};
}

function getParallelogram(width, height, pathData){
    var data = "", xpos, ypos;
   
    var x_percentArr = [0,1,1,0]
    var y_percentArr = [0,0,1,1]
    // var currentPosition = (pathData.controlPoints.t.x / 100) * width;
    var least = (height < width) ? height : width
    var currentPosition = (pathData.controlPoints.t.x / 100) * least;

    if(currentPosition >= 0.99 * width)
        currentPosition = 0.99 * width

    if(currentPosition <= 0)
        currentPosition = 0

    for(var i = 0; i < x_percentArr.length;i++){
        xpos = x_percentArr[i] * width;
        ypos = y_percentArr[i] * height;
        if(i === 0)
            data ="M"+ currentPosition +","+ ypos +" C"+ currentPosition +","+ ypos;
        else if(i === 2){
            xpos = xpos - currentPosition
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }
        else
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
    }

    ypos = y_percentArr[0] * height;

    data += " "+ currentPosition +","+ ypos +" "+ currentPosition +","+ ypos +"z";

    var positions = {t : {}};
    positions.t.x = (currentPosition / width) * 100;
    positions.t.y = pathData.controlPoints.t.y;
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : positions};
}

function getTrapezoid(width, height, pathData){
    var data = "", xpos, ypos;
   
    var x_percentArr = [0,1,1,0]
    var y_percentArr = [0,0,1,1]
    // var currentPosition = (pathData.controlPoints.t.x / 100) * width;
    var least = (height < width) ? height : width
    var currentPosition = (pathData.controlPoints.t.x / 100) * least;

    if(currentPosition >= least)
        currentPosition = least

    if(currentPosition <= 0)
        currentPosition = 0

    for(var i = 0; i < x_percentArr.length;i++){
        xpos = x_percentArr[i] * width;
        ypos = y_percentArr[i] * height;
        if(i === 0)
            data ="M"+ currentPosition +","+ ypos +" C"+ currentPosition +","+ ypos;
        else if(i === 1){
            xpos = xpos - currentPosition
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }
        else
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
    }

    ypos = y_percentArr[0] * height;

    data += " "+ currentPosition +","+ ypos +" "+ currentPosition +","+ ypos +"z";

    var positions = {t : {}};
    positions.t.x = (currentPosition / width) * 100;
    positions.t.y = pathData.controlPoints.t.y;
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : positions};
}

function getCollate(width, height, pathData){
    
    var data = "", xpos, ypos;
   
    var x_percentArr = [0,1,0.5,0,1, 0]
    var y_percentArr = [0,0,0.5,1,1,0]

    for(var i = 0; i < x_percentArr.length;i++){
        xpos = x_percentArr[i] * width;
        ypos = y_percentArr[i] * height;
        if(i === 0)
            data ="M"+ xpos +","+ ypos +" C"+ xpos +","+ ypos;
        else
            data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
    }

    xpos = x_percentArr[0] * width;
    ypos = y_percentArr[0] * height;

    data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +"z";
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData};
}

export function getHandleAngle(x, y, width, height){
    var centerX = width / 2;
    var centerY = height / 2;
    var ax = ((x/width) * (centerX * 2))
    var ay = ((y/height) * (centerY * 2))
    var angle = Math.atan2(ay - centerY, ax - centerX);
    
    
    return angle;
}

function createPie(mWidth, mHeight, pathData, width, height, xOffset, yOffset, ang1, ang2, isDonut, addAngle){
    var data9="";
    var least=0.00001;
    var dia=6.2831;
    var crossAngle = ang1;
    if(addAngle !== undefined)
        crossAngle = addAngle;

	var he = Math.abs(ang1) > dia ? ang1 % dia : crossAngle,
	se = Math.abs(ang2) > dia ? ang2 % dia : ang2,
	len= Math.min(dia, Math.abs(se - he)),
	sgn = (crossAngle < ang2) ? +1 : -1; 
	var avd = crossAngle, point1X, point1Y, point2X, point2Y;
    
	for (var i =len; i > least; ) {
        
        var va = avd + sgn * Math.min(i,  Math.PI / 2.0);
       
		var se = (va - avd)*.5
		var bt =se+avd
		var he = .5 * Math.cos(se);
		var ve = .5 * Math.sin(se);
		var mx = he;
		var my = -ve;

		var q1 = mx * mx + my * my;
		var q2 = q1 + mx * he + my * ve;
		var lQ=q1 * q2+q1 * q2
		lQ = (Math.sqrt(lQ) - q2) / (mx * ve - my * he);

		var htu = mx - (lQ*1.33 )* my;
		var vtu = my + (lQ*1.33 ) * mx;
		var huz = htu;
		var vuz = -vtu;
		var cx= htu * Math.cos(bt) - vtu * Math.sin(bt)
		var cy= htu * Math.sin(bt) + vtu * Math.cos(bt)
		var outTanX= huz * Math.cos(bt) - vuz * Math.sin(bt)
		var outTanY= huz * Math.sin(bt) + vuz * Math.cos(bt)
		var cpX=(((.5 * Math.cos(avd)+.5)*width)+xOffset).toFixed(2)
		var cpY=(((.5 * Math.sin(avd)+.5)*height)+yOffset).toFixed(2)
		if(avd === crossAngle){
			
            data9=data9+'M'+cpX+","+cpY;
            point1X = cpX; point1Y = cpY;
            
		}
		cpX=(((.5 * Math.cos(va)+.5)*width)+xOffset).toFixed(2);
        cpY=(((.5 * Math.sin(va)+.5)*height)+yOffset).toFixed(2);
        
        point2X = cpX; point2Y = cpY;
		
		data9=data9+' C'+(((cx+.5)*width)+xOffset).toFixed(2)+","+(((cy+.5)*height)+yOffset).toFixed(2)+" "+(((outTanX+.5)*width)+xOffset).toFixed(2)+","+(((outTanY+.5)*height)+yOffset).toFixed(2)+" "+cpX+","+cpY;
		i -= Math.abs(va - avd);
		avd = va;
		
	}
	
	var playArr=data9.split(" ")
	playArr.reverse()
	var jlen=playArr.length;

		if(isDonut===true){
			var cinc=0;
			var revData=" "+playArr[0];
			for (var j =1; j < jlen; j++) {
			
			if(j === jlen-1){
			
				playArr[j]=	playArr[j].substr(1)
						
				cinc=2;
				
			}
			var str=(cinc===0)?" C":" ";
			revData=revData+(str+((cinc===1)?playArr[j].substr(1):playArr[j]))
			cinc=(cinc===2)?0:cinc+1;

		}
		data9=revData;
	}
	
	if(pathData.type === "pie"){
        cpX = mWidth/2
        cpY = mHeight/2
        data9 = data9+' C'+cpX+","+cpY+" "+cpX+","+cpY+" "+cpX+","+cpY;
    }
    
	return {data : data9+('z'), x1 : point1X, y1 : point1Y, x2 : point2X, y2 : point2Y};
}

function getPie(width, height, pathData){
    var data = "";
    var p1x = (pathData.controlPoints.p1.x / 100) * width, p1y = (pathData.controlPoints.p1.y / 100) * height;
    var p2x = (pathData.controlPoints.p2.x / 100) * width, p2y = (pathData.controlPoints.p2.y / 100) * height;
  
    
    let ang1 = getHandleAngle(p1x, p1y, width, height);

    var a1 = ang1 * 180 / Math.PI;
    if(a1<0){
        var temp=a1*-1
        a1=180+(180-temp)
        ang1=a1*Math.PI/180 ;
    }

    let ang2 = getHandleAngle(p2x, p2y, width, height);

    var a1 = ang2 * 180 / Math.PI;
    if(a1<0){
        var temp=a1*-1
        a1=180+(180-temp)
        ang2=a1*Math.PI/180 ;
    }

    var ang1Deg=ang1*180/Math.PI
    var ang2Deg=ang2*180/Math.PI
    
    var addAngle = ang1;
    if(ang1Deg>ang2Deg){
        addAngle = (ang1Deg-360)*Math.PI/180
    }
    
    let circle1 = createPie(width, height, pathData, width, height, 0, 0, ang1, ang2, false, addAngle);

    var newControlPoints = {p1 : {}, p2 : {}};
        newControlPoints.p1.x = (circle1.x1 / width) * 100;
        newControlPoints.p1.y = (circle1.y1 / height) * 100;

        newControlPoints.p2.x = (circle1.x2 / width) * 100;
        newControlPoints.p2.y = (circle1.y2 / height) * 100;

    var data = String(circle1.data);
    
    if(pathData.type === "blockarc"){
       
        let circle2 = createPie(width, height, pathData, width/2, height/2, width/4, height/4,  ang1, ang2, true, addAngle);
        var newData2 = circle2.data;
        data = data.slice(0, -1);
        var newDataArr = data.split(" ")
        var str = newDataArr[newDataArr.length-1]
        newData2 = newData2.slice(0, -1);
        newData2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";
        data = data+" C"+str+" "+str+newData2

    }
    else if(pathData.type === "pie"){
        data = data.slice(0, -1);
        data += " C"+ width/2 +","+ height/2 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";
    }
    else{
        data = data.slice(0, -1);
        data += " C"+ circle1.x2 +","+ circle1.y2 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";
    }
   
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints}
}

function getHalfFrame(width, height, pathData){
    var xpos, ypos;
    var x_percentArr = [0,100,75,25,25,0];
    var y_percentArr = [0,0,25,25,75,100];

    var arrLen = y_percentArr.length;		
    var xpos2=((x_percentArr[0]/100)*width);
    var ypos2=((y_percentArr[0]/100)*height);
    var result0;
    var data0;
    var data1="M"+xpos2+","+ypos2+" C"+xpos2+","+ypos2;
    var least = (height < width) ? height : width

    var currentXValue = (pathData.controlPoints.t.x / 100) * least;
    var currentYValue = (pathData.controlPoints.l.y / 100) * least;

    if(currentXValue >= width)
        currentXValue = width

    if(currentXValue <= 0)
        currentXValue = 0

    if(currentYValue >= height)
        currentYValue = height

    if(currentYValue <= 0)
        currentYValue = 0

    for(var i=1; i<arrLen;i++){
        var xpos=((x_percentArr[i]/100)*width);
        var ypos=((y_percentArr[i]/100)*height);
    
        if(x_percentArr[i]===75 && y_percentArr[i]===25){
            result0=checkLineIntersection(0, currentYValue, width, currentYValue, width, 0, 0, height);
            xpos=result0.x;
            ypos=result0.y;
            data0=data1+" "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }else if(x_percentArr[i]===25 && y_percentArr[i]===75){
            var result1=checkLineIntersection(currentXValue, 0, currentXValue, height, width, 0, 0, height);
            xpos=result1.x;
            ypos=result1.y;
            var angle = Math.atan2(result0.y-result1.y,result0.x-result1.x);
            if(angle<=0){
            data0=data0+" "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
            }else{
            data0=data1;
            }
            
        }else if(x_percentArr[i]===25 && y_percentArr[i]===25){
          
            xpos=currentXValue//((xp/100)*width);
            ypos=currentYValue//((yp/100)*height)
            data0=data0+" "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        }
        
        if(i===1){						
            data1=data1+" "+(xpos)+","+(ypos)+" "+xpos+","+ypos+" C"+(xpos)+","+(ypos);
        }
        if(i===5){				
            data1=data0+" "+(xpos)+","+(ypos)+" "+xpos+","+ypos+" C"+(xpos)+","+(ypos);
        }
    }		
    data1=data1+" "+(xpos2)+","+ypos2+" "+(xpos2)+","+(ypos2)+"z";

    var newControlPoints = {t : {}, l : {}};
    newControlPoints.t.x = (currentXValue / width) * 100;
    newControlPoints.t.y = pathData.controlPoints.t.y;

    newControlPoints.l.x = pathData.controlPoints.l.x;
    newControlPoints.l.y = (currentYValue / height) * 100;

    return {data : data1, pathDetails : [{data : data1, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function drawData(xpos,ypos){
    return " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
}

function getFrame(width, height, pathData){
    var data1 = "M"+0+","+0+" C"+0+","+0;
    var least = (height < width) ? height : width
    var _thickn = (pathData.controlPoints.t.x / 100) * least;

    if(_thickn > least/2)
        _thickn = least/2

    if(_thickn <= 0.01 * least)
        _thickn = 0.01 * least
    // var _thickn = (pathData.controlPoints.t.x / 100) * width;
    
    data1 = data1+drawData(width,0);
    data1 = data1+drawData(width,height);
    data1 = data1+drawData(0,height);
   
    data1 = data1+drawData(0,0);
    data1 = data1+drawData(_thickn,0);
    data1 = data1+drawData(_thickn , height-( _thickn));
    data1 = data1+drawData( width-(_thickn), height-( _thickn));
    data1 = data1+drawData(width-(_thickn), _thickn);
    data1 = data1+drawData(0, _thickn);
    data1 = data1+" "+0+","+0+" "+0+","+0+"z";

    // pathData.controlPoints.t.x = (_thickn / least) * 100;
    var newControlPoints = {t : {}};
    newControlPoints.t.x = (_thickn / width) * 100;
    newControlPoints.t.y = pathData.controlPoints.t.y;

    return {data : data1, pathDetails : [{data : data1, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getTriangle(width, height, pathData){
    var data = "";
    let offset = 0;

    if(pathData.controlPoints.t !== undefined)
        offset = pathData.controlPoints.t.x;
    else if(pathData.offset !== undefined)
        offset = pathData.offset;

    var currentPosition = (offset / 100) * width;

    if(currentPosition >= width)
        currentPosition = width

    if(currentPosition <= 0)
        currentPosition = 0

    // var least = (height < width) ? height : width
    // var currentPosition = (offset / 100) * least;

    var data = "M"+ currentPosition +","+0+" C"+ currentPosition +","+0;

    data += drawData(width, height);
    data += drawData(0, height);
    data += " "+ currentPosition +","+0+" "+ currentPosition +","+0+"z";

    if(pathData.controlPoints.t !== undefined){
        var newControlPoints = {t : {}};
        newControlPoints.t.x = (currentPosition / width) * 100;
        newControlPoints.t.y = pathData.controlPoints.t.y;
    }

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getPlus(width, height, pathData){
    var arrX = [];
    var arrY = [];
    var midX = width /2;
    var midY = height / 2;
    var least = (height < width) ? height : width

    var xoffset = 0;
    var yoffset = 0;
    var _thickn = (least/2) - (pathData.controlPoints.l.y / 100) * least;

    if(_thickn > least / 2)
        _thickn = least / 2

    if(_thickn < (least / 2) * 0.01)
        _thickn = (least / 2) * 0.01

    arrX.push( midX - _thickn );
    arrY.push(0);
    
    arrX.push(midX + _thickn);
    arrY.push(0);
    
    arrX.push(midX + _thickn);
    arrY.push(midY - _thickn);
    
    arrX.push(width);
    arrY.push(midY - _thickn);
    
    arrX.push(width);
    arrY.push(midY + _thickn);
    
    arrX.push(midX + _thickn);
    arrY.push(midY + _thickn);
    
    arrX.push(midX + _thickn);
    arrY.push(height);
    
    arrX.push(midX - _thickn);
    arrY.push(height);

    arrX.push(midX - _thickn);
    arrY.push(midY + _thickn);

    arrX.push(0);
    arrY.push(midY + _thickn);

    arrX.push(0);
    arrY.push(midY - _thickn);

    arrX.push(midX - _thickn);
    arrY.push(midY - _thickn);

    var arrLen=arrY.length;
    var data ="M"+(arrX[0]+xoffset)+","+(arrY[0]+yoffset)+" C"+(arrX[0]+xoffset)+","+(arrY[0]+yoffset);

    for(var i=1; i<arrLen;i++){
    
        var xpos=arrX[i]+xoffset;
        var ypos=arrY[i]+yoffset;
        data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        
    }
    
    data += " "+(arrX[0]+xoffset)+","+(arrY[0]+yoffset)+" "+(arrX[0]+xoffset)+","+(arrY[0]+yoffset)+"z";

    var newControlPoints = {l : {}};
    newControlPoints.l.x = pathData.controlPoints.l.x;
    newControlPoints.l.y = ((midY - _thickn) / height) * 100;
   
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function distance(x1, x2,  y1, y2) {
    var dx = x1-x2;
    var dy = y1-y2;
    return Math.sqrt(dx * dx + dy * dy);
}

function getMultiply(width, height, pathData){
   
    var angle
    var midX = width/2;
    var midY = height/2;

    var least = (height < width) ? height : width

    var _thickn = (least/2) - (pathData.controlPoints.l.y / 100) * least;
   
    if(_thickn >= least/4)
        _thickn = least/4
    
    if(_thickn <= 0.01 * least)
        _thickn = 0.01 * least;

    var arrX = [];
    var arrY = [];

    var angl=-45*(Math.PI/180);
    var totalDistance=distance(midX,0,midY,0)/2;
    angle = Math.atan2(midY-0 ,midX-0);
    
    totalDistance = least / 2;
    
    var nwX=midX+( totalDistance * Math.cos(angle));
    var nwY=midY+(  totalDistance * Math.sin(angle));
    angl=-45*(Math.PI/180);
    var a1x=nwX+( _thickn * Math.cos(angl));
    var a1y=nwY+( _thickn * Math.sin(angl));
    angl=135*(Math.PI/180);
    var a2x=nwX+( _thickn * Math.cos(angl));
    var a2y=nwY+( _thickn * Math.sin(angl));

    angle = Math.atan2(midY-0 ,midX-width);
    var neX = midX+( totalDistance * Math.cos(angle));
    var neY = midY+(  totalDistance * Math.sin(angle));
    angl = 45*(Math.PI/180)
    var a3x = neX+( _thickn * Math.cos(angl));
    var a3y = neY+( _thickn * Math.sin(angl));
    angl = 225*(Math.PI/180)
    var a4x = neX+( _thickn * Math.cos(angl));
    var a4y = neY+( _thickn * Math.sin(angl));

    angle = Math.atan2(midY-height ,midX-width);
    var seX = midX+( totalDistance * Math.cos(angle));
    var seY = midY+(  totalDistance * Math.sin(angle));
    angl=135*(Math.PI/180);
    var a5x=seX+( _thickn * Math.cos(angl));
    var a5y=seY+( _thickn * Math.sin(angl));
    angl=-45*(Math.PI/180);
    var a6x=seX+( _thickn * Math.cos(angl));
    var a6y=seY+( _thickn * Math.sin(angl));
    
    angle = Math.atan2(midY-height ,midX-0);
    var swX=midX+( totalDistance * Math.cos(angle));
    var swY=midY+(  totalDistance * Math.sin(angle));

    angl=-135*(Math.PI/180);		
    var a7x=swX+( _thickn * Math.cos(angl));
    var a7y=swY+( _thickn * Math.sin(angl));
    angl=45*(Math.PI/180);
    var a8x=swX+( _thickn * Math.cos(angl));
    var a8y=swY+( _thickn * Math.sin(angl));
    
    arrX.push(a1x);
    arrY.push(a1y);
    
    arrX.push(a2x);
    arrY.push(a2y);

    var res=checkLineIntersection(a2x, a2y, a5x, a5y, a3x, a3y, a8x, a8y);
    arrX.push(res.x);
    arrY.push(res.y);
    
    arrX.push(a3x);
    arrY.push(a3y);	
    arrX.push(a4x);
    arrY.push(a4y);
    res=checkLineIntersection(a4x, a4y, a7x, a7y, a2x, a2y, a5x, a5y);
    arrX.push(res.x);
    arrY.push(res.y);
    
    arrX.push(a5x);
    arrY.push(a5y);
    arrX.push(a6x);
    arrY.push(a6y);
    res=checkLineIntersection(a6x, a6y, a1x, a1y, a7x, a7y, a4x, a4y);
    arrX.push(res.x);
    arrY.push(res.y);
    
    arrX.push(a7x);
    arrY.push(a7y);
    arrX.push(a8x);
    arrY.push(a8y);
    res=checkLineIntersection(a8x, a8y, a3x, a3y, a1x, a1y, a6x, a6y);
    arrX.push(res.x);
    arrY.push(res.y);

    var arrLen=arrY.length;
    var data ="M"+(arrX[0])+","+(arrY[0])+" C"+(arrX[0])+","+(arrY[0]);

    for(var i=1; i<arrLen;i++){
    
        var xpos=arrX[i];
        var ypos=arrY[i];
        data += " "+xpos+","+ypos+" "+xpos+","+ypos+" C"+xpos+","+ypos;
        
    }
    
    data += " "+(arrX[0])+","+(arrY[0])+" "+(arrX[0])+","+(arrY[0])+"z";

    var newControlPoints = {l : {}};
    newControlPoints.l.x = pathData.controlPoints.l.x;
    newControlPoints.l.y = ((midY - _thickn) / height) * 100;
   
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getMinus(width, height, pathData){
    
    var data = "M0,0 C0,0"

    data += " "+ width +",0 "+ width +",0 C"+ width +",0";

    data += " "+ width +","+ height +" "+ width +","+ height +" C"+ width +","+ height;

    data += " 0,"+ height +" 0,"+ height +" C0,"+ height;

    data += " 0,0 0,0z";
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData};
}

function getGroupPath(width, height, pathData, storedItem){
    let colors =  Object.keys(pathData.colors);
    var controlPoints = pathData.controlPoints;
    var least = (height < width) ? height : width;
    var datas = [], pathDetails = [];
    var midX = width / 2;
    var midY = height / 2;
    if(pathData.type === "cube"){
        var l = (controlPoints.l.y / 100) * least, xpos, ypos;

        if(l >= least * 0.99)
            l = least * 0.99;
        if(l < 0)
            l = 0;

        var data = "M0,"+l+" C0,"+l;

        xpos = l; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width - l; ypos = l;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " 0,"+ l +" 0,"+l+"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[0]]});

        var data = "M0,"+l+" C0,"+l;

        xpos = width - l; ypos = l;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width - l; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = 0; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " 0,"+ l +" 0,"+l+"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var data = "M"+ (width - l) +","+ l +" C"+ (width - l) +","+l;

        xpos = width; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = height - l;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width - l; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ (width - l) +","+ l +" "+ (width - l) +","+l+"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[2]]});

        var newControlPoints = {l : {}};
        newControlPoints.l.x = pathData.controlPoints.l.x;
        newControlPoints.l.y = (l / height) * 100;
    }
    else if(pathData.type === "cylinder"){
        var r = (controlPoints.m.y / 100) * least, xpos, ypos;

        if(r >= least / 2)
            r = least / 2

        if(r <= 0)
            r = 0
        
        let ang1 = 180 * Math.PI/180;
        let ang2 = 360 * Math.PI/180;
        var circle1 = createPie(width, height, pathData, -width, (-(r)), width, height, ang1, ang2);
        var data= String(circle1.data);
        
        data = data.slice(0, -1)

        data += " C"+ circle1.x2 +","+ circle1.y2 +" "+0+","+r/2+" "+0+","+r/2+" C"+ width +","+r/2+" "+ width +","+r/2+" "+ width +","+r/2;
        data += " C"+ width +","+r/2 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[0]]});

        ang1 = -180 * Math.PI/180;
        ang2 = 190 * Math.PI/180;
        
        let circle2 = createPie(width, height, pathData, width, r, 0, 0, ang1, ang2);
        var data = String(circle2.data);

        data = data.slice(0, -1)
        data += " C"+ circle2.x2 +","+ circle2.y2 +" "+ circle2.x1 +","+ circle2.y1 +" "+ circle2.x1 +","+ circle2.y1+"z";
        
        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {m : {}};
        newControlPoints.m.x = pathData.controlPoints.m.x;
        newControlPoints.m.y = (r / height) * 100;
    }
    else if(pathData.type === "grouped_triangle"){
        var b = (controlPoints.b.x / 100) * least, xpos, ypos;
        var midX = width / 2;

        if(b <= 0)
            b = 0
        else if(b >= width)
            b = width;

        var data = "M"+ midX +",0 C"+ midX +",0";

        xpos = 0; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = b; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = midX; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ midX +",0 "+ midX +",0z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[0]]});

        var data = "M"+ b +","+ height +" C"+ b +","+height;

        xpos = width; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = midX; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ b +","+ height +" "+ b +","+ height +"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {b : {}};
        newControlPoints.b.x = (b / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;
    }
    else if(pathData.type === "grouped_rectangle"){
        var t = (controlPoints.t.x / 100) * least, xpos, ypos;
        var midX = width / 2;

        if(t <= 0)
            t = 0
        else if(t >= width)
            t = width;

        var data = "M0,0 C0,0";

        xpos = t; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = t; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = 0; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " 0,0 0,0z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[0]]});

        var data = "M"+ t +",0 C"+ t +",0";

        xpos = width; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = t; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ t +",0 "+ t +",0z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {t : {}};
        newControlPoints.t.x = (t / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
    }
    else if(pathData.type === "equal_to"){

        var l = (controlPoints.l.y / 100) * height, r = (controlPoints.r.y / 100) * height, xpos, ypos;
        var midX = width / 2, midY = height / 2;

        var thickness = 0
        if(storedItem !== undefined){
           
            thickness = ((storedItem.getIn(["pathData", "controlPoints", "r", "y"]) - controlPoints.l.y) / 100) * height //((storedItem.getIn(["pathData", "controlPoints", "r", "y"]) / 100) * height) - ((storedItem.getIn(["pathData", "controlPoints", "l", "y"]) / 100) * height);
        }else{
            thickness = r - l;
        }

        if(thickness >= midY * .85)
            thickness = midY * .85 

        l = r - thickness;

        if(l <= 0){
            l = 0;
            r = l+thickness;
        }
        
        if(r > midY){
            r = midY;
            l = r - thickness;
        }

        if(l >= r){
            l = r;
        }
        
        var data = "M0,"+ l +" C0,"+l;

        xpos = width; ypos = l;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = r;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = 0; ypos = r;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " 0,"+ l +" 0,"+ l +"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[0]]});

        var data = "M0,"+ (height - l) +" C0,"+(height - l);

        xpos = width; ypos = (height - l);
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = (height - r);
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = 0; ypos = (height - r);
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " 0,"+ (height - l) +" 0,"+ (height - l) +"z";

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        pathData.controlPoints.l.y = (l/height) * 100;
        pathData.controlPoints.r.y = (r/height) * 100;

        var newControlPoints = {l : {}, r : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (l/height) * 100;

        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (r/height) * 100;
    }
    else if(pathData.type === "curved_right_arrow"){
        var least = height < width ? height : width;

        var r = ((controlPoints.r.y / 100) * least);
        var m = ((controlPoints.m.y / 100) * least);
        
        var thick1 = least - ((controlPoints.r.y / 100) * least);
        var b = width - (((100 - controlPoints.b.x) * width) / 100);

        if(b >= width)
            b = width
        
        if(b < 0)
            b = 0
       
        var thick2 = least - ((controlPoints.m.y / 100) * least);   

        if(thick1 >= least / 2)
            thick1 = least / 2

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick2 >= thick1)
            thick2 = thick1

        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        let ang1 = 270 * Math.PI/180;
        let ang2 = 180 * Math.PI/180;

        var circle1 = createPie(width, height, pathData, width * 2, height - (thick1/2) - (thick2/2), 0, 0, ang1, ang2, false);
        var bData1 = String(circle1.data)

        var circle2 = createPie(width, height, pathData, width * 2, (height - thick2) - (height - (height - (thick1/2) + (thick2/2))), 0, thick2, ang1, ang2, true);
        var bData2 = String(circle2.data)

        bData2 = bData2.slice(0, -1);
        bData2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];
        bData1 = bData1+" C"+str+" "+str+bData2;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[0]]});

        ang1 = angle(width, (height - (thick1/2) - (thick2/2))/2, b, height) * Math.PI/180;
        ang2 = 180 * Math.PI/180;

        var circle1 = createPie(width, height, pathData, width * 2, height - (thick1/2) - (thick2/2), 0, 0, ang1, ang2, false)

        var bData1 = String(circle1.data)

        var circle2 = createPie(width, height, pathData, width * 2, (height - thick2) - (height - (height - (thick1/2) + (thick2/2))), 0, thick2, ang1, ang2, true)
        var bData2 = String(circle2.data)

        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];

        var data = "M"+circle1.x1+","+circle1.y1+" C"+circle1.x1+","+circle1.y1;

        xpos = circle1.x1; ypos = (parseInt(circle2.y1, 10) - (thick2/2)) - (thick1/2);
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = width; ypos = parseInt(circle2.y1, 10) - thick2/2;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = ((parseInt(circle1.y1, 10) + (thick2/2)) + (thick1/2));
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ circle1.x1 +","+ (circle1.y1) +" "+ circle1.x1 +","+ (circle1.y1) +"z";

        bData2 = bData2.slice(0, -1)
       
        var curve2 = bData1+" C"+str+" "+str + bData2

        curve2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        data = curve2 + data;

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {m : {}, r : {}, b : {}};
        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (((parseInt(circle2.y1, 10) - (thick2/2)) - (thick1/2))/height) * 100;

        newControlPoints.m.x = (circle1.x1 / width) * 100;
        newControlPoints.m.y = (circle1.y1 / height) * 100;

        newControlPoints.b.x = (circle1.x1 / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;


    }else if(pathData.type === "curved_left_arrow"){
        var least = height < width ? height : width;

        var l = ((controlPoints.l.y / 100) * least);
        var m = ((controlPoints.m.y / 100) * least);
        
        var thick1 = least - ((controlPoints.l.y / 100) * least);
        var b = (controlPoints.b.x * least) / 100;

        if(b <= 0)
            b = 0;

        var thick2 = least - ((controlPoints.m.y / 100) * least);

        if(thick1 >= least / 2)
            thick1 = least / 2

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick2 >= thick1)
            thick2 = thick1

        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        let ang1 = 0 * Math.PI/180;
        let ang2 = -90 * Math.PI/180;

        var circle1 = createPie(width, height, pathData, width * 2, height - (thick1/2) - (thick2/2), -width, 0, ang1, ang2, false);
    
        var bData1 = String(circle1.data);

        var circle2 = createPie(width, height, pathData, width * 2, (height - thick2) - (height - (height - (thick1/2) + (thick2/2))), -width, thick2, ang1, ang2, true);

        var bData2 = String(circle2.data);

        bData2 = bData2.slice(0, -1);
        bData2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];
        bData1 = bData1+" C"+str+" "+str+bData2;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[0]]});

        ang2 = 0 * Math.PI/180;
        ang1 = (angle(0, (height - (thick1/2) - (thick2/2))/2, b, height)) * Math.PI/180;

        var circle1 = createPie(width, height, pathData, width * 2, height - (thick1/2) - (thick2/2), -width, 0, ang1, ang2, false)

        var bData1 = String(circle1.data);
        
        var circle2 = createPie(width, height, pathData, width * 2, (height - thick2) - (height - (height - (thick1/2) + (thick2/2))), -width, thick2, ang1, ang2, true)

        var bData2 = String(circle2.data)

        bData1 = bData1.slice(0, -1);	

        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];

        var data = "M"+circle1.x1+","+circle1.y1+" C"+circle1.x1+","+circle1.y1;

        xpos = circle1.x1; ypos = (parseInt(circle2.y1, 10) - (thick2/2)) - (thick1/2);
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

      
        xpos = 0; ypos = parseInt(circle2.y1, 10) - thick2/2;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = ((parseInt(circle1.y1, 10) + (thick2/2)) + (thick1/2));
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ circle1.x1 +","+ (circle1.y1) +" "+ circle1.x1 +","+ (circle1.y1) +"z";

        bData2 = bData2.slice(0, -1)

        var curve2 = bData1+" C"+str+" "+str + bData2

        curve2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        data = curve2 + data;

        datas.push(data);
        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {m : {}, l : {}, b : {}};
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((parseInt(circle2.y1, 10) - (thick2/2)) - (thick1/2))/height) * 100;

        newControlPoints.m.x = (circle1.x1 / width) * 100;
        newControlPoints.m.y = (circle1.y1 / height) * 100;

        newControlPoints.b.x = (circle2.x1 / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;

        // datas.push(data)
    }
    else if(pathData.type === "curved_up_arrow"){
        var least = height < width ? height : width;

        var t = ((controlPoints.t.x / 100) * least);
        var m = ((controlPoints.m.x / 100) * least);
        
        var thick1 = least - ((controlPoints.t.x / 100) * least);
        var r = (controlPoints.r.y * least) / 100;

        if(r <= 0)
            r = 0;
       
        var thick2 = least - ((controlPoints.m.x / 100) * least);

        if(thick1 >= least / 2)
            thick1 = least / 2

        if(thick1 <= 0.01 * least)
            thick1 = 0.01 * least

        if(thick2 >= thick1)
            thick2 = thick1

        if(thick2 <= 0.01 * least)
            thick2 = 0.01 * least

        let ang1 = 180 * Math.PI/180;
        let ang2 = 90 * Math.PI/180;

    
        var bData1 = String(createPie(width, height, pathData, width - (thick1 / 2) - (thick2/2), height * 2, 0, -height, ang1, ang2, false).data)
        
        var bData2 = String(createPie(width, height, pathData, (width - thick2) - (width - (width - (thick1/2) + (thick2/2))), height * 2, thick2, -height, ang1, ang2, true).data)
       
        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];
        bData1 = bData1+" C"+str+" "+str+bData2;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[0]]});
       
        ang2 = 90 * Math.PI/180;
        ang1 = angle(width/2, 0, width, r) * Math.PI/180;


        var circle1 = createPie(width, height, pathData, width - (thick1 / 2) - (thick2/2), height * 2, 0, -height, ang1, ang2, false);

        var bData1 = String(circle1.data)

        var circle2 = createPie(width, height, pathData, (width - thick2) - (width - (width - (thick1/2) + (thick2/2))), height * 2, thick2, -height, ang1, ang2, true)
        
        var bData2 = String(circle2.data)

        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];

        var data = "M"+circle1.x1+","+circle1.y1+" C"+circle1.x1+","+circle1.y1;

        xpos = (parseInt(circle2.x1, 10) - (thick2/2)) - (thick1/2); ypos = circle1.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

      
        xpos = parseInt(circle2.x1, 10) - thick2/2; ypos = 0;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = ((parseInt(circle1.x1, 10) + (thick2/2)) + (thick1/2)); ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ circle1.x1 +","+ (circle1.y1) +" "+ circle1.x1 +","+ (circle1.y1) +"z";

        bData2 = bData2.slice(0, -1)


        bData1 = bData1+" C"+str+" "+str+bData2 + data;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {m : {}, t : {}, r : {}};
        newControlPoints.t.x = (((parseInt(circle2.x1, 10) - (thick2/2)) - (thick1/2)) / width) * 100
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.m.x = (circle1.x1 / width) * 100;
        newControlPoints.m.y = (circle1.y1 / height) * 100;

        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (circle2.y1 / height) * 100;
    }
    else if(pathData.type === "curved_down_arrow"){
        var least = height < width ? height : width;

        var b = ((controlPoints.b.x / 100) * least);
        var m = ((controlPoints.m.x / 100) * least);
        
        var thick1 = least - ((controlPoints.b.x / 100) * least);
        var r = (controlPoints.r.y * least) / 100;

        if(r >= height)
            r = height
       
        var thick2 = least - ((controlPoints.m.x / 100) * least);

        if(thick1 >= least / 2)
            thick1 = least / 2

        if(thick1 <= 0)
            thick1 = 0

        if(thick2 >= thick1)
            thick2 = thick1

        if(thick2 <= 0)
            thick2 = 0


        let ang1 = 180 * Math.PI/180;
        let ang2 = 270 * Math.PI/180;

        var bData1 = String(createPie(width, height, pathData, width - (thick1 / 2) - (thick2/2), height * 2, 0, 0, ang1, ang2, false).data)
        
        var bData2 = String(createPie(width, height, pathData, (width - thick2) - (width - (width - (thick1/2) + (thick2/2))), height * 2, thick2, 0, ang1, ang2, true).data)
        
        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];
        bData1 = bData1+" C"+str+" "+str+bData2;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[0]]});
        
        ang1 = angle(width/2, height, width, r) * Math.PI/180;
        ang2 = -90 * Math.PI/180;

        
        var circle1 = createPie(width, height, pathData, width - (thick1 / 2) - (thick2/2), height * 2, 0, 0, ang1, ang2, false);

        var bData1 = String(circle1.data)
        
        var circle2 = createPie(width, height, pathData, (width - thick2) - (width - (width - (thick1/2) + (thick2/2))), height * 2, thick2, 0, ang1, ang2, true);

        var bData2 = String(circle2.data)

        bData1 = bData1.slice(0, -1);	
        var newDataArr = bData1.split(" ");
        var str = newDataArr[newDataArr.length-1];


        var data = "M"+circle1.x1+","+circle1.y1+" C"+circle1.x1+","+circle1.y1;
        
        xpos = (parseInt(circle2.x1, 10) - (thick2/2)) - (thick1/2); ypos = circle1.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

      
        xpos = parseInt(circle2.x1, 10) - thick2/2; ypos = height;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = ((parseInt(circle1.x1, 10) + (thick2/2)) + (thick1/2)); ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        xpos = circle2.x1; ypos = circle2.y1;
        data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos

        data += " "+ circle1.x1 +","+ (circle1.y1) +" "+ circle1.x1 +","+ (circle1.y1) +"z";

        bData2 = bData2.slice(0, -1)

        
        bData1 = bData1+" C"+str+" "+str+bData2 + data;

        datas.push(bData1);
        pathDetails.push({data : bData1, isFill : true, color : pathData.colors[colors[1]]});

        var newControlPoints = {m : {}, b : {}, r : {}};
        newControlPoints.r.x = controlPoints.r.x;
        newControlPoints.r.y = (circle1.y1 / height) * 100;

        newControlPoints.m.x = (circle1.x1 / width) * 100;
        newControlPoints.m.y = (circle1.y1 / height) * 100;

        newControlPoints.b.x = (((parseInt(circle2.x1, 10) - (thick2/2)) - (thick1/2)) / width) * 100;
        newControlPoints.b.y = controlPoints.b.y;

    }
    else if(pathData.type === "striped_right_arrow"){
        var least = height;
    
        var tr = width - (((100 - controlPoints.t.x) * least) / 100)
        var _thickn = (least/2) - (controlPoints.l.y / 100) * least;

        var striped_width = least * 0.15;

        var stripe_1 = striped_width * 0.25;
        var stripe_2 = striped_width * 0.50;

        var delay = striped_width * 0.125;
       
        if(tr <= striped_width)
            tr = striped_width

        if(tr >= width)
            tr = width

        if(_thickn >= least/2)
            _thickn = least/2
        
        if(_thickn <= (least/2) * 0.01)
            _thickn = (least/2) * 0.01

        var stripe1_data = "M0,"+ (midY - _thickn) +" C0,"+ (midY - _thickn)

        xpos = stripe_1; ypos = midY - _thickn;
        stripe1_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = stripe_1; ypos = midY + _thickn;
        stripe1_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = midY + _thickn;
		stripe1_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        stripe1_data += " "+"0,"+ (midY - _thickn) +" 0,"+ (midY - _thickn)+"z";

        pathDetails.push({data : stripe1_data, isFill : true, color : pathData.colors[colors[0]]});

        var stripe2_data = "M"+ (stripe_1 + (delay)) +","+ (midY - _thickn) +" C"+ (stripe_1 + (delay)) +","+ (midY - _thickn)

        xpos = (stripe_1 + stripe_2 + delay); ypos = midY - _thickn;
        stripe2_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = (stripe_1 + stripe_2 + delay); ypos = midY + _thickn;
        stripe2_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = stripe_1 + (delay); ypos = midY + _thickn;
		stripe2_data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        stripe2_data += " "+ (stripe_1 + delay) +","+ (midY - _thickn) +" " + (stripe_1 + delay) + ","+ (midY - _thickn)+"z";

        pathDetails.push({data : stripe2_data, isFill : true, color : pathData.colors[colors[1]]});

        var stripe_data = stripe1_data + stripe2_data;
        
        var currentXValue = tr;
            
        xpos = striped_width; ypos = midY - _thickn;
        
        var data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = currentXValue; ypos = midY - _thickn;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width, ypos = height/2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = currentXValue; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = striped_width; ypos = midY + _thickn;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = striped_width; ypos = midY - _thickn;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        pathDetails.push({data : data, isFill : true, color : pathData.colors[colors[2]]});

        data = [{data : stripe1_data, isFill : true,  color : pathData.colors[colors[0]]}, {data : stripe2_data, isFill : true, color : pathData.colors[colors[1]]}, {data : data, isFill : true, color : pathData.colors[colors[2]]}]
        
        // data = stripe1_data + stripe2_data + data;
        
        newControlPoints = {t : {}, l : {}};
        newControlPoints.t.x = (tr / width) * 100;
        newControlPoints.t.y = controlPoints.t.y;
        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((midY - _thickn)) / height) * 100;
    }


    return {data : datas, pathDetails : pathDetails, pathData : pathData, newControlPoints : newControlPoints};

}

function angle(cx, cy, ex, ey) {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
  }

function getPlaque(width, height, pathData){
    var least = (height < width) ? height : width;
    var t = (pathData.controlPoints.t.x / 100) * least, xpos, ypos, ratio = 1/2;

    if(t >= least/2)
        t = least/2

    if(t <= 0)
        t = 0;

    var data = "M"+ t +",0 C"+t+",0";

    xpos = width - t; ypos = 0;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos + (t*ratio))

    xpos = width; ypos = t;
    data += " "+ (xpos - (t*ratio)) + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

    xpos = width; ypos = height - t;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos - (t*ratio)) + ","+(ypos)

    xpos = width - t; ypos = height;
    data += " "+ xpos + "," + (ypos - (t*ratio)) +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

    xpos = t; ypos = height;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos - (t*ratio))

    xpos = 0; ypos = height - t;
    data += " "+ (xpos + (t*ratio)) + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

    xpos = 0; ypos = t;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos + (t*ratio)) + ","+(ypos)

    data += " "+ t +","+ (t*ratio) +" "+ t +",0z";

    var newControlPoints = {t : {}};
    newControlPoints.t.x = (t / width) * 100;
    newControlPoints.t.y = pathData.controlPoints.t.y;

    let dataP = {data : data, color : pathData.colors[Object.keys(pathData.colors)[0]]};
   
    if(pathData.isStroke === true){
        dataP.isStroke = true;
        dataP.pathStyle = {left : strokeWidth / 2, top : strokeWidth / 2};
        var shapeStyle = {width : width + strokeWidth, height : height + strokeWidth};
    }
    else 
        dataP.isFill = true;

    return {data : data, pathDetails : [dataP], pathData : pathData, shapeStyle : shapeStyle, newControlPoints : newControlPoints};
    
}

function getMoon(width, height, pathData){
    var least = width;
    var m = (pathData.controlPoints.m.x / 100) * least;

    if(m <= (width * .1))
        m = width * .1

    if(m >= (width * .9))
        m = width * .9

    let ang1 = 90 * Math.PI/180;
    let ang2 = 270 * Math.PI/180;

    var newData = String(createPie(width, height, pathData, (width*2), height, 0, 0, ang1, ang2).data)

    ang1 = 270 * Math.PI/180;
    ang2 = 90 * Math.PI/180;
    
    var newData2 = String( createPie(width, height, pathData, (width*2) - (m*2), height, m, 0, ang1, ang2).data)
	newData=newData.slice(0,-1)
    var testARr=newData2.split(" ");
    
    testARr.shift()
    newData=newData+" "+testARr.join(" ")
    
    var newControlPoints = {m : {}};
    newControlPoints.m.x = (m / width) * 100;
    newControlPoints.m.y = pathData.controlPoints.m.y;
	
    return {data : newData, pathDetails : [{data : newData, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getDonut(width, height, pathData){
    var least = (height < width) ? height : width;
    var m = (pathData.controlPoints.m.x / 100) * least;

    if(m <= (least * .1))
        m = least * .1

    if(m >= (least * .9))
        m = least * .9
    
    if(m >= (least * .5))
        m = (least - m)

    let ang1 = -180 * Math.PI/180;
    let ang2 = 190 * Math.PI/180;

    var circle1 = createPie(width, height, pathData, (width), height, 0, 0, ang1, ang2);
    var newData = String(circle1.data)

    var circle2 = createPie(width, height, pathData, (width) - (m*2), height - (m*2), m, m, ang1, ang2, true);
    var newData2 = String(circle2.data)
	newData = newData.slice(0, -1);
    var newDataArr = newData.split(" ");
    var str = newDataArr[newDataArr.length-1];

    newData2 = newData2.slice(0, -1)
    newData2 += " C"+ circle2.x2 +","+ circle2.y2 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

    newData = newData+" C"+str+" "+str+newData2;

    var newControlPoints = {m : {}};
    newControlPoints.m.x = (m / width) * 100;
    newControlPoints.m.y = pathData.controlPoints.m.y;
	
    return {data : newData, pathDetails : [{data : newData, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getFile(width, height, pathData){
    var least = (height < width) ? height : width, pathDetails = [];
    var b = ((100 - pathData.controlPoints.b.x) / 100) * least, datas = [], xpos, ypos;

    if(b >= least / 2)
        b = least / 2
    if(b <= 0)
        b = 0

    var data = "M0,0 C0,0";

    xpos = width; ypos = 0;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    xpos = width; ypos = height - b;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    xpos = width - b; ypos = height;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    xpos = 0; ypos = height;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    data += " 0,0 0,0z";

    datas.push(data);
    pathDetails.push({data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]});

    data = "M"+ (width - b) +","+height+" C"+ (width - b) +","+height;

    xpos = (width - b) + (b * 0.1); ypos = (height - b) + (b * 0.1);
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    xpos = width; ypos = height - b;
    data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

    data += " "+ (width - b) +","+ height +" "+ (width - b) +","+ height +"z";

    datas.push(data);
    pathDetails.push({data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[1]]});

    var newControlPoints = {b : {}};
    newControlPoints.b.x = ((width - b) / width) * 100;
    newControlPoints.b.y = pathData.controlPoints.b.y;

    return {data : datas, pathDetails : pathDetails, pathData : pathData, newControlPoints : newControlPoints};

}

function getFlowchart(width, height, pathData){
    var least = (height < width) ? height : width;

    if(pathData.type === "flowchart_connector"){
        var l = ((100 - pathData.controlPoints.l.y) / 100) * least;
        if(l >= least / 2)
            l = least / 2
        
        if(l <= 0)
            l = 0;
        let ang1 = 180 * Math.PI/180;
        let ang2 = 360 * Math.PI/180;
        var circle1 = createPie(width, height, pathData, -width, (-(l)*2), width, height, ang1, ang2);
        var newData= String(circle1.data)
        newData = newData.slice(0, -1)

        newData += " C"+0+","+0+" "+0+","+0+" "+0+","+0+" C"+ width +","+0+" "+ width +","+0+" "+ width +","+0;

        newData += " C"+ width +","+ 0 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

        var newControlPoints = {l : {}};
        newControlPoints.l.x = pathData.controlPoints.l.x;
        newControlPoints.l.y = ((height - l) / height) * 100;

        return {data : newData, pathDetails : [{data : newData, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
    }
}

function getTearDrop(width, height, pathData){
    var t = ((pathData.controlPoints.t.x) / 100) * width;
    var ang1 = 0*Math.PI/180;
    var ang2 = 270*Math.PI/180;
    var circle1 = createPie(width, height, pathData, width, height, 0, 0, ang1, ang2);
    var newData= String(circle1.data);

    if(t <= 0)
        t = 0;
    
    if(t >= width)
        t = width;
    
    var diff = width - t;   
    var diffX = t, diffY = diff;

    newData = newData.slice(0,-1);
    newData += " C"+circle1.x2+","+circle1.y2+" "+diffX+","+diffY+" "+diffX+","+diffY+" C"+diffX+","+diffY+" "+circle1.x1+","+circle1.y1+" "+circle1.x1+","+circle1.y1+"z";
    
    
    var newControlPoints = {t : {}};
    newControlPoints.t.x = (t / width) * 100;
    newControlPoints.t.y = pathData.controlPoints.t.y;

    let shapeX = 0, shapeY = 0, shapeWidth = width, shapeHeight = height;
    if(diff > width/2)
        shapeWidth = (width/2) + diff;
    
    return {data : newData, pathDetails : [{data : newData, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints, shapeStyle : {left : shapeX, top : shapeY, width : shapeWidth, height : shapeHeight}};
}

function getCubicBezierPoints(pointDataArr,t) {

    var helperPoints = [];
 
     for (var i = 1; i < 4; i++) {
     var p = lerp(pointDataArr[i - 1], pointDataArr[i], t);
     helperPoints.push(p);
     }
 
     helperPoints.push(lerp(helperPoints[0], helperPoints[1], t));
     helperPoints.push(lerp(helperPoints[1], helperPoints[2], t));
 
 
     helperPoints.push(lerp(helperPoints[3], helperPoints[4], t));
 
     let firstBezier = [
     pointDataArr[0],
     helperPoints[0],
     helperPoints[3],
     helperPoints[5]
     ];
     let secondBezier = [
     helperPoints[5],
     helperPoints[4],
     helperPoints[2],
     pointDataArr[3]
     ];
 
     // returns 2 array of points for the new bezier curves;
     return [firstBezier, secondBezier];
   }
 
   function lerp(A, B, t) {
 
     return [
     (Number(B[0]) - Number(A[0])) * t + Number(A[0]), // the x coordinate;
     (Number(B[1]) - Number(A[1])) * t + Number(A[1])  // the y coordinate;
     ];
     
   }

function sfix(arr){
     var num1=Number(arr[0]);
     var num2=Number(arr[1]);
     num1 = num1 < 0 ? Math.floor(Math.abs(num1) * 100) * -1 / 100 : num1.toFixed(2);
     num2 = num2 < 0 ? Math.floor(Math.abs(num2) * 100) * -1 / 100 : num2.toFixed(2);
     return num1+","+num2
}

function sFix2(num1,num2){
  num1=Number(num1);
  num2=Number(num2);
  num1 = num1 < 0 ? Math.floor(Math.abs(num1) * 100) * -1 / 100 : num1.toFixed(2);
  num2 = num2 < 0 ? Math.floor(Math.abs(num2) * 100) * -1 / 100 : num2.toFixed(2);
  return num1+","+num2
}

function dist(pot1, pt2) {
    var dx = pot1.x - pt2.x;
    var dy = pot1.y - pt2.y;
    return Math.sqrt(dx * dx + dy * dy);
};

function gradSearch(l0, pt, actualLength, cltpth){
    l0 = l0 + actualLength;
		
		var l1 = l0,
			dist0 = dist(cltpth.getPointAtLength(l0 % actualLength), pt),
			dist1,
            searchDir;
		if (dist(cltpth.getPointAtLength((l0 - 1) % actualLength), pt) > 
    dist(cltpth.getPointAtLength((l0 + 1) % actualLength), pt)) {
			searchDir = 1;
		} else {
			searchDir = -1;
		}

		l1 += searchDir;
		dist1 = dist(cltpth.getPointAtLength(l1 % actualLength), pt);
		while (dist1 < dist0) {
			dist0 = dist1;
			l1 += searchDir;
			dist1 = dist(cltpth.getPointAtLength(l1 % actualLength), pt);
		}
		l1 -= searchDir;
		return (l1 % actualLength);
  }

function getCallout(width, height, pathData, storedItem){
    var px = ((pathData.controlPoints.p.x) / 100) * width;
    var py = ((pathData.controlPoints.p.y) / 100) * height;
    
    let currentAngle = ((Math.PI + Math.atan2(-px + (width/2), py - (height/2))) * 180 / Math.PI) - 90;

    let shapeX = 0, shapeY = 0, shapeWidth = width, shapeHeight = height;
    if(px > width)
        shapeWidth = px;
    if(py > height)
        shapeHeight = py;
    if(px < 0)
    {
        shapeX = px;
        shapeWidth = width - px;
    }
    if(py < 0)
    {
        shapeY = py;
        shapeHeight = height - py;
    }
    

    if(pathData.type === "circle_callout"){
        if(currentAngle <= 0)
            currentAngle += 360
        
        let angle1 = currentAngle - 15;
        let angle2 =currentAngle + 15;

        if(angle2 >= 0)
            angle2 = (-360 + angle2);

        var ang2 = angle1 * Math.PI/180;
        var ang1 = angle2 * Math.PI/180;

        var circle = createPie(width, height, pathData, width, height, 0, 0, ang1, ang2);
    
        var data = String(circle.data);

        data = data.slice(0, -1)
       
        data += " C"+ circle.x2 +","+ circle.y2 +" "+(px)+","+(py)+" "+(px)+","+(py);
        data += " C"+(px)+","+(py)+" "+circle.x1+","+circle.y1+" "+(circle.x1)+","+circle.y1+"z";

        var newControlPoints = {p : {}};
        newControlPoints.p.x = (px / width) * 100;
        newControlPoints.p.y = (py / height) * 100;

    }else if(pathData.type === "bubble_callout"){
       
        var least = (height < width) ? height : width;
      
        var dy1 = py - height/2;
        var dx1 = px - width/2;
        var theta = Math.atan2(dy1, dx1); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        if (theta < 0) theta = 360 + theta; // range [0, 360)
        
        currentAngle = theta;
       
        let angle1 = currentAngle;
        let angle2 =currentAngle;

        if(angle2 >= 0)
            angle2 = (-360 + angle2);

        ang1 = angle1 * Math.PI/180;
        ang2 = angle2 * Math.PI/180;

        circle = createPie(width, height, pathData, width, height, 0, 0, ang1, ang2);
        
        data = String(circle.data);

       
        let handleAngle = getHandleAngle(px, py, width, height);
        handleAngle = handleAngle * 180/Math.PI;
        // handleAngle = (180 - handleAngle);
        if(handleAngle < 0)
            handleAngle = 360 + handleAngle

        handleAngle = handleAngle * Math.PI/180;
        let dx = px - parseFloat(circle.x1), dy = py - parseFloat(circle.y1);

        let dist = Math.sqrt(dx * dx + dy * dy);
        var diameter1 = 0.05 * least;
        var diameter2 = 0.1 * least;
        var diameter3 = 0.15 * least;

        let disPers = (dist * .25)

        var bubble1X = parseFloat(circle.x1) + (dist - diameter1) * Math.cos(handleAngle);
        var bubble1Y = parseFloat(circle.y1) + (dist - diameter1) * Math.sin(handleAngle);

        var bubble2X = parseFloat(circle.x1) + (dist - (disPers + diameter2 / 2)) * Math.cos(handleAngle);
        var bubble2Y = parseFloat(circle.y1) + (dist - (disPers + diameter2 / 2)) * Math.sin(handleAngle);

        var bubble3X = parseFloat(circle.x1) + (dist - ((disPers + diameter2 / 2) * 2)) * Math.cos(handleAngle);
        var bubble3Y = parseFloat(circle.y1) + (dist - ((disPers + diameter2 / 2) * 2)) * Math.sin(handleAngle);

       

        data += createPie(width, height, pathData, diameter1, diameter1, bubble1X - diameter1 / 2, bubble1Y - diameter1/2, ang1, ang2).data;

        data += createPie(width, height, pathData, diameter2, diameter2, bubble2X - diameter2 / 2, bubble2Y - diameter2/2, ang1, ang2).data;

        data += createPie(width, height, pathData, diameter3, diameter3, bubble3X - diameter3/2, bubble3Y - diameter3/2, ang1, ang2).data;

        newControlPoints = {p : {}};
        newControlPoints.p.x = (bubble1X/width) * 100;
        newControlPoints.p.y = (bubble1Y/height) * 100;

        if(bubble1X > width)
            shapeWidth = bubble1X + diameter1;
        if(bubble1Y > height)
            shapeHeight = bubble1Y + diameter1;
        if(bubble1X < 0)
        {
            shapeX = bubble1X - diameter1;
            shapeWidth = width - bubble1X + diameter1;
        }
        if(bubble1Y < 0)
        {
            shapeY = bubble1Y - diameter1;
            shapeHeight = height - bubble1Y + diameter1;
        }

       
    }else if(pathData.type === "rectangle_callout" || pathData.type === "rounded_rectangle_callout"){
        least = (height < width) ? height : width;

        if(pathData.type === "rectangle_callout"){
            var data = "M0,0 C0,0", xpos, ypos;

            xpos = width; ypos = 0;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

            xpos = width; ypos = height;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

            xpos = 0; ypos = height;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ xpos + ","+ypos;

            data += " 0,0 0,0z";
        }else if(pathData.type === "rounded_rectangle_callout"){
            var t = 0.15 * least, ratio = 1/2;

            data = "M"+ t +",0 C"+t+",0";

            xpos = width - t; ypos = 0;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos + (t*ratio)) + ","+(ypos)

            xpos = width; ypos = t;
            data += " "+ (xpos ) + "," + (ypos - (t*ratio)) +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

            xpos = width; ypos = height - t;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos+(t*ratio))

            xpos = width - t; ypos = height;
            data += " "+ (xpos  + (t*ratio)) + "," + (ypos) +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

            xpos = t; ypos = height;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos - (t*ratio)) + ","+(ypos)

            xpos = 0; ypos = height - t;
            data += " "+ (xpos ) + "," + (ypos + (t*ratio)) +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos)

            xpos = 0; ypos = t;
            data += " "+ xpos + "," + ypos +" "+ xpos + "," + ypos +" C"+ (xpos) + ","+(ypos - (t*ratio))

            data += " "+ (t - (t*ratio)) +",0 "+ t +",0z";
        }

        var cltpth = document.createElementNS("http://www.w3.org/2000/svg","path");	
        cltpth.setAttributeNS(null,'d', data);
        
        var clt = document.createElementNS("http://www.w3.org/2000/svg","path");	
        clt.setAttributeNS(null,'d', data);
        let centerX = width / 2, centerY = height / 2;
        var insideX = px > 0 && px < width;
        var insideY = py > 0 && py < height;
        var pointInsideRectangle = insideX && insideY;
        var closestPoint = {};
        var tailPosition = 0;
        if (!pointInsideRectangle){ //Outside

            if(px > width && py < 0){
                closestPoint = {x: width, y : 0}
                tailPosition = width;
            }
            else if(px > width && py > height){
                
                closestPoint = {x: width, y : height}
                tailPosition = width + height;
            }
            else if(px < 0 && py > height){
                
                closestPoint = {x: 0, y : height}
                tailPosition = (width * 2) + height;
            }
            else if(px < 0 && py < 0){
               
                closestPoint = {x: 0, y : 0}
                tailPosition = 0;
            }
            else{
                if(py < 0){
                  
                    closestPoint = {x: px, y : 0};
                    tailPosition = px;
                }
                else if(px > width){
                   
                    closestPoint = {x: width, y : py}
                    tailPosition = width + py;
                }
                else if(py > height){
                   
                    closestPoint = {x: px, y : height}
                    tailPosition = width + height + (width -  px);
                }
                else if(px < 0){
                   
                    closestPoint = {x: 0, y : py}
                    tailPosition = (width * 2) + height + (height -  py);
                }
            }
        }else{
            if(centerX > px){
                closestPoint.x = (px > centerX - px)?px:0;
            }else{
                closestPoint.x = (px > px - centerX)?px:width;
            }

            if(centerY > py){
                closestPoint.y = (py > centerY - py)?py:0;
            }else{
                closestPoint.y = (py > py - centerY)?py:height;
            }
        }
        
       
        var actualLength = cltpth.getTotalLength();
        
          var ptArray = [], segInc = 0, totLen = 0, individualPtArray =[];
          ptArray[segInc] = clt.getPointAtLength(0);
          ptArray[segInc].t = 0;
          var pathString = data;
          var pathArr = [];
        
            var _Data=pathString.slice(0, -1)
            var ptArrays =[]
            ptArrays=_Data.split(" ");
            individualPtArray=_Data.split(" ");
            individualPtArray[0]=individualPtArray[0].substring(1)
            var tempArr=[]
            var str=ptArrays[0]

            for(var i = 0; i < ptArrays.length-1; i += 3){
                if(i===0){
                tempArr.push(ptArrays[i]+" "+ptArrays[i+1]+" "+ptArrays[i+2]+" "+ptArrays[i+3]+" ")
                }else{
                tempArr.push(ptArrays[i+1]+" "+ptArrays[i+2]+" "+ptArrays[i+3]+" ")
                }
                str=str+" "+ptArrays[i+1]+" "+ptArrays[i+2]+" "+ptArrays[i+3]+" "
              
                clt.setAttributeNS(null, 'd', str);
                totLen = clt.getTotalLength();
                ptArray[segInc] = clt.getPointAtLength(totLen);
                ptArray[segInc].t = totLen;
                segInc++;
            }

            pathArr = tempArr;
            
            var pointLen = pathArr.length;
            
            var cTail = tailPosition, callThick = 0.30 * least;
            var midPoint = cTail + (callThick/2);
            midPoint = gradSearch(midPoint, {x : px, y : py}, actualLength, cltpth);
            cTail = midPoint - (callThick/2);				
            var endPt = cTail + callThick;
            if(cTail<0){
                cTail = cTail + actualLength;
            }
            if(endPt > actualLength){
                endPt = endPt - actualLength;
            }

            var pt = cltpth.getPointAtLength(cTail);
            
            var pt2= cltpth.getPointAtLength(endPt);
            
            midPoint = cTail + (callThick/2);
            if(midPoint > actualLength)
            midPoint = midPoint - actualLength;
           
            var dx2 = px;
            var dy2 = py;

            cltpth = null;
            clt = null;

            var plen2=ptArray.length;
		
            var currentSegment1=0;
            var currentSegment2=0;
            
            for(i=0;i<plen2;i++){						
                if(cTail<ptArray[i].t){		
                currentSegment1=i;
                break;							
                }
            }

            for(var j=0;j<plen2;j++){						
                if(endPt<ptArray[j].t){		
                currentSegment2=j;
                break;							
                }
            }

            var istr=""
            var totalIndi=((currentSegment1+1)*3)+1
            
            for(var v=0;v<totalIndi-3;v++){		
                
                
            istr=istr+individualPtArray[v]+" "
            }
            var cir2ptDataArr=[]
            for(v=totalIndi-4;v<totalIndi;v++){	
            
                if(individualPtArray[v].indexOf("C")!==-1){
                cir2ptDataArr.push(individualPtArray[v].substring(1).split(","))	
                }else{
                cir2ptDataArr.push(individualPtArray[v].split(",")) 	
                }	
            }
            
            var cir3ptDataArr=[]

            var totalIndi2=((currentSegment2+1)*3)+1
            
            for(var d=totalIndi2-4;d<totalIndi2;d++){	
            
                if(individualPtArray[d].indexOf("C")!==-1){
                cir3ptDataArr.push(individualPtArray[d].substring(1).split(","))
                }else{
                cir3ptDataArr.push(individualPtArray[d].split(",")) 	
                }
            }
            if(currentSegment2>=currentSegment1){
            
            }else{	
            
            }
            var ustr=""
            var totalInd=individualPtArray.length
            for(var u=totalIndi2-1;u<totalInd;u++){		
            ustr=ustr+individualPtArray[u]+" "
            }
            
            var curseg1=currentSegment1
            var t
            if(currentSegment1<=0){
                t=(cTail/ptArray[0].t)
            }else{
                t=((cTail-ptArray[curseg1-1].t)/(ptArray[curseg1].t-ptArray[curseg1-1].t))
                
            }
            
            var resultcir2Arr = getCubicBezierPoints(cir2ptDataArr,t);
            
            
            var t2
            var curseg2=currentSegment2
            if(currentSegment2-1<0){
                t2=(endPt/ptArray[0].t)
            }else{
                t2=((endPt-ptArray[curseg2-1].t)/(ptArray[curseg2].t-ptArray[curseg2-1].t))
            }
            
            
            var resultcir3Arr = getCubicBezierPoints(cir3ptDataArr,t2);

            var cl0 = sFix2(pt.x,pt.y)
            var cl = sFix2(dx2,dy2)
            var cl2 = sFix2(pt2.x,pt2.y)
            var h1 = sfix(resultcir2Arr[0][1])
            var h2 = sfix(resultcir2Arr[0][2])
            
            var b1 = sfix(resultcir3Arr[1][1])
            var b2 = sfix(resultcir3Arr[1][2]);

            if(currentSegment2>=currentSegment1){
                data = "M"+istr+"C"+h1+" "+h2+" "+cl0+" C"+cl0+" "+cl+" "+cl+" C"+cl+" "+cl2+" "+cl2+" C"+b1+" "+b2+" "+ustr.trim()+"z";
            }else{
                var istrArr=istr.split(" ")
                istrArr.shift();
                istrArr.shift();
                istrArr.shift();
                istr=istrArr.join(" ")
                data = "M"+cl2+" C"+b1+" "+	b2+" "+istr+"C"+h1+" "+h2+" "+cl0+" C"+cl0+" "+cl+" "+cl+" C"+cl+" "+cl2+" "+cl2+"z";
            }

            newControlPoints = {p : {}};
            newControlPoints.p.x = (px / width) * 100;
            newControlPoints.p.y = (py / height) * 100;
    }

    let dataP = {data : data, color : pathData.colors[Object.keys(pathData.colors)[0]]};
    let pathLeft = -shapeX, pathTop = -shapeY;
    if(pathData.isStroke === true){
        dataP.isStroke = true;
        pathLeft += strokeWidth / 2; pathTop += strokeWidth / 2;
    }
    else 
        dataP.isFill = true;

    dataP.pathStyle = {left : pathLeft, top : pathTop};
        
    return {data : data, pathDetails : [dataP],  pathData : pathData, shapeStyle : {left : shapeX, top : shapeY, width : shapeWidth + strokeWidth, height : shapeHeight + strokeWidth}, newControlPoints : newControlPoints};
}

function getSymbol(width, height, pathData){
    var least = (height < width) ? height : width, pathDetails = [];
    var data = [];
    var p = (pathData.controlPoints.p.x / 100) * least;

    if(p <= (least * .1))
        p = least * .1

    if(p >= (least * .9))
        p = least * .9
    
    if(p >= (least * .5))
        p = (least - p)

    if(p >= (width * .3))
        p = (width * .3)
        
    var ang1 = -180 * Math.PI/180;
    var ang2 = 180 * Math.PI/180;

    var circle1 = createPie(width, height, pathData, width, height, 0, 0, ang1, ang2, false);
    var newData = circle1.data;
    var circle2 = createPie(width, height, pathData, width - (p * 2), height - (p * 2), p, p, ang1, ang2, true);
    var newData2 = circle2.data;

    newData2 = newData2.slice(0, -1);
    newData2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";
    
    newData = newData.slice(0, -1);
    var newDataArr = newData.split(" ");
    var str = newDataArr[newDataArr.length-1];
    newData = newData+" C"+str+" "+str+newData2;

    data.push(newData);
    pathDetails.push({data : newData, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]});
    

    var sq1 = (width-(p*2))
    var aaa=((sq1*sq1)+(sq1*sq1)-(p*p))/(2*sq1*sq1);
    aaa=Math.acos(aaa);
    aaa=(aaa*(180/Math.PI));

    var midV=-130;
    ang1=(midV-aaa)*Math.PI/180;
    ang2=(midV+aaa)*Math.PI/180;	
    circle1 = createPie(width, height, pathData, width - (p * 2) - 2, height - (p * 2) -2, p, p, ang1, ang2, false);
    var bData1 = circle1.data;

    midV=45;
		
    ang1=(midV+aaa)*Math.PI/180;
    ang2=(midV-aaa)*Math.PI/180;	

    circle2 = createPie(width, height, pathData, width - (p * 2) + 1, height - (p * 2) + 1, p, p, ang1, ang2, true);
    var bData2 = circle2.data;

    bData2 = bData2.slice(0, -1);
    bData2 += " C"+ circle2.x1 +","+ circle2.y1 +" "+ circle1.x1 +","+ circle1.y1 +" "+ circle1.x1 +","+ circle1.y1+"z";

    bData1 = bData1.slice(0, -1);	
    newDataArr = bData1.split(" ");
    str = newDataArr[newDataArr.length-1];
    bData1 = bData1+" C"+str+" "+str+bData2;

    data.push(bData1);
    pathDetails.push({data : bData1, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[1]]});

    var newControlPoints = {p : {}};
    newControlPoints.p.x = (p / width) * 100;
    newControlPoints.p.y = pathData.controlPoints.p.y;

    return {data : data, pathDetails : pathDetails, pathData : pathData, newControlPoints : newControlPoints};
}


function getScalene(width, height, pathData){
    var data = "";
    var l = (pathData.controlPoints.l.y / 100) * height;

    if(l <= (0.1 * height))
        l = 0.1 * height
    
    if(l >= height)
        l = height
    
    data = "M0,"+ l +" C0,"+ l;
    data += drawData(0, 0);
    data += drawData(width, height);
    data += " 0,"+ l +" 0,"+ l +"z";

    var newControlPoints = {l : {}};
    newControlPoints.l.x = pathData.controlPoints.l.x;
    newControlPoints.l.y = (l / height) * 100;

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getRepeated(width, height, pathData){
    var data = "", xpos, ypos;
    var controlPoints = pathData.controlPoints;
    var midY = height / 2;

    if(height <= 1)
        height = 1;
   
    if(pathData.type === "repeated_arrow"){
        var least = height;
        
        var repeaterLeast = (height < width) ? height : width;

        var tr = width - (((100 - controlPoints.t.x) / 100) * least);
    
        if(tr < 0)
            tr = 0;

        if(tr >= width)
            tr = width
        
        var repeaterWidth = 0.2 * repeaterLeast;
        var repeaterGap = 0.1 * repeaterLeast;

        var _thickn = (least/2) - (controlPoints.l.y / 100) * least;

        if(_thickn >= least/2 )
            _thickn = least/2
        var startX, startY, endX, endY;
        
        for(var r=0; r < Math.floor(tr / (repeaterWidth + repeaterGap)); r++ ){
            startX = ((r * repeaterWidth) + ((r + 1) * repeaterGap));
            startY = (midY - _thickn);
            endX = startX + repeaterWidth;
            endY = (midY + _thickn);

            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ endX +","+ startY +" "+ endX +","+ startY +" C"+ endX + "," +startY;
            data += " "+ endX +","+ endY +" "+ endX +","+ endY +" C"+ endX + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";
        }

        if((tr % (repeaterWidth + repeaterGap)) > repeaterGap){
            startX = tr - ((tr % (repeaterWidth + repeaterGap)) - repeaterGap);
            startY = (midY - _thickn);
            endX = tr;
            endY = (midY + _thickn);
            
            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ endX +","+ startY +" "+ endX +","+ startY +" C"+ endX + "," +startY;
            data += " "+ endX +","+ endY +" "+ endX +","+ endY +" C"+ endX + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";
        }
      
        data += "M"+ tr +",0 C"+ tr +",0";

        xpos = width, ypos = height / 2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = tr, ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        data += " "+ tr +","+ 0 +" "+tr+","+0+"z";

        var newControlPoints = {l : {}, t : {}};
        newControlPoints.t.x = (tr/width) * 100;
        newControlPoints.t.y = controlPoints.t.y;

        newControlPoints.l.x = controlPoints.l.x;
        newControlPoints.l.y = (((least/2) - _thickn) / height) * 100;
    }
    else if(pathData.type === "repeated_bar"){
        
        var least = (height < width) ? height : width;

        if(width < height)
            least = height
        var repeaterWidth = 0.1 * least;
        var repeaterGap = 0.1 * least;

        var startX, startY, endX, endY;
        
        for(var r=0; r < Math.floor(width / (repeaterWidth + repeaterGap)); r++ ){
            startX = ((r * repeaterWidth) + ((r + 1) * repeaterGap));
            startY = 0;
            endX = startX + repeaterWidth;
            endY = height;

            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ endX +","+ startY +" "+ endX +","+ startY +" C"+ endX + "," +startY;
            data += " "+ endX +","+ endY +" "+ endX +","+ endY +" C"+ endX + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";
        }

        if((width % (repeaterWidth + repeaterGap)) > repeaterGap){
            startX = width - ((width % (repeaterWidth + repeaterGap)) - repeaterGap);
            startY = 0;
            endX = width;
            endY = height;
            
            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ endX +","+ startY +" "+ endX +","+ startY +" C"+ endX + "," +startY;
            data += " "+ endX +","+ endY +" "+ endX +","+ endY +" C"+ endX + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";
        }

    }
    else if(pathData.type === "repeated_double"){
        var least = (height < width) ? height : width;

        if(width < height)
            least = height
        var repeater1Width = 0.25 * least;
        var repeater1Gap = 0.25 * least;

        var repeater2Width = 0.1 * least;
        var repeater2Gap = 0.30 * least;

        var start1X = 0, start1Y = 0, end1X = 0 , end1Y = 0, start2X = 0, start2Y = 0, end2X = 0, end2Y = 0;
        
        for(var r=0; r < Math.floor(width / (repeater1Width + repeater1Gap + repeater2Width + repeater2Gap)); r++ ){
            start1X = ((r * repeater1Width) + ((r + 1 ) * repeater1Gap) + ((r + 1) * repeater2Gap));
            start1Y = 0;
            end1X = start1X + repeater1Width;
            end1Y = height;
           
            data += " M"+ start1X +","+ start1Y +" C"+ start1X + "," + start1Y;
            data += " "+ end1X +","+ start1Y +" "+ end1X +","+ start1Y +" C"+ end1X + "," +start1Y;
            data += " "+ end1X +","+ end1Y +" "+ end1X +","+ end1Y +" C"+ end1X + "," +end1Y;
            data += " "+ start1X +","+ end1Y +" "+ start1X +","+ end1Y +" C"+ start1X + "," +end1Y;
            data += " "+ start1X +","+ start1Y +" "+ start1X +","+ start1Y + "z";

            start2X = end1X + (repeater2Gap);
            start2Y = 0;
            end2X = start2X + repeater2Width;
            end2Y = height;
           
            data += " M"+ start2X +","+ start2Y +" C"+ start2X + "," + start2Y;
            data += " "+ end2X +","+ start2Y +" "+ end2X +","+ start2Y +" C"+ end2X + "," +start2Y;
            data += " "+ end2X +","+ end2Y +" "+ end2X +","+ end2Y +" C"+ end2X + "," +end2Y;
            data += " "+ start2X +","+ end2Y +" "+ start2X +","+ end2Y +" C"+ start2X + "," +end2Y;
            data += " "+ start2X +","+ start2Y +" "+ start2X +","+ start2Y + "z";
        }
        
        if(end1X < width && (width - end1X) > repeater2Gap){
            let lastX = end1X;
            start1X = lastX + repeater2Gap;
            start1Y = 0;
            end1X = start1X + repeater2Width;
            end1Y = height;
           
            data += " M"+ start1X +","+ start1Y +" C"+ start1X + "," + start1Y;
            data += " "+ end1X +","+ start1Y +" "+ end1X +","+ start1Y +" C"+ end1X + "," +start1Y;
            data += " "+ end1X +","+ end1Y +" "+ end1X +","+ end1Y +" C"+ end1X + "," +end1Y;
            data += " "+ start1X +","+ end1Y +" "+ start1X +","+ end1Y +" C"+ start1X + "," +end1Y;
            data += " "+ start1X +","+ start1Y +" "+ start1X +","+ start1Y + "z";

            start1X = lastX + repeater1Gap + repeater2Gap;
            start1Y = 0;
            end1X = start1X + repeater1Width;
            end1Y = height;
           
            data += " M"+ start1X +","+ start1Y +" C"+ start1X + "," + start1Y;
            data += " "+ end1X +","+ start1Y +" "+ end1X +","+ start1Y +" C"+ end1X + "," +start1Y;
            data += " "+ end1X +","+ end1Y +" "+ end1X +","+ end1Y +" C"+ end1X + "," +end1Y;
            data += " "+ start1X +","+ end1Y +" "+ start1X +","+ end1Y +" C"+ start1X + "," +end1Y;
            data += " "+ start1X +","+ start1Y +" "+ start1X +","+ start1Y + "z";
        }

        
    }
    else if(pathData.type === "repeated_triple"){
       
        var least = (height < width) ? height : width;

        if(width < height)
            least = height;
        
        var repeaterWidth = least;
        var repeaterGap = 0.25 * least;
      
        var bar1 = 0.075 * repeaterWidth;
        var gap1 = 0.225 * repeaterWidth;
        var bar2 = 0.3 * repeaterWidth;
        var gap2 = 0.1 * repeaterWidth;
        var bar3 = 0.3 * repeaterWidth;

        var startX = 0, startY = 0, endX = 0 , endY = 0;

        for(var r=0; r < Math.floor(width / (repeaterWidth + repeaterGap)); r++ ){
            startX = ((r * repeaterWidth) + ((r + 1 ) * repeaterGap));
            startY = 0;
            endX = startX + repeaterWidth;
            endY = height;

            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ (startX + bar1) +","+ startY +" "+ (startX + bar1) +","+ startY +" C"+ (startX + bar1) + "," +startY;
            data += " "+ (startX + bar1) +","+ endY +" "+ (startX + bar1) +","+ endY +" C"+ (startX + bar1) + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";

            data += " M"+ (startX + bar1 + gap1) +","+ startY +" C"+ (startX + bar1 + gap1) + "," + startY;
            data += " "+ (startX + bar1 + gap1 + bar2) +","+ startY +" "+ (startX + bar1 + gap1 + bar2) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2) + "," +startY;
            data += " "+ (startX + bar1 + gap1 + bar2) +","+ endY +" "+ (startX + bar1 + gap1 + bar2) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2) + "," +endY;
            data += " "+ (startX + bar1 + gap1) +","+ endY +" "+ (startX + bar1 + gap1) +","+ endY +" C"+ (startX + bar1 + gap1) + "," +endY;
            data += " "+ (startX + bar1 + gap1) +","+ startY +" "+ (startX + bar1 + gap1) +","+ startY + "z";

            data += " M"+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2 + gap2) + "," + startY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ startY +" "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) + "," +startY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ endY +" "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) + "," +endY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ endY +" "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2 + gap2) + "," +endY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY +" "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY + "z";
        }

        if((width % (repeaterWidth + repeaterGap)) > repeaterGap){
            startX = width - ((width % (repeaterWidth + repeaterGap)) - repeaterGap);
            startY = 0;
            endX = width;
            endY = height;

            

           if((startX + bar1 + gap1 + bar2 + gap2) < width){
               bar3 = width - startX - bar1 - gap1 - bar2;
            }
            else if((startX + bar1 + gap1 + bar2) < width){
                gap2 = width - startX - bar1 - gap1; bar3 = 0;
            }
            else if((startX + bar1 + gap1) < width){
                bar2 = width - startX - bar1 - gap1;gap2 = 0;bar3 = 0;
            }
            else if((startX + bar1) < width){
              gap1 = width - startX - bar1;bar2 = 0;gap2 = 0;bar3 = 0;
            }
            

            data += " M"+ startX +","+ startY +" C"+ startX + "," + startY;
            data += " "+ (startX + bar1) +","+ startY +" "+ (startX + bar1) +","+ startY +" C"+ (startX + bar1) + "," +startY;
            data += " "+ (startX + bar1) +","+ endY +" "+ (startX + bar1) +","+ endY +" C"+ (startX + bar1) + "," +endY;
            data += " "+ startX +","+ endY +" "+ startX +","+ endY +" C"+ startX + "," +endY;
            data += " "+ startX +","+ startY +" "+ startX +","+ startY + "z";

            data += " M"+ (startX + bar1 + gap1) +","+ startY +" C"+ (startX + bar1 + gap1) + "," + startY;
            data += " "+ (startX + bar1 + gap1 + bar2) +","+ startY +" "+ (startX + bar1 + gap1 + bar2) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2) + "," +startY;
            data += " "+ (startX + bar1 + gap1 + bar2) +","+ endY +" "+ (startX + bar1 + gap1 + bar2) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2) + "," +endY;
            data += " "+ (startX + bar1 + gap1) +","+ endY +" "+ (startX + bar1 + gap1) +","+ endY +" C"+ (startX + bar1 + gap1) + "," +endY;
            data += " "+ (startX + bar1 + gap1) +","+ startY +" "+ (startX + bar1 + gap1) +","+ startY + "z";

            data += " M"+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2 + gap2) + "," + startY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ startY +" "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ startY +" C"+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) + "," +startY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ endY +" "+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2 + gap2 + bar3) + "," +endY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ endY +" "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ endY +" C"+ (startX + bar1 + gap1 + bar2 + gap2) + "," +endY;
            data += " "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY +" "+ (startX + bar1 + gap1 + bar2 + gap2) +","+ startY + "z";
        }

    }

    return {data : data, pathDetails : [{data : data, isFill : true, color : pathData.colors[Object.keys(pathData.colors)[0]]}], pathData : pathData, newControlPoints : newControlPoints};
}

function getControlPoints(item, zoomFactor){
    let pathData = item.get("pathData").toJS();
    let controlPoints = pathData.controlPoints, newControlPoints = {};
    var width = item.get("width") * zoomFactor, height = item.get("height") * zoomFactor;
    var least = (height < width) ? height : width;
    if(pathData.type === "ribbon"){
        var t = (controlPoints.t.x * least) / 100;
        if(t < 0)
            t = 0;
        if(t >= width)
            t = width;
        
        newControlPoints.t = {x: (t / width) * 100, y : controlPoints.t.y}
    }

    return newControlPoints;
}

export function getRatio(item, zoomFactor){
    let pathData = item.get("pathData").toJS(), textContainerRatio = {};
    if(pathData.type === "ribbon"){
        var points = getControlPoints(item, zoomFactor);

        textContainerRatio.x = points.t.x;
        textContainerRatio.y = 5;
        textContainerRatio.width = 100 - (points.t.x * 2);
        textContainerRatio.height = 90;
    }

    return textContainerRatio;
}

function getBanner(item, zoomFactor){
    let pathData = item.get("pathData").toJS();
    let colors = item.get("colors").toJS();
    let controlPoints = pathData.controlPoints, xpos, ypos, containerRatio;
    var data = "", width = item.get("width") * zoomFactor, height = item.get("height") * zoomFactor;
    if(pathData.type === "ribbon"){
        var points = getControlPoints(item, zoomFactor);
            
        var currentXValue = (points.t.x * width) / 100;
            
        xpos = 0; ypos = 0;
        
        data = "M"+ xpos +","+ ypos +" C"+ xpos +","+ypos

		xpos = width; ypos = 0;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width - currentXValue; ypos = height/2;
		data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = width; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;
        
        xpos = 0; ypos = height;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = currentXValue; ypos = height / 2;
        data += " "+ xpos +","+ ypos +" "+ xpos +","+ ypos +" C"+ xpos +","+ypos;

        xpos = 0; ypos = 0;
        data += " "+ xpos +","+ ypos +" "+xpos+","+ypos+"z";

        containerRatio = getRatio(item, zoomFactor);

    }
    
    return {data : data, pathDetails : [{data : data, isFill : true, color : colors[Object.keys(colors)[0]]}], pathData : pathData, containerRatio : containerRatio};
}