/* eslint-disable prefer-template */

// Individual Util functions that perform the necessary calculation.
// Can be reused for future requirements.

export function hexToRgb(hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        , (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
}

export function calculatedThickness(val, fontSize) {
    const maxThickness = 0.06 * parseInt((fontSize + "").replace("px", ""), 10);
    return (1 + (maxThickness * val * 0.01)) / 2;
}

// reverse logic for calculatedThickness
export function reverseCalculatedThickness(strokeWidth, fontSize) {
    const maxThickness = 0.06 * parseInt((fontSize + "").replace("px", ""), 10);
    return (2 * strokeWidth - 1) / (maxThickness * 0.01);
}

export function getDirectionValues(shadowOffset, shadowDir, fontSize) {
    const maxShadow = 0.12 * parseInt((fontSize + "").replace("px", ""), 10) * 0.6;
    const offset = maxShadow * (shadowOffset / 100);
    const dir1 = offset * Math.cos(shadowDir * Math.PI / 180);
    const dir2 = offset * Math.sin(shadowDir * Math.PI / 180);
    return {
        directionOne: dir1,
        directionTwo: dir2
    };
}

export function calculateNeonShadow(intensity, color) {
    const rgb = hexToRgb(color);
    return `drop-shadow(rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/95%) 0px 0px ${1 + (0.01 * intensity)}px)
            drop-shadow(rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/75%) 0px 0px ${1 + (0.02 * intensity)}px)
            drop-shadow(rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/44%) 0px 0px ${3 + (0.02 * intensity)}px)`
}

export function calculateNeonColor(intensity, color) {
    const rgb = hexToRgb(color);
    const r = rgb[0] + Math.ceil((255 - rgb[0]) / 100 * intensity);
    const g = rgb[1] + Math.ceil((255 - rgb[1]) / 100 * intensity);
    const b = rgb[2] + Math.ceil((255 - rgb[2]) / 100 * intensity);
    return `rgb(${r} ${g} ${b})`;
}

export function calculateOutlineTextShadow(color, thickness, blurValue, transparency, fontSize) {
    const strokewidth = calculatedThickness(thickness, fontSize);
    const blur = (20 * 0.05) + blurValue * 0.02;
    const rgb = hexToRgb(color);
    const shadowColor = `rgb(${rgb[0]} ${rgb[1]} ${rgb[2]} / ${transparency}%)`;

    return strokewidth < 6 ? `
        ${strokewidth * 1}px ${strokewidth * 0}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9808}px ${strokewidth * 0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9239}px ${strokewidth * 0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8315}px ${strokewidth * 0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * 0.7071}px ${strokewidth * 0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * 0.5556}px ${strokewidth * 0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * 0.3827}px ${strokewidth * 0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * 0.1951}px ${strokewidth * 0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * 0}px ${strokewidth * 1}px ${blur}px ${shadowColor},
        ${strokewidth * -0.1951}px ${strokewidth * 0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * -0.3827}px ${strokewidth * 0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * -0.5556}px ${strokewidth * 0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * -0.7071}px ${strokewidth * 0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8315}px ${strokewidth * 0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9239}px ${strokewidth * 0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9808}px ${strokewidth * 0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * -1}px ${strokewidth * 0}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9808}px ${strokewidth * -0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9239}px ${strokewidth * -0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8315}px ${strokewidth * -0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * -0.7071}px ${strokewidth * -0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * -0.5556}px ${strokewidth * -0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * -0.3827}px ${strokewidth * -0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * -0.1951}px ${strokewidth * -0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * 0}px ${strokewidth * -1}px ${blur}px ${shadowColor},
        ${strokewidth * 0.1951}px ${strokewidth * -0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * 0.3827}px ${strokewidth * -0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * 0.5556}px ${strokewidth * -0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * 0.7071}px ${strokewidth * -0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8315}px ${strokewidth * -0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9239}px ${strokewidth * -0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9808}px ${strokewidth * -0.1951}px ${blur}px ${shadowColor}
    `: `
        ${strokewidth * 1}px ${strokewidth * 0}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9952}px ${strokewidth * 0.098}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9808}px ${strokewidth * 0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9569}px ${strokewidth * 0.2903}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9239}px ${strokewidth * 0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8819}px ${strokewidth * 0.4714}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8315}px ${strokewidth * 0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * 0.773}px ${strokewidth * 0.6344}px ${blur}px ${shadowColor},
        ${strokewidth * 0.7071}px ${strokewidth * 0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * 0.6344}px ${strokewidth * 0.773}px ${blur}px ${shadowColor},
        ${strokewidth * 0.5556}px ${strokewidth * 0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * 0.4714}px ${strokewidth * 0.8819}px ${blur}px ${shadowColor},
        ${strokewidth * 0.3827}px ${strokewidth * 0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * 0.2903}px ${strokewidth * 0.9569}px ${blur}px ${shadowColor},
        ${strokewidth * 0.1951}px ${strokewidth * 0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * 0.098}px ${strokewidth * 0.9952}px ${blur}px ${shadowColor},
        ${strokewidth * 0}px ${strokewidth * 1}px ${blur}px ${shadowColor},
        ${strokewidth * -0.098}px ${strokewidth * 0.9952}px ${blur}px ${shadowColor},
        ${strokewidth * -0.1951}px ${strokewidth * 0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * -0.2903}px ${strokewidth * 0.9569}px ${blur}px ${shadowColor},
        ${strokewidth * -0.3827}px ${strokewidth * 0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * -0.4714}px ${strokewidth * 0.8819}px ${blur}px ${shadowColor},
        ${strokewidth * -0.5556}px ${strokewidth * 0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * -0.6344}px ${strokewidth * 0.773}px ${blur}px ${shadowColor},
        ${strokewidth * -0.7071}px ${strokewidth * 0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * -0.773}px ${strokewidth * 0.6344}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8315}px ${strokewidth * 0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8819}px ${strokewidth * 0.4714}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9239}px ${strokewidth * 0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9569}px ${strokewidth * 0.2903}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9808}px ${strokewidth * 0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9952}px ${strokewidth * 0.098}px ${blur}px ${shadowColor},
        ${strokewidth * -1}px ${strokewidth * 0}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9952}px ${strokewidth * -0.098}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9808}px ${strokewidth * -0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9569}px ${strokewidth * -0.2903}px ${blur}px ${shadowColor},
        ${strokewidth * -0.9239}px ${strokewidth * -0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8819}px ${strokewidth * -0.4714}px ${blur}px ${shadowColor},
        ${strokewidth * -0.8315}px ${strokewidth * -0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * -0.773}px ${strokewidth * -0.6344}px ${blur}px ${shadowColor},
        ${strokewidth * -0.7071}px ${strokewidth * -0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * -0.6344}px ${strokewidth * -0.773}px ${blur}px ${shadowColor},
        ${strokewidth * -0.5556}px ${strokewidth * -0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * -0.4714}px ${strokewidth * -0.8819}px ${blur}px ${shadowColor},
        ${strokewidth * -0.3827}px ${strokewidth * -0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * -0.2903}px ${strokewidth * -0.9569}px ${blur}px ${shadowColor},
        ${strokewidth * -0.1951}px ${strokewidth * -0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * -0.098}px ${strokewidth * -0.9952}px ${blur}px ${shadowColor},
        ${strokewidth * 0}px ${strokewidth * -1}px ${blur}px ${shadowColor},
        ${strokewidth * 0.098}px ${strokewidth * -0.9952}px ${blur}px ${shadowColor},
        ${strokewidth * 0.1951}px ${strokewidth * -0.9808}px ${blur}px ${shadowColor},
        ${strokewidth * 0.2903}px ${strokewidth * -0.9569}px ${blur}px ${shadowColor},
        ${strokewidth * 0.3827}px ${strokewidth * -0.9239}px ${blur}px ${shadowColor},
        ${strokewidth * 0.4714}px ${strokewidth * -0.8819}px ${blur}px ${shadowColor},
        ${strokewidth * 0.5556}px ${strokewidth * -0.8315}px ${blur}px ${shadowColor},
        ${strokewidth * 0.6344}px ${strokewidth * -0.773}px ${blur}px ${shadowColor},
        ${strokewidth * 0.7071}px ${strokewidth * -0.7071}px ${blur}px ${shadowColor},
        ${strokewidth * 0.773}px ${strokewidth * -0.6344}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8315}px ${strokewidth * -0.5556}px ${blur}px ${shadowColor},
        ${strokewidth * 0.8819}px ${strokewidth * -0.4714}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9239}px ${strokewidth * -0.3827}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9569}px ${strokewidth * -0.2903}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9808}px ${strokewidth * -0.1951}px ${blur}px ${shadowColor},
        ${strokewidth * 0.9952}px ${strokewidth * -0.098}px ${blur}px ${shadowColor}
    `;
}

// main function where the styles are returned
export function convertEffectValuesToStyles(effect, fontSize, fontColor) {
    const effectName = effect.Type;
    const styles = {};
    const rgb = effect.Color !== undefined && effectName !== "Glitch" && hexToRgb(effect.Color);
    const directions = effect.Direction !== undefined && getDirectionValues(effect.Offset, effect.Direction, fontSize);
    const intensity = effect.Intensity !== undefined && effect.Intensity;
    const thickness = effect.Thickness !== undefined && calculatedThickness(effect.Thickness, fontSize);
    const transparency = effect.Transparency !== undefined && effect.Transparency;

    if (effectName === "Shadow") {
        const shadowBlur = effect.Blur * 0.05;
        styles.filter = 'none';
        styles.webkitTextFillColor = "currentcolor";
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = `rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/ ${transparency}%) ${directions.directionOne}px ${directions.directionTwo}px ${shadowBlur}px`;
    } else if (effectName === "Lift") {
        const maxSize = 0.5 * parseInt((fontSize + "").replace("px", ""), 10);
        const variableSize = (maxSize / 200);
        const depth = effect.Depth;
        styles.filter = 'none';
        styles.webkitTextFillColor = "currentcolor";
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = `rgb(0 0 0 / ${intensity * 0.55 + 5}%) 0px ${maxSize * depth / 100}px ${maxSize - (variableSize * intensity)}px`
    } else if (effectName === "Hollow") {
        styles.webkitTextStroke = `${thickness}px currentcolor`;
        styles.webkitTextFillColor = "transparent";
        styles.filter = 'none';
        styles.textShadow = "none";
    } else if (effectName === "Glitch") {
        const glitchColor = effect.Color === 1
            ? `rgb(0 255 255) ${directions.directionOne}px ${directions.directionTwo}px 0px, rgb(255 0 0) ${directions.directionOne * -1}px ${directions.directionTwo * -1}px 0px`
            : `rgb(0 255 255) ${directions.directionOne}px ${directions.directionTwo}px 0px, rgb(255 0 255) ${directions.directionOne * -1}px ${directions.directionTwo * -1}px 0px`;
        styles.filter = 'none';
        styles.webkitTextFillColor = "currentcolor";
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = glitchColor;
    } else if (effectName === "Echo") {
        styles.filter = 'none';
        styles.webkitTextFillColor = "currentcolor";
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = `rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/50%) ${directions.directionOne}px ${directions.directionTwo}px,
                             rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}/ 30%) ${directions.directionOne * 2}px ${directions.directionTwo * 2}px`;
    } else if (effectName === "Neon") {
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = "none";
        styles.webkitTextFillColor = calculateNeonColor(intensity, fontColor);
        styles.filter = calculateNeonShadow(intensity, fontColor);
    } else if (effectName === "Splice") {
        styles.webkitTextStroke = `${thickness}px currentcolor`;
        styles.webkitTextFillColor = "transparent";
        styles.filter = 'none';
        styles.textShadow = `rgb(${rgb[0]} ${rgb[1]} ${rgb[2]}) ${directions.directionOne}px ${directions.directionTwo}px 0px`;
    } else if (effectName === "Outline") {
        const shadowBlur = effect.Blur;
        const outlineThickness = effect.Thickness
        styles.filter = 'none';
        styles.webkitTextFillColor = "currentcolor";
        styles.webkitTextStroke = "0px currentcolor";
        styles.textShadow = calculateOutlineTextShadow(effect.Color, outlineThickness, shadowBlur, transparency, fontSize);
    }
    return styles;
}