import React from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../../redux/actions/notificationUtils";
import uuid from "../uuid";

const withNotify = (WrappedComponent) => {
  const NotificationWrapper = (props) => {
    const dispatch = useDispatch();

    const notify = (toastType, description, subText) => {
      dispatch(
        addNotification({
          id: uuid(),
          toastType,
          description,
          subText,
        })
      );
    };

    const enhancedProps = {
      ...props,
      notify: {
        warn: (description, subText) => notify("WARNING", description, subText),
        success: (description, subText) =>
          notify("SUCCESS", description, subText),
      },
    };

    return <WrappedComponent {...enhancedProps} />;
  };

  return NotificationWrapper;
};

export default withNotify;
