/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable react/sort-comp */

import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TEXT_EFFECTS, TEXT_EFFECT_LIMITS } from '../../../text/text-constants';
import Slider from '../../../zoom/zoom-slider';
import ColorPaletteWindow from '../color/propertywindow-colorpalette';
import { setWorkspaceTextFocus, updateTextStatus } from '../../../../redux/actions/appUtils';
import content from '../../../../constants/content';
import { undoTextEffects, updateTextEffects } from '../../../../redux/actions/timelineUtils';
import NumberInput from '../../../../common-components/NumberInputComponent';
import { AttributeTitle, AttributesContainer, ColorAttribute, ColorSelector, EffectBox, EffectName, EffectText, EffectsWrapper, GlitchBtn, GlitchButtons, Header, SliderAttribute, TextEffectsContainer } from './textproperty-components';
import vmTheme from "../../../../constants/theme";

class TextEffectsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            effectsList: [],
            selectedEffect: '',
            effectAttributes: {},
            activeEffect: '',
            glitchEffect: '',
            showColorPalette: false
        };
        this.currentText = this.props.selectedItem;
        this.textData = this.currentText.get("textData");
        this.toggleColorPalette = this.toggleColorPalette.bind(this);
        this.colorSelectionHandler = this.colorSelectionHandler.bind(this);
    }

    componentDidMount() {
        if (TEXT_EFFECTS) {
            const getTextEffects = typeof (this.textData.getIn(["effects"])) === "undefined"
                ? "None"
                : this.textData.getIn(["effects"]).toJS().Type;
            const unorderedAttributes = getTextEffects !== "None" ? this.textData.getIn(["effects"]).toJS() : null;
            const presetAttrs = { ...TEXT_EFFECTS[getTextEffects] }
            presetAttrs.Type = getTextEffects;
            const orderedAttributes = Object.assign(presetAttrs, unorderedAttributes);

            this.setState({
                effectsList: Object.keys(TEXT_EFFECTS),
                selectedEffect: this.textData.getIn(["effects"]),
                activeEffect: getTextEffects,
                glitchEffect: getTextEffects === "Glitch" ? this.textData.getIn(["effects", "Color"]) : 2,
                effectAttributes: orderedAttributes
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.currentText = this.props.selectedItem;
            this.textData = this.currentText.get("textData");
            const getTextEffects = typeof (this.textData.getIn(["effects"])) === "undefined"
                ? "None"
                : this.textData.getIn(["effects"]).toJS().Type;
            const unorderedAttributes = getTextEffects !== "None" ? this.textData.getIn(["effects"]).toJS() : null;
            const presetAttrs = { ...TEXT_EFFECTS[getTextEffects] }
            presetAttrs.Type = getTextEffects;
            const orderedAttributes = Object.assign(presetAttrs, unorderedAttributes);

            this.setState({
                effectsList: Object.keys(TEXT_EFFECTS),
                selectedEffect: this.textData.getIn(["effects"]),
                activeEffect: getTextEffects,
                glitchEffect: getTextEffects === "Glitch" ? this.textData.getIn(["effects", "Color"]) : 2,
                effectAttributes: orderedAttributes
            });
        }
    }

    toggleColorPalette() {
        this.setState(p => ({
            showColorPalette: !p.showColorPalette
        }));
    }

    selectEffectHandler(effect, index) {
        let attributes = [];
        attributes = { ...TEXT_EFFECTS[effect] };
        const currentEffect = index !== 0 ? fromJS((TEXT_EFFECTS[effect]))
            : '';
        this.setState({
            effectAttributes: attributes,
            selectedEffect: currentEffect,
            activeEffect: effect,
            glitchEffect: 2,
            showColorPalette: false
        })
        const effectObj = { ...TEXT_EFFECTS[effect] };
        effectObj.Type = effect;
        this.textData = index === 0 ? this.textData.setIn(["effects"], fromJS({ "None": "" }))
            : this.textData.setIn(["effects"], fromJS(effectObj));
        this.props.updateTextEffects({
            id: this.props.textStatus.get("id"),
            container: this.props.textStatus.get("container"),
            textData: this.textData.toJS(),
        })
    }

    handleAttributeChange(property, value, shouldSave) {
        const { selectedEffect } = this.state;
        const updatedEffectValues = selectedEffect.setIn([property], value);
        this.textData = this.textData.setIn(["effects", property], value);
        this.setState({
            selectedEffect: updatedEffectValues
        });

        if (shouldSave) {
            this.props.updateTextEffects({
                id: this.props.textStatus.get("id"),
                container: this.props.textStatus.get("container"),
                textData: this.textData.toJS(),
            });
        } else {
            this.props.undoTextEffects({
                id: this.props.textStatus.get("id"),
                container: this.props.textStatus.get("container"),
                textData: this.textData.toJS(),
                canEmit: false,
            });
        }
    }

    colorSelectionHandler(color) {
        this.textData = this.textData.setIn(["effects", "Color"], color);
        this.props.updateTextEffects({
            id: this.props.textStatus.get("id"),
            container: this.props.textStatus.get("container"),
            textData: this.textData.toJS(),
        });
    }

    glitchEffectHandler(variantNum, attribute) {
        this.setState({
            glitchEffect: variantNum
        });
        this.textData = this.textData.setIn(["effects", attribute], variantNum);
        this.props.updateTextEffects({
            id: this.props.textStatus.get("id"),
            container: this.props.textStatus.get("container"),
            textData: this.textData.toJS(),
        });
    }

    handleInputFocus = () => {
        if (this.props.textStatus.get('isFocused'))
            this.props.updateTextStatus({ id: this.props.textStatus.get('id'), isFocused: false, isSelected: true });

        this.props.setWorkspaceTextFocus(true);
    }

    handleInputBlur = () => {
        this.props.setWorkspaceTextFocus(false);
    }

    render() {
        const { theme } = this.props
        const effectBoxes = [];
        let attributesContainer = null;
        let insertAtIndex = null;
        const gridColumns = 2;

        for (let index = 0; index < this.state.effectsList.length; index += 1) {
            const effect = this.state.effectsList[index];
            const isActive = effect === this.state.activeEffect;

            if (isActive && index > 0) {
                insertAtIndex = Math.ceil((index + 1) / gridColumns) * gridColumns;

                const attributes = [];
                for (const attribute of Reflect.ownKeys(this.state.effectAttributes)) {
                    if (attribute === "Type") {
                        continue;
                    }

                    if (index !== 4 && attribute === "Color") {
                        let currentColor = this.textData.getIn(["effects", attribute]);
                        if (!currentColor) {
                            currentColor = TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute];
                        }

                        attributes.push(
                            <ColorAttribute>
                                <AttributeTitle>{attribute}</AttributeTitle>
                                <ColorSelector id='colorSelector'
                                    style={{
                                        background: currentColor,
                                    }}
                                    onClick={this.toggleColorPalette}
                                    active={this.state.showColorPalette}
                                />
                                {this.state.showColorPalette && (
                                    <ColorPaletteWindow
                                        colorSelected={this.colorSelectionHandler}
                                        closePopup={this.toggleColorPalette}
                                        currentColor={currentColor}
                                        type="TEXT"
                                        itemId={this.props.textStatus.get("id")}
                                        stopSlidePropogation={true}
                                        from="TextEffectsSection"
                                        componentFrom="TXT_EFF_SEC"
                                    />
                                )}
                            </ColorAttribute>
                        );
                    } else if (index === 4 && attribute === "Color") {
                        attributes.push(
                            <ColorAttribute>
                                <AttributeTitle>{attribute}</AttributeTitle>
                                    <GlitchButtons>
                                        <GlitchBtn
                                            active={this.state.glitchEffect === 1}
                                            onClick={() => this.glitchEffectHandler(1, attribute)}
                                        />
                                        <GlitchBtn
                                            active={this.state.glitchEffect === 2}
                                            variant={2}
                                            onClick={() => this.glitchEffectHandler(2, attribute)}
                                        />
                                    </GlitchButtons>
                            </ColorAttribute>
                        );
                    } else {
                        attributes.push(
                            <SliderAttribute>
                                <AttributeTitle>{attribute}</AttributeTitle>
                                <NumberInput
                                    min={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][1]}
                                    max={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][2]}
                                    step={1}
                                    value={this.state.selectedEffect.getIn([attribute])}
                                    precision={1}
                                    onChange={
                                        (e, value) => this.handleAttributeChange(
                                            attribute,
                                            value,
                                            true
                                        )
                                    }
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                    suffix=""
                                    saveOnUnmount={false}
                                />
                                <Slider
                                    min={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][1]}
                                    max={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][2]}
                                    inputMin={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][1]}
                                    inputMax={TEXT_EFFECT_LIMITS[this.state.activeEffect][attribute][2]}
                                    step={1}
                                    value={this.state.selectedEffect.getIn([attribute])}
                                    onChangeSliderValue={
                                        (e, value) => this.handleAttributeChange(
                                            attribute,
                                            value,
                                            false
                                        )
                                    }
                                    onMouseUp={
                                        (e, value) => this.handleAttributeChange(
                                            attribute,
                                            value,
                                            true
                                        )
                                    }
                                    border={"none"}
                                    borderRadius={"2px"}
                                    background={vmTheme[theme].veryLightGray}
                                    margin={"0"}
                                    height={"4px"}
                                    thumb={{
                                        background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                                        border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                                        height: "14px",
                                        width: "14px",
                                        hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                                        hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                                    }}
                                    progressBackground={vmTheme[theme].secondaryBorderColor}
                                    isChangeBg={true}
                                    showValue={false}
                                    doubleSide={false}
                                />
                            </SliderAttribute>
                        );
                    }
                }

                attributesContainer = (
                    <AttributesContainer>
                        {attributes}
                    </AttributesContainer>
                );
            }

            if (insertAtIndex === index) {
                effectBoxes.push(attributesContainer);
                attributesContainer = null;
            }

            effectBoxes.push(
                <React.Fragment key={effect}>
                    <EffectBox
                        isActive={effect === this.state.activeEffect}
                        onClick={effect !== this.state.activeEffect ? () => this.selectEffectHandler(effect, index) : () => { }}
                    >
                        <EffectText effectNumber={index}>Ag</EffectText>
                        <EffectName>{effect}</EffectName>
                    </EffectBox>
                </React.Fragment>
            );
        }

        if (insertAtIndex !== null && attributesContainer !== null) {
            effectBoxes.push(attributesContainer);
            attributesContainer = null;
        }

        return (
            <TextEffectsContainer>
                <Header><FormattedMessage id={content.EFFECTS} /></Header>
                <EffectsWrapper>
                    {effectBoxes}
                </EffectsWrapper>
            </TextEffectsContainer>
        );
    }
}

TextEffectsComponent.propTypes = {
    textStatus: PropTypes.object,
    // selectionContainer: PropTypes.string,
    selectedItem: PropTypes.object,
    updateTextEffects: PropTypes.func,
    undoTextEffects: PropTypes.func,
    updateTextStatus: PropTypes.func,
    setWorkspaceTextFocus: PropTypes.func,
    theme: PropTypes.string,
};

const mapStateToProps = (state) => {
    const textStatus = state.app.get("textStatus");
    const selectionContainer = textStatus.get("container");
    const selectedItem = state.projectDetails.getIn([selectionContainer, textStatus.get("id")]);
    const theme = state.app.get('theme');

    return {
        textStatus: state.app.get("textStatus"),
        selectionContainer,
        selectedItem,
        theme
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateTextEffects: (data) => dispatch(updateTextEffects(data)),
    undoTextEffects: (data) => dispatch(undoTextEffects(data)),
    updateTextStatus: (data) => dispatch(updateTextStatus(data)),
    setWorkspaceTextFocus: (data) => dispatch(setWorkspaceTextFocus(data)),
});

const TextEffects = connect(mapStateToProps, mapDispatchToProps)(TextEffectsComponent);

export default TextEffects;
