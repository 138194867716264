/* eslint-disable jsx-a11y/no-static-element-interactions, prefer-template, react/sort-comp, camelcase, prefer-destructuring */

/** @todo move all touchstart touchend handlers to pointerdown handlers. Reason: touchstart will lead to hard to reproduce bugs */

import { fromJS } from "immutable";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReturnSvgFilter } from "../workspace/workspace-filter";
import {
  getSelectedItemIds,
  getSelectedObjects,
  getSelectionContainer,
} from "../../helper/IndividualSelectHelper";
import { getMouseClientPosition } from "../../helper/TransformManagerHelper";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import { ITEM_CONFIG } from "../../constants/config";
import {
  VIGNETTE,
  SVG,
} from "../frame/frame-components";
import { cancelFraming } from "../../redux/actions/appUtils";
import { applyFrameClipPlot } from "../../redux/actions/timelineUtils";
import { FrameResizeHandleContainer, UnCropB } from "./tm-components";
import { ItemToolConfirmation } from "../itemtoolbar/itemtoolbar-confirmation";
import { isVideoOnly } from "../timeline/timeline-helper";

const FilterItemComponent = (props, ref) => {
  const { filterId, filterDOMId, vignetteId, filterSvgId, style, browserName } =
    props;

  const vignetteValue = parseInt(filterId.substr(12, 2), 16);

  return (
    <div style={style} ref={ref}>
      <VIGNETTE
        id={vignetteId}
        className="vignette"
        style={{
          "--vignette": vignetteValue * 0.007,
        }}
      />
      <svg
        id={filterSvgId}
        style={
          browserName === "Firefox"
            ? { width: "0", height: "0", display: "block" }
            : { display: "none" }
        }
      >
        <defs>
          <filter colorInterpolationFilters="sRGB" id={filterDOMId}>
            <ReturnSvgFilter filterCode={filterId} vignetteId={vignetteId} />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
FilterItemComponent.propTypes = {
  filterId: PropTypes.string,
  filterDOMId: PropTypes.string,
  vignetteId: PropTypes.string,
  filterSvgId: PropTypes.string,
  style: PropTypes.object,
  browserName: PropTypes.string,
};
FilterItemComponent.displayName = "FilterItemComponent";
const FilterItem = React.forwardRef(FilterItemComponent);

function getFlipPosition(props) {
  const flipValue = props.frameItem.get("flipPosition");
  return flipValue === 1
    ? " scaleX(-1)"
    : flipValue === 2
    ? " scaleY(-1)"
    : flipValue === 3
    ? " scaleX(-1) scaleY(-1)"
    : "";
}

function getClipImageFlipPosition({ frameItem, selectedClip }) {
  let flipValue;

  if (frameItem.get("type") === "FRAME") {
    flipValue = frameItem.getIn([
      "clipDetails",
      selectedClip,
      "imgDetails",
      "flipPosition",
    ]);
  } else {
    flipValue = frameItem.getIn([
      "gridData",
      "gridCells",
      selectedClip,
      "imgDetails",
      "flipPosition",
    ]);
  }
  return flipValue === 1
    ? " scaleX(-1)"
    : flipValue === 2
    ? " scaleY(-1)"
    : flipValue === 3
    ? " scaleX(-1) scaleY(-1)"
    : "";
}

class FrameImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.getInitialState = this.getInitialState.bind(this);
    this.initiateMove = this.initiateMove.bind(this);
    this.moveSelection = this.moveSelection.bind(this);
    this.stopMove = this.stopMove.bind(this);
    this.initiateResize = this.initiateResize.bind(this);
    this.resizeSelection = this.resizeSelection.bind(this);
    this.stopResize = this.stopResize.bind(this);
    this.getUnCroppedXY = this.getUnCroppedXY.bind(this);
    this.getMousePosition = this.getMousePosition.bind(this);
    this.getUnCroppedResizePosition =
      this.getUnCroppedResizePosition.bind(this);
    this.getWorkspaceBounds = this.getWorkspaceBounds.bind(this);

    this.mouseStartPosition = {
      x: null,
      y: null,
      offsetX: null,
      offsetY: null,
    };
  }

  getInitialState() {
    let unclipped = {
      x:
        this.props.frameItem.get("width") *
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "imgDetails",
          "original",
          "x",
        ]),
      y:
        this.props.frameItem.get("height") *
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "imgDetails",
          "original",
          "y",
        ]),
      width:
        this.props.frameItem.get("width") *
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "imgDetails",
          "original",
          "width",
        ]),
      height:
        this.props.frameItem.get("height") *
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "imgDetails",
          "original",
          "height",
        ]),
    };
    let clipped = {
      x: this.props.frameItem.get("x"),
      y: this.props.frameItem.get("y"),
      width: this.props.frameItem.get("width"),
      height: this.props.frameItem.get("height"),
    };
    let clipBox = {
      x:
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "clipX",
        ]) *
        (this.props.frameItem.get("width") /
          this.props.frameItem.get("defaultWidth")),
      y:
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "clipY",
        ]) *
        (this.props.frameItem.get("height") /
          this.props.frameItem.get("defaultHeight")),
      width:
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "clipWidth",
        ]) *
        (this.props.frameItem.get("width") /
          this.props.frameItem.get("defaultWidth")),
      height:
        this.props.frameItem.getIn([
          "clipDetails",
          this.props.selectedClip,
          "clipHeight",
        ]) *
        (this.props.frameItem.get("height") /
          this.props.frameItem.get("defaultHeight")),
    };
    const zoomFactor = this.props.zoomFactor;
    const flipPosition = this.props.frameItem.get("flipPosition");
    const flip = getFlipPosition(this.props);
    const clipImageFlip = getClipImageFlipPosition(this.props);

    if (this.props.frameItem.get("type") === "GRID") {
      const gridCellData = this.props.frameItem.getIn([
        "gridData",
        "gridCells",
        this.props.selectedClip,
      ]);
      const gridElement = document.getElementById(
        this.props.selectedItems.get(0)
      );
      const { offsetTop, offsetLeft } = gridElement.getElementsByClassName(
        `cell-${this.props.selectedClip}`
      )[0];
      const scale = { x: 1, y: 1 };
      let oX = offsetLeft / this.props.zoomFactor;
      let oY = offsetTop / this.props.zoomFactor;
      if (flipPosition === 1 || flipPosition === 3) {
        scale.x = -1;
        const x1 =
          (offsetLeft / this.props.zoomFactor) * scale.x +
          this.props.frameItem.get("width");
        const x2 =
          (offsetLeft / this.props.zoomFactor + gridCellData.get("cellWidth")) *
            scale.x +
          this.props.frameItem.get("width");
        oX = Math.min(x1, x2);
      }
      if (flipPosition === 2 || flipPosition === 3) {
        scale.y = -1;
        const y1 =
          (offsetTop / this.props.zoomFactor) * scale.y +
          this.props.frameItem.get("height");
        const y2 =
          (offsetTop / this.props.zoomFactor + gridCellData.get("cellHeight")) *
            scale.y +
          this.props.frameItem.get("height");
        oY = Math.min(y1, y2);
      }

      const itemCX = this.props.frameItem.get("width") / 2;
      const itemCY = this.props.frameItem.get("height") / 2;
      const cellCX = oX + gridCellData.get("cellWidth") / 2;
      const cellCY = oY + gridCellData.get("cellHeight") / 2;
      const cellCenter = this.getRotatedPoint(
        itemCX,
        itemCY,
        cellCX,
        cellCY,
        this.props.frameItem.get("angle")
      );
      const cellCorner = this.getRotatedPoint(
        itemCX,
        itemCY,
        oX,
        oY,
        this.props.frameItem.get("angle")
      );
      const unrotatedCorner = this.getRotatedPoint(
        cellCenter.x,
        cellCenter.y,
        cellCorner.x,
        cellCorner.y,
        -this.props.frameItem.get("angle")
      );

      unclipped = {
        x:
          gridCellData.get("cellWidth") *
          gridCellData.getIn(["imgDetails", "original", "x"]),
        y:
          gridCellData.get("cellHeight") *
          gridCellData.getIn(["imgDetails", "original", "y"]),
        width:
          gridCellData.get("cellWidth") *
          gridCellData.getIn(["imgDetails", "original", "width"]),
        height:
          gridCellData.get("cellHeight") *
          gridCellData.getIn(["imgDetails", "original", "height"]),
      };
      clipped = {
        x: this.props.frameItem.get("x") + unrotatedCorner.x,
        y: this.props.frameItem.get("y") + unrotatedCorner.y,
        width: gridCellData.get("cellWidth"),
        height: gridCellData.get("cellHeight"),
        originX: cellCX,
        originY: cellCY,
      };
      clipBox = {
        x: gridCellData.get("x"),
        y: gridCellData.get("y"),
        width: gridCellData.get("cellWidth"),
        height: gridCellData.get("cellHeight"),
      };
    }

    return {
      unclipped,
      clipped,
      clipBox,
      zoomFactor,
      flipPosition,
      flip,
      clipImageFlip,
      originalToUpdate: null,
    };
  }

  getRotatedPoint = (px, py, x, y, angle) => {
    const angle_in_rad = (angle * Math.PI) / 180;
    return {
      x:
        (x - px) * Math.cos(angle_in_rad) -
        (y - py) * Math.sin(angle_in_rad) +
        px,
      y:
        (x - px) * Math.sin(angle_in_rad) +
        (y - py) * Math.cos(angle_in_rad) +
        py,
    };
  };

  getWorkspaceBounds() {
    return this.props.workspaceRef.current.getBoundingClientRect();
  }

  getMousePosition(e) {
    const workspaceBounds = this.getWorkspaceBounds();

    const unrotatedPostion = getMouseClientPosition(e, fromJS({
      x: -workspaceBounds.x,
      y: -workspaceBounds.y,
    }));

    /* handle mouse position for rotated item
     * e.g. when image is rotated to 90deg and cropped horizontal movement should actually be vertical movement
     */
    const angle = this.props.frameItem.get("angle");
    const px = workspaceBounds.width / 2;
    const py = workspaceBounds.height / 2;
    return this.getRotatedPoint(
      px,
      py,
      unrotatedPostion.get("x"),
      unrotatedPostion.get("y"),
      -angle
    );
  }

  initiateMove(e) {
    e.preventDefault();
    e.stopPropagation();
    this.lastDown = e.timeStamp;
    this.moved = false;
    const mouse_position = this.getMousePosition(e);
    this.mouseStartPosition = Object.assign(this.mouseStartPosition, {
      x: mouse_position.x,
      y: mouse_position.y,
    });
    window.addEventListener("mousemove", this.moveSelection, false);
    window.addEventListener("mouseup", this.stopMove, false);
    window.addEventListener("touchmove", this.moveSelection, false);
    window.addEventListener("touchend", this.stopMove, false);
  }

  getUnCroppedXY(e) {
    const unClippedX = this.state.unclipped.x * this.props.zoomFactor;
    const unClippedY = this.state.unclipped.y * this.props.zoomFactor;
    const unClippedWidth = this.state.unclipped.width * this.props.zoomFactor;
    const unClippedHeight = this.state.unclipped.height * this.props.zoomFactor;
    let xDir = 1;
    let yDir = 1;
    if (this.state.flipPosition === 1) xDir = -1;
    else if (this.state.flipPosition === 2) yDir = -1;
    else if (this.state.flipPosition === 3) {
      xDir = -1;
      yDir = -1;
    }

    const mouse_position = this.getMousePosition(e);
    const mouseX = (this.mouseStartPosition.x - mouse_position.x) * xDir;
    const mouseY = (this.mouseStartPosition.y - mouse_position.y) * yDir;
    let x = unClippedX - mouseX;
    let y = unClippedY - mouseY;

    const clipX = this.state.clipBox.x * this.props.zoomFactor;
    const clipY = this.state.clipBox.y * this.props.zoomFactor;
    const clipWidth = this.state.clipBox.width * this.props.zoomFactor;
    const clipHeight = this.state.clipBox.height * this.props.zoomFactor;

    if (this.props.frameItem && this.props.frameItem.get("type") === "GRID") {
      if (x > 0) x = 0;
      if (y > 0) y = 0;
      if (Math.abs(x) + clipWidth >= unClippedWidth)
        x = -(unClippedWidth - clipWidth);
      if (Math.abs(y) + clipHeight >= unClippedHeight)
        y = -(unClippedHeight - clipHeight);
    } else {
      if (x > clipX) x = clipX;
      if (y > clipY) y = clipY;

      if (clipX + clipWidth - x > unClippedWidth)
        x = -(unClippedWidth - (clipX + clipWidth));
      if (clipY + clipHeight - y > unClippedHeight)
        y = -(unClippedHeight - (clipY + clipHeight));
    }
    return { x, y };
  }

  moveSelection(e) {
    if (this.lastDown + 10 < e.timeStamp) {
      if (!this.moved) this.moved = true;
      e.preventDefault();
      const XY = this.getUnCroppedXY(e);

      this.clippled.style.transform =
        "translate(" + XY.x + "px, " + XY.y + "px)";
      this.unclippled.style.transform =
        "translate(" + XY.x + "px, " + XY.y + "px)";
      this.filterItem.style.transform =
        "translate(" + XY.x + "px, " + XY.y + "px)";
      this.selection.style.transform =
        "translate(" + XY.x + "px, " + XY.y + "px)";
    }
  }

  stopMove(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.moved) {
      const XY = this.getUnCroppedXY(e);

      const frameData = {
        x: XY.x / this.props.zoomFactor,
        y: XY.y / this.props.zoomFactor,
        width: this.state.unclipped.width,
        height: this.state.unclipped.height,
      };
      this.setState((prevState) => {
        const unclipped = { ...prevState.unclipped };
        unclipped.x = XY.x / this.props.zoomFactor;
        unclipped.y = XY.y / this.props.zoomFactor;
        return {
          ...prevState,
          unclipped,
        };
      });
      this.setFrameData(frameData);
    }

    window.removeEventListener("mousemove", this.moveSelection, false);
    window.removeEventListener("mouseup", this.stopMove, false);
    window.removeEventListener("touchmove", this.moveSelection, false);
    window.removeEventListener("touchend", this.stopMove, false);
  }

  getUnCroppedResizePosition(e) {
    let unClippedX = this.state.unclipped.x * this.props.zoomFactor;
    let unClippedY = this.state.unclipped.y * this.props.zoomFactor;
    const unClippedWidth = this.state.unclipped.width * this.props.zoomFactor;
    const unClippedHeight = this.state.unclipped.height * this.props.zoomFactor;
    let xDir = 1;
    let yDir = 1;
    if (this.state.flipPosition === 1) xDir = -1;
    else if (this.state.flipPosition === 2) yDir = -1;
    else if (this.state.flipPosition === 3) {
      xDir = -1;
      yDir = -1;
    }
    const mouse_position = this.getMousePosition(e);
    const mouseX =
      unClippedX - (this.mouseStartPosition.x - mouse_position.x) * xDir;
    const mouseY =
      unClippedX - (this.mouseStartPosition.y - mouse_position.y) * yDir;

    let unCropX;
    let unCropY;
    let unCropWidth;
    let unCropHeight;

    if (this.state.handle === "top-left") {
      unCropWidth = unClippedWidth - (mouseX - unClippedX);
      unCropHeight = unClippedHeight - (mouseY - unClippedY);
      unCropX = mouseX;
      unCropY =
        mouseY -
        ((unCropWidth / unClippedWidth) * unClippedHeight - unCropHeight);
    } else if (this.state.handle === "top-right") {
      unCropWidth = mouseX - unClippedX + unClippedWidth;
      unCropHeight = unClippedHeight - (mouseY - unClippedY);
      unCropX = unClippedX;
      unCropY =
        mouseY -
        ((unCropWidth / unClippedWidth) * unClippedHeight - unCropHeight);
    } else if (this.state.handle === "bottom-right") {
      unCropWidth = mouseX - unClippedX + unClippedWidth;
      unCropHeight = mouseY - unClippedY + unClippedHeight;
      unCropX = unClippedX;
      unCropY = unClippedY;
    } else {
      unCropWidth = unClippedWidth - (mouseX - unClippedX);
      unCropHeight = mouseY - unClippedY;
      unCropX = mouseX;
      unCropY = unClippedY;
    }

    unCropHeight = (unCropWidth / unClippedWidth) * unClippedHeight;

    const clipX = this.state.clipBox.x * this.props.zoomFactor;
    const clipWidth = this.state.clipBox.width * this.props.zoomFactor;
    const clipHeight = this.state.clipBox.height * this.props.zoomFactor;
    const clipY = this.state.clipBox.y * this.props.zoomFactor;

    let dW;
    let dH;

    if (this.props.frameItem && this.props.frameItem.get("type") === "GRID") {
      unCropX += clipX;
      unCropY += clipY;
      unClippedX += clipX;
      unClippedY += clipY;
    }

    if (this.state.handle === "top-left") {
      if (unCropX > clipX) {
        dW = unCropX - clipX;
        unCropWidth += dW;
        unCropHeight += (dW / unClippedWidth) * unClippedHeight;
        unCropX = clipX;
        unCropY = unClippedY + (unClippedHeight - unCropHeight);
      }

      if (unCropY > clipY) {
        dH = unCropY - clipY;
        unCropHeight += dH;
        unCropWidth += (dH / unClippedHeight) * unClippedWidth;
        unCropY = clipY;
        unCropX = unClippedX + (unClippedWidth - unCropWidth);
      }
    } else if (this.state.handle === "top-right") {
      if (clipX + clipWidth - unCropX > unCropWidth) {
        dW = unCropWidth - (clipX + clipWidth - unCropX);
        unCropWidth -= dW;
        unCropHeight -= (dW / unClippedWidth) * unClippedHeight;
        unCropY = unClippedY + (unClippedHeight - unCropHeight);
      }
      if (unCropY > clipY) {
        dH = unCropY - clipY;
        unCropHeight += dH;
        unCropWidth += (dH / unClippedHeight) * unClippedWidth;
        unCropY = clipY;
      }
    } else if (this.state.handle === "bottom-right") {
      if (clipX + clipWidth - unCropX > unCropWidth) {
        dW = unCropWidth - (clipX + clipWidth - unCropX);
        unCropWidth -= dW;
        unCropHeight -= (dW / unClippedWidth) * unClippedHeight;
      }
      if (clipY + clipHeight - unCropY > unCropHeight) {
        dH = unCropHeight - (clipY + clipHeight - unCropY);
        unCropHeight -= dH;
        unCropWidth -= (dH / unClippedHeight) * unClippedWidth;
      }
    } else {
      if (unCropX > clipX) {
        dW = unCropX - clipX;
        unCropWidth += dW;
        unCropHeight += (dW / unClippedWidth) * unClippedHeight;
        unCropX = clipX;
      }
      if (clipY + clipHeight - unCropY > unCropHeight) {
        dH = unCropHeight - (clipY + clipHeight - unCropY);
        unCropHeight -= dH;
        unCropWidth -= (dH / unClippedHeight) * unClippedWidth;
        unCropX = unClippedX + (unClippedWidth - unCropWidth);
      }
    }
    if (this.props.frameItem && this.props.frameItem.get("type") === "GRID") {
      unCropX -= clipX;
      unCropY -= clipY;
      unClippedX -= clipX;
      unClippedY -= clipY;
    }

    return { x: unCropX, y: unCropY, width: unCropWidth, height: unCropHeight };
  }

  initiateResize(e) {
    e.preventDefault();
    e.stopPropagation();
    const handle = e.target.getAttribute("data-handle");
    const mouse_position = this.getMousePosition(e);
    this.mouseStartPosition = Object.assign(this.mouseStartPosition, {
      x: mouse_position.x,
      y: mouse_position.y,
    });
    this.setState({ handle });
    window.addEventListener("mousemove", this.resizeSelection, false);
    window.addEventListener("mouseup", this.stopResize, false);
    window.addEventListener("touchmove", this.resizeSelection, false);
    window.addEventListener("touchend", this.stopResize, false);
  }

  resizeSelection(e) {
    e.preventDefault();
    e.stopPropagation();

    const unCrop = this.getUnCroppedResizePosition(e);
    this.clippled.style.transform =
      "translate(" + unCrop.x + "px, " + unCrop.y + "px)";
    this.clippled.style.width = unCrop.width + "px";
    this.clippled.style.height = unCrop.height + "px";
    this.unclippled.style.transform =
      "translate(" + unCrop.x + "px, " + unCrop.y + "px)";
    this.unclippled.style.width = unCrop.width + "px";
    this.unclippled.style.height = unCrop.height + "px";
    this.filterItem.style.transform =
      "translate(" + unCrop.x + "px, " + unCrop.y + "px)";
    this.filterItem.style.width = unCrop.width + "px";
    this.filterItem.style.height = unCrop.height + "px";
    this.selection.style.transform =
      "translate(" + unCrop.x + "px, " + unCrop.y + "px)";
    this.selection.style.width = unCrop.width + "px";
    this.selection.style.height = unCrop.height + "px";
  }

  stopResize(e) {
    e.preventDefault();
    e.stopPropagation();
    const unCrop = this.getUnCroppedResizePosition(e);

    const frameData = {
      x: unCrop.x / this.props.zoomFactor,
      y: unCrop.y / this.props.zoomFactor,
      width: unCrop.width / this.props.zoomFactor,
      height: unCrop.height / this.props.zoomFactor,
    };
    this.setState((prevState) => {
      const unclipped = { ...prevState.unclipped };
      unclipped.x = unCrop.x / this.props.zoomFactor;
      unclipped.y = unCrop.y / this.props.zoomFactor;
      unclipped.width = unCrop.width / this.props.zoomFactor;
      unclipped.height = unCrop.height / this.props.zoomFactor;
      return {
        ...prevState,
        unclipped,
      };
    });
    this.setFrameData(frameData);
    window.removeEventListener("mousemove", this.resizeSelection, false);
    window.removeEventListener("mouseup", this.stopResize, false);
    window.removeEventListener("touchmove", this.resizeSelection, false);
    window.removeEventListener("touchend", this.stopResize, false);
  }

  setFrameData = (frameData) => {
    const { x, y, width, height } = frameData;
    let original = {
      x: x / this.props.frameItem.get("width"),
      y: y / this.props.frameItem.get("height"),
      width: width / this.props.frameItem.get("width"),
      height: height / this.props.frameItem.get("height"),
    };
    if (this.props.frameItem.get("type") === "GRID") {
      const gridCellData = this.props.frameItem.getIn([
        "gridData",
        "gridCells",
        this.props.selectedClip,
      ]);
      original = {
        x: x / gridCellData.get("cellWidth"),
        y: y / gridCellData.get("cellHeight"),
        width: width / gridCellData.get("cellWidth"),
        height: height / gridCellData.get("cellHeight"),
      };
    }
    this.setState({
      originalToUpdate: original,
    });
  };

  cancelFraming = () => {
    this.props.cancelFraming();
  };

  applyFrame = () => {
    if (this.props.frameItem.get("type") === "FRAME") {
      if (this.state.originalToUpdate) {
        this.props.applyFrameClipPlot(
          {
            container: this.props.selectionContainer,
            id: this.props.selectedFrameClip.get("id"),
            clipId: this.props.selectedFrameClip.get("clipId"),
            original: { ...this.state.originalToUpdate },
          },
          this.props.socket
        );
      } else {
        this.cancelFraming();
      }
    } else if (this.props.frameItem.get("type") === "GRID") {
      if (this.state.originalToUpdate) {
        /*
        this.props.applyGridCellPlot(
          {
            container: this.props.selectionContainer,
            id: this.props.selectedFrameClip.get("id"),
            cellId: this.props.selectedFrameClip.get("clipId"),
            original: { ...this.state.originalToUpdate },
          },
          this.props.socket
        );
        */
      } else {
        this.cancelFraming();
      }
    }
  };

  getFrameHandleDirectionClass = () => {
    let frameHandleDirectionClass = "";
    const angle = this.props.frameItem.get("angle");
    if (angle >= 337.5 || angle <= 22.5) frameHandleDirectionClass = "e";
    else if (angle >= 22.5 && angle <= 67.5) frameHandleDirectionClass = "se";
    else if (angle >= 67.5 && angle <= 112.5) frameHandleDirectionClass = "s";
    else if (angle >= 112.5 && angle <= 157.5) frameHandleDirectionClass = "sw";
    else if (angle >= 157.5 && angle <= 202.5) frameHandleDirectionClass = "w";
    else if (angle >= 202.5 && angle <= 247.5) frameHandleDirectionClass = "nw";
    else if (angle >= 247.5 && angle <= 292.5) frameHandleDirectionClass = "n";
    else if (angle >= 292.5 && angle <= 337.5) frameHandleDirectionClass = "ne";
    return frameHandleDirectionClass;
  };

  getCursorFlipPos = () => {
    const defaultCursorStyle = {
      topLeft: "nwse-resize",
      topRight: "nesw-resize",
      bottomLeft: "nesw-resize",
      bottomRight: "nwse-resize",
    };
    let cursorStyle = defaultCursorStyle;
    try {
      const flipPosition = this.props.frameItem.get("flipPosition");
      if (flipPosition === 1 || flipPosition === 2) {
        cursorStyle = {
          topLeft: "nesw-resize",
          topRight: "nwse-resize",
          bottomLeft: "nwse-resize",
          bottomRight: "nesw-resize",
        };
      }
    } catch (error) {
      cursorStyle = defaultCursorStyle;
    }
    return cursorStyle;
  };

  render() {
    const itemType = this.props.frameItem.get("type");

    let deepSearchTerm;
    if (itemType === "FRAME") {
      deepSearchTerm = ["clipDetails", this.props.selectedClip];
    } else {
      deepSearchTerm = ["gridData", "gridCells", this.props.selectedClip];
    }
    const clipData = this.props.frameItem.getIn(deepSearchTerm)
    const imgDetails = clipData.get("imgDetails");
    const { src, thumb } = getWorkspaceItemSource({
      item: {
        bgremoval: imgDetails.get("bgremoval"),
        isBlob: imgDetails.get("isBlob"),
        src: imgDetails.get("src"),
        stickerify: imgDetails.get("stickerify"),
        subType: imgDetails.get("subType"),
        thumb: imgDetails.get("thumb"),
        thumbnail: imgDetails.get("thumbnail"),
        type: imgDetails.get("type"),
      },
    });
    let imageSrc = src;
    if (isVideoOnly(imgDetails.get("type"), imgDetails.get("subType"))) {
      imageSrc = thumb;
    }

    const unclippedPositionStyle = {
      position: "absolute",
      transform: `translate(${
        this.state.unclipped.x * this.props.zoomFactor
      }px, ${this.state.unclipped.y * this.props.zoomFactor}px)`,
      width: `${this.state.unclipped.width * this.props.zoomFactor}px`,
      height: `${this.state.unclipped.height * this.props.zoomFactor}px`,
      willChange: "transform",
    };

    const clippedPositionStyle = {
      transform: `translate(${
        this.state.clipped.x * this.props.zoomFactor
      }px, ${
        this.state.clipped.y * this.props.zoomFactor
      }px) rotate(${this.props.frameItem.get("angle")}deg) ${this.state.flip}`,
      width: `${this.state.clipped.width * this.props.zoomFactor}px`,
      height: `${this.state.clipped.height * this.props.zoomFactor}px`,
    };

    const clipPathId = `${
      this.props.selectedClip
    }_${this.props.selectedItems.get(0)}_framing`;
    const clipImageStyle = {};
    if (itemType === "FRAME") {
      clipImageStyle.clipPath = `url(#${clipPathId})`;
    }
    let gridStyles = {};
    if (itemType === "GRID") {
      gridStyles = {
        borderRadius: `${this.props.frameItem.getIn([
          "gridData",
          "borderRadius",
        ])}px`,
      };
    }

    const filterData =
      this.props.frameItem
        .getIn(deepSearchTerm)
        .getIn(["imgDetails", "filter"]) !== undefined
        ? this.props.frameItem
            .getIn(deepSearchTerm)
            .getIn(["imgDetails", "filter"])
        : ITEM_CONFIG.IMAGE.DEFAULT_FILTER;
    const filterId = `svgFilter_${this.props.selectedItems.get(0)}_${
      this.props.selectedClip
    }_${filterData}_framing`;
    const imageStyle = {
      width: "100%",
      height: "100%",
      filter: `url(#${filterId})`,
    };

    const vignetteId = `${
      this.props.selectedClip
    }-vignette-${this.props.selectedItems.get(0)}_framing`;
    const filterSvgId = `svgFilter_${this.props.selectedItems.get(0)}_${
      this.props.selectedClip
    }_framing`;

    const clipPathStyle = {
      transform: `scaleX(${
        (this.props.frameItem.get("width") /
          this.props.frameItem.get("defaultWidth")) *
        this.props.zoomFactor
      }) scaleY(${
        (this.props.frameItem.get("height") /
          this.props.frameItem.get("defaultHeight")) *
        this.props.zoomFactor
      })`,
    };

    const frameHandleDirectionClass = this.getFrameHandleDirectionClass();
    let cursorStyle = {};
    if (itemType === "GRID") {
      cursorStyle = this.getCursorFlipPos();
    }

    return (
      <>
        <div
          className="frameimage-tool-bar"
          style={{
            left: "0px",
            top: "0px",
            width: `${this.props.workspaceWidth * this.props.zoomFactor}px`,
            height: `${this.props.workspaceHeight * this.props.zoomFactor}px`,
            position: "absolute",
          }}
        >
          <div style={{ ...clippedPositionStyle }}>
            <div
              ref={(instance) => {
                this.unclippled = instance;
              }}
              style={{ opacity: 0.5, ...unclippedPositionStyle }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  transform: `${this.state.clipImageFlip}`,
                }}
              >
                <img
                  alt={`Unclip_${
                    itemType === "FRAME" ? "FRAME" : "GRID"
                  }_Image`}
                  src={imageSrc}
                  style={imageStyle}
                  draggable="false"
                />
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                ...gridStyles,
              }}
            >
              {itemType === "FRAME" && (
                <SVG style={{ position: "absolute" }}>
                  <defs>
                    <clipPath id={clipPathId}>
                      <path
                        d={clipData.get("clipData")}
                        style={clipPathStyle}
                      />
                    </clipPath>
                  </defs>
                </SVG>
              )}
              <div style={clipImageStyle}>
                <div
                  ref={(instance) => {
                    this.clippled = instance;
                  }}
                  style={{ opacity: 1, ...unclippedPositionStyle }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: `${this.state.clipImageFlip}`,
                    }}
                  >
                    <img
                      alt={`Clip_${
                        itemType === "FRAME" ? "FRAME" : "GRID"
                      }_Image`}
                      data-id={this.props.selectedItems.get(0)}
                      clip={this.props.selectedClip}
                      src={imageSrc}
                      style={imageStyle}
                      draggable="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <FilterItem
              ref={(instance) => {
                this.filterItem = instance;
              }}
              filterId={filterData}
              filterDOMId={filterId}
              vignetteId={vignetteId}
              filterSvgId={filterSvgId}
              browserName={this.props.browserName}
              style={{ ...unclippedPositionStyle }}
            />

            <div
              ref={(instance) => {
                this.selection = instance;
              }}
              style={{
                transform: `translate(${
                  this.state.unclipped.x * this.props.zoomFactor
                }px, ${this.state.unclipped.y * this.props.zoomFactor}px)`,
                width: `${
                  this.state.unclipped.width * this.props.zoomFactor
                }px`,
                height: `${
                  this.state.unclipped.height * this.props.zoomFactor
                }px`,
                position: "absolute",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  bottom: "-10px",
                  left: "-10px",
                  pointerEvents: "none",
                  outline: "none",
                  outlineOffset: 0,
                  animation: "unset",
                }}
              />
              <UnCropB
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  pointerEvents: "auto",
                }}
                onTouchStart={this.initiateMove}
                onMouseDown={this.initiateMove}
              />
              <FrameResizeHandleContainer
                className={`frame-resize-handles ${frameHandleDirectionClass}`}
              >
                <span
                  className="resize-handle top-left"
                  data-handle="top-left"
                  onTouchStart={this.initiateResize}
                  onMouseDown={this.initiateResize}
                  style={{ cursor: cursorStyle.topLeft }}
                />
                <span
                  className="resize-handle top-right"
                  data-handle="top-right"
                  onTouchStart={this.initiateResize}
                  onMouseDown={this.initiateResize}
                  style={{ cursor: cursorStyle.topRight }}
                />
                <span
                  className="resize-handle bottom-right"
                  data-handle="bottom-right"
                  onTouchStart={this.initiateResize}
                  onMouseDown={this.initiateResize}
                  style={{ cursor: cursorStyle.bottomRight }}
                />
                <span
                  className="resize-handle bottom-left"
                  data-handle="bottom-left"
                  onTouchStart={this.initiateResize}
                  onMouseDown={this.initiateResize}
                  style={{ cursor: cursorStyle.bottomLeft }}
                />
              </FrameResizeHandleContainer>
            </div>
          </div>
        </div>
        <ItemToolConfirmation
          workspaceStage={this.props.workspaceStage}
          onApply={this.applyFrame}
          onCancel={this.cancelFraming}
        />
      </>
    );
  }
}

FrameImageComponent.propTypes = {
  workspaceStage: PropTypes.object,
  zoomFactor: PropTypes.number,
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
  selectedItems: PropTypes.object,
  selectionContainer: PropTypes.string,
  selectedClip: PropTypes.string,
  selectedFrameClip: PropTypes.object,
  frameItem: PropTypes.object,
  browserName: PropTypes.string,
  socket: PropTypes.object,
  cancelFraming: PropTypes.func,
  applyFrameClipPlot: PropTypes.func,
  workspaceRef: PropTypes.object,
  // applyGridCellPlot: PropTypes.func,
};

const mapStateToProps = (state) => ({
  workspaceStage: state.app.get("workspaceStage"),
  zoomFactor: state.app.get("zoomFactor"),
  workspaceWidth: state.projectDetails.get("width"),
  workspaceHeight: state.projectDetails.get("height"),
  selectedItems: getSelectedItemIds({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
  }),
  selectionContainer: getSelectionContainer({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
  }),
  selectedClip: state.app.getIn(["selectedFrameClip", "clipId"]),
  selectedFrameClip: state.app.get("selectedFrameClip"),
  frameItem: getSelectedObjects({
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
    workspaceChildren: state.projectDetails.get("workspaceChildren"),
    workspaceItems: state.projectDetails.get("workspaceItems"),
  }).first(),
  browserName: state.app.get("browserName"),
});

const mapDispatchToProps = (dispatch) => ({
  cancelFraming: () => dispatch(cancelFraming()),
  applyFrameClipPlot: (data) => dispatch(applyFrameClipPlot(data)),
  // applyGridCellPlot: (data, socket) => dispatch(applyGridCellPlot(data, socket)),
});

const FrameImage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FrameImageComponent);

export default FrameImage;
