import { useReducer } from "react";
import { fontFamilies } from "../../containers/text/text-constants";
import {
  SUBTITLE_STYLE_OPTION,
  TEXT_ALIGN,
  TEXT_TRANSFORM,
} from "../subtitleHelper";

/**
 * @description useSubtitleStyle a customs hook return a reducer state based on styleValues props.
 * @param {*} props styleValues
 * @returns {Object}
 */
export default function useSubtitleStyle(props) {
  /* Helper functions start */
  const isStyleActive = (property, state) => {
    if (
      state !== null &&
      property !== null &&
      state !== undefined &&
      property !== undefined
    ) {
      return state.get(property.STATE_NAME);
    }
    return false;
  };

  const getStyleValue = (property, state) => {
    let currentValue;
    if (
      state !== null &&
      property !== null &&
      state !== undefined &&
      property !== undefined
    ) {
      currentValue = state.get(property.STYLE_NAME);
      if (currentValue != null) return currentValue;
    }
    currentValue = property.DEFAULT_VALUE;
    return currentValue;
  };

  const checkIfBoldCanApply = (state) => {
    const toReturn = { boldCanApply: false };
    if (state === undefined || state === null) {
      return toReturn;
    }
    const isUserFont = state.getIn(["others", "isUserFont"]);
    if (isUserFont) {
      return toReturn;
    }
    const fontFamilyName = state.getIn(["fontFamilyName"]);
    fontFamilies.getIn(["default", "fonts"]).some((fontData) => {
      if (fontData.get("fontFamily") === fontFamilyName) {
        if (fontData.get("isBold")) {
          toReturn.boldCanApply = true;
          toReturn.fontFamily = fontFamilyName;
          toReturn.fileName = fontData.get("fileName").replace(".css", "");
          toReturn.changeBold = true;
          toReturn.bold = fontData.get("bold");
          toReturn.regular = fontData.get("regular");
        }
        return true;
      }
      return false;
    });
    return toReturn;
  };
  /* Helper functions end */
  const reducer = () => {
    let isBold = 0;
    let isItalic = 0;
    let isUnderline = 0;
    let textAlign = TEXT_ALIGN.CENTER;
    let textTransform = TEXT_TRANSFORM[0];
    let subtitleColor = "#ffffff";
    let subtitleBgColor = "#00000080";
    let fontSize = "16px";
    let letterSpacing = 0;
    let lineHeight = 1.2;
    let fontFamilyName = "";

    isBold = isStyleActive(SUBTITLE_STYLE_OPTION.BOLD, props.styleValues);
    isItalic = isStyleActive(SUBTITLE_STYLE_OPTION.ITALIC, props.styleValues);
    isUnderline = isStyleActive(
      SUBTITLE_STYLE_OPTION.UNDERLINE,
      props.styleValues
    );
    textAlign = getStyleValue(
      SUBTITLE_STYLE_OPTION.TEXT_ALIGN,
      props.styleValues
    );
    textTransform = getStyleValue(
      SUBTITLE_STYLE_OPTION.TEXT_TRANSFORM,
      props.styleValues
    );
    subtitleColor = getStyleValue(
      SUBTITLE_STYLE_OPTION.COLOR,
      props.styleValues
    );
    subtitleBgColor = getStyleValue(
      SUBTITLE_STYLE_OPTION.BG_COLOR,
      props.styleValues
    );
    fontSize = getStyleValue(
      SUBTITLE_STYLE_OPTION.FONT_SIZE,
      props.styleValues
    );
    fontFamilyName = isStyleActive(
      SUBTITLE_STYLE_OPTION.FONT_FAMILY,
      props.styleValues
    );
    letterSpacing = getStyleValue(
      SUBTITLE_STYLE_OPTION.LETTER_SPACING,
      props.styleValues
    );
    lineHeight = getStyleValue(
      SUBTITLE_STYLE_OPTION.LINE_HEIGHT,
      props.styleValues
    );
    const fontFamily = getStyleValue(
      SUBTITLE_STYLE_OPTION.FONT_FAMILY,
      props.styleValues
    );
    const colorAlpha = getStyleValue(
      SUBTITLE_STYLE_OPTION.COLOR_ALPHA,
      props.styleValues
    );
    const bgAlpha = getStyleValue(
      SUBTITLE_STYLE_OPTION.BG_ALPHA,
      props.styleValues
    );
    const boldData = checkIfBoldCanApply(props.styleValues);
    return {
      isBold,
      isItalic,
      isUnderline,
      textAlign,
      textTransform,
      subtitleColor,
      subtitleBgColor,
      fontSize,
      fontFamily,
      letterSpacing,
      fontFamilyName,
      lineHeight,
      boldData,
      colorAlpha: colorAlpha || 100,
      bgAlpha: bgAlpha || 100,
    };
  };

  const [styleValues, updatestyleValues] = useReducer(
    reducer,
    undefined,
    reducer
  );

  return [styleValues, updatestyleValues];
}
