const light = require("./light.json");
const material = require("./material.json");

const vmTheme = {
  light,
  material,
};

export const LIGHT = "light";
export const MATERIAL = "material";

export default vmTheme;
