import React from "react";
import { TMOverlayContainer } from "./tm-components";

const TMOverlay = () => {
  return (
    <TMOverlayContainer data-html2canvas-ignore="true">
      <div className="snap-line vertical-line ver-left" />
      <div className="snap-line vertical-line ver-center" />
      <div className="snap-line vertical-line ver-right" />
      <div className="snap-line horizontal-line hor-top" />
      <div className="snap-line horizontal-line hor-center" />
      <div className="snap-line horizontal-line hor-bottom" />
      <div className="snap-line rotationLine" />
      <div className="snap-line rotationDisplay">
        <span id="rotationAngle" />
      </div>
    </TMOverlayContainer>
  );
};

export default TMOverlay;
