import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { PropertyWindowContainer } from "./propertywindow-components";
import SettingsWindow from "./settingswindow";
import ColorPropertyWindow from "./color/propertywindow-colorproperty";
import content from "../../../constants/content";
import SubtitlePropertyWindow from "./subtitle/subtitle-propertywindow";
import AnimationPanel from "./animation/animation-panel";

const PropertyWindow = (props) => {
    const propertyWindow = useSelector(state => state.app.get("propertyWindow"));
    const components = [];

    if (propertyWindow.get("isOpened") && propertyWindow.get("component") === "settings") {
        components.push(
            <SettingsWindow
                key="settings"
                textPropertyShortcut={props.textPropertyShortcut}
            />
        );
    }

    if (propertyWindow.get("isOpened") && propertyWindow.get("component") === "color") {
        components.push(<ColorPropertyWindow key="color" />);
    }


    if (propertyWindow.get("isOpened") && propertyWindow.get("component") === content.ANIMATE) {
        components.push(<AnimationPanel key="animate" />);
    }

    if (propertyWindow.get("isOpened") && propertyWindow.get("component") === "subtitle_settings") {
        components.push(<SubtitlePropertyWindow key="subtitle" />);
    }

    return (
        <PropertyWindowContainer>
            {components}
        </PropertyWindowContainer>
    );
}

PropertyWindow.propTypes = {
    textPropertyShortcut: PropTypes.string,
};

export default PropertyWindow;
