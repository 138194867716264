import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "./Typography";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const TooltipWrapper = styled.div`
  position: relative;
  color: ${(props) => props.theme.polarColor};
  display: inherit;
`;

const Tooltips = styled.div`
  position: fixed;
  border-radius: 6px;
  left: 3%;
  right: 5%;
  padding: 10px 15px;
  color: ${(props) => props.theme.polarColor};
  background-color: rgba(0, 0, 0, 0.8);
  font: ${font.normalMini};
  z-index: 9999;
  top: ${(props) => props.top || "0%"};
  min-width: 215px;
  height: fit-content;
`;

const Tooltip = ({ heading, content, children, top }) => {
  const [active, setActive] = useState(false);
  const [position, setPosition] = useState({});
  const ref = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));

  const calculatePosition = () => {
    if (ref && ref.current) {
      const node = ref.current.getBoundingClientRect();
      const topPosition = content ? node.top - 70 : node.top - 50;
      setPosition({ top: topPosition, left: node.left });
    }
  };

  const showTip = () => {
    setActive(true);
    calculatePosition();
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip} ref={ref}>
      {children}
      {active && (content || heading) && (
        <Tooltips style={position} top={top}>
          <Typography
            enableTrim={false}
            font={font.normalMini}
            color={vmTheme[theme].polarColor}
            content={heading}
            display="block"
            padding="5px 0px"
            top={top}
          />
          {active && content && (
            <Typography
              enableTrim={false}
              font={font.normalMicro_11}
              color={vmTheme[theme].polarColor}
              content={content}
              display="block"
              padding="3px 0px 5px 0px"
            />
          )}
        </Tooltips>
      )}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string,
  heading: PropTypes.string,
  top: PropTypes.string,
  children: PropTypes.element,
};
export default Tooltip;
