/// <reference path="./editable-types.js" />

export const SPACE = "\u0020";
export const NEWLINE = "\u000A";

/** @type {EditableNewlineDelta} */
export const NEWLINE_DELTA_TEMPLATE = Object.freeze({
    startIndex: 0,
    length: 1,
    text: NEWLINE,
    allowedChar: NEWLINE,
    isNewline: true,
    preventJoin: true,
});
