import styled from "styled-components";
import { font } from "../../../constants/font";

export const ARRANGE_MENU_LEFT_CLASS = "arrange-context-menu-on-left";
export const GAP_BETWEEN_MENU = 2; // gap between main menu and sub menu

export const OptionTextStyled = styled.p`
    font: ${font.normalBase_21};
    color: ${(props) => props.theme.tertiaryColor};
    position: relative;
    white-space: nowrap;
    margin: 0;
`;
OptionTextStyled.displayName = "OptionTextStyled";

export const ArrangeMenuStyled = styled.div`
    position: absolute;
    left: calc(100% + ${GAP_BETWEEN_MENU}px); top: 0px;
    min-width: 134px;
    background: ${(props) => props.theme.polarColor};
    box-shadow: 0px 0px 4px ${(props) => props.theme.arrangeMenuColor};
    border-radius: 4px;
    cursor: auto;

    &.${ARRANGE_MENU_LEFT_CLASS} {
        left: 0;
        transform: translateX(calc(-100% - ${GAP_BETWEEN_MENU}px));
    }
`;
ArrangeMenuStyled.displayName = "ArrangeMenuStyled";

export const ContextMenuOptionStyled = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: ${(props) => props.theme.polarColor};
    cursor: pointer;

    > img.cmo--icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    > img.cmo--submenu-trigger {
        width: 8px;
        height: 8px;
        margin-left: auto;
        transform: rotate(270deg);
    }

    :hover, &.cmo--selected {
        background: ${(props) => props.theme.btnBgLightColor};

        > img.cmo--icon {
            content: var(--hoverSrc);
        }

        > p {
            font-weight: normal;
            color: ${(props) => props.theme.tertiaryColor};
        }
    }

    &.cmo--disabled {
        pointer-events: none !important;
        cursor: auto;

        > img.cmo--icon {
            content: var(--disabledSrc);
        }

        > p {
            color: ${(props) => props.theme.contextMenuTxtColor};
        }
    }
`;
ContextMenuOptionStyled.displayName = "ContextMenuOptionStyled";

export const ContextMenuListStyled = styled.ul`
    margin: 0;
    padding: 4px 0 4px 0;

    :not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.buttonDisableColor};
    }
`;
ContextMenuListStyled.displayName = "ContextMenuListStyled";

export const ContextMenuStyled = styled.div`
    position: fixed;
    z-index: 10;
    min-width: 134px;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px ${(props) => props.theme.arrangeMenuColor};
    border-radius: 6px;
`;
ContextMenuStyled.displayName = "ContextMenuStyled";

export const VolumeSliderContainer = styled.div`
    padding: 8px;

    &.is-disabled {
        pointer-events: none;
        opacity: 0.64;
    }

    > div {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        > img {
            width: 24px;
            height: 24px;
        }

        > div {
            position: absolute;
            width: calc(100% - 24px - 8px);
            right: 0;

            input {
                width: 100%;
                height: 5px;
            }
        }
    }
`;
VolumeSliderContainer.displayName = "VolumeSliderContainer";
