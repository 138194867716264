/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  background-color: white;
  z-index: 99999;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  width: 175px;
  height: 182px;
  flex-direction: column;
  left: calc(50% - 91px);
  top: calc(50% - 160px);
  position: fixed;
`;

const Video = styled.video`
  width: 175px;
  height: 175px;
`;

const Load = styled.div`
  .loaderbar {
    width: 175px;
    height: 6px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.veryLightGray};
    position: absolute;
  }

  .loaderbar2 {
    height: 6px;
    border-radius: 8px;
    -webkit-transition: width 250ms;
    transition-property: width, background-color;
    background: transparent ${(props) => props.theme.headerColor} 0% 0%
      no-repeat padding-box;
    position: relative;
    transition: all 0.3s;
    width: ${(props) => `${props.progress}%`};
  }
`;

const Loader = ({ progress, disableProgress = false }) => {
  return (
    <Root>
      <Container>
        <Video preload="auto" muted="true" loop="true" autoPlay="true">
          <source
            src="https://static.animaker.com/animapp-static/images/animaker-new-loader.webm"
            type="video/webm"
          />
        </Video>
        {!disableProgress && (
          <Load progress={progress}>
            <div className="loaderbar" />
            <div className="loaderbar2" />
          </Load>
        )}
      </Container>
    </Root>
  );
};

export default Loader;
