/* eslint-disable max-len */

import { List, Record, fromJS } from "immutable";

export const TextStatusRecord = Record({
    id: null,
    isSelected: false,
    isFocused: false,
    container: null,
    isRTL: false,
    isGrouped: false,
    type: "",
});

const TextOptionRecord = Record({ disabled: false, isActive: false, value: null });

export const TextOptionsRecord = Record({
    fontFamily: TextOptionRecord(),
    fontSize: TextOptionRecord(),
    fontWeight: TextOptionRecord(),
    fontStyle: TextOptionRecord(),
    textDecoration: TextOptionRecord(),
    bullet: TextOptionRecord({ value: "none" }),
    textAlign: TextOptionRecord(),
    textTransform: TextOptionRecord(),
    color: TextOptionRecord(),
    link: TextOptionRecord(),
    opacity: TextOptionRecord(),
    lineSpacing: TextOptionRecord(),
    lineHeight: TextOptionRecord(),
    wordSpacing: TextOptionRecord(),
    letterSpacing: TextOptionRecord(),
    colors: List([]),
});

export const TextApplyAllOptionsRecord = Record({
    isFontSizeApplyAll: true,
    isFontFamilyApplyAll: true,
    isTextEffectsApplyAll: true,
});

export const TEXT_EFFECTS = {
    "None": {},
    "Shadow": { Offset: 77, Blur: 0, Transparency: 40, Direction: 48, Color: "#000000" },
    "Lift": { Intensity: 61, Depth: 0 },
    "Hollow": { Thickness: 55 },
    "Glitch": { Offset: 32, Direction: -135, Color: 2 },
    "Echo": { Offset: 84, Direction: 47, Color: "#000000" },
    "Neon": { Intensity: 72 },
    "Splice": { Offset: 64, Direction: 10, Thickness: 55, Color: "#aaaaaa" },
    "Outline": { Thickness: 50, Blur: 50, Transparency: 50, Color: "#FFA500" }
};

export const TEXT_EFFECT_LIMITS = {
    "Shadow": { Offset: [77, 0, 100], Blur: [0, 0, 100], Transparency: [40, 0, 100], Direction: [48, -180, 180], Color: "#000000" },
    "Lift": { Intensity: [61, 1, 100], Depth: [0, 0, 100] },
    "Hollow": { Thickness: [45, 1, 100] },
    "Glitch": { Offset: [32, 0, 100], Direction: [-135, -180, 180], Color: "#000000" },
    "Echo": { Offset: [84, 0, 100], Direction: [47, -180, 180], Color: "#000000" },
    "Neon": { Intensity: [72, 1, 100] },
    "Splice": { Offset: [64, 0, 100], Direction: [10, -180, 180], Thickness: [10, 1, 100], Color: "#aaaaaa" },
    "Outline": { Thickness: [34, 1, 100], Blur: [15, 0, 100], Transparency: [40, 0, 100], Color: "#FFA500" },
};

const Arvo = { "fontFamily": "Arvo", "regular": "Arvo", "bold": "Arvo-Bold", "italic": "Arvo-Italic", "boldItalic": "Arvo-BoldItalic", "image": "arvo.png", "fileName": "Arvo.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.16 };
const Poppins = { "fontFamily": "Poppins", "regular": "Poppins-Regular", "bold": "Poppins-Bold", "italic": "", "boldItalic": "", "image": "Poppins.png", "fileName": "Poppins.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.66 };

/** 
 * @Note Naming convention in key-value regular, bold, italic, should be same as the value present in PostScriptName 
 * @Note Mobile Team is not supporting otf file, make sure to convert it, using otf2ttf python module.
*/
export const fontFamilies = fromJS({
    "default": {
        name: "Default Fonts", fonts: [
            { "fontFamily": "AC Mountain Inverted", "regular": "ACMountainInverted-Regular", "image": "ACMountainInverted.png", "fileName": "AC Mountain Inverted", "lineHeight": 1.2 },
            { "fontFamily": "Abril Fatface", "regular": "AbrilFatface-Regular", "image": "abril_fatface.png", "fileName": "Abril Fatface.css", "lineHeight": 1.35 },
            { "fontFamily": "AlikeAngular", "regular": "AlikeAngular-Regular", "image": "AlikeAngular.png", "fileName": "AlikeAngular.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Allerta", "regular": "Allerta-Regular", "image": "allerta.png", "fileName": "Allerta.css", "lineHeight": 1.28 },
            { "fontFamily": "AllertaStencil", "regular": "AllertaStencil-Regular", "image": "AllertaStencil.png", "fileName": "AllertaStencil.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.28 },
            { "fontFamily": "Allura", "regular": "Allura-Regular", "image": "Allura.png", "fileName": "Allura.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Amatic SC", "regular": "AmaticSC-Regular", "bold": "AmaticSC-Bold", "image": "amatic_sc.png", "fileName": "Amatic SC.css", "isBold": true, "lineHeight": 1.41 },
            { "fontFamily": "Anton", "regular": "Anton-Regular", "image": "Anton.png", "fileName": "Anton.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.5 },
            { "fontFamily": "ArchitectsDaughter", "regular": "ArchitectsDaughter-Regular", "image": "ArchitectsDaughter.png", "fileName": "ArchitectsDaughter.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.39 },
            Arvo,
            Poppins,
            { "fontFamily": "Baloo", "regular": "Baloo-Regular", "image": "Baloo.png", "fileName": "Baloo.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.57 },
            { "fontFamily": "Bangers", "regular": "Bangers-Regular", "image": "Bangers.png", "fileName": "Bangers.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.76 },
            { "fontFamily": "BarlowSemiCondensed", "regular": "BarlowSemiCondensed-Regular", "bold": "BarlowSemiCondensed-Bold", "image": "BarlowSemiCondensed.png", "fileName": "BarlowSemiCondensed.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.35 },
            // { "fontFamily": "Barracuda", "regular": "Barracuda-Regular", "bold": "Barracuda-Bold", "image": "Barracuda.png", "fileName": "Barracuda.css", "isBold": true, "lineHeight": 0.91  },
            { "fontFamily": "BebasNeue", "regular": "BebasNeue-Regular", "image": "BebasNeue.png", "fileName": "BebasNeue.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.3 },
            { "fontFamily": "BerkshireSwash", "regular": "BerkshireSwash-Regular", "image": "BerkshireSwash.png", "fileName": "BerkshireSwash.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.24 },
            { "fontFamily": "Bevan", "regular": "Bevan-Regular", "image": "bevan.png", "fileName": "Bevan.css", "lineHeight": 1.74 },
            { "fontFamily": "BigShouldersDisplay", "regular": "BigShouldersDisplay-Regular", "image": "BigShouldersDisplay.png", "fileName": "BigShouldersDisplay.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.67 },
            { "fontFamily": "BlackOpsOne", "regular": "BlackOpsOne-Regular", "image": "BlackOpsOne.png", "fileName": "BlackOpsOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "BloggerSans", "regular": "BloggerSans-Regular", "image": "BloggerSans.png", "fileName": "BloggerSans.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "Bowlby One SC", "regular": "BowlbyOneSC-Regular", "image": "bowlby_one_sc.png", "fileName": "Bowlby One SC.css", "lineHeight": 1.53 },
            { "fontFamily": "BreeSerif", "regular": "BreeSerif-Regular", "image": "BreeSerif.png", "fileName": "BreeSerif.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.36 },
            { "fontFamily": "Buffalo", "regular": "Buffalo-Regular", "image": "Buffalo.png", "fileName": "Buffalo.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.4 },
            { "fontFamily": "Bungee", "regular": "Bungee-Regular", "image": "bungee.png", "fileName": "Bungee.css", "lineHeight": 2.35 },
            { "fontFamily": "Cabin", "regular": "Cabin-Regular", "bold": "Cabin-Bold", "italic": "Cabin-Italic", "boldItalic": "Cabin-BoldItalic", "image": "cabin.png", "fileName": "Cabin.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.46 },
            { "fontFamily": "Cairo", "regular": "Cairo-Regular", "bold": "Cairo-Bold", "image": "Cairo.png", "fileName": "Cairo.css", "isBold": true, "lineHeight": 1.833 },
            { "fontFamily": "Caladea", "regular": "Caladea-Regular", "bold": "Caladea-Bold", "image": "Caladea.png", "fileName": "Caladea.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.3 },
            { "fontFamily": "Calistoga", "regular": "Calistoga-Regular", "image": "Calistoga.png", "fileName": "Calistoga.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.7 },
            { "fontFamily": "Cardo", "regular": "Cardo-Regular", "bold": "Cardo-Bold", "image": "Cardo.png", "fileName": "Cardo.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.35 },
            { "fontFamily": "CarterOne", "regular": "CarterOne-Regular", "image": "CarterOne.png", "fileName": "CarterOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.54 },
            { "fontFamily": "Caveat", "regular": "Caveat-Regular", "bold": "Caveat-Bold", "image": "Caveat.png", "fileName": "Caveat.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.28 },
            { "fontFamily": "Chango", "regular": "Chango-Regular", "image": "Chango.png", "fileName": "Chango.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.21 },
            { "fontFamily": "ChelseaMarket", "regular": "ChelseaMarket-Regular", "image": "ChelseaMarket.png", "fileName": "ChelseaMarket.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.27 },
            { "fontFamily": "Cinzel", "regular": "Cinzel-Regular", "bold": "Cinzel-Bold", "image": "Cinzel.png", "fileName": "Cinzel.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.35 },
            { "fontFamily": "Codystar", "regular": "Codystar-Regular", "image": "Codystar.png", "fileName": "Codystar.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.18 },
            { "fontFamily": "Comfortaa", "regular": "Comfortaa-Regular", "bold": "Comfortaa-Bold", "image": "Comfortaa.png", "fileName": "Comfortaa.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.62 },
            { "fontFamily": "ConcertOne", "regular": "ConcertOne-Regular", "image": "ConcertOne.png", "fileName": "ConcertOne.css", "lineHeight": 1.17 },
            { "fontFamily": "Convergence", "regular": "Convergence-Regular", "image": "Convergence.png", "fileName": "Convergence.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "CormorantGaramond", "regular": "CormorantGaramond-Regular", "bold": "CormorantGaramond-Bold", "image": "CormorantGaramond.png", "fileName": "CormorantGaramond.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.22 },
            { "fontFamily": "CoveredByYourGrace", "regular": "CoveredByYourGrace-Regular", "image": "CoveredByYourGrace.png", "fileName": "CoveredByYourGrace.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.35 },
            { "fontFamily": "Creepster", "regular": "Creepster-Regular", "image": "Creepster.png", "fileName": "Creepster.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "CrimsonPro", "regular": "CrimsonPro-Regular", "bold": "CrimsonPro-Bold", "image": "CrimsonPro.png", "fileName": "CrimsonPro.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.27 },
            { "fontFamily": "Damion", "regular": "Damion-Regular", "image": "Damion.png", "fileName": "Damion.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.37 },
            { "fontFamily": "DarkerGrotesque", "regular": "DarkerGrotesque-Regular", "bold": "DarkerGrotesque-Bold", "image": "DarkerGrotesque.png", "fileName": "DarkerGrotesque.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.36 },
            { "fontFamily": "DeliusSwashCaps", "regular": "DeliusSwashCaps-Regular", "image": "DeliusSwashCaps.png", "fileName": "DeliusSwashCaps.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.26 },
            { "fontFamily": "Dhurjati", "regular": "Dhurjati", "image": "Dhurjati.png", "fileName": "Dhurjati.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.85 },
            { "fontFamily": "Dosis", "regular": "Dosis-Regular", "bold": "Dosis-Bold", "image": "Dosis.png", "fileName": "Dosis.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.36 },
            // { "fontFamily": "DotGothic16", "regular": "DotGothic16-Regular", "image": "DotGothic16.png", "fileName": "DotGothic16.css", "lineHeight": 1.44  },
            { "fontFamily": "EmilysCandy", "regular": "EmilysCandy-Regular", "image": "EmilysCandy.png", "fileName": "EmilysCandy.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.26 },
            { "fontFamily": "Exo", "regular": "Exo-Regular", "bold": "Exo-Bold", "italic": "Exo-Italic", "boldItalic": "Exo-BoldItalic", "image": "exo.png", "fileName": "Exo.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.41 },
            { "fontFamily": "FanwoodText", "regular": "FanwoodText-Regular", "image": "FanwoodText.png", "fileName": "FanwoodText.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.31 },
            { "fontFamily": "FascinateInline", "regular": "FascinateInline-Regular", "image": "FascinateInline.png", "fileName": "FascinateInline.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.33 },
            { "fontFamily": "Faster One", "regular": "FasterOne-Regular", "image": "faster_one.png", "fileName": "Faster One.css", "lineHeight": 1.07 },
            { "fontFamily": "Flavors", "regular": "Flavors-Regular", "image": "Flavors.png", "fileName": "Flavors.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.28 },
            { "fontFamily": "Fondamento", "regular": "Fondamento-Regular", "image": "Fondamento.png", "fileName": "Fondamento.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.38 },
            { "fontFamily": "FrankRuhlLibre", "regular": "FrankRuhlLibre-Regular", "bold": "FrankRuhlLibre-Bold", "image": "FrankRuhlLibre.png", "fileName": "FrankRuhlLibre.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.29 },
            { "fontFamily": "FreckleFace", "regular": "FreckleFace-Regular", "image": "FreckleFace.png", "fileName": "FreckleFace.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.23 },
            { "fontFamily": "FrederickatheGreat", "regular": "FrederickatheGreat-Regular", "image": "FrederickatheGreat.png", "fileName": "FrederickatheGreat.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.23 },
            { "fontFamily": "Frijole", "regular": "Frijole-Regular", "image": "Frijole.png", "fileName": "Frijole.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.38 },
            { "fontFamily": "Geo", "regular": "Geo-Regular", "image": "Geo.png", "fileName": "Geo.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 0.93 },
            { "fontFamily": "Gidugu", "regular": "Gidugu", "image": "Gidugu.png", "fileName": "Gidugu.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.85 },
            { "fontFamily": "GlacialIndifference", "regular": "GlacialIndifference-Regular", "image": "GlacialIndifference.png", "fileName": "GlacialIndifference.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1 },
            { "fontFamily": "Gochi Hand", "regular": "GochiHand-Regular", "image": "gochi_hand.png", "fileName": "Gochi Hand.css", "lineHeight": 1.18 },
            { "fontFamily": "GrandHotel", "regular": "GrandHotel-Regular", "image": "GrandHotel.png", "fileName": "GrandHotel.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.36 },
            { "fontFamily": "GravitasOne", "regular": "GravitasOne-Regular", "image": "gravitas_one.png", "fileName": "GravitasOne.css", "lineHeight": 1.28 },
            { "fontFamily": "GreatVibes", "regular": "GreatVibes-Regular", "image": "GreatVibes.png", "fileName": "GreatVibes.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Grenze", "regular": "Grenze-Regular", "bold": "Grenze-Bold", "image": "Grenze.png", "fileName": "Grenze.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.49 },
            { "fontFamily": "GrenzeGotisch", "regular": "GrenzeGotisch-Regular", "bold": "GrenzeGotisch-Bold", "image": "GrenzeGotisch.png", "fileName": "GrenzeGotisch.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.49 },
            { "fontFamily": "Griffy", "regular": "Griffy-Regular", "image": "griffy.png", "fileName": "Griffy.css", "lineHeight": 1.36 },
            { "fontFamily": "Gudea", "regular": "Gudea-Regular", "bold": "Gudea-Bold", "image": "Gudea.png", "fileName": "Gudea.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.24 },
            // { "fontFamily": "HachiMaruPop", "regular": "HachiMaruPop-Regular", "image": "HachiMaruPop.png", "fileName": "HachiMaruPop.css", "lineHeight": 1.44  },
            { "fontFamily": "Hammersmith One", "regular": "HammersmithOne-Regular", "image": "hammersmith_one.png", "fileName": "Hammersmith One.css", "lineHeight": 1.25 },
            { "fontFamily": "Hanalei", "regular": "Hanalei-Regular", "image": "Hanalei.png", "fileName": "Hanalei.css", "lineHeight": 1.30 },
            { "fontFamily": "Harmattan", "regular": "Harmattan-Regular", "bold": "Harmattan-Bold", "image": "Harmattan.png", "fileName": "Harmattan.css", "isBold": true, "lineHeight": 1.70 },
            { "fontFamily": "HennyPenny", "regular": "HennyPenny-Regular", "image": "HennyPenny.png", "fileName": "HennyPenny.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.77 },
            { "fontFamily": "IBMPlexSansArabic", "regular": "IBMPlexSansArabic-Regular", "bold": "IBMPlexSansArabic-Bold", "image": "IBMPlexSansArabic.png", "fileName": "IBMPlexSansArabic", "isBold": true, "lineHeight": 1.72 },
            { "fontFamily": "IndieFlower", "regular": "IndieFlower", "image": "IndieFlower.png", "fileName": "IndieFlower.css", "lineHeight": 1.45 },
            { "fontFamily": "Inter", "regular": "Inter-Regular", "bold": "Inter-Bold", "image": "Inter.png", "fileName": "Inter.css", "isBold": true, "lineHeight": 1.21 },
            { "fontFamily": "Jacques Francois", "regular": "JacquesFrancois-Regular", "image": "jacques_francois.png", "fileName": "Jacques Francois.css", "lineHeight": 1.32 },
            { "fontFamily": "JockeyOne", "regular": "JockeyOne-Regular", "image": "JockeyOne.png", "fileName": "JockeyOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.4 },
            { "fontFamily": "Josefin Sans", "regular": "JosefinSans-Regular", "bold": "JosefinSans-Bold", "italic": "JosefinSans-Italic", "boldItalic": "JosefinSans-BoldItalic", "image": "josefin_sans.png", "fileName": "Josefin Sans.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.57 },
            { "fontFamily": "Jua", "regular": "Jua-Regular", "image": "Jua.png", "fileName": "Jua.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 0.86 },
            { "fontFamily": "JustAnotherHand", "regular": "JustAnotherHand-Regular", "image": "JustAnotherHand.png", "fileName": "JustAnotherHand.css", "lineHeight": 1.27 },
            { "fontFamily": "Karla", "regular": "Karla-Regular", "bold": "Karla-Bold", "italic": "Karla-Italic", "boldItalic": "Karla-BoldItalic", "image": "karla.png", "fileName": "Karla.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "Kenia", "regular": "Kenia-Regular", "image": "Kenia.png", "fileName": "Kenia.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.29 },
            { "fontFamily": "Kreon", "regular": "Kreon-Regular", "bold": "Kreon-Bold", "image": "Kreon.png", "fileName": "Kreon.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.46 },
            { "fontFamily": "Lalezar", "regular": "Lalezar-Regular", "image": "Lalezar.png", "fileName": "Lalezar.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.57 },
            // { "fontFamily": "Lateef", "regular": "Lateef", "image": "Lateef.png", "fileName": "Lateef", "lineHeight": 1.44  },
            { "fontFamily": "Lato", "regular": "Lato-Regular", "bold": "Lato-Bold", "italic": "Lato-Italic", "boldItalic": "Lato-BoldItalic", "image": "lato.png", "fileName": "Lato.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "LeagueSpartan", "regular": "LeagueSpartan-Regular", "image": "LeagueSpartan.png", "fileName": "LeagueSpartan.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Lemon", "regular": "Lemon-Regular", "image": "Lemon.png", "fileName": "Lemon.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.31 },
            { "fontFamily": "LindenHill", "regular": "LindenHill-Regular", "image": "LindenHill.png", "fileName": "LindenHill.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.15 },
            { "fontFamily": "Lobster", "regular": "Lobster-Regular", "image": "lobster.png", "fileName": "Lobster.css", "lineHeight": 1.49 },
            { "fontFamily": "Lora", "regular": "Lora-Regular", "bold": "Lora-Bold", "italic": "Lora-Italic", "boldItalic": "Lora-BoldItalic", "image": "lora.png", "fileName": "Lora.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.5 },
            { "fontFamily": "Luckiest Guy", "regular": "LuckiestGuy-Regular", "image": "luckiest_guy.png", "fileName": "Luckiest Guy.css", "lineHeight": 1.23 },
            { "fontFamily": "Lusitana", "regular": "Lusitana-Regular", "bold": "Lusitana-Bold", "image": "Lusitana.png", "fileName": "Lusitana.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.3 },
            { "fontFamily": "MPLUSRounded1c", "regular": "RoundedMplus1c-Regular", "bold": "RoundedMplus1c-Bold", "image": "MPLUSRounded1c.png", "fileName": "MPLUSRounded1c.css", "isBold": true, "lineHeight": 1.30 },
            { "fontFamily": "MajorMonoDisplay", "regular": "MajorMonoDisplay-Regular", "image": "MajorMonoDisplay.png", "fileName": "MajorMonoDisplay.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.38 },
            { "fontFamily": "Manjari", "regular": "Manjari-Regular", "bold": "Manjari-Bold", "image": "Manjari.png", "fileName": "Manjari.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.64 },
            { "fontFamily": "MedulaOne", "regular": "MedulaOne-Regular", "image": "MedulaOne.png", "fileName": "MedulaOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.01 },
            { "fontFamily": "MissFajardose", "regular": "MissFajardose-Regular", "image": "MissFajardose.png", "fileName": "MissFajardose.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "MochiyPopPOne", "regular": "MochiyPopPOne-Regular", "image": "MochiyPopPOne.png", "fileName": "MochiyPopPOne.css", "lineHeight": 1.44 },
            { "fontFamily": "Monoton", "regular": "Monoton-Regular", "image": "monoton.png", "fileName": "Monoton.css", "lineHeight": 1.56 },
            { "fontFamily": "MontaguSlab24pt", "regular": "MontaguSlab24pt-Regular", "bold": "MontaguSlab24pt-Bold", "image": "MontaguSlab24pt.png", "fileName": "MontaguSlab_24pt.css", "isBold": true, "lineHeight": 1.45 },
            { "fontFamily": "MontaguSlab36pt", "regular": "MontaguSlab36pt-Regular", "bold": "MontaguSlab36pt-Bold", "image": "MontaguSlab36pt.png", "fileName": "MontaguSlab_36pt.css", "isBold": true, "lineHeight": 1.45 },
            { "fontFamily": "MontaguSlab48pt", "regular": "MontaguSlab48pt-Regular", "bold": "MontaguSlab48pt-Bold", "image": "MontaguSlab48pt.png", "fileName": "MontaguSlab_48pt.css", "isBold": true, "lineHeight": 1.45 },
            { "fontFamily": "MontaguSlab96pt", "regular": "MontaguSlab96pt-Regular", "bold": "MontaguSlab96pt-Bold", "image": "MontaguSlab96pt.png", "fileName": "MontaguSlab_96pt.css", "isBold": true, "lineHeight": 1.45 },
            { "fontFamily": "MontaguSlab120pt", "regular": "MontaguSlab120pt-Regular", "bold": "MontaguSlab120pt-Bold", "image": "MontaguSlab120pt.png", "fileName": "MontaguSlab_120pt.css", "isBold": true, "lineHeight": 1.45 },
            { "fontFamily": "Montserrat", "regular": "Montserrat-Regular", "bold": "Montserrat-Bold", "italic": "Montserrat-Italic", "boldItalic": "Montserrat-BoldItalic", "image": "montserrat.png", "fileName": "Montserrat.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.38 },
            { "fontFamily": "Mountains of Christmas", "regular": "MountainsofChristmas-Regular", "bold": "MountainsofChristmas-Bold", "image": "mountains_of_christmas.png", "fileName": "Mountains of Christmas.css", "isBold": true, "lineHeight": 1.39 },
            { "fontFamily": "MrDafoe", "regular": "MrDafoe-Regular", "image": "MrDafoe.png", "fileName": "MrDafoe.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.4 },
            { "fontFamily": "Mrs Sheppards", "regular": "MrsSheppards-Regular", "image": "mrs_sheppards.png", "fileName": "Mrs Sheppards.css", "lineHeight": 1.34 },
            { "fontFamily": "MrsSaintDelafield", "regular": "MrsSaintDelafield-Regular", "image": "MrsSaintDelafield.png", "fileName": "MrsSaintDelafield.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.52 },
            { "fontFamily": "My Font", "regular": "MyFontRegular", "image": "MyFont.png", "fileName": "My Font.css", "lineHeight": 1.26 },
            { "fontFamily": "Neucha", "regular": "Neucha", "image": "Neucha.png", "fileName": "Neucha.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.17 },
            { "fontFamily": "7NeutralGrotesk", "regular": "NeutralGrotesk-Regular", "image": "7NeutralGrotesk.png", "fileName": "7NeutralGrotesk.css", "lineHeight": 1.2 },
            { "fontFamily": "NewRocker", "regular": "NewRocker-Regular", "image": "NewRocker.png", "fileName": "NewRocker.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.23 },
            { "fontFamily": "Niconne", "regular": "Niconne-Regular", "image": "Niconne.png", "fileName": "Niconne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.21 },
            { "fontFamily": "Norican", "regular": "Norican-Regular", "image": "Norican.png", "fileName": "Norican.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.41 },
            { "fontFamily": "Norwester", "regular": "Norwester-Regular", "image": "Norwester.png", "fileName": "Norwester.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1 },
            { "fontFamily": "Nosifer", "regular": "Nosifer-Regular", "image": "Nosifer.png", "fileName": "Nosifer.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.74 },
            { "fontFamily": "Notable", "regular": "Notable-Regular", "image": "Notable.png", "fileName": "Notable.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.57 },
            { "fontFamily": "Noto Sans", "regular": "NotoSans-Regular", "bold": "NotoSans-Bold", "italic": "NotoSans-Italic", "boldItalic": "NotoSans-BoldItalic", "image": "noto_sans.png", "fileName": "Noto Sans.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.37 },
            // { "fontFamily": "NotoSansArabic", "regular": "NotoSansArabic-Regular", "bold":"NotoSansArabic-Bold", "image": "NotoSansArabic.png", "fileName": "NotoSansArabic","isBold": true, "lineHeight": 1.18 },
            // { "fontFamily": "NotoSansArabic_Condensed", "regular": "NotoSansArabicCondensed-Regular", "bold":"NotoSansArabicCondensed-Bold", "image": "NotoSansArabic_Condensed.png", "fileName": "NotoSansArabic_Condensed","isBold": true, "lineHeight": 1.18  },
            // { "fontFamily": "NotoSansArabic_ExtraCondensed", "regular": "NotoSansArabicExtraCondensed-Regular", "bold":"NotoSansArabicExtraCondensed-Bold", "image": "NotoSansArabic_ExtraCondensed.png", "fileName": "NotoSansArabic_ExtraCondensed","isBold": true, "lineHeight": 1.18 },
            { "fontFamily": "NotoSansJP", "regular": "NotoSansJP-Regular", "bold": "NotoSansJP-Bold", "image": "NotoSansJP.png", "fileName": "NotoSansJP.css", "isBold": true, "lineHeight": 1.40 },
            { "fontFamily": "NotoSansKR", "regular": "NotoSansKR-Regular", "bold": "NotoSansKR-Bold", "image": "NotoSansKR.png", "fileName": "NotoSansKR.css", "isBold": true, "lineHeight": 1.40 },
            // { "fontFamily": "NotoSansArabic_SemiCondensed", "regular": "NotoSansArabicSemiCondensed-Regular", "bold":"NotoSansArabicSemiCondensed-Bold", "image": "NotoSansArabic_SemiCondensed.png", "fileName": "NotoSansArabic_SemiCondensed","isBold": true, "lineHeight": 1.18 },
            { "fontFamily": "NotoSerifJP", "regular": "NotoSerifJP-Regular", "bold": "NotoSerifJP-Bold", "image": "NotoSerifJP.png", "fileName": "NotoSerifJP.css", "isBold": true, "lineHeight": 1.40 },
            { "fontFamily": "NotoSerifKR", "regular": "NotoSerifKR-Regular", "bold": "NotoSerifKR-Bold", "image": "NotoSerifKR.png", "fileName": "NotoSerifKR.css", "isBold": true, "lineHeight": 1.40 },
            { "fontFamily": "NunitoSans", "regular": "NunitoSans-Regular", "bold": "NunitoSans-Bold", "image": "NunitoSans.png", "fileName": "NunitoSans.css", "isBold": true, "lineHeight": 1.42 },
            { "fontFamily": "Oleo Script", "regular": "OleoScript-Regular", "bold": "OleoScript-Bold", "image": "oleo_script.png", "fileName": "Oleo Script.css", "isBold": true, "lineHeight": 1.39 },
            { "fontFamily": "Open Sans", "regular": "OpenSans-Regular", "bold": "OpenSans-Bold", "italic": "OpenSans-Italic", "boldItalic": "OpenSans-BoldItalic", "image": "open_sans.png", "fileName": "Open Sans.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.3 },
            { "fontFamily": "Oswald", "regular": "Oswald-Regular", "bold": "Oswald-Bold", "image": "oswald.png", "fileName": "Oswald.css", "isBold": true, "lineHeight": 1.71 },
            { "fontFamily": "Oxygen", "regular": "Oxygen-Regular", "bold": "Oxygen-Bold", "image": "oxygen.png", "fileName": "Oxygen.css", "isBold": true, "lineHeight": 1.27 },
            { "fontFamily": "PT Sans", "regular": "PTSans-Regular", "bold": "PTSans-Bold", "italic": "PTSans-Italic", "boldItalic": "PTSans-BoldItalic", "image": "pt_sans.png", "fileName": "PT Sans.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.3 },
            { "fontFamily": "Pacifico", "regular": "Pacifico-Regular", "image": "pacifico.png", "fileName": "Pacifico.css", "lineHeight": 1.94 },
            { "fontFamily": "PatrickHand", "regular": "PatrickHand-Regular", "image": "PatrickHand.png", "fileName": "PatrickHand.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.35 },
            { "fontFamily": "Paytone One", "regular": "PaytoneOne-Regular", "image": "paytone_one.png", "fileName": "Paytone One.css", "lineHeight": 1.58 },
            { "fontFamily": "Permanent Marker", "regular": "PermanentMarker-Regular", "image": "permanent_marker.png", "fileName": "Permanent Marker.css", "lineHeight": 1.37 },
            { "fontFamily": "Plaster", "regular": "Plaster-Regular", "image": "Plaster.png", "fileName": "Plaster.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Playfair Display SC", "regular": "PlayfairDisplaySC-Regular", "bold": "PlayfairDisplaySC-Bold", "italic": "PlayfairDisplaySC-Italic", "boldItalic": "PlayfairDisplaySC-BoldItalic", "image": "playfair_display_sc.png", "fileName": "Playfair Display SC.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.46 },
            { "fontFamily": "Poiret One", "regular": "PoiretOne-Regular", "image": "poiret_one.png", "fileName": "Poiret One.css", "lineHeight": 1.17 },
            { "fontFamily": "Prompt", "regular": "Prompt-Regular", "bold": "Prompt-Bold", "image": "Prompt.png", "fileName": "Prompt.css", "isBold": true, "lineHeight": 1.31 },
            { "fontFamily": "Quicksand", "regular": "Quicksand-Regular", "bold": "Quicksand-Bold", "image": "quicksand.png", "fileName": "Quicksand.css", "isBold": true, "lineHeight": 1.49 },
            { "fontFamily": "RacingSansOne", "regular": "RacingSansOne-Regular", "image": "RacingSansOne.png", "fileName": "RacingSansOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.26 },
            { "fontFamily": "Radley", "regular": "Radley-Regular", "italic": "Radley-Italic", "image": "radley.png", "fileName": "Radley.css", "isItalic": true, "lineHeight": 1.27 },
            { "fontFamily": "Raleway", "regular": "Raleway-Regular", "bold": "Raleway-Bold", "italic": "Raleway-Italic", "boldItalic": "Raleway-BoldItalic", "image": "raleway.png", "fileName": "Raleway.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.18 },
            { "fontFamily": "RammettoOne", "regular": "RammettoOne-Regular", "image": "RammettoOne.png", "fileName": "RammettoOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.69 },
            { "fontFamily": "RampartOne", "regular": "RampartOne-Regular", "image": "RampartOne.png", "fileName": "RampartOne.css", "lineHeight": 1.44 },
            { "fontFamily": "Rasa", "regular": "Rasa-Regular", "bold": "Rasa-SemiBold", "image": "Rasa.png", "fileName": "Rasa.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1 },
            { "fontFamily": "ReemKufi", "regular": "ReemKufi-Regular", "image": "ReemKufi.png", "fileName": "ReemKufi.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.5 },
            { "fontFamily": "Ribeye", "regular": "Ribeye-Regular", "image": "ribeye.png", "fileName": "Ribeye.css", "lineHeight": 1.37 },
            { "fontFamily": "RibeyeMarrow", "regular": "RibeyeMarrow-Regular", "image": "RibeyeMarrow.png", "fileName": "RibeyeMarrow.css", "lineHeight": 1.36 },
            { "fontFamily": "Righteous", "regular": "Righteous-Regular", "image": "righteous.png", "fileName": "Righteous.css", "lineHeight": 1.25 },
            { "fontFamily": "Roboto", "regular": "Roboto-Regular", "bold": "Roboto-Bold", "italic": "Roboto-Italic", "boldItalic": "Roboto-BoldItalic", "image": "roboto.png", "fileName": "Roboto.css", "isBold": true, "isItalic": true, "isBoldItalic": false, "lineHeight": 1.16 },
            { "fontFamily": "RocknRollOne", "regular": "RocknRollOne-Regular", "image": "RocknRollOne.png", "fileName": "RocknRollOne.css", "lineHeight": 1.44 },
            { "fontFamily": "Rowdies", "regular": "Rowdies-Regular", "bold": "Rowdies-Bold", "image": "Rowdies.png", "fileName": "Rowdies.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.95 },
            { "fontFamily": "RumRaisin", "regular": "RumRaisin-Regular", "image": "RumRaisin.png", "fileName": "RumRaisin.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.29 },
            { "fontFamily": "RussoOne", "regular": "RussoOne-Regular", "image": "RussoOne.png", "fileName": "RussoOne.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.21 },
            { "fontFamily": "Rye", "regular": "Rye-Regular", "image": "rye.png", "fileName": "Rye.css", "lineHeight": 1.25 },
            { "fontFamily": "Sacramento", "regular": "Sacramento-Regular", "image": "Sacramento.png", "fileName": "Sacramento.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.46 },
            { "fontFamily": "Saira", "regular": "Saira-Regular", "bold": "Saira-Bold", "image": "Saira.png", "fileName": "Saira.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.57 },
            { "fontFamily": "ScheherazadeNew", "regular": "ScheherazadeNew-Regular", "bold": "ScheherazadeNew-Bold", "image": "ScheherazadeNew.png", "fileName": "ScheherazadeNew.css", "isBold": true, "lineHeight": 2 },
            { "fontFamily": "Sedgwick Ave Display", "regular": "SedgwickAveDisplay-Regular", "image": "sedgwick_ave_display.png", "fileName": "Sedgwick Ave Display.css", "lineHeight": 3.11 },
            { "fontFamily": "ShadowsIntoLight", "regular": "ShadowsIntoLight", "image": "ShadowsIntoLight.png", "fileName": "ShadowsIntoLight.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.6 },
            { "fontFamily": "Shojumaru", "regular": "Shojumaru-Regular", "image": "Shojumaru.png", "fileName": "Shojumaru.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.32 },
            { "fontFamily": "Shrikhand", "regular": "Shrikhand-Regular", "image": "Shrikhand.png", "fileName": "Shrikhand.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.46 },
            { "fontFamily": "SignikaNegative", "regular": "SignikaNegative-Regular", "bold": "SignikaNegative-Bold", "image": "SignikaNegative.png", "fileName": "SignikaNegative.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.23 },
            { "fontFamily": "SixCaps", "regular": "SixCaps", "image": "SixCaps.png", "fileName": "SixCaps.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.32 },
            { "fontFamily": "Skranji", "regular": "Skranji-Regular", "bold": "Skranji-Bold", "image": "Skranji.png", "fileName": "Skranji.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.36 },
            { "fontFamily": "Slabo13px", "regular": "Slabo13px-Regular", "image": "Slabo13px.png", "fileName": "Slabo13px.css", "lineHeight": 1.23 },
            { "fontFamily": "Slabo27px", "regular": "Slabo27px-Regular", "image": "Slabo27px.png", "fileName": "Slabo27px.css", "lineHeight": 1.22 },
            { "fontFamily": "Sora", "regular": "Sora-Regular", "bold": "Sora-Bold", "image": "Sora.png", "fileName": "Sora.css", "isBold": true, "lineHeight": 1.54 },
            { "fontFamily": "Source Serif Pro", "regular": "SourceSerifPro-Regular", "bold": "SourceSerifPro-Bold", "image": "source_serif_pro.png", "fileName": "Source Serif Pro.css", "isBold": true, "lineHeight": 1.26 },
            { "fontFamily": "SourceSansPro", "regular": "SourceSansPro-Regular", "bold": "SourceSansPro-Bold", "image": "SourceSansPro.png", "fileName": "SourceSansPro.css", "isBold": true, "lineHeight": 1.25 },
            { "fontFamily": "SpaceGrotesk", "regular": "SpaceGrotesk-Regular", "bold": "SpaceGrotesk-Bold", "image": "SpaceGrotesk.png", "fileName": "SpaceGrotesk.css", "isBold": true, "lineHeight": 1.34 },
            { "fontFamily": "Spartan", "regular": "Spartan-Regular", "bold": "Spartan-Bold", "image": "Spartan.png", "fileName": "Spartan.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "SpectralSC", "regular": "SpectralSC-Regular", "bold": "SpectralSC-Bold", "image": "SpectralSC.png", "fileName": "SpectralSC.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.52 },
            { "fontFamily": "SpicyRice", "regular": "SpicyRice-Regular", "image": "SpicyRice.png", "fileName": "SpicyRice.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.38 },
            { "fontFamily": "Staatliches", "regular": "Staatliches-Regular", "image": "Staatliches.png", "fileName": "Staatliches.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.31 },
            { "fontFamily": "SulphurPoint", "regular": "SulphurPoint-Regular", "bold": "SulphurPoint-Bold", "image": "SulphurPoint.png", "fileName": "SulphurPoint.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 0.75 },
            { "fontFamily": "Sunday", "regular": "Sunday-Regular", "image": "Sunday.png", "fileName": "Sunday.css", "lineHeight": 1.2 },
            { "fontFamily": "Tajawal", "regular": "Tajawal-Regular", "image": "Tajawal.png", "fileName": "Tajawal.css", "lineHeight": 1.19 },
            { "fontFamily": "Take Cover", "regular": "TakeCover", "image": "TakeCover.png", "fileName": "Take Cover.css", "lineHeight": 0.94 },
            { "fontFamily": "Tangerine", "regular": "Tangerine-Regular", "bold": "Tangerine-Bold", "image": "Tangerine.png", "fileName": "Tangerine.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.05 },
            { "fontFamily": "Thasadith", "regular": "Thasadith-Regular", "bold": "Thasadith-Bold", "image": "Thasadith.png", "fileName": "Thasadith.css", "isBold": true, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.67 },
            { "fontFamily": "TradeWinds", "regular": "TradeWinds-Regular", "image": "TradeWinds.png", "fileName": "TradeWinds.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.45 },
            { "fontFamily": "TrainOne", "regular": "TrainOne-Regular", "image": "TrainOne.png", "fileName": "TrainOne.css", "lineHeight": 1.44 },
            { "fontFamily": "Ultra", "regular": "Ultra-Regular", "image": "ultra.png", "fileName": "Ultra.css", "lineHeight": 1.29 },
            { "fontFamily": "Uncial Antiqua", "regular": "UncialAntiqua-Regular", "image": "uncial_antiqua.png", "fileName": "Uncial Antiqua.css", "lineHeight": 1.32 },
            { "fontFamily": "VastShadow", "regular": "VastShadow-Regular", "image": "VastShadow.png", "fileName": "VastShadow.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.25 },
            { "fontFamily": "Vibes", "regular": "Vibes-Regular", "image": "Vibes.png", "fileName": "Vibes.css", "lineHeight": 1.75 },
            // { "fontFamily": "Vibur", "regular": "Vibur", "image": "Vibur.png", "fileName": "Vibur.css", "lineHeight": 1.35  },
            { "fontFamily": "Vidaloka", "regular": "Vidaloka-Regular", "image": "vidaloka.png", "fileName": "Vidaloka.css", "lineHeight": 1.22 },
            { "fontFamily": "Viga", "regular": "Viga-Regular", "image": "Viga.png", "fileName": "Viga.css", "isBold": false, "isItalic": false, "isBoldItalic": false, "lineHeight": 1.34 },
            { "fontFamily": "Work Sans", "regular": "WorkSans-Regular", "bold": "WorkSans-Bold", "image": "work_sans.png", "fileName": "Work Sans.css", "isBold": true, "lineHeight": 1.18 },
            { "fontFamily": "Yeseva One", "regular": "YesevaOne-Regular", "image": "yeseva_one.png", "fileName": "Yeseva One.css", "lineHeight": 1.39 },
            { "fontFamily": "ZenAntique", "regular": "ZenAntique-Regular", "image": "ZenAntique.png", "fileName": "ZenAntique.css", "lineHeight": 1.44 },
        ]
    }
});

export const libraryTextFonts = fromJS({
    heading: {
        // render styles for library
        style: "bold",
        fontSize: 24,
        // data used by library-helper
        id: "heading",
        type: "TEXT",
        subType: "DTXT",
        dropType: "heading",
        // actual font data
        data: Poppins,
    },
    subHeading: {
        // render styles for library
        style: "regular",
        fontSize: 18,
        // data used by library-helper
        id: "subHeading",
        type: "TEXT",
        subType: "DTXT",
        dropType: "subHeading",
        // actual font data
        data: Poppins,
    },
    body: {
        // render styles for library
        style: "regular",
        fontSize: 15,
        // data used by library-helper
        id: "body",
        type: "TEXT",
        subType: "DTXT",
        dropType: "body",
        // actual font data
        data: Poppins,
    },
    bullet: {
        // render styles for library
        style: "regular",
        fontSize: 15,
        // data used by library-helper
        id: "bullet",
        type: "TEXT",
        subType: "DTXT",
        dropType: "bullet",
        // actual font data
        data: Poppins,
    },
});

// to avoid unnecessary looping in preloadTextFonts for each text item
export const ffFontStyleGrouped = fromJS({
    "regular": fontFamilies
        .getIn(["default", "fonts"])
        .groupBy((font) => font.get("regular"))
        .delete(undefined),
    "bold": fontFamilies
        .getIn(["default", "fonts"])
        .groupBy((font) => font.get("bold"))
        .delete(undefined),
    "italic": fontFamilies
        .getIn(["default", "fonts"])
        .groupBy((font) => font.get("italic"))
        .delete(undefined),
    "boldItalic": fontFamilies
        .getIn(["default", "fonts"])
        .groupBy((font) => font.get("boldItalic"))
        .delete(undefined),
});

export const fontSizes = [6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 30, 32, 36, 38, 40, 44, 48, 50, 56, 60, 68, 72, 76, 80, 84, 90, 96, 104, 120, 140];