import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import { updateTimelineTime } from "../../../redux/actions/timelineUtils";
import GreenScreen from "./green-screen";

const ImageSection = () => {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.app.get("selectedItems"));
  const projectData = useSelector((state) => state.projectDetails);
  const item = projectData.getIn(["workspaceItems", selectedItems.get(0)]);

  const [isChromaRemoval, setIsChromaRemoval] = useState(false);
  const [chromaKey, setChromaKey] = useState(null);
  const [tolerance, setTolerance] = useState(15);
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);

  useEffect(() => {
    setIsChromaRemoval(!!item.get("chromaKey"));
    setChromaKey(item.get("chromaKey"));
    setTolerance(
      typeof item.get("tolerance") === "number" ? item.get("tolerance") / 2 : 15
    );
  }, [item]);

  const dispatcher = (toUpdate) => {
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const handleTolerance = (e) => {
    const value = parseFloat(e.target.value);
    setTolerance(value);
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        tolerance: value * 2,
      },
    });
    dispatcher(toUpdate);
  };

  const onFocus = () => {
    dispatch(setWorkspaceTextFocus(true));
  };

  const onBlur = (e) => {
    dispatch(setWorkspaceTextFocus(false));
    handleTolerance(e);
  };

  const toggleColorPalette = () => {
    setIsShowColorPicker(!isShowColorPicker);
  };

  const colorSelectionHandler = (color) => {
    setChromaKey(color);
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        chromaKey: color,
      },
    });
    dispatcher(toUpdate);
  };

  const toggleCheckBox = (isChecked) => {
    const toUpdate = [];
    setIsChromaRemoval(isChecked);
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        chromaKey: isChecked ? chromaKey || "#00FF00" : null,
        tolerance: isChecked ? tolerance * 2 : null,
      },
    });
    dispatcher(toUpdate);
  };

  const onPickerClick = () => {
    setChromaKey("#00FF00");
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        chromaKey: "#00FF00",
      },
    });
    dispatcher(toUpdate);
  };

  return (
    <GreenScreen
      isChromaRemoval={isChromaRemoval}
      toggleCheckBox={toggleCheckBox}
      chromaKey={chromaKey}
      toggleColorPalette={toggleColorPalette}
      tolerance={tolerance}
      handleTolerance={handleTolerance}
      isShowColorPicker={isShowColorPicker}
      colorSelectionHandler={colorSelectionHandler}
      onPickerClick={onPickerClick}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default ImageSection;
