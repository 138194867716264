import styled from "styled-components";
import { font } from "../../constants/font";
import { scaleWithinRange } from "../timeline/timeline-helper";

export const ZoomContainer = styled.div.attrs((props) => {
  return {
    style: {
      borderColor: props.$isOpen && props.theme.polarColor,
    },
  };
})`
  justify-self: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 2;
  height: 30px;
  background-color: ${(props) => props.theme.zoomContainerBgColor};
  border: 1px solid ${(props) => props.theme.zoomContainerBorder};
  border-radius: 16px;
  cursor: pointer;

  > img {
    display: inline-block;
    width: 8px;
    height: 8px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
ZoomContainer.displayName = "ZoomContainer";

export const ZoomSliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: 1px 3px 8px rgb(90 90 90 / 16%);
  cursor: auto;

  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 17px);

  background-color: ${(props) => props.theme.polarColor};
`;
ZoomSliderContainer.displayName = "ZoomSliderContainer";

const sliderDefaultValues = {
  height: "3px",
  width: "130px",
  borderRadius: "6px",
  margin: "0 0 0 10px",
  background: "rgba(255, 205, 0, 1)",
};
const sliderDefaultThumbValues = {
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  border: "1px solid rgba(22, 22, 31, 1)",
  cursor: "pointer",
  background: "rgba(255, 255, 255, 1)",
};
const sliderHover = {
  transform: "scale(1)",
  border: "1px solid rgba(22, 22, 31, 1)",
  background: "rgba(255, 255, 255, 1)",
};

export const InputSliderContainer = styled.div.attrs((props) => {
  let { className = "" } = props;
  const style = {};

  if (props.showToolTip) {
    className = `${className} input-slider--tooptip`;
    style["--tooltip-content"] = `"${props.value}"`;

    const value = parseFloat(props.value);
    const percent = scaleWithinRange({
      fromRange: { start: props.min, end: props.max },
      toRange: { start: 0, end: 100 },
      num: value,
    });
    style["--tooltip-left"] = `${percent}%`;

    let thumbWidth = parseFloat(sliderDefaultThumbValues.width);
    if (props.thumb && props.thumb.width) {
      thumbWidth = parseFloat(props.thumb.width);
    }
    if (!Number.isFinite(thumbWidth)) {
      thumbWidth = parseFloat(sliderDefaultThumbValues.width);
    }

    const offset = -scaleWithinRange({
      fromRange: { start: 0, end: 100 },
      toRange: { start: -thumbWidth / 2, end: thumbWidth / 2 },
      num: percent,
    });
    style["--tooltip-loffset"] = `translateX(calc(${offset}px - 50%))`;

    if (props.tooltipBottomOffset !== undefined) {
      style["--tooltip-boffset"] = props.tooltipBottomOffset;
    } else {
      style["--tooltip-boffset"] = "7px";
    }
  }

  return {
    className,
    style,
  };
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.input-slider--tooptip:hover {
    &::after {
      content: var(--tooltip-content, " ");

      position: absolute;
      bottom: calc(var(--tooltip-boffset) + 9px);
      left: var(--tooltip-left, 50%);
      transform: var(--tooltip-loffset, translateX(-50%));
      z-index: 2;

      font: ${font.normalMicro};
      color: ${(props) => props.theme.sliderTextColor};
      background: ${(props) => props.theme.lightPrimaryColor} 0 0 no-repeat
        padding-box;
      text-align: center;

      min-width: 23px;
      min-height: 22px;
      padding: 3px;
      border: 1px solid ${(props) => props.theme.primaryColor};
      border-radius: 4px;
      box-sizing: border-box;
    }

    &::before {
      content: "";

      position: absolute;
      bottom: var(--tooltip-boffset);
      left: var(--tooltip-left, 50%);
      transform: var(--tooltip-loffset, translateX(-50%));
      z-index: 2;

      border: solid 5px;
      border-color: ${(props) => props.theme.primaryColor} transparent
        transparent;
    }
  }
`;
InputSliderContainer.displayName = "InputSliderContainer";

export const InputSlider = styled.input.attrs((props) => {
  const style = {
    "--width": props.width,
    "--height": props.height,
    "--background": props.background,
    "--border-radius": props.borderRadius,
    "--margin": props.margin,
  };

  if (props.thumb) {
    style["--thumb-width"] = props.thumb.width;
    style["--thumb-height"] = props.thumb.height;
    style["--thumb-background"] = props.thumb.background;
    style["--thumb-cursor"] = props.thumb.cursor;
    style["--thumb-border-radius"] = props.thumb.borderRadius;
    style["--thumb-border"] = props.thumb.border;

    style["--thumb-hover-background"] = props.thumb.hoverBG;
    style["--thumb-hover-border"] = props.thumb.hoverBorder;
  }

  if (props.hover) {
    style["--thumb-hover-transform"] = props.hover.transform;
  }

  return {
    type: "range",
    style,
  };
})`
  -webkit-appearance: none;
  appearance: none;
  touch-action: none;

  background: var(--background, ${sliderDefaultValues.background});
  height: var(--height, ${sliderDefaultValues.height});
  width: var(--width, ${sliderDefaultValues.width});
  border-radius: var(--border-radius, ${sliderDefaultValues.borderRadius});
  margin: var(--margin, ${sliderDefaultValues.margin});

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumb-width, ${sliderDefaultThumbValues.width});
    height: var(--thumb-height, ${sliderDefaultThumbValues.height});
    background: var(--thumb-background, ${sliderDefaultThumbValues.background});
    cursor: var(--thumb-cursor, ${sliderDefaultThumbValues.cursor});
    border-radius: var(
      --thumb-border-radius,
      ${sliderDefaultThumbValues.borderRadius}
    );
    border: var(--thumb-border, ${sliderDefaultThumbValues.border});
  }

  &::-webkit-slider-thumb:hover {
    transform: var(--thumb-hover-transform, ${sliderHover.transform});
    background: var(--thumb-hover-background, ${sliderHover.background});
    border: var(--thumb-hover-border, ${sliderHover.border});
  }
`;
InputSlider.displayName = "InputSlider";

export const ValueContainerInput = styled.input.attrs((props) => {
  const style = {};

  if (props.input) {
    style["--width"] = props.input.width;
  }

  return {
    style,
  };
})`
  border: 0.5px solid rgba(229, 229, 229, 1);
  width: var(--width, 45px);
  height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: text;
  border-radius: 3px;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
ValueContainerInput.displayName = "ValueContainerInput";

export const FitButton = styled.div`
  width: 45px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.theme.loaderBgFullWhite};
  border-radius: 3px;
  background-color: ${(props) =>
    props.isFitValue ? "transparent" : props.theme.secondaryBorderColor};
  transform: ${(props) => (props.isFitValue ? `scale(1.01)` : `scale(1)`)};
  cursor: pointer;
`;

FitButton.displayName = "FitButton";
