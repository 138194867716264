import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const LabelComponent = styled.label`
  user-select: ${(props) => props.userSelect};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  float: ${(props) => props.float};
  cursor: ${(props) => props.cursor};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  font-variant-ligatures: common-ligatures;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  ${(props) =>
    props.enableHover &&
    css`
      &:hover {
        color: ${props.hoverColor || props.theme.polarColor};
      }
    `}
  ${(props) =>
    props.enableTrim &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: ${props.textWidth ? props.textWidth : props.width};
    `}
  ${(props) =>
    props.beforeContent &&
    css`
      &::before {
        content: url(${props.beforeContent});
        ${props.beforeContentStyles};
      }
    `}
  ${(props) =>
    props.isNone &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.verticalAlign &&
    css`
      vertical-align: ${props.verticalAlign};
    `}
  ${(props) =>
    props.font &&
    css`
      font: ${(props) => props.font};
    `}
  ${(props) =>
    props.textStyles &&
    css`
      ${props.textStyles}
    `}
`;

const AnchorComponent = styled.a`
  user-select: ${(props) => props.userSelect};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  float: ${(props) => props.float};
  cursor: ${(props) => props.cursor};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  font-variant-ligatures: common-ligatures;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  ${(props) =>
    props.enableTrim &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: ${props.textWidth ? props.textWidth : props.width};
    `}
  ${(props) =>
    props.isNone &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.font &&
    css`
      font: ${(props) => props.font};
    `}
`;

const ParentComponent = (props) =>
  props.isAnchor ? (
    <AnchorComponent {...props}>{props.children}</AnchorComponent>
  ) : (
    <LabelComponent {...props}>{props.children}</LabelComponent>
  );

const Typography = (props) => {
  const theme = useSelector((state) => state.app.get("theme"));
  const { primaryColor } = vmTheme[theme];
  return props.dangerouslySetInnerHTML ? (
    <ParentComponent {...props} />
  ) : (
    <ParentComponent {...props}>
      {props.content ? (
        <FormattedMessage
          id={props.content}
          color={props.color ? props.color : primaryColor}
        />
      ) : (
        props.innerContent
      )}
      {props.children}
    </ParentComponent>
  );
};

Typography.defaultProps = {
  display: "inline",
  margin: "0px",
  padding: "0px",
  align: "initial",
  float: "initial",
  cursor: "default",
  userSelect: "auto",
  onClick: () => {},
  name: "",
  maxWidth: "auto",
  width: "auto",
  isVisible: true,
  enableTrim: true,
  isAnchor: false,
  enableHover: false,
  isNone: false,
  font: font.normal,
};

Typography.propTypes = {
  font: PropTypes.string,
  userSelect: PropTypes.string,
  children: PropTypes.element,
  content: PropTypes.string,
  display: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  float: PropTypes.string,
  cursor: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  enableTrim: PropTypes.bool,
  isAnchor: PropTypes.bool,
  textWidth: PropTypes.string,
  enableHover: PropTypes.bool,
  hoverColor: PropTypes.string,
  innerContent: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.object,
  isNone: PropTypes.bool,
  textStyles: PropTypes.object,
};

ParentComponent.propTypes = {
  isAnchor: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element) || PropTypes.element,
};

export default Typography;
