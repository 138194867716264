import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Audio, AudioContainer, DotContainer } from "./upload-panel-components";
import { STATIC_PATH } from "../../../../constants/config";
import DotMenu from "../../../../common-components/DotMenu";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";
import vmTheme from "../../../../constants/theme";

export const AudioFile = ({
  containerRef,
  className,
  fileMeta,
  subMenuOptions,
  disableFileActions,
  onTileClick,
  onPointerDown,
  style,
  isDisable,
}) => {
  const { name } = fileMeta;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useSelector((state) => state.app.get("theme"));
  return (
    <AudioContainer
      ref={containerRef}
      className={className}
      onPointerDown={onPointerDown}
      onClick={onTileClick}
      isDisable={isDisable}
      style={{
        ...style,
        zIndex: isOpen ? 9 : 1,
      }}
    >
      <Audio>
        <img
          src={`${STATIC_PATH}audio.svg`}
          width="89px"
          height="89px"
          alt="audio"
          draggable={false}
        />
        {!disableFileActions ? (
          <DotContainer data-lib-ignore={true}>
            <DotMenu
              icon={`${STATIC_PATH}dot-menu.svg`}
              subMenu={subMenuOptions.subMenu}
              onSelect={(key) =>
                subMenuOptions.subMenuAction(fileMeta, key, fileMeta.id, "file")
              }
              style={{ margin: "6px 0 0 0 ", inset: "14px 0px auto auto" }}
              onOpen={(a) => setIsOpen(a)}
            />
          </DotContainer>
        ) : (
          ""
        )}
      </Audio>
      <Typography
        innerContent={name}
        color={vmTheme[theme].panelPrimaryColor}
        font={font.normalMini}
        display="block"
        enableTrim={true}
      />
    </AudioContainer>
  );
};

AudioFile.propTypes = {
  fileMeta: PropTypes.object,
  disableFileActions: PropTypes.bool,
  subMenuOptions: PropTypes.object,
  className: PropTypes.string,
  containerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  onTileClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  isDisable: PropTypes.bool,
};
