/* eslint-disable react/prop-types, import/no-extraneous-dependencies */
import { useState, useEffect } from "react";

const imageCache = Object.create(null);

const ImagePrimitive = ({
  src,
  canLoad = true,
  onChange = () => {},
  render,
  children,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(undefined);

  const getRenderProps = () => {
    const renderProps = {
      src,
      loaded,
      pending,
      error,
    };

    if (imageCache[src]) {
      renderProps.width = imageCache[src].width;
      renderProps.height = imageCache[src].height;
    }

    return renderProps;
  };

  const update = (newLoaded, newPending, newError) => {
    setLoaded(newLoaded);
    setPending(newPending);
    setError(newError);
    onChange(getRenderProps());
  };

  const onUpdate = () => {
    const alreadyLoaded = src && imageCache[src] !== undefined;

    if (alreadyLoaded) {
      update(true, false, undefined);
    } else if (!canLoad || !src) {
      update(false, false, undefined);
    } else {
      update(false, true, undefined);
      const loadImage = require("load-img");
      const img = loadImage(src, (loadError) => {
        if (!loadError) {
          imageCache[src] = img;
        }

        if (src === getRenderProps().src) {
          update(!loadError, false, loadError);
        }
      });
    }
  };

  useEffect(() => {
    onUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, canLoad]);

  return (render || children)(getRenderProps());
};

export default ImagePrimitive;
