import styled, { css } from "styled-components";
import { font } from "../../../../constants/font";
import { STATIC_PATH } from "../../../../constants/config";

export const UploadHead = styled.div`
  position: relative;
  z-index: 1;
`;

export const MyFileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  outline: 0;
  box-sizing: border-box;
  padding: ${(props) => props.padding || "0px"};
  .applyModal{
    font: ${font.mediumLarge_27};
  }
`;

export const FolderWrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
`;

const DotContainer = styled.div`
  display: none;
  height: 24px;
  width: 20px;
  position: absolute;
  top: 4px;
  color: white;
  right: 4px;
`;

const CheckBoxContainer = styled.div`
  display: none;
  height: 24px;
  width: 20px;
  position: absolute;
  top: 4px;
  color: white;
  left: 4px;
`;

const AudioContainer = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  transition: ease 0.5s;
  width: 152px;
  &:hover {
    transform: scale(1.05);
  }
  img {
    object-fit: contain;
    object-position: center;
    max-height: 80px;
    width: 100%;
    margin: auto;
  }

  ${(props) =>
    props.isDisable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const ProgressContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  background-color: ${props => props.theme.progressContainerBg};
  transition: all 0.5s ease 0s;
  border-radius: 4px;
  margin-bottom: 12px;
  &:hover {
    transform: scale(1.05);
  }
  svg {
    position: absolute;
    margin: 12px 0px 0px 20px;
    transform-origin: 50% 50%;
    transform: rotate(270deg);
  }
  label {
    position: absolute;
    bottom: 8px;
    left: 40px;
  }
`;

const Percentage = styled.div`
  position: absolute;
  margin: 50px 0px 0px 62px;
  font: ${font.normalMini};
  color: ${props => props.theme.propertyColor};
`;

const Image = styled.img`
  height: 100px;
  width: inherit;
  border-radius: 4px;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 150px;
  margin-bottom: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: ease 0.5s;
  video {
    height: fit-content;
    border-radius: 4px;
    display: none;
  }
  &:hover {
    transform: scale(1.05);
    ${DotContainer} {
      display: block;
    }
    ${CheckBoxContainer} {
      display: block;
    }
    ${(props) =>
      props.isVideoFile &&
      css`
        ${Image} {
          display: none;
        }
        video {
          display: block;
        }
      `}
  }
`;

const DragDropContainer = styled.div`
  position: absolute;
  padding: 12px;
  background: ${props => props.theme.blackColor} 0% 0% no-repeat padding-box;
  opacity: 0.72;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const DragDropArea = styled.div`
  border: 3px dashed ${props => props.theme.veryDarkGrayColor};
  border-radius: 8px;
  height: 100%;
`;

const OverlayContain = styled.div`
  position: absolute;
  display: ${(props) => props.display};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &:after {
    background: 0% 0% no-repeat padding-box padding-box ${props => props.theme.rgbaBlack};
    content: "";
    position: absolute;
    top: -50px;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    left: -65px;
  }
`;

const OverlaySectionWrap = styled.div`
  z-index: 2;
  width: 384px;
  height: auto;
  position: absolute;
  top: 148px;
  left: 448px;
  background: ${props => props.theme.polarColor} 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
`;

const CloseIcon = styled.img`
  float: right;
  width: 17px;
  height: 17px;
  margin: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;
const Wrap = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: end;
`;

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -50px;
  left: -65px;
  z-index: ${(props) => props.zIndex};
`;
const FolderSection = styled.div`
  max-height: 225px;
  min-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 767px) {
    touch-action: auto;
  }
`;
const HeaderWrap = styled.div`
  height: 65px;
`;

const FooterSection = styled.div`
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
`;

const NewFolder = styled.div`
  height: 48px;
  background-color: ${props => props.theme.panelBgColor};
  display: inline-block;
  padding: 5px 8px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.primaryBorderColor};
  margin: 10px 0 10px 0px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 50px;
  &:hover {
    border-color: ${props => props.theme.keyboardTextColor};
  }
  &:before {
    content: url(${STATIC_PATH}foldericon.svg);
    position: absolute;
    left: 12px;
    top: 10px;
  }
`;

const FolderLinks = styled.div`
  display: flex;
  ${(props) =>
    !props.showAnchor &&
    css`
      .folderAnchor {
        position: relative;
        padding-right: 20px;
        height: 18px;
        &:after {
          content: "";
          position: absolute;
          right: 8px;
          z-index: 11;
          display: block;
          width: 5px;
          height: 5px;
          top: 4px;
          border-bottom: 1px solid ${props => props.theme.secondaryColor};
          border-right: 1px solid ${props => props.theme.secondaryColor};
          transform: rotate(-45deg);
        }
        &:last-child() {
          &:after {
            content: "";
          }
        }
      }
    `}

  svg {
    cursor: pointer;
  }
`;
const DotWrap = styled.div`
  position: relative;
  top: -4px;
  cursor: pointer;
  padding-right: 20px;
  &::after {
    content: "...";
    left: 0px;
    background-color: ${props => props.theme.btnBgLightColor};
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-top: 2px;
    padding-top: 0px;
    height: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    right: 8px;
    z-index: 11;
    display: block;
    width: 5px;
    height: 5px;
    top: 6px;
    border-bottom: 1px solid ${props => props.theme.secondaryColor};
    border-right: 1px solid ${props => props.theme.secondaryColor};
    transform: rotate(-45deg);
  }
`;
const SectionWrapper = styled.div`
  position: relative;
`;
const SectionOption = styled.ul`
  background: ${props => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px ${props => props.theme.rgbaBlack};
  border: 1px solid ${props => props.theme.primaryBorderColor};
  border-radius: 4px;
  width: 125px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  margin: 0;
  list-style: none;
  padding: 0;
  transform: translate(-50px, 0px);
`;
const Links = styled.li`
  background: ${props => props.theme.polarColor} 0% 0% no-repeat padding-box;
  opacity: 1;
  text-decoration: none;
  .nav-anchor {
    display: block;
    padding: 12px;
    cursor: pointer;
    &:hover {
      background-color: ${props => props.theme.btnBgLightColor};
    }
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
`;

const Audio = styled.div`
  position: relative;
  width: 150px;
  height: 125px;
  max-height: 125px;
  background: ${props => props.theme.borderBgUpgrade};
  border-radius: 4px;
  box-shadow: 0 0 11px ${props => props.theme.rgbaBlack};
  display: flex;
  margin-bottom: 5px;

  &:hover {
    ${DotContainer} {
      display: block;
    }
  }
`;

const UploadProgress = styled.div`
  width: 100%;
  height: 40px;
  margin: 20px 0px;
`;

export const UploadWrapperContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export {
  AudioContainer,
  ProgressContainer,
  Percentage,
  Container,
  Image,
  DotContainer,
  CheckBoxContainer,
  DragDropContainer,
  DragDropArea,
  OverlayContain,
  OverlaySectionWrap,
  CloseIcon,
  Wrap,
  NewFolder,
  Footer,
  Backdrop,
  FolderSection,
  FolderLinks,
  SectionOption,
  Links,
  SectionWrapper,
  DotWrap,
  FooterSection,
  HeaderWrap,
  SpinnerContainer,
  Audio,
  UploadProgress,
};
