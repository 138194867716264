import { API, API_URL } from "../constants/config";
import triggerSimpleAjax from "../helper/httpHelper";


export const createVersion = (reqData) => {
  const apiUrl = `${API_URL}${API.CREATE_VERSION}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      reqData,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export const getVersionList = (projectId) => {
  const apiUrl = API_URL + API.GET_VERSION_LIST;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      { projectId },
      (response) => {
        const { error, data } = response;
        if (!error) {
          const obj = {};
          data.forEach(ele => {
            if  (obj[ele.key_as_string]) {
              obj[ele.key_as_string].hits.push(ele.hits[0])
            } else {
              obj[ele.key_as_string] = {
                ...ele
              }
            }
          });
          const arr = [];
          Object.keys(obj).map(key => arr.push(obj[[key]]));
          res({
            ...response,
            data: arr
          });
        }
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export const restoreVersion = (versionId, isPreview = false) => {
  const { RESTORE_VERSION, GET_VERSION } = API;
  const url = isPreview ? GET_VERSION : RESTORE_VERSION;
  const apiUrl = `${API_URL}${url}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      { versionId },
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
};