/* eslint-disable react/no-danger, jsx-a11y/no-static-element-interactions, prefer-template  */

import React, { Component } from "react";
import PropTypes from "prop-types";
import config from "../../constants/config";
import { TextContentEditable } from "./text-components";

class ContentEditable extends Component {
  constructor(props) {
    super(props);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.emitChange = this.emitChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
  }

  componentDidMount() {
    if (this.props.isEditable) {
      this.selectAll();
    }
  }

  shouldComponentUpdate(nextProps) {
    const currStyles = this.props.styles.remove("fontSize");
    const nextStyles = nextProps.styles.remove("fontSize");

    return (
      nextProps.html !== this.htmlContent.innerHTML ||
      !nextStyles.equals(currStyles) ||
      (this.props.styles.get("fontSize") !== nextProps.styles.get("fontSize") &&
        (
          parseFloat(this.htmlContent.style.fontSize, 10) / nextProps.zoomFactor
        ).toFixed(2) !==
          parseFloat(nextProps.styles.get("fontSize"), 10).toFixed(2)) ||
      nextProps.classes !== this.props.classes ||
      nextProps.zoomFactor !== this.props.zoomFactor ||
      nextProps.isEditable !== this.props.isEditable ||
      (this.props.fontStatus !== undefined &&
        !this.props.fontStatus.equals(nextProps.fontStatus)) ||
      nextProps.isReady !== this.props.isReady
    );
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.selectedAll ||
        prevProps.isEditable !== this.props.isEditable) &&
      this.props.isEditable
    ) {
      this.selectAll();
    }
    if (
      this.htmlContent &&
      this.props.html !== this.htmlContent.innerHTML &&
      !this.props.isPlay &&
      !this.props.isPlayAll
    ) {
      // Perhaps React (whose VDOM gets outdated because we often prevent
      // rerendering) did not update the DOM. So we update it manually now.
      this.htmlContent.innerHTML = this.props.html;
    }
  }

  handlePaste(e) {
    try {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      let pastedData;

      e.stopPropagation();

      if (!this.isBase64(clipboardData.getData("Text"))) {
        pastedData = clipboardData.getData("Text");
        pastedData = pastedData
          .replace(/<\/?div[^>]*>/g, "")
          .replace(/<\/?DIV[^>]*>/g, "")
          .replace(/<\/?span[^>]*>/g, "")
          .replace(/<\/?font[^>]*>/g, "")
          .replace(/<\/?SPAN[^>]*>/g, "")
          .replace(/<\/?FONT[^>]*>/g, "")
          .replace(/^\s+|\s+$/, "");
        const textNode = document.createTextNode(pastedData);
        const range = window.getSelection().getRangeAt(0);
        range.deleteContents();
        range.insertNode(textNode);
        range.collapse();
        this.emitChange(e);
      }
    } catch (error) {
      //
    }
  }

  handleMouseUp(e) {
    try {
      this.props.handleMouseUp(e);
    } catch (error) {
      //
    }
  }

  handleClick(e) {
    try {
      if (typeof this.props.handleClick === "function") {
        this.props.handleClick(e);
      }
    } catch (error) {
      //
    }
  }

  handleDoubleClick(e) {
    try {
      if (typeof this.props.handleDoubleClick === "function") {
        this.props.handleDoubleClick(e);
      }
    } catch (error) {
      //
    }
  }

  onKeyPress = (e) => {
    const { nativeEvent } = e;
    /**
     * @summary the below condition prevents inserting insertLineBreak (shift + enter)
     */
    if (nativeEvent.keyCode === 13) {
      if (nativeEvent.shiftKey === true) {
        if (!e) {
          e = window.event;
        }
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    }
  };

  handleKeyDown = (e) => {
    if (
      (e.nativeEvent.code === "KeyZ" || e.nativeEvent.code === "KeyY") &&
      (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)
    ) {
      this.props.clearSaveText();
    }
  };

  emitChange(e) {
    try {
      e.preventDefault();

      if (
        this.htmlContent.childNodes.length === 1 &&
        this.htmlContent.firstChild.localName === "br"
      ) {
        this.htmlContent.removeChild(this.htmlContent.firstChild);
      }

      const html = this.htmlContent.innerHTML;

      if (this.props.onChange && html !== this.props.html) {
        this.props.onChange({
          target: {
            value: html,
          },
          eventName: e.nativeEvent.inputType,
        });
      }
    } catch (error) {
      //
    }
  }

  selectAll() {
    let range;
    let selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(
        document
          .getElementById(this.props.textId)
          .getElementsByClassName("text-container")[0]
      );
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(
        document
          .getElementById(this.props.textId)
          .getElementsByClassName("text-container")[0]
      );
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  isBase64(str) {
    try {
      return (
        btoa(
          unescape(encodeURIComponent(decodeURIComponent(escape(atob(str)))))
        ) === str
      );
    } catch (err) {
      return false;
    }
  }

  render() {
    const styles = this.props.styles.toJS();

    styles.fontSize =
      (parseFloat(styles.fontSize, 10) * this.props.zoomFactor).toFixed(2) +
      "px";
    if (styles.margin !== undefined) {
      styles.margin =
        (parseFloat(styles.margin, 10) * this.props.zoomFactor).toFixed(2) +
        "px";
    }

    if (
      styles.letterSpacing !== undefined &&
      this.props.isBullet &&
      this.props.isOrderList
    ) {
      styles.paddingLeft =
        (parseFloat(styles.letterSpacing, 10) * 3).toFixed(2) + "em";
    }

    styles.fontFamily += ", gfs";
    if (!this.props.isReady) {
      styles.opacity = "0";
    }

    const loaderElement = !this.props.isReady ? (
      <div
        style={{
          backgroundImage: "url(" + config.WORKSPACE_LOADER + ")",
          marginRight: "0px",
          width: "15px",
          height: "15px",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    ) : null;

    let dir = "auto";

    if (this.props.otherOptions.get("isRTL")) {
      dir = "rtl";
      if (styles.letterSpacing !== undefined && this.props.isOrderList) {
        styles.paddingLeft = "0px";
        styles.paddingRight =
          (parseFloat(styles.letterSpacing, 10) * 3).toFixed(2) + "em";
      }
    }

    return (
      <>
        <TextContentEditable
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.onKeyPress}
          className={this.props.classes}
          ref={(e) => {
            this.htmlContent = e;
          }}
          onPaste={this.handlePaste}
          style={styles}
          contentEditable={this.props.isEditable}
          data-id={this.props.dataId}
          data-group-id={this.props.dataGroupId}
          dangerouslySetInnerHTML={{ __html: this.props.html }}
          dir={dir}
          onInput={this.emitChange}
          onDoubleClick={this.handleDoubleClick}
          onClick={this.handleClick}
          onMouseUp={this.handleMouseUp}
          onFocus={this.props.onFocusIn}
        />
        {loaderElement}
      </>
    );
  }
}

ContentEditable.propTypes = {
  styles: PropTypes.object,
  html: PropTypes.string,
  classes: PropTypes.string,
  zoomFactor: PropTypes.number,
  isEditable: PropTypes.bool,
  fontStatus: PropTypes.object,
  isReady: PropTypes.bool,
  selectedAll: PropTypes.bool,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  handleMouseUp: PropTypes.func,
  handleClick: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  clearSaveText: PropTypes.func,
  onChange: PropTypes.func,
  textId: PropTypes.string,
  isBullet: PropTypes.bool,
  isOrderList: PropTypes.bool,
  otherOptions: PropTypes.object,
  dataId: PropTypes.string,
  dataGroupId: PropTypes.string,
  onFocusIn: PropTypes.func,
};

export default ContentEditable;
