import React from "react";
import useMobileDetection from "../hooks/useMobileDetection";

const mobileDetection = (Component) => {
    const MobileDetectionWrapper = (props) => {
      const isMobile = useMobileDetection();
      return <Component isMobile={isMobile} {...props} />;
    };

    MobileDetectionWrapper.displayName = `MobileDetection(${Component.displayName || Component.name || "Component"})`;
  
    return MobileDetectionWrapper;
  };

export default mobileDetection;