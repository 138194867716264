import { PLAN } from "../constants"

const isPremiumUser = (plan) => {
    return plan !== PLAN.LITE && plan !== PLAN.STARTER
}

const isEnterPrise = (plan) => {
    return plan !== PLAN.LITE && plan !== PLAN.STARTER && plan !== PLAN.TEAM
}

const isPaidUser = (plan) => {
    return plan !== PLAN.LITE
}

export {
    isPremiumUser,
    isEnterPrise,
    isPaidUser
}