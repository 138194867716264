/**
 * Function to check if an audio buffer exists or not.
 * @param {string} audioUrl
 * @returns boolean true if the source contains an audio buffer; otherwise, return false..
 */
export const checkAudioBuffer = async (audioUrl) => {
  try {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const request = new XMLHttpRequest();
    request.open("GET", audioUrl, true);
    request.responseType = "arraybuffer";

    return new Promise((resolve, reject) => {
      request.onload = async () => {
        try {
          // Using the promise-based API for decodeAudioData
          await audioContext.decodeAudioData(request.response);
          // Audio buffer is present
          resolve(true);
        } catch (decodeError) {
          // Error decoding audio data (no buffer)
          resolve(false);
        }
      };

      request.onerror = () => {
        // Error loading audio file
        reject(new Error("Error loading audio file"));
      };

      request.send();
    });
  } catch (error) {
    return false;
  }
};