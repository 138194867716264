/// <reference path="./timeline-types.js" />

import { Record } from "immutable";

export const TIMELINE_HEIGHT = {
  initialPercent: 25,
  initialPx: 250, // this value is chosen to show atleast 3 tracks
  minimum: 48,
  /** maximum height - percent of window height */
  maximumPercent: 70,
};

/**
 * @type {MouseDragState} initial state of MouseDrag component
 */
export const MOUSE_DRAG_INITIAL_STATE = {
  isDragging: false,
  cursor: "auto",
  cursorThreshold: 0,
  mouseDownStart: {
    x: 0,
    y: 0,
  },
  scrollLeftOnMouseDown: 0,
  scrollTopOnMouseDown: 0,
  clientOffset: { x: 0, y: 0 },
  throttle: 0,
  onMouseDrag: null,
  onMouseDragEnd: null,
};

/**
 * @type {AutoScrollState} initial state of AutoScroll component
 */
export const AUTO_SCROLL_INITIAL_STATE = {
  scrollStatus: {
    isScrolling: false,
    interval: 0,
    scrollLeftDisPerInterval: 0,
    scrollTopDisPerInterval: 0,
    isHorizontalScroll: false,
    isVerticalScroll: false,
    context: undefined,
  },
  scrollDeps: {
    onAutoScroll: null,
    scrollLeftBy: null,
    scrollLeftElRef: null,
    scrollTopBy: null,
    scrollTopElRef: null,
  },
};

// Default duration while adding a new item like text , images and shapes
export const DEFAULT_DURATION = 5;

export const PLAYER_CONTROLS_HEIGHT = 48;

export const TRACK_TYPES = {
  RULER: "ruler",
  OBJECT: "object",
  VIDEO: "video",
  AUDIO: "audio",
};

export const SLIDER_TYPES = {
  RULER: "ruler",
  PLAYHEAD_THUMB: "playheadThumb",
  PLAYHEAD_INDICATOR: "playheadIndicator",
  OBJECT: "object",
  SUBTITLE: "subtitle",
  MINI_SUBTITLE: "msubtitle",
  VIDEO: "video",
  AUDIO: "audio",
  GAP: "gap",
};

export const SLIDER_GAP_TYPES = {
  ITEM: "item-gap",
  SUBTITLE: "subtitle-gap",
};

export const DEFAULT_AUTOSCROLL_OPTIONS = {
  /** unit: px */
  moveBy: 10,
  /** unit: ms */
  interval: 34,
  /** width of opposite ends in x-axis (in client width) which can trigger scroll - unit: px */
  clientExtremeThreshold: 15,
  /** whether thumb should align with step on autoscroll - overrides {@link RULER_OPTIONS.alignWithStep} */
  alignWithStepOnScroll: false,
  /** whether thumb should align with step when autoscroll ends - overrides {@link RULER_OPTIONS.alignWithStep} */
  alignWithStepOnScrollEnd: true,
};

export const RULER_OPTIONS = {
  /**
   * hard limit for duration of project - no asset can be dragged beyond this duration (unit - seconds)
   * will be ignored if set to 0
   */
  projectMaxDuration: 7200,
  /**
   * default duration to set if project is empty
   * NOTE: DO NOT SET THIS BELOW {@link RULER_OPTIONS.interval}!
   */
  projectMinDuration: 10,
  /** time scale range info - in percent */
  timeScale: {
    min: -100,
    max: 100,
    minScaleOffsetPercent: 10,
    maxScaleDuration: [
      // unit - seconds
      { min: 0, max: 3599, duration: 1 }, // 0-1hr
      { min: 3599, max: 7199, duration: 30 }, // 1-2hr
      { min: 7199, max: 10799, duration: 60 }, // 2-3hr
      { min: 10799, max: null, duration: 60 }, // >=3hr
    ],
    step: 0.01,
    buttonStep: 1,
    /** initial time scale in percent */
    default: 0,
  },
  /** height of ruler */
  height: 32,
  /** height of excess step region */
  excessHeight: 16,
  /** duration of each step in seconds */
  interval: 0.1,
  /**
   * some calculation for stepper will produce float errors. So use this as threshold to reduce float issues
   *
   * Example:
   *   Instead of 11 steps we might get 10.999999.
   *   In this case we should ceil it to 11 as 11 - 10.999999 will be below 0.1 (0.000001).
   *   Except for this case, all step values should be floored
   */
  stepCorrection: 0.1,
  /** dimension of all marks that will be shown in ruler */
  markDimension: {
    /** lines indicating middle step */
    middle: {
      width: 1,
      height: 5,
    },
    /** lines indicating twice of middle step */
    main: {
      width: 1,
      height: 15,
    },
    /** baseline from which other marks extend */
    baseline: {
      width: null, // width is dynamic
      height: 1,
    },
  },
  /** decides at which step to use middle mark */
  middleStep: {
    /** puts middle mark at multiples of this step */
    markAt: 1,
    /** decides which step (multiple of {@link RULER_OPTIONS.middleStep.markAt}) to mark - unit: px */
    minimum: 70,
  },
  /** decides how much spacing to add at start of the ruler */
  paddingLeft: 20,
  /** decides how much spacing to add at end of the ruler */
  paddingRight: 20,
  timeLabel: {
    /** line height of label. NOTE: keep this in sync with whatever font we apply to label */
    lineHeight: 14,
    /** offset of label from ruler mark */
    offsetX: 3,
  },
  /**
   * decides whether a component's position aligns exactly to the step in ruler on drag end.
   * true - gives jump effect on drag end, false - gives smooth effect on drag end
   */
  alignWithStep: {
    [SLIDER_TYPES.AUDIO]: true,
    [SLIDER_TYPES.OBJECT]: true,
    [SLIDER_TYPES.PLAYHEAD_INDICATOR]: false,
    [SLIDER_TYPES.PLAYHEAD_THUMB]: true,
    [SLIDER_TYPES.RULER]: true,
    [SLIDER_TYPES.VIDEO]: true,
    [SLIDER_TYPES.GAP]: true,
    [SLIDER_TYPES.SUBTITLE]: true,
  },
  /**
   * decides whether a component's position aligns exactly to the step in ruler on drag
   * true - gives jump effect on drag, false - gives smooth effect on drag
   */
  alignWithStepOnDrag: {
    [SLIDER_TYPES.PLAYHEAD_THUMB]: false,
  },
  /** decides how to scroll the ruler */
  scrollOptions: {
    [SLIDER_TYPES.AUDIO]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.OBJECT]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.PLAYHEAD_INDICATOR]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.PLAYHEAD_THUMB]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.RULER]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.VIDEO]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.GAP]: DEFAULT_AUTOSCROLL_OPTIONS,
    [SLIDER_TYPES.SUBTITLE]: DEFAULT_AUTOSCROLL_OPTIONS,
  },
  snapOptions: {
    /**
     * thumbs will align with snap point if distance between them is below/equal to this value (unit - px)
     * but (stepSizePx / 2) will be considered if time scale is equal to {@link RULER_OPTIONS.timeScale.max}
     */
    minDistance: 5,
    /**
     * ruler will be partitioned into several tiles such that each tile will cover 100 steps
     * which in turn will have snap points in that tile region if there are any
     */
    stepPartitionSize: 100,
    /** decides which thumb can snap */
    allowedThumbs: ["enterStart", "exitEnd"],
    /** width of snap line */
    strokeWidth: 2,
    /** style of dash */
    strokeDashArray: "4,3",
    /** snap targets are checked in this order */
    snapPriorityByTracks: [
      TRACK_TYPES.RULER,
      TRACK_TYPES.OBJECT,
      TRACK_TYPES.VIDEO,
      TRACK_TYPES.AUDIO,
    ],
    /** snap targets are checked in this order for seek buttons */
    seekPriorityByTracks: [
      TRACK_TYPES.OBJECT,
      TRACK_TYPES.VIDEO,
      TRACK_TYPES.AUDIO,
      TRACK_TYPES.RULER,
    ],
  },
  /**
   * @type {"step" | "nearest-thumb"}
   */
  seekButtonBehaviour: "nearest-thumb",
  /** max duration for subtitle to be inserted (unit - seconds) */
  newSubtitleDuration: 2,
};

export const TRACK_OPTIONS = {
  [TRACK_TYPES.AUDIO]: {
    height: 30,
    groupMarginTop: 9,
    miniSubtitleHeight: 20,
    miniSubtitleGap: 1,
    subtitleHeight: 33,
    subtitleGap: 2,
    marginTop: 2,
    newTrackTriggerRegionHeight: 15,
    newTrackHeight: 3,
    isReversed: false,
    allowTrimOnSameTrack: true,
    dividerHeight: 1,
    dividerAbove: true,
  },
  [TRACK_TYPES.OBJECT]: {
    height: 30,
    withImageHeight: 44,
    miniSubtitleHeight: 20,
    miniSubtitleGap: 1,
    subtitleHeight: 33,
    subtitleGap: 2,
    groupMarginTop: 7,
    marginTop: 2,
    newTrackTriggerRegionHeight: 15,
    newTrackHeight: 3,
    isReversed: true,
    allowTrimOnSameTrack: true,
  },
  [TRACK_TYPES.VIDEO]: {
    height: 60,
    miniSubtitleHeight: 20,
    miniSubtitleGap: 1,
    subtitleHeight: 33,
    subtitleGap: 2,
    groupMarginTop: 7,
    /** a image in video track will not have duration greater than this value (unit - seconds) */
    nonVideoMaxDuration: 5400,
    newSliderTriggerRegionWidth: 14,
    allowTrimOnSameTrack: true,
  },
  tracksMarginBottom: 5,
};

export const TIMELINE_MODES = {
  MAIN: 0,
  SUBTITLE: 1,
};

export const TimelineRunningStateRecord = Record({
  seekPlayhead: 0,
  seekToken: "",
});

export const transitionTime = 0.69;