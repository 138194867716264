
import triggerSimpleAjax from "./httpHelper";

const AWS = require('aws-sdk');

const AppConfig = require("../constants/config");

const { API_URL, API } = AppConfig;


export const uploadFile = (config, blob, Key) => {
    return new Promise((response, rej) => {
        const {
            accessKeyId, secretAccessKey, sessionToken
        } = config || {};
        if (accessKeyId) {
            const config = {
                region: 'us-west-2',
                accessKeyId,
                secretAccessKey
            }
            if (sessionToken) {
                config.sessionToken = sessionToken;
            }
            AWS.config.update(config);
            const S3 = new AWS.S3({
                signatureVersion: 'v4',
                useAccelerateEndpoint: true,
                endpoint: 'https://s3-accelerate.amazonaws.com/'
            });
            S3.putObject({
                Bucket: 'anim-user-uploads',
                Key,
                ContentType: 'image/png',
                Body: blob
            }, (res) => {
                response(res)
            })
        } else {
            rej();
        }
    })
    
}

const getAwsAccess = (userId) => {
    const apiUrl = `${API_URL}${API.GET_ACCESS}`;
    return new Promise((resolve, reject) => {
        triggerSimpleAjax(apiUrl, 'POST',
            true, {
                userId
            }, (res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
    })
}

export default getAwsAccess;