/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "../../common-components/Typography";
import { Modal } from "../../common-components/modal";
import content from "../../constants/content";
import { font } from "../../constants/font";
import PlanCard from "./planCard";
import ProfessionalForm from "./formdata";
import openUrl from "../../helper/openUrl";
import EnterpriseForm from "./enterpriseForm";
import { Body, Container, Header, PricingCard, SubHead } from "./upgrade-components";
import { PLAN, PLAN_TYPES, ONBOARDING_CONTENT, PLAN_CONTENT, PLAN_PRISING_RES } from "../../constants";
import AppConfig, { API_URL, PAGE, CNAME } from "../../constants/config";
import { isEnterPrise, isPremiumUser } from "../../helper/checkUser";
import useUpgrade from "../../helper/hooks/useUpgrade";
import ActionTypes from "../../constants/action-types";
import useApi from "../../helper/hooks/useApi";

const filterData = (userPlan) => {
  const planOrder = [PLAN.LITE, PLAN.STARTER, PLAN.TEAM, PLAN.ENTERPRISE];
  const userPlanIndex = planOrder.indexOf(userPlan);

  if (userPlan === PLAN.ENTERPRISE) {
    return [];
  }

  return PLAN_TYPES.filter((e) => planOrder.indexOf(e.plan) > userPlanIndex);
};

const Upgrade = () => {
  const [showEnterprise, setShowEnterprise] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [planCost, setPlanCost] = useState(PLAN_PRISING_RES);
  const { plan } = useSelector((store) => store.userDetails);
  const dispatch = useDispatch();
  const { get } = useApi();

  const { user_type, user_details } = useSelector((state) => state.userDetails);
  const { show, headerText, subText, enterpriseContent, customForm = null, upgradesource, type = "default" } = useSelector((state) =>
    state.app.get("upgrade").toJS()
  );
  const { hideUpgrade } = useUpgrade();

  const upgradeHandler = (e, planName) => {
    e && e.preventDefault() && e.stopPropagation();
    const source = upgradesource;
    if (isEnterPrise(planName)) {
      setShowEnterprise(true);
    } else if (planName === PLAN.STARTER) {
      openUrl(
        `${API_URL}${PAGE.MY_PLAN}?planname=${PLAN.STARTER}&plancycle=yearly&upgradesource=${source}`
      );
    } else if (planName === PLAN.TEAM) {
      openUrl(
        `${API_URL}${PAGE.MY_PLAN}?planname=${PLAN.TEAM}&plancycle=yearly&upgradesource=${source}`
      );
    } else {
      openUrl(`${API_URL}${PAGE.MY_PLAN}`);
    }
  };

  useEffect(() => {
    get(`${API_URL}${AppConfig.API.PLAN_PRISING}`, {}, false).then((res) => {
      setPlanCost(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customForm) {
      switch (customForm) {
        case "SUPPORT_FORM":
          hideUpgrade();
          dispatch({
            type: ActionTypes.SHOW_SUPPORT_FORM,
            payload: true,
          });
          break;
        case "ENTER_PRISE_FORM":
          setShowEnterprise(true);
          break;
        default:
          break;
      }
    } else if (plan && show) {
      if (isEnterPrise(plan)) {
        hideUpgrade();
        dispatch({
          type: ActionTypes.SHOW_SUPPORT_FORM,
          payload: true,
        });
      } else if (isPremiumUser(plan)) {
        setShowEnterprise(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, show, customForm]);

  useEffect(() => {
    const data = filterData(plan);
    setPlanData(data);
  }, [plan]);

  const handleClose = () => {
    setShowEnterprise(false);
    hideUpgrade();
  };

  return (
    <PricingCard>
      <Modal
        width="fit-content"
        height="auto"
        showModal={show}
        onClose={handleClose}
        closeWidth="24"
        closeHeight="24px"
        closeTop="22px"
        closeRight="25px"
        closeIcon="vm-close.svg"
        className="upgrade-modal"
        borderRadius="12px"
      >
        {!showEnterprise ? (
          <Container>
            <Header>
              <SubHead>
                <Typography
                  content={headerText || content.CHOOSE_PLAN}
                  color={"#313345"}
                  font={font.bold_20_16}
                  padding="0px 0px 8px"
                  enableTrim={false}
                />
                {subText && (
                  <Typography
                    content={subText}
                    color={"#313345"}
                    font={font.normalBase_19}
                    padding="8px 0px"
                    enableTrim={false}
                  />
                )}
              </SubHead>
              <Typography
                content={content.COMPARE_PLANS}
                color={"#313345"}
                font={font.normalBase_19}
                cursor={"pointer"}
                textStyles={{ textDecoration: "underline" }}
                padding="8px 0px"
                href={`https://www.${CNAME}/pricing`}
                target="_blank"
                isAnchor
              />
            </Header>
            <Body>
              {planData.length > 0 ?
                planData.map((data) => (
                  <PlanCard
                    key={data.name}
                    data={data}
                    planName={data.name}
                    iconSrc={data.icon}
                    content={PLAN_CONTENT[data.plan][type]}
                    action={data.action}
                    onClick={(e) => upgradeHandler(e, data.plan)}
                    actionBgColor={data.actionBgColor}
                    enterpriseContent={data.description}
                    monthlyPrice={(planCost[data.plan] || {}).yearly / 12}
                    yearlyPrice={(planCost[data.plan] || {}).yearly}
                  />
                )) : plan !== PLAN.ENTERPRISE && <p>No available upgrades</p>}
            </Body>
          </Container>
        ) : user_type === ONBOARDING_CONTENT.PROFESSIONAL ? (
          <ProfessionalForm
            selectedValue={user_details}
            onClose={handleClose}
          />
        ) : (
          <EnterpriseForm enterpriseContent={enterpriseContent} />
        )}
      </Modal>
    </PricingCard>
  );
};

export default Upgrade;
