import ActionTypes from "../../constants/action-types";

export function addNotification(notification) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.ADD_NOTIFICATION,
      payload: notification,
    });
}

export function removeNotification(id) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.REMOVE_NOTIFICATION,
      payload: id,
    });
}