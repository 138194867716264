/* eslint-disable react/sort-comp, prefer-destructuring, operator-assignment, one-var, prefer-template, no-restricted-syntax, no-labels */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { rgbString2hex } from "../../helper/colorHelper";
import { preloadTextFonts, removeSelection } from "./TextHelper";
import { isMouseOn } from "../../helper/TransformManagerHelper";
import { convertEffectValuesToStyles } from "./TextEffectsHelper";
import ContentEditable from "./content-editable";
import {
  changeText,
  deleteObject,
  syncText,
  updateGroupText,
  writeText,
} from "../../redux/actions/timelineUtils";
import {
  setLastEditText,
  setTextOptions,
  updateTextStatus,
} from "../../redux/actions/appUtils";

export class TextComponent extends Component {
  constructor(props) {
    super(props);
    this.emitChange = this.emitChange.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.getUpdatedText = this.getUpdatedText.bind(this);
    this.setTypeTimeout = this.setTypeTimeout.bind(this);
    this.saveText = this.saveText.bind(this);
    this.getPreviousUndoSyncData = this.getPreviousUndoSyncData.bind(this);
    this.handleTextClick = this.handleTextClick.bind(this);
    this.handleTextDoubleClick = this.handleTextDoubleClick.bind(this);
    this.clearSaveText = this.clearSaveText.bind(this);
    this.isAllFontFamilyIsLoaded = this.isAllFontFamilyIsLoaded.bind(this);
    this.hasUndoRedoAffectedText = this.hasUndoRedoAffectedText.bind(this);
    this.getFontSize = this.getFontSize.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.typingTimer = null;
    this.typeTimeout = 500;
    this.state = {
      textId: !this.props.isGrouped ? this.props.data_id : this.props.itemId,
      container: !this.props.isGrouped ? "workspaceItems" : "workspaceChildren",
      focused:
        this.props.textStatus.get("id") ===
          (!this.props.isGrouped ? this.props.data_id : this.props.itemId) &&
        this.props.textStatus.get("isFocused"),
      html: this.props.text.getIn(["textData", "htmlText"]),
      isFontFamilyLoaded: false,
      previousSyncHtml: null,
      prevSyncX: null,
      prevSyncY: null,
      prevSyncWidth: null,
      prevSyncHeight: null,
      isTyping: false,
      canFocusOnClick: false, // to check whether text can be focused (in some cases text should not focus on first click)
    };
    this.waitForSyncEmit = undefined;
    this.syncEmitTrottleDur = 500;
  }

  static getDerivedStateFromProps(props, state) {
    let toReturn = null;
    if (
      props.textStatus.get("id") === state.textId &&
      props.textStatus.get("isFocused") !== state.focused
    ) {
      toReturn = { focused: props.textStatus.get("isFocused") };
    }

    if (props.text.getIn(["textData", "htmlText"]) !== state.html) {
      if (toReturn === null)
        toReturn = { html: props.text.getIn(["textData", "htmlText"]) };
      else toReturn.html = props.text.getIn(["textData", "htmlText"]);
    }

    if (
      state.canFocusOnClick &&
      ((props.isGrouped &&
        props.itemId !== props.childrenSelection.getIn(["childIds", 0])) || // different child is selected
        props.data_id !== props.selectedItems.get(0) || // different item is selected
        props.transformStatus.get("transforming")) // transform manager is moved/rotated
    ) {
      if (toReturn === null) {
        toReturn = { canFocusOnClick: false };
      } else {
        toReturn.canFocusOnClick = false;
      }
    }

    return toReturn;
  }

  componentDidMount() {
    if (this.props.workspaceItems.size > 0) {
      if (!this.props.isPlay && !this.props.isPlayAll) {
        document.addEventListener("mouseup", this.handleMouseUp, false);
        document.addEventListener("mousedown", this.handleMouseDown, false);
      }
    }

    // Fail safe for previous projects with empty text
    const innerHtml = this.props.text.getIn(["textData", "htmlText"]);

    if (
      this.props.text.get("subType") === "DTXT" &&
      (innerHtml === "<br>" ||
        innerHtml === "" ||
        innerHtml === "<div><br></div>")
    ) {
      this.props.deleteObject({
        toUpdate: [
          {
            id: this.state.textId,
            container: !this.props.isGrouped
              ? "workspaceItems"
              : "workspaceChildren",
            isDelete: true,
          },
        ],
      });
    }

    this.isAllFontFamilyIsLoaded();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isPlay && !this.props.isPlayAll) {
      if (this.props.textStatus !== prevProps.textStatus) {
        if (
          this.props.textStatus.get("id") === this.state.textId &&
          this.props.textStatus.get("isFocused")
        ) {
          this.htmlEl.htmlContent.focus();
          this.setState({ focused: true });
        }
      }
      /** @note :- if syncData is moved to localStore revert the data in textUpdation action it's self */
      if (
        this.props.textStatus.get("id") === prevProps.textStatus.get("id") &&
        this.props.textStatus.get("id") === this.state.textId &&
        (this.props.text.getIn([
          "textData",
          "formats",
          "containerStyle",
          "fontSize",
        ]) !==
          prevProps.text.getIn([
            "textData",
            "formats",
            "containerStyle",
            "fontSize",
          ]) ||
          this.props.text.getIn([
            "textData",
            "formats",
            "containerStyle",
            "letterSpacing",
          ]) !==
            prevProps.text.getIn([
              "textData",
              "formats",
              "containerStyle",
              "letterSpacing",
            ]) ||
          this.props.text.getIn([
            "textData",
            "formats",
            "containerStyle",
            "lineHeight",
          ]) !==
            prevProps.text.getIn([
              "textData",
              "formats",
              "containerStyle",
              "lineHeight",
            ]))
      ) {
        this.setState({
          prevSyncHeight: null,
          prevSyncWidth: null,
        });
      }

      const prevStyles = prevProps.text
        .getIn(["textData", "formats", "containerStyle"])
        .remove("color")
        .remove("textAlign")
        .remove("textDecoration")
        .remove("bullet")
        .remove("letterSpacing");
      const currStyles = this.props.text
        .getIn(["textData", "formats", "containerStyle"])
        .remove("color")
        .remove("textAlign")
        .remove("textDecoration")
        .remove("bullet")
        .remove("letterSpacing");

      if (
        this.props.text.get("width") !== prevProps.text.get("width") ||
        this.props.text.get("height") !== prevProps.text.get("height")
      ) {
        if (currStyles.get("fontSize") !== prevStyles.get("fontSize")) {
          document
            .getElementById(this.state.textId)
            .getElementsByClassName("text-container")[0].style.fontSize =
            (
              parseFloat(currStyles.get("fontSize"), 10) * this.props.zoomFactor
            ).toFixed(2) + "px";
        }

        return;
      }

      if (
        currStyles.get("fontSize") !== prevStyles.get("fontSize") &&
        this.props.isGrouped &&
        this.props.parentItem.get("subType") !== "TXTGRP"
      ) {
        document
          .getElementById(this.state.textId)
          .getElementsByClassName("text-container")[0].style.fontSize =
          (
            parseFloat(currStyles.get("fontSize"), 10) * this.props.zoomFactor
          ).toFixed(2) + "px";
        return;
      }

      const actPrevStyle = prevStyles.remove("fontSize");
      const actCurrStyle = currStyles.remove("fontSize");
      let isTyping = false; // eslint-disable-line

      if (
        (currStyles.get("fontFamily") !== prevStyles.get("fontFamily") &&
          (this.props.fontStatus === undefined ||
            this.props.fontStatus.get("isLoaded"))) ||
        this.props.text.getIn(["textData", "htmlText"]) !==
          prevProps.text.getIn(["textData", "htmlText"])
      ) {
        isTyping = false; // eslint-disable-line

        if (
          !this.props.isGrouped ||
          (this.props.isGrouped &&
            this.props.parentItem.get("subType") === "TXTGRP")
        ) {
          // this.getUpdatedText( currStyles.get("fontSize") !== prevStyles.get("fontSize") && prevHtml === curHtml, (this.props.fontStatus !== undefined && !this.props.fontStatus.equals(prevProps.fontStatus)), isTyping, (this.props.fontStatus !== undefined && !this.props.fontStatus.equals(prevProps.fontStatus) && this.props.fontStatus.get("isLoaded")));
        } else {
          // this.updateGroupedText(currStyles.get("fontSize") !== prevStyles.get("fontSize") && prevHtml === curHtml, false, isTyping, (this.props.fontStatus !== undefined && !this.props.fontStatus.equals(prevProps.fontStatus) && this.props.fontStatus.get("isLoaded")));
        }
      } /* else if (
        this.props.text.getIn(["textData", "htmlText"]) !==
          prevProps.text.getIn(["textData", "htmlText"]) &&
        !this.props.textStatus.get("isFocused") &&
        (this.props.text.get("width") !== prevProps.text.get("width") ||
          this.props.text.get("height") !== prevProps.text.get("height"))
      ) {
        document
          .getElementById(this.state.textId)
          .getElementsByClassName("text-container")[0].innerHTML =
          this.props.text.getIn(["textData", "htmlText"]);
      } */

      if (!currStyles.equals(prevStyles) && actPrevStyle.equals(actCurrStyle)) {
        document
          .getElementById(this.state.textId)
          .getElementsByClassName("text-container")[0].style.fontSize =
          (
            parseFloat(currStyles.get("fontSize"), 10) * this.props.zoomFactor
          ).toFixed(2) + "px";
      }
    }

    /** @summary below condition is added to fix text selection range is Persistent when we drop new text from lib. revert if it breaks. */
    if (
      (this.state.textId === this.props.textStatus.get("id") &&
        prevProps.textStatus.get("isFocused") &&
        !this.props.textStatus.get("isFocused")) ||
      (this.state.focused && !this.props.textStatus.get("id")) ||
      (prevProps.textStatus.get("id") === this.state.textId &&
        !this.props.textStatus.get("id"))
    ) {
      removeSelection();
    }

    const fontFamily = this.props.text.getIn([
      "textData",
      "formats",
      "containerStyle",
      "fontFamily",
    ]);
    const currLoadedFonts = this.props.loadedFonts.get("fonts");
    const prevLoadedFonts = prevProps.loadedFonts.get("fonts");
    const curRootFontData = currLoadedFonts
      ? currLoadedFonts.find((x) => x.get("load") === fontFamily)
      : undefined;
    const prevRootFontData = prevLoadedFonts
      ? prevLoadedFonts.find((x) => x.get("load") === fontFamily)
      : undefined;
    if (
      curRootFontData !== undefined &&
      fontFamily !== undefined &&
      prevRootFontData !== undefined &&
      fontFamily !== undefined
    ) {
      if (
        !prevRootFontData.get("isLoaded") &&
        curRootFontData.get("isLoaded")
      ) {
        this.isAllFontFamilyIsLoaded();
      }
    }
  }

  componentWillUnmount() {
    this.clearSaveText(); // clear text update debounce to prevent state update
    document.removeEventListener("mouseup", this.handleMouseUp, false);
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  clearSaveText() {
    if (this.typingTimer) clearTimeout(this.typingTimer);
  }

  emitChange(e) {
    try {
      this.setState({ html: e.target.value, isTyping: true });

      if (
        this.state.focused &&
        this.props.text.getIn(["textData", "htmlText"]) !== e.target.value &&
        e.eventName !== "insertUnorderedList" &&
        e.eventName !== "insertOrderedList" &&
        e.eventName !== "formatUnderline"
      ) {
        if (
          e.target.value === "<br>" ||
          e.target.value === "" ||
          e.target.value === "<div><br></div>"
        ) {
          this.props.setLastEditText(
            fromJS({
              beforeEmpty: this.props.text.getIn(["textData", "htmlText"]),
              prevX: this.props.text.get("x"),
              prevY: this.props.text.get("y"),
              prevWidth: this.props.text.get("width"),
              prevHeight: this.props.text.get("height"),
            })
          );
        }
        this.setTypeTimeout(e);
        const isTyping = true;
        let syncUndo = false;

        const currentTime = Date.now();
        if (
          currentTime > this.waitForSyncEmit ||
          this.waitForSyncEmit == null
        ) {
          syncUndo = true;
          this.waitForSyncEmit = currentTime + this.syncEmitTrottleDur;
        }

        if (
          !this.props.isGrouped ||
          (this.props.isGrouped &&
            this.props.parentItem.get("subType") === "TXTGRP")
        ) {
          this.getUpdatedText(false, false, isTyping, false, syncUndo);
        } else {
          this.updateGroupedText(false, false, isTyping, syncUndo);
        }
      }
    } catch (error) {
      //
    }
  }

  getFontSize(id, width, height, isCallout) {
    let container = document.getElementById(id);

    if (isCallout === true) {
      container = container.getElementsByClassName("callout-text-container")[0];
    }
    const ourText = container.getElementsByClassName("text-container")[0];

    if (ourText.style.fontSize === "") ourText.style.fontSize = "16px";

    if (isCallout === true) {
      if (width !== undefined)
        container.style.width = width * this.props.zoomFactor + "px";
      if (height !== undefined)
        container.style.height = height * this.props.zoomFactor + "px";
    }
    let fontSize = parseFloat(ourText.style.fontSize, 10).toFixed(2);
    const textItem = this.props.text;

    // var maxWidth      = (textItem.get("maxWidthRatio"))?textItem.get("maxWidthRatio") * textItem.get("width") * this.props.zoomFactor:container.clientWidth;
    let boundHeight = textItem.get("height");
    if (this.props.isGrouped && isCallout !== true)
      boundHeight = this.props.parentItem.get("height");

    const maxHeight = textItem.get("maxHeightRatio")
      ? ((textItem.get("maxHeightRatio") * boundHeight) / 100) *
        this.props.zoomFactor
      : container.clientHeight;

    // var textWidth     = ourText.clientWidth;
    let textHeight = ourText.clientHeight,
      i,
      j;

    if (textHeight > maxHeight) {
      for (i = 0; i < 500; i++) {
        if (textHeight < maxHeight) {
          fontSize = fontSize + 1;
          for (j = 0; j < 10; j++) {
            const decFontSize = fontSize - (j + 1) / 10;
            ourText.style.fontSize = decFontSize + "px";
            textHeight = ourText.clientHeight;

            if (textHeight <= maxHeight) {
              ourText.style.fontSize = decFontSize + "px";
              return decFontSize + "px";
            }
          }
          ourText.style.fontSize = fontSize + "px";
          return fontSize + "px";
        }
        fontSize--;
        ourText.style.fontSize = fontSize + "px";
        textHeight = ourText.clientHeight;
        // textWidth  = ourText.clientWidth;
      }
    } else if (textHeight < maxHeight) {
      for (i = 0; i < 500; i++) {
        if (textHeight > maxHeight) {
          for (j = 0; j < 10; j++) {
            const decFontSize = fontSize - (j + 1) / 10;
            ourText.style.fontSize = decFontSize + "px";
            textHeight = ourText.clientHeight;
            // textWidth  = ourText.clientWidth;
            if (textHeight <= maxHeight) {
              ourText.style.fontSize = decFontSize + "px";
              return decFontSize + "px";
            }
          }
          fontSize = fontSize - 1;
          ourText.style.fontSize = fontSize + "px";
          return fontSize + "px";
        }
        fontSize++;
        ourText.style.fontSize = fontSize + "px";
        textHeight = ourText.clientHeight;
        // textWidth  = ourText.clientWidth;
      }
    }

    return ourText.style.fontSize + "px";
  }

  /**
   * @returns PreviousUndoSyncData
   */
  getPreviousUndoSyncData(undoRedoUpdate = false, currentHtml) {
    let prevHtml, prevX, prevY, prevWidth, prevHeight;

    const hasNoChangeFromUndo = this.hasUndoRedoAffectedText(
      currentHtml,
      this.state.previousSyncHtml
    );

    if (
      this.state.previousSyncHtml != null &&
      this.state.prevSyncX != null &&
      this.state.prevSyncY != null &&
      this.state.prevSyncWidth != null &&
      this.state.prevSyncHeight != null &&
      undoRedoUpdate === false &&
      hasNoChangeFromUndo
    ) {
      prevHtml = this.state.previousSyncHtml;
      prevX = this.state.prevSyncX;
      prevY = this.state.prevSyncY;
      prevWidth = this.state.prevSyncWidth;
      prevHeight = this.state.prevSyncHeight;
    } else {
      prevX = this.props.text.get("x");
      prevY = this.props.text.get("y");
      prevWidth = this.props.text.get("width");
      prevHeight = this.props.text.get("height");
      prevHtml = this.props.text.getIn(["textData", "htmlText"]);
    }

    return { prevHtml, prevX, prevY, prevWidth, prevHeight };
  }

  /**
   * @param syncUndo - decides whether the changes should store in undoRedo
   */
  getUpdatedText(
    fontSizeChanged,
    saveText,
    isTyping,
    forceSave = false,
    syncUndo = false
  ) {
    const textItem = this.props.text;
    const textElement = document.getElementById(this.state.textId);
    let itemX = textItem.get("x"),
      itemY = textItem.get("y");
    if (this.props.isGrouped) {
      itemX = itemX - this.props.parentItem.get("x");
      itemY = itemY - this.props.parentItem.get("y");
    }
    const textContainer =
      textElement.getElementsByClassName("text-container")[0];
    textContainer.style.fontSize =
      parseFloat(
        textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]),
        10
      ).toFixed(2) *
        this.props.zoomFactor +
      "px";
    const textHeight = textItem.get("height");
    const verticalAlign =
      textItem.get("verticalAlign") !== undefined &&
      textItem.get("verticalAlign") !== ""
        ? textItem.get("verticalAlign")
        : "top";
    const preWidth = textElement.style.width;
    let isFixedWidth = textItem.getIn([
      "textData",
      "formats",
      "others",
      "isFixedWidth",
    ]);
    if (this.props.selectedItems.size > 1) isFixedWidth = true;
    let minWidth = textItem.getIn([
      "textData",
      "formats",
      "others",
      "minWidth",
    ]);
    minWidth = minWidth !== null && minWidth !== undefined ? minWidth : 100;
    let widthRestricted = fontSizeChanged;
    if (textItem.get("type") === "SHAPE" || this.props.isGrouped)
      widthRestricted = true;
    if (!isFixedWidth && !widthRestricted) {
      textElement.style.width = "auto";
    }
    let flip = "";
    if (textItem.get("type") === "SHAPE") {
      const flipPos = textItem.get("flipPosition");
      flip =
        flipPos === 1
          ? " scaleX(-1)"
          : flipPos === 2
          ? " scaleY(-1)"
          : flipPos === 3
          ? " scaleX(-1) scaleY(-1)"
          : "";
    }

    textElement.style.transform =
      "translate(" +
      (itemX * this.props.zoomFactor).toFixed(2) +
      "px, " +
      (itemY * this.props.zoomFactor).toFixed(2) +
      "px) rotateZ(0deg)" +
      flip;
    if (this.props.isGrouped)
      textElement.style.transform =
        "translate(" +
        (itemX * this.props.zoomFactor).toFixed(2) +
        "px, " +
        (itemY * this.props.zoomFactor).toFixed(2) +
        "px) rotateZ(" +
        -(textItem.get("angle") + this.props.parentItem.get("angle")) +
        "deg)" +
        flip;
    const range = document.createRange();
    range.selectNode(textContainer);
    const rangeBounds = range.getBoundingClientRect();
    const elementBounds = textContainer.getBoundingClientRect();
    let aHeight = elementBounds.height;
    textElement.style.transform =
      "translate(" +
      (itemX * this.props.zoomFactor).toFixed(2) +
      "px, " +
      (itemY * this.props.zoomFactor).toFixed(2) +
      "px) rotateZ(" +
      textItem.get("angle") +
      "deg)" +
      flip;
    textElement.style.width = preWidth;

    const workspace = this.getWorkspaceBounds();
    let elementWidth = rangeBounds.width;
    if (this.props.isGrouped || textItem.get("type") === "SHAPE") {
      let toUpdate = fromJS({ textData: textItem.get("textData") });
      let minXRatio =
        textItem.get("minXRatio") !== undefined &&
        textItem.get("minXRatio") !== null
          ? Math.round(textItem.get("minXRatio"))
          : textItem.get("type") === "SHAPE"
          ? 5
          : 0;
      let minYRatio =
        textItem.get("minYRatio") !== undefined &&
        textItem.get("minYRatio") !== null
          ? Math.round(textItem.get("minYRatio"))
          : textItem.get("type") === "SHAPE"
          ? 5
          : 0;
      let maxWidthRatio =
        textItem.get("maxWidthRatio") !== undefined &&
        textItem.get("maxWidthRatio") !== null
          ? Math.round(textItem.get("maxWidthRatio"))
          : textItem.get("type") === "SHAPE"
          ? 90
          : 100;
      let maxHeightRatio =
        textItem.get("maxHeightRatio") !== undefined &&
        textItem.get("maxHeightRatio") !== null
          ? Math.round(textItem.get("maxHeightRatio"))
          : textItem.get("type") === "SHAPE"
          ? 90
          : 100;

      if (textItem.getIn(["pathData", "maxBounds"]) !== undefined) {
        minXRatio = textItem.getIn(["pathData", "maxBounds", "x"]);
        minYRatio = textItem.getIn(["pathData", "maxBounds", "y"]);
        maxWidthRatio = textItem.getIn(["pathData", "maxBounds", "width"]);
        maxHeightRatio = textItem.getIn(["pathData", "maxBounds", "height"]);
      }

      const textWidth = textItem.get("width"),
        textHeight = textItem.get("height");
      const minX = (minXRatio * textWidth) / 100,
        minY = (minYRatio * textHeight) / 100,
        maxWidth = (maxWidthRatio * textWidth) / 100,
        maxHeight = (maxHeightRatio * textHeight) / 100;
      let autoWidthBounds, preAutotWidth;
      if (textItem.get("type") === "SHAPE") {
        preAutotWidth = textElement.getElementsByClassName(
          "callout-text-container"
        )[0].style.width;
        textElement.getElementsByClassName(
          "callout-text-container"
        )[0].style.width = "auto";
        autoWidthBounds = textContainer.getBoundingClientRect();
        textElement.getElementsByClassName(
          "callout-text-container"
        )[0].style.width = preAutotWidth;
      } else {
        preAutotWidth = textElement.style.width;
        textElement.style.width = "auto";
        autoWidthBounds = textContainer.getBoundingClientRect();
        textElement.style.width = preAutotWidth;
      }

      let innerX =
          (elementBounds.x - workspace.x) / this.props.zoomFactor -
          textItem.get("x") -
          (minXRatio * textWidth) / 100,
        innerY =
          (elementBounds.y - workspace.y) / this.props.zoomFactor -
          textItem.get("y") -
          (minYRatio * textHeight) / 100,
        innerWidth = parseFloat(
          (autoWidthBounds.width / this.props.zoomFactor).toFixed(2)
        ),
        innerHeight = parseFloat(
          (elementBounds.height / this.props.zoomFactor).toFixed(2)
        );
      if (this.props.isGrouped) {
        innerHeight = parseFloat(
          (rangeBounds.height / this.props.zoomFactor).toFixed(2)
        );
        innerWidth = parseFloat(
          (rangeBounds.width / this.props.zoomFactor).toFixed(2)
        );
      }

      if (innerX < 0) innerX = 0;

      if (innerY < 0) innerY = 0;

      if (innerWidth === 0 || innerHeight === 0) {
        innerWidth = maxWidth;
        textContainer.innerHTML = "a";
        innerHeight = textContainer.getBoundingClientRect().height;
        textContainer.innerHTML = " ";

        innerX = minX;
        const iDH = maxHeight - innerHeight;
        innerY = innerY + iDH / 2;

        if (innerHeight > maxHeight) innerHeight = maxHeight;
      }

      if (
        textItem.get("type") === "SHAPE" ||
        textItem.getIn(["textData", "formats", "others", "sizeFixed"]) === true
      ) {
        if (innerWidth <= maxWidth && innerHeight <= maxHeight) {
          if (innerWidth <= maxWidth) {
            const iDW = maxWidth - innerWidth;
            innerX = innerX + iDW / 2;
          }
          const iDH = maxHeight - innerHeight;
          innerY = innerY + iDH / 2;
        } else if (/* innerWidth > maxWidth ||  */ innerHeight > maxHeight) {
          let fontSize =
            parseFloat(
              this.getFontSize(
                this.state.textId,
                maxWidth,
                maxHeight,
                textItem.get("type") === "SHAPE" ||
                  textItem.get("subType") === "BANNER"
              ),
              10
            ) / this.props.zoomFactor;

          if (Number.isNaN(fontSize)) fontSize = 10;
          if (fontSizeChanged) {
            if (
              fontSize >
              parseFloat(
                textItem.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ]),
                10
              )
            ) {
              fontSize = parseFloat(
                textItem.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ]),
                10
              );
              let container = document.getElementById(this.state.textId);
              if (container.className.indexOf("callout-text") !== -1) {
                container = container.getElementsByClassName(
                  "callout-text-container"
                )[0];
              }
              const ourText =
                container.getElementsByClassName("text-container")[0];

              ourText.style.fontSize = fontSize * this.props.zoomFactor + "px";
            }
          }
          toUpdate = toUpdate.setIn(
            ["textData", "formats", "containerStyle", "fontSize"],
            fontSize + "px"
          );
        }
      } else {
        let fontSize =
          parseFloat(
            this.getFontSize(
              this.state.textId,
              maxWidth,
              maxHeight,
              textItem.get("type") === "SHAPE" ||
                textItem.get("subType") === "BANNER"
            ),
            10
          ) / this.props.zoomFactor;

        if (Number.isNaN(fontSize)) fontSize = 10;
        if (fontSizeChanged) {
          if (
            fontSize >
            parseFloat(
              textItem.getIn([
                "textData",
                "formats",
                "containerStyle",
                "fontSize",
              ]),
              10
            )
          ) {
            fontSize = parseFloat(
              textItem.getIn([
                "textData",
                "formats",
                "containerStyle",
                "fontSize",
              ]),
              10
            );
            let container = document.getElementById(this.state.textId);
            if (container.className.indexOf("callout-text") !== -1) {
              container = container.getElementsByClassName(
                "callout-text-container"
              )[0];
            }
            const ourText =
              container.getElementsByClassName("text-container")[0];

            ourText.style.fontSize = fontSize * this.props.zoomFactor + "px";
          }
        }
        toUpdate = toUpdate.setIn(
          ["textData", "formats", "containerStyle", "fontSize"],
          parseFloat(fontSize, 10).toFixed(2) + "px"
        );
      }

      let yRatio =
        ((innerY + (minY + maxHeight / 2) - (innerY + innerHeight / 2)) /
          textHeight) *
        100;
      if (yRatio < minYRatio) yRatio = minYRatio;

      toUpdate = toUpdate
        .set("yRatio", yRatio)
        .set("heightRatio", (innerHeight / textHeight) * 100);

      toUpdate = toUpdate.setIn(
        ["textData", "htmlText"],
        textContainer.innerHTML
      );

      if (
        textContainer.innerHTML.trim() === "" &&
        textItem.get("type") === "SHAPE"
      ) {
        innerWidth = maxWidth;
        const iDW = maxWidth - innerWidth;
        innerX = innerX + iDW / 2;
        const iDH = maxHeight - innerHeight;
        innerY = innerY + iDH / 2;
        yRatio =
          ((innerY + (minY + maxHeight / 2) - (innerY + innerHeight / 2)) /
            textItem.get("height")) *
          100;

        if (yRatio < minYRatio) yRatio = minYRatio;

        toUpdate = toUpdate
          .set("yRatio", yRatio)
          .set("heightRatio", (innerHeight / textItem.get("height")) * 100)
          .set(
            "xRatio",
            ((innerX + (minX + maxWidth / 2) - (innerX + innerWidth / 2)) /
              textItem.get("width")) *
              100
          )
          .set("widthRatio", (innerWidth / textItem.get("width")) * 100);
        toUpdate = toUpdate.setIn(["textData", "htmlText"], " ");
      }

      if (!toUpdate.isEmpty()) {
        const newText = textItem.merge(toUpdate);
        if (!textItem.equals(newText) || !isTyping) {
          if (!this.props.isGrouped) {
            if ((isTyping === true || forceSave) && !syncUndo) {
              this.props.writeText(
                {
                  selectedItem: this.state.textId,
                  container: "workspaceItems",
                  toUpdate: toUpdate.toJS(),
                  isTyping: isTyping && forceSave,
                },
                this.props.socket
              );
            }
            if (syncUndo) {
              let currentInnerHTML = toUpdate.getIn(["textData", "htmlText"]);
              let undoRedoUpdate = false;
              if (this.state.previousSyncHtml === currentInnerHTML) {
                undoRedoUpdate = true;
                if (saveText) {
                  return;
                }
              }
              toUpdate = toUpdate.set("x", newText.get("x"));
              toUpdate = toUpdate.set("y", newText.get("y"));
              toUpdate = toUpdate.set("width", newText.get("width"));
              toUpdate = toUpdate.set("height", newText.get("height"));

              const { prevHtml, prevX, prevY, prevWidth, prevHeight } =
                this.getPreviousUndoSyncData(undoRedoUpdate, currentInnerHTML);

              if (this.state.previousSyncHtml == null) {
                currentInnerHTML = currentInnerHTML.trim();
              }

              this.setState({
                previousSyncHtml: currentInnerHTML,
                prevSyncX: newText.get("x"),
                prevSyncY: newText.get("y"),
                prevSyncWidth: newText.get("width"),
                prevSyncHeight: newText.get("height"),
              });
              this.props.syncText(
                {
                  selectedItem: this.state.textId,
                  container: "workspaceItems",
                  toUpdate: toUpdate.toJS(),
                  isTyping: false,
                  prevHtml,
                  prevX,
                  prevY,
                  prevWidth,
                  prevHeight,
                },
                this.props.socket
              );
            }
          } else {
            if ((isTyping === true || forceSave) && !syncUndo) {
              this.props.writeText(
                {
                  selectedItem: this.state.textId,
                  container: "workspaceChildren",
                  toUpdate: toUpdate.toJS(),
                  isTyping: isTyping && forceSave,
                },
                this.props.socket
              );
            }
            if (syncUndo) {
              const currentInnerHTML = toUpdate.getIn(["textData", "htmlText"]);
              let undoRedoUpdate = false;
              if (this.state.previousSyncHtml === currentInnerHTML) {
                undoRedoUpdate = true;
                if (saveText) {
                  return;
                }
              }
              toUpdate = toUpdate.set("x", newText.get("x"));
              toUpdate = toUpdate.set("y", newText.get("y"));
              toUpdate = toUpdate.set("width", newText.get("width"));
              toUpdate = toUpdate.set("height", newText.get("height"));

              const { prevHtml, prevX, prevY, prevWidth, prevHeight } =
                this.getPreviousUndoSyncData(undoRedoUpdate, currentInnerHTML);

              this.setState({
                previousSyncHtml: currentInnerHTML,
                prevSyncX: newText.get("x"),
                prevSyncY: newText.get("y"),
                prevSyncWidth: newText.get("width"),
                prevSyncHeight: newText.get("height"),
              });
              this.props.syncText(
                {
                  selectedItem: this.state.textId,
                  container: "workspaceChildren",
                  toUpdate: toUpdate.toJS(),
                  isTyping: false,
                  prevHtml,
                  prevX,
                  prevY,
                  prevWidth,
                  prevHeight,
                },
                this.props.socket
              );
            }
          }
        } else {
          toUpdate = toUpdate.set("x", newText.get("x"));
          toUpdate = toUpdate.set("y", newText.get("y"));
          toUpdate = toUpdate.set("width", newText.get("width"));
          toUpdate = toUpdate.set("height", newText.get("height"));

          this.props.syncText(
            {
              selectedItem: this.state.textId,
              container: !this.props.isGrouped
                ? "workspaceItems"
                : "workspaceChildren",
              toUpdate: toUpdate.toJS(),
              isTyping: false,
            },
            this.props.socket
          );
        }
      }
    } else {
      if (
        textItem.getIn(["textData", "formats", "containerStyle", "margin"]) !==
        undefined
      ) {
        elementWidth += this.props.textOffset * this.props.zoomFactor;
        aHeight += this.props.textOffset * this.props.zoomFactor;
      }
      let alignment = textItem.getIn([
        "textData",
        "formats",
        "containerStyle",
        "textAlign",
      ]);
      const isRTL = textItem.getIn(["textData", "formats", "others", "isRTL"]);

      if (!alignment) alignment = "left";

      if (isRTL) alignment = alignment === "left" ? "right" : alignment;

      let toUpdate = fromJS({ textData: textItem.get("textData") });
      let xToUpdate;

      if (!widthRestricted) {
        let bufferWidth = 0;
        let centerBufferWidth = 0;
        if (
          textItem.getIn([
            "textData",
            "formats",
            "containerStyle",
            "margin",
          ]) === "0px" &&
          !isFixedWidth
        ) {
          bufferWidth = 3;
          centerBufferWidth = 1.5;
        }
        const dW = elementWidth - parseInt(preWidth, 10);
        let widthToUpdate =
          textItem.get("width") + dW / this.props.zoomFactor + bufferWidth;
        if (alignment === "left") {
          if (
            textItem.get("x") + textItem.get("width") <
            this.props.workspaceWidth
          ) {
            if (textItem.get("x") + widthToUpdate > this.props.workspaceWidth)
              widthToUpdate = this.props.workspaceWidth - textItem.get("x");
            if (widthToUpdate > minWidth) {
              toUpdate = toUpdate.set("width", widthToUpdate);
            } else {
              toUpdate = toUpdate.set("width", minWidth);
            }
          } else if (!isFixedWidth) {
            aHeight =
              textElement.scrollHeight !== undefined &&
              textElement.scrollHeight !== null &&
              textElement.scrollHeight !== 0
                ? textElement.scrollHeight
                : aHeight;
            toUpdate = toUpdate.setIn(
              ["textData", "formats", "others", "isFixedWidth"],
              true
            );
          }
        } else if (alignment === "right") {
          if (textItem.get("x") > 0) {
            xToUpdate =
              textItem.get("x") - dW / this.props.zoomFactor - bufferWidth;
            if (xToUpdate < 0) xToUpdate = 0;
            if (widthToUpdate > minWidth) {
              toUpdate = toUpdate
                .set("x", xToUpdate)
                .set("width", widthToUpdate);
            } else {
              xToUpdate = textItem.get("x") + textItem.get("width") - minWidth;
              toUpdate = toUpdate.set("width", minWidth).set("x", xToUpdate);
            }
          } else if (!isFixedWidth) {
            aHeight =
              textElement.scrollHeight !== undefined &&
              textElement.scrollHeight !== null &&
              textElement.scrollHeight !== 0
                ? textElement.scrollHeight
                : aHeight;
            toUpdate = toUpdate.setIn(
              ["textData", "formats", "others", "isFixedWidth"],
              true
            );
          }
        } else if (alignment === "center") {
          if (
            textItem.get("x") > 0 &&
            textItem.get("x") + textItem.get("width") <
              this.props.workspaceWidth
          ) {
            xToUpdate =
              textItem.get("x") -
              dW / 2 / this.props.zoomFactor -
              centerBufferWidth;
            if (xToUpdate < 0) xToUpdate = 0;
            if (xToUpdate + widthToUpdate > this.props.workspaceWidth)
              widthToUpdate = this.props.workspaceWidth - xToUpdate;
            if (widthToUpdate > minWidth) {
              toUpdate = toUpdate
                .set("width", widthToUpdate)
                .set("x", xToUpdate);
            } else {
              xToUpdate =
                textItem.get("x") + textItem.get("width") / 2 - minWidth / 2;
              toUpdate = toUpdate.set("width", minWidth).set("x", xToUpdate);
            }
          } else if (!isFixedWidth) {
            aHeight =
              textElement.scrollHeight !== undefined &&
              textElement.scrollHeight !== null &&
              textElement.scrollHeight !== 0
                ? textElement.scrollHeight
                : aHeight;
            toUpdate = toUpdate.setIn(
              ["textData", "formats", "others", "isFixedWidth"],
              true
            );
          }
        }
      }

      if (textHeight !== aHeight && aHeight !== 0) {
        const fontSize = parseFloat(
          textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]),
          10
        );
        const lineHeight = parseFloat(
          textItem.getIn([
            "textData",
            "formats",
            "containerStyle",
            "lineHeight",
          ]),
          10
        );
        const minHeight = parseFloat(
          fontSize * lineHeight * this.props.zoomFactor,
          10
        );
        aHeight = aHeight < minHeight ? minHeight : aHeight;
        const dH = aHeight / this.props.zoomFactor - textItem.get("height");
        let curY, curHeight;
        if (verticalAlign === "middle") {
          curY = -1 * (dH / 2);
          curHeight = dH;
        } else if (verticalAlign === "bottom") {
          curY = -1 * dH;
          curHeight = dH;
        } else {
          curY = 0;
          curHeight = dH;
        }
        toUpdate = toUpdate.set("y", textItem.get("y") + curY);
        toUpdate = toUpdate.set("height", textItem.get("height") + curHeight);
      }
      toUpdate = toUpdate.setIn(
        ["textData", "htmlText"],
        textContainer.innerHTML
      );

      if (!toUpdate.isEmpty()) {
        const newText = textItem.merge(toUpdate);
        if (!textItem.equals(newText) || !isTyping) {
          const currentInnerHTML = toUpdate.getIn(["textData", "htmlText"]);
          let preventSync = false;
          if (
            currentInnerHTML === "<br>" ||
            currentInnerHTML === "" ||
            currentInnerHTML === "<div><br></div>"
          ) {
            preventSync = true;
            this.waitForSyncEmit = Date.now();
          }
          if ((isTyping === true || forceSave) && (!syncUndo || preventSync)) {
            document.getElementById(this.state.textId).style.transform =
              "translate(" +
              parseFloat(newText.get("x") * this.props.zoomFactor).toFixed(2) +
              "px, " +
              parseFloat(newText.get("y") * this.props.zoomFactor).toFixed(2) +
              ") rotateZ(" +
              newText.get("angle") +
              "deg)";
            document.getElementById(this.state.textId).style.width =
              parseFloat(newText.get("width") * this.props.zoomFactor).toFixed(
                2
              ) + "px";
            document.getElementById(this.state.textId).style.height =
              parseFloat(newText.get("height") * this.props.zoomFactor).toFixed(
                2
              ) + "px";
            this.props.writeText(
              {
                selectedItem: this.state.textId,
                container: "workspaceItems",
                toUpdate: toUpdate.toJS(),
                isTyping: isTyping && forceSave,
              },
              this.props.socket
            );
          }
          if (syncUndo && preventSync === false) {
            let undoRedoUpdate = false;
            if (this.state.previousSyncHtml === currentInnerHTML) {
              undoRedoUpdate = true;
              if (saveText) {
                return;
              }
            }
            toUpdate = toUpdate.set("x", newText.get("x"));
            toUpdate = toUpdate.set("y", newText.get("y"));
            toUpdate = toUpdate.set("width", newText.get("width"));
            toUpdate = toUpdate.set("height", newText.get("height"));

            let previousSyncData = {};
            if (this.props.text.getIn(["textData", "htmlText"]) !== "") {
              previousSyncData = this.getPreviousUndoSyncData(
                undoRedoUpdate,
                currentInnerHTML
              );
            } else if (this.props.lastEditText.get("beforeEmpty") !== "") {
              previousSyncData.prevHtml =
                this.props.lastEditText.get("beforeEmpty");
              previousSyncData.prevX = this.props.lastEditText.get("prevX");
              previousSyncData.prevY = this.props.lastEditText.get("prevY");
              previousSyncData.prevWidth =
                this.props.lastEditText.get("prevWidth");
              previousSyncData.prevHeight =
                this.props.lastEditText.get("prevHeight");
            }

            this.setState({
              previousSyncHtml: currentInnerHTML,
              prevSyncX: newText.get("x"),
              prevSyncY: newText.get("y"),
              prevSyncWidth: newText.get("width"),
              prevSyncHeight: newText.get("height"),
            });

            this.props.syncText(
              {
                selectedItem: this.state.textId,
                container: "workspaceItems",
                toUpdate: toUpdate.toJS(),
                isTyping,
                ...previousSyncData,
              },
              this.props.socket
            );
          }
        }
      }
    }
  }

  getWorkspaceBounds() {
    return document.getElementById("workspace").getBoundingClientRect();
  }

  handleTextClick() {
    if (!this.props.transformStatus.get("transforming")) {
      if (
        !(this.props.text.get("type") === "SHAPE" && !this.props.isGrouped) && // except when ungrouped shape is clicked (since they should be focused only on double click)
        !this.props.textStatus.get("isFocused")
      ) {
        if (this.state.canFocusOnClick) {
          this.props.updateTextStatus({
            id: this.state.textId,
            isFocused: true,
            container: this.state.container,
            isRTL: this.props.text.getIn([
              "textData",
              "formats",
              "others",
              "isRTL",
            ]),
            isGrouped: this.props.isGrouped,
            fontSize: parseFloat(
              this.props.text.getIn([
                "textData",
                "formats",
                "containerStyle",
                "fontSize",
              ]),
              10
            ),
            type: this.props.text.get("type"),
            textGroup:
              this.props.parentItem &&
              this.props.parentItem.get("subType") === "TXTGRP",
          });
        } else {
          this.setState({
            canFocusOnClick: true,
          });
        }
      }

      let bulletOption = "none";
      if (document.queryCommandState("insertUnorderedList")) {
        bulletOption = "unOrderedList";
      } else if (document.queryCommandState("insertOrderedList")) {
        bulletOption = "orderedList";
      }

      this.props.setTextOptions({
        color: rgbString2hex(document.queryCommandValue("ForeColor")),
        fontWeight: document.queryCommandState("Bold") ? "bold" : null,
        fontStyle: document.queryCommandState("Italic") ? "italic" : null,
        textDecoration: document.queryCommandState("Underline")
          ? "underline"
          : null,
        fontFamily: document
          .queryCommandValue("FontName")
          .replace(/['"]+/g, ""),
        isBullet:
          document.queryCommandState("insertUnorderedList") ||
          document.queryCommandState("insertOrderedList"),
        bullet: bulletOption,
        colors: [],
      });
    }

    if (this.state.canFocusOnClick) {
      this.setState({
        canFocusOnClick: false,
      });
    }
  }

  handleTextDoubleClick() {
    if (!this.props.transformStatus.get("transforming")) {
      if (
        !this.props.textStatus.get("isFocused") &&
        this.props.text.get("type") === "SHAPE" &&
        !this.props.isGrouped
      ) {
        this.props.updateTextStatus({
          id: this.state.textId,
          isFocused: true,
          container: this.state.container,
          isRTL: this.props.text.getIn([
            "textData",
            "formats",
            "others",
            "isRTL",
          ]),
          isGrouped: this.props.isGrouped,
          fontSize: parseFloat(
            this.props.text.getIn([
              "textData",
              "formats",
              "containerStyle",
              "fontSize",
            ]),
            10
          ),
          type: this.props.text.get("type"),
          textGroup:
            this.props.parentItem &&
            this.props.parentItem.get("subType") === "TXTGRP",
        });
      }
    }

    if (this.state.canFocusOnClick) {
      this.setState({
        canFocusOnClick: false,
      });
    }
  }

  hasUndoRedoAffectedText(currentInnerHtml, prevSyncHtml) {
    let curHasPrevSync = false,
      prevSyncHasCur = false;
    if (currentInnerHtml != null && prevSyncHtml != null) {
      currentInnerHtml = currentInnerHtml.replaceAll("&nbsp;", "");
      prevSyncHtml = prevSyncHtml.replaceAll("&nbsp;", "");
      curHasPrevSync = currentInnerHtml.includes(prevSyncHtml); // should be true
      prevSyncHasCur = prevSyncHtml.includes(currentInnerHtml); // should be false
    }
    if (curHasPrevSync && !prevSyncHasCur) return true;
    return false;
  }

  handleMouseUp(e) {
    try {
      if (
        !this.props.transformStatus.get("transforming") &&
        this.props.textStatus.get("id") !== null
      ) {
        const mouseUpOnSelectionBox =
          this.props.isMouseOnSelectionBox &&
          this.props.isMouseOnSelectionBox({
            event: e,
          });
        const mouseUpOnTextSelectionBox =
          this.props.isMouseOnSelectionBox &&
          this.props.isMouseOnSelectionBox({
            event: e,
            uniqueClassName: "selectionBox text-content",
          });
        if (
          !(
            isMouseOn(e, "text-container") ||
            isMouseOn(e, "callout-text") ||
            mouseUpOnTextSelectionBox ||
            (this.props.textStatus.get("isFocused") &&
              this.props.text.get("type") === "SHAPE" &&
              !this.props.isGrouped) ||
            isMouseOn(e, "tool-bar")
          ) &&
          !this.isTextSelected()
        ) {
          e.preventDefault();
          if (
            !this.props.textStatus.get("isFocused") &&
            this.props.text.get("type") === "SHAPE"
          )
            this.onBlur(mouseUpOnSelectionBox);
        }
      }
      // to delete text while focusing other objects in workspace --
      const innerHtml = this.state.html;
      if (
        !this.props.isGrouped &&
        this.htmlEl &&
        this.htmlEl.htmlContent &&
        !this.htmlEl.htmlContent.contains(e.target) &&
        this.props.text.get("subType") === "DTXT" &&
        (innerHtml === "<br>" ||
          innerHtml === "" ||
          innerHtml === "<div><br></div>")
      ) {
        // delete empty text only when clicked out of container
        this.props.deleteObject({
          toUpdate: [
            {
              id: this.state.textId,
              container: !this.props.isGrouped
                ? "workspaceItems"
                : "workspaceChildren",
              isDelete: true,
            },
          ],
          isTextEmptyData: true,
        });
      }
    } catch (error) {
      //
    }
  }

  handleMouseDown(e) {
    try {
      if (
        document.elementFromPoint(e.clientX, e.clientY).getAttribute("id") ===
          this.props.selectedItems.first() &&
        !document
          .elementFromPoint(e.clientX, e.clientY)
          .classList.contains("text-container")
      ) {
        e.preventDefault();
      }

      if (!this.props.transformStatus.get("transforming")) {
        const mouseUpOnSelectionBox =
          this.props.isMouseOnSelectionBox &&
          this.props.isMouseOnSelectionBox({
            event: e,
          });
        const mouseUpOnTextSelectionBox =
          this.props.isMouseOnSelectionBox &&
          this.props.isMouseOnSelectionBox({
            event: e,
            uniqueClassName: "selectionBox text-content",
          });
        if (
          !(
            isMouseOn(e, "text-container") ||
            isMouseOn(e, "callout-text") ||
            mouseUpOnTextSelectionBox ||
            isMouseOn(e, "tool-bar")
          ) &&
          document.elementFromPoint(e.clientX, e.clientY).getAttribute("id") !==
            this.props.selectedItems.first()
        ) {
          e.preventDefault();
          this.onBlur(mouseUpOnSelectionBox);
        }
      }
    } catch (error) {
      //
    }
  }

  /**
   * @summary the below function will flip textLoadingStaus when text root fontFamily is loaded in the document.
   * @todo load the bold and italic fot face of the default text if required in future.
   */
  isAllFontFamilyIsLoaded() {
    const fontFamiliesToLoad = [];
    const textElement = this.props.text.getIn(["textData", "htmlText"]);
    let fontFamilies = preloadTextFonts(textElement);
    const rootFontFamily = this.props.text.getIn([
      "textData",
      "formats",
      "containerStyle",
      "fontFamily",
    ]);
    fontFamilies = fontFamilies.map((data) => data.load);
    if (rootFontFamily) {
      fontFamilies.unshift(rootFontFamily);
    } else {
      this.setState({ isFontFamilyLoaded: true });
    }
    if (this.props.loadedFonts) {
      const loadedFonts = this.props.loadedFonts.get("fonts");
      fontFamilies.forEach((fontFamily) => {
        const letRootFontData = loadedFonts
          ? loadedFonts.find((x) => x.get("load") === fontFamily)
          : undefined;
        if (letRootFontData !== undefined && fontFamily !== undefined) {
          if (letRootFontData.get("isLoaded")) {
            const tempText = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ, ابپتجچحخدذرزسشصضططعغفقکگلمنوهی`;
            fontFamiliesToLoad.push(
              document.fonts.load(`12px ${fontFamily}`, tempText)
            );
          } else {
            // respected fontFamily css is not loaded yet.
          }
        } else {
          // no fontFamily is present in library.
          const letRootFontData = loadedFonts
            ? loadedFonts.find(
                (x) => x.get("load") === fontFamily.split(",")[0]
              )
            : undefined;
          if (letRootFontData === undefined || fontFamily === undefined) {
            this.setState({ isFontFamilyLoaded: true });
          }
        }
      });
    }
    if (fontFamiliesToLoad.length) {
      Promise.all(fontFamiliesToLoad)
        .then(() => {
          // FontFamily loaded successFully.
        })
        .catch(() => {
          // errorlog(error, error.message, "TextComponent");
        })
        .finally(() => {
          // fliping the state even it fails to load the fontFamily, cause fallBack fontFamily will applied to the text.
          this.setState({ isFontFamilyLoaded: true });
        });
    }
  }

  isTextSelected() {
    let textSelected = false;

    if (window.getSelection) {
      textSelected = !(
        window.getSelection().focusOffset === window.getSelection().anchorOffset
      );
    } else if (document.getSelection) {
      textSelected = !(
        document.getSelection().focusOffset ===
        document.getSelection().anchorOffset
      );
    } else if (document.selection) {
      textSelected = document.selection.createRange().text !== "";
    }

    return textSelected;
  }

  onBlur(isSelected) {
    try {
      this.typingTimer = null;

      let syncUndo;
      if (this.state.isTyping === true) {
        syncUndo = true;
        this.setState({ isTyping: false });
      }

      if (this.state.focused) {
        if (this.state.focused && this.props.textStatus.get("isFocused")) {
          this.setState({ focused: false });
          const innerHtml = this.state.html;

          let textItem = this.props.text;
          if (
            innerHtml === "<br>" ||
            innerHtml === "" ||
            innerHtml === "<div><br></div>"
          ) {
            if (
              this.props.text.get("type") === "SHAPE" ||
              this.props.isGrouped
            ) {
              textItem = textItem.setIn(["textData", "htmlText"], "&nbsp;");
              textItem = textItem.setIn(["textData", "splittedText"], "&nbsp;");

              this.props.changeText(
                {
                  active: this.state.textId,
                  text: textItem,
                  container: this.state.container,
                },
                this.props.socket
              );
            } else {
              this.props.deleteObject({
                toUpdate: [
                  {
                    id: this.state.textId,
                    container: !this.props.isGrouped
                      ? "workspaceItems"
                      : "workspaceChildren",
                    isDelete: true,
                  },
                ],
                isTextEmptyData: true,
              });
            }
          } else if (
            !this.props.isGrouped ||
            (this.props.isGrouped &&
              this.props.parentItem.get("subType") === "TXTGRP")
          ) {
            this.getUpdatedText(false, true, false, false, syncUndo);
          }
          this.props.updateTextStatus({
            ...(!isSelected && { id: null }),
            isSelected,
            isFocused: false,
            container: null,
            isRTL: false,
            isGrouped: false,
          });
        }

        if (window.getSelection) {
          // All browsers, except IE <=8
          window.getSelection().removeAllRanges();
        } else if (document.selection) {
          // IE <=8
          document.selection.empty();
        }
      }
    } catch (error) {
      //
    }
  }

  onFocus() {
    try {
      if (!this.state.focused) {
        this.htmlEl.htmlContent.focus();
        this.setState({ focused: true });
      }
    } catch (error) {
      //
    }
  }

  saveText() {
    if (!this.typingTimer) return;
    this.typingTimer = null;
    if (
      !this.props.isGrouped ||
      (this.props.isGrouped &&
        this.props.parentItem.get("subType") === "TXTGRP")
    ) {
      this.getUpdatedText(false, true, false, true, true);
    } else {
      this.updateGroupedText(false, true, false, true);
    }
  }

  setTypeTimeout(e) {
    try {
      // eslint-disable-next-line
      if (
        e.type === "keyup" &&
        (e.keyCode !== 8 || e.keyCode !== 17 || e.keyCode !== 91)
      )
        return;
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => this.saveText(), this.typeTimeout);
    } catch (error) {
      //
    }
  }

  updateGroupedText(fontSizeChanged, saveText, isTyping, syncUndo = false) {
    const textItem = this.props.text;
    const textElement = document.getElementById(this.state.textId);
    const textContainer =
      textElement.getElementsByClassName("text-container")[0];
    let parentX, parentY, parentHeight, parentAngle, parentElement, parentItem;
    if (this.props.isGrouped) {
      parentElement = document.getElementById(this.props.data_id);
      parentItem = this.props.parentItem;
      parentX = (parentItem.get("x") * this.props.zoomFactor).toFixed(2);
      parentY = (parentItem.get("y") * this.props.zoomFactor).toFixed(2);
      parentHeight = parentItem.get("height");
      parentAngle = parentItem.get("angle");
    } else {
      parentX = (textItem.get("x") * this.props.zoomFactor).toFixed(2);
      parentY = (textItem.get("y") * this.props.zoomFactor).toFixed(2);
      parentHeight = textItem.get("height");
      parentAngle = textItem.get("angle");
      parentElement = textElement;
    }

    const itemY = textItem.get("yRatio")
        ? (textItem.get("yRatio") * parentHeight) / 100
        : textItem.get("y"),
      itemHeight = textItem.get("heightRatio")
        ? (textItem.get("heightRatio") * parentHeight) / 100
        : textItem.get("height");

    let parentFlip = "";
    if (!this.props.isGrouped) {
      if (textItem.get("type") === "SHAPE") {
        const flipPos = textItem.get("flipPosition");
        parentFlip =
          flipPos === 1
            ? " scaleX(-1)"
            : flipPos === 2
            ? " scaleY(-1)"
            : flipPos === 3
            ? " scaleX(-1) scaleY(-1)"
            : "";
      }
    }
    parentElement.style.transform =
      "translate(" +
      parentX +
      "px, " +
      parentY +
      "px) rotateZ(0deg)" +
      parentFlip;
    let flip = "";
    if (this.props.isGrouped) {
      if (textItem.get("type") === "SHAPE") {
        const flipPos = textItem.get("flipPosition");
        flip =
          flipPos === 1
            ? " scaleX(-1)"
            : flipPos === 2
            ? " scaleY(-1)"
            : flipPos === 3
            ? " scaleX(-1) scaleY(-1)"
            : "";
      }
      textElement.style.transform =
        "translate(" +
        (
          (textItem.get("x") - parentItem.get("x")) *
          this.props.zoomFactor
        ).toFixed(2) +
        "px, " +
        (
          (textItem.get("y") - parentItem.get("y")) *
          this.props.zoomFactor
        ).toFixed(2) +
        "px) rotateZ(0deg)" +
        flip;
      textElement.style.height =
        textItem.get("height") * this.props.zoomFactor + "px";
    }
    const range = document.createRange();
    range.selectNode(textContainer);

    const rangeBounds = range.getBoundingClientRect();

    parentElement.style.transform =
      "translate(" +
      parentX +
      "px, " +
      parentY +
      "px) rotateZ(" +
      parentAngle +
      "deg)" +
      parentFlip;

    if (this.props.isGrouped) {
      flip = "";
      if (textItem.get("type") === "SHAPE") {
        const flipPos = textItem.get("flipPosition");
        flip =
          flipPos === 1
            ? " scaleX(-1)"
            : flipPos === 2
            ? " scaleY(-1)"
            : flipPos === 3
            ? " scaleX(-1) scaleY(-1)"
            : "";
      }
      textElement.style.transform =
        "translate(" +
        (
          (textItem.get("x") - parentItem.get("x")) *
          this.props.zoomFactor
        ).toFixed(2) +
        "px, " +
        (
          (textItem.get("y") - parentItem.get("y")) *
          this.props.zoomFactor
        ).toFixed(2) +
        "px) rotateZ(" +
        textItem.get("angle") +
        "deg)" +
        flip;
    }
    let textHeight = rangeBounds.height / this.props.zoomFactor;

    let newText = textItem;
    if (textItem.get("type") !== "SHAPE") {
      textHeight += this.props.textOffset;
      if (itemHeight !== parseFloat(textHeight.toFixed(2)) || syncUndo) {
        const dH = textHeight - itemHeight,
          dW = 0;
        let toUpdate = {
          dW,
          dH,
          itemY,
          verticalAlign: textItem.get("verticalAlign"),
          currentId: this.state.textId,
          groupId: this.props.data_id,
          isGrouped: this.props.isGrouped,
          selectedItems: this.props.selectedItems,
        };
        toUpdate.textData = textItem
          .setIn(["textData", "htmlText"], textContainer.innerHTML)
          .get("textData")
          .toJS();
        newText = textItem.merge(toUpdate);
        if (syncUndo) {
          const currentInnerHTML = textContainer.innerHTML;
          let undoRedoUpdate = false;
          if (this.state.previousSyncHtml === currentInnerHTML) {
            undoRedoUpdate = true;
            if (saveText) {
              return;
            }
          }
          const hasNoChangeFromUndo = this.hasUndoRedoAffectedText(
            currentInnerHTML,
            this.state.previousSyncHtml
          );
          let prevHtml;
          if (
            this.state.previousSyncHtml != null &&
            undoRedoUpdate === false &&
            hasNoChangeFromUndo
          ) {
            prevHtml = this.state.previousSyncHtml;
          } else {
            prevHtml = textItem.getIn(["textData", "htmlText"]);
          }

          this.setState({
            previousSyncHtml: currentInnerHTML,
          });

          toUpdate = { ...toUpdate, prevHtml };
        }
        if (!textItem.equals(newText) || !isTyping)
          this.props.updateGroupText(toUpdate, this.props.socket);
      } else {
        let toUpdate = fromJS({ textData: textItem.get("textData") });
        toUpdate = toUpdate.setIn(
          ["textData", "htmlText"],
          textContainer.innerHTML
        );

        newText = textItem.merge(toUpdate);
        if (!textItem.equals(newText) || !isTyping) {
          if (isTyping === true) {
            this.props.writeText(
              {
                selectedItem: this.state.textId,
                container: this.props.isGrouped
                  ? "workspaceChildren"
                  : "workspaceItems",
                toUpdate: toUpdate.toJS(),
                isTyping,
              },
              this.props.socket
            );
          } else {
            toUpdate = toUpdate.set("x", newText.get("x"));
            toUpdate = toUpdate.set("y", newText.get("y"));
            toUpdate = toUpdate.set("width", newText.get("width"));
            toUpdate = toUpdate.set("height", newText.get("height"));
            this.props.writeText(
              {
                selectedItem: this.state.textId,
                container: this.props.isGrouped
                  ? "workspaceChildren"
                  : "workspaceItems",
                toUpdate: toUpdate.toJS(),
                isTyping: false,
              },
              this.props.socket
            );
          }
        }
      }
    } else {
      let toUpdate = fromJS({ textData: textItem.get("textData") });
      const minYRatio =
        textItem.get("minYRatio") !== undefined ? textItem.get("minYRatio") : 5;
      const maxWidthRatio =
        textItem.get("maxWidthRatio") !== undefined
          ? textItem.get("maxWidthRatio")
          : 90;
      const maxHeightRatio =
        textItem.get("maxHeightRatio") !== undefined
          ? textItem.get("maxHeightRatio")
          : 90;

      const maxWidth = (maxWidthRatio * textItem.get("width")) / 100;
      const maxHeight = (maxHeightRatio * textItem.get("height")) / 100;

      if (textHeight > maxHeight) {
        let fontSize =
          parseFloat(
            this.getFontSize(this.state.textId, maxWidth, maxHeight, true),
            10
          ).toFixed(2) / this.props.zoomFactor;

        if (Number.isNaN(fontSize)) fontSize = 10;
        if (fontSizeChanged) {
          if (
            fontSize >
            parseFloat(
              textItem.getIn([
                "textData",
                "formats",
                "containerStyle",
                "fontSize",
              ]),
              10
            )
          ) {
            fontSize = parseFloat(
              textItem.getIn([
                "textData",
                "formats",
                "containerStyle",
                "fontSize",
              ]),
              10
            );
            const container = document
              .getElementById(this.state.textId)
              .getElementsByClassName("callout-text-container")[0];
            const ourText =
              container.getElementsByClassName("text-container")[0];
            ourText.style.fontSize = fontSize * this.props.zoomFactor + "px";
          }
        }
        toUpdate = toUpdate.setIn(
          ["textData", "formats", "containerStyle", "fontSize"],
          fontSize + "px"
        );
      } else {
        const cy = maxHeight / 2 + (minYRatio * textItem.get("height")) / 100;
        const yRatio = ((cy - textHeight / 2) / textItem.get("height")) * 100;
        const heightRatio = (textHeight / textItem.get("height")) * 100;
        toUpdate = toUpdate
          .set("yRatio", yRatio)
          .set("heightRatio", heightRatio);
      }
      toUpdate = toUpdate.setIn(
        ["textData", "htmlText"],
        textContainer.innerHTML
      );
      newText = textItem.merge(toUpdate);
      if ((!textItem.equals(newText) || !isTyping) && !syncUndo) {
        if (isTyping === true) {
          this.props.writeText(
            {
              selectedItem: this.state.textId,
              container: "workspaceChildren",
              toUpdate: toUpdate.toJS(),
              isTyping,
            },
            this.props.socket
          );
        } else {
          toUpdate = toUpdate.set("x", newText.get("x"));
          toUpdate = toUpdate.set("y", newText.get("y"));
          toUpdate = toUpdate.set("width", newText.get("width"));
          toUpdate = toUpdate.set("height", newText.get("height"));
          this.props.writeText(
            {
              selectedItem: this.state.textId,
              container: "workspaceChildren",
              toUpdate: toUpdate.toJS(),
              isTyping: false,
            },
            this.props.socket
          );
        }
      } else if (syncUndo) {
        const currentInnerHTML = toUpdate.getIn(["textData", "htmlText"]);
        let undoRedoUpdate = false;
        if (this.state.previousSyncHtml === currentInnerHTML) {
          undoRedoUpdate = true;
          if (saveText) {
            return;
          }
        }
        toUpdate = toUpdate.set("x", newText.get("x"));
        toUpdate = toUpdate.set("y", newText.get("y"));
        toUpdate = toUpdate.set("width", newText.get("width"));
        toUpdate = toUpdate.set("height", newText.get("height"));

        const { prevHtml, prevX, prevY, prevWidth, prevHeight } =
          this.getPreviousUndoSyncData(undoRedoUpdate, currentInnerHTML);

        this.setState({
          previousSyncHtml: currentInnerHTML,
          prevSyncX: newText.get("x"),
          prevSyncY: newText.get("y"),
          prevSyncWidth: newText.get("width"),
          prevSyncHeight: newText.get("height"),
        });
        this.props.syncText(
          {
            selectedItem: this.state.textId,
            container: "workspaceChildren",
            toUpdate: toUpdate.toJS(),
            isTyping: false,
            prevHtml,
            prevX,
            prevY,
            prevWidth,
            prevHeight,
          },
          this.props.socket
        );
      }
    }
  }

  render() {
    const styles = this.props.text
      .getIn(["textData", "formats", "containerStyle"])
      .toObject();

    let classes = "text-container scene-item-inner";

    if (this.props.isGrouped) {
      classes += " is-child-text";
    }

    if (
      this.props.textStatus.get("isFocused") &&
      this.props.textStatus.get("id") === this.state.textId
    )
      classes += " selected";
    else {
      classes += " unselectable";
    }

    if (this.props.text.getIn(["textData", "formats", "others", "isRTL"])) {
      classes += " rtl-text";
    }

    let padding = 0;
    if (
      this.props.text.getIn(["textData", "formats", "bullet", "type"]) !==
        "none" ||
      this.props.text.getIn(["textData", "formats", "bullet", "type"]) !== ""
    ) {
      if (
        this.props.text.getIn([
          "textData",
          "formats",
          "bullet",
          "bulletSpace",
        ]) !== undefined
      ) {
        classes +=
          " bspace bspace" +
          this.props.text.getIn([
            "textData",
            "formats",
            "bullet",
            "bulletSpace",
          ]);
        padding = this.props.text.getIn([
          "textData",
          "formats",
          "bullet",
          "bulletSpace",
        ]);
      }
    }
    padding = padding * this.props.zoomFactor;
    styles["--paddingSpace"] = padding + "px";

    const innerX = this.props.text.get("innerX")
      ? this.props.text.get("innerX")
      : 0;
    const innerY = this.props.text.get("innerY")
      ? this.props.text.get("innerY")
      : 0;

    styles.transform =
      "translate(" +
      innerX * this.props.zoomFactor +
      "px, " +
      innerY * this.props.zoomFactor +
      "px)";

    const currentEffects = this.props.text.getIn(["textData", "effects"]);
    const fontSize = this.props.text.getIn([
      "textData",
      "formats",
      "containerStyle",
      "fontSize",
    ]);
    const fontColor = this.props.text.getIn([
      "textData",
      "formats",
      "containerStyle",
      "color",
    ]);
    if (currentEffects && currentEffects !== "None") {
      const effect = convertEffectValuesToStyles(
        currentEffects.toJS(),
        fontSize,
        fontColor
      );
      styles.textShadow = effect.textShadow;
      styles.webkitTextFillColor = effect.webkitTextFillColor;
      styles.webkitTextStroke = effect.webkitTextStroke;
      styles.filter = effect.filter;
    }

    let isEditable = false;
    if (
      this.props.textStatus.get("id") === this.state.textId &&
      this.props.textStatus.get("isFocused") &&
      !this.props.isPlay &&
      !this.props.isPlayAll
    ) {
      isEditable = true;
    }

    return (
      <ContentEditable
        clearSaveText={this.clearSaveText}
        isReady={this.state.isFontFamilyLoaded}
        dataId={this.props.data_id}
        textId={this.state.textId}
        isEditable={isEditable}
        hyperLinkText={this.props.text.getIn(["textData", "link"])}
        height={this.props.text.get("height")}
        otherOptions={this.props.text.getIn(["textData", "formats", "others"])}
        zoomFactor={this.props.zoomFactor}
        dataGroupId={this.props.itemId}
        html={this.props.text.getIn(["textData", "htmlText"])}
        ref={(e) => {
          this.htmlEl = e;
        }}
        styles={fromJS(styles)}
        classes={classes}
        handleDoubleClick={this.handleTextDoubleClick}
        handleClick={this.handleTextClick}
        selectedAll={this.props.textStatus.get("selectedAll")}
        onChange={this.emitChange}
        fontStatus={this.props.fontStatus}
        onFocusIn={this.onFocus}
        isBullet={
          this.props.text.getIn(["textData", "formats", "bullet", "type"]) !==
          "none"
        }
        isOrderList={
          this.props.text.getIn(["textData", "formats", "bullet", "type"]) ===
          "orderedList"
        }
        isPlay={this.props.isPlay}
        isPlayAll={this.props.isPlayAll}
        text={this.props.text}
        socket={this.props.socket}
      />
    );
  }
}

TextComponent.propTypes = {
  isGrouped: PropTypes.bool,
  data_id: PropTypes.string,
  itemId: PropTypes.string,
  textStatus: PropTypes.object,
  text: PropTypes.object,
  childrenSelection: PropTypes.object,
  transformStatus: PropTypes.object,
  selectedItems: PropTypes.object,
  updateTextStatus: PropTypes.func,
  parentItem: PropTypes.object,
  setTextOptions: PropTypes.func,
  workspaceItems: PropTypes.object,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  deleteObject: PropTypes.func,
  zoomFactor: PropTypes.number,
  fontStatus: PropTypes.object,
  loadedFonts: PropTypes.object,
  writeText: PropTypes.func,
  syncText: PropTypes.func,
  socket: PropTypes.object,
  textOffset: PropTypes.number,
  workspaceWidth: PropTypes.number,
  lastEditText: PropTypes.object,
  setLastEditText: PropTypes.func,
  isMouseOnSelectionBox: PropTypes.func,
  changeText: PropTypes.func,
  updateGroupText: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  parentItem: ownProps.isGrouped
    ? state.projectDetails.getIn(["workspaceItems", ownProps.data_id])
    : null,
  workspaceWidth: state.projectDetails.get("width"),
  selectedItems: state.app.get("selectedItems"),
  zoomFactor: state.app.get("zoomFactor"),
  isPlay: false,
  isPlayAll: state.app.get("isPlayAll"),
  textStatus: state.app.get("textStatus"),
  transformStatus: state.app.get("transformStatus"),
  workspaceItems: state.projectDetails.get("workspaceItems"),
  fontStatus: state.app
    .getIn(["loadedFonts", "fonts"])
    .find(
      (x) =>
        x.get("load") ===
        ownProps.text.getIn([
          "textData",
          "formats",
          "containerStyle",
          "fontFamily",
        ])
    ),
  loadedFonts: state.app.get("loadedFonts"),
  textOffset:
    ownProps.text.getIn(["textData", "formats", "containerStyle", "margin"]) ===
    "0px"
      ? state.app.get("textOffset")
      : 30,
  lastEditText: state.app.get("lastEditText"),
  childrenSelection: state.app.get("childrenSelection"),
});

const mapDispatchToProps = (dispatch) => ({
  changeText: (payload) => dispatch(changeText(payload)),
  deleteObject: (payload) => dispatch(deleteObject(payload)),
  updateTextStatus: (data) => dispatch(updateTextStatus(data)),
  setTextOptions: (data) => dispatch(setTextOptions(data)),
  updateGroupText: (data) => dispatch(updateGroupText(data)),
  syncText: (data) => dispatch(syncText(data)),
  writeText: (data) => dispatch(writeText(data)),
  setLastEditText: (data) => dispatch(setLastEditText(data)),
});

const Text = connect(mapStateToProps, mapDispatchToProps)(TextComponent);

export default Text;
