import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import Typography from "../../../common-components/Typography";
import { Close } from "../../header/header-components";
import { font } from "../../../constants/font";
import content from "../../../constants/content";
import Spinner, { SPINNER_TYPE } from "../../../common-components/Spinner";
import { Container } from "../modal-components";
import useNotify from "../../../helper/hooks/useNotify";
import vmTheme from "../../../constants/theme";

const ExportLoader = ({ modalHandler, exportStatus }) => {
  const { warn } = useNotify();
  const theme = useSelector((state) => state.app.get("theme"));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (exportStatus === "FAILED") {
      warn(content.FAILED_TO_DOWNLOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus]);
  return (
    <Container>
      <Spinner type={SPINNER_TYPE.DUAL_RING_SPINNER} spinnerHeight={"130px"} />
      <Typography
        enableTrim={false}
        innerContent="Please Wait, we are processing your video"
        color={vmTheme[theme].exportLoaderTxtColor}
        align="center"
        display="block"
      />
      <Typography
        innerContent="Export in Progress"
        color={vmTheme[theme].blackColor}
        align="center"
        display="block"
        margin="10px 0"
        padding="2px 0"
      />
      <Typography
        enableTrim={false}
        innerContent="Note: Once this process is completed. You can Publish your project for your required mediums"
        color={vmTheme[theme].closeIconColor}
        align="center"
        display="block"
        margin="10px 0"
        padding="2px 0"
        font={font.medium}
      />
      <Close onClick={modalHandler}>
        <Typography
          enableTrim={false}
          innerContent="Close"
          color={vmTheme[theme].blackColor}
          align="center"
          display="block"
          margin="30px 0 0"
          padding="2px 0"
          cursor="pointer"
          hoverColor={vmTheme[theme].closeIconColor}
        />
      </Close>
    </Container>
  );
};

ExportLoader.propTypes = {
  modalHandler: PropTypes.func,
  exportStatus: PropTypes.string,
};

export default ExportLoader;
