/* eslint-disable jsx-a11y/no-static-element-interactions, camelcase, no-bitwise, object-shorthand, 
prefer-const, react/no-unused-state, react/sort-comp, prefer-destructuring, prefer-template,
no-continue, no-restricted-syntax */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ColorButton, ColorButtonContainer, ColorGroup, ColorList, ColorPickerContainer } from "./color-components";
import { STATIC_PATH } from "../../../../constants/config";
import { getFrameDropAreaId, getFrameSvgId } from "../../../frame/frame-helper";
import { isAllTextSelected, isTextSelected } from "../../../text/TextHelper";
import { DialogBox } from "./propertywindow-dialogbox";
import { setWorkspaceTextFocus, updateRecentColors } from "../../../../redux/actions/appUtils";

const STICKERIFY = "STICKERIFY";
const TEXT_EFFECT_SECTION = "TXT_EFF_SEC";

class ColorPaletteWindowComponent extends React.Component {

    constructor(props) {
        super(props);
        this.recentColors = this.props.recentColors;

        if (this.props.currentColor) this.recentColors = this.recentColors.unshift(this.props.currentColor);
        if (this.recentColors.size > 9) this.recentColors = this.recentColors.slice(0, 9);

        this.state = {
            showPaletteDisplay: false,
            showColorPicker: false,
            selectedIndex: 0,
            hexInput: "000000",
            h: 0,
            s: 100,
            v: 50,
            l: 50,
            a: 1,
            r: 255,
            g: 255,
            b: 255,
            hexa: "#000000",
            colorPalettes: this.getColorPalettesFromProps(),
        };

        this.updateColor = this.updateColor.bind(this);
        this.onClickOutSideOfPopup = this.onClickOutSideOfPopup.bind(this);
        this.togglePicker = this.togglePicker.bind(this);
        this.handleHexChange = this.handleHexChange.bind(this);
        this.handleHexFocus = this.handleHexFocus.bind(this);
        this.colorPicked = this.colorPicked.bind(this);
        this.hueClick = this.hueClick.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.hexKeyUp = this.hexKeyUp.bind(this);

        this.hueMouseDown = this.hueMouseDown.bind(this);
        this.setHueSlider = this.setHueSlider.bind(this);
        this.pickerMouseDown = this.pickerMouseDown.bind(this);
        this.updateColor = this.updateColor.bind(this);
        this.updateElements = this.updateElements.bind(this);
        this.getColorPalettesFromProps = this.getColorPalettesFromProps.bind(this);
        this.renderPalette = this.renderPalette.bind(this);
        this.renderPickerOnly = this.renderPickerOnly.bind(this);
        this.elements = [];
        this.colorPickerRef = React.createRef();
        this.dialogBoxRef = React.createRef();
    }

    setElementsData = () => {
        if (this.props.type !== undefined) {
            if (this.props.type === "FRAME") {
                if (this.props.isFrameClip) { // frame clip is selected
                    let elem = document
                        .getElementById(getFrameDropAreaId(this.props.itemId, this.props.colorKey));
                    let property = "background-color";

                    this.elements.push({ element: elem, isAttr: false, property: property });
                } else { // frame is selected
                    let elem = document
                        .getElementById(getFrameSvgId(this.props.itemId, this.props.colorKey))
                        .getElementsByTagName("path")[0];
                    let property = "fill";

                    this.elements.push({ element: elem, isAttr: true, property: property });
                }
            } else if (this.props.type === "SHAPE" || this.props.subType === "BANNER") {
                let elem = document
                    .getElementById(this.props.itemId)
                    .getElementsByTagName("svg")[0]
                    .getElementsByTagName("path")[this.props.colorIndex];
                let property = "fill";

                if (elem && elem.getAttribute("fill") === "none") property = "stroke";
                this.elements.push({ element: elem, isAttr: true, property: property });
            } else if (this.props.type === "CHR" || this.props.type === "PROP" || this.props.type === "OVLYSVG") {
                let cols = document
                    .getElementById(this.props.itemId)
                    .getElementsByClassName("scene-item-inner")[0]
                    .querySelectorAll("[class*='" + this.props.colorKey + "']");
                for (let i = 0; i < cols.length; i++) {
                    if (cols[i].firstChild) {
                        let res = cols[i].className.animVal.replace(/zf/g, ".");
                        res = res.split("_");
                        if (!cols[i].firstChild) continue;
                        let objToPush = { element: cols[i].firstChild, isAttr: true };
                        if (res.length !== 1) {
                            objToPush.setHue = true;
                            objToPush.h = res[1];
                            objToPush.s = res[2];
                            objToPush.l = res[3];
                        }
                        if (cols[i].firstChild.hasAttribute("fill")) objToPush.property = "fill";
                        if (cols[i].firstChild.hasAttribute("stroke")) objToPush.property = "stroke";

                        this.elements.push(objToPush);
                    } else if (
                        cols[i].className.animVal.includes(this.props.colorKey) &&
                        cols[i] !== null &&
                        cols[i].hasAttribute("fill")
                    ) {
                        let objToPush = { element: cols[i], property: "fill", isAttr: true };
                        this.elements.push(objToPush);
                    } else {
                        continue;
                    }
                }
            } else if (this.props.type === "TEXT") {
                let textSelected = isTextSelected();

                let textElem = document.getElementById(this.props.itemId).getElementsByClassName("text-container")[0];
                let allTextSelected = isAllTextSelected(textElem);

                if (textSelected && !allTextSelected) {
                    this.elements.push({ execCommand: true, property: "foreColor" });
                } else {
                    this.elements.push({ element: textElem, isAttr: false, property: "color" });
                }
            } else if (this.props.type === "BG") {
                let cols = document
                    .getElementById(this.props.itemId)
                    .getElementsByTagName("svg")[0]
                    .querySelectorAll("[class*='" + this.props.colorKey + "']");

                for (let i = 0; i < cols.length; i++) {
                    if (this.props.colorKey === "prop1" && this.props.hasGradient === "1") {
                        let defs = document
                            .getElementById(this.props.itemId)
                            .getElementsByTagName("svg")[0]
                            .querySelectorAll("stop");

                        this.elements.push({ element: defs, isAttr: true, property: "stop-color", isGradient: true });
                    } else {
                        let res = cols[i].className.animVal.replace(/zf/g, ".");
                        res = res.split("_");
                        if (!cols[i].firstChild) continue;
                        let objToPush = { element: cols[i].firstChild, isAttr: true };
                        if (res.length !== 1) {
                            objToPush.setHue = true;
                            objToPush.h = res[1];
                            objToPush.s = res[2];
                            objToPush.l = res[3];
                        }
                        if (cols[i].firstChild.hasAttribute("fill")) objToPush.property = "fill";
                        if (cols[i].firstChild.hasAttribute("stroke")) objToPush.property = "stroke";

                        this.elements.push(objToPush);
                    }
                }
            }
        }
    };

    handleTextSelectionChange = () => {
        let selectedTextItemDom = document.getElementById(this.props.itemId);
        if (this.props.type === "TEXT" && selectedTextItemDom) {
            let getSelection = window.getSelection || document.getSelection;

            if (getSelection) {
                let selection = getSelection();
                if (
                    selectedTextItemDom.contains(selection.anchorNode)
                    && selectedTextItemDom.contains(selection.focusNode)
                ) {
                    this.elements = [];
                    this.setElementsData();
                }
            }
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.onClickOutSideOfPopup, false);
        window.addEventListener("mousedown", this.togglePicker, false);
        // this.changePositionOfDiv();
        this.updateSliderPosition();
        this.setElementsData();

        if (this.props.componentFrom === STICKERIFY || this.props.componentFrom === TEXT_EFFECT_SECTION) {
            this.hueClick();
        }

        if (this.props.type === "TEXT" && this.props.itemId) {
            document.addEventListener("selectionchange", this.handleTextSelectionChange);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentColor !== this.props.currentColor) {
            if (!this.recentColors.includes(this.props.currentColor)) {
                this.recentColors = this.recentColors.unshift(this.props.currentColor);
                if (this.recentColors.size > 9) this.recentColors = this.recentColors.slice(0, 9);
            }
            this.setState({
                selectedIndex: this.recentColors.indexOf(this.props.currentColor),
            });
        }

        if (
            prevProps.colorIndex !== this.props.colorIndex ||
            prevProps.colorKey !== this.props.colorKey ||
            prevProps.itemId !== this.props.itemId
        ) {
            this.elements = [];
            this.setElementsData();
        }
        this.updateSliderPosition();
        this.updateColorPickerPositison();

        if (this.props.type !== prevProps.type) {
            if (this.props.type === "TEXT") {
                document.addEventListener("selectionchange", this.handleTextSelectionChange);
            } else if (prevProps.type === "TEXT") {
                document.removeEventListener("selectionchange", this.handleTextSelectionChange);
            }
        }
    }

    onClickOutSideOfPopup() {
        try {
            this.props.closePopup();
        } catch (error) { }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onClickOutSideOfPopup, false);
        window.removeEventListener("mousedown", this.togglePicker, false);
        document.removeEventListener("selectionchange", this.handleTextSelectionChange);
        this.elements = [];
    }

    togglePicker() {
        try {
            let stateToUpdate = { showColorPicker: false };

            this.setState(stateToUpdate);

            if (this.props.closePopup && this.props.componentFrom === TEXT_EFFECT_SECTION) {
                this.props.closePopup();
            }
        } catch (error) { }
    }

    updateHueSliderPostion() {
        let size = document.getElementsByClassName("hue")[0].offsetWidth;
        let pos = ((this.state.h * size) / 360) | 0;
        document.getElementsByClassName("hue-slider")[0].style.left = pos - 7.5 + "px";
    }

    updatePickerPostion() {
        let sizeW = document.getElementsByClassName("picker-area")[0].clientWidth;
        let sizeH = document.getElementsByClassName("picker-area")[0].clientHeight;

        let x = ((this.state.s * sizeW) / 100) | 0;
        let y = (sizeH - (this.state.l * sizeH) / 100) | 0;

        document.getElementsByClassName("picker")[0].style.left = x - 7.5 + "px";
        document.getElementsByClassName("picker")[0].style.top = y - 7.5 + "px";
    }

    updateSliderPosition() {
        if (this.state.showColorPicker) {
            this.updateHueSliderPostion();
            this.updatePickerPostion();
        }
    }

    updateColorPickerPositison = () => {
        if (this.state.showColorPicker && this.colorPickerRef.current && this.dialogBoxRef.current) {
            this.dialogBoxRef.current.style.left = `${this.colorPickerRef.current.getBoundingClientRect().x}px`;
            this.dialogBoxRef.current.style.top = `${this.colorPickerRef.current.getBoundingClientRect().y +
                this.colorPickerRef.current.getBoundingClientRect().height +
                5
                }px`;
        }
    };

    isValidRGBValue(value) {
        return typeof value === "number" && Number.isNaN(value) === false && value >= 0 && value <= 255;
    }

    setRGBA(red, green, blue, alpha) {
        if (
            this.isValidRGBValue(red) === false ||
            this.isValidRGBValue(green) === false ||
            this.isValidRGBValue(blue) === false
        )
            return;

        let r = red | 0;
        let g = green | 0;
        let b = blue | 0;

        let a = this.state.a;
        if (a !== undefined && this.isValidRGBValue(alpha) === true) a = alpha | 0;
        let hexa = this.getHexa(r, g, b, a);

        // eslint-disable-next-line consistent-return
        return { r: r, g: g, b: b, a: a, hexa: hexa, hexInput: hexa.substring(1) };
    }

    getHexa(r, g, b) {
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);

        if (r.length === 1) r = "0" + r;
        if (g.length === 1) g = "0" + g;
        if (b.length === 1) b = "0" + b;
        let value = "#" + r + g + b;
        return value.toUpperCase();
    }

    HEX2RGB(hex) {
        // eslint-disable-next-line
        "use strict";
        if (hex.charAt(0) === "#") {
            hex = hex.substr(1);
        }
        if (hex.length < 3 || hex.length > 6) {
            return false;
        }
        let values = hex.split("");
        let r;
        let g;
        let b;

        if (hex.length === 3) {
            r = parseInt(values[0].toString() + values[0].toString(), 16);
            g = parseInt(values[1].toString() + values[1].toString(), 16);
            b = parseInt(values[2].toString() + values[2].toString(), 16);
        } else if (hex.length === 6) {
            r = parseInt(values[0].toString() + values[1].toString(), 16);
            g = parseInt(values[2].toString() + values[3].toString(), 16);
            b = parseInt(values[4].toString() + values[5].toString(), 16);
        } else {
            return false;
        }
        return [r, g, b];
    }

    RGBtoHSL(r, g, b) {
        let red = r / 255;
        let green = g / 255;
        let blue = b / 255;

        let cmax = Math.max(red, green, blue);
        let cmin = Math.min(red, green, blue);
        let delta = cmax - cmin;
        let hue = 0;
        let saturation = 0;
        let lightness = (cmax + cmin) / 2;
        let X = 1 - Math.abs(2 * lightness - 1);

        if (delta) {
            if (cmax === red) {
                hue = (green - blue) / delta;
            }
            if (cmax === green) {
                hue = 2 + (blue - red) / delta;
            }
            if (cmax === blue) {
                hue = 4 + (red - green) / delta;
            }
            if (cmax) saturation = delta / X;
        }

        hue = (60 * hue) | 0;
        if (hue < 0) hue += 360;
        saturation = (saturation * 100) | 0;
        lightness = (lightness * 100) | 0;

        return { h: hue, s: saturation, l: lightness };
    }

    HSLtoRGB(h, s, l) {
        let sat = s / 100;
        let light = l / 100;
        let C = sat * (1 - Math.abs(2 * light - 1));
        let H = h / 60;
        let X = C * (1 - Math.abs((H % 2) - 1));
        let m = light - C / 2;
        let precision = 255;

        C = ((C + m) * precision) | 0;
        X = ((X + m) * precision) | 0;
        m = (m * precision) | 0;

        if (H >= 0 && H < 1) {
            return this.setRGBA(C, X, m);
        }
        if (H >= 1 && H < 2) {
            return this.setRGBA(X, C, m);
        }
        if (H >= 2 && H < 3) {
            return this.setRGBA(m, C, X);
        }
        if (H >= 3 && H < 4) {
            return this.setRGBA(m, X, C);
        }
        if (H >= 4 && H < 5) {
            return this.setRGBA(X, m, C);
        }
        if (H >= 5 && H < 6) {
            return this.setRGBA(C, m, X);
        }

        return undefined;
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    HSVtoRGB(h, s, v) {
        let sat = s / 100;
        let value = v / 100;
        let C = sat * value;
        let H = h / 60;
        let X = C * (1 - Math.abs((H % 2) - 1));
        let m = value - C;
        let precision = 255;

        C = ((C + m) * precision) | 0;
        X = ((X + m) * precision) | 0;
        m = (m * precision) | 0;

        if (H >= 0 && H < 1) {
            return this.setRGBA(C, X, m);
        }
        if (H >= 1 && H < 2) {
            return this.setRGBA(X, C, m);
        }
        if (H >= 2 && H < 3) {
            return this.setRGBA(m, C, X);
        }
        if (H >= 3 && H < 4) {
            return this.setRGBA(m, X, C);
        }
        if (H >= 4 && H < 5) {
            return this.setRGBA(X, m, C);
        }
        if (H >= 5 && H < 6) {
            return this.setRGBA(C, m, X);
        }

        return undefined;
    }

    setHueSlider(e) {
        try {
            let pageX = e.touches ? e.touches[0].pageX : e.pageX;
            let x = pageX - document.getElementsByClassName("hue")[0].getBoundingClientRect().x;
            let width = document.getElementsByClassName("hue")[0].offsetWidth;

            if (x < 0) x = 0;
            if (x > width) x = width;

            // TODO 360 => 359
            let hue = ((359 * x) / width) | 0;
            // if (hue === 360) hue = 359;

            let stateToUpdate = { h: hue };

            let rgba = this.HSLtoRGB(hue, this.state.s, this.state.l);
            let hexa = rgba.hexa;
            stateToUpdate = Object.assign(stateToUpdate, rgba);

            if (e.type === "mousedown" || e.type === "touchstart") {
                if (hexa !== undefined) {
                    if (this.state.selectedIndex === -1) {
                        this.recentColors = this.recentColors.unshift(hexa);
                        if (this.recentColors.size > 9) this.recentColors = this.recentColors.slice(0, 9);
                        stateToUpdate.selectedIndex = 0;
                    } else {
                        this.recentColors = this.recentColors.set(this.state.selectedIndex, hexa);
                    }
                }
            } else if (this.state.selectedIndex !== -1) {
                // this.recentColors = this.recentColors.set(this.state.selectedIndex, hexa);
            }

            if ((e.type === "mousemove" || e.type === "touchmove") && !this.props.stopSlidePropogation) {
                this.updateElements(hexa);
            }

            this.setState(stateToUpdate);
        } catch (error) { }
    }

    colorPicked() {
        try {
            this.props.colorSelected(this.state.hexa);
            if (this.props.shouldUpdateRecentColor) {
                this.props.updateRecentColors({ colors: this.recentColors });
            }
            document.removeEventListener("mousemove", this.setHueSlider);
            document.removeEventListener("mousemove", this.updateColor);
            document.removeEventListener("mouseup", this.colorPicked);

            document.removeEventListener("touchmove", this.setHueSlider);
            document.removeEventListener("touchmove", this.updateColor);
            document.removeEventListener("touchend", this.colorPicked);
        } catch (error) { }
    }

    hueClick(e) {
        try {
            if (e) e.stopPropagation();
            let stateToUpdate = { showColorPicker: !this.state.showColorPicker };
            let value = this.state.hexa;
            let brandColorValue = this.props.isCustom
                ? this.props.selectedTransititon[this.props.color]
                : this.recentColors.get(this.state.selectedIndex);

            if (this.state.selectedIndex !== -1 && brandColorValue !== undefined) {
                value = brandColorValue;
            }
            let rgb = this.HEX2RGB(value);

            stateToUpdate.hexInput = value.replace(/#/g, "");
            if (rgb !== false) {
                stateToUpdate.hexa = value;
                stateToUpdate.r = rgb[0];
                stateToUpdate.g = rgb[1];
                stateToUpdate.b = rgb[2];
                let hsl = this.RGBtoHSL(rgb[0], rgb[1], rgb[2]);

                stateToUpdate = Object.assign(stateToUpdate, hsl);
            }

            this.setState(stateToUpdate);
        } catch (error) { }
    }

    hueMouseDown(e) {
        try {
            this.setHueSlider(e);
            document.addEventListener("mousemove", this.setHueSlider);
            document.addEventListener("mouseup", this.colorPicked);
            document.addEventListener("touchmove", this.setHueSlider);
            document.addEventListener("touchend", this.colorPicked);
        } catch (error) { }
    }

    updateColor(e) {
        try {
            let pageX = e.touches ? e.touches[0].pageX : e.pageX;
            let pageY = e.touches ? e.touches[0].pageY : e.pageY;
            let pickerBounds = document.getElementsByClassName("picker-area")[0].getBoundingClientRect();
            let x = pageX - pickerBounds.x;
            let y = pageY - pickerBounds.y;

            // width and height should be the same
            let sizeW = pickerBounds.width;
            let sizeH = pickerBounds.height;

            if (x > sizeW) x = sizeW;
            if (y > sizeH) y = sizeH;
            if (x < 0) x = 0;
            if (y < 0) y = 0;

            let value = (100 - (y * 100) / sizeH) | 0;
            let saturation = ((x * 100) / sizeW) | 0;

            let stateToUpdate = { s: saturation, l: value };

            let rgba = this.HSLtoRGB(this.state.h, saturation, value);
            let hexa = rgba.hexa;
            stateToUpdate = Object.assign(stateToUpdate, rgba);

            if (this.state.selectedIndex !== -1) {
                this.recentColors = this.recentColors.set(this.state.selectedIndex, hexa);
            }

            if ((e.type === "mousemove" || e.type === "touchmove") && !this.props.stopSlidePropogation) {
                this.updateElements(hexa);
            }

            this.setState(stateToUpdate);
        } catch (error) { }
    }

    hex2rgb(str) {
        let arr1 = [];
        arr1[0] = parseInt(str.substring(0, 2), 16);
        arr1[1] = parseInt(str.substring(2, 4), 16);
        arr1[2] = parseInt(str.substring(4, 6), 16);
        return arr1;
    }

    rgb2hex(red, green, blue) {
        let rgb = blue | (green << 8) | (red << 16);
        return "#" + (0x1000000 + rgb).toString(16).slice(1);
    }

    rgb2hsl(color) {
        let r = color[0] / 255;
        let g = color[1] / 255;
        let b = color[2] / 255;

        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h;
        let s;
        let l = (max + min) / 2;

        if (max === min) {
            h = 0; // achromatic
            s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
                default:
                    break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    hsl2rgb(color) {
        let l = color[2];

        if (color[1] === 0) {
            l = Math.round(l * 255);
            return [l, l, l];
        }
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        let s = color[1];
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        let r = hue2rgb(p, q, color[0] + 1 / 3);
        let g = hue2rgb(p, q, color[0]);
        let b = hue2rgb(p, q, color[0] - 1 / 3);
        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    _iHSL10(str1, hue, sat, light) {
        let color1 = this.hex2rgb(str1);

        let hsl1 = this.rgb2hsl(color1);

        hsl1[0] = Number(hsl1[0]) - Number(hue);
        hsl1[1] = Number(hsl1[1]) - Number(sat);
        hsl1[2] = Number(hsl1[2]) - Number(light);
        hsl1[0] = hsl1[0] > 1 ? 1 : hsl1[0];
        hsl1[1] = hsl1[1] > 1 ? 1 : hsl1[1];
        hsl1[2] = hsl1[2] > 1 ? 1 : hsl1[2];
        hsl1[0] = hsl1[0] < 0 ? 0 : hsl1[0];
        hsl1[1] = hsl1[1] < 0 ? 0 : hsl1[1];
        hsl1[2] = hsl1[2] < 0 ? 0 : hsl1[2];
        let arr3 = this.hsl2rgb(hsl1);

        return this.rgb2hex(arr3[0], arr3[1], arr3[2]);
    }

    updateElements(color) {
        this.elements.forEach(
            (elem) => {
                if (!elem.execCommand) {
                    if (elem.setHue === true) {
                        let newColor = this._iHSL10(color.substr(1), elem.h, elem.s, elem.l);
                        if (elem.isAttr) {
                            elem.element.setAttribute(elem.property, newColor);
                        } else {
                            elem.element.style[elem.property] = newColor;
                        }
                    } else if (elem.isGradient === true) {
                        let color1 = this.hex2rgb(color.substr(1));
                        let color2 = this.hex2rgb(this._iHSL10(color.substr(1), -0.009, -0.622, -0.221).substr(1));

                        let r1 = color1[0];
                        let g1 = color1[1];
                        let b1 = color1[2];

                        let r2 = color2[0];
                        let g2 = color2[1];
                        let b2 = color2[2];

                        let r_mid = r1 + (r2 - r1) / 2;
                        let g_mid = g1 + (g2 - g1) / 2;
                        let b_mid = b1 + (b2 - b1) / 2;

                        elem.element[0].setAttribute(elem.property, "rgb(" + r1 + "," + g1 + "," + b1 + ")");
                        elem.element[1].setAttribute(elem.property, "rgb(" + r_mid + "," + g_mid + "," + b_mid + ")");
                        elem.element[2].setAttribute(elem.property, "rgb(" + r2 + "," + g2 + "," + b2 + ")");
                    } else if (elem.isAttr) {
                        elem.element.setAttribute(elem.property, color);
                    } else {
                        elem.element.style[elem.property] = color;
                    }
                } else {
                    document.execCommand(elem.property, false, color);
                }
            }
        );
    }

    pickerMouseDown(e) {
        try {
            this.updateColor(e);
            document.addEventListener("mousemove", this.updateColor);
            document.addEventListener("mouseup", this.colorPicked);
            document.addEventListener("touchmove", this.updateColor);
            document.addEventListener("touchend", this.colorPicked);
        } catch (error) { }
    }

    handleColorPick = (value) => {
        try {
            let rgb;
            let stateToUpdate = {};
            if (value[0] === "#") {
                value = value.slice(1);
                rgb = this.HEX2RGB(value);
                stateToUpdate.hexInput = value;
            } else {
                rgb = value
                    .slice("4", -1)
                    .split(",")
                    .map((data) => parseInt(data, 10));
            }
            if (rgb !== false) {
                stateToUpdate.r = rgb[0];
                stateToUpdate.g = rgb[1];
                stateToUpdate.b = rgb[2];

                let hsl = this.RGBtoHSL(rgb[0], rgb[1], rgb[2]);
                let hexa = this.getHexa(rgb[0], rgb[1], rgb[2]);

                stateToUpdate.hexa = hexa;

                this.props.colorSelected(hexa.toUpperCase());

                value = hexa.slice(1);
                stateToUpdate.hexInput = hexa.replace("#", "");

                if (this.state.selectedIndex !== -1)
                    this.recentColors = this.recentColors.set(this.state.selectedIndex, "#" + value);

                stateToUpdate = Object.assign(stateToUpdate, hsl);
            }
            this.setState(stateToUpdate, () => {
                this.colorPicked();
            });
        } catch (error) { }
    };

    handleHexChange(e) {
        try {
            e.stopPropagation();
            if (!(/^#[0-9A-F]{0,7}$/i.test(e.target.value) || /^[0-9A-F]{0,6}$/i.test(e.target.value))) {
                e.target.classList.remove("shakeBox");
                e.target.classList.add("shakeBox");
                return;
            }

            let value = e.target.value;
            value = value.replace(/#/g, "");
            if (value.length <= 6) {
                let rgb = this.HEX2RGB(value);
                let stateToUpdate = { hexInput: value };
                if (rgb !== false) {
                    stateToUpdate.r = rgb[0];
                    stateToUpdate.g = rgb[1];
                    stateToUpdate.b = rgb[2];

                    let hsl = this.RGBtoHSL(rgb[0], rgb[1], rgb[2]);
                    let hexa = this.getHexa(rgb[0], rgb[1], rgb[2]);

                    stateToUpdate.hexa = hexa;

                    this.props.colorSelected(hexa.toUpperCase());

                    if (this.state.selectedIndex !== -1)
                        this.recentColors = this.recentColors.set(this.state.selectedIndex, "#" + value);
                    stateToUpdate = Object.assign(stateToUpdate, hsl);
                }

                this.setState(stateToUpdate);
            }
        } catch (error) { }
    }

    hexKeyUp(e) {
        try {
            if (e.key === "Enter" && e.target.value !== "") {
                this.props.colorSelected(this.state.hexa.toUpperCase());
            }
        } catch (error) { }
    }

    handlePaste() {
        try {
            setTimeout(
                () => {
                    this.props.colorSelected(this.state.hexa.toUpperCase());
                },
                100
            );
        } catch (error) { }
    }

    handleHexFocus(e) {
        try {
            this.props.setWorkspaceTextFocus(true);
            e.target.select();
        } catch (error) { }
    }

    pickColor(pickedColor = null, index) {
        try {
            if (this.props.isScenePreviewing) return; // Only for fanUp and fanDown transition.
            if (!this.props.isCustom) {
                if (pickedColor) {
                    this.props.colorSelected(pickedColor);
                }
            } else {
                /**
                 * @description commenting out scene transition code of animaker
                let custom = {};
                custom[this.props.colorPart] = pickedColor;

                // this.props.changeSceneTransColors(Object.assign(this.props.selectedTransititon,custom) , this.props.selectedScene, this.props.socket);
                this.props.updateScene(
                    {
                        selectedScene: this.props.selectedScene,
                        toUpdate: { transColor: Object.assign(this.props.selectedTransititon, custom) },
                    },
                    this.props.socket
                );
                // this.props.addSceneTransition
                */
            }
            let stateToUpdate = {};

            if (!this.recentColors.includes(pickedColor)) {
                this.recentColors = this.recentColors.unshift(pickedColor);
                stateToUpdate.selectedIndex = 0;
            } else {
                stateToUpdate.selectedIndex = this.recentColors.indexOf(pickedColor);
            }

            if (this.recentColors.size > 9) this.recentColors = this.recentColors.slice(0, 9);
            if (pickedColor) stateToUpdate.currentColorCode = pickedColor;

            if (index !== undefined) {
                // stateToUpdate.selectedIndex = index;

                if (this.state.showColorPicker) {
                    let value = this.recentColors.get(index);
                    let rgb = this.HEX2RGB(value);
                    stateToUpdate.hexInput = value.replace(/#/g, "");
                    if (rgb !== false) {
                        stateToUpdate.hexa = value;
                        stateToUpdate.r = rgb[0];
                        stateToUpdate.g = rgb[1];
                        stateToUpdate.b = rgb[2];
                        let hsl = this.RGBtoHSL(rgb[0], rgb[1], rgb[2]);
                        stateToUpdate = Object.assign(stateToUpdate, hsl);
                    }
                }

                this.setState(stateToUpdate);
            }
        } catch (error) { }
    }

    handleColorButtonMouseDown = (e) => {
        e.preventDefault(); // safari switches to text selection on div drag, thus breaking partial text color change. This line is needed to fix this issue
    }

    onColorPickClick = () => {
        if (!window.EyeDropper) {
            // this.props.manageToast({ action: "add", type: "error", message: "ey.drp.err", formatId: "msg" });
        } else {
            const eyeDropper = new window.EyeDropper();

            eyeDropper.open().then((result) => {
                this.handleColorPick(result.sRGBHex);
            }).catch(() => { });
            if (this.props.onPickerClick) {
                this.props.onPickerClick();
            }
        }
    };

    getColorPalettesFromProps() {
        const { type, brand } = this.props;
        let { colorPalettes } = this.props;

        if (type !== "CHR") {
            colorPalettes = colorPalettes.filter(c => c.get("isDefault"));
        }
        colorPalettes = colorPalettes.toList();

        if (brand && brand.get("colorpalette")) {
            colorPalettes = brand.get("colorpalette").concat(colorPalettes);
        }

        return colorPalettes;
    }

    renderPalette() {
        const { colorPalettes } = this.state;
        const { recentColors } = this;

        const colorPickerElem = (
            <ColorPickerContainer
                className="palette-color-picker tool-bar"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
            >
                <div
                    className="picker-area"
                    style={{ backgroundColor: this.HSVtoRGB(this.state.h, 100, 100).hexa, touchAction: "none" }}
                    onTouchStart={this.pickerMouseDown}
                    onMouseDown={this.pickerMouseDown}
                >
                    <div className="picker" />
                </div>
                <div
                    style={{ cursor: "pointer", touchAction: "none" }}
                    className="hue"
                    onTouchStart={this.hueMouseDown}
                    onMouseDown={this.hueMouseDown}
                >
                    <div className="hue-slider" />
                </div>
                <div className="hex-input-container palette">
                    <span>#</span>
                    <form
                        onSubmit={(e) => e.preventDefault()}
                        autoComplete="off"
                        style={{ display: "inline", width: "112.5px" }}
                    >
                        <input
                            autoComplete="off"
                            type="text"
                            onChange={this.handleHexChange}
                            onFocus={this.handleHexFocus}
                            onBlur={() => this.props.setWorkspaceTextFocus(false)}
                            onKeyUp={this.hexKeyUp}
                            onPaste={this.handlePaste}
                            value={this.state.hexInput}
                        />
                    </form>
                </div>
                <div className="color-picker palette" onClick={this.onColorPickClick}>
                    <img
                        draggable={false}
                        src={STATIC_PATH + "color-property/color-picker.svg"}
                        alt="color picker"
                    />
                </div>
            </ColorPickerContainer>
        );

        const recentColorButtons = [];

        if (recentColors) {
            for (const [index, color] of recentColors.entrySeq()) {
                recentColorButtons.push(
                    <ColorButton
                        key={color + index}
                        onPointerDown={this.handleColorButtonMouseDown}
                        onClick={() => {
                            this.pickColor(color, index);
                        }}
                        style={{
                            backgroundColor: color,
                        }}
                    />
                );
            }
        }

        const colorGroups = [
            <ColorButtonContainer key="edit-container" className="edit-container">
                <ColorButton
                    key={"edit"}
                    ref={this.colorPickerRef}
                    className="edit"
                    onPointerDown={(e) => e.stopPropagation()}
                    onClick={this.hueClick}
                >
                    <img draggable={false} alt="color picker" src={`${STATIC_PATH}color-property/edit.svg`} />
                    <DialogBox
                        ref={this.dialogBoxRef}
                        open={this.state.showColorPicker}
                        style={{
                            zIndex: "1000",
                            width: "262px",
                        }}
                    >
                        {colorPickerElem}
                    </DialogBox>
                </ColorButton>
                {recentColorButtons}
            </ColorButtonContainer>
        ];

        if (colorPalettes) {
            for (const [groupIndex, colorGroupData] of colorPalettes.entrySeq()) {
                const title = colorGroupData.get("name");
                const colors = [];

                for (const color of colorGroupData.get("colors").valueSeq()) {
                    colors.push(
                        <ColorButton
                            key={color}
                            onPointerDown={this.handleColorButtonMouseDown}
                            onClick={() => {
                                this.pickColor(color);
                            }}
                            style={{
                                backgroundColor: color,
                            }}
                        />
                    );
                }

                colorGroups.push(
                    <ColorGroup key={groupIndex + title}>
                        <p>{title}</p>
                        <ColorButtonContainer>
                            {colors}
                        </ColorButtonContainer>
                    </ColorGroup>
                );
            }
        }


        return (
            <ColorList>
                <div>
                    {colorGroups}
                </div>
            </ColorList>
        );
    }

    renderPickerOnly() {
        let colorPickerElem;

        if (this.state.showColorPicker) {
            colorPickerElem = (
                <ColorPickerContainer
                    className="palette-color-picker tool-bar"
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <div
                        className="picker-area"
                        style={{ backgroundColor: this.HSVtoRGB(this.state.h, 100, 100).hexa, touchAction: "none" }}
                        onTouchStart={this.pickerMouseDown}
                        onMouseDown={this.pickerMouseDown}
                    >
                        <div className="picker" />
                    </div>
                    <div
                        style={{ cursor: "pointer", touchAction: "none" }}
                        className="hue"
                        onTouchStart={this.hueMouseDown}
                        onMouseDown={this.hueMouseDown}
                    >
                        <div className="hue-slider" />
                    </div>
                    <div className="hex-input-container palette">
                        <span>#</span>
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            autoComplete="off"
                            style={{ display: "inline", width: "112.5px" }}
                        >
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={this.handleHexChange}
                                onFocus={this.handleHexFocus}
                                onBlur={() => this.props.setWorkspaceTextFocus(false)}
                                onKeyUp={this.hexKeyUp}
                                onPaste={this.handlePaste}
                                value={this.state.hexInput}
                            />
                        </form>
                    </div>
                    <div className="color-picker palette" onClick={this.onColorPickClick}>
                        <img
                            draggable={false}
                            src={STATIC_PATH + "color-property/color-picker.svg"}
                            alt="color picker"
                        />
                    </div>
                </ColorPickerContainer>
            );
        }

        return colorPickerElem;
    }

    render() {
        if (this.props.componentFrom === STICKERIFY || this.props.componentFrom === TEXT_EFFECT_SECTION) {
            return this.renderPickerOnly();
        }
        return this.renderPalette();
    }
}

ColorPaletteWindowComponent.defaultProps = {
    shouldUpdateRecentColor: true
}

ColorPaletteWindowComponent.propTypes = {
    colorPalettes: PropTypes.object,
    brand: PropTypes.object,
    recentColors: PropTypes.object,
    setWorkspaceTextFocus: PropTypes.func,
    updateRecentColors: PropTypes.func,
    type: PropTypes.string,
    subType: PropTypes.string,
    currentColor: PropTypes.string,
    isFrameClip: PropTypes.bool,
    itemId: PropTypes.string,
    colorKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    colorIndex: PropTypes.number,
    hasGradient: PropTypes.string,
    closePopup: PropTypes.func,
    componentFrom: PropTypes.string,
    colorSelected: PropTypes.func,
    isCustom: PropTypes.bool,
    selectedTransititon: PropTypes.object,
    color: PropTypes.string,
    stopSlidePropogation: PropTypes.bool,
    isScenePreviewing: PropTypes.bool,
    shouldUpdateRecentColor: PropTypes.bool,
    onPickerClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
    colorPalettes: state.app.get("colorPalettes"),
    brand: state.app.get("brandColorPalettes"),
    recentColors: state.app.get("recentColors"),
});

const mapDispatchToProps = (dispatch) => ({
    setWorkspaceTextFocus: (data) => dispatch(setWorkspaceTextFocus(data)),
    updateRecentColors: (data) => dispatch(updateRecentColors(data)),
});

const ColorPaletteWindow = connect(mapStateToProps, mapDispatchToProps)(ColorPaletteWindowComponent);

export default ColorPaletteWindow;
