/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { SmartImage } from "../../../common-components/SmartImage";
import { API, API_URL, STATIC_PATH } from "../../../constants/config";
import Typography from "../../../common-components/Typography";
import { font } from "../../../constants/font";
import vmTheme from "../../../constants/theme";
import LabelDropdown from "../../../common-components/LabelDropdown";
import { CloseIcon } from "../../modal/modal-components";
import useApi from "../../../helper/hooks/useApi";
import useNotify from "../../../helper/hooks/useNotify";
import content from "../../../constants/content";

const ShareListWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  padding: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 767px) {
    touch-action: auto;
  }
`;
const SharedList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`;
const SharedLi = styled.li`
  display: flex;
  gap: 6px;
  align-items: center;
  background: #f7f4f3;
  margin: 5px 0px;
  padding: 0px 8px;
  border-radius: 8px;
  .drop-down {
    background: transparent;
  }
  .drop-down-options {
  }
`;
const SharedDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
const BackIcon = styled.img`
  width: 30px;
  position: absolute;
  left: 25px;
  top: 35px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.01);
  }
`;

const ShareList = ({
  users,
  onClose,
  goBack
}) => {
  const [list, setList] = useState([]);
  const theme = useSelector((state) => state.app.get("theme"));
  const { projectId } = useSelector((state) => state.userDetails);
  const { post } = useApi();
  const { success, warn } = useNotify();
  const onChange = useCallback((value, email) => {
    post(`${API_URL}${API.CHANGE_SHARE}`, {
      projectId,
      email,
      permission: value
    }).then(() => {
      success(`Permission has been changed to ${value}`)
    })
  }, [projectId]);

  const onRemove = useCallback((email, index) => {
    post(`${API_URL}${API.REMOVE_PERMISSION}`, {
      projectId,
      email
    }).then(() => {
      success(`Permission has been revoked`);
      const data = [...list];
      data.splice(index, 1);
      if (data.length) {
        setList([...data]);
      } else {
        onClose()
      }
    }).catch((error) => {
      warn(error.message || content.WENT_WRONG);
      onClose()
    })
  }, [projectId]);

  useEffect(() => {
    setList(users)
  }, [])

  return (
    <div>
      <BackIcon
        src={`${STATIC_PATH}back.svg`}
        alt=""
        onClick={goBack}
      />
      <ShareListWrapper>
        <SharedList>
          {list.map(({ email, edit, name, image }, index) => {
            return (
              <SharedLi key={email}>
                <SmartImage
                  className="profile-pic"
                  imageSource={(image === "undefined" || !image) ?`${STATIC_PATH}chr-male.svg` : image}
                  placeHolderImage={`${STATIC_PATH}chr-male.svg`}
                  cursor="pointer"
                  width="32px"
                  height="32px"
                  alt={""}
                />
                <SharedDetails>
                  <Typography
                    content={name === "undefined" ? 'Unregistered User' : name}
                    font={font.boldBase}
                    color={vmTheme[theme].panelPrimaryColor}
                    padding="3px 0px"
                    width="155px"
                  />
                  <Typography
                    content={email}
                    font={font.normalBase_16}
                    color={vmTheme[theme].panelPrimaryColor}
                    padding="3px 0px"
                    width="155px"
                  />
                </SharedDetails>
                <LabelDropdown
                  position={"static"}
                  className="inputDropdown"
                  width="105px"
                  margin="12px 3px"
                  optionWidth="105px"
                  border="none"
                  font={font.boldBase}
                  borderActive="none"
                  enableAutoAlign
                  defaultValue={edit ? 'edit' : 'view'}
                  options={[
                    { label: "Edit Link", value: 'edit', id: "editlink" },
                    { label: "View Link", value: 'view', id: "viewlink" },
                  ]}
                  onChange={(value) => {
                    onChange(value, email)
                  }}
                />
                <CloseIcon
                  src={`${STATIC_PATH}close-icon.svg`}
                  alt="close"
                  style={{
                    width: "13px",
                    height: "13px",
                    margin: "0px",
                    padding: "1px",
                  }}
                  onClick={() => {
                    onRemove(email, index)
                  }}
                />
              </SharedLi>
            )
          })}
        </SharedList>
      </ShareListWrapper>
    </div>
  );
};

ShareList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  goBack: PropTypes.func,
  onClose: PropTypes.func
};

export default ShareList;
