/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Typography from "../../../../common-components/Typography";
import vmTheme from "../../../../constants/theme";
import { font } from "../../../../constants/font";
import { STATIC_PATH } from "../../../../constants/config";
import {
  ColorPaletteWrapper,
  ColorPickerButton,
  Icon,
  SubtitleSliderWrap,
} from "./subtitleproperty-components";
import Slider from "../../../zoom/zoom-slider";
import NumberInput from "../../../../common-components/NumberInputComponent";
import { FontSizeList } from "../text/textproperty-components";
import { setWorkspaceTextFocus } from "../../../../redux/actions/appUtils";
import ColorPaletteWindow from "../color/propertywindow-colorpalette";
import { PORTAL_ID } from "../../../../constants";
import { getArrayOfNumbers } from "../../../../helper/subtitleHelper";

const X_OFFSET = 15;
const Y_OFFSET = 166;

const SubtitleSliderSection = ({
  heading,
  value,
  dropdownList,
  colorSelectionHandler,
  onChangeHandler,
  mouseUpHandler,
  paletteColor,
  handleClick,
  leftIcon,
  rightIcon,
  width,
  enableColorPick = true,
  titleIcon,
  numberWidth,
  borderRight,
  min = 0,
  max = 100,
  type = "number",
}) => {
  const colorPickerRef = useRef();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));

  const [fontSizeFocused, setFontSizeFocused] = useState(false);
  const [fontSizeContainerBounds, setFontSizeContainerBounds] = useState(false);
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);

  const inputFontOutlineRef = useRef();
  const textOutlineContainerRef = useRef();

  const toggleSizes = () => {
    const toUpdate = {
      fontSizeFocused: !fontSizeFocused,
    };

    if (toUpdate.fontSizeFocused && textOutlineContainerRef.current) {
      toUpdate.fontSizeContainerBounds =
        textOutlineContainerRef.current.getBoundingClientRect();
    } else {
      toUpdate.fontSizeFocused = false;
      toUpdate.fontSizeContainerBounds = null;
    }
    setFontSizeFocused(toUpdate.fontSizeFocused);
    setFontSizeContainerBounds(toUpdate.fontSizeContainerBounds);
  };

  const handleFontOutlineFocus = () => {
    toggleSizes();
    if (inputFontOutlineRef.current) {
      inputFontOutlineRef.current.select();
    }
    dispatch(setWorkspaceTextFocus(true));
  };

  const handleFontOutlineBlur = (e) => {
    dispatch(setWorkspaceTextFocus(false));
    let { value } = e.target;
    if (value < min) {
      value = min;
    }
    if (value > max) {
      value = max
    }
    handleClick(parseFloat(value), heading);
  };

  const getFontSizeValue = () => {
    if (inputFontOutlineRef && inputFontOutlineRef.current) {
      let { value } = inputFontOutlineRef.current;
      if (type === "angle") {
        value = value.replace("°", "")
      }
      return value;
    }
    return "";
  };

  const handleInputChange = () => {
    setFontSizeFocused(false);
    const fontSizeValue = getFontSizeValue();
    if (
      fontSizeValue !== "" &&
      parseFloat(fontSizeValue) >= min &&
      parseFloat(fontSizeValue) <= max + 1
    ) {
      let sizeValue = fontSizeValue;
      const splitValue = sizeValue.split(".");
      if (splitValue.length > 1 && splitValue[1].length > 2)
        sizeValue = `${splitValue[0]}.${splitValue[1].substr(0, 2)}`;
      handleClick(parseFloat(sizeValue), heading);
    } else if (fontSizeValue === "") {
      // Commented to prevent NaN.
    } /* else {
      setOutline(fontSizeValue);
    } */
  };

  const handleChangeFontOutline = (action) => {
    const minValue = min;
    const maxValue = max;
    if (inputFontOutlineRef && inputFontOutlineRef.current) {
      let value = getFontSizeValue();
      value = Math.round(value); // empty string returns 0
      let updateValue = minValue;
      if (
        value !== "" &&
        parseFloat(value) >= minValue &&
        parseFloat(value) <= maxValue
      ) {
        if (action === "INCREASE" && parseFloat(value) !== maxValue) {
          updateValue = value + 1;
        } else if (action === "DECREASE" && parseFloat(value) !== minValue) {
          updateValue = value - 1;
        }
        if (
          (action === "INCREASE" && parseFloat(value) === maxValue) ||
          (action === "DECREASE" && parseFloat(value) === minValue)
        ) {
          updateValue = parseFloat(value);
        }
      } else if (
        value === "" &&
        parseFloat(value) < minValue &&
        parseFloat(value) > maxValue
      ) {
        if (action === "INCREASE" && parseFloat(value) < minValue) {
          updateValue = minValue;
        } else if (action === "DECREASE" && parseFloat(value) > maxValue) {
          updateValue = maxValue;
        }
      }
      if (type === "degree") {
        updateValue = `${updateValue}°`;
      }
      inputFontOutlineRef.current.value = updateValue;
      handleInputChange();
    }
  };

  const toggleColorPalette = () => {
    setIsOpenColorPicker(!isOpenColorPicker);
  };

  const getColorPickerPos = () => {
    const colorPickerBound = colorPickerRef.current.getBoundingClientRect();
    const x = colorPickerBound.top + colorPickerBound.height + X_OFFSET;
    const y = colorPickerBound.left - colorPickerBound.width - Y_OFFSET;
    return { x, y }
  }

  let dropdownOptions;
  if (fontSizeFocused && fontSizeContainerBounds) {
    dropdownOptions = (
      <FontSizeList
        className="font-size-list"
        open={fontSizeFocused}
        onOutsideMouseDown={toggleSizes}
        style={{
          left: `${fontSizeContainerBounds.left}px`,
          top: `${fontSizeContainerBounds.bottom + 4}px`,
        }}
      >
        <ul>
          {dropdownList ||
            getArrayOfNumbers(min, max).map((item) => {
              return (
                <li
                  key={item}
                  onClick={() => {
                    toggleSizes();
                    handleClick(item, heading);
                  }}
                >
                  {item}
                </li>
              );
            })}
        </ul>
      </FontSizeList>
    );
  }
  return (
    <SubtitleSliderWrap
      titleIcon={titleIcon}
      numberWidth={numberWidth}
      borderRight={borderRight}
    >
      <Typography
        content={heading}
        display="block"
        color={vmTheme[theme].tabTextColor}
        font={font.boldBase}
        className="title"
      />
      <div className="outline-wrapper">
        {leftIcon && <Icon src={`${STATIC_PATH}${leftIcon}`} />}
        <Slider
          min={min}
          max={max}
          inputMin={min}
          inputMax={max}
          step={1}
          value={Number(value)}
          onChangeSliderValue={(e) => onChangeHandler(e.target.value, heading)}
          onMouseUp={(e) => mouseUpHandler(e.target.value, heading)}
          border={"none"}
          borderRadius={"3px"}
          background={vmTheme[theme].veryLightGray}
          margin="0"
          height="5px"
          width={width}
          thumb={{
            background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
            border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
            height: "16px",
            width: "16px",
            hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
            hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
          }}
          progressBackground={vmTheme[theme].secondaryBorderColor}
          isChangeBg={true}
          showValue={false}
          showToolTip={true}
          tooltipBottomOffset={"11px"}
          doubleSide={false}
        />
        {rightIcon && <Icon src={`${STATIC_PATH}${rightIcon}`} />}
        <div ref={textOutlineContainerRef} className="font-size-container">
          <div className="font-size" data-tooltip-id={"tt-tOutline"}>
            <NumberInput
              inputRef={inputFontOutlineRef}
              min={min}
              max={max}
              step={1}
              value={Number(value)}
              precision={1}
              onChange={() => { }}
              onFocus={handleFontOutlineFocus}
              onBlur={handleFontOutlineBlur}
              suffix={(type === "angle")  ? "°": ""}
              saveOnUnmount={false}
            />
            <div
              className="font-size-btn"
              onClick={() => handleChangeFontOutline("INCREASE")}
            >
              <img
                className="font-size-increase"
                alt="font-size-increase"
                src={`${STATIC_PATH}text-property/font-size-increase.svg`}
              />
            </div>
            <div
              className="font-size-btn"
              onClick={() => handleChangeFontOutline("DECREASE")}
            >
              <img
                className="font-size-decrease"
                alt="font-size-decrease"
                src={`${STATIC_PATH}text-property/font-size-decrease.svg`}
              />
            </div>
          </div>
          {dropdownOptions}
        </div>
        {enableColorPick && (
          <ColorPickerButton
            ref={colorPickerRef}
            background={paletteColor}
            onClick={toggleColorPalette}
          />
        )}
        {isOpenColorPicker &&
          ReactDOM.createPortal(
            <ColorPaletteWrapper top={`${getColorPickerPos().x}px`} left={`${getColorPickerPos().y}px`}>
              <ColorPaletteWindow
                colorSelected={(color) => colorSelectionHandler(color, heading)}
                closePopup={toggleColorPalette}
                currentColor={paletteColor}
                itemId={"colorSelector"}
                stopSlidePropogation={true}
                from="TextEffectsSection"
                componentFrom="TXT_EFF_SEC"
              />
            </ColorPaletteWrapper>,
            document.getElementById(PORTAL_ID)
          )}
      </div>
    </SubtitleSliderWrap>
  );
};

SubtitleSliderSection.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  dropdownList: PropTypes.array,
  colorSelectionHandler: PropTypes.func,
  mouseUpHandler: PropTypes.func,
  paletteColor: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  titleIcon: PropTypes.string,
  width: PropTypes.string,
  enableColorPick: PropTypes.bool,
  borderRight: PropTypes.bool,
  numberWidth: PropTypes.string,
  handleClick: PropTypes.func,
  onChangeHandler: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
};

export default SubtitleSliderSection;
