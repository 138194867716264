import React from "react";
import PropTypes from "prop-types";
import { getFlipPosition } from "../workspace/workspace-helper";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import SVGConstructor from "./callout-svgconstructor";
import CustomSVGRender from "../workspace/workspace-customsvgrender";
import Text from "../text/text";

class Callout extends React.Component {
  render() {
    const xRatio =
      this.props.data.get("xRatio") !== undefined
        ? this.props.data.get("xRatio")
        : 5;
    const yRatio =
      this.props.data.get("yRatio") !== undefined
        ? this.props.data.get("yRatio")
        : 5;
    const widthRatio =
      this.props.data.get("widthRatio") !== undefined
        ? this.props.data.get("widthRatio")
        : 90;
    const heightRatio =
      this.props.data.get("heightRatio") !== undefined
        ? this.props.data.get("heightRatio")
        : 90;

    const textX =
      ((xRatio * this.props.data.get("width")) / 100) * this.props.zoomFactor;

    const textY =
      ((yRatio * this.props.data.get("height")) / 100) * this.props.zoomFactor;

    const textWidth =
      ((widthRatio * this.props.data.get("width")) / 100) *
      this.props.zoomFactor;

    const textHeight =
      ((heightRatio * this.props.data.get("height")) / 100) *
      this.props.zoomFactor;

    const flip = getFlipPosition(
      this.props.data.get("flipPosition"),
      null,
      false,
      null
    );
    const { src } = getWorkspaceItemSource({
      item: {
        src: this.props.data.get("src"),
        animsrc: this.props.data.get("animsrc"),
        subType: this.props.data.get("subType"),
        thumb: this.props.data.get("thumb"),
        thumbnail: this.props.data.get("thumbnail"),
        type: this.props.data.get("type"),
      },
    });

    let svgImage = null;
    if (
      (this.props.data.get("type") === "SHAPE" &&
        this.props.data.get("subType") === "CSTMPTH") ||
      (this.props.data.get("type") === "TEXT" &&
        this.props.data.get("subType") === "BANNER")
    ) {
      svgImage = (
        <SVGConstructor
          data_id={this.props.data_id}
          item={this.props.data}
          zoomFactor={this.props.zoomFactor}
          isPlay={this.props.isPlay}
          isPlayAll={this.props.isPlayAll}
        />
      );
    } else {
      svgImage = (
        <CustomSVGRender
          uploadSVG={false}
          workspaceWidth={this.props.workspaceWidth}
          workspaceHeight={this.props.workspaceHeight}
          parentItem={this.parentItem}
          completeWorkspaceItems={this.props.data}
          addTween={null}
          zoomFactor={this.props.zoomFactor}
          assetType={this.props.data.get("type")}
          data_id={this.props.data_id}
          isPlay={this.props.isPlay}
          isPlayAll={this.props.isPlayAll}
          key={this.props.itemId}
          selectedItems={this.props.selectedItems}
          src={src}
          colors={this.props.data.get("colors")}
          parts={this.props.data.get("parts")}
          animdata={this.props.data.get("animdata")}
          setPlay={this.props.setPlay}
          setPlayAll={this.props.setPlayAll}
          prefetchToken={this.props.prefetchToken}
          prefetchComplete={this.props.prefetchComplete}
        />
      );
    }
    return (
      <>
        {svgImage}
        <div
          className="callout-text-container"
          style={{
            transform: `translate(${textX}px, ${textY}px)${flip}`,
            width: `${textWidth}px`,
            height: `${textHeight}px`,
            position: "absolute",
          }}
          data-id={this.props.itemId}
        >
          <Text
            isMouseOnSelectionBox={this.props.isMouseOnSelectionBox}
            text={this.props.data}
            data_id={this.props.data_id}
            itemId={this.props.itemId}
            linkEnabled={this.props.isPlay}
            isGrouped={this.props.isGrouped}
            socket={this.props.socket}
          />
        </div>
      </>
    );
  }
}

Callout.propTypes = {
  data: PropTypes.object,
  zoomFactor: PropTypes.number,
  itemId: PropTypes.string,
  data_id: PropTypes.string,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
  selectedItems: PropTypes.object,
  setPlay: PropTypes.func,
  setPlayAll: PropTypes.func,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  isMouseOnSelectionBox: PropTypes.func,
  isGrouped: PropTypes.bool,
  socket: PropTypes.object,
};

export default Callout;
