import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { STATIC_PATH } from "../constants/config";
import DropdownComp from "./Dropdown";
import { font } from "../constants/font";

const Container = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.polarColor};
  padding: 0px 8px;
  border: 1px solid ${(props) => props.theme.searchBorder};
  border-radius: 8px;
  margin-bottom: 12px;
  gap: 8px;
  &:hover {
    border-color: ${(props) => props.theme.secondaryBorderColor};
  }
`;

const InputText = styled.input.attrs({ type: "text" })`
  width: 80%;
  background: transparent;
  color: ${(props) => props.theme.panelPrimaryColor};
  font: ${font.normalMini_17};
  border: none;

  &:focus {
    outline: none;
    border-radius: 4px;
  }

  ::placeholder {
    text-align: left;
    font: ${font.normalMini_17};
    letter-spacing: 0px;
    color: ${(props) => props.theme.searchTextColor};
    opacity: 0.44;
  }
`;

const SearchInputFilter = ({
  icon,
  options = [],
  optionWidth,
  iconWidth,
  iconHeight,
  actionText,
  actionFont,
  labelProps,
  headerContent,
  placeholder,
  onDropdownSelect,
  inputHandler,
  optionMargin,
  inputValue,
  keyPressHandler,
  onAction,
  onFocus,
  onBlur,
  autoComplete,
}) => {
  return (
    <Container className="search-input-container">
      <img
        src={`${STATIC_PATH}search.svg`}
        width="20px"
        height="20px"
        alt="alt"
      />
      <InputText
        placeholder={placeholder}
        value={inputValue}
        onChange={inputHandler}
        onKeyPress={keyPressHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      {options.length ? (
        <DropdownComp
          icon={icon}
          options={options}
          width={optionWidth}
          margin={optionMargin}
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          labelProps={labelProps}
          checkBoxOption
          padding="10px"
          checkBoxBorderRadius="100%"
          header={headerContent}
          actionText={actionText}
          actionFont={actionFont}
          onHandlerClick={onDropdownSelect}
          onAction={onAction}
        />
      ) : null}
    </Container>
  );
};

SearchInputFilter.propTypes = {
  icon: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, id: PropTypes.string })
  ),
  optionWidth: PropTypes.string,
  optionMargin: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  actionText: PropTypes.string,
  actionFont: PropTypes.string,
  labelProps: PropTypes.object,
  headerContent: PropTypes.string,
  placeholder: PropTypes.string,
  onDropdownSelect: PropTypes.func,
  inputHandler: PropTypes.func,
  inputValue: PropTypes.string,
  keyPressHandler: PropTypes.func,
  onAction: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
};
export default SearchInputFilter;
