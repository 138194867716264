import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Error,
  FormDataHeader,
  FormSection,
  TwoCol,
  Wrappers,
} from "./upgrade-components";
import Typography from "../../common-components/Typography";
import content from "../../constants/content";
import { font } from "../../constants/font";
import LabelDropdown from "../../common-components/LabelDropdown";
import Action from "../../common-components/Action";
import { enterpriseForm, sendMail } from "../../redux/actions/appUtils";
import LabeledInput from "../../common-components/LabeledInput";
import useNotify from "../../helper/hooks/useNotify";
import useUpgrade from "../../helper/hooks/useUpgrade";

const EnterpriseForm = ({ enterpriseContent }) => {
  const [isLoading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    companyName: "",
    phonenumber: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    companyName: "",
    phonenumber: "",
  });
  const [ipData, setIpData] = useState(null);
  const [selectedCompanySize, setSelectedCompanySize] = useState("0 to 5");
  const { isAvatarUpgrade } = useSelector((state) =>
    state.app.get("upgrade").toJS()
  );
  const { username, email } = useSelector((state) => state.userDetails);
  const handleDropdownChanges = (selectedValue) => {
    setSelectedCompanySize(selectedValue);
  };
  const { success, warn } = useNotify();
  const { hideUpgrade } = useUpgrade();


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleKeyPress = (event) => {
    const inputKey = event.key;

    if (!/\d/.test(inputKey)) {
      event.preventDefault();
    }
  };

  const result = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setIpData(data);
  };

  useEffect(() => {
    result();
  }, []);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formValue.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formValue.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValue.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    if (!formValue.companyName) {
      errors.companyName = "Company Name is required";
      isValid = false;
    }

    if (!formValue.phonenumber) {
      errors.phonenumber = "Phone number is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const { country, region, city } = ipData;
      const submitPath = "Myplans";

      const reqBody = {
        a: btoa(formValue.name),
        b: btoa(formValue.email),
        c: btoa(formValue.phonenumber),
        d: btoa(selectedCompanySize),
        e: btoa(country),
        f: btoa(submitPath),
        g: btoa(region),
        h: btoa(formValue.companyName),
        i: btoa(city),
      };

      enterpriseForm(reqBody).then((res) => {
        if (res.error) {
          warn(res.msg);
        } else {
          success(res.msg);
        }
        setLoading(false);
        hideUpgrade()
        setFormValue({
          name: "",
          email: "",
          companyName: "",
          phonenumber: ""
        });
        if (isAvatarUpgrade) {
          const req = {
            name: username,
            email,
            Environment: process.env.NODE_ENV,
            subject: `Vmaker AI Avatar Credits Request From Enterprise Form`,
            toAddress: "akbar@animaker.com, ranga@animaker.com, hola@vmaker.com"
          };
          sendMail(req);
        }
      }).catch((error) => {
        warn(error);
      });
    }
  };

  return (
    <FormDataHeader>
      <Typography
        content={content.ENTERPRISE_PLAN}
        color={"#313345"}
        font={font.bold_20_16}
        display="block"
        align="center"
        padding="5px 0"
        className="enterprise-section"
        margin="0px 0"
      />
      <p>
        {enterpriseContent || (
          <>
            <span>Contact us for customized features </span> and credits
            tailored for your needs.
          </>
        )}
      </p>
      <FormSection className="form-data-tool-bar">
        <LabeledInput
          label="Name"
          value={formValue.name}
          onChange={handleChange}
          type="text"
          name="name"
          required
        />
        {formErrors.name && <Error>{formErrors.name}</Error>}
        <LabeledInput
          label="Company Name"
          value={formValue.companyName}
          onChange={handleChange}
          type="text"
          name="companyName"
          required
        />
        {formErrors.companyName && <Error>{formErrors.companyName}</Error>}

        <LabeledInput
          label="Company Email"
          value={formValue.email}
          onChange={handleChange}
          type="email"
          name="email"
          required
        />
        {formErrors.email && <Error>{formErrors.email}</Error>}

        <TwoCol>
          <div>
            <LabeledInput
              label="Phone Number"
              value={formValue.phonenumber}
              onKeyPress={handleKeyPress}
              onChange={handleChange}
              name="phonenumber"
              type="text"
            />
            {formErrors.phonenumber && <Error>{formErrors.phonenumber}</Error>}
          </div>
          <Wrappers>
            <LabelDropdown
              className="inputDropdown"
              width="100%"
              labelText="Company Size"
              labelEnable
              height="38px"
              border="1px solid #DADBE0"
              optionWidth="100%"
              options={[
                { label: "0 to 5", value: "0 to 5" },
                { label: "10 to 50", value: "10 to 50" },
                { label: "50 to 100", value: "50to100" },
                { label: "200 to 10000", value: "20 to 10000" },
              ]}
              value={selectedCompanySize}
              onChange={handleDropdownChanges}
            />
          </Wrappers>
        </TwoCol>
        <Action
          text={content.SUBMIT}
          background={"#7353FF"}
          hoverColor={"#7353FF"}
          color={"#ffffff"}
          type="submit"
          onClick={submitHandler}
          isLoading={isLoading}
        />
      </FormSection>
    </FormDataHeader>
  );
};

EnterpriseForm.propTypes = {
  enterpriseContent: PropTypes.string
}

export default EnterpriseForm;
