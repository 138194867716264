import ActionTypes from "../../constants/action-types";

export const initialState = {
  notificationList: [],
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notificationList: [payload, ...state.notificationList],
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationList: state.notificationList.filter(
          (item) => item.id !== payload
        ),
      };
    default:
      return state;
  }
};

export default notificationReducer;
