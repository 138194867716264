export const clampFilterValue = (value, intensity) => {
    return ((value * intensity / 100) <= -100) ? -100 : ((value * intensity / 100) >= 100) ? 100 : (value * intensity / 100);
}

export function spontaneousFilters(/* selectedItems, selectedScene, */svgFilterItem, presetName, changingValues, inputValue, type, vignetteDOMId) {
    let brightness = 0;
    let contrast = 0;
    let hue = 0;
    let saturate;
    let tint;
    let blur;
    let vignette = 0;

    if (type === "intensity") {
        const FilterValues = changingValues.get(presetName);

        brightness = clampFilterValue(FilterValues.get("bright"), inputValue);
        contrast = clampFilterValue(FilterValues.get("contrast"), inputValue);
        hue = clampFilterValue(FilterValues.get("hue"), inputValue);
        saturate = clampFilterValue(FilterValues.get("saturate"), inputValue);
        tint = (inputValue === 0) ? 0 : FilterValues.get("tint");
        blur = clampFilterValue(FilterValues.get("blur"), inputValue);
        vignette = clampFilterValue(FilterValues.get("vignette"), inputValue);
    }
    else if (type === "bright") {
        brightness = inputValue;
    }
    else if (type === "contrast") {
        contrast = inputValue;
    }
    else if (type === "hue") {
        hue = inputValue;
    }
    else if (type === "saturate") {
        saturate = inputValue;
    }
    else if (type === "tint") {
        tint = inputValue;
    }
    else if (type === "blur") {
        blur = inputValue;
    }
    else if (type === "vignette") {
        vignette = inputValue;
    }

    if (brightness !== 0) {
        svgFilterItem.getElementById("RbrightnessSVG").setAttribute("intercept", brightness / 200);
        svgFilterItem.getElementById("GbrightnessSVG").setAttribute("intercept", brightness / 200);
        svgFilterItem.getElementById("BbrightnessSVG").setAttribute("intercept", brightness / 200);
    }

    if (contrast !== 0) {
        svgFilterItem.getElementById("RcontrastSVG").setAttribute("slope", (contrast < 0) ? ((contrast + 100) * 0.006) + 0.4 : ((contrast * 1.5) / 100) + 1);
        svgFilterItem.getElementById("GcontrastSVG").setAttribute("slope", (contrast < 0) ? ((contrast + 100) * 0.006) + 0.4 : ((contrast * 1.5) / 100) + 1);
        svgFilterItem.getElementById("BcontrastSVG").setAttribute("slope", (contrast < 0) ? ((contrast + 100) * 0.006) + 0.4 : ((contrast * 1.5) / 100) + 1);

        svgFilterItem.getElementById("RcontrastSVG").setAttribute("intercept", (contrast > 0) ? (-((contrast * 75 / 100) / 100)) : (-(contrast * 30 / 100) / 100));
        svgFilterItem.getElementById("GcontrastSVG").setAttribute("intercept", (contrast > 0) ? (-((contrast * 75 / 100) / 100)) : (-(contrast * 30 / 100) / 100));
        svgFilterItem.getElementById("BcontrastSVG").setAttribute("intercept", (contrast > 0) ? (-((contrast * 75 / 100) / 100)) : (-(contrast * 30 / 100) / 100));
    }

    if (hue !== 0) {
        svgFilterItem.getElementById("HueSVG").setAttribute("values", hue);
    }

    if (saturate !== 0 && saturate !== undefined) {
        const filterValue = [
            ((saturate + 100) * (1.6914 - 0.3086) / (200)) + 0.3086,
            ((saturate + 100) * (- 0.6094 - 0.6094) / (200)) + 0.6094,
            ((saturate + 100) * (- 0.082 - 0.082) / (200)) + 0.082,
            0,
            0,
            ((saturate + 100) * (- 0.3086 - 0.3086) / (200)) + 0.3086,
            ((saturate + 100) * (1.3906 - 0.6094) / (200)) + 0.6094,
            ((saturate + 100) * (- 0.082 - 0.082) / (200)) + 0.082,
            0,
            0,
            ((saturate + 100) * (- 0.3086 - 0.3086) / (200)) + 0.3086,
            ((saturate + 100) * (- 0.6094 - 0.6094) / (200)) + 0.6094,
            ((saturate + 100) * (1.918 - 0.082) / (200)) + 0.082,
            0,
            0,
            0, 0, 0,
            1,
            0
        ].join(',');

        svgFilterItem.getElementById("saturateSVG").setAttribute("values", filterValue);
    }
    else if (saturate === 0) {
        svgFilterItem.getElementById("saturateSVG").removeAttribute("values")
    }

    const feFuncR = svgFilterItem.getElementById("RtintSVG");
    const feFuncG = svgFilterItem.getElementById("GtintSVG");
    const feFuncB = svgFilterItem.getElementById("BtintSVG");
    if (tint !== 0 && tint !== undefined) {
        feFuncR.setAttribute("slope", 0.9)
        feFuncG.setAttribute("slope", 0.9)
        feFuncB.setAttribute("slope", 0.9)

        if (tint > 0 && tint <= 33) {
            feFuncR.setAttribute("intercept", 0)
            feFuncG.setAttribute("intercept", 0.1)
            feFuncB.setAttribute("intercept", tint / 330)
        }
        else if (tint > 33 && tint < 66) {
            feFuncR.setAttribute("intercept", 0)
            feFuncG.setAttribute("intercept", (33 - tint % 33) / 330)
            feFuncB.setAttribute("intercept", 0.1)
        }
        else if (tint >= 66) {
            feFuncR.setAttribute("intercept", (tint - 66) / 330)
            feFuncG.setAttribute("intercept", 0)
            feFuncB.setAttribute("intercept", 0.1)
        }
        else if (tint < 0 && tint >= -33) {
            feFuncR.setAttribute("intercept", -(tint / 330))
            feFuncG.setAttribute("intercept", 0.1)
            feFuncB.setAttribute("intercept", 0)
        }
        else if (tint < -33 && tint > -66) {
            feFuncR.setAttribute("intercept", 0.1)
            feFuncG.setAttribute("intercept", (33 - (-tint) % 33) / 330)
            feFuncB.setAttribute("intercept", 0)
        }
        else if (tint <= -66) {
            feFuncR.setAttribute("intercept", 0.1)
            feFuncG.setAttribute("intercept", 0)
            feFuncB.setAttribute("intercept", -((tint + 66) / 330))
        }
    }
    else if (tint === 0) {
        feFuncR.removeAttribute("slope")
        feFuncG.removeAttribute("slope")
        feFuncB.removeAttribute("slope")

        feFuncR.removeAttribute("intercept")
        feFuncG.removeAttribute("intercept")
        feFuncB.removeAttribute("intercept")
    }

    if (blur !== 0 && blur !== undefined) {
        if (blur > 0) {
            svgFilterItem.getElementsByTagName("feGaussianBlur")[0].setAttribute('stdDeviation', (blur * 3) / 10)
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('operator')
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k1')
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k2')
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k3')
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k4')
            svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('in2')

        }
        else if (blur < 0) {
            svgFilterItem.getElementsByTagName("feGaussianBlur")[0].setAttribute('stdDeviation', 1)
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('operator', "arithmetic")
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('k1', 0)
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('k2', (blur * 6 / 100))
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('k3', (((blur * 6 / 100) * -1) + 1))
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('k4', 0)
            svgFilterItem.getElementsByTagName("feComposite")[0].setAttribute('in2', "preblur")
        }
    }
    else if (blur === 0) {
        svgFilterItem.getElementsByTagName("feGaussianBlur")[0].setAttribute('stdDeviation', 0)
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('operator')
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k1')
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k2')
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k3')
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('k4')
        svgFilterItem.getElementsByTagName("feComposite")[0].removeAttribute('in2')
    }

    /*
    if (vignette !== 0) {
        if (selectedItems.size > 0) {
            let vignetteId = selectedItems.get(0) + "-vignette-" + selectedScene
            if (vignetteDOMId) {
                vignetteId = vignetteDOMId;
            }

            document.getElementById(vignetteId).style.backgroundImage = `radial-gradient(circle farthest-side at center center, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, ${vignette * 0.007}) 130%)`
        }
        else if (selectedItems.size === 0) {
            let vignetteId = "ws-vignette-" + selectedScene

            document.getElementById(vignetteId).style.backgroundImage = `radial-gradient(circle farthest-side at center center, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, ${vignette * 0.007}) 130%)`
        }
    }
    */

    if (vignette !== 0 && vignetteDOMId && typeof vignetteDOMId === "string") {
        const vignetteDOM = document.getElementById(vignetteDOMId);
        if (vignetteDOM) {
            vignetteDOM.style.backgroundImage = `radial-gradient(circle farthest-side at center center, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, ${vignette * 0.007}) 130%)`;
        }
    }
}