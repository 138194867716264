import styled from "styled-components";
import PropTypes from "prop-types";

export const TimelineInnerContainer = styled.div.attrs((props) => {
  return {
    style: {
      height: props.$height,
    },
  };
})`
  width: 100%;
  position: relative;

  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.scrollbarColor};
    border-radius: 16px;
    background-clip: content-box;
    border: 4px solid transparent;
  }
  &::-webkit-scrollbar {
    height: 16px;
  }
  // mozilla scrollbar
  scrollbar-width: auto;
  scrollbar-color: ${(props) => props.theme.scrollbarColor} transparent;
  @media screen and (max-width: 767px) {
    touch-action: auto;
  }
`;
TimelineInnerContainer.propTypes = {
  $height: PropTypes.string.isRequired,
};
TimelineInnerContainer.displayName = "TimelineInnerContainer";
