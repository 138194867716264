import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { WorkspaceBGColorContainer } from "./workspace-components";
import vmTheme from "../../constants/theme";

const WorkspaceBGColor = (props) => {
  const { bgColor } = props;
  const theme = useSelector((state) => state.app.get('theme'));
  const styles = useMemo(() => {
    const styles = {};
    let colors = bgColor.get("colors");

    if (colors !== undefined && colors.size > 0) {
      if (colors.size === 1) {
        styles.backgroundColor = colors.get(0);
      } else {
        let gradient;
        const isRadial = bgColor.get("isRadial");
        let gradientType = "linear";
        if (!isRadial) {
          gradient =
            bgColor.get("direction") !== undefined
              ? `to ${bgColor.get("direction")}, `
              : "to right, ";
        } else {
          gradient = "";
          gradientType = "radial";

          if (bgColor.get("radius") !== "")
            colors = colors.set(
              0,
              `${colors.get(0)} ${bgColor.get("radius")}%`
            );
        }

        colors.forEach((color) => {
          gradient += `${color}, `;
        });

        styles.backgroundImage = `${gradientType}-gradient(${gradient.replace(
          /,\s*$/,
          ""
        )})`;
      }
    } else {
      styles.backgroundColor = vmTheme[theme].blackColor;
    }

    return styles;
  }, [bgColor]);

  return <WorkspaceBGColorContainer style={styles} />;
};
WorkspaceBGColor.propTypes = {
  bgColor: PropTypes.object,
};

export default WorkspaceBGColor;
