import styled from "styled-components";
import { STATIC_PATH } from "../../../../constants/config";
import { ScrollbarCSS } from "../propertywindow-components";
import { font } from "../../../../constants/font";
import { APP_Z_INDICES } from "../../../../constants";

export const ColorButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 42px);
  gap: 12px;

  &.edit-container {
    margin-bottom: 12px;
  }
`;
ColorButtonContainer.displayName = "ColorButtonContainer";

export const ColorButton = styled.div`
  border-radius: 6px;
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;

  &:not(.edit) {
    border: 2px solid ${(props) => props.theme.rgbaBlack};

    &.is-selected,
    &:hover {
      box-shadow: inset 0 0 0 2px ${(props) => props.theme.polarColor};
    }
  }

  &.edit:hover {
    > img {
      content: url(${STATIC_PATH}color-property/edit-hover.svg);
    }
  }

  @media screen and (max-width: 767px) {
    width: 42px;
    height: 42px;
  }
`;
ColorButton.displayName = "ColorButton";

export const ColorGroup = styled.div`
  font: ${font.mediumMini};
  color: ${(props) => props.theme.stockActionColor};
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    p {
      margin: 10px 0px 10px;
    }
  }
`;
ColorGroup.displayName = "ColorGroup";

export const ColorList = styled.div`
  height: 100%;
  padding: 8px;
  overflow-x: hidden;
  overflow-y: scroll;

  ${ScrollbarCSS};

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  @media screen and (max-width: 767px) {
    touch-action: auto;
  }
`;
ColorList.displayName = "ColorList";

export const ColorPickerContainer = styled.div`
  width: 100%;
  display: inline-block;
  position: absolute;
  width: 266px;
  background: white;
  top: 0%;
  left: 0%;
  box-shadow: 0px 0px 6px ${(props) => props.theme.menuBoxShawdow};
  border-radius: 4px;
  padding: 21px;
  transform: none;
  z-index: ${APP_Z_INDICES.colorpicker.default};

  .hue {
    width: 100%;
    height: 15px;
    position: relative;
    border-radius: 7.5px;
    background: -moz-${(props) => props.theme.heuColor};
    background: -webkit-${(props) => props.theme.heuColor};
    background: -ms-${(props) => props.theme.heuColor};
    background: -o-${(props) => props.theme.heuColor};
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
  }

  .hue .hue-slider {
    width: 2.5px;
    height: 2.5px;
    position: absolute;
    cursor: pointer;
    border: 2.5px solid ${(props) => props.theme.polarColor};
    padding: 5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 2.5px ${(props) => props.theme.rgbaBlack5};
    box-shadow: 0 0 2.5px ${(props) => props.theme.rgbaBlack5};
  }

  .picker-area {
    width: 100%;
    height: 175px;
    margin-top: 20px;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    background: -moz-linear-gradient(
        top,
        hsl(0, 0%, 100%) 0%,
        hsla(0, 0%, 100%, 0) 50%,
        hsla(0, 0%, 0%, 0) 50%,
        hsl(0, 0%, 0%) 100%
      ),
      -moz-linear-gradient(left, hsl(0, 0%, 50%) 0%, hsla(0, 0%, 50%, 0) 100%);
    background: -webkit-linear-gradient(
        top,
        hsl(0, 0%, 100%) 0%,
        hsla(0, 0%, 100%, 0) 50%,
        hsla(0, 0%, 0%, 0) 50%,
        hsl(0, 0%, 0%) 100%
      ),
      -webkit-linear-gradient(left, hsl(0, 0%, 50%) 0%, hsla(0, 0%, 50%, 0) 100%);
    background: -ms-linear-gradient(
        top,
        hsl(0, 0%, 100%) 0%,
        hsla(0, 0%, 100%, 0) 50%,
        hsla(0, 0%, 0%, 0) 50%,
        hsl(0, 0%, 0%) 100%
      ),
      -ms-linear-gradient(left, hsl(0, 0%, 50%) 0%, hsla(0, 0%, 50%, 0) 100%);
    background: -o-linear-gradient(
        top,
        hsl(0, 0%, 100%) 0%,
        hsla(0, 0%, 100%, 0) 50%,
        hsla(0, 0%, 0%, 0) 50%,
        hsl(0, 0%, 0%) 100%
      ),
      -o-linear-gradient(left, hsl(0, 0%, 50%) 0%, hsla(0, 0%, 50%, 0) 100%);
    background-color: #f00;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
  }

  .picker-area .picker {
    width: 15px;
    height: 15px;
    border: 2px solid;
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    box-shadow: 0 0 2.5px ${(props) => props.theme.rgbaWhite5};
  }

  .hex-input-container {
    width: 174px;
    height: 36px;
    margin-top: 10px;
    display: inline-flex;
    justify-content: flex-end;
    background: ${(props) => props.theme.liBgColor} 0% 0% no-repeat padding-box;
    border-radius: 4px;
    @media (max-width: 768px) {
      min-width: calc(100% - 40px);
    }
  }

  .hex-input-container.palette input {
    max-width: 112.5px;
  }

  .hex-input-container span {
    padding: 10px 0px;
    display: inline-block;
    background-color: ${(props) => props.theme.liBgColor};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .hex-input-container input {
    display: inline-block;
    padding: 8px 0px;
    border: none;
    background: ${(props) => props.theme.liBgColor} 0% 0% no-repeat padding-box;
    outline: none;
    max-width: 60px;
    font: normal normal normal 15px/14px PT Sans;
    text-transform: uppercase;
  }

  .color-picker {
    width: 32px;
    position: relative;
    left: 4px;
    cursor: pointer;
    padding: 1px 5px;

    &:hover {
      img {
        content: url(${STATIC_PATH}color-property/color-picker-hover.svg);
      }
    }
  }

  .color-picker.palette {
    top: 13px;
    left: 8px;
    display: inline-flex;
  }

  .color-picker img {
    width: 36px;
    height: 36px;
  }
`;
ColorPickerContainer.displayName = "ColorPickerContainer";

export const ColorPropertyStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 53px min-content 1fr;
  max-height: 100%;

  > .clrp--header {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.panelPrimaryColor};
    padding: 0 16px;

    > .clrp--heading {
      display: flex;
      align-items: center;
      cursor: pointer;
      font: ${font.boldMini_15};
    }

    > .clrp--reset {
      margin-left: auto;
      font: ${font.normalBase};
      cursor: pointer;
    }
  }

  > ${ColorButtonContainer} {
    padding: 16px 16px 20px 16px;
    border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
  }

  > .clrp--body {
    overflow: hidden;
    padding: 16px 6px 0px 8px;
  }
`;
ColorPropertyStyled.displayName = "ColorTopSection";

export const ColorThemeContainer = styled.div`
  padding: 10px;
  margin: 16px;
  border: 1px solid ${(props) => props.theme.mercuryColor};
  border-radius: 4px;
  color: ${(props) => props.theme.tertiaryColor};
  cursor: pointer;
  height: 52px;
  background: ${(props) => props.theme.liBgColor};

  > .theme-container {
    display: grid;
    grid-template-columns: 1fr 32px;

    > .current-theme-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        width: 100%;
      }

      > div:first-child {
        font: ${font.normalMicroLarge};
      }

      > div:last-child {
        font: ${font.boldMini_15};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    > .current-theme-img {
      width: 32px;
      height: 32px;

      img {
        display: block;
        width: 32px;
        height: 32px;
        position: relative;
        border: 1px solid ${(props) => props.theme.veryLightGray};
        border-radius: 4px;
      }
    }
  }
`;
ColorThemeContainer.displayName = "ColorThemeContainer";

export const ColorThemeDropDown = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 5px 0 10px;
  border-radius: 4px;
  user-select: none;
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: ${(props) => props.theme.rgbaBlack12} 0px 1px 6px;

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  > ul.themes-tab {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    text-align: center;
    text-transform: capitalize;

    > li {
      border-bottom: 2px solid ${(props) => props.theme.themeTabBorderColor};
      padding: 10px 0;
      color: ${(props) => props.theme.colorThemeDropDownli};
      font: ${font.normalBase};
      cursor: pointer;

      &.active,
      &:hover {
        color: ${(props) => props.theme.tertiaryColor};
        font-weight: 700;
        position: relative;
      }

      &.active::after {
        content: "";
        position: absolute;
        height: 4px;
        width: calc(100% - 6px);
        background: ${(props) => props.theme.secondaryBorderColor};
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  > ul.theme-collections {
    font-size: 12px;
    color: ${(props) => props.theme.collectionsColor};
    padding: 5px 0;
    max-height: 378px;
    overflow-y: scroll;
    ${ScrollbarCSS}

    > li {
      padding: 1px 13px;
      border-left: 4px solid transparent;

      > div.theme-name {
        padding: 5px 0;
        font: ${font.normalMicroLarge};
      }

      > div.theme-list {
        padding-bottom: 5px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1px;

        > div {
          aspect-ratio: 1;
        }

        > div:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        > div:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &.active,
      &:hover {
        border-left: 4px solid ${(props) => props.theme.keyboardTextColor};
        background-color: ${(props) => props.theme.collectionsColorActive};
      }
    }
  }
`;
ColorThemeDropDown.displayName = "ColorThemeDropDown";
