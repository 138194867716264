import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { PlotType, SliderColorType } from "../timeline-proptypes";
import { font } from "../../../constants/font";
import { SLIDER_TYPES } from "../timeline-constants";

export const OuterThumb = styled.div.attrs((props) => {
  let xOffset = -1;
  if (props.$thumbType === "exitEnd") {
    xOffset = -7;
  }
  return {
    style: {
      transform: `translateX(${props.$x + xOffset}px)`,
      "--timeline-slider-outerthumb-clr": props.$color
        ? props.$color
        : "transparent",
      "--timeline-slider-outerthumb-hoverclr": props.$hoverColor
        ? props.$hoverColor
        : "transparent",
    },
  };
})`
  position: absolute;
  transform: translate(0, 0);
  transform-origin: 0 0;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
  cursor: ew-resize;

  ::after {
    content: " ";
    position: absolute;
    width: 2px;
    height: calc(100% - 10px);
    max-height: 26px;
    border-radius: 1px;
    background-color: var(--timeline-slider-outerthumb-clr);
  }
  &.timeline-slider-thumb--enterStart {
    ::after {
      left: 100%;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
  &.timeline-slider-thumb--exitEnd {
    ::after {
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
    }
  }
  &:hover,
  &.timeline-slider--selected {
    ::after {
      background-color: var(--timeline-slider-outerthumb-hoverclr);
    }
  }

  &.timeline-slider--disabled {
    pointer-events: none;
  }

  &.timeline-slider-thumb--hidden {
    opacity: 0;
  }
`;
OuterThumb.propTypes = {
  $thumbType: PropTypes.oneOf(["enterStart", "exitEnd"]),
  $x: PropTypes.number.isRequired,
  $color: PropTypes.string,
  $hoverColor: PropTypes.string,
};
OuterThumb.displayName = "OuterThumb";

export const InnerThumb = styled.div.attrs((props) => {
  return {
    style: {
      transform: `translateX(${props.$x - 3}px)`,
      "--timeline-slider-innerthumb-clr": props.$color
        ? props.$color
        : "transparent",
      "--timeline-slider-innerthumb-hoverclr": props.$hoverColor
        ? props.$hoverColor
        : "transparent",
    },
  };
})`
  position: absolute;
  transform: translate(0, 0);
  transform-origin: 0 0;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  cursor: ew-resize;

  ::after {
    content: " ";
    position: absolute;
    width: 6px;
    height: calc(100% - 10px);
    max-height: 26px;
    border-radius: 4px;
    left: 3px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--timeline-slider-innerthumb-clr);
  }
  &:hover,
  &.timeline-slider--selected {
    ::after {
      background-color: var(--timeline-slider-innerthumb-hoverclr);
    }
  }

  &.timeline-slider--disabled {
    pointer-events: none;
  }

  &.timeline-slider-thumb--hidden {
    opacity: 0;
  }
`;
InnerThumb.propTypes = {
  $thumbType: PropTypes.oneOf(["enterEnd", "exitStart"]),
  $x: PropTypes.number.isRequired,
  $color: PropTypes.string,
};
InnerThumb.displayName = "InnerThumb";

export const EffectDuration = styled.div.attrs((props) => {
  return {
    style: {
      transform: `translateX(${props.$x}px)`,
      width: `${props.$width}px`,
      "--timeline-slider-effectduration-clr": props.$color
        ? props.$color
        : "transparent",
      "--timeline-slider-effectduration-hoverclr": props.$hoverColor
        ? props.$hoverColor
        : "transparent",
    },
  };
})`
  position: absolute;
  transform-origin: 0 0;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
  background: var(--timeline-slider-effectduration-clr);

  &:hover,
  &.timeline-slider--selected {
    background: var(--timeline-slider-effectduration-hoverclr);
  }

  &.timeline-slider--disabled {
    pointer-events: none;
  }
`;
EffectDuration.propTypes = {
  $x: PropTypes.number.isRequired,
  $width: PropTypes.number.isRequired,
  $color: PropTypes.string,
};
EffectDuration.displayName = "EffectDuration";

export const SliderOverlay = styled.div.attrs((props) => {
  return {
    style: {
      "--timeline-slider-overlay-clr": props.$color
        ? props.$color
        : "transparent",
      "--timeline-slider-overlay-bgimg": props.$gradient
        ? props.$gradient
        : "none",
      "--timeline-slider-overlay-hoverclr": props.$hoverColor
        ? props.$hoverColor
        : "transparent",
      "--timeline-slider-overlay-hoverbgimg": props.$hoverGradient
        ? props.$hoverGradient
        : "none",
    },
  };
})`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--timeline-slider-overlay-clr);
  background-image: var(--timeline-slider-overlay-bgimg);

  &:hover,
  &.timeline-slider--selected {
    background-color: var(--timeline-slider-overlay-hoverclr);
    background-image: var(--timeline-slider-overlay-hoverbgimg);
  }
`;
SliderOverlay.propTypes = {
  $color: PropTypes.string,
  $gradient: PropTypes.string,
  $hoverColor: PropTypes.string,
  $hoverGradient: PropTypes.string,
};
SliderOverlay.displayName = "SliderOverlay";

export const SliderContainer = styled.div.attrs((props) => {
  const sliderPlot = props.$plot;
  let { default: defaultColors, selected: selectedColors } = props.$colors;
  const { trackChange: trackChangeColors } = props.$colors;
  if (props.$forTrackChange && trackChangeColors) {
    defaultColors = trackChangeColors;
    selectedColors = trackChangeColors;
  }

  return {
    style: {
      transform: `translate(${sliderPlot.x}px, ${sliderPlot.y}px)`,
      width: `${sliderPlot.width}px`,
      height: `${sliderPlot.height}px`,
      "--timeline-slider-bg-clr": defaultColors.bg
        ? defaultColors.bg
        : "transparent",
      "--timeline-slider-border-clr": defaultColors.border
        ? defaultColors.border
        : "transparent",
      "--timeline-slider-overlay-clr": defaultColors.overlay
        ? defaultColors.overlay
        : "transparent",
      "--timeline-slider-bg-hoverclr": selectedColors.bg
        ? selectedColors.bg
        : "transparent",
      "--timeline-slider-border-hoverclr": selectedColors.border
        ? selectedColors.border
        : "transparent",
      "--timeline-slider-outerthumb-hoverclr": selectedColors.outerThumbs
        ? selectedColors.outerThumbs
        : "transparent",
      "--timeline-slider-innerthumb-hoverclr": selectedColors.innerThumbs
        ? selectedColors.innerThumbs
        : "transparent",
      "--timeline-slider-effectduration-hoverclr": selectedColors.effect
        ? selectedColors.effect
        : "transparent",
      "--timeline-slider-overlay-hoverclr": selectedColors.overlay
        ? selectedColors.overlay
        : "transparent",
    },
  };
})`
  transform-origin: 0 0;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;

  display: flex;
  align-items: center;

  background-color: var(--timeline-slider-bg-clr);
  border-radius: var(--timeline-slider-br);
  overflow: visible; // to make thumb draggable outside slider
  pointer-events: auto;
  cursor: grab;

  .timeline-slider--content {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: var(--timeline-slider-br);
    overflow: hidden;
    pointer-events: none;

    ::after {
      // to prevent layout shift on slider hover or select
      content: " ";
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 1px solid var(--timeline-slider-border-clr);
      border-radius: var(--timeline-slider-br);
    }
  }

  &.timeline-slider--is-drag-slider,
  &.timeline-slider--is-thumb-drag {
    z-index: 2;
  }

  &:hover,
  &.timeline-slider--selected {
    background-color: var(--timeline-slider-bg-hoverclr);

    .timeline-slider--content {
      ::after {
        // to prevent layout shift on slider hover or select
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 2px solid var(--timeline-slider-border-hoverclr);
      }
    }

    ${OuterThumb} {
      ::after {
        background-color: var(--timeline-slider-outerthumb-hoverclr);
      }
    }

    ${InnerThumb} {
      ::after {
        background-color: var(--timeline-slider-innerthumb-hoverclr);
      }
    }

    ${EffectDuration} {
      background-color: var(--timeline-slider-effectduration-hoverclr);
    }

    ${SliderOverlay} {
      background-color: var(--timeline-slider-overlay-hoverclr);
    }
  }

  &.timeline-slider--for-track-change,
  &.timeline-slider--for-track-change:hover {
    z-index: 1;

    .timeline-slider--content {
      ::after {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-width: 1px;
      }
    }
  }

  &.timeline-slider--disabled {
    pointer-events: none;
  }

  &.timeline-slider--hidden {
    opacity: 0;
  }

  &.timeline-slider--use-org-pos {
    opacity: 0.4;
  }
  &.timeline-slider--is-drag-slider.timeline-slider--is-overlapping {
    opacity: 0.64;
  }
  &.timeline-slider--for-track-change.timeline-slider--is-overlapping {
    opacity: 0.4;
  }

  &.timeline-slider--st-${SLIDER_TYPES.GAP} {
    cursor: pointer;
  }
  --timeline-slider-br: 8px;
  &.timeline-slider--st-${SLIDER_TYPES.MINI_SUBTITLE} {
    --timeline-slider-br: 4px;
    cursor: pointer;
  }
`;
SliderContainer.propTypes = {
  $plot: PlotType.isRequired,
  $colors: PropTypes.shape({
    default: SliderColorType.isRequired,
    selected: SliderColorType.isRequired,
    trackChange: SliderColorType,
  }).isRequired,
  $forTrackChange: PropTypes.bool.isRequired,
};
SliderContainer.displayName = "SliderContainer";

export const MenuIcon = styled.img.attrs((props) => {
  return {
    style: {
      left: props.$left,
    },
  };
})`
  width: ${(props) => props.width};
  height: 18px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  user-select: none;
`;
MenuIcon.displayName = "ContextmenuIcon";

export const Duration = styled.div`
  width: auto;
  height: 12px;
  position: absolute;
  border-radius: 2px;
  background: ${(props) => props.theme.polarColor} 0 0 no-repeat padding-box;
  font: ${font.normalMicro_10};
  color: ${(props) => props.theme.primaryColor};
  padding: 1px 1px 0;
  z-index: 1;
  top: 4px;
  ${(props) => {
    if (props.isDragging) {
      return css`
        right: 4px;
      `;
      // eslint-disable-next-line no-else-return
    } else {
      return css`
        left: 4px;
      `;
    }
  }}
`;
