import PropTypes from "prop-types";
import React, { forwardRef, useContext } from "react";
import { TimelineInnerContainer } from "./timeline-inner-components";
import { TimelineScrollUpdateContext } from "../contexts/timeline-scroll-context";

const TimelineInner = forwardRef((props, ref) => {
  const { children, height, onPointerMove, onPointerLeave } = props;
  const { onScrollLeft, registerScrollLeftEl } = useContext(
    TimelineScrollUpdateContext
  );

  const assignTimelineInnerRef = (r) => {
    if (typeof registerScrollLeftEl === "function") {
      registerScrollLeftEl(r);
    }
    if (typeof ref === "function") {
      ref(r);
    } else if (ref && typeof ref === "object") {
      ref.current = r;
    }
  };

  return (
    <TimelineInnerContainer
      ref={assignTimelineInnerRef}
      $height={height}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}
      onScroll={onScrollLeft}
    >
      {children}
    </TimelineInnerContainer>
  );
});
TimelineInner.displayName = "TimelineInner";
TimelineInner.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string.isRequired,
  onPointerMove: PropTypes.func,
  onPointerLeave: PropTypes.func,
};

export default TimelineInner;
