import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import vmTheme from "../../../constants/theme";
import { SpeedVideoContainer } from "./propertywindow-components";
import { updateTimelineTime } from "../../../redux/actions/timelineUtils";
import GroupedButtons from "../../../common-components/GroupedButtons";

const itemHoverEffect = `
transform: scale(1.05);
transition: transform 0.2s ease-in-out;
`;

const inputActiveEffect = `
border: 2px solid #00BAFF;
background: #F0FBFE;
`;

const customeInputError = `
border: 2px solid #ED2B3E;
background: #F0FBFE;
`;

const buttonsArr = [
  {
    name: 0.5,
    value: "0.5x",
  },
  {
    name: 1,
    value: "1x",
  },
  {
    name: 1.5,
    value: "1.5x",
  },
  {
    name: 2,
    value: "2x",
  },
];

const speedList = [0.5, 1, 1.5, 2]

const VideoSpeed = () => {
  const dispatch = useDispatch();
  const [isCustomInputError, setCustomInputError] = useState(false);
  const workspaceItems = useSelector((state) => state.projectDetails);
  const appReducer = useSelector((state) => state.app);
  const selectedItem = appReducer.get("selectedItems");
  const item = workspaceItems.getIn(["workspaceItems", selectedItem.get(0)]);
  const speed = item.get("speed");
  const theme = useSelector((state) => state.app.get("theme"));


  const onSave = useCallback((videoSpeed) => {
    const videoStart = item.get("videoStart");
    const videoEnd = item.get("videoEnd");
    const videoDuration = videoEnd - videoStart;
    const updateVideoEnd = videoDuration / videoSpeed;
    const updateExitEnd = item.get("enterStart") + updateVideoEnd;
    const isAnimate = item.get("exitEffectName") === "no_Effect" ;
    const animDuration =isAnimate ?  item.get("exitStart") :  item.get("exitEnd") - item.get("exitStart");
    // const duration = videoDuration - animDuration;
    // const exitStart = duration / videoSpeed;
    const updateExitStart = updateExitEnd  -  animDuration;
    const itemSpeed = parseFloat(videoSpeed)
    const toUpdate = [];

    toUpdate.push(          {
      id: selectedItem.get(0),
      container: "workspaceItems",
      toUpdate: {
          speed: itemSpeed,
          exitEnd: updateExitEnd,
          exitStart: updateExitStart
      },
    })

    // For transitions, Need to remove enterId and exitId if applied speed
    if (item.get("transitionEnterId") !== "none") {
      const nextItem = workspaceItems.getIn(["workspaceItems", item.get("transitionEnterId")])
      if (nextItem) {
        toUpdate.push({
          id: selectedItem.get(0),
          container: "workspaceItems",
          toUpdate: {
            transitionEnterId: "none"
          },
        })
        toUpdate.push({
          id: nextItem.get("id"),
          container: "workspaceItems",
          toUpdate: {
            transitionExitId: "none"
          },
        })
      }
    }

    dispatch(updateTimelineTime({ toUpdate }));
  },[item]) 

  const onChange = (value) => {
    if (value < 0.5 || value > 10) {
      setCustomInputError(true);
    } else if (value <= 10 ){
      onSave(value);
      setCustomInputError(false);
    }
  }

  return (
    <SpeedVideoContainer>
      <GroupedButtons
        buttonsArray={buttonsArr}
        buttonDefaultColor={"#FFFFFF"}
        showHoverColor={false}
        hoverEffects={itemHoverEffect}
        activeEffects={isCustomInputError ? customeInputError : inputActiveEffect}
        onClick={onSave}
        buttonWidth={"49px"}
        onChange={onChange}
        isMultiSelect={false}
        fontColor={vmTheme[theme].panelPrimaryColor}
        inputType={"number"}
        selectedButton={speed || 1}
        enableCustomInput={true}
        isCustomInput={speed && !speedList.includes(speed)}
        borderRadius={"6px"}
      />
    </SpeedVideoContainer>
  );
};

export default VideoSpeed;
