import { ITEM_CONFIG } from "../constants/config";
import { isCustomBG, isImageOnly, isVideoOnly, randomString } from "../containers/timeline/timeline-helper";
import { getRotatedPoint } from "./TransformManagerHelper";

const { DEFAULT_FILTER } = ITEM_CONFIG.IMAGE;

const isVmakerSupportedType = (item) => {
  return (
    isImageOnly(item.type, item.subType)
    || isVideoOnly(item.type, item.subType)
    || item.type === "TEXT"
    || item.type === "SHAPE"
    || item.type === "PROP"
    // || item.type === "FRAME" // vmaker partially supports frame, include frame is needed
  );
}

const containerKeys = {
  audios: { startKey: "playStart", endKey: "playEnd" },
  workspaceItems: { startKey: "enterStart", endKey: "exitEnd" },
  // workspaceChildren: { startKey: "enterStart", endKey: "exitEnd" },
};

const getSortPriority = (item) => {
  const sortOrder = {
    VIDEO: 0,
    IMAGE: 1,
    OTHERS: 2,
    SHAPE: 3,
    TEXT: 4, // having text on top
  };

  let sortType = sortOrder[item.type] !== undefined ? item.type : "OHTERS";
  if (isVideoOnly(item.type, item.subType)) {
    sortType = "VIDEO";
  } else if (isImageOnly(item.type, item.subType)) {
    sortType = "IMAGE";
  }

  return sortOrder[sortType];
}

const itemComparator = (item1Id, item2Id, container, containerData) => {
  const { startKey, endKey } = containerKeys[container];

  const item1 = containerData[item1Id];
  const item2 = containerData[item2Id];

  let toReturn = item1.track - item2.track;
  if (toReturn === 0) {
    toReturn = item1[startKey] - item2[startKey];
    if (container !== "audios" && toReturn === 0) {
      toReturn = getSortPriority(item1) - getSortPriority(item2);
      if (toReturn === 0) {
        toReturn = item1.width * item1.height - item2.width * item2.height; // check using area taken
        if (toReturn === 0) {
          // as final resort
          toReturn = item1[endKey] - item2[endKey];
        }
      }
    }
  }

  return toReturn;
}

const isOverlap = (item1Id, item2Id, container, containerData) => {
  const { startKey, endKey } = containerKeys[container];
  const item1 = containerData[item1Id];
  const item2 = containerData[item2Id];

  if (!item1 || !item2) {
    return false;
  }

  return !(
    item1[startKey] >= item2[endKey]
    || item1[endKey] <= item2[startKey]
  );
}

const handleTimeOverlap = (container, containerData, trackStart = 0) => {
  // assign unique track numbers and remove empty spaces (imagine pulling down items from top to fill spaces)
  // this way is more intuitive than handling overlap with original track numbers by sorting on each track modification

  const tracks = {};
  const itemIds = Reflect.ownKeys(containerData);
  itemIds.sort((item1Id, item2Id) => itemComparator(item1Id, item2Id, container, containerData));

  // assign unique track
  for (let i = 0; i < itemIds.length; i += 1) {
    const itemId = itemIds[i];
    containerData[itemId].track = trackStart + i;
    tracks[trackStart + i] = [itemId];
  }

  // pull down items to fill track spaces
  for (let i = 0; i < itemIds.length; i += 1) {
    const itemId = itemIds[i];
    const item = containerData[itemId];
    let newTrack = item.track;

    for (let c = item.track - 1; c >= trackStart; c -= 1) {
      const currentTrackInfo = tracks[c];
      let isOverlapping = false;

      for (let j = 0; j < currentTrackInfo.length; j += 1) {
        const currentItemId = currentTrackInfo[j];
        isOverlapping = isOverlap(itemId, currentItemId, container, containerData);

        if (isOverlapping) {
          break;
        }
      }

      if (isOverlapping) {
        break;
      }

      newTrack = c;
    }

    if (newTrack !== item.track && newTrack >= trackStart) {
      tracks[item.track] = tracks[item.track].filter(id => id !== item.id);
      item.track = newTrack;
      tracks[item.track].push(item.id);
    }
  }
}

const animakerToVmakerAudio = (audioId, audio) => {
  const audioTemplate = {
    id: audioId || randomString(),
    playStart: audio.playStart,
    src: audio.src,
    musicStart: audio.musicStart,
    type: audio.type,
    fadeDetails: audio.fadeDetails,
    volume: audio.volume,
    musicDuration: audio.musicDuration,
    playEnd: audio.playEnd,
    assetId: audio.assetId,
    name: audio.name,
    musicEnd: audio.musicEnd,
    subType: audio.subType,
    track: Number(audio.track.split("track")[1]),
  };

  if (audioTemplate.subType === "bgm") {
    audioTemplate.subType = audioTemplate.subType.toUpperCase();
  }

  if (!audioTemplate.fadeDetails) {
    const fadeDetails = [];
    const amp = audio.volume;
    if (audio.musicDuration > 1) {
      fadeDetails.push({ a: amp, t: audio.musicStart + 2 });
      fadeDetails.push({ a: amp, t: audio.musicStart + 4 });
      fadeDetails.push({ a: amp, t: audio.musicEnd - 2 });
      fadeDetails.push({ a: amp, t: audio.musicEnd - 4 });
    } else if (audio.musicDuration <= 1) {
      fadeDetails.push({ a: amp, t: audio.musicStart + 0.1 });
      fadeDetails.push({ a: amp, t: audio.musicEnd - 0.1 });
    }
    audioTemplate.fadeDetails = fadeDetails;
  }

  return audioTemplate;
}

/**
 * @param {object} params
 * @param {string} params.id
 * @param {object} params.bgItem
 * @param {number} params.sceneStart
 * @param {number} params.sceneDuration
 * @param {number} params.projectWidth
 * @param {number} params.projectHeight
 */
const animakerToVideoBG = (params = {}) => {
  const { bgItem, id, sceneDuration, sceneStart, projectHeight, projectWidth } = params;

  const bgTemplate = {
    id,

    type: bgItem.type,
    subType: bgItem.subType,
    src: bgItem.src,
    thumb: bgItem.thumb || bgItem.thumbnail || "",
    sourceType: bgItem.sourceType,

    enterStart: sceneStart,
    enterEnd: 0,
    enterEffectName: "no_Effect",
    exitStart: 0,
    exitEnd: sceneStart + sceneDuration,
    exitEffectName: "no_Effect",
    track: 0, // always have bg on first track

    x: bgItem.x * projectWidth,
    y: bgItem.y * projectHeight,
    width: bgItem.width * projectWidth,
    height: bgItem.height * projectHeight,
    pwidth: bgItem.pwidth !== undefined ? bgItem.pwidth : bgItem.width * projectWidth,
    pheight: bgItem.pheight !== undefined ? bgItem.pheight : bgItem.height * projectHeight,
    angle: bgItem.angle,

    assetId: bgItem.assetId || bgItem.stockId,
    name: bgItem.name,

    opacity: 1,
    filter: bgItem.bgFilter || DEFAULT_FILTER,
    flipPosition: bgItem.flipPosition,

    isCropped: false,
    original: {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
    },
  };

  if (bgTemplate.type === "UPLOADS") {
    bgTemplate.type = bgTemplate.subType;
  }

  if (isImageOnly(bgTemplate.type, bgTemplate.subType)) {
    bgTemplate.recentEdit = bgItem.recentEdit || "";
    bgTemplate.bgremoval = bgItem.bgremoval || false;
    bgTemplate.stickerify = bgItem.stickerify || false
    bgTemplate.stickerifyPath = bgItem.stickerifyPath || "";
    bgTemplate.bgremovalPath = bgItem.bgremovalPath || "";
    bgTemplate.originalPath = bgItem.originalPath || "";
    bgTemplate.strokeOptions = bgItem.strokeOptions || null;
    bgTemplate.firstEdit = bgItem.firstEdit || "";
  } else if (isVideoOnly(bgTemplate.type, bgTemplate.subType)) {
    bgTemplate.videoStart = bgItem.videoStart;
    bgTemplate.videoEnd = bgItem.videoEnd;
    bgTemplate.videoDuration = bgItem.videoDuration;
    bgTemplate.volume = bgItem.volume / 100;
  }

  if (bgItem.link) {
    // for vimeo src
    bgTemplate.src = bgItem.link;
  }

  return bgTemplate;
}

/**
 * @param {object} params
 * @param {string} params.id
 * @param {object} params.item
 * @param {number} params.sceneStart
 * @param {boolean} params.enableAnimation
 */
const animakerToVmakerItem = (params) => {
  const { item, id, sceneStart, enableAnimation = true } = params;

  const itemTemplate = {
    id,

    type: item.type,
    subType: item.subType,

    enterStart: sceneStart + item.enterStart,
    enterEnd: 0,
    enterEffectName: "no_Effect",
    exitStart: 0,
    exitEnd: sceneStart + item.exitEnd,
    exitEffectName: "no_Effect",
    track: item.index + 1, // bg will be on first track

    x: item.x,
    y: item.y,
    width: item.width,
    height: item.height,
    angle: item.angle,

    assetId: item.assetId || item.stockId,

    opacity: item.opacity,
    flipPosition: item.flipPosition,
  };

  if (enableAnimation) {
    itemTemplate.enterEnd = sceneStart + item.enterEnd;
    itemTemplate.enterEffectName = item.enterEffectName;

    itemTemplate.exitStart = sceneStart + item.exitStart;
    itemTemplate.exitEffectName = item.exitEffectName;
  }

  if (itemTemplate.type === "UPLOADS") {
    itemTemplate.type = itemTemplate.subType;
  }

  if (isImageOnly(itemTemplate.type, itemTemplate.subType) || isVideoOnly(itemTemplate.type, itemTemplate.subType)) {
    itemTemplate.name = item.name;

    itemTemplate.src = item.src;
    itemTemplate.thumb = item.thumb || item.thumbnail;
    itemTemplate.sourceType = item.sourceType;

    itemTemplate.pwidth = item.pwidth !== undefined ? item.pwidth : item.width;
    itemTemplate.pheight = item.pheight !== undefined ? item.pheight : item.height;

    itemTemplate.filter = item.filter || DEFAULT_FILTER;

    if (item.isCropped) {
      itemTemplate.isCropped = true;
      itemTemplate.original = item.original;
    } else {
      itemTemplate.isCropped = false;
      itemTemplate.original = {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      };
    }

    if (item.link) {
      // for vimeo src
      itemTemplate.src = item.link;
    }
  }

  if (isImageOnly(itemTemplate.type, itemTemplate.subType)) {
    itemTemplate.recentEdit = item.recentEdit || "";
    itemTemplate.bgremoval = item.bgremoval || false;
    itemTemplate.stickerify = item.stickerify || false
    itemTemplate.stickerifyPath = item.stickerifyPath || "";
    itemTemplate.bgremovalPath = item.bgremovalPath || "";
    itemTemplate.originalPath = item.originalPath || "";
    itemTemplate.strokeOptions = item.strokeOptions || null;
    itemTemplate.firstEdit = item.firstEdit || "";
  } else if (isVideoOnly(itemTemplate.type, itemTemplate.subType)) {
    itemTemplate.videoStart = item.videoStart;
    itemTemplate.videoEnd = item.videoEnd;
    itemTemplate.videoDuration = item.videoDuration;
    itemTemplate.volume = item.volume / 100;
  }

  if (itemTemplate.type === "TEXT") {
    itemTemplate.name = item.name;

    itemTemplate.textData = item.textData;
    itemTemplate.src = "";
    itemTemplate.thumb = "text1.png";

    itemTemplate.widthRatio = item.widthRatio !== undefined ? item.widthRatio : null;
    itemTemplate.heightRatio = item.heightRatio !== undefined ? item.heightRatio : null;
    itemTemplate.xRatio = item.xRatio !== undefined ? item.xRatio : null;
    itemTemplate.yRatio = item.yRatio !== undefined ? item.yRatio : null;
  }

  if (itemTemplate.type === "SHAPE") {
    itemTemplate.textData = item.textData;

    itemTemplate.thumb = item.thumb || item.thumbnail;

    itemTemplate.pwidth = item.pwidth !== undefined ? item.pwidth : item.width;
    itemTemplate.pheight = item.pheight !== undefined ? item.pheight : item.height;

    itemTemplate.colors = item.colors;
    itemTemplate.defaultColor = item.defaultColor;

    itemTemplate.groupBounds = item.groupBounds;
    itemTemplate.pathData = item.pathData;
    itemTemplate.scaleType = item.scaleType;
    itemTemplate.isVertical = item.isVertical;
    itemTemplate.isHorizontal = item.isHorizontal;

    itemTemplate.xRatio = item.xRatio;
    itemTemplate.yRatio = item.yRatio;
    itemTemplate.widthRatio = item.widthRatio;
    itemTemplate.heightRatio = item.heightRatio;

    itemTemplate.minXRatio = item.minXRatio;
    itemTemplate.minYRatio = item.minYRatio;
    itemTemplate.maxWidthRatio = item.maxWidthRatio;
    itemTemplate.maxHeightRatio = item.maxHeightRatio;
  }

  if (itemTemplate.type === "FRAME") {
    itemTemplate.defaultWidth = item.defaultWidth;
    itemTemplate.defaultHeight = item.defaultHeight;
    itemTemplate.clipDetails = item.clipDetails;
    itemTemplate.d = item.d;
  }

  if (itemTemplate.type === "PROP") {
    itemTemplate.charId = item.charId;

    itemTemplate.pwidth = item.pwidth !== undefined ? item.pwidth : item.width;
    itemTemplate.pheight = item.pheight !== undefined ? item.pheight : item.height;

    itemTemplate.colors = item.colors;
    itemTemplate.defaultColor = item.defaultColor;

    itemTemplate.src = item.src;
    itemTemplate.thumb = item.thumb;
    itemTemplate.animsrc = item.animsrc;
    itemTemplate.animdata = item.animdata;

    itemTemplate.isAnimated = false && item.isAnimated; // remove false once vmaker supports animated props
    itemTemplate.isRepeated = item.isRepeated;
    itemTemplate.repeatLoop = item.repeatLoop;
  }

  return itemTemplate;
}

const applyZoomFactor = (item, zoomFactor = 1) => {
  item.x *= zoomFactor;
  item.y *= zoomFactor;
  item.width *= zoomFactor;
  item.height *= zoomFactor;

  if (item.type === "SHAPE" || item.type === "TEXT") {
    const { fontSize } = item.textData.formats.containerStyle;
    item.textData.formats.containerStyle.fontSize = `${parseFloat(fontSize) * zoomFactor}px`;
  }
}

export const projectMigrationHelper = (animakerProject, options = {}) => {
  const { objectTrackStart = 0, projectDimension = null } = options;

  let { width: projectWidth, height: projectHeight } = animakerProject;
  const animakerAudios = animakerProject.audios;
  const animakerScenes = animakerProject.scenes;
  const lastScene = animakerScenes[animakerScenes.length - 1];

  const originalProjectWidth = projectWidth;
  const originalProjectHeight = projectHeight;

  let zoomFactor = 1;
  if (projectDimension) {
    zoomFactor = projectDimension.width / projectWidth;
    projectWidth = projectDimension.width;
    projectHeight = projectDimension.height;
  }

  const vmakerTemplate = {
    project: {
      name: animakerProject.name,
      duration: lastScene ? lastScene.startTime + lastScene.duration : 10,
      width: projectWidth,
      height: projectHeight,
      bgColor: {
        colors: [
          "#ffffff"
        ]
      },
      workspaceItems: {},
      workspaceChildren: {},
      workspaceBG: {},
      audios: {},
    },
  };

  const scenesWithBG = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [audioId, audioData] of Object.entries(animakerAudios)) {
    vmakerTemplate.project.audios[audioId] = animakerToVmakerAudio(audioId, audioData);
  }

  for (let i = 0; i < animakerScenes.length; i += 1) {
    const scene = animakerScenes[i];
    const sceneStart = scene.startTime;

    if (
      scene.workspaceBG.src
      && !isCustomBG(scene.workspaceBG.type, scene.workspaceBG.subType)
    ) {
      scenesWithBG.push(i);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [itemId, itemData] of Object.entries(scene.workspaceItems)) {
      if (itemData.type === "GROUP") {
        const groupPlot = { x: itemData.x, y: itemData.y, width: itemData.width, height: itemData.height };
        const groupCenter = { x: groupPlot.x + groupPlot.width / 2, y: groupPlot.y + groupPlot.height / 2 };
        const groupAngle = itemData.angle;

        // eslint-disable-next-line no-restricted-syntax
        for (const childId of itemData.groupChildren) {
          const childData = scene.workspaceChildren[childId];

          const canProcessChild = childData && isVmakerSupportedType(childData);
          if (!canProcessChild) {
            // eslint-disable-next-line no-continue
            continue;
          }

          const childPlot = { x: childData.x, y: childData.y, width: childData.width, height: childData.height };
          const childCenter = { x: childPlot.x + childPlot.width / 2, y: childPlot.y + childPlot.height / 2 };
          const childAngle = childData.angle;

          const rotatedChildA = getRotatedPoint(
            groupCenter.x,
            groupCenter.y,
            childPlot.x,
            childPlot.y,
            groupAngle,
          );
          const rotatedChildCenter = getRotatedPoint(
            groupCenter.x,
            groupCenter.y,
            childCenter.x,
            childCenter.y,
            groupAngle,
          );
          const newChildA = getRotatedPoint(
            rotatedChildCenter.x,
            rotatedChildCenter.y,
            rotatedChildA.x,
            rotatedChildA.y,
            -groupAngle,
          );
          const newChildAngle = ((groupAngle + childAngle) % 360 + 360) % 360;

          const child = animakerToVmakerItem({
            id: childId,
            item: childData,
            sceneStart,
          });

          child.angle = newChildAngle;
          child.x = newChildA.x;
          child.y = newChildA.y;

          applyZoomFactor(child, zoomFactor);
          vmakerTemplate.project.workspaceItems[child.id] = child;
        }
      } else if (isVmakerSupportedType(itemData)) {
        const item = animakerToVmakerItem({
          id: itemId,
          item: itemData,
          sceneStart,
        });

        applyZoomFactor(item, zoomFactor);
        vmakerTemplate.project.workspaceItems[item.id] = item;
      }
    }
  }

  let bgTrackOffset = 0;
  if (scenesWithBG.length) {
    bgTrackOffset += 1;
  }

  handleTimeOverlap("workspaceItems", vmakerTemplate.project.workspaceItems, objectTrackStart + bgTrackOffset);
  handleTimeOverlap("audios", vmakerTemplate.project.audios);

  for (let i = 0; i < scenesWithBG.length; i += 1) {
    const scene = animakerScenes[scenesWithBG[i]];
    const sceneStart = scene.startTime;
    const sceneDuration = scene.duration;
    const sceneId = scene.id || randomString();

    const bgItem = animakerToVideoBG({
      bgItem: scene.workspaceBG,
      id: sceneId,
      projectHeight: originalProjectHeight,
      projectWidth: originalProjectWidth,
      sceneDuration,
      sceneStart,
    });

    bgItem.track += objectTrackStart;
    applyZoomFactor(bgItem, zoomFactor);
    vmakerTemplate.project.workspaceItems[bgItem.id] = bgItem;
  }

  return vmakerTemplate.project;
}
