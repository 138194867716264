import React from "react";
import styled from "styled-components";
import { STATIC_PATH } from "../constants/config";

const SearchSection = styled.div`
  float: right;
  position: relative;
  input {
    max-width: 100%;
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 4px;
    padding: 10px 10px 10px 40px;
    outline: none;
    margin-right: 24px;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    transition: transform 0.5s ease 0s;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  &:focus {
    outline: 0px;
    box-shadow: none;
  }
`;

export const Icon = styled.img``;

const SearchInputBox = () => {
  return (
    <SearchSection>
      <Icon src={`${STATIC_PATH}search.svg`} />
      <SearchInput type="text" placeholder="Search" />
    </SearchSection>
  );
};
export default SearchInputBox;
