/* eslint-disable camelcase, react/sort-comp */

import { fromJS } from "immutable";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ITEM_CONFIG } from "../../constants/config";
import {
  isAudioOnly,
  isImageOnly,
  isUpImageSVG,
  isVideoOnly,
} from "../timeline/timeline-helper";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import { applyCropToDropItem } from "../../helper/TransformManagerHelper";
import { updateSwapHoverDrop } from "../../redux/actions/appUtils";

const SwapDropAreaStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  pointer-events: auto;

  *, ~ * {
    pointer-events: none;
  }

  &.hide-original-item ~ * {
    opacity: 0;
  }

  img {
    position: absolute;
    display: block;
  }
`;
SwapDropAreaStyled.displayName = "SwapDropAreaStyled";

class SwapDropAreaComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      previewDetails: null,
    };

    this.swapAreaRef = React.createRef(null);

    this.shouldUpdatePreview = this.shouldUpdatePreview.bind(this);
    this.updatePreview = this.updatePreview.bind(this);
    this.canDropOnWorkspace = this.canDropOnWorkspace.bind(this);

    this.handleWorkspaceMouseEnter = this.handleWorkspaceMouseEnter.bind(this);
    this.handleItemMouseEnter = this.handleItemMouseEnter.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);

    this.handleWorkspaceMouseLeave = this.handleWorkspaceMouseLeave.bind(this);
    this.handleItemMouseLeave = this.handleItemMouseLeave.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentDidMount() {
    const swapAreaEl = this.swapAreaRef.current;
    if (swapAreaEl) {
      swapAreaEl.addEventListener("touch-swap-enter", this.handleMouseEnter);
      swapAreaEl.addEventListener("touch-swap-leave", this.handleMouseLeave);
    }

    this.updatePreview();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldUpdatePreview(prevProps, prevState)) {
      this.updatePreview();
    }
  }

  componentWillUnmount() {
    const swapAreaEl = this.swapAreaRef.current;
    if (swapAreaEl) {
      swapAreaEl.removeEventListener("touch-swap-enter", this.handleMouseEnter);
      swapAreaEl.removeEventListener("touch-swap-leave", this.handleMouseLeave);
    }
  }

  shouldUpdatePreview(prevProps, prevState) {
    const currentLibItem = this.props.libraryDragItem.get("data");
    const prevLibItem = prevProps.libraryDragItem.get("data");
    return (
      this.state.isHovered !== prevState.isHovered ||
      currentLibItem !== prevLibItem ||
      this.props.item !== prevProps.item ||
      this.props.type !== prevProps.type
    );
  }

  updatePreview() {
    const {
      type = ITEM_CONFIG.SWAP_TARGETS.WORKSPACE,
      libraryDragItem,
      item,
    } = this.props;
    const { isHovered, previewDetails } = this.state;
    const libItem = libraryDragItem.get("data");

    if (type !== ITEM_CONFIG.SWAP_TARGETS.WORKSPACE) {
      if (previewDetails !== null) {
        this.setState({ previewDetails: null });
      }
      return;
    }

    if (
      isHovered &&
      libItem &&
      item &&
      isImageOnly(item.get("type"), item.get("subType")) &&
      !isUpImageSVG(item) &&
      isImageOnly(libItem.get("type"), libItem.get("subType")) &&
      !isUpImageSVG(libItem)
    ) {
      const { thumb } = getWorkspaceItemSource({
        item: {
          type: libItem.get("type"),
          subType: libItem.get("subType"),
          thumb: libItem.get("thumb"),
          thumbnail: libItem.get("thumbnail"),
        },
      });
      const dropItem = {
        libAssetWidth: libItem.get("width"),
        libAssetHeight: libItem.get("height"),
      };
      const targetPlot = {
        x: item.get("x"),
        y: item.get("y"),
        width: item.get("width"),
        height: item.get("height"),
      };
      const { drop_item_original, item_plot } = applyCropToDropItem(
        dropItem,
        targetPlot
      );

      this.setState({
        previewDetails: fromJS({
          src: thumb,
          original: drop_item_original,
          width: item_plot.w,
          height: item_plot.h,
        }),
      });
    } else if (previewDetails) {
      this.setState({ previewDetails: null });
    }
  }

  canDropOnWorkspace(item) {
    const type = item.get("type");
    const subType = item.get("subType");

    return (
      isImageOnly(type, subType) ||
      isVideoOnly(type, subType) ||
      isAudioOnly(type, subType) ||
      type === "TEXT" ||
      type === "GROUP" ||
      type === "SHAPE" ||
      type === "PROP" ||
      type === "FRAME" 
    );
  }

  handleWorkspaceMouseEnter() {
    const { libraryDragItem } = this.props;
    if (
      libraryDragItem.get("data") &&
      this.canDropOnWorkspace(libraryDragItem.get("data"))
    ) {
      this.props.updateSwapHoverDrop({
        id: ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER,
        hovering: true,
        sourceType: ITEM_CONFIG.SWAP_SOURCE.LIBRARY,
        targetType: ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER,
      });
    }
  }

  handleTransitionMouseEnter() {
    const { libraryDragItem } = this.props;
    if (
      libraryDragItem.getIn(["data", "type"]) === "TRANSITIONS"
    ) {
      this.props.updateSwapHoverDrop({
        id: ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS,
        hovering: true,
        sourceType: ITEM_CONFIG.SWAP_SOURCE.LIBRARY,
        targetType: ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS,
        transitionLayer: fromJS({
          prevItem: this.props.prevItem,
          nextItem: this.props.nextItem
        })
      });
    }
  }

  handleItemMouseEnter() {
    const { item, libraryDragItem } = this.props;
    if (item && libraryDragItem.get("data")) {
      this.props.updateSwapHoverDrop({
        id: item.get("id"),
        hovering: true,
        sourceType: ITEM_CONFIG.SWAP_SOURCE.LIBRARY,
        targetType: ITEM_CONFIG.SWAP_TARGETS.WORKSPACE,
      });
      this.setState({ isHovered: true });
    }
  }

  handleMouseEnter(event) {
    const { type } = this.props;
    if (type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE) {
      this.handleItemMouseEnter(event);
    } else if (type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER) {
      this.handleWorkspaceMouseEnter(event);
    } else if (type === ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS) {
      this.handleTransitionMouseEnter(event);
    }
  }

  handleWorkspaceMouseLeave() {
    const { swapHoverDrop } = this.props;
    if (
      swapHoverDrop.get("hovering") &&
      swapHoverDrop.get("targetType") ===
        ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER
    ) {
      this.props.updateSwapHoverDrop({
        id: ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER,
        hovering: false,
      });
    }
  }

  handleItemMouseLeave() {
    const { item, swapHoverDrop } = this.props;

    if (item.get("id") === swapHoverDrop.get("id")) {
      this.props.updateSwapHoverDrop({
        id: item.get("id"),
        hovering: false,
        targetType: ITEM_CONFIG.SWAP_TARGETS.WORKSPACE,
        sourceType: ITEM_CONFIG.SWAP_SOURCE.LIBRARY,
      });
    }
    this.setState({ isHovered: false, previewDetails: null });
  }

  handleTransitionMouseLeave() {
    const { swapHoverDrop } = this.props;
    if (
      swapHoverDrop.get("hovering") &&
      swapHoverDrop.get("targetType") ===
        ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS
    ) {
      this.props.updateSwapHoverDrop({
        id: ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS,
        hovering: false,
      });
    }
  }

  handleMouseLeave(event) {
    const { type } = this.props;
    if (type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE) {
      this.handleItemMouseLeave(event);
    } else if (type === ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS) {
      this.handleTransitionMouseLeave(event);
    } else if (type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER) {
      this.handleWorkspaceMouseLeave(event);
    }
  }

  render() {
    const { item, type, zoomFactor } = this.props;
    const { isHovered, previewDetails } = this.state;
    let preview = null;

    if (previewDetails !== null) {
      const previewStyles = {
        left: `${
          previewDetails.getIn(["original", "x"]) *
          item.get("width") *
          zoomFactor
        }px`,
        top: `${
          previewDetails.getIn(["original", "y"]) *
          item.get("height") *
          zoomFactor
        }px`,
        width: `${
          previewDetails.getIn(["original", "width"]) *
          item.get("width") *
          zoomFactor
        }px`,
        height: `${
          previewDetails.getIn(["original", "height"]) *
          item.get("height") *
          zoomFactor
        }px`,
      };
      preview = (
        <img
          src={previewDetails.get("src")}
          alt="preview detail"
          style={previewStyles}
        />
      );
    }

    const hideOriginalItem =
      type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE &&
      preview !== null &&
      isHovered;

    let swapareaCls = "";
    if (hideOriginalItem) {
      swapareaCls = `${swapareaCls} hide-original-item`;
    }

    let swapTkn = ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER;
    if (item && type === ITEM_CONFIG.SWAP_TARGETS.WORKSPACE) {
      swapTkn = `${ITEM_CONFIG.SWAP_TARGETS.WORKSPACE}-${item.get("id")}`;
    }

    return (
      <SwapDropAreaStyled
        ref={this.swapAreaRef}
        className={swapareaCls}
        onPointerEnter={this.handleMouseEnter}
        onPointerLeave={this.handleMouseLeave}
        data-tswap-tkn={swapTkn}
      >
        {preview}
      </SwapDropAreaStyled>
    );
  }
}

SwapDropAreaComponent.propTypes = {
  type: PropTypes.oneOf([
    ITEM_CONFIG.SWAP_TARGETS.WORKSPACE,
    ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER,
    ITEM_CONFIG.SWAP_TARGETS.TRANSITIONS,
  ]),
  libraryDragItem: PropTypes.object.isRequired,
  item: PropTypes.object,
  zoomFactor: PropTypes.number,
  updateSwapHoverDrop: PropTypes.func,
  swapHoverDrop: PropTypes.object,
  prevItem: PropTypes.string,
  nextItem: PropTypes.string,

};

const mapStateToProps = (state) => ({
  swapHoverDrop: state.app.get("swapHoverDrop"),
  libraryDragItem: state.app.get("libraryDragItem"),
  zoomFactor: state.app.get("zoomFactor"),
});

const mapDispatchToProps = (dispatch) => ({
  updateSwapHoverDrop: (data) => dispatch(updateSwapHoverDrop(data)),
});

const SwapDropArea = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwapDropAreaComponent);

export default SwapDropArea;
