import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  setEnterEffectName,
  setExitEffectName,
} from "../../../../redux/actions/appUtils";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import useNotify from "../../../../helper/hooks/useNotify";
import ApplyAllModal from "../../../../common-components/ApplyAllModal";
import content from "../../../../constants/content";
import { font } from "../../../../constants/font";

const AnimationApplyAll = ({
  isOpen,
  onClose,
  modalData,
  successMessage,
  itemType,
}) => {
  const dispatch = useDispatch();
  const { success } = useNotify();

  const selectedItem = useSelector((state) => {
    return state.app.get("selectedItems");
  });

  const projectDetails = useSelector((state) => {
    return state.projectDetails;
  });

  const enterEffect = projectDetails.getIn([
    "workspaceItems",
    selectedItem.get(0),
    "enterEffectName",
  ]);

  const exitEffect = projectDetails.getIn([
    "workspaceItems",
    selectedItem.get(0),
    "exitEffectName",
  ]);

  const workspace = projectDetails.get("workspaceItems");

  const onSave = () => {
    if (workspace.size > 0) {
      workspace.forEach((value, key) => {
        if (value.get("type") === itemType && key !== selectedItem.get(0)) {
          const enterStart = projectDetails.getIn([
            "workspaceItems",
            key,
            "enterStart",
          ]);
          const exitEnd = projectDetails.getIn([
            "workspaceItems",
            key,
            "exitEnd",
          ]);

          dispatch(
            setEnterEffectName({
              effectName: enterEffect,
              item: key,
              enterEnd: enterStart + 1,
            })
          );

          dispatch(
            updateTimelineTime({
              toUpdate: [
                {
                  container: "workspaceItems",
                  id: key,
                  toUpdate: {
                    enterEffectName: enterEffect,
                    enterEnd: enterStart + 1,
                  },
                },
              ],
            })
          );

          dispatch(
            setExitEffectName({
              effectName: exitEffect,
              item: key,
              exitStart: exitEnd - 1,
            })
          );

          dispatch(
            updateTimelineTime({
              toUpdate: [
                {
                  container: "workspaceItems",
                  id: key,
                  toUpdate: {
                    exitEffectName: exitEffect,
                    exitStart: exitEnd - 1,
                  },
                },
              ],
            })
          );
        }
      });
      onClose();
      success(successMessage);
    }
  };

  return (
    <ApplyAllModal
      isOpen={isOpen}
      onClose={onClose}
      modalData={modalData}
      onSave={onSave}
      cancelText={content.CANCEL}
      confirmText={content.APPLY_ALL}
      textFont={font.mediumLarge_27}
    />
  );
};

AnimationApplyAll.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  modalData: PropTypes.string,
  successMessage: PropTypes.string,
  itemType: PropTypes.string,
};

export default AnimationApplyAll;
