import PropTypes from "prop-types";
import { SLIDER_TYPES, TRACK_TYPES } from "./timeline-constants";

export const PlotType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

export const StepperType = PropTypes.shape({
  steps: PropTypes.number.isRequired,
  stepSizePx: PropTypes.number.isRequired,
  timeScale: PropTypes.number.isRequired,
  excessDuration: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  videoExcessSteps: PropTypes.number.isRequired,
  displayDuration: PropTypes.number.isRequired,
});

export const ThumbPositionType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  trackType: PropTypes.oneOf(Object.values(TRACK_TYPES)).isRequired,
  step: PropTypes.number.isRequired,
  thumbId: PropTypes.string.isRequired,
  sliderId: PropTypes.string.isRequired,
});

export const ThumbType = PropTypes.shape({
  position: ThumbPositionType.isRequired,
  trackPosition: ThumbPositionType,
  dragPosition: ThumbPositionType,
  isDragging: PropTypes.bool.isRequired,
  sliderId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  min: ThumbPositionType,
  max: ThumbPositionType,
  mediaMin: ThumbPositionType,
  mediaMax: ThumbPositionType,
});

export const SliderType = PropTypes.shape({
  enterStart: ThumbType.isRequired,
  exitEnd: ThumbType,
  enterEnd: ThumbType,
  exitStart: ThumbType,
  rulerEnd: ThumbType,
  sliderType: PropTypes.oneOf(Object.values(SLIDER_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  itemSubType: PropTypes.string.isRequired,
  useRulerMinMax: PropTypes.bool,
  toNewTrackAbove: PropTypes.bool.isRequired,
  toNewTrackBelow: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isDraggable: PropTypes.bool.isRequired,
  isOverlapping: PropTypes.bool,
  gapSliderDetail: PropTypes.shape({
    leftThumbPosition: ThumbPositionType.isRequired,
    rightThumbPosition: ThumbPositionType.isRequired,
  }),
});

export const SelectedSliderType = PropTypes.shape({
  sliderId: PropTypes.string,
  thumbId: PropTypes.string,
});

export const SliderColorType = PropTypes.shape({
  bg: PropTypes.string,
  border: PropTypes.string,
  outerThumbs: PropTypes.string,
  innerThumbs: PropTypes.string,
  text: PropTypes.string,
  audioWave: PropTypes.string,
  audioWaveBg: PropTypes.string,
});

export const SnapPointType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  trackType: PropTypes.oneOf(Object.values(TRACK_TYPES)).isRequired,
  step: PropTypes.number.isRequired,
  thumbId: PropTypes.string.isRequired,
  sliderId: PropTypes.string.isRequired,
  snapPointId: PropTypes.string.isRequired,
});

export const MatchingSnapPointType = PropTypes.shape({
  snapPoint: SnapPointType.isRequired,
  aligningSnapPoints: PropTypes.arrayOf(SnapPointType),
  sliderId: PropTypes.string.isRequired,
  thumbId: PropTypes.string.isRequired,
});
