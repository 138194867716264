import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { STATIC_PATH } from "../../../constants/config";
import { ProgressBar } from "../../../common-components/MusicTile";
import { getAudioSource } from "../../../helper/URLHelper";
import vmTheme from "../../../constants/theme";
import { addCacheClearQuery } from "../../../helper/addCacheClearQuery";

const Icon = styled.img`
  height: 30px;
  width: 30px;
  margin-left: 8px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
`;

export const IconSVG = styled.div`
  svg {
    height: 16px;
    width: 16px;
    margin-left: 8px;
    position: absolute;
    top: 9px;
    path {
      fill: ${(props) => props.theme.svgIconPurple};
    }
    rect {
      fill: ${(props) => props.theme.svgIconPurple};
    }
  }
`;

const AudioPlayer = ({ sliderId, isPlay, isMute, volume, onAudioStop }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [duration, setDuration] = useState(0);
  const [source, setSource] = useState("");
  const audioRef = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const audioData = useSelector((store) =>
    store.projectDetails.getIn(["audios", sliderId])
  );

  const playHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = audioData.get("musicStart");
      setPercentage(0);
      onAudioStop();
    } else {
      audioRef.current.currentTime = audioData.get("musicStart");
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audioData) {
      setDuration(audioData.get("musicEnd") - audioData.get("musicStart"));
      const { src } = getAudioSource({
        item: {
          type: audioData.get("type"),
          subType: audioData.get("subType"),
          src: audioData.get("src"),
        },
      });
      setSource(src);
      audioRef.current.volume = volume;
    }
    if (isPlay) {
      playHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioData, isPlay, isMute, volume]);

  /**
   * Event to get the current time of the audio
   * @param {Event} e
   */
  const timeUpdateHandler = (e) => {
    if (e.target.currentTime) {
      setPercentage(
        ((e.target.currentTime - audioData.get("musicStart")) /
          parseFloat(duration)) *
          100
      );
    }
    if (
      percentage >= 100 ||
      duration.toFixed(1) === audioRef.current.currentTime.toFixed(1)
    ) {
      playHandler();
    }
  };

  return (
    <>
      {isMute ? (
        <IconSVG>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_187907"
                  data-name="Rectangle 187907"
                  width="20"
                  height="20"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="volume_full_player_white" transform="translate(-266 -504)">
              <rect
                id="Rectangle_17903"
                data-name="Rectangle 17903"
                width="24"
                height="24"
                transform="translate(266 504)"
                fill="#fff"
                opacity="0"
              />
              <g
                id="Volume-01"
                transform="translate(268 506)"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Subtraction_16"
                  data-name="Subtraction 16"
                  d="M8.771,15.611a1.039,1.039,0,0,1-.743-.315L4.605,11.884l5.21-5.21v7.871a1.083,1.083,0,0,1-.659.988A1.129,1.129,0,0,1,8.771,15.611ZM13.158,14a.624.624,0,0,1-.391-1.112l.015-.014c.014-.011.036-.029.066-.056.088-.079.17-.159.251-.243a6.945,6.945,0,0,0,.787-.987,6.973,6.973,0,0,0,0-7.667,7.128,7.128,0,0,0-.565-.75l.886-.886a8.111,8.111,0,0,1,.724.95,8.224,8.224,0,0,1,0,9.041A8.089,8.089,0,0,1,14,13.442c-.142.146-.25.247-.316.306-.04.035-.071.063-.095.082l-.04.034A.63.63,0,0,1,13.158,14Zm-1.292-2.525a.543.543,0,0,1-.446-.208.626.626,0,0,1,.094-.875l.013-.013.011-.01c.026-.025.061-.059.105-.107A2.844,2.844,0,0,0,12,9.785a3.874,3.874,0,0,0,.535-2.036A3.865,3.865,0,0,0,12,5.714a2.856,2.856,0,0,0-.354-.475c-.052-.056-.089-.09-.106-.105a.165.165,0,0,0-.023-.021.705.705,0,0,1-.072-.068l.884-.884.023.021.032.03c.05.045.113.108.177.177a4.234,4.234,0,0,1,.507.675,5.135,5.135,0,0,1,.716,2.685,5.135,5.135,0,0,1-.716,2.685,4.255,4.255,0,0,1-.5.677c-.068.072-.129.133-.178.177-.02.02-.041.038-.061.055l-.013.011-.012.009A1.009,1.009,0,0,1,11.867,11.474Zm-8.454-.165H1.065A1.066,1.066,0,0,1,0,10.244v-4.9A1.06,1.06,0,0,1,1.065,4.3H4.028L7.994.316A1.079,1.079,0,0,1,8.754,0,1.059,1.059,0,0,1,9.815,1.067v3.84l-6.4,6.4Zm9.123-9.123v0a.624.624,0,0,1,.622-.685q.031,0,.062,0l-.682.682Z"
                  transform="translate(2.469 2.25)"
                  fill="#4d4b5c"
                />
                <rect
                  id="Rectangle_187906"
                  data-name="Rectangle 187906"
                  width="1.25"
                  height="22.5"
                  rx="0.625"
                  transform="translate(17.132 0.629) rotate(45)"
                  fill="#4d4b5c"
                />
              </g>
            </g>
          </svg>
        </IconSVG>
      ) : isPlaying ? (
        <Icon
          draggable="false"
          src={`${STATIC_PATH}stop-audio.svg`}
          alt="play-audio"
          onClick={playHandler}
        />
      ) : (
        <Icon
          draggable="false"
          src={`${STATIC_PATH}audio-play.svg`}
          alt="play-audio"
          onClick={playHandler}
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        src={addCacheClearQuery(source)}
        onTimeUpdate={timeUpdateHandler}
        muted={isMute}
      />
      {isPlaying && (
        <ProgressBar
          className="progress"
          width={`${percentage}%`}
          opacity={1}
          color={vmTheme[theme].rgbaPurpleColor}
          borderRight={`2px solid ${vmTheme[theme].svgIconPurple}`}
        />
      )}
    </>
  );
};

AudioPlayer.propTypes = {
  sliderId: PropTypes.string,
  isPlay: PropTypes.bool,
  isMute: PropTypes.bool,
  volume: PropTypes.number,
  onAudioStop: PropTypes.func,
};
export default AudioPlayer;
