/* eslint-disable prefer-template, operator-assignment, react/sort-comp, prefer-const, react/no-array-index-key, one-var, max-len */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { getToolBarStyle } from "./itemtoolbar-helper";
import { IconSetStyled, ItemToolBarStyled, MoreOptionsContainer , CloseItemToolBar } from "./itemtoolbar-components";
import { duplicateItemHelper, getPanelName, isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getSelectionContainer } from "../../helper/IndividualSelectHelper";
import { DEFAULT_SELECTED_FRAME_CLIP, prepareDefaultImgDetails } from "../frame/frame-helper";
import ITEM_TOOLBAR_ICONS_MAP from "./itemToolBarIcons";
import { isMouseOn } from "../../helper/TransformManagerHelper";
import { getTextColors, isAllTextSelected, isTextSelected } from "../text/TextHelper";
import { ItemToolColorOption, ItemToolOption } from "./itemtoolbar-options";
import { resetSwap, selectFrameClip, setExpandPanel, setPropertyPanel, setPropertyWindow, setSwapInfo, setSwapItem, toggleCrop, updateTextStatus } from "../../redux/actions/appUtils";
import { addObject, addReplaceObject, updateTimelineTime } from "../../redux/actions/timelineUtils";
import { ArrangePopup, LayerPopup, RadiusPopup, TransformPopup, TransparencyPopup } from "./itemtoolbar-popups";
import content from "../../constants/content";
import { font } from "../../constants/font";
import Typography from "../../common-components/Typography";
import { ItemToolConfirmation } from "./itemtoolbar-confirmation";
import { STATIC_PATH } from "../../constants/config";
import vmTheme from "../../constants/theme";
import uuid from "../../helper/uuid";
import { addNotification } from "../../redux/actions/notificationUtils";
import withNotify from "../../helper/hoc/withNotify";

const {
    COLOR
  } = content;


class ItemToolBarComponent extends React.Component {
    static GROUP_COLOR_TYPES = ["TEXT", "SHAPE", "PROP"];

    constructor(props) {
        super(props);

        // eslint-disable-next-line no-unused-vars
        const { isMobile } = this.props;

        this.INITIAL_POPUP_STATE = Object.freeze({
            radius: false,
            transform: false,
            transparency: false,
            layer: false,
            expand: false,
            align: false,
        });
        this.state = {
            iconType: "",
            iconContainer: "",
            icons: fromJS({
                setOne: [],
                setTwo: [],
            }),
            removeCount: 0,
            setOneRemoveCount: 0,
            setTwoRemoveCount: 0,
            popupToggleState: this.INITIAL_POPUP_STATE,
            textSelectedIn: fromJS({}),
            animateToolbar: false,
        }

        this.handleShortcuts = this.handleShortcuts.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleMoreOptionsListing = this.handleMoreOptionsListing.bind(this);
        this.isPopupStateDifferent = this.isPopupStateDifferent.bind(this);
        this.isAnyPopupOpen = this.isAnyPopupOpen.bind(this);
        this.closeAllPopups = this.closeAllPopups.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.shouldClosePopup = this.shouldClosePopup.bind(this);
        this.handlePopupOutsideMouseDown = this.handlePopupOutsideMouseDown.bind(this);
        this.handlePopupOutsideClick = this.handlePopupOutsideClick.bind(this);
        this.isSelectedItemIsText = this.isSelectedItemIsText.bind(this);
        this.toggleTextColorPalette = this.toggleTextColorPalette.bind(this);
        this.checkTextSelected = this.checkTextSelected.bind(this);
        this.checkIconIsDisabled = this.checkIconIsDisabled.bind(this);
        this.checkIconIsActive = this.checkIconIsActive.bind(this);
        this.getGroupColors = this.getGroupColors.bind(this);
        this.getSelectedTextColor = this.getSelectedTextColor.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.canShowToolBar = this.canShowToolBar.bind(this);
        this.CloseItemToolBar = this.CloseItemToolBar.bind(this);
        this.applyHandler = this.applyHandler.bind(this);
        this.cancelHandler = this.cancelHandler.bind(this);
        this.onToolbarTransitionEnd = this.onToolbarTransitionEnd.bind(this);

        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.itemToolBarRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.setOneOptionsRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.setTwoOptionsRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.setOneMoreOptionsRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.setTwoMoreOptionsRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.moreOptionsRef = React.createRef(null);

        this.MIN_OPTION_IN_SET_TWO = 1; // keep atleast one icon on set two
        this.MIN_OPTION_IN_SET_ONE = 1; // keep atleast one icon on set one
    }

    static getDerivedStateFromProps(props, state) {
        let toReturn = null;

        if (
            props.selectedItems !== state.selectedItems
            || props.childrenSelection !== state.childrenSelection
            || props.selectedAudios !== state.selectedAudios
            || props.selectedSubtitles !== state.selectedSubtitles
            || props.propertyWindow !== state.propertyWindow
            || props.textStatus !== state.textStatus
            || !props.transformStatus.get("transfroming")
        ) {
            toReturn = {
                selectedItems: props.selectedItems,
                childrenSelection: props.childrenSelection,
                propertyWindow: props.propertyWindow,
                textStatus: props.textStatus,
                transformStatus: props.transformStatus,
                selectedAudios: props.selectedAudios,
                selectedSubtitles: props.selectedSubtitles,
                textSelectedIn: fromJS({}),
                icons: fromJS({
                    setOne: [],
                    setTwo: [],
                }),
                iconType: "",
                iconContainer: "",
            };

            let iconType = "";
            let iconContainer = "";
            let iconsToRemove = [];

            if (!props.selectedItems.size && !props.selectedAudios.size && !props.selectedSubtitles.size) {
                iconType = "PROJECT";
                iconContainer = "project";
            } else if (props.selectedSubtitles.size > 0) {
                iconType = "SUBTITLE";
                iconContainer = "subtitle";
            } else if (props.selectedItems.size > 0 && props.selectedAudios.size > 0) {
                iconType = "MULTI_SELECT";
                iconContainer = "mixed";
            } else if (props.selectedItems.size > 1) {
                iconType = "MULTI_SELECT";
                iconContainer = "workspaceItems";
            } else if (props.selectedAudios.size > 1) {
                iconType = "MULTI_SELECT";
                iconContainer = "audios";
            } else if (props.selectedAudios.size === 1) {
                iconType = "AUDIO";
                iconContainer = "audios";
            } else if (props.selectedObjects.size === 1) {
                let selectedItem = props.selectedObjects.first();
                if (selectedItem.get("type") === "GROUP") {
                    const isGroupTextSelected = (props.textStatus.get('type') === 'TEXT' || props.textStatus.get('type') === 'SHAPE') && props.textStatus.get('isFocused') === true && props.textStatus.get('container') === 'workspaceChildren';
                    let isChildSelected = props.selectionContainer === "workspaceChildren" && (props.selectedChildrenObjects.size === 1 || isGroupTextSelected);

                    if (isChildSelected && !isGroupTextSelected) {
                        let selectedChild = props.selectedChildrenObjects.first();
                        let selectedChildId = props.selectedChildrenObjects.keySeq().first();

                        iconType = selectedChild.get("type");
                        iconContainer = "workspaceChildren";

                        if (
                            isImageOnly(selectedChild.get("type"), selectedChild.get("subType"))
                            || isVideoOnly(selectedChild.get("type"), selectedChild.get("subType"))
                        ) {
                            iconType = "VISUAL_MEDIA";
                            if (ItemToolBarComponent.isUpImageSVG(selectedChild)) {
                                iconType = "UPIMAGE_SVG";
                            }
                        } else if (selectedChild.get("type") === "FRAME") {
                            if (props.selectedFrameClip.get("clipId")) {
                                iconType = "FRAME_EMPTY_CLIP";
                                let imgDetails = selectedChild.getIn(["clipDetails", props.selectedFrameClip.get("clipId"), "imgDetails"]);
                                if (
                                    isImageOnly(imgDetails.get("type"), imgDetails.get("subType"))
                                    || isVideoOnly(imgDetails.get("type"), imgDetails.get("subType"))
                                ) {
                                    iconType = "FRAME_CLIP_VISUAL_MEDIA";
                                }
                            } else if (props.selectedFrameClip.get("clipId") === DEFAULT_SELECTED_FRAME_CLIP.get("clipId")) {
                                iconType = "FRAME";
                                if (selectedChild.get("d").size === 0) {
                                    iconsToRemove.push("color");
                                }
                            }
                        } else if (selectedChild.get("type") === "PROP") {
                            iconsToRemove = [];
                            let propHasAnimation = (
                                selectedChild.get("animsrc") !== undefined
                                && selectedChild.get("animsrc") !== ""
                            );
                            if (!propHasAnimation) {
                                iconsToRemove.push("objectAction");
                            }
                        } else if (selectedChild.get("type") === "TEXT") {
                            toReturn.textSelectedIn = fromJS({
                                type: selectedChild.get("type"),
                                id: selectedChildId,
                                rootType: "TEXT"
                            });
                        }
                    } else if (isChildSelected && isGroupTextSelected) {
                        let selectedChild = props.selectedChildrenObjects.first();

                        iconType = selectedChild.get("type");
                        iconContainer = "workspaceChildren";

                        toReturn.textSelectedIn = fromJS({
                            type: props.textStatus.get("type"),
                            id: props.textStatus.get("id"),
                            textFocused: true,
                            rootType: "TEXT"
                        });
                    } else {
                        iconsToRemove = [];
                        iconType = "GROUP";
                        iconContainer = "workspaceItems";

                        let groupHasColors = props.workspaceChildren
                            .filter((_, key) => selectedItem.get("groupChildren").includes(key))
                            .some(v => ItemToolBarComponent.GROUP_COLOR_TYPES.includes(v.get("type")));
                        if (!groupHasColors) {
                            iconsToRemove.push("color")
                        }
                    }
                } else if (selectedItem.get("type") === "FRAME") {
                    iconContainer = "workspaceItems";

                    if (props.selectedFrameClip.get("clipId")) {
                        iconType = "FRAME_EMPTY_CLIP";
                        let imgDetails = selectedItem.getIn(["clipDetails", props.selectedFrameClip.get("clipId"), "imgDetails"]);
                        if (isImageOnly(imgDetails.get("type"), imgDetails.get("subType")) || isVideoOnly(imgDetails.get("type"), imgDetails.get("subType"))) {
                            iconType = "FRAME_CLIP_VISUAL_MEDIA";
                        }
                    } else if (props.selectedFrameClip.get("clipId") === DEFAULT_SELECTED_FRAME_CLIP.get("clipId")) {
                        iconType = "FRAME";
                        if (selectedItem.get("d").size === 0) {
                            iconsToRemove.push("color");
                        }
                    }
                } else if (selectedItem.get("type") === "TEXT") {
                    iconType = "TEXT";
                    iconContainer = "workspaceItems";

                    toReturn.textSelectedIn = fromJS({
                        rootType: "TEXT"
                    });
                } else if (selectedItem.get("type") === "PROP") {
                    iconType = "PROP";
                    iconContainer = "workspaceItems";

                    iconsToRemove = [];
                    let propHasAnimation = (
                        selectedItem.get("animsrc") !== undefined
                        && selectedItem.get("animsrc") !== ""
                    );
                    if (!propHasAnimation) {
                        iconsToRemove.push("objectAction");
                    }
                } else if (selectedItem.get("type") === "SHAPE") {
                    iconType = "SHAPE";
                    iconContainer = "workspaceItems";

                    if (props.textStatus.get('type') === "SHAPE" && props.textStatus.get("isFocused") === true) {
                        toReturn.textSelectedIn = fromJS({
                            type: props.textStatus.get("type"),
                            id: props.textStatus.get("id"),
                            textFocused: true,
                            rootType: "TEXT"
                        });
                    }
                } else if (
                    isImageOnly(selectedItem.get("type"), selectedItem.get("subType"))
                    || isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))
                ) {
                    iconContainer = "workspaceItems";
                    if (ItemToolBarComponent.isUpImageSVG(selectedItem)) {
                        iconType = "UPIMAGE_SVG";
                    } else {
                        iconType = "VISUAL_MEDIA";
                    }
                }
            }

            if (
                ITEM_TOOLBAR_ICONS_MAP[iconType]
                && ITEM_TOOLBAR_ICONS_MAP[iconType][iconContainer]
            ) {
                let icons = fromJS(ITEM_TOOLBAR_ICONS_MAP[iconType][iconContainer]);
                icons = icons.map((iconSet) => {
                    let actionList = iconSet.get("actionList");
                    if (iconsToRemove.length > 0) {
                        actionList = actionList.filter((icon) => !iconsToRemove.includes(icon))
                    }
                    return actionList;
                });

                toReturn.icons = icons;
                toReturn.iconType = iconType;
                toReturn.iconContainer = iconContainer;
            }
        }

        return toReturn;
    }

    componentDidMount() {
        if (!window.ResizeObserver) return;
        this.resizeObserver = new window.ResizeObserver(() => {
            this.handleMoreOptionsListing();
        });
        window.addEventListener("resize", this.handleWindowResize);
        this.resizeObserver.observe(this.itemToolBarRef.current);
    }

    componentDidUpdate(prevProps, prevState) {
        const itemToolBarEl = this.itemToolBarRef.current;

        if (
            (this.props.selectedItems !== prevProps.selectedItems && !this.props.selectedItems.equals(prevProps.selectedItems))
            || (this.props.selectedAudios !== prevProps.selectedAudios && !this.props.selectedAudios.equals(prevProps.selectedAudios))
            || (
                this.props.childrenSelection.get("childIds") !== prevProps.childrenSelection.get("childIds")
                && !this.props.childrenSelection.get("childIds").equals(prevProps.childrenSelection.get("childIds"))
            )
        ) {
            this.closeAllPopups();
        }

        if (this.isPopupStateDifferent(this.state, prevState)) {
            if (this.isAnyPopupOpen(false)) {
                window.addEventListener("pointerdown", this.handlePopupOutsideMouseDown);
                window.addEventListener("click", this.handlePopupOutsideClick);
                window.addEventListener("contextmenu", this.handleContextMenu);
            } else {
                window.removeEventListener("pointerdown", this.handlePopupOutsideMouseDown);
                window.removeEventListener("click", this.handlePopupOutsideClick);
                window.removeEventListener("contextmenu", this.handleContextMenu);
            }
        }

        if (!this.props.isWorkspaceTextFocus && this.props.shortcutName !== prevProps.shortcutName && this.props.shortcutName !== "") {
            this.handleShortcuts(this.props.shortcutName);
        }

        if (!this.state.icons.equals(prevState.icons)) {
            this.handleMoreOptionsListing();
        }

        if (!this.state.textSelectedIn.equals(prevState.textSelectedIn)) { // Adding EventListner to decide the font color for TEXT
            if (prevState.textSelectedIn.get('rootType') === undefined && this.state.textSelectedIn.get('rootType') === 'TEXT') {
                document.addEventListener("selectionchange", this.checkTextSelected);
            } else if (prevState.textSelectedIn.get('rootType') === 'TEXT' && this.state.textSelectedIn.get('rootType') === undefined) {
                document.removeEventListener("selectionchange", this.checkTextSelected);
            }
        }

        const { itemtoolAnimationToken } = this.props;
        const pItemtoolAnimationToken = prevProps.itemtoolAnimationToken;
        if (itemtoolAnimationToken !== pItemtoolAnimationToken) {
            const { animateToolbar } = this.state;
            if (itemtoolAnimationToken) {
                if (!animateToolbar) {
                    this.setState({ animateToolbar: true });
                }
                if (
                    itemToolBarEl
                    && !itemToolBarEl.classList.contains("toolbar-transition")
                ) {
                    itemToolBarEl.classList.add("toolbar-transition");
                }
            } else {
                if (animateToolbar) {
                    this.setState({ animateToolbar: false });
                }
                if (
                    itemToolBarEl
                    && itemToolBarEl.classList.contains("toolbar-transition")
                ) {
                    itemToolBarEl.classList.remove("toolbar-transition");
                }
            }
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
        window.removeEventListener("pointerdown", this.handlePopupOutsideMouseDown);
        window.removeEventListener("click", this.handlePopupOutsideClick);
        window.removeEventListener("contextmenu", this.handleContextMenu);
        document.removeEventListener("selectionchange", this.checkTextSelected);
        window.removeEventListener("resize", this.handleWindowResize);
    }

    static getFrameClipFlip(frameItem, selectedFrameClip) {
        if (frameItem.get("type") === "FRAME") {
            const imgDetails = frameItem.getIn(["clipDetails", selectedFrameClip.get("clipId"), "imgDetails"]);
            if (imgDetails && (isImageOnly(imgDetails.get("type"), imgDetails.get("subType")) || isVideoOnly(imgDetails.get("type"), imgDetails.get("subType")))) {
                return imgDetails.get("flipPosition");
            }
        }
        return null;
    }

    static isUpImageSVG(item) {
        return (
            item
            && item.get("subType") === "UPIMAGE"
            && item.get("sourceType") === "svg"
            && item.get("colors")
            // && item.get("colors").size > 0
        );
    }

    
    handleShortcuts(option) {
        let handlerOptions = {
            isShortcutUsed: true,
        };
        switch (option) {
            case "DELETE_ITEM":
            case "DELETE_ITEM_MAC":
                if (this.props.swapDetails) {
                    this.props.resetSwap();
                }
                this.handleClick("keys", "delete", handlerOptions);
                break;
            default:
                break;
        }
    }

    handleWindowResize() {
        if (
            this.itemToolBarRef.current
            && this.itemToolBarRef.current.classList.contains("toolbar-transition")
        ) {
            // remove transition on resize
            this.itemToolBarRef.current.classList.remove("toolbar-transition");
        }
    }

    handleMoreOptionsListing() {
        const canHandleListing = (
            this.itemToolBarRef.current instanceof HTMLElement
            && this.setOneOptionsRef.current instanceof HTMLElement
            && this.setTwoOptionsRef.current instanceof HTMLElement
            && this.setOneMoreOptionsRef.current instanceof HTMLElement
            && this.setTwoMoreOptionsRef.current instanceof HTMLElement
        );
        if (!canHandleListing) return;

        const itemToolStyles = window.getComputedStyle(this.itemToolBarRef.current);
        const TOOLBAR_PADDING_LEFT = parseFloat(itemToolStyles.paddingLeft);
        const TOOLBAR_PADDING_RIGHT = parseFloat(itemToolStyles.paddingRight);

        let toolBarWidth = this.itemToolBarRef.current.getBoundingClientRect().width - (TOOLBAR_PADDING_LEFT + TOOLBAR_PADDING_RIGHT);

        let setOneWidth = this.setOneOptionsRef.current.getBoundingClientRect().width;
        let setTwoWidth = this.setTwoOptionsRef.current.getBoundingClientRect().width;

        let setOneMoreOptionsWidth = this.setOneMoreOptionsRef.current.getBoundingClientRect().width;
        let setTwoMoreOptionsWidth = this.setTwoMoreOptionsRef.current.getBoundingClientRect().width;

        let contentWidth = setOneWidth + setTwoWidth + setOneMoreOptionsWidth + setTwoMoreOptionsWidth;

        let setOneRemoveCount = 0;
        let setTwoRemoveCount = 0;

        if (toolBarWidth < contentWidth) { // HANDLE SET TWO
            let setTwoParents = [
                this.setTwoMoreOptionsRef.current,
                this.setTwoOptionsRef.current,
            ];

            for (let parentIndex = 0; parentIndex < setTwoParents.length; parentIndex = parentIndex + 1) {
                let setTwoParent = setTwoParents[parentIndex];
                let setTwoOptionList = [...setTwoParent.querySelectorAll(":scope > ul > li")];

                for (let optionIndex = 0; optionIndex < setTwoOptionList.length; optionIndex = optionIndex + 1) {
                    if (
                        parentIndex === setTwoParents.length - 1
                        && setTwoOptionList.length - optionIndex === this.MIN_OPTION_IN_SET_TWO
                    ) {
                        break;
                    }

                    let option = setTwoOptionList[optionIndex];
                    let optionStyles = window.getComputedStyle(option);
                    const OPTIONS_MARGIN_LEFT = parseFloat(optionStyles.marginLeft);
                    const OPTIONS_MARGIN_RIGHT = parseFloat(optionStyles.marginRight);

                    contentWidth = contentWidth - (OPTIONS_MARGIN_LEFT + option.getBoundingClientRect().width + OPTIONS_MARGIN_RIGHT);
                    setTwoRemoveCount = setTwoRemoveCount + 1;
                    if (toolBarWidth >= contentWidth) {
                        break;
                    }
                }

                if (toolBarWidth >= contentWidth) {
                    break;
                }
            }

            if (toolBarWidth < contentWidth) { // HANDLE SET ONE
                let setOneParents = [
                    this.setOneMoreOptionsRef.current,
                    this.setOneOptionsRef.current,
                ];

                for (let parentIndex = 0; parentIndex < setOneParents.length; parentIndex = parentIndex + 1) {
                    let setOneParent = setOneParents[parentIndex];
                    let setOneOptionList = [...setOneParent.querySelectorAll(":scope > ul > li")].reverse();

                    for (let optionIndex = 0; optionIndex < setOneOptionList.length; optionIndex = optionIndex + 1) {
                        if (
                            parentIndex === setOneParents.length - 1
                            && setOneOptionList.length - optionIndex === this.MIN_OPTION_IN_SET_ONE
                        ) {
                            break;
                        }

                        let option = setOneOptionList[optionIndex];
                        let optionStyles = window.getComputedStyle(option);
                        const OPTIONS_MARGIN_LEFT = parseFloat(optionStyles.marginLeft);
                        const OPTIONS_MARGIN_RIGHT = parseFloat(optionStyles.marginRight);

                        contentWidth = contentWidth - (OPTIONS_MARGIN_LEFT + option.getBoundingClientRect().width + OPTIONS_MARGIN_RIGHT);
                        setOneRemoveCount = setOneRemoveCount + 1;
                        if (toolBarWidth >= contentWidth) {
                            break;
                        }
                    }

                    if (toolBarWidth >= contentWidth) {
                        break;
                    }
                }
            }
        } else {
            setOneRemoveCount = 0;
            setTwoRemoveCount = 0;
        }

        let toUpdate = {};
        if (this.state.setOneRemoveCount !== setOneRemoveCount) {
            toUpdate.setOneRemoveCount = setOneRemoveCount;
        }
        if (this.state.setTwoRemoveCount !== setTwoRemoveCount) {
            toUpdate.setTwoRemoveCount = setTwoRemoveCount;
        }
        if (toUpdate.setOneRemoveCount !== undefined || toUpdate.setTwoRemoveCount !== undefined) {
            this.setState(toUpdate);
        }

        if (this.isAnyPopupOpen()) { // close all popups on resize/icon change
            this.closeAllPopups();
        }
    }

    isPopupStateDifferent(state, prevState) {
        return Reflect
            .ownKeys(state.popupToggleState)
            .some(t => state.popupToggleState[t] !== prevState.popupToggleState[t]);
    }

    isAnyPopupOpen(ignoreExpandIcon = true) {
        return Reflect
            .ownKeys(this.state.popupToggleState)
            .some(t => {
                if (t === "expand" && ignoreExpandIcon) {
                    return false;
                }
                return this.state.popupToggleState[t];
            });
    }

    closeAllPopups(params = {}) {
        const { ignoreExpand } = params;
        const { popupToggleState } = this.state;

        let newPopupState = this.INITIAL_POPUP_STATE;

        if (ignoreExpand) {
            newPopupState = { ...newPopupState };
            newPopupState.expand = popupToggleState.expand;
        }

        this.setState({ popupToggleState: newPopupState });
    }

    togglePopup(icon, state) {
        this.setState(p => ({
            ...p,
            popupToggleState: {
                ...p.popupToggleState,
                [icon]: typeof state === "boolean" ? state : !p.popupToggleState[icon],
            },
        }));
    }

    handleContextMenu() {
        this.closeAllPopups();
    }

    shouldClosePopup(e) {
        return (
            this.isAnyPopupOpen(false)
            && (!this.itemToolBarRef.current.contains(e.target) || this.itemToolBarRef.current === e.target) // clicked outside toolbar or clicked on empty space of toolbar
            && !(isMouseOn(e, "item-tool")) // not clicked on options
            && !(isMouseOn(e, "tool-bar")) // to prevent layer-menu close during tour!!
        );
    }

    handlePopupOutsideMouseDown(e) {
        if (this.shouldClosePopup(e)) {
            this.clickedOutside = true;
        } else {
            this.clickedOutside = false;
        }
    }

    handlePopupOutsideClick(e) {
        if (this.clickedOutside && this.shouldClosePopup(e)) {
            this.closeAllPopups();
        }
        this.clickedOutside = false;
    }

    isSelectedItemIsText() {
        let selectedItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));
        return (
            this.state.textSelectedIn.get("type") === "TEXT"
            || this.state.textSelectedIn.get("type") === "SHAPE"
            || (selectedItem && selectedItem.get("type") === "TEXT")
        );
    }

    toggleTextColorPalette() {
        if (
            this.props.propertyWindow.get("component") === "settings"
            && this.props.propertyWindow.get("type") === "TEXT"
            && this.props.propertyWindow.get("isTextColor")
        ) {
            this.props.setPropertyWindow({
                component: "",
                type: "",
                isTextColor: false,
                isOpened: false
            });
        } else {
            this.props.setPropertyWindow({
                component: "settings",
                type: "TEXT",
                isTextColor: true,
                isOpened: true,
            });
        }
    }

    checkTextSelected() {
        if (!this.isSelectedItemIsText()) { // Runs only for textItem.
            return;
        }
        try {
            let itemId = this.props.selectedItems.get(0);
            if ((this.state.textSelectedIn.get("type") === "TEXT" || this.state.textSelectedIn.get("type") === "SHAPE")) {
                itemId = this.state.textSelectedIn.get("id")
            }
            let fullTxtSelected = isAllTextSelected(document.getElementById(itemId).getElementsByClassName("text-container")[0]);
            let txtSelected = false;
            if (!fullTxtSelected) {
                txtSelected = isTextSelected();
            }
            this.setState({
                fullTextSelected: fullTxtSelected,
                textSelected: txtSelected
            });
        } catch (error) { }
    }

    checkIconIsDisabled(icon) {
        let result = false;

        if (icon === "group") {
            result = (
                this.props.selectedItems.size > 1
                && this.props.selectedObjects.some((item) => item.get("type") === "GROUP")
            );
        }

        if (icon === "animate") {
            const item = this.props.projectDetails.getIn([
                "workspaceItems",
                this.props.selectedItems.get(0),
            ]);
            result =
                (item.get("transitionEnterEffect") &&
                    item.get("transitionEnterEffect") !== "none_transition") &&
                (item.get("transitionExitEffect") &&
                    item.get("transitionExitEffect") !== "none_transition") &&
                (item.get("transitionExitId") &&
                    item.get("transitionExitId") !== "none") &&
                (item.get("transitionEnterId") &&
                    item.get("transitionEnterId") !== "none")
        }
        return result;
    }

    checkIconIsActive(icon) {
        let result = false;

        if (icon === "settings") {
            result = (
                this.props.propertyWindow.get("isOpened")
                && (
                    this.props.propertyWindow.get("component") === "settings"
                    || this.props.propertyWindow.get("component") === "subtitle_settings"
                )
                && !this.props.propertyWindow.get("isTextColor")
            );
        } else if (icon === "color") {
            if (this.props.propertyWindow.get("component") === "settings") {
                result = (
                    this.props.propertyWindow.get("isOpened")
                    && this.props.propertyWindow.get("isTextColor")
                );
            } else if (
                this.props.propertyWindow.get("component") === "color"
                || this.props.propertyWindow.get("component") === "bgColor"
            ) {
                result = this.props.propertyWindow.get("isOpened");
            }
        } else if (Reflect.has(this.state.popupToggleState, icon)) {
            result = this.state.popupToggleState[icon];
        }

        return result;
    }

    getGroupColors() {
        if (!(this.props.selectedObjects.size === 1 && this.props.selectedObjects.first().get("type") === "GROUP")) {
            return null;
        }

        let groupChildrenIds = this.props.selectedObjects.first().get("groupChildren");
        let groupChildren = groupChildrenIds.map((childId) => this.props.workspaceChildren.get(childId));
        let colorValues = new Set();

        groupChildren.forEach((child) => {
            if (!ItemToolBarComponent.GROUP_COLOR_TYPES.includes(child.get("type"))) return;

            let color;
            if (child.get("type") === "TEXT") {
                color = child.getIn(["textData", "formats", "containerStyle", "color"]);
            } else {
                let colors = [...child.get("colors").values()];
                [color] = colors;
            }

            if (typeof color === "string") {
                colorValues.add(color.toUpperCase());
            }
        });

        return [...colorValues];
    }

    getSelectedTextColor() {
        let selectedItemId = this.props.selectedItems.get(0);
        let selectedItem = this.props.selectedObjects.get(selectedItemId);
        if (selectedItem.get("type") === "GROUP") {
            selectedItemId = this.state.textSelectedIn.get("id");
            selectedItem = this.props.workspaceChildren.get(this.state.textSelectedIn.get("id"));
        }

        return getTextColors({
            selectedItemId,
            selectedItem,
            textStatus: this.props.textStatus,
            getAllColors: false,
        });
    }

    getFirstSelection() {
        const selection = {
            container: "",
            selectedItem: null,
            selectedClip: null,
            isError: false,
        };

        try {
            const { selectedItems, childrenSelection, selectionContainer, selectedFrameClip, selectedAudios, selectedSubtitles } = this.props;
            const { selectedObjects, selectedChildrenObjects } = this.props;

            selection.container = selectionContainer;

            if (selectedFrameClip.get("clipId")) {
                if (selectedFrameClip.get("groupId")) {
                    selection.container = "workspaceChildren";
                    selection.selectedItem = selectedChildrenObjects.get(selectedFrameClip.get("id"));
                } else {
                    selection.container = "workspaceItems";
                    selection.selectedItem = selectedObjects.get(selectedFrameClip.get("id"));
                }

                selection.selectedClip = selection.selectedItem.getIn(["clipDetails", selectedFrameClip.get("clipId")]);
            } else if (selectedAudios.size === 1 && selectedItems.size === 0) {
                const selectedAudio = this.props.audios.get(selectedAudios.first());
                if (selectedAudio) {
                    selection.selectedItem = selectedAudio;
                    selection.container = "audios";
                }
            } else if (selectedSubtitles.size > 0) {
                const selectedSubtitle = this.props.localSubtitle.get(selectedSubtitles.first());
                if (selectedSubtitle) {
                    selection.selectedItem = selectedSubtitle;
                    selection.container = "localSubtitle";
                }
            } else if (selectionContainer === "workspaceChildren") {
                const firstChildId = childrenSelection.getIn(["childIds", 0]);
                selection.selectedItem = selectedChildrenObjects.get(firstChildId);
            } else if (selectionContainer === "workspaceItems") {
                const firstItemId = selectedItems.first();
                selection.selectedItem = selectedObjects.get(firstItemId);
            }
        } catch (error) {
            selection.selectedItem = null;
            selection.selectedClip = null;
            selection.container = "";
            selection.isError = true;
        }

        return selection;
    }

    duplicateHandler() {
        const { projectDetails, selectedItems, updateTimelineTime } = this.props;
        const itemsToDuplicate = [];
        ["workspaceItems", /* "workspaceBG", */ "audios"].forEach((container) => {
            selectedItems.forEach((sliderId) => {
                if (projectDetails.getIn([container, sliderId])) {
                    itemsToDuplicate.push({
                        container,
                        item: projectDetails.getIn([container, sliderId]).toJS(),
                    });
                }
            });
        });
        const toUpdate = duplicateItemHelper({ itemsToDuplicate, projectDetails });
        updateTimelineTime({ toUpdate });
    }



    handleClick(e, action, /* options = { isShortcutUsed: false } */) {
        try {
            const { popupToggleState } = this.state;
            const selection = this.getFirstSelection();



            switch (action) {
                case "radius":
                case "transform":
                case "transparency":
                case "layer":
                case "align":
                case "expand": {
                    this.closeAllPopups({ ignoreExpand: true });
                    this.togglePopup(action, !popupToggleState[action]);
                    break;
                }
                case "crop": {
                    this.props.toggleCrop();
                    break;
                }
                case "frame": {
                    this.props.selectFrameClip({
                        id: this.props.selectedFrameClip.get("id"),
                        groupId: this.props.selectedFrameClip.get("groupId"),
                        clipId: this.props.selectedFrameClip.get("clipId"),
                        isFraming: !this.props.selectedFrameClip.get("isFraming"),
                    });
                    break;
                }
                case "delete": {
                    const { selectedItems, selectedAudios, selectedSubtitles, selectedFrameClip } = this.props;
                    const { selectedObjects, selectedChildrenObjects, localSubtitle } = this.props;

                    const toUpdate = [];

                    if (selectedFrameClip.get("clipId")) {
                        const container = selectedFrameClip.get("groupId")
                            ? "workspaceChildren"
                            : "workspaceItems";
                        const clipId = selectedFrameClip.get("clipId");
                        let frameItem =
                            container === "workspaceChildren"
                                ? selectedChildrenObjects.get(selectedFrameClip.get("id"))
                                : selectedObjects.get(selectedFrameClip.get("id"));
                        if (
                            frameItem &&
                            frameItem.getIn([
                                "clipDetails",
                                clipId,
                                "imgDetails",
                                "src",
                            ])
                        ) {
                            toUpdate.push({
                                id: selectedFrameClip.get("id"),
                                container,
                                toUpdate: {
                                    frameClipImage: {
                                        clipId,
                                        imgDetails: prepareDefaultImgDetails(
                                            frameItem,
                                            clipId
                                        ),
                                    },
                                },
                            });
                        }
                    } else {
                        selectedItems.valueSeq().forEach((itemId) => {
                            const isBlob = this.props.projectDetails && this.props.projectDetails.getIn(["workspaceItems", itemId, "isBlob"])
                            if (!isBlob) {
                                toUpdate.push({
                                    id: itemId,
                                    container: "workspaceItems",
                                    isDelete: true,
                                });
                            } else {
                                this.props.addNotifications({
                                    id: uuid(),
                                    toastType: "WARNING",
                                    description: content.UPLOAD_UNABLE_TO_DELETE,
                                })
                            }
                        });
                        selectedAudios.valueSeq().forEach((itemId) => {
                            if (this.props.projectDetails.getIn(["audios", itemId, "isBlob"])) {
                                this.props.addNotifications({
                                    id: uuid(),
                                    toastType: "WARNING",
                                    description: content.UPLOAD_UNABLE_TO_DELETE,
                                })
                            } else {
                                toUpdate.push({
                                    id: itemId,
                                    container: "audios",
                                    isDelete: true,
                                });
                            }
                        });

                        selectedSubtitles.valueSeq().forEach((itemId) => {
                            const subtitleId = localSubtitle.getIn([itemId, "subtitleId"]);
                            toUpdate.push({
                                container: "subtitleData",
                                id: subtitleId.get("id"),
                                dropId: subtitleId.get("dropId"),
                                langId: subtitleId.get("langId"),
                                timelineId: subtitleId.get("timelineId"),
                                isDelete: true,
                            });
                        });
                    }

                    if (toUpdate.length) {
                        this.props.updateTimelineTime({ toUpdate });
                    }
                    break;
                }
                case "settings": {
                    if (selection.selectedClip) {
                        const imgDetails = selection.selectedClip.get("imgDetails");
                        if (
                            isImageOnly(
                                imgDetails.get("type"),
                                imgDetails.get("subType")
                            )
                        ) {
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: "IMAGE_SETTINGS",
                                isOpened: true,
                            });
                        } else if (
                            isVideoOnly(
                                imgDetails.get("type"),
                                imgDetails.get("subType")
                            )
                        ) {
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: "VIDEO_SETTINGS",
                                isOpened: true,
                            });
                        }
                    } else if (selection.container === "localSubtitle" && selection.selectedItem) {
                        this.props.setPropertyWindow({ component: "subtitle_settings", type: "SUBTITLE", });
                    } else if (
                        selection.selectedItem &&
                        (selection.container === "workspaceItems" ||
                            selection.container === "workspaceChildren")
                    ) {
                        const { selectedItem } = selection;

                        if (
                            isImageOnly(
                                selectedItem.get("type"),
                                selectedItem.get("subType")
                            )
                        ) {
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: "IMAGE_SETTINGS",
                                isOpened: true,
                            });
                        } else if (
                            isVideoOnly(
                                selectedItem.get("type"),
                                selectedItem.get("subType")
                            )
                        ) {
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: "VIDEO_SETTINGS",
                                isOpened: true,
                            });
                        } else if (selectedItem.get("type") === "SHAPE") {
                            this.props.updateTextStatus({
                                id: selectedItem.get("id"),
                                isFocused: true,
                                container: selection.container,
                                isRTL: selectedItem.getIn([
                                    "textData",
                                    "formats",
                                    "others",
                                    "isRTL",
                                ]),
                                isGrouped: selection.container === "workspaceChildren",
                                type: "SHAPE",
                                fontSize: parseFloat(
                                    selectedItem.getIn([
                                        "textData",
                                        "formats",
                                        "containerStyle",
                                        "fontSize",
                                    ])
                                ),
                            });
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: "TEXT",
                                isOpened: true,
                            });
                        } else {
                            this.props.setPropertyWindow({
                                component: "settings",
                                type: selectedItem.get("type"),
                                isOpened: true,
                            });
                        }
                    } else if (
                        selection.selectedItem &&
                        selection.container === "audios"
                    ) {
                        this.props.setPropertyWindow({
                            component: "settings",
                            type: "AUDIO_SETTINGS",
                            isOpened: true,
                        });
                    }
                    break;
                }
                case "color": {
                    if (this.isSelectedItemIsText()) {
                        this.toggleTextColorPalette();
                    } else {
                        this.props.setPropertyWindow({ component: "color" });
                    }
                    break;
                }
                case "popup-active": {
                    this.closeAllPopups({ ignoreExpand: true });
                    break;
                }
                case "settings-active": {
                    this.closeAllPopups({ ignoreExpand: true });
                    this.props.setPropertyWindow({
                        component: "",
                        type: "",
                        isOpened: false,
                    });
                    break;
                }
                case "duplicate":
                    this.duplicateHandler();
                    break;
                case "swap":
                    {
                        let itemToReplace;
                        let container;
                        if (this.props.selectedAudios.size) {
                            itemToReplace = this.props.projectDetails.getIn(["audios", this.props.selectedAudios.first()]);
                            container = "audios";
                        } else {
                            itemToReplace = selection.selectedItem;
                            container = "workspaceItems";
                        }
                        const { panelName, itemType } = getPanelName(
                            itemToReplace.get("type"),
                            itemToReplace.get("subType")
                        );
                        this.props.setReplaceItem(itemToReplace);
                        this.props.setSwapInfo(fromJS({ panelName, itemType, container }))
                        this.props.setPanel(panelName);
                        this.props.setExpand(true);
                        break;
                    }
                case "animate": {
                    this.props.setPropertyWindow({
                        component: content.ANIMATE,
                        type: "TEXT_ANIMATION",
                        isOpened: true,
                    });
                    break;
                }          
                default:
                    break;
            }
        } catch (error) { }
    }

    CloseItemToolBar = () => {
        this.setState(
          () => {
            this.props.onStateChange(false);
          }
        );
      };
      

    canShowToolBar() {
        return !(
            this.props.isCropping
            || this.props.isFraming
            || this.props.swapDetails
        );
    }

    applyHandler() {
        const { swapDetails, swapInfo, addObj, resetSwap } = this.props;
        if (swapDetails && swapInfo.get("droppedItem")) {
            const id = swapDetails.get("id");
            // taking the newItem form swapInfo to avoid issue in cropping (from portrait to landscape).
            const newItemData = swapInfo.get("droppedItem");
            addObj({
                toUpdate: [
                    {
                        container: swapInfo.get("container"),
                        id,
                        newItemData,
                        swapDetails,
                        isAdd: true,
                        isWorkspaceReplace: true,
                    }
                ],
            });
            resetSwap();
        } else if (swapDetails && !swapInfo.get("droppedItem")) {
            this.props.notify.warn("No Item selected for Swapping");
        }
    }

    cancelHandler() {
        const { swapDetails, swapInfo, resetSwap, addReplaceObj } = this.props;
        if (swapDetails) {
            const { id } = swapDetails;
            // Passing isWorkspaceReplace as false to avoid autosave and update redux with the original data to retain the original crop data.
            addReplaceObj({
                toUpdate: [
                    {
                        container: swapInfo.get("container"),
                        id,
                        newItemData: swapDetails,
                        isAdd: true,
                        isWorkspaceReplace: false,
                    }
                ],
            });
            resetSwap();
        }
    }
    
    onToolbarTransitionEnd(event) {
        if (event.target === event.currentTarget) {
            this.setState({ animateToolbar: false })
        }
    }

    render() {
        const { workspaceStage , isMobile } = this.props;
        const itemStyle = getToolBarStyle(workspaceStage);
        
        let toolbarClassName =  `item-tool-bar`;

        if (this.state.iconType) {
            toolbarClassName = toolbarClassName + " toolbar-" + this.state.iconType.toLowerCase();
        }
        if (!this.canShowToolBar()) {
            toolbarClassName = toolbarClassName + " toolbar-hidden";
        }
        if (this.state.animateToolbar) {
            toolbarClassName = toolbarClassName + " toolbar-transition " + this.props.itemtoolAnimationToken;
        }

        let selectedItemId = this.props.selectedItems.get(0);
        let selectedItem = this.props.selectedObjects.get(selectedItemId);
        if (
            this.props.selectionContainer === "workspaceChildren"
            && this.props.childrenSelection.get("childIds").size === 1
        ) {
            selectedItemId = this.props.childrenSelection.get("childIds").first();
            selectedItem = this.props.selectedChildrenObjects.get(selectedItemId);
        }
        let itemType = selectedItem && selectedItem.get('type');
        let isTextSelection = (itemType === 'TEXT' || this.state.textSelectedIn.get('type') === 'TEXT' || this.state.textSelectedIn.get('type') === 'SHAPE');

        let { setOneRemoveCount, setTwoRemoveCount } = this.state;
        let setOneIcons = [],
            setTwoIcons = [],
            setOneRemovedList = [],
            setTwoRemovedList = [];

        this.state.icons.forEach((iconSet, setKey) => {
            let icons = [];

            iconSet.forEach((icon) => {
                let iconDetail = vmTheme[this.props.theme].icons.actions[icon];
                if (iconDetail.ifDisabledSwapWith && this.checkIconIsDisabled(icon)) {
                    iconDetail = ITEM_TOOLBAR_ICONS_MAP.actions[iconDetail.ifDisabledSwapWith];
                }
                let iconToRender = null;
                let iconPopup = null;

                let { iconPath, isDisabled, action } = iconDetail;

                let isActive = this.checkIconIsActive(icon);
                let iconClassName = setKey + "-icon " + icon;

                if (isActive && iconDetail.actionIfActive) {
                    action = iconDetail.actionIfActive;
                }

                if (icon === "transparency" && isActive) {
                    iconPopup = <TransparencyPopup />;
                }

                if (icon === "layer" && isActive) {
                    iconPopup = <LayerPopup />;
                }

                if (icon === "align" && isActive) {
                    iconPopup = <ArrangePopup />;
                }

                if (icon === "transform" && isActive) {
                    iconPopup = <TransformPopup />;
                }

                if (icon === "radius" && isActive) {
                    iconPopup = <RadiusPopup />;
                }

                if (!iconPath) {
                    iconPath = {
                        default: "",
                        hover: "",
                        active: "",
                    };
                }

                if (icon === "color") {
                    let colors = [];
                    let colorType = "COMMON";

                    if (!selectedItem) {
                        colors = [...this.props.bgColor.get("colors").values()];
                    } else if (
                        selectedItem
                        && (
                            ItemToolBarComponent.isUpImageSVG(selectedItem)
                            || ((itemType === "PROP" || itemType === "CHR") && selectedItem.get("colors"))
                            || (itemType === "SHAPE" && !this.state.textSelectedIn.get("textFocused"))
                        )
                    ) {
                        colors = selectedItem.get("colors").filter((c) => c !== "");
                        colors = [...colors.values()];
                    } else if (selectedItem && itemType === "FRAME") {
                        if (this.props.selectedFrameClip.get("clipId")) {
                            colors = [selectedItem.getIn(["clipDetails", this.props.selectedFrameClip.get("clipId"), "imgDetails", "color"])];
                        } else {
                            colors = [...selectedItem.get("d").map((pathInfo) => pathInfo.get("fill"))];
                        }
                    } else if (selectedItem && itemType === "GROUP" && !isTextSelection) {
                        colors = this.getGroupColors();
                        if (colors === null) {
                            colors = [];
                        }
                    } else if (isTextSelection) {
                        colorType = "TEXT";
                        colors = this.getSelectedTextColor().multiColors;
                    }

                    iconToRender = (
                        <ItemToolColorOption
                            key={setKey + icon}
                            className={iconClassName}
                            colors={colors}
                            colorType={colorType}
                            langId={content[iconDetail.langId]}
                            isActive={isActive}
                            onClick={(e) => this.handleClick(e, action)}
                            ignoreTooltipIfActive={iconDetail.ignoreTooltipIfActive}
                        >
                            {iconPopup}
                            {isMobile && (
                                <Typography
                                content={COLOR}
                                font={font.normalMicro_11}
                                cursor="pointer"
                            />
                            )}
                        </ItemToolColorOption>
                    );
                } else {
                    iconToRender = (
                        <ItemToolOption
                            key={setKey + icon}
                            className={iconClassName}
                            hasIcon={iconDetail.hasIcon || isMobile}
                            hasText={iconDetail.hasText && !isMobile}
                            langId={content[iconDetail.langId]}
                            disabledLangId={iconDetail.disabledLangId}
                            isActive={isActive}
                            isDisabled={isDisabled}
                            prefix={ITEM_TOOLBAR_ICONS_MAP.basePath}
                            src={iconPath.default}
                            hover={iconPath.hover}
                            active={iconPath.active}
                            onClick={(e) => this.handleClick(e, action)}
                            ignoreTooltipIfActive={iconDetail.ignoreTooltipIfActive}
                        >
                            {iconPopup}
                        </ItemToolOption>
                    );
                }

                if (iconToRender) {
                    icons.push(iconToRender);
                }
            });

            if (setKey === "setOne") {
                setOneIcons = icons;
            } else if (setKey === "setTwo") {
                setTwoIcons = icons;
            }
        });



        if (isMobile && this.props.isToolBarVisible) {
              return (
                <ItemToolBarStyled
                ref={this.itemToolBarRef}
                className={toolbarClassName}
                style={itemStyle}
                >
                    <IconSetStyled ref={this.setOneOptionsRef} className="setOne">
                        <CloseItemToolBar onClick={this.CloseItemToolBar}>
                            <img src={`${STATIC_PATH}add-white.svg`} height="24px" width="24px" cursor={"pointer"} alt="add-white"/>
                        </CloseItemToolBar>
                        <ul>{setOneIcons}</ul>
                    </IconSetStyled>
                    <IconSetStyled ref={this.setTwoOptionsRef} className="setTwo">
                        <ul>{setTwoIcons}</ul>
                    </IconSetStyled>
                </ItemToolBarStyled>
                );
          }
          
        


        let expandIconDetail = ITEM_TOOLBAR_ICONS_MAP.actions.expand;
        let expandIconClassName = "expand setTwo-icon";
        if (setTwoRemoveCount === 0 && setOneRemoveCount === 0) {
            expandIconClassName = expandIconClassName + " expand-icon-tool-hidden";
        }

        setTwoIcons.unshift("expand"); // put a placeholder at the start to simplify calculation
        if (setTwoRemoveCount > 0) {
            setTwoRemovedList = setTwoIcons.splice(this.MIN_OPTION_IN_SET_TWO, setTwoRemoveCount); // remove icons from set two as much as possible
        }
        if (setOneRemoveCount > 0) {
            setOneRemovedList = setOneIcons.splice(-setOneRemoveCount); // remove icons from last of set one
        }
        let canShowMoreOptions = (
            this.state.popupToggleState.expand
            && (setOneRemovedList.length > 0 || setTwoRemovedList.length > 0)
        );
        let moreOptionsClass = !canShowMoreOptions ? "hide-moreoptions" : "";
        setTwoIcons[0] = ( // replace placeholder with actual icon
            <ItemToolOption
                key={"expand"}
                className={expandIconClassName}
                hasIcon={expandIconDetail.hasIcon || isMobile}
                hasText={expandIconDetail.hasText && !isMobile}
                langId={expandIconDetail.langId}
                isActive={this.state.popupToggleState.expand}
                prefix={ITEM_TOOLBAR_ICONS_MAP.basePath}
                src={expandIconDetail.iconPath.default}
                hover={expandIconDetail.iconPath.hover}
                active={expandIconDetail.iconPath.active}
                onClick={(e) => this.handleClick(e, expandIconDetail.action)}
                ignoreTooltipIfActive={expandIconDetail.ignoreTooltipIfActive}
            >
                {!isMobile && (
                <MoreOptionsContainer
                    ref={this.moreOptionsRef}
                    className={moreOptionsClass}
                >
                    <IconSetStyled ref={this.setOneMoreOptionsRef} className="setOne">
                        <ul>
                            {setOneRemovedList}
                        </ul>
                    </IconSetStyled>
                    <IconSetStyled ref={this.setTwoMoreOptionsRef} className="setTwo">
                        <ul>
                            {setTwoRemovedList}
                        </ul>
                    </IconSetStyled>
                </MoreOptionsContainer>
                )}
            </ItemToolOption>
        );

        return (
            <>
                <ItemToolBarStyled
                    ref={this.itemToolBarRef}
                    className={`${this.props.outerLayerVisible ? 'show' : 'hide'} ${toolbarClassName}`}
                    style={itemStyle}
                    onTransitionEnd={this.onToolbarTransitionEnd}
                >
                    <IconSetStyled ref={this.setOneOptionsRef} className="setOne">
                        <CloseItemToolBar onClick={this.CloseItemToolBar}>
                            <img src={`${STATIC_PATH}add-white.svg`} height="24px" width="24px" cursor={"pointer"} alt="add-white"/>
                        </CloseItemToolBar>
                        <ul>{setOneIcons}</ul>
                    </IconSetStyled>
                    <IconSetStyled ref={this.setTwoOptionsRef} className="setTwo">
                        <ul>{setTwoIcons}</ul>
                    </IconSetStyled>
                </ItemToolBarStyled>
                {this.props.swapDetails ?
                    <ItemToolConfirmation
                        workspaceStage={this.props.workspaceStage}
                        onApply={this.applyHandler}
                        onCancel={this.cancelHandler}
                    /> : null}
            </>
        );
    }
}

ItemToolBarComponent.propTypes = {
    workspaceStage: PropTypes.object,
    itemtoolAnimationToken: PropTypes.string,
    isCropping: PropTypes.bool,
    isFraming: PropTypes.bool,
    isMobile: PropTypes.bool,
    swapDetails: PropTypes.object,
    swapInfo: PropTypes.object,
    selectionContainer: PropTypes.string,
    selectedChildrenObjects: PropTypes.object,
    selectedObjects: PropTypes.object,
    childrenSelection: PropTypes.object,
    selectedItems: PropTypes.object,
    selectedAudios: PropTypes.object,
    selectedSubtitles: PropTypes.object,
    transformStatus: PropTypes.object,
    textStatus: PropTypes.object,
    propertyWindow: PropTypes.object,
    selectedFrameClip: PropTypes.object,
    workspaceChildren: PropTypes.object,
    localSubtitle: PropTypes.object,
    setPropertyWindow: PropTypes.func,
    toggleCrop: PropTypes.func,
    selectFrameClip: PropTypes.func,
    updateTimelineTime: PropTypes.func,
    bgColor: PropTypes.object,
    updateTextStatus: PropTypes.func,
    audios: PropTypes.object,
    shortcutName: PropTypes.string,
    isToolBarVisible: PropTypes.string,
    isWorkspaceTextFocus: PropTypes.bool,
    projectDetails: PropTypes.object,
    setReplaceItem: PropTypes.func,
    setSwapInfo: PropTypes.func,
    resetSwap: PropTypes.func,
    setPanel: PropTypes.func,
    setExpand: PropTypes.func,
    addObj: PropTypes.func,
    onStateChange: PropTypes.func,
    addReplaceObj: PropTypes.func,
    theme: PropTypes.string,
    outerLayerVisible:  PropTypes.bool,
    addNotifications: PropTypes.func,
    notify: PropTypes.object,
};

const getSelectedChildrenObjects = (state) => {
    const selectedChildIds = state.app.getIn(["childrenSelection", "childIds"]);
    return (
        state.projectDetails
            .get("workspaceChildren")
            .filter((obj, key) => selectedChildIds.keyOf(key) !== undefined)
    );
}

const mapStateToProps = (state) => ({
    // do not remove workspaceBounds prop! this is used to trigger render for ItemToolBar when window resizes
    workspaceBounds: state.app.get("workspaceBounds"),
    workspaceStage: state.app.get("workspaceStage"),
    itemtoolAnimationToken: state.app.get("itemtoolAnimationToken"),
    isCropping: state.app.get("isCropping"),
    isFraming: state.app.get("isFraming"),
    swapDetails: state.app.get("swapDetails"),
    swapInfo: state.app.get("swapInfo"),
    selectionContainer: getSelectionContainer({
        childrenSelection: state.app.get("childrenSelection"),
        selectedItems: state.app.get("selectedItems"),
    }),
    selectedChildrenObjects: getSelectedChildrenObjects(state),
    selectedObjects: state.projectDetails.get("workspaceItems").filter(
        (obj, key) => state.app.get("selectedItems").keyOf(key) !== undefined
    ),
    childrenSelection: state.app.get("childrenSelection"),
    selectedItems: state.app.get("selectedItems"),
    selectedAudios: state.app.get("selectedAudios"),
    selectedSubtitles: state.app.get("selectedSubtitles"),
    transformStatus: state.app.get('transformStatus'),
    textStatus: state.app.get("textStatus"),
    propertyWindow: state.app.get("propertyWindow"),
    selectedFrameClip: state.app.get("selectedFrameClip"),
    workspaceChildren: state.projectDetails.get("workspaceChildren"),
    localSubtitle: state.projectDetails.get("localSubtitle"),
    audios: state.projectDetails.get("audios"),
    bgColor: state.projectDetails.get("bgColor"),
    isWorkspaceTextFocus: state.app.get("isWorkspaceTextFocus"),
    projectDetails: state.projectDetails,
    theme: state.app.get('theme'),
});

const mapDispatchToProps = (dispatch) => ({
    setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
    toggleCrop: () => dispatch(toggleCrop()),
    selectFrameClip: (data) => dispatch(selectFrameClip(data)),
    updateTimelineTime: (data) => dispatch(updateTimelineTime(data)),
    updateTextStatus: (data) => dispatch(updateTextStatus(data)),
    setReplaceItem: (data) => dispatch(setSwapItem(data)),
    setSwapInfo: (data) => dispatch(setSwapInfo(data)),
    resetSwap: () => dispatch(resetSwap()),
    setPanel: (data) => dispatch(setPropertyPanel(data)),
    setExpand: (data) => dispatch(setExpandPanel(data)),
    addObj: (payload) => dispatch(addObject(payload)),
    addReplaceObj: (payload) => dispatch(addReplaceObject(payload)),
    addNotifications: (payload) => dispatch(addNotification(payload)),
});

const ItemToolBar = connect(mapStateToProps, mapDispatchToProps)(withNotify(ItemToolBarComponent));

export default ItemToolBar;
