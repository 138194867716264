import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../redux/actions/notificationUtils";
import { STATIC_PATH } from "../constants/config";
import { font } from "../constants/font";
import Typography from "./Typography";
import { useCustomCallback } from "../helper/hooks/useCustomCallback";
import vmTheme from "../constants/theme";

const ICON_TYPES = {
  SUCCESS: "toast_success.svg",
  WARNING: "toast_warning.svg",
};

export const TOAST_TYPES = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
};

const CloseIcon = styled.img`
  float: right;
  width: 20px;
  height: 20px;
  margin: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150%);
  }
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  bottom: 12px;
  right: 12px;
  z-index: 999999;
`;

const ToastNotification = styled.div`
  display: grid;
  grid-template-areas: "image message cancelBtn";
  align-items: center;
  grid-template-columns: 20px 1fr 10px;
  column-gap: 10px;
  margin: 0 0 6px;
  margin-bottom: 15px;
  min-width: 292px;
  min-height: 44px;
  border-radius: 8px;
  animation: ${(props) => (props.isVisible ? slideIn : slideOut)} 0.7s forwards;
  overflow: hidden;
  padding: 15px 10px;
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.theme.rgbaBlack};

  .toast-notification-image {
    grid-area: image;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .toast-close-icon {
    margin: 0;
    position: absolute;
    top: 17px;
    right: 10px;
  }

  .toast-notification-message {
    margin: 0;
    text-align: left;
    margin-left: -1px;
    white-space: nowrap;
    word-break: break-word;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToastItem = (props) => {
  const { toast } = props;
  const { id, timer = 2000 } = toast;
  const autoClose = !toast.disableDelete;

  const [isVisible, setIsVisible] = useState(true);

  const remainingTime = useRef(timer);
  const closeTimer = useRef(null);
  const resumedAt = useRef(null);
  const pausedAt = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const dispatch = useDispatch();

  const _closeAfter = useCallback((time) => {
    remainingTime.current = time;
    clearTimeout(closeTimer.current);
    closeTimer.current = setTimeout(() => {
      setIsVisible(false);
    }, time);
  }, []);
  const closeAfter = useCustomCallback({ memoizedCallback: _closeAfter });

  const _pauseTimer = useCallback(() => {
    if (autoClose) {
      if (resumedAt.current === null) {
        resumedAt.current = Date.now();
      }
      pausedAt.current = Date.now();
      clearTimeout(closeTimer.current);
    }
  }, [autoClose]);
  const pauseTimer = useCustomCallback({ memoizedCallback: _pauseTimer });

  const _resumeTimer = useCallback(() => {
    if (autoClose && resumedAt.current !== null) {
      const elapseBeforePause = pausedAt.current - resumedAt.current;
      const closeTimer = remainingTime.current - elapseBeforePause;
      resumedAt.current = Date.now();

      if (closeTimer <= 0) {
        setIsVisible(false);
      } else {
        closeAfter(closeTimer);
      }
    }
  }, [autoClose, closeAfter]);
  const resumeTimer = useCustomCallback({ memoizedCallback: _resumeTimer });

  /** @param {React.AnimationEvent} e */
  const onAnimationEnd = (e) => {
    const slideInName = slideIn.getName();
    const slideOutName = slideOut.getName();

    if (e.animationName === slideOutName) {
      dispatch(removeNotification(id));
    } else if (e.animationName === slideInName) {
      if (autoClose && document.visibilityState === "visible") {
        resumedAt.current = Date.now();
        closeAfter(timer);
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        pauseTimer();
      } else {
        resumeTimer();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [pauseTimer, resumeTimer]);

  return (
    <ToastNotification
      key={toast.id}
      isVisible={isVisible}
      onAnimationEnd={onAnimationEnd}
      onMouseEnter={pauseTimer}
      onMouseLeave={resumeTimer}
      className={"toast-notification"}
      backgroundColor={
        toast.toastType === "SUCCESS"
          ? vmTheme[theme].successColor
          : vmTheme[theme].toastWarningColor
      }
    >
      <CloseIcon
        src={`${STATIC_PATH}toast_close.svg`}
        className="toast-close-icon"
        alt="close_icon"
        onClick={() => setIsVisible(false)}
      />
      <div className={"toast-notification-image"}>
        <img
          src={`${STATIC_PATH}${ICON_TYPES[toast.toastType]}`}
          alt={toast.description}
        />
      </div>
      <TextContainer>
        <Typography
          padding="2px 0"
          color={vmTheme[theme].polarColor}
          className={"toast-notification-message"}
          content={toast.description}
          font={font.boldBase_16}
        />
        {toast.subText && (
          <Typography
            padding="2px 0"
            color={vmTheme[theme].polarColor}
            className={"toast-notification-message"}
            content={toast.subText}
            font={font.normalMini_20}
          />
        )}
      </TextContainer>
    </ToastNotification>
  );
};
ToastItem.propTypes = {
  toast: PropTypes.object,
};

const Toast = () => {
  const notificationList = useSelector(
    (state) => state.notification.notificationList
  );

  return (
    <MainContainer>
      {notificationList.map((toast, index) => (
        <ToastItem key={toast.id || index} toast={toast} />
      ))}
    </MainContainer>
  );
};

export default Toast;
