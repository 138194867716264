import React from "react";
import PropTypes from "prop-types";
import { getPath } from "./ShapeHelper";

class SVGConstructor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svgItem: "",
    };
  }

  componentDidMount() {
    const svgItem = this.getCustomPath();
    this.setState({ svgItem });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.zoomFactor !== this.props.zoomFactor ||
      prevProps.item.get("pathData") !== this.props.item.get("pathData") ||
      prevProps.item.get("colors") !== this.props.item.get("colors") ||
      prevProps.item.get("width") !== this.props.item.get("width") ||
      prevProps.item.get("height") !== this.props.item.get("height")
    ) {
      const svgItem = this.getCustomPath();
      this.setState({ svgItem });
    }
  }

  getCustomPath() {
    const { item } = this.props;

    const paths = [];
    const pathDetails = getPath(item, this.props.zoomFactor);
    const pathData = pathDetails.pathDetails;

    if (Array.isArray(pathData)) {
      for (let i = 0; i < pathData.length; i += 1) {
        let style = {};
        if (pathData[i].pathStyle !== undefined)
          style = {
            transform: `translate(${pathData[i].pathStyle.left}px, ${pathData[i].pathStyle.top}px)`,
          };
        paths.push(
          <g xmlns="http://www.w3.org/2000/svg" key={i} style={style}>
            <path
              xmlns="http://www.w3.org/2000/svg"
              d={pathData[i].data}
              className={`path${i}`}
              strokeWidth={`${this.props.zoomFactor * 4}px`}
              stroke={pathData[i].isStroke ? pathData[i].color : "none"}
              fill={pathData[i].isFill ? pathData[i].color : "none"}
            />
          </g>
        );
      }
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={
          pathDetails.shapeStyle === undefined
            ? item.get("width") * this.props.zoomFactor
            : pathDetails.shapeStyle.width
        }
        height={
          pathDetails.shapeStyle === undefined
            ? item.get("height") * this.props.zoomFactor
            : pathDetails.shapeStyle.height
        }
        style={
          pathDetails.shapeStyle !== undefined ? pathDetails.shapeStyle : {}
        }
      >
        {paths}
      </svg>
    );
  }

  render() {
    return this.state.svgItem;
  }
}

SVGConstructor.propTypes = {
  zoomFactor: PropTypes.number,
  item: PropTypes.object,
};

export default SVGConstructor;
