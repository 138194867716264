import PropTypes from "prop-types";
import styled from "styled-components";
import { RULER_OPTIONS } from "../timeline-constants";
import { font } from "../../../constants/font";

export const RulerContainer = styled.div`
  height: ${RULER_OPTIONS.height}px;
  overflow: hidden;

  > svg {
    height: 100%;

    text.ruler--timestamp {
      font: ${font.normalMicro};
      color: ${(props) => props.theme.RULER_FONT_COLOR};
    }
  }
`;
RulerContainer.displayName = "RulerContainer";

export const UpgradeIconContainer = styled.div.attrs((props) => {
  return {
    style: {
      transform: `translate(${props.$x}px, ${props.$y}px)`,
    },
  };
})`
  position: absolute;
  transform-origin: 0 0;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
`;
UpgradeIconContainer.propTypes = {
  $x: PropTypes.number.isRequired,
  $y: PropTypes.number.isRequired,
};
UpgradeIconContainer.displayName = "UpgradeIconContainer";
