/**
 * @param {String} styleString styleString inline html css value. remove the redundant style present in the first.
 * @returns unique style property as string.
 */
const removeDuplicateStyles = (styleString) => {
    // Split the style string into individual declarations
    const declarations = styleString.split(';').reverse().filter(declaration => { return declaration.trim() !== "" ? declaration.trim() : false });
    // Create an object to store unique style properties
    const uniqueStyles = {};

    // Iterate through the declarations and store unique properties
    declarations.forEach(declaration => {
        const [property, value] = declaration.split(':').map(part => part.trim());
        if (!uniqueStyles[property]) {
            uniqueStyles[property] = value;
        }
    });

    delete uniqueStyles["font-size"];
    delete uniqueStyles["letter-spacing"];

    // Reconstruct the style string from unique properties
    const uniqueStyleString = Object.entries(uniqueStyles)
        .map(([property, value]) => {
            if (property !== undefined && value !== undefined)
                return `${property}: ${value}; `
                return ""
        }).join('');

    return uniqueStyleString;
}

/**
 * @param {*} styleString styleString inline html css value.
 * @param {*} toRemove style to be removed;
 * @returns sliced style from toRemove value.
 */
const removeStyle = (styleString = "", toRemove = "") => {
    if (toRemove === undefined || toRemove === null || styleString.trim() === "" || toRemove.trim() === "") {
        return styleString;
    }
    // Split the style string into individual declarations
    const declarations = styleString.split(';').reverse().filter(declaration => { return declaration.trim() !== "" ? declaration.trim() : false });

    const removeDeclarations = toRemove.split(';').reverse().filter(declaration => { return declaration.trim() !== "" ? declaration.trim() : false });
    // Create an object to store unique style properties
    const uniqueStyles = {};
    const toRemUniqueStyles = {};

    // Iterate through the declarations and store unique properties
    declarations.forEach(declaration => {
        const [property, value] = declaration.split(':').map(part => part.trim());
        if (!uniqueStyles[property]) {
            uniqueStyles[property] = value;
        }
    });

    removeDeclarations.forEach(declaration => {
        const [property, value] = declaration.split(':').map(part => part.trim());
        if (!toRemUniqueStyles[property]) {
            toRemUniqueStyles[property] = value;
        }
    });

    Object.keys(toRemUniqueStyles).forEach(styleKey => {
        delete uniqueStyles[styleKey];
    });

    // Reconstruct the style string from unique properties
    const uniqueStyleString = Object.entries(uniqueStyles)
        .map(([property, value]) => {
            if (property !== undefined && value !== undefined)
                return `${property}: ${value}; `

                return ""
        }).join('');

    return uniqueStyleString;
}

/**
 * a recursive function which will flattern the nodes and construct a innerHTML
 * @param {*} nodes 
 * @param {*} htmlDOM - newFlatternDom to be builded
 * @param {*} styleVal - inline style values.
 * @returns flattern HTML as string format;
 */
const formatDom = (nodes, htmlDOM = "", openDiv = false, styleVal = '', params = {}) => {
    const { isCapitalize } = params;
    function formatTextNode(node, styleVal = '') {
        let newHtml = "";
        if (node.nodeType === Node.TEXT_NODE) {
            try {
                let hasStyle = true;
                if (styleVal.trim() === "") {
                    hasStyle = false;
                }
                /** @returns {Object} - isWordEndWithSpace - fix: if multiple space is present after a word */
                const parseCharDom = (word, style) => {
                    const chars = word.split("");
                    let charDom = "";
                    let isWordEndWithSpace = false;
                    for (let i = 0; i < chars.length; i++) {
                        if (isCapitalize && i === 1) { 
                            style += `text-transform: none;`;
                        }
                        if (chars[i] !== "\n") {
                            if (style !== "") {
                                charDom += `<span style="${style}" class="sp-ch">${chars[i]}</span>`
                            } else {
                                charDom += `<span class="sp-ch">${chars[i]}</span>`
                            }
                        }
                        if (chars[i + 1] !== undefined && chars[i + 1].trim() === "") {
                            isWordEndWithSpace = true;
                            i++;
                        }
                    }
                    return { charDom, isWordEndWithSpace };
                }
                if (hasStyle) {
                    newHtml = node.data.split(" ").map(word => {
                        const removedColor = styleVal.replace(/color[^;]*;/, ''); /** @Note removing the color style value for charNode only, faced hards in effect textColorHighlightHandler */
                        if (word.trim() === "") { // failCheck when we copy our space dom and pasted in the text
                            return word !== "" ? `<span data-ws="true" style="${styleVal}">${word.split("").map(char => `<span style="${removedColor}" class="sp-ch">${char}</span>`).join("")}</span>` : "";
                        }
                        const { charDom, isWordEndWithSpace } = parseCharDom(word, removedColor);
                        let wordDom = `<span class="sp-wd" style="${styleVal}">${charDom}</span>`
                        wordDom = isWordEndWithSpace ? `${wordDom}<span data-ws="true" style="${styleVal}">&nbsp;</span>` : wordDom;
                        return wordDom
                    }).join(`<span data-ws="true" style="${styleVal}"><span> </span></span>`);
                } else {
                    newHtml = node.data.split(" ").map(word => {
                        if (word.trim() === "") { // failCheck when we copy our space dom and pasted in the text
                            return word !== "" ? `<span data-ws="true">${word.split("").map(char => `<span class="sp-ch">${char}</span>`).join("")}</span>` : "";
                        }
                        const { charDom, isWordEndWithSpace } = parseCharDom(word, "");
                        let wordDom = `<span class="sp-wd">${charDom}</span>`
                        wordDom = isWordEndWithSpace ? `${wordDom}<span data-ws="true">&nbsp;</span>` : wordDom;
                        return wordDom
                        // return `<span class="sp-wd">${word.split("").map(char => `<span class="sp-ch">${char}</span>`).join("")}</span>`;
                    }).join(`<span data-ws="true"><span> </span></span>`);
                }
            } catch (Err) {
                // console.log(Err);
            }
            return newHtml;
        } 
        if (node.nodeName === 'BR') {
            newHtml = "<br/>";
            return newHtml;
        }
        return true;
    }

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].childNodes && nodes[i].childNodes.length === 0) {
            const appendHtml = formatTextNode(nodes[i], styleVal);
            if (appendHtml !== undefined) {
                htmlDOM += appendHtml
            }
        } else {
            if (nodes[i].nodeName === "DIV") {
                // styleVal = "";
                htmlDOM += "<div>";
            }
            if (nodes[i].nodeName === 'SPAN') {
                styleVal += nodes[i].getAttribute("style");
            } else if (nodes[i].nodeName === 'FONT') {
                if (nodes[i].getAttribute("face") !== undefined && nodes[i].getAttribute("face") !== null && nodes[i].getAttribute("face") !== "") {
                    styleVal += `font-family: ${nodes[i].getAttribute("face")};`
                }
                styleVal += `color: ${nodes[i].getAttribute("color")};`
            } else if (nodes[i].nodeName === "U") {
                styleVal += `text-decoration: underline;`
            } else if (nodes[i].nodeName === 'OL') {
                    htmlDOM += `<ol>`
            } else if (nodes[i].nodeName === 'UL') {
                    htmlDOM += `<ul>`
            } else if (nodes[i].nodeName === 'LI') {
                htmlDOM += `<li>`
            }
            if (styleVal !== "") {
                styleVal = removeDuplicateStyles(styleVal);
            }
            [htmlDOM, styleVal] = formatDom(nodes[i].childNodes, htmlDOM, openDiv, styleVal, params);
            if (nodes[i].nodeName === "DIV") {
                htmlDOM += "</div>";
            } else if (nodes[i].nodeName === 'FONT') {
                if (nodes[i].getAttribute("face") !== undefined && nodes[i].getAttribute("face") !== null && nodes[i].getAttribute("face") !== "") {
                    styleVal = styleVal.replace(`font-family: ${nodes[i].getAttribute("face")};`, "");
                }
                styleVal = styleVal.replace(`color: ${nodes[i].getAttribute("color")};`, "");
            } else if (nodes[i].nodeName === "U") {
                styleVal = styleVal.replace(`text-decoration: underline;`, "");
            } else if (nodes[i].nodeName === 'SPAN') {
                styleVal = removeStyle(styleVal, nodes[i].getAttribute("style"));
            } else if (nodes[i].nodeName === 'OL') {
                htmlDOM += `</ol>`
            } else if (nodes[i].nodeName === 'UL') {
                htmlDOM += `</ul>`
            } else if (nodes[i].nodeName === 'LI') {
                htmlDOM += `</li>`
            }
        }
    }
    return [htmlDOM, styleVal]
}

/** this will shift the child div to parents siblings. so that no div elem presents inside div */
const shiftDivElemUp = (nodes) => {
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].nodeName === "DIV") {
            const divChildNodes = nodes[i].childNodes;
            let k = i + 1;
            for (let j = 0; j < divChildNodes.length;) {
                if (divChildNodes[j].nodeName === "DIV") {
                    const divNode = divChildNodes[j];
                    nodes[i].removeChild(divNode);
                    if (nodes[k] !== null && nodes[k] !== undefined && nodes[k] instanceof Node) {
                        nodes[k].parentNode.insertBefore(divNode, nodes[k])
                    } else {
                        nodes[k - 1].parentElement.appendChild(divNode)
                    }
                    k++;
                } else {
                    j++;
                }
            }
        }
    }
}

/** 
 * @scenario - https://app.vmaker.com/record/CejzriOpW1MsBJtk
 * this will wrap the textContent or elem other than div inside the div element
 */
const wrapWithDiv = (nodes, parentNode) => {
    try {
        let divElement = document.createElement("div");
        let isDivCreated = false;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].nodeName !== "DIV" && nodes[i].nodeName !== "OL" && nodes[i].nodeName !== "UL") {
                const removedNode = parentNode.removeChild(nodes[i])
                divElement.appendChild(removedNode)
                i--;
                if (!isDivCreated) {
                    isDivCreated = true;
                }
            } else if (isDivCreated) {
                    parentNode.insertBefore(divElement, nodes[i]);
                    isDivCreated = false;
                    i++;
                    divElement = document.createElement("div");
                }
        }
        if (isDivCreated === true) {
            parentNode.appendChild(divElement);
        }
    } catch (Err) {
        // console.log(Err);
    }
}

export const getFlatternHtmlText = (htmlText, params) => {
    const { hasUnderLine } = params;
    const divElement = document.createElement("div");
    divElement.innerHTML = htmlText;
    shiftDivElemUp(divElement.childNodes);
    wrapWithDiv(divElement.childNodes, divElement);
    const newHtmlDom = formatDom(divElement.childNodes, "", false, hasUnderLine ? "text-decoration: underline;" : "", params);
    return newHtmlDom[0]
}

/**
 * this functinon will position the dummy inline-flex element to as same as the original inline element;
 * as well as split the overFlow text content into lines
 * @param {*} originalNodes - []
 * @param {*} dummyNodes - []
 * @param {*} parentPos - { x, y}
 * @param {*} parentNodeName - {DIV,..}
 * @returns 
 */
export const splitIntoLineAndPosititonDom = (originalNodes, dummyNodes, parentPos, parentNodeName, params) => {
    try {
        const { isCapitalize } = params;
        const { parentX, parentY } = parentPos;
        let lines = [];
        // let prevValidSpaceIndex = -1;
        let prevTop;
        let prevHeight;
        let divElement = document.createElement("div");
        let dummyIndex = 0;
        divElement.classList.add("sp-ln");
        for (let index = 0; index < originalNodes.length; index++) {
            const { x, width, height, y } = originalNodes[index].getBoundingClientRect();
            const offSetY = originalNodes[index].offsetTop;
            if (prevTop === undefined) {
                prevTop = y;
                prevHeight = height;
            }
            if (originalNodes[index].nodeName !== "SPAN" && originalNodes[index].nodeName !== "BR") { // DIV, OL, UL, LI
                if (originalNodes[index].nodeName === "LI") {
                    dummyNodes[index].style.width = `${width.toFixed(2)}px`;
                    dummyNodes[index].style.height = `${height.toFixed(2)}px`;
                }
                if (originalNodes[index].childNodes.length > 0) {
                    let newLines = splitIntoLineAndPosititonDom(originalNodes[index].childNodes, dummyNodes[dummyIndex].childNodes,
                        parentPos, originalNodes[index].nodeName, params);
                    if (originalNodes[index].nodeName === "LI") {
                        dummyNodes[index].classList.add("sp-ln");
                        newLines = [dummyNodes[index]]
                    }
                    if (originalNodes[index].nodeName === "UL" || originalNodes[index].nodeName === "OL") {
                        dummyIndex++;
                        lines = [...lines, ...newLines];
                    } else if (newLines.length > 0) {
                            if (originalNodes[index].nodeName === "DIV") {
                                const { height: divHeight } = originalNodes[index].getBoundingClientRect();
                                const eachLineHeight = divHeight / newLines.length;
                                for (let i = 0; i < newLines.length; i++) {
                                    newLines[i].style.height = `${eachLineHeight.toFixed(2)}px`;
                                }
                                dummyNodes[dummyIndex].replaceWith(...newLines)
                            }
                            dummyIndex += newLines.length;
                            lines = [...lines, ...newLines];
                        }
                }
            } else {
                const applyTransFormStyle = (node, x, y, parentX, parentY, width, height) => {
                    node.style.display = "inline-flex";
                    node.style.position = 'absolute';
                    node.style.alignItems = 'center';
                    node.style.left = `${(x - parentX).toFixed(2)}px`;
                    node.style.top = `${(y - parentY).toFixed(2)}px`;
                    node.style.width = `${width.toFixed(2)}px`;
                    node.style.height = `${height.toFixed(2)}px`;
                }

                applyTransFormStyle(dummyNodes[index], x, y, parentX, parentY, width, height);
                /** word array construction start */
                if (dummyNodes[index].dataset.ws === 'true') {
                    dummyNodes[index].style.opacity = 0;
                    dummyNodes[index].innerHTML = `&nbsp;` /** @Note fix for space underLine break while play */
                    // prevValidSpaceIndex = index;
                } else if (originalNodes[index].nodeName !== "BR") { /** Don't consider BR as word */
                        /** char array construction start */
                        let wholeCharHeight = 0
                        let topVal;
                        for (let i = 0; i < originalNodes[index].childNodes.length; i++) {
                            if (topVal !== originalNodes[index].childNodes[i].offsetTop) {
                                topVal = originalNodes[index].childNodes[i].offsetTop;
                                originalNodes[index].childNodes[i].style.display = "inline-flex";
                                wholeCharHeight += originalNodes[index].childNodes[i].getBoundingClientRect().height;
                                originalNodes[index].childNodes[i].style.display = "inline";
                            }
                        }
                        let prevCharSpace = 0;
                        for (let i = 0; i < originalNodes[index].childNodes.length; i++) {
                            let { x: ogCharX } = originalNodes[index].childNodes[i].getBoundingClientRect();
                            const { width } = originalNodes[index].childNodes[i].getBoundingClientRect();

                            if (width === 0) {
                                ogCharX = prevCharSpace; /** bugFix @Note in some fontFamily combination a single char width take twise the size and the adjacent takes 0 width */
                                /* originalNodes[index].childNodes[i].style.display = "inline-flex";
                                ogCharX = originalNodes[index].childNodes[i].getBoundingClientRect().x;
                                originalNodes[index].childNodes[i].style.display = "inline"; */
                            }
                            prevCharSpace = parseFloat((ogCharX + (width / 2)));
                            topVal = originalNodes[index].childNodes[i].offsetTop;
                            dummyNodes[index].childNodes[i].style.display = "inline-flex";
                            dummyNodes[index].childNodes[i].style.alignItems = 'center';
                            dummyNodes[index].childNodes[i].style.position = 'absolute';
                            dummyNodes[index].childNodes[i].style.left = `${(ogCharX - x).toFixed(2)}px`;
                            dummyNodes[index].childNodes[i].style.top = `${((topVal - offSetY) + ((height - wholeCharHeight) / 2)).toFixed(2)}px`;
                            if (i !== 0 && isCapitalize) { 
                                dummyNodes[index].childNodes[i].style.textTransform = 'none';
                            }
                        }
                        /** char array construction End */
                    }
                /** word array construction End */

                /** line array construction start */
                if (parentNodeName === "DIV") {
                    if (((prevTop + (prevHeight / 2)) < y)) {
                        if (dummyNodes[index - 1].dataset.ws === 'true') { /** no need to apply this style in last whiteSpace. */
                            divElement.lastElementChild.style.textDecoration = "none";
                        }
                        prevTop = y;
                        prevHeight = height;
                        lines.push(divElement.cloneNode(true));
                        divElement = document.createElement("div");
                        divElement.classList.add("sp-ln");
                    }
                    divElement.appendChild(dummyNodes[index].cloneNode(true));
                }
                /** line array construction end */
            }
        }
        if (divElement.childNodes.length > 0) {
            lines.push(divElement.cloneNode(true));
        }
        return lines;
    } catch (Err) {
        // console.log(Err);
    }
    return true;
}

/**
 * from the originalNode split words, lines into a seperate array;
 * @param {*} originalNodes - []
 */
const constructLineAndWordArr = (originalNodes) => {
    try {
        let words = [];
        let lines = [];
        let prevValidSpaceIndex = -1;
        for (let index = 0; index < originalNodes.length; index++) {
            if (originalNodes[index].nodeName !== "SPAN" && originalNodes[index].nodeName !== "BR") { // DIV, OL, UL, LI
                if (originalNodes[index].childNodes.length > 0) {
                    const [newWords, initialNewLines] = constructLineAndWordArr(originalNodes[index].childNodes);
                    let newLines = initialNewLines;
                    if (newWords !== undefined && newWords.length > 0) {
                        words = [...words, ...newWords];
                    }
                    if (originalNodes[index].nodeName === "LI") {
                        newLines = [originalNodes[index]]
                    }
                    if (originalNodes[index].nodeName === "DIV") {
                        newLines = [originalNodes[index]]
                    }
                    if (newLines.length > 0) {
                        lines = [...lines, ...newLines];
                    }
                }
                /** word array construction start */
            } else if (originalNodes[index].dataset.ws === 'true') {
                    prevValidSpaceIndex = index;
                }  else  if (originalNodes[index].nodeName !== "BR") { /** Don't consider BR as word */
                        if (prevValidSpaceIndex === index - 1) {
                            words.push(originalNodes[index]);
                        } else {
                            const lastWord = words[words.length - 1];
                            if (Array.isArray(lastWord) === false) {
                                words[words.length - 1] = [words[words.length - 1]]
                            }
                            words[words.length - 1].push(originalNodes[index])
                        }
                    }

                /** word array construction End */
        }
        return [words, lines];
    } catch (Err) {
        // console.log(Err);
    }
    return true;
}

/**
 * this will parse your dom returns chars, words and lines as a array.
 * @param {*} textElement 
 * @param {*} isGrouped 
 * @param {*} itemData 
 * @param {*} zoomFactor 
 * @returns 
 */
export const getSplittedDom = (textElement, isGrouped, itemData, zoomFactor) => {
    const toReturn = {};
    const textContainerElem = textElement.querySelectorAll(".text-container")[0];
    if (textContainerElem.querySelectorAll(".sp-ln").length <= 0) {
        const itemX = itemData.get("xval");
        const itemY = itemData.get("yval");
        const parentAngle = itemData.get("parentRotation");
        const transFormStyle = textElement.style.transform;
        const textTransformStyle = textContainerElem.style.textTransform;
        textElement.style.transform = `translate(${(itemX * zoomFactor).toFixed(2)}px, ${(itemY * zoomFactor).toFixed(2)}px) rotateZ(0deg)`;

        if (isGrouped)
        textElement.style.transform = `translate(${(itemX * zoomFactor).toFixed(2)}px, ${(itemY * zoomFactor).toFixed(2)}px) rotateZ(${-parentAngle}deg)`;

        let { x, y } = textElement.getBoundingClientRect();
        if (textContainerElem.style.margin) {
            x += parseFloat(textContainerElem.style.margin);
            y += parseFloat(textContainerElem.style.margin);
        }
        const dummyElement = textContainerElem.cloneNode(true);
        dummyElement.style.opacity = "1";
        splitIntoLineAndPosititonDom(textContainerElem.childNodes, dummyElement.childNodes, { parentX: x, parentY: y }, "", { isCapitalize: textTransformStyle === 'capitalize' });
        textContainerElem.innerHTML = dummyElement.innerHTML;
        textElement.style.transform = transFormStyle;
    }
    const [words, lines] = constructLineAndWordArr(textContainerElem.childNodes);
    const chars = textContainerElem.querySelectorAll(".sp-ch")
    toReturn.chars = Array.from(chars);
    toReturn.words = words;
    toReturn.lines = lines;
    return toReturn
}