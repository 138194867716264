import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Backdrop from "../../../common-components/BackDrop";
import {
  ControlButton,
  MobilePlayerControlLeftSection,
  MobilePlayerPopup,
  PlayerControlLeftSection,
  PlayerControlMiddleSection,
  PlayerControlRightSection,
  PlayerControlTextButton,
  PlayerControlsContainer,
  TimeScaleButton,
  TimeScaleContainer,
  TimelineExpand,
} from "./timeline-playercontrols-components";
import Slider from "../../zoom/zoom-slider";
import { RULER_OPTIONS, TIMELINE_HEIGHT, TIMELINE_MODES } from "../timeline-constants";
import { STATIC_PATH, IS_LOCAL } from "../../../constants/config";
import Typography from "../../../common-components/Typography";
import content from "../../../constants/content";
import { font } from "../../../constants/font";
import { secondsToTimestamp } from "../timeline-helper";
import useMobileDetection from "../../../helper/hooks/useMobileDetection";

import {
  setPlayAll,
  setPropertyPanel,
  setExpandPanel,
} from "../../../redux/actions/appUtils";
import { SelectedSliderType } from "../timeline-proptypes";
import CustomTooltipComponent from "../../../common-components/CustomTooltip";
import vmTheme from "../../../constants/theme";


const {
  TIMELINE,
  ADD_SUBTITLE,
  ADD_MEDIA,
  RECORD,
  TIMELINE_ZOOM,
  RESET_TIMELINE_ZOOM,
  TOGGLE_TIMELINE,
  PLAY_FROM_PLAYHEAD,
  PLAYHEAD_FORWARD,
  PLAYHEAD_BACKWARD,
  TOTAL_TIME,
  TIME_AT_PLAYHEAD,
  ADD,
  SPLIT,
} = content;


const PlayerControls = (props) => {
  const dispatch = useDispatch();
  const isPlayAll = useSelector((state) => state.app.get("isPlayAll"));
  const theme = useSelector((state) => state.app.get("theme"));
  const projectDuration = useSelector((state) =>
    state.projectDetails.get("duration")
  );

  const playIcon = isPlayAll ? vmTheme[theme].icons.pauseIcon : vmTheme[theme].icons.playIcon;
  const isTimelineMinimized = props.timelineHeight === TIMELINE_HEIGHT.minimum;

  const isMobile = useMobileDetection();

  /** @type {{ playheadSlider: Slider, rulerSlider: Slider }} */
  const { playheadSlider, rulerSlider } = props;

  const playheadText = useMemo(() => {
    let playheadPosition = playheadSlider.enterStart.position;
    if (playheadSlider.enterStart.trackPosition) {
      playheadPosition = playheadSlider.enterStart.trackPosition;
    }

    const seconds = playheadPosition.step * RULER_OPTIONS.interval;

    return secondsToTimestamp({ seconds, mode: "playercontrol" });
  }, [playheadSlider]);

  const projectDurationText = useMemo(() => {
    let projectDurationPosition = rulerSlider.exitEnd.position;
    if (rulerSlider.exitEnd.trackPosition) {
      projectDurationPosition = rulerSlider.exitEnd.trackPosition;
    }

    const seconds = projectDurationPosition.step * RULER_OPTIONS.interval;
    const timestamp = secondsToTimestamp({ seconds, mode: "playercontrol" });

    return ` / ${timestamp}`;
  }, [rulerSlider]);

  const handlePlayClick = () => {
    if (!isPlayAll) {
      const playheadPosition = playheadSlider.enterStart.position;
      const playheadSeconds = playheadPosition.step * RULER_OPTIONS.interval;
      const isFullPreview = playheadSeconds >= projectDuration;
      dispatch(setPlayAll(true, isFullPreview));
    } else {
      dispatch(setPlayAll(false));
    }
  };

  const handleShortcuts = (shortcutAction) => {
    const { adjustPlayheadStepBy, selectedSliders } = props;
    switch (shortcutAction) {
      case "START_PLAY_PLAYHEAD":
        handlePlayClick();
        break;
      case "MOVE_PLAYHEAD_1S_LEFT":
        // Prevent action if item is selected.
        !selectedSliders.length && adjustPlayheadStepBy(-10);
        break;
      case "MOVE_PLAYHEAD_1S_RIGHT":
        // Prevent action if item is selected.
        !selectedSliders.length && adjustPlayheadStepBy(10);
        break;
      case "MOVE_PLAYHEAD_5S_LEFT":
        // Prevent action if item is selected.
        !selectedSliders.length && adjustPlayheadStepBy(-50);
        break;
      case "MOVE_PLAYHEAD_5S_RIGHT":
        // Prevent action if item is selected.
        !selectedSliders.length && adjustPlayheadStepBy(50);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const { shortcutName } = props;
    if (shortcutName && shortcutName !== "") {
      handleShortcuts(shortcutName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shortcutName]);

  const openUploadPanel = () => {
    dispatch(setPropertyPanel(content.UPLOAD));
    dispatch(setExpandPanel(true));
  };

  const { min, maxScaleDuration } = RULER_OPTIONS.timeScale;
  let { max } = RULER_OPTIONS.timeScale;
  if (projectDuration <= maxScaleDuration) {
    max = RULER_OPTIONS.timeScale.default;
  }

  const [isSectionVisible, setSectionVisibility] = useState(false);

  const handleDropdownClick = () => {
      const { onDropdownClick } = props;
      if (onDropdownClick) {
          onDropdownClick(); // Invoke the callback function passed from the parent
      }
  };

  const toggleSection = () => {
      const { setOuterLayerVisible } = props;
      setSectionVisibility(!isSectionVisible);
      setOuterLayerVisible(isSectionVisible);
      handleDropdownClick();
  };

  const toggleSubtitleMode = (event) => {
    if (isMobile) {
      toggleSection();
    }
    props.toggleSubtitleMode(event);
  };

  const leftSectionButtons = [];
  if (props.timelineMode === TIMELINE_MODES.MAIN) {
    leftSectionButtons.push(
      <PlayerControlTextButton
        key="add-media"
        className={isPlayAll ? "pc--is-disabled" : ""}
        onClick={openUploadPanel}
      >
        <img
          draggable="false"
          alt="add media"
          src={`${STATIC_PATH}${vmTheme[theme].icons.addMedia}`}
        />
        <Typography
          as="span"
          color={vmTheme[theme].primaryColor}
          content={ADD_MEDIA}
          font={font.normalMini}
          cursor={"pointer"}
          enableTrim={true}
          maxWidth={"75px"}
        />
      </PlayerControlTextButton>
    );
    if (IS_LOCAL) {
      leftSectionButtons.push(
        <PlayerControlTextButton
          key="record"
          className={
            isPlayAll
              ? "pc--is-disabled"
              : "create_button chrome-vmaker-ext-btn"
          }
          onClick={() => {
            if (document.getElementsByName("vmaker-container").length) {
              window.location = "Vmaker://open";
            }
          }}
        >
          <img
            draggable="false"
            alt="record"
            src={`${STATIC_PATH}${vmTheme[theme].icons.recordIcon}`}
          />
          <Typography
            as="span"
            color={vmTheme[theme].primaryColor}
            content={RECORD}
            font={font.normalMini}
            cursor={"pointer"}
            enableTrim={true}
            maxWidth={"75px"}
          />
        </PlayerControlTextButton>
      );
    }
    leftSectionButtons.push(
      <PlayerControlTextButton
        key="split"
        className={isPlayAll ? "pc--is-disabled" : ""}
        onClick={props.splitHandler}
      >
        <img
          draggable="false"
          alt="split"
          src={`${STATIC_PATH}${vmTheme[theme].icons.splitIcon}`}
        />
        <Typography
          as="span"
          color={vmTheme[theme].primaryColor}
          content={SPLIT}
          font={font.normalMini}
          cursor={"pointer"}
          enableTrim={true}
          maxWidth={"75px"}
        />
      </PlayerControlTextButton>
    );
  } else if (props.timelineMode === TIMELINE_MODES.SUBTITLE) {
    leftSectionButtons.push(
      <PlayerControlTextButton
        key="back-timeline"
        className="timeline-btn-highlight"
        onClick={toggleSubtitleMode}
      >
        <img
          draggable="false"
          alt="back to timeline"
          src={`${STATIC_PATH}${vmTheme[theme].icons.timelineBack}`}
        />
        <Typography
          as="span"
          color={vmTheme[theme].primaryColor}
          content={TIMELINE}
          font={font.normalMini}
          cursor={"pointer"}
          enableTrim={true}
          maxWidth={"75px"}
        />
      </PlayerControlTextButton>
    );
    leftSectionButtons.push(
      <PlayerControlTextButton
        key="add-subtitle"
        className={isPlayAll ? "pc--is-disabled" : ""}
        onClick={props.addSubtitle}
      >
        <img
          draggable="false"
          alt="split"
          src={`${STATIC_PATH}${vmTheme[theme].icons.addMedia}`}
        />
        <Typography
          as="span"
          color={vmTheme[theme].primaryColor}
          content={ADD_SUBTITLE}
          font={font.normalMini}
          cursor={"pointer"}
          enableTrim={true}
          maxWidth={"86px"}
        />
      </PlayerControlTextButton>
    );
    leftSectionButtons.push(
      <PlayerControlTextButton
        key="split"
        className={isPlayAll ? "pc--is-disabled" : ""}
        onClick={props.splitHandler}
      >
        <img
          draggable="false"
          alt="split"
          src={`${STATIC_PATH}${vmTheme[theme].icons.splitIcon}`}
        />
        <Typography
          as="span"
          color={vmTheme[theme].primaryColor}
          content={SPLIT}
          font={font.normalMini}
          cursor={"pointer"}
          enableTrim={true}
          maxWidth={"75px"}
        />
      </PlayerControlTextButton>
    );
  }

  return (
    <PlayerControlsContainer isSectionVisible={isSectionVisible}>
      {props.isShowPlayControls && (
        <>
          {isMobile && (
            <>
              <img src={`${STATIC_PATH}add.svg`} height="24px" width="24px" cursor={"pointer"} onClick={() => {toggleSection();}} alt="addIcon"/>
              {isSectionVisible && (
                <>
                  <Backdrop background="#00000061 0% 0% no-repeat padding-box" zIndex={98} />
                  <MobilePlayerControlLeftSection>
                    <MobilePlayerPopup>
                      <Typography
                        content={ADD}
                        color={vmTheme[theme].panelPrimaryColor}
                        padding="4px 4px 4px 0px"
                        width="100%"
                        align="center"
                        display="inline-block"
                        font="normal normal 600 16px/14px Poppins;"
                      />
                      <img src={`${STATIC_PATH}cross.svg`} height="20px" width="20px" cursor={"pointer"} onClick={toggleSection} alt="cross-icon" className="sm-close-icon"/>
                      {leftSectionButtons}
                    </MobilePlayerPopup>
                  </MobilePlayerControlLeftSection>
                </>
              )}
            </>
          )}
          {!isMobile && (
            <PlayerControlLeftSection>
              {leftSectionButtons}
            </PlayerControlLeftSection>
          )}
          <PlayerControlMiddleSection>
            <ControlButton
              className={isPlayAll ? "pc--is-disabled" : ""}
              onClick={() => props.onSeekClick({ direction: "left" })}
            >
              <CustomTooltipComponent
                tooltipId={"player-backward-tooltip"}
                dataTooltip={PLAYHEAD_BACKWARD}
                tooltipPosition="top"
              >
                <img
                  data-tooltip-id={"player-backward-tooltip"}
                  draggable="false"
                  alt="backward"
                  src={`${STATIC_PATH}${vmTheme[theme].icons.playerBackward}`}
                />
              </CustomTooltipComponent>
            </ControlButton>
            <CustomTooltipComponent
              tooltipId={"play/pause-tooltip"}
              dataTooltip={PLAY_FROM_PLAYHEAD}
              tooltipPosition="top"
            >
              <ControlButton
                data-tooltip-id={"play/pause-tooltip"}
                onClick={handlePlayClick}
              >
                <img
                  draggable="false"
                  alt="play/pause"
                  src={`${STATIC_PATH}${playIcon}-player.svg`}

                />
              </ControlButton>
            </CustomTooltipComponent>
            <div className="pc--timestamp">
              <span>
                <CustomTooltipComponent
                  tooltipId={"duration-tooltip"}
                  dataTooltip={TIME_AT_PLAYHEAD}
                  tooltipPosition="top"
                >
                  <Typography
                    as="span"
                    data-tooltip-id={"duration-tooltip"}
                    color={vmTheme[theme].primaryColor}
                    innerContent={playheadText}
                    font={font.normalMini}
                  />
                </CustomTooltipComponent>
                <CustomTooltipComponent
                  tooltipId={"duration-1-tooltip"}
                  dataTooltip={TOTAL_TIME}
                  tooltipPosition="top"
                >
                  <Typography
                    as="span"
                    data-tooltip-id={"duration-1-tooltip"}
                    color={vmTheme[theme].secondaryColor}
                    innerContent={projectDurationText}
                    font={font.normalMini}
                  />
                </CustomTooltipComponent>
              </span>
            </div>
            <ControlButton
              className={isPlayAll ? "pc--is-disabled" : ""}
              onClick={() => props.onSeekClick({ direction: "right" })}
            >
              <CustomTooltipComponent
                tooltipId={"player-forward-tooltip"}
                dataTooltip={PLAYHEAD_FORWARD}
                tooltipPosition="top"
              >
                <img
                  data-tooltip-id={"player-forward-tooltip"}
                  draggable="false"
                  alt="forward"
                  src={`${STATIC_PATH}${vmTheme[theme].icons.playerForward}`}
                />
              </CustomTooltipComponent>
            </ControlButton>
          </PlayerControlMiddleSection>
          <PlayerControlRightSection>
            <TimeScaleContainer>
              <TimeScaleButton
                className={`pc--scale-minus ${isPlayAll ? "pc--is-disabled" : ""
                  }`}
                onClick={() =>
                  props.onTimeScaleChange(
                    null,
                    props.timeScale - RULER_OPTIONS.timeScale.buttonStep
                  )
                }
              >
                <img
                  draggable="false"
                  alt="zoom in timeline"
                  src={`${STATIC_PATH}${vmTheme[theme].icons.timeScaleMinus}`}
                />
              </TimeScaleButton>
              <CustomTooltipComponent
                tooltipId={"zoom-slider-tooltip"}
                dataTooltip={TIMELINE_ZOOM}
                tooltipPosition="top"
              >
                <Slider
                  tooltipId={"zoom-slider-tooltip"}
                  className={isPlayAll ? "pc--is-disabled" : ""}
                  width={"84px"}
                  border={`1px solid ${vmTheme[theme].timelineSliderBg}`}
                  borderRadius={"2px"}
                  background={vmTheme[theme].timelineSliderBg}
                  margin={"0"}
                  thumb={{
                    background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                    border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                    height: "14px",
                    width: "14px",
                    hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                    hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                  }}
                  onChangeSliderValue={props.onTimeScaleChange}
                  value={props.timeScale}
                  height="4px"
                  min={min}
                  max={max}
                  inputMin={min}
                  inputMax={max}
                  step={RULER_OPTIONS.timeScale.step}
                  progressBackground={vmTheme[theme].sliderDefaultValue}
                  isChangeBg={true}
                />
              </CustomTooltipComponent>
              <TimeScaleButton
                className={`pc--scale-plus ${isPlayAll ? "pc--is-disabled" : ""
                  }`}
                onClick={() =>
                  props.onTimeScaleChange(
                    null,
                    props.timeScale + RULER_OPTIONS.timeScale.buttonStep
                  )
                }
              >
                <img
                  draggable="false"
                  alt="zoom out timeline"
                  src={`${STATIC_PATH}${vmTheme[theme].icons.timeScalePlus}`}
                />
              </TimeScaleButton>
              <CustomTooltipComponent
                tooltipId={"zoom-reset-tooltip"}
                dataTooltip={RESET_TIMELINE_ZOOM}
                tooltipPosition="top"
              >
                <TimeScaleButton
                  data-tooltip-id={"zoom-reset-tooltip"}
                  className={`pc--scale-reset ${isPlayAll ? "pc--is-disabled" : ""
                    } ${props.timeScale ? "allowed" : "not-allowed"}`}
                  onClick={() => {
                    if (props.timeScale) {
                      props.onTimeScaleChange(null, 0);
                    }
                  }}
                >
                  <img
                    draggable="false"
                    alt="reset timeline scale"
                    src={`${STATIC_PATH}${vmTheme[theme].icons.timeScaleReset}`}
                  />
                </TimeScaleButton>
              </CustomTooltipComponent>
            </TimeScaleContainer>
            <CustomTooltipComponent
              tooltipId={"toggle-timeline-tooltip"}
              dataTooltip={TOGGLE_TIMELINE}
              tooltipPosition="top-right"
            >
              <TimelineExpand
                data-tooltip-id={"toggle-timeline-tooltip"}
                className={isPlayAll ? "pc--is-disabled" : ""}
                $type={isTimelineMinimized ? "expand" : ""}
                onClick={() =>
                  props.stopTimelineResize({
                    clickType: isTimelineMinimized ? "expand" : "minimize",
                  })
                }
              >
                <img
                  draggable="false"
                  alt="toggle timeline height"
                  src={`${STATIC_PATH}${vmTheme[theme].icons.timelineMinimize}`}
                />
              </TimelineExpand>
            </CustomTooltipComponent>
          </PlayerControlRightSection>
        </>
      )}
    </PlayerControlsContainer>
  );
};

PlayerControls.propTypes = {
  timelineMode: PropTypes.number,
  toggleSubtitleMode: PropTypes.func,
  timeScale: PropTypes.number,
  onTimeScaleChange: PropTypes.func,
  timelineHeight: PropTypes.number,
  stopTimelineResize: PropTypes.func,
  playheadSlider: PropTypes.object,
  rulerSlider: PropTypes.object,
  adjustPlayheadStepBy: PropTypes.func,
  shortcutName: PropTypes.string,
  selectedSliders: PropTypes.arrayOf(SelectedSliderType),
  onSeekClick: PropTypes.func,
  isShowPlayControls: PropTypes.bool,
  splitHandler: PropTypes.func,
  addSubtitle: PropTypes.func,
  onDropdownClick:PropTypes.func,
  setOuterLayerVisible: PropTypes.func,
};

export default PlayerControls;
