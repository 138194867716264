import styled, { css } from "styled-components";
import { font } from "../../constants/font";
import { STATIC_PATH } from "../../constants/config";

export const Container = styled.div.attrs((props) => ({
  style: {
    "--sh-polarColor": props.theme.polarColor,
    "--sh-rgbaLightBlack2": props.theme.rgbaLightBlack2,
  },
}))`
  height: 352px;
  width: 1152px;
  margin: 24px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--sh-polarColor) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px var(--sh--rgbaLightBlack2);
  border-radius: 16px;
  gap: 24px;
  padding: 24px;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 95%;
    height: auto;
    flex-direction: column;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    width: 95%;
    height: auto;
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  position: relative;
  overflow: hidden;
  width: 540px;
  height: 304px;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const PreviewContainer = styled.div`
  position: absolute;
  height: 304px;
  video {
    position: absolute;
    height: 304px;
    display: none;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  height: 304px;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;

export const Loader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Duration = styled.div`
  position: absolute;
  bottom: 8px;
  left: ${(props) => props.left || "8px"};
  z-index: 2;
  width: 47px;
  height: 22px;
  background: #1b1f21 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${font.normalMicroLarge_18};
  letter-spacing: 0px;
  color: ${(props) => props.theme.polarColor};
`;

export const Wrapper = styled.div`
  height: calc(100vh - 96px);
  background: ${(props) => props.theme.subtitleBgColor};
  overflow: scroll;
`;

export const PlayButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 8px;
  width: 100px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

export const ToolbarHeader = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 1152px;
  justify-content: end;
  margin: 0px auto;
  .subtitle-style {
    position: relative;
    border-radius: 8px;
    padding: 10px 24px 10px 34px;
    &:hover {
      background-color: ${(props) => props.theme.btnBgLightColor};
    }
    &:after {
      content: url(${STATIC_PATH}right-arrow.svg);
      position: absolute;
      right: 7px;
      top: 6px;
      transform: rotate(90deg);
    }
    &::before {
      content: url(${STATIC_PATH}sh-subtitle.svg);
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 95%;
  }
  @media screen and (max-width: 767px) {
    width: 95%;
  }
`;

export const ResizeDropdown = styled.ul`
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px ${(props) => props.theme.rgbaLightBlack2};
  border: 1px solid ${(props) => props.theme.resizeBorder};
  border-radius: 8px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0px;
  top: 25px;
  @media (max-width: 767px) {
    right: 0px;
    width: fit-content;
  }
`;

export const ResizeDropdownLi = styled.li`
  list-style: none;
  padding: 2px 0px 2px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  label {
    color: ${(props) =>
      props.isHovered
        ? props.theme.resizeHoverColor
        : props.theme.primaryColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.btnBgLightColor};
    color: ${(props) => props.theme.resizeHoverColor};
    svg {
      * {
        fill: ${(props) => props.theme.resizeHoverColor};
        stroke: ${(props) => props.theme.resizeHoverColor};
      }
    }
    label {
      color: ${(props) => props.theme.resizeHoverColor};
      background-color: ${(props) => props.theme.btnBgLightColor};
    }
  }
`;

export const IconSVG = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isSelected &&
    css`
      & #resizeContainer {
        fill: ${(props) => props.theme.svgIconLightColor};
        stroke: ${(props) => props.theme.svgIconColor};
      }
      & text {
        fill: ${(props) => props.theme.svgIconColor};
      }
    `}
  &:hover {
    & #resizeContainer {
      fill: ${(props) => props.theme.svgIconLightColor};
      stroke: ${(props) => props.theme.svgIconColor};
    }
    & text {
      fill: ${(props) => props.theme.svgIconColor};
    }
  }
`;

export const ToolbarWrap = styled.div`
  position: relative;
  border-radius: 6px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: isActive;
  background-color: ${(props) => props.isActive && props.theme.liBgColor};
  .resizeDropdown {
    text-transform: capitalize;
    position: relative;
    border-radius: 6px;
    &:after {
      content: url(${STATIC_PATH}right-arrow.svg);
      position: absolute;
      right: 0px;
      top: 0;
      transform: rotate(90deg);
    }
    &:before {
      content: url(${STATIC_PATH}selectedIcon);
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
  .resizeMenu {
    display: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.liBgColor};
    transform: scale(1.02);
    .resizeMenu {
      display: flex;
      flex-direction: column;
    }
  }
  a {
    text-decoration: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  gap: 18px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  height: 48px;
`;
Footer.displayName = "Shorts Footer";

export const SocialMedia = styled.ul`
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  gap: 4px;
`;

export const Links = styled.li.attrs((props) => ({
  style: {
    "--backgroundColor": props.theme.subtitleBgColor,
  },
}))`
  background: var(--backgroundColor) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  img {
    padding: 0px 6px;
    cursor: pointer;
    transition: all 1s;
    &:hover {
      transform: rotate(360deg);
    }
  }
`;

export const ContentHeader = styled.div.attrs((props) => ({
  style: {
    "--shortsBtnBgColor": props.theme.shortsBtnBgColor,
    "--polarColor": props.theme.polarColor,
    "--shortsBtnHoverColor": props.theme.shortsBtnHoverColor,
  },
}))`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  .edit-project {
    position: relative;
    padding-left: 8px;
    background: var(--polarColor);
    &:hover {
      background: var(--shortsBtnBgColor);
    }
    &::before {
      content: url(${STATIC_PATH}vm-cut.svg);
      position: absolute;
      left: 6px;
      top: 6.5px;
    }
  }
  .export-project {
    position: relative;
    padding-left: 8px;
    &:hover {
      background: var(--shortsBtnHoverColor);
    }
    &::before {
      content: url(${STATIC_PATH}vm-upload.svg);
      position: absolute;
      left: 7px;
      top: 7px;
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const ContentBody = styled.div`
  height: 172px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen {
    max-height: 172px;
    height: 100%;
  }
`;

export const Icon = styled.img``;
