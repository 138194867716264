import styled from "styled-components";

export const DroppableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  * {
    pointer-events: none; // using pointer events so that we can detect mouse up in LibraryComponent
  }
`;
DroppableContainer.displayName = "DroppableContainer";

export const SVG = styled.svg`
  overflow: visible;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
SVG.displayName = "SVG";

export const VIGNETTE = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  background-image: radial-gradient(
    circle farthest-side at center center,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, var(--vignette)) 130%
  );
`;
VIGNETTE.displayName = "VIGNETTE";
