/* eslint-disable no-continue, operator-assignment */

import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import {
  RulerContainer,
  UpgradeIconContainer,
} from "./timeline-ruler-components";
import { RULER_OPTIONS, SLIDER_TYPES } from "../timeline-constants";
import {
  getTrackPosition,
  plotToPath,
  scaleWithinRange,
  secondsToStep,
  secondsToTimestamp,
} from "../timeline-helper";
import {
  SelectedSliderType,
  SliderType,
  StepperType,
} from "../timeline-proptypes";
import { STATIC_PATH } from "../../../constants/config";
import { TimelineScrollContext } from "../contexts/timeline-scroll-context";

/** NOTE: mutates passed params */
const restrictViewBox = (viewBox, plot) => {
  if (plot.x < viewBox.x) {
    plot.width -= viewBox.x - plot.x;
    plot.x = viewBox.x;
  }
  if (plot.width > viewBox.width) {
    plot.width = viewBox.width;
  }
  return plot;
};

const UpgradeIcon = (props) => {
  const { projectTimeLimit, stepper } = props;
  const timelineScrollPos = useContext(TimelineScrollContext);

  const iconPos = useMemo(() => {
    const ICON_OFFSET = { x: 2, y: 2 };
    const ICON_WIDTH = 18;

    const upgradeStepStart = secondsToStep({
      seconds: projectTimeLimit,
      stepper,
    });
    const upgradeStepEnd = stepper.duration / RULER_OPTIONS.interval;
    const upgradeIndicationPlot = {
      x: RULER_OPTIONS.paddingLeft + upgradeStepStart.step * stepper.stepSizePx,
      y: 0,
      width: (upgradeStepEnd - upgradeStepStart.step) * stepper.stepSizePx,
      height: RULER_OPTIONS.height,
    };

    const iconPos = {
      x: upgradeStepStart.x + ICON_OFFSET.x,
      y: ICON_OFFSET.y,
    };

    if (iconPos.x < timelineScrollPos.x + ICON_OFFSET.x) {
      iconPos.x = timelineScrollPos.x + ICON_OFFSET.x;
    }

    if (
      iconPos.x + ICON_WIDTH + ICON_OFFSET.x >
      upgradeIndicationPlot.x + upgradeIndicationPlot.width
    ) {
      iconPos.x =
        upgradeIndicationPlot.x +
        upgradeIndicationPlot.width -
        (ICON_WIDTH + ICON_OFFSET.x);
    }

    return iconPos;
  }, [projectTimeLimit, stepper, timelineScrollPos]);

  return (
    <UpgradeIconContainer $x={iconPos.x} $y={iconPos.y}>
      <img
        alt="upgrade-indication"
        draggable="false"
        src={`${STATIC_PATH}upgrade-indication.svg`}
      />
    </UpgradeIconContainer>
  );
};
UpgradeIcon.propTypes = {
  stepper: StepperType,
  projectTimeLimit: PropTypes.number,
};

const Ruler = (props) => {
  const {
    timelinePlotWidth,
    stepper,
    placePlayhead,
    projectTimeLimit,
    rulerScrollBy,
    selectedSliders,
    sliders,
  } = props;

  const theme = useTheme();
  const {
    RULER_COLOR,
    RULER_EXCESS_COLOR,
    RULER_SEL_HIGHLIGHT,
    UPGRADE_INDICATION_PRIMARY_COLOR,
    UPGRADE_INDICATION_SECONDARY_COLOR,
  } = theme;

  const timelineScroll = useContext(TimelineScrollContext);
  const timelineScrollX = timelineScroll.x;

  const steps = stepper.steps + stepper.videoExcessSteps;
  const { stepSizePx, duration, excessDuration, videoExcessSteps } = stepper;
  const rulerWidth =
    RULER_OPTIONS.paddingLeft +
    RULER_OPTIONS.paddingRight +
    steps * stepper.stepSizePx;

  const viewBox = useMemo(() => {
    const bufferWidth = timelinePlotWidth * 0.2;
    return {
      x: timelineScrollX - bufferWidth,
      y: 0,
      width: timelinePlotWidth + bufferWidth * 2,
      height: RULER_OPTIONS.height,
    };
  }, [timelinePlotWidth, timelineScrollX]);

  const excessPath = useMemo(() => {
    // no need to ceil here as we need to highlight excess duration accurately
    const actualSteps = duration / RULER_OPTIONS.interval;
    const excessSteps =
      excessDuration / RULER_OPTIONS.interval + videoExcessSteps;

    const excessPlot = restrictViewBox(viewBox, {
      x: RULER_OPTIONS.paddingLeft + actualSteps * stepSizePx,
      y: RULER_OPTIONS.height - RULER_OPTIONS.excessHeight,
      width: excessSteps * stepSizePx + RULER_OPTIONS.paddingRight,
      height: RULER_OPTIONS.excessHeight,
    });
    if (
      excessPlot.x > viewBox.x + viewBox.width ||
      excessPlot.x + excessPlot.width < viewBox.x
    ) {
      // no need to render path outside viewbox
      return null;
    }

    // create path to indicate excess duration
    const excessStepsPath = plotToPath(excessPlot);
    return <path d={excessStepsPath} fill={RULER_EXCESS_COLOR} />;
  }, [
    stepSizePx,
    duration,
    excessDuration,
    videoExcessSteps,
    viewBox,
    RULER_EXCESS_COLOR,
  ]);

  const upgradePath = useMemo(() => {
    // no need to ceil here as we need to highlight excess duration accurately
    const actualSteps = duration / RULER_OPTIONS.interval;

    const defs = [];
    let upgrageIndication = null;
    if (Number.isFinite(projectTimeLimit) && projectTimeLimit >= 0) {
      const upgradeStepStart = secondsToStep({
        seconds: projectTimeLimit,
        stepper,
      });
      if (upgradeStepStart.step < actualSteps) {
        const upgradePlot = restrictViewBox(viewBox, {
          x:
            RULER_OPTIONS.paddingLeft +
            upgradeStepStart.step * stepper.stepSizePx,
          y: 0,
          width: (actualSteps - upgradeStepStart.step) * stepper.stepSizePx,
          height: RULER_OPTIONS.height,
        });
        if (
          upgradePlot.x > viewBox.x + viewBox.width ||
          upgradePlot.x + upgradePlot.width < viewBox.x
        ) {
          // no need to render path outside viewbox
          return { defs: [], upgrageIndication: null };
        }

        const upgradeIndicationPath = plotToPath(upgradePlot);
        const patternId = "timeline-ruler--upgrade-indication-pattern";
        defs.push(
          <pattern
            key={patternId}
            id={patternId}
            width="4"
            height={RULER_OPTIONS.height}
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(30)"
          >
            <rect
              x="0"
              y="0"
              width="1"
              height={RULER_OPTIONS.height}
              fill={UPGRADE_INDICATION_PRIMARY_COLOR}
            />
            <rect
              x="2"
              y="0"
              width="3"
              height={RULER_OPTIONS.height}
              fill={UPGRADE_INDICATION_SECONDARY_COLOR}
            />
          </pattern>
        );
        upgrageIndication = (
          <>
            <path
              key={"uip-bg"}
              d={upgradeIndicationPath}
              fill={UPGRADE_INDICATION_SECONDARY_COLOR}
            />
            <path
              key={"uip"}
              d={upgradeIndicationPath}
              fill={`url(#${patternId})`}
            />
          </>
        );
      }
    }

    return { defs, upgrageIndication };
  }, [
    viewBox,
    duration,
    projectTimeLimit,
    stepper,
    UPGRADE_INDICATION_PRIMARY_COLOR,
    UPGRADE_INDICATION_SECONDARY_COLOR,
  ]);

  const selectionHighlightPath = useMemo(() => {
    const reqPlots = [];
    for (let sidx = 0; sidx < selectedSliders.length; sidx += 1) {
      const selection = selectedSliders[sidx];
      const slider = sliders[selection.sliderId];
      if (
        slider &&
        slider.enterStart &&
        slider.exitEnd &&
        (slider.sliderType === SLIDER_TYPES.AUDIO ||
          slider.sliderType === SLIDER_TYPES.GAP ||
          slider.sliderType === SLIDER_TYPES.OBJECT ||
          slider.sliderType === SLIDER_TYPES.SUBTITLE ||
          slider.sliderType === SLIDER_TYPES.VIDEO)
      ) {
        const { enterStart, exitEnd } = getTrackPosition({
          slider,
          thumbIds: ["enterStart", "exitEnd"],
        });
        const plot = restrictViewBox(viewBox, {
          x: enterStart.x,
          y: viewBox.y,
          width: exitEnd.x - enterStart.x,
          height: viewBox.height,
        });
        if (
          !(
            plot.x > viewBox.x + viewBox.width ||
            plot.x + plot.width < viewBox.x
          )
        ) {
          // render only visible path
          reqPlots.push(plot);
        }
      }
    }
    reqPlots.sort((p1, p2) => p1.x - p2.x);

    let prevPlot = reqPlots[0];
    let highlightPath = "";
    for (let ridx = 1; ridx < reqPlots.length; ridx += 1) {
      const curPlot = reqPlots[ridx];
      if (prevPlot && curPlot.x < prevPlot.x + prevPlot.width) {
        // merge overlapping plots
        const curX2 = curPlot.x + curPlot.width;
        prevPlot = { ...prevPlot, width: curX2 - prevPlot.x };
        continue;
      }

      if (prevPlot) {
        highlightPath = `${highlightPath} ${plotToPath(prevPlot)}`;
      }

      prevPlot = curPlot;
    }
    if (prevPlot) {
      highlightPath = `${highlightPath} ${plotToPath(prevPlot)}`;
    }
    if (highlightPath.trim()) {
      return <path d={highlightPath} fill={RULER_SEL_HIGHLIGHT} />;
    }
    return null;
  }, [viewBox, selectedSliders, sliders, RULER_SEL_HIGHLIGHT]);

  // ruler svg construction
  const ruler = useMemo(() => {
    const baseline = {
      x: 0,
      y: RULER_OPTIONS.height - 1,
      width: rulerWidth,
      height: RULER_OPTIONS.markDimension.baseline.height,
    };
    let path = plotToPath(baseline);
    const text = [];

    let middleStep = RULER_OPTIONS.middleStep.markAt;
    const totalStepSizePx = stepSizePx * steps;
    let minumumMiddleStepPx = RULER_OPTIONS.middleStep.minimum;
    if (minumumMiddleStepPx > totalStepSizePx / 2) {
      minumumMiddleStepPx = totalStepSizePx / 2;
    }
    if (middleStep * stepSizePx < minumumMiddleStepPx) {
      const stepAtMininum = minumumMiddleStepPx / stepSizePx;
      // round to nearest multiple of initially chosen step position
      middleStep = Math.ceil(stepAtMininum / middleStep) * middleStep;
    }

    let startStep = scaleWithinRange({
      fromRange: {
        start: RULER_OPTIONS.paddingLeft,
        end: RULER_OPTIONS.paddingLeft + totalStepSizePx,
      },
      toRange: {
        start: 0,
        end: steps,
      },
      num: viewBox.x,
    });
    startStep = Math.floor(startStep / middleStep) * middleStep;
    if (startStep < 0) {
      startStep = 0;
    }

    let endStep = scaleWithinRange({
      fromRange: {
        start: RULER_OPTIONS.paddingLeft,
        end: RULER_OPTIONS.paddingLeft + totalStepSizePx,
      },
      toRange: {
        start: 0,
        end: steps,
      },
      num: viewBox.x + viewBox.width,
    });
    endStep = Math.ceil(endStep / middleStep) * middleStep;

    for (
      let stepIndex = startStep;
      stepIndex <= steps && stepIndex <= endStep;
      stepIndex += middleStep
    ) {
      let adjustedStepIndex = stepIndex;
      let markType = "middle";
      let isLeft = false;
      if (stepIndex % (middleStep * 2) === 0) {
        markType = "main";
      }

      if (
        stepIndex === steps || // current step mark is the last step
        steps - stepIndex < middleStep // current step mark is the last visible mark
      ) {
        isLeft = true;
        markType = "main";
        adjustedStepIndex = steps;
      }

      const mark = {
        x: RULER_OPTIONS.paddingLeft + adjustedStepIndex * stepSizePx,
        y: RULER_OPTIONS.height - RULER_OPTIONS.markDimension[markType].height,
        width: RULER_OPTIONS.markDimension[markType].width,
        height: RULER_OPTIONS.markDimension[markType].height,
      };

      path = `${path} ${plotToPath(mark)}`;

      if (markType === "main") {
        const timestamp = secondsToTimestamp({
          mode: "ruler",
          seconds: adjustedStepIndex * RULER_OPTIONS.interval,
        });
        let { offsetX } = RULER_OPTIONS.timeLabel;
        if (
          isLeft || // current mark indicates end of actual duration
          stepIndex === steps || // this is the last step
          stepIndex + middleStep * 2 > steps // there is no way a main mark will render after this
        ) {
          isLeft = true;
          offsetX = -offsetX;
        }
        text.push(
          <text
            key={stepIndex}
            className="ruler--timestamp"
            x={mark.x + offsetX}
            y={RULER_OPTIONS.height - RULER_OPTIONS.timeLabel.lineHeight}
            textAnchor={isLeft ? "end" : "start"}
            dominantBaseline="hanging"
          >
            {timestamp}
          </text>
        );
      }
    }

    return {
      text,
      path: <path d={path} fill={RULER_COLOR} />,
    };
  }, [viewBox, stepSizePx, rulerWidth, steps, RULER_COLOR]);

  const rulerSVG = useMemo(() => {
    return (
      <svg
        style={{
          transform: `translateX(${viewBox.x}px)`,
        }}
        viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
      >
        {upgradePath.defs}
        {excessPath}
        {selectionHighlightPath}
        {upgradePath.upgrageIndication}
        {ruler.text}
        {ruler.path}
      </svg>
    );
  }, [viewBox, upgradePath, excessPath, selectionHighlightPath, ruler]);

  /**
   * @param {React.WheelEvent} e
   */
  const onRulerWheel = (e) => {
    const isHorizontalScroll = Boolean(e.deltaX);
    if (!isHorizontalScroll) {
      const { deltaY } = e;
      rulerScrollBy({ left: deltaY, behaviour: "smooth" });
    }
  };

  return (
    <RulerContainer
      style={{ width: `${rulerWidth}px` }}
      onClick={placePlayhead}
      onWheel={onRulerWheel}
    >
      {rulerSVG}
      {Number.isFinite(projectTimeLimit) &&
        projectTimeLimit >= 0 &&
        duration > projectTimeLimit && (
          <UpgradeIcon projectTimeLimit={projectTimeLimit} stepper={stepper} />
        )}
    </RulerContainer>
  );
};
Ruler.propTypes = {
  stepper: StepperType,
  selectedSliders: PropTypes.arrayOf(SelectedSliderType).isRequired,
  sliders: PropTypes.objectOf(SliderType).isRequired,
  placePlayhead: PropTypes.func,
  projectTimeLimit: PropTypes.number,
  timelinePlotWidth: PropTypes.number,
  rulerScrollBy: PropTypes.func.isRequired,
};
Ruler.defaultProps = {
  stepper: {
    steps: 1,
    stepSizePx: 0,
    timeScale: 0,
    excessDuration: 0,
    duration: 0,
  },
  projectTimeLimit: -1,
};

export default Ruler;
