import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { font } from "../constants/font";
import Typography from "./Typography";
import { STATIC_PATH } from "../constants/config";

const DropDown = styled.div`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background: #ffffff;
  border: ${(props) => props.border || "1px solid #e4eaeb"};
  border-radius: ${(props) => props.borderRadius || "6px"};
  margin: ${(props) => props.margin || "8px 0px 12px"};
  position: ${(props) => props.position || "relative"};
  height: 40px;
  transition: all 0.3s ease 0s;
  ${(props) =>
    props.isOpen &&
    css`
      border: ${(props) => props.borderActive || "1px solid #b0b6b8"};
    `}
  ${(props) =>
    props.hoverScale &&
    !props.isOpen &&
    css`
      &:hover {
        transform: scale(${props.hoverScale || 1.05});
      }
    `}
  ${(props) => props.customStyles && css`${props.customStyles}`}
`;

const OptionWrapper = styled.ul`
  list-style: none;
  margin-top: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #dddddd;
  width: ${(props) => props.optionWidth || "262px"};
  padding: 5px 0px 5px 0px;
  position: absolute;
  z-index: 9;
  top: ${(props) => props.top + 45}px;
  border-radius: ${(props) => props.borderRadius || "6px"};
  right: ${(props) => props.right || ""};
`;

const Option = styled.div`
  color: #46445d;
  width: 100%;
  display: inline-block;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  padding: 12px 10px;
  cursor: pointer;
  font: ${font.normalBase};
  position: relative;
  &:hover {
    background: #e6e6e6;
    &:after {
      content: "";
      position: absolute;
      right: 15px;
      top: 8px;
      width: 4px;
      height: 12px;
      border: solid #84bf10;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

const Selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #46445d;
  padding: 10px;
  font: ${font.normalBase};
  position: relative;
  cursor: pointer;
  height: 100%;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.zIndex};
  /* pointer-events: none; */
`;

const Label = styled.label`
  display: block;
  font: ${font.normal_14};
  margin: ${(props) => props.labelMargin || "0 0 10px"};
  top: -25px;
  color: ${(props) => props.labelColor};
`;

const RequiredStar = styled.span`
  color: #ff0000;
  margin-left: 4px;
  font: ${font.normalBase_16};
`;

const DropdownLabel = ({
  options,
  width,
  height,
  right,
  border,
  labelEnable,
  labelText,
  borderActive,
  margin,
  autoEnable,
  optionWidth,
  fontStyle,
  customSize,
  onChange,
  enableInputBox,
  position,
  labelColor,
  borderRadius,
  beforeImage,
  hoverScale = 0,
  labelTextEnable,
  labelMargin,
  showRequiredStar,
  customStyles
}) => {
  const optionRef = useRef(null);
  const [top, setTop] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: labelText,
    value: options[0].value,
    subtext: options[0].subtext,
  });
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    customSize &&
      setSelectedOption({
        label: customSize,
        value: customSize,
        subtext: customSize,
      });
  }, [customSize]);

  useEffect(() => {
    if (optionRef.current) {
      const clientRect = optionRef.current.getBoundingClientRect();
      setTop(clientRect.top - 12);
    }
  }, [isOpen]);

  const onOptionClick = useCallback((option) => {
    onChange(option.value);
    setSelectedOption(option);
  });

  const onInputChange = useCallback((e) => {
    const { value } = e.target;
    onOptionClick({
      label: value,
      value: value !== "" && Number(value),
    });
  });

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {labelEnable && (
        <Label labelColor={labelColor} labelMargin={labelMargin}>
          {labelText}
          {showRequiredStar &&<RequiredStar>*</RequiredStar>}
        </Label>
      )}
      <DropDown
        position={position}
        className="drop-down"
        onClick={handleToggleDropdown}
        width={width}
        height={height}
        isOpen={isOpen}
        margin={margin}
        border={border}
        borderActive={borderActive}
        labelEnable={labelEnable}
        ref={optionRef}
        borderRadius={borderRadius}
        hoverScale={hoverScale}
        customStyles={customStyles}
      >
        <Selected className="selected-option">
          {beforeImage && <img src={beforeImage} alt={beforeImage} />}
          {!enableInputBox ? (
            <Typography
              innerContent={labelTextEnable ? labelText : selectedOption.label}
              {...fontStyle}
              font={fontStyle}
              cursor="pointer"
            />
          ) : (
            <input
              className="dropdown-input"
              type="number"
              value={selectedOption.value}
              onChange={onInputChange}
              onBlur={() =>
                setTimeout(() => {
                  setIsOpen(false);
                })
              }
            />
          )}
          <img
            src={`${STATIC_PATH}DownArrowIcon.svg`}
            alt="close"
            style={
              isOpen
                ? {
                    transform: "rotate(180deg)",
                  }
                : {}
            }
          />
        </Selected>
        {isOpen && (
          <>
            <Backdrop onClick={() => setIsOpen(false)} zIndex={1} />
            <OptionWrapper
              className="dropdown-options-wrapper"
              optionWidth={optionWidth}
              top={autoEnable ? top : "unset"}
              right={right}
            >
              {options.map((option, index) => (
                <Option
                  className="dropdown-option-item"
                  key={`${option.label + index}`}
                  onClick={() => onOptionClick(option)}
                  right={right}
                  font={font.normalBase}
                >
                  {option.optionImg && (
                    <img src={option.optionImg} alt={option.optionImg} />
                  )}
                  <Typography
                    font={font.normalBase}
                    innerContent={option.label}
                    {...fontStyle}
                    subtext={option.subtext}
                    cursor="pointer"
                  />
                </Option>
              ))}
            </OptionWrapper>
          </>
        )}
      </DropDown>
    </>
  );
};

DropdownLabel.defaultProps = {
  onChange: () => {},
  enableInputBox: false,
  autoEnable: false,
  showRequiredStar: true,
};

DropdownLabel.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      subtext: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  borderActive: PropTypes.string,
  optionWidth: PropTypes.string,
  right: PropTypes.string,
  fontStyle: PropTypes.object,
  customSize: PropTypes.number,
  onChange: PropTypes.func,
  enableInputBox: PropTypes.bool,
  labelEnable: PropTypes.bool,
  autoEnable: PropTypes.bool,
  labelText: PropTypes.string,
  position: PropTypes.string,
  borderRadius: PropTypes.string,
  beforeImage: PropTypes.string,
  labelMargin: PropTypes.string,
  labelColor: PropTypes.string,
  hoverScale: PropTypes.number,
  labelTextEnable: PropTypes.bool,
  showRequiredStar: PropTypes.bool,
  customStyles: PropTypes.string,
};

export default DropdownLabel;
