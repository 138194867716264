import styled from "styled-components";

/* moving to attrs method because of styled-component warning 
"Over 200 classes were generated for component styled.section. 
Consider using the attrs method, together with a style object for frequently changed styles." */
export const SubtitleTextWrapper = styled.div`
  position: absolute;
  width: max-content;
  user-select: none;
`;

SubtitleTextWrapper.displayName = "SubtitleTextWrapper";

export const SubtitleText = styled.p.attrs((props) => ({
  style: { lineHeight: props.lineHeight || "inherit" },
}))`
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  & .splittedText {
    background: ${(props) => props.fontBgColor || "rgba(0, 0, 0, 0.8)"};
    padding: ${(props) => props.paddingSpace};
    width: max-content;
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  & .subtitle-line {
    & span,
    & div {
      text-decoration: ${(props) => props.textDecoration};
    }
  }
  & div[class^="splitedWords"] {
    z-index: 1;
  }
  & div[class^="lineWords"] {
    display: inline-block !important;
  }
`;

SubtitleText.displayName = "SubtitleText";
