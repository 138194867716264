/* eslint-disable react/sort-comp, operator-assignment, prefer-template */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MouseDrag from "../containers/timeline/helper-components/timeline-mousedrag";
import { clamp } from "../containers/timeline/timeline-helper";

// do not send props for dynamic styling here. use static css in required component's style!
// this is used only for css reset and functionality related css
export const NumberInputStyled = styled.div`
    * {
        background-color: transparent;
    }

    form {
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }
    input {
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &:not(.is-focused) input {
        pointer-events: none;
    }

    &.is-focused {
        pointer-events: none;
        input {
            pointer-events: auto;
        }
    }

    &.is-dragging {
        pointer-events: none;
    }
`;
NumberInputStyled.displayName = "NumberInputStyled";

const round = (n, d = 2) => {
    n = n.toString().split("e");
    let offset = 0;
    if (n[1]) offset = Number(n[1]);
    return Number(Math.round(n[0] + "e" + (offset + d)) + "e" + (offset - d));
}

class NumberInputComponent extends React.Component {
    constructor(props) {
        super(props);

        this.toInternalText = this.toInternalText.bind(this);
        this.toValue = this.toValue.bind(this);
        this.isPartialInput = this.isPartialInput.bind(this);
        this.isSameInternalText = this.isSameInternalText.bind(this);
        this.validateValue = this.validateValue.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.save = this.save.bind(this);
        this.onInputSubmit = this.onInputSubmit.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onNumberInputDrag = this.onNumberInputDrag.bind(this);
        this.onNumberInputDragEnd = this.onNumberInputDragEnd.bind(this);
        this.setupNumberDrag = this.setupNumberDrag.bind(this);
        this.assignInputRef = this.assignInputRef.bind(this);

        this.state = {
            internalText: this.toInternalText(this.props.value),
            isFocused: false,
            dragStarted: false,
        };

        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.inputRef = React.createRef(null);

        this.DRAG_START_THRESHOLD = 10;
        this.DRAG_STEP_PERCENT = 0.1; // percent of window width (viewport)
        this.clickFocusTriggered = false;
        this.dragStarted = false;
        this.valueBeforeDrag = null;

        this.preventBlurSave = false;
    }

    componentDidUpdate(prevProps) {
        const newInternalText = this.toInternalText(this.props.value);
        if (
            this.props.value !== prevProps.value
            && (Number.isFinite(this.props.value) || Number.isFinite(this.props.value) !== Number.isFinite(prevProps.value))
            && !this.isSameInternalText(newInternalText, this.state.internalText)
        ) {
            this.setState({ internalText: newInternalText });
        }
    }

    componentWillUnmount() {
        const { saveOnUnmount } = this.props;
        if (saveOnUnmount) {
            this.save({ type: "<<NumberInputUnmount>>" });
        }
    }

    toInternalText(value) {
        const isCustomFormat = Boolean(this.props.displayTextToNumber && this.props.numberToDisplayText && this.props.partialInputTest);
        if (isCustomFormat) {
            return this.props.numberToDisplayText(value);
        }
        return value.toString();
    }

    toValue(internalText, isPartial) {
        const isCustomFormat = Boolean(this.props.displayTextToNumber && this.props.numberToDisplayText && this.props.partialInputTest);
        if (isCustomFormat) {
            return this.props.displayTextToNumber(internalText, isPartial);
        }
        return Number(internalText);
    }

    isPartialInput(internalText) {
        const isCustomFormat = Boolean(this.props.displayTextToNumber && this.props.numberToDisplayText && this.props.partialInputTest);
        if (isCustomFormat) {
            return this.props.partialInputTest(internalText);
        }
        const partialInput = /^[+-]?\d*\.?\d*$/;
        return partialInput.test(internalText);
    }

    isSameInternalText(internalText1, internalText2) {
        const isCustomFormat = Boolean(this.props.displayTextToNumber && this.props.numberToDisplayText && this.props.partialInputTest);
        if (isCustomFormat) {
            const value1 = this.props.displayTextToNumber(internalText1);
            const value2 = this.props.displayTextToNumber(internalText2);
            return value1 === value2;
        }
        return internalText1 === internalText2;
    }

    validateValue(value) {
        const inputVal = Number(value);
        return inputVal >= this.props.min && inputVal <= this.props.max;
    }

    onValueChange(e) {
        const { value, min, max, precision, onChange } = this.props;

        let textVal = e.target.value;
        let numberVal = this.toValue(textVal, true);

        if (Number.isNaN(numberVal)) {
            if (this.isPartialInput(textVal)) {
                this.setState({ internalText: textVal });
            } else {
                this.setState({ internalText: this.toInternalText(value) });
            }
            return;
        }

        numberVal = round(numberVal, precision);
        numberVal = clamp(numberVal, min, max);
        if (e.type === "<<NumberInputMouseDrag>>") {
            textVal = this.toInternalText(numberVal);
        }

        this.setState({ internalText: textVal });
        if (onChange && this.validateValue(numberVal) && value !== numberVal) {
            onChange(e, numberVal);
        }
    }

    // NOTE: this function will be called even on component unmount. please check for this scenario when updating states!
    save(e) {
        const { value, min, max, precision, onSubmit } = this.props;
        const { internalText } = this.state;

        let willSave = false;
        let numberVal = this.toValue(internalText);
        if (Number.isNaN(numberVal)) {
            numberVal = value;
        }
        numberVal = round(numberVal, precision);
        numberVal = clamp(numberVal, min, max);

        if (this.validateValue(numberVal)) {
            if (e.type !== "<<NumberInputUnmount>>") {
                this.setState({ internalText: this.toInternalText(numberVal) });
            }
            if (onSubmit) {
                onSubmit(e, numberVal);
            }
            willSave = true;
        }
        return willSave;
    }

    onInputSubmit(e) {
        if (e.keyCode === 13) {
            const willSave = this.save(e);
            if (willSave) {
                this.preventBlurSave = true;
                e.target.blur();
            }
        }
    }

    onInputFocus(e) {
        const { onFocus } = this.props;
        const { isFocused } = this.state;

        const canProcess = !this.clickFocusTriggered;
        if (e.type === "click") {
            this.clickFocusTriggered = true;
        } else {
            this.clickFocusTriggered = false;
        }

        if (canProcess) {
            this.setState({ isFocused: true });
            if (this.inputRef.current && !isFocused) {
                this.inputRef.current.focus();
            }
            if (onFocus) {
                onFocus(e);
            }
        }
    }

    onInputBlur(e) {
        const { onBlur } = this.props;
        const { preventBlurSave } = this;
        this.preventBlurSave = false;

        this.setState({ isFocused: false });
        if (!preventBlurSave) {
            this.save(e);
        }
        if (onBlur) {
            onBlur(e);
        }
    }

    onNumberInputDrag(params) {
        const { mouseMovedBy } = params;
        const { dragStartThreshold = this.DRAG_START_THRESHOLD, dragStepPercent = this.DRAG_STEP_PERCENT } = this.props;
        const { min, max } = this.props;
        if (
            !this.dragStarted
            && this.valueBeforeDrag !== null
            && (Math.abs(mouseMovedBy.x) > dragStartThreshold || Math.abs(mouseMovedBy.y) > dragStartThreshold)
        ) {
            this.dragStarted = true;
            this.setState({ dragStarted: true });
        }
        if (!this.dragStarted) {
            return;
        }

        const stepSizePx = dragStepPercent * window.innerWidth;
        const dragPercent = (mouseMovedBy.x / stepSizePx) * 100;
        const rangeDis = max - min;
        const increaseBy = rangeDis * dragPercent / 100;

        const newValue = this.valueBeforeDrag + increaseBy;
        this.onValueChange({
            type: "<<NumberInputMouseDrag>>",
            target: { value: newValue }
        });
    }

    onNumberInputDragEnd(params) {
        const { event } = params;
        if (!this.dragStarted) {
            return;
        }

        this.dragStarted = false;
        this.valueBeforeDrag = null;
        this.setState({ dragStarted: false });
        this.save(event);
    }

    setupNumberDrag(event) {
        const { dragStartThreshold = this.DRAG_START_THRESHOLD } = this.props;
        this.dragStarted = false;
        this.valueBeforeDrag = this.props.value;
        this.setState({ dragStarted: false });
        this.props.initiateNumberDrag({
            event,
            onMouseDrag: this.onNumberInputDrag,
            onMouseDragEnd: this.onNumberInputDragEnd,
            cursor: "grabbing",
            cursorThreshold: dragStartThreshold,
        });
    }

    assignInputRef(ref) {
        this.inputRef.current = ref;
        if (typeof this.props.inputRef === "function") {
            this.props.inputRef(ref);
        } else if (this.props.inputRef) {
            this.props.inputRef.current = ref;
        }
    }

    render() {
        const { className = "", suffix = "", draggable } = this.props;
        const { internalText, isFocused, dragStarted } = this.state;

        let displayValue = internalText;
        if (suffix && !isFocused) {
            displayValue = `${internalText}${suffix}`;
        }

        let containerClass = `numberinput--wrapper ${className}`;
        if (isFocused) {
            containerClass = `${containerClass} is-focused`;
        }
        if (dragStarted) {
            containerClass = `${containerClass} is-dragging`;
        }

        const addClickEvent = !isFocused && !dragStarted;
        const addMouseDownEvent = draggable && !isFocused && !dragStarted;

        return (
            <NumberInputStyled
                className={containerClass}
                onClick={addClickEvent ? this.onInputFocus : undefined}
                onMouseDown={addMouseDownEvent ? this.setupNumberDrag : undefined}
            >
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <input
                        ref={this.assignInputRef}
                        autoComplete="off"
                        onFocus={this.onInputFocus}
                        type={"text"}
                        value={displayValue}
                        onKeyUp={this.onInputSubmit}
                        onChange={(e) => this.onValueChange(e, false)}
                        onBlur={this.onInputBlur}
                    />
                </form>
            </NumberInputStyled>
        );
    }
}

const NumberInput = (props) => {
    return (
        <MouseDrag>
            {(numberDragProps) => (
                <NumberInputComponent
                    {...props}
                    initiateNumberDrag={numberDragProps.initiateDrag}
                    numberDragStatus={numberDragProps.dragStatus}
                />
            )}
        </MouseDrag>
    );
}

const NumberInputPropTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    precision: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    displayTextToNumber: PropTypes.func,
    numberToDisplayText: PropTypes.func,
    partialInputTest: PropTypes.func,
    suffix: PropTypes.string,
    dragStartThreshold: PropTypes.number,
    dragStepPercent: PropTypes.number,
    draggable: PropTypes.bool,
    saveOnUnmount: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}
NumberInputComponent.propTypes = {
    initiateNumberDrag: PropTypes.func,
    ...NumberInputPropTypes,
};
NumberInput.propTypes = NumberInputPropTypes;

export default NumberInput;
