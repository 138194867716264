/* eslint-disable operator-assignment, one-var, prefer-template */

import { fromJS } from "immutable";
import { ITEM_CONFIG, FRAME } from "../../constants/config";
import { isVideoOnly } from "../timeline/timeline-helper";

export const FRAME_CLASSES = {
  DROP_AREA: "frame-droppable-area",
  INNER_IMAGE: "frame-img-item",
};

export const DEFAULT_SELECTED_FRAME_CLIP = fromJS({
  id: "",
  groupId: "",
  clipId: "",
});

const DEFAULT_IMAGE_DETAILS = fromJS({
  src: FRAME.DEFAULT_IMAGE,
  width: 1500,
  height: 600,
});

export const BASE_IMAGE_DETAILS = fromJS({
  src: "",
  thumb: "",
  defaultImageDetails: DEFAULT_IMAGE_DETAILS,
  flipPosition: 0,
  filter: "6464646464640032",
  type: "",
  id: "default",
});

export const calculateAspectRatio = (Frame, Image, clip) => {
  const selectedItem = Frame;

  let imageWidth;
  let imageHeight;

  if (Image !== null) {
    const selectedImage = Image;
    imageWidth = selectedImage.get("width");
    imageHeight = selectedImage.get("height");
    if (isVideoOnly(Image.get("type"), Image.get("subType"))) {
      imageWidth = selectedImage.get("pwidth")
        ? selectedImage.get("pwidth")
        : selectedImage.get("width");
      imageHeight = selectedImage.get("pheight")
        ? selectedImage.get("pheight")
        : selectedImage.get("height");
    }
  } else {
    imageWidth = selectedItem.getIn([
      "clipDetails",
      clip,
      "imgDetails",
      "width",
    ]);
    imageHeight = selectedItem.getIn([
      "clipDetails",
      clip,
      "imgDetails",
      "height",
    ]);
  }
  const imageAspectRatio = imageWidth / imageHeight;

  const frameScale =
    selectedItem.get("width") / selectedItem.get("defaultWidth");
  const clipWidth =
    selectedItem.getIn(["clipDetails", clip, "clipWidth"]) * frameScale;
  const clipHeight =
    selectedItem.getIn(["clipDetails", clip, "clipHeight"]) * frameScale;

  const frameAspectRatio = clipWidth / clipHeight;

  let width,
    height,
    type,
    X = selectedItem.getIn(["clipDetails", clip, "clipX"]) * frameScale,
    Y = selectedItem.getIn(["clipDetails", clip, "clipY"]) * frameScale;

  if (imageAspectRatio < frameAspectRatio) {
    width = clipWidth;
    height = clipWidth * (imageHeight / imageWidth);

    Y = Y + clipHeight / 2 - height / 2;

    type = 1;
  } else if (imageAspectRatio > frameAspectRatio) {
    width = clipHeight * (imageWidth / imageHeight);
    height = clipHeight;

    X = X + clipWidth / 2 - width / 2;

    type = 2;
  } else if (imageAspectRatio === frameAspectRatio) {
    width = clipWidth;
    height = clipHeight;

    X = X + clipWidth / 2 - width / 2;
    Y = Y + clipHeight / 2 - height / 2;

    type = 0;
  }

  const frameHeight = selectedItem.get("height");
  const frameWidth = selectedItem.get("width");

  width = width / frameWidth;
  height = height / frameHeight;
  X = X / frameWidth;
  Y = Y / frameHeight;

  return { width, height, X, Y, type };
};

export const prepareClipDetails = (Frame) => {
  const clipDetails = Frame.get("clipDetails");
  clipDetails.keySeq().forEach((key) => {
    Frame = Frame.setIn(["clipDetails", key, "imgDetails"], BASE_IMAGE_DETAILS);
    const {
      width,
      height,
      X: x,
      Y: y,
    } = calculateAspectRatio(Frame, DEFAULT_IMAGE_DETAILS, key);
    Frame = Frame.setIn(
      ["clipDetails", key, "imgDetails", "original"],
      fromJS({ width, height, x, y })
    );
  });

  return Frame.get("clipDetails");
};

export const prepareDefaultImgDetails = (Frame, clipId) => {
  const imgDetails = BASE_IMAGE_DETAILS.merge({
    color: Frame.getIn(["clipDetails", clipId, "imgDetails", "color"]),
  });
  Frame = Frame.setIn(["clipDetails", clipId, "imgDetails"], imgDetails);
  const {
    width,
    height,
    X: x,
    Y: y,
  } = calculateAspectRatio(Frame, DEFAULT_IMAGE_DETAILS, clipId);
  Frame = Frame.setIn(
    ["clipDetails", clipId, "imgDetails", "original"],
    fromJS({ width, height, x, y })
  );
  return Frame.getIn(["clipDetails", clipId, "imgDetails"]);
};

export const prepareClipFromWorkspace = ({
  Item,
  ItemId,
  Frame,
  clipId,
} = {}) => {
  const type = Item.get("type");
  const subType = Item.get("subType");
  const ClipData = Frame.getIn(["clipDetails", clipId]);

  const imgDetails = ClipData.getIn(["imgDetails"]);
  let imageData = imgDetails
    .set("subType", subType)
    .set("type", type)
    .set("width", Item.get("width"))
    .set("height", Item.get("height"))
    .set("assetId", Item.get("assetId"))
    .set("src", Item.get("src"))
    .set("thumb", Item.get("thumb") || Item.get("thumbnail"))
    .set("stickerify", Item.get("stickerify"))
    .set("strokeOptions", Item.get("strokeOptions"))
    .set("firstEdit", Item.get("firstEdit"))
    .set("isTransparent", Item.get("isTransparent"))
    .set("stickerifyPath", Item.get("stickerifyPath"))
    .set("originalPath", Item.get("originalPath"))
    .set("recentEdit", Item.get("recentEdit"))
    .set("bgremoval", Item.get("bgremoval"))
    .set("bgremovalPath", Item.get("bgremovalPath"))
    .set("id", ItemId)
    .set("colors", Item.get("colors"))
    .set(
      "filter",
      Item.get("filter") ? Item.get("filter") : ITEM_CONFIG.IMAGE.DEFAULT_FILTER
    )
    .set("flipPosition", 0)
    .set("defaultColor", Item.get("defaultColor"))
    .set("sourceType", Item.get("sourceType"))
    .set("pwidth", Item.get("pwidth"))
    .set("pheight", Item.get("pheight"));

  if (
    Item.get("isBlob") &&
    Item.get("src") &&
    Item.get("src").startsWith("blob:")
  ) {
    imageData = imageData.remove("thumb");
    imageData = imageData.set("isBlob", Item.get("isBlob"));
  }

  if (isVideoOnly(type, subType)) {
    imageData = imageData
      .set("videoStart", Item.get("videoStart"))
      .set("videoEnd", Item.get("videoEnd"))
      .set("videoDuration", Item.get("videoDuration"))
      .set("volume", Item.get("volume"))
      .set("duration", Item.get("duration"));
  }

  const imagePlotRatio = calculateAspectRatio(Frame, imageData, clipId);

  imageData = imageData.set(
    "original",
    fromJS({
      x: imagePlotRatio.X,
      y: imagePlotRatio.Y,
      width: imagePlotRatio.width,
      height: imagePlotRatio.height,
    })
  );

  return imageData;
};

export const getFrameDropAreaId = (id, clipId) => {
  return id + "_droppable_area_" + clipId;
};

export const getFrameSvgId = (id, svgIndex) => {
  return id + "_svg_" + svgIndex;
};

export const getFrameClipFilterSvgId = (id, clipId) => {
  return id + "_svgFilter_" + clipId;
};

export const getFrameClipFilterId = (id, clipId, filterId) => {
  return id + "_svgFilter_" + clipId + "_" + filterId;
};

export const getFrameClipVignetteId = (id, clipId) => {
  return id + "-vignette-" + clipId;
};

export const getFrameInnerImageId = (id, clipId) => {
  return id + "_innerimg_" + clipId;
};
