/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "../../common-components/Typography";
import { VIDEO_RESIZE_DETAILS } from "../../constants";
import {
  IconSVG,
  ResizeDropdown,
  ResizeDropdownLi,
  ToolbarHeader,
  ToolbarWrap,
} from "./shorts-components";
import vmTheme from "../../constants/theme";
import { font } from "../../constants/font";
import { resizeVideo } from "../../redux/actions/appUtils";
import content from "../../constants/content";
import ShortsPreset from "./shorts-preset";
import Backdrop from "../../common-components/BackDrop";

const ShortsToolbar = () => {
  const dropdownRef = useRef(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const projectDetails = useSelector((store) => store.projectDetails);
  const hasSubtitle = !!projectDetails.get("localSubtitle").size;
  const RESIZETHEME = VIDEO_RESIZE_DETAILS(theme);
  const userDetails = useSelector((store) => store.userDetails);
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(
    RESIZETHEME[userDetails.dimensionName]?.name
  );
  const [selectedIcon, setSelectedIcon] = useState(
    RESIZETHEME[userDetails.dimensionName]?.src
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isShowModal, setIsShowModal] = useState(false);
  const { permission } = userDetails;

  const closeModal = () => setIsShowModal(false);
  const resizeProject = (name) => {
    resizeVideo(
      name,
      projectDetails,
      userDetails.projectId,
      projectDetails.get("duration"),
      dispatch
    );
    setDropdownVisible(false);
    setSelectedOption(RESIZETHEME[name].name);
    setSelectedIcon(RESIZETHEME[name].src);
  };

  useEffect(() => {
    setSelectedOption(RESIZETHEME[userDetails.dimensionName]?.name);
    setSelectedIcon(RESIZETHEME[userDetails.dimensionName]?.src);
  }, [RESIZETHEME, userDetails]);

  const calculatePosition = () => {
    if (dropdownRef.current) {
      const node = dropdownRef.current.getBoundingClientRect();
      const topPosition = node.bottom;
      const leftPosition = node.left;
      setPosition({ top: topPosition, left: leftPosition });
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      calculatePosition();
    }
  }, [dropdownVisible]);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  return (
    <>
      {permission !== 0 && (
        <ToolbarHeader>
          {/* <ToolbarWrap
            ref={dropdownRef}
            selectedIcon={selectedIcon}
            isActive={dropdownVisible}
          >
            <IconSVG
              dangerouslySetInnerHTML={{
                __html: selectedIcon,
              }}
            />
            <Typography
              content={selectedOption}
              cursor="pointer"
              display="block"
              padding="5px 20px 5px 5px"
              className="resizeDropdown"
              onClick={toggleDropdown}
            />
          </ToolbarWrap> */}
          {dropdownVisible && (
            <>
              <Backdrop
                onClick={() => setDropdownVisible(false)}
                zIndex="8"
                height="100%"
              />
              <ResizeDropdown
                className="resizeMenu"
                style={{
                  position: "absolute",
                  top: position.top,
                  left: position.left,
                  zIndex: 1000,
                }}
              >
                {Object.keys(RESIZETHEME).map((key) => {
                  const isSelected = key === userDetails.dimensionName;
                  return (
                    <ResizeDropdownLi key={key} isHovered={isSelected}>
                      <IconSVG
                        isSelected={isSelected}
                        dangerouslySetInnerHTML={{
                          __html: isSelected
                            ? RESIZETHEME[key].selectedSrc
                            : RESIZETHEME[key].src,
                        }}
                      />
                      <Typography
                        content={RESIZETHEME[key].title}
                        display="block"
                        color={vmTheme[theme].primaryColor}
                        padding="10px"
                        font={font.normalBase_21}
                        cursor="pointer"
                        onClick={() => resizeProject(key)}
                        isHovered={isSelected}
                        isSelected={key === userDetails.dimensionName}
                      />
                    </ResizeDropdownLi>
                  );
                })}
              </ResizeDropdown>
            </>
          )}
          <Typography
            content={content.SUBTITLE_STYLES}
            cursor={hasSubtitle ? "pointer" : "not-allowed"}
            display="block"
            padding="5px 20px 5px 5px"
            className="subtitle-style"
            onClick={hasSubtitle ? () => setIsShowModal(true) : null}
          />
        </ToolbarHeader>
      )}
      <ShortsPreset isShowModal={isShowModal} onClose={closeModal} />
    </>
  );
};

export default ShortsToolbar;
