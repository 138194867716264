import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Typography from "../../../common-components/Typography";
import { font } from "../../../constants/font";
import PaymentInput from "../../../common-components/PaymentInput";
import { STATIC_PATH } from "../../../constants/config";
import { Button as BackButton } from "../../panels/panel-components";
import content from "../../../constants/content";
import Action from "../../../common-components/Action";
import { setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import vmTheme from "../../../constants/theme";

const DropdownWrap = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 11px;
  border: 1px solid ${(props) => props.theme.paymentBoxShadow};
  box-shadow: 0px 0px 3px ${(props) => props.theme.paymentBoxShadow};
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

const LabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 9px;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`;

const CheckMark = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.checked ? props.theme.labelBorderColor : "transparent"};
  border-radius: 50%;
  border: ${(props) =>
    props.checked
      ? `1px solid transparent`
      : `1px solid ${props.theme.checkboxBorderColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 10px;
    border-style: solid;
    border-color: ${(props) => props.theme.polarColor};
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    position: relative;
    top: -1px;
  }
`;

const Payment = styled.div`
  padding: 16px;
`;

const TopWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px 14px;
`;

const { DOWNLOAD, UPDATE, TOTAL_AMOUNT, PAYPAL, CREDIT_CARD } = content;

const PaymentCard = () => {
  const theme = useSelector((state) => state.app.get("theme"));
  const dispatch = useDispatch();

  const [cardContent, setCardContent] = useState(true);
  const [paypalContent, setPaypalContent] = useState(false);

  const openUpgradeModal = useCallback((data) => {
    dispatch(setWorkspaceTextFocus(data));
  }, []);

  const handleCardClick = () => {
    setCardContent(true);
    setPaypalContent(false);
  };

  const handlePaypalClick = () => {
    setCardContent(false);
    setPaypalContent(true);
  };

  return (
    <Payment>
      <BackButton>
        <img src={`${STATIC_PATH}left-arrow.svg`} alt="left-arrow" />
        <Typography
          content={DOWNLOAD}
          font={font.boldRegular_25}
          color={vmTheme[theme].panelPrimaryColor}
          margin="auto 9px 24px"
          cursor="pointer"
        />
      </BackButton>
      <TopWrap>
        <Typography
          content={TOTAL_AMOUNT}
          color={vmTheme[theme].sbSecondaryColor}
          font={font.normalSmall_14}
        />
        <Typography
          innerContent="$3.00"
          color={vmTheme[theme].paymentCardTextColor}
          font={font.boldBase}
        />
      </TopWrap>
      <DropdownWrap>
        <DropdownHeader onClick={handleCardClick}>
          <Label>
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                checked={cardContent}
                onChange={handleCardClick}
              />
              <CheckMark checked={cardContent} />
            </CheckboxWrapper>
            <Typography
              content={CREDIT_CARD}
              color={vmTheme[theme].paymentCardTextColor}
              font={font.mediumBase_21}
              cursor="pointer"
            />
          </Label>
          <img src={`${STATIC_PATH}paypal-icon.svg`} alt="toggle-icon" />
        </DropdownHeader>
        {cardContent && (
          <DropdownContent>
            <PaymentInput
              icon={`${STATIC_PATH}card-number.svg`}
              placeholder="Add Card Number"
              onFocus={() => openUpgradeModal(true)}
              onBlur={() => openUpgradeModal(false)}
            />
            <LabelWrap>
              <PaymentInput
                width="100%"
                icon={`${STATIC_PATH}card-month.svg`}
                placeholder="MM/YY"
                onFocus={() => openUpgradeModal(true)}
                onBlur={() => openUpgradeModal(false)}
              />
              <PaymentInput
                width="100%"
                icon={`${STATIC_PATH}card-cvv.svg`}
                placeholder="CVV"
                onFocus={() => openUpgradeModal(true)}
                onBlur={() => openUpgradeModal(false)}
              />
            </LabelWrap>
            <PaymentInput
              icon={`${STATIC_PATH}card-pincode.svg`}
              placeholder="Add Zipcode"
              onFocus={() => openUpgradeModal(true)}
              onBlur={() => openUpgradeModal(false)}
            />
          </DropdownContent>
        )}
      </DropdownWrap>
      <DropdownWrap>
        <DropdownHeader onClick={handlePaypalClick}>
          <Label>
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                checked={paypalContent}
                onChange={handlePaypalClick}
              />
              <CheckMark checked={paypalContent} />
            </CheckboxWrapper>
            <Typography
              content={PAYPAL}
              color={vmTheme[theme].paymentCardTextColor}
              font={font.mediumBase_21}
              cursor="pointer"
            />
          </Label>
          <img src={`${STATIC_PATH}card-icon.svg`} alt="toggle-icon" />
        </DropdownHeader>
      </DropdownWrap>
      <Action
        text={UPDATE}
        background={vmTheme[theme].actionBgColor}
        font={font.mediumMiniBase}
        borderRadius="8px"
        height="36px"
      />
    </Payment>
  );
};

export default PaymentCard;
