/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { hexToRGB } from "../../helper/getColorAtPercentage";

class CanvasVideo extends Component {
  constructor(props) {
    super(props);
    this.canvas1Ref = React.createRef();
    this.canvas2Ref = React.createRef();
    this.timeoutRef = null;
    this.selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });
    this.selfieSegmentation.setOptions({
      modelSelection: 1,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tolerance !== this.props.tolerance ||
      prevProps.chromaKey !== this.props.chromaKey
    ) {
      this.seeked();
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.timeoutRef);
  }

  startTimer = () => {
    this.timeoutRef = requestAnimationFrame(this.timerCallback);
  };

  timerCallback = () => {
    const { mediaElRef } = this.props;
    if (
      mediaElRef.current &&
      !mediaElRef.current.paused &&
      !mediaElRef.current.ended
    ) {
      const { width, height } = this.canvas1Ref.current;
      this.computeFrame(width, height);
      this.timeoutRef = requestAnimationFrame(this.timerCallback);
    }
  };

  computeFrame = (width, height) => {
    const { mediaElRef, chromaKey } = this.props;
    const ctx1 = this.canvas1Ref.current?.getContext("2d");
    const ctx2 = this.canvas2Ref.current?.getContext("2d");
    if (!ctx1 || !ctx2) return;

    // Enable antialiasing
    ctx1.imageSmoothingEnabled = true;
    ctx1.imageSmoothingQuality = "high";
    ctx2.imageSmoothingEnabled = true;
    ctx2.imageSmoothingQuality = "high";

    // Adjust color space if necessary
    ctx1.webkitImageSmoothingEnabled = true; // For Safari
    ctx1.mozImageSmoothingEnabled = true; // For Firefox
    ctx2.webkitImageSmoothingEnabled = true; // For Safari
    ctx2.mozImageSmoothingEnabled = true; // For Firefox

    ctx1.drawImage(mediaElRef.current, 0, 0, width, height);
    const frame = ctx1.getImageData(0, 0, width, height);
    const l = frame.data.length / 4;

    const [bgR, bgG, bgB] = hexToRGB(chromaKey || "");
    const { tolerance } = this.props;

    for (let i = 0; i < l; i += 1) {
      const r = frame.data[i * 4];
      const g = frame.data[i * 4 + 1];
      const b = frame.data[i * 4 + 2];
      const diff = Math.abs(r - bgR) + Math.abs(g - bgG) + Math.abs(b - bgB);
      if (diff < tolerance) {
        frame.data[i * 4 + 3] = 0;
      }
    }
    ctx2.putImageData(frame, 0, 0);
  };

  play = () => {
    const { mediaElRef } = this.props;
    if (mediaElRef.current) {
      this.startTimer();
    }
  };

  seeked = () => {
    const { mediaElRef, imageRef } = this.props;
    if (mediaElRef.current) {
      const { width, height } = this.canvas1Ref.current;
      this.computeFrame(width, height);

      if (
        imageRef?.current &&
        mediaElRef.current &&
        mediaElRef.current.paused
      ) {
        imageRef.current.style.visibility = "hidden";
      }
    }
  };

  render() {
    const {
      assignRef,
      src,
      className,
      style,
      crossOrigin,
      muted,
      isPlayAll,
      onWaiting,
      chromaKey,
    } = this.props;
    const isCanvasRender = !!chromaKey;

    return (
      <>
        <video
          ref={assignRef}
          key={src}
          playsInline={true}
          className={className}
          style={isCanvasRender ? { ...style, visibility: "hidden" } : style}
          preload={this.props.preload || "auto"}
          crossOrigin={crossOrigin || "anonymous"}
          muted={muted}
          onWaiting={isPlayAll ? onWaiting : undefined}
          onPlay={this.play}
          onSeeked={this.seeked}
        >
          <source src={src} />
        </video>
        {isCanvasRender && (
          <>
            <canvas
              ref={this.canvas1Ref}
              style={{ ...style, visibility: "hidden" }}
              className={className}
            />
            <canvas ref={this.canvas2Ref} style={style} className={className} />
          </>
        )}
      </>
    );
  }
}

CanvasVideo.propTypes = {
  assignRef: PropTypes.func,
  src: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  crossOrigin: PropTypes.string,
  isPlayAll: PropTypes.bool,
  onWaiting: PropTypes.func,
  muted: PropTypes.bool,
  chromaKey: PropTypes.string,
  imageRef: PropTypes.object,
  tolerance: PropTypes.number,
  mediaElRef: PropTypes.object,
  preload: PropTypes.string,
};

export default CanvasVideo;
