import { fromJS } from "immutable";
import AppConfig from "../../constants/config";
import {
  getSubtitlesFontToLoad,
  loadSubtitleFontFamily,
} from "../../helper/fontLoadHelper";
import triggerSimpleAjax from "../../helper/httpHelper";
import proceedWait from "../../helper/proceedWait";
import {
  getSampleSubtitleText,
  getSubtitleListPosition,
} from "../../helper/subtitleHelper";
import { insertSubtitleData, reconcileSubtitleData, setSubtitleStatus, updateSubtitleData } from "./appUtils";
import content from "../../constants/content";
import ActionTypes from "../../constants/action-types";

const { API_URL, API } = AppConfig;
const { CREATE, STATUS, UPDATE } = API.SUBTITLE;

const DELAY = 5000;
const AS_CREATE_URL = `${API_URL}${CREATE}`;
const AS_STATUS_URL = `${API_URL}${STATUS}`;
const SUBTITLE_UPDATE_URL = `${API_URL}${UPDATE}`;

async function loadSubtitleFonts(subtitles, params) {
  const { loadedFonts } = params;
  const { textStyles, data } = subtitles;

  /** @info preload fonts data */
  let fontToLoad = getSubtitlesFontToLoad(data, loadedFonts, textStyles);
  if (fontToLoad.length) {
    const sampleSubtitleText = getSampleSubtitleText(data);
    fontToLoad = await loadSubtitleFontFamily(fontToLoad, sampleSubtitleText);
  }

  return fontToLoad;
}

export function getSubtitleList(params = {}) {
  return new Promise((resolve, reject) => {
    const onSuccess = (res) => {
      if (res.status === "success") {
        resolve(res.data);
      } else {
        reject(new Error("failed while fetcing subtitle_data!"));
      }
    };
    const onError = () => {
      reject(new Error("failed while fetcing subtitle_data!"));
    };
    triggerSimpleAjax(`${API_URL}${API.SUBTITLE.GET_SUBTITLE_LIST}`, "POST", false, params, onSuccess, onError);
  });
}

export async function addCordsToSubtitle(subtitles, params) {
  const { loadedFonts, workspaceWidth, workspaceHeight } = params;
  const { textStyles, data, textEffects, position } = subtitles;

  /** @info preload fonts data */
  let fontToLoad = getSubtitlesFontToLoad(data, loadedFonts, textStyles);
  if (fontToLoad.length) {
    const sampleSubtitleText = getSampleSubtitleText(data);
    fontToLoad = await loadSubtitleFontFamily(fontToLoad, sampleSubtitleText);
  }
  /** @info calc posistion and update */
  const { updatedSubtitles, emptyDropList } = getSubtitleListPosition(
    fromJS(data),
    workspaceWidth,
    workspaceHeight,
    fromJS(textStyles),
    fromJS(textEffects),
    position
  );
  return { updatedSubtitles, textStyles, textEffects, fontToLoad, emptyDropList };
}

function trackAutoSubtitle(requestId, subtitleId, targetLanguage) {
  return new Promise((resolve, reject) => {
    const body =
      subtitleId !== undefined ? { requestId, subtitleId, targetLanguage } : { requestId, targetLanguage };
    const onSuccess = (res) => {
      const { status } = res.data;
      let result = {};
      if (status === "COMPLETED") {
        const { subtitleid, subtitles } = res.data;
        result = { subtitleId: subtitleid, subtitles, status };
      } else if (status === "IN_PROGRESS") {
        result = { status: "RUNNING" };
      } else {
        reject(new Error("failed while tracking.!"));
      }
      resolve(result);
    };
    const onError = () => {
      reject(new Error("failed while tracking.!"));
    };
    triggerSimpleAjax(AS_STATUS_URL, "POST", false, body, onSuccess, onError);
  });
}

function createAutoSubtitle(assets, targetLanguage, projectId, subtitleId) {
  return new Promise((resolve, reject) => {
    const body =
      subtitleId
        ? { assets, targetLanguage, projectId, subtitleId }
        : { assets, targetLanguage, projectId };
    const onSuccess = (res) => {
      if (res.data.request_id === undefined) {
        const error = new Error("failed while getting jobid.!");
        error.code = res.message === "Credit exceeded" ? "CREDIT_EXCEED" : "FAILED";
        reject(error);
      } else {
        resolve({ requestId: res.data.request_id });
      }
    };
    const onError = (err) => {
      reject(err);
    };
    triggerSimpleAjax(AS_CREATE_URL, "POST", false, body, onSuccess, onError);
  });
}

export function updateAutoSubtitle(
  updatedSubtitles,
  projectId,
  subtitleId,
  textStyles,
  textEffects,
  deletePosition = false, /** only use once to determine y-posititon for viral style. */
) {
  return new Promise((resolve, reject) => {
    const body = {
      projectId,
      subtitleId,
      deletePosition,
      data: { subtitle: updatedSubtitles.toJS(), textStyles, textEffects },
    };
    const onSuccess = (res) => resolve(res);
    const onError = () =>
      reject(new Error(content.FAILED_TO_UPDATE_SUBTITLE_DATA));
    triggerSimpleAjax(
      SUBTITLE_UPDATE_URL,
      "POST",
      false,
      body,
      onSuccess,
      onError
    );
  });
}

function checkUserCancelRequest(getState, shouldThrowErr = true) {
  const currentState = getState();
  const isUserCancelReq = currentState.app.getIn([
    "subtitle",
    "autoSubtitle",
    "shouldCancel",
  ])
  if (!shouldThrowErr) return isUserCancelReq
  if (isUserCancelReq) {
    const error = new Error("User canceled the request");
    error.code = "USER_CANCELED_REQUEST";
    throw error;
  }
  return null;
}

export function generateAutoSubtitle(payload) {
  return async (dispatch, getState) => {
    try {
      let trackStatus = 3;
      let currentState = getState(); // Accessing current state
      const { projectId } = currentState.userDetails;
      const workspaceWidth = currentState.projectDetails.get("width");
      const workspaceHeight = currentState.projectDetails.get("height");
      dispatch(
        setSubtitleStatus({
          type: "autoSubtitle",
          toMerge: { isLoading: true, trackStatus, shouldCancel: false },
        })
      );
      const { assets, targetLanguage } = payload;
      trackStatus += 2;
      const { requestId } = await createAutoSubtitle(
        assets,
        targetLanguage,
        projectId,
        payload.subtitleId
      );
      dispatch(
        setSubtitleStatus({ type: "autoSubtitle", toMerge: { trackStatus } })
      );

      trackStatus += 2;
      let result = { status: "RUNNING" };
      let delay = 100;
      while (result.status === "RUNNING") {
        checkUserCancelRequest(getState);
        // eslint-disable-next-line no-await-in-loop
        await proceedWait(delay);
        // eslint-disable-next-line no-await-in-loop
        result = await trackAutoSubtitle(requestId, payload.subtitleId, targetLanguage);
        if (result.status !== "COMPLETED") {
          dispatch(
            setSubtitleStatus({
              type: "autoSubtitle",
              toMerge: { trackStatus },
            })
          );
        }
        if (trackStatus < 98) trackStatus += 2;
        delay = DELAY;
      }

      dispatch(
        setSubtitleStatus({
          type: "autoSubtitle",
          toMerge: { trackStatus: 99 },
        })
      );
      const { subtitleId } = result;
      let { subtitles } = result;
      if (payload.subtitleId && payload.subtitleId === subtitleId) {
        subtitles = {
          data: subtitles,
          textStyles: currentState.projectDetails.getIn(["subtitle", "textStyles"]).toJS(),
          textEffects: currentState.projectDetails.getIn(["subtitle", "textEffects"]).toJS()
        }
      }
      const { langCode, animData } = subtitles;
      currentState = getState(); // Accessing current state
      const loadedFonts = currentState.app.getIn(["loadedFonts", "fonts"]);
      const { updatedSubtitles, textStyles, textEffects, fontToLoad, emptyDropList } =
        await addCordsToSubtitle(subtitles, {
          loadedFonts,
          workspaceWidth,
          workspaceHeight,
        });
      if (emptyDropList.length) {
        payload.notify.warn(content.AST_NO_SUB);
      }
      if (emptyDropList.length !== updatedSubtitles.size) {
        await updateAutoSubtitle(
          updatedSubtitles,
          projectId,
          subtitleId,
          textStyles,
          textEffects
        );
      }
      const subtitleList = await getSubtitleList({ projectId });

      if (checkUserCancelRequest(getState, false)) {
        payload.notify.warn(content.FAIL_TO_CANCEL);
      }
      dispatch(
        setSubtitleStatus({
          type: "autoSubtitle",
          toMerge: { isLoading: false, trackStatus: 0, shouldCancel: false },
        })
      );
      if (!payload.subtitleId || (payload.subtitleId && payload.subtitleId !== subtitleId)) { // no default subtitle present need insert
        dispatch(
          insertSubtitleData({
            subtitleData: {
              ...subtitles,
              data: updatedSubtitles,
              textStyles,
              textEffects,
              subtitleId,
              langCode,
              animData,
            },
            fontToLoad,
            subtitleId,
            subtitleList,
          })
        );
      } else { // default subtitle present need update
        dispatch(
          updateSubtitleData({
            subtitleData: {
              data: updatedSubtitles,
            },
            fontToLoad,
            subtitleId: payload.subtitleId,
            subtitleList,
          })
        );
      }

      return {
        isCompleted: true,
      };
    } catch (Err) {
      dispatch(
        setSubtitleStatus({
          type: "autoSubtitle",
          toMerge: { isLoading: false, trackStatus: 0, shouldCancel: false },
        })
      );
      if (Err.code === "CREDIT_EXCEED") {
        payload.notify.warn(content.SUBTEXT_SUBTITLE);
        dispatch({
          type: ActionTypes.SHOW_UPGRADE_MODAL,
          payload: {
            show: true,
            headerText: content.WHOOPS,
            subText: content.SUBTEXT_SUBTITLE,
            upgradesource: 'Subtitle-credits'
          },
        });
      } else if (Err.code !== "USER_CANCELED_REQUEST") {
        payload.notify.warn(content.FAILED_TO_LOAD_DATA);
      }
      return {
        isCompleted: false,
      };
    }
  };
}

export function getSubtitleReconcile(payload, params = {}) {
  const apiUrl = `${API_URL}${API.SUBTITLE.GET}`;

  return (dispatch, getState) =>
    new Promise((res, rej) => {
      const onSuccess = async (response) => {
        try {
          if (response.status !== "success") {
            params.notify.warn(content.FAILED_TO_TRANSLATE_SUBTITLE);
            return;
          }
          const currentState = getState(); // Accessing current state
          const { projectId } = currentState.userDetails;
          let subtitleList = currentState.projectDetails.get("subtitleData");
          if (payload.fromApi) {
            subtitleList = await getSubtitleList({ projectId });
          }
          const loadedFonts = currentState.app.getIn(["loadedFonts", "fonts"]);
          const data = response.subtitle;
          const subtitles = {
            data: data.data,
            textEffects: data.textEffects,
            textStyles: data.textStyles,
          };

          // for language switch, there is a possibility that subtitle_data is outdated
          // so flip flag once just in case
          subtitleList.forEach((meta) => {
            meta.isActive = meta.subtitleId === data.subtitleId;
          });

          const { abortSignal = null } = params;
          const fontToLoad = loadSubtitleFonts(subtitles, {
            loadedFonts,
          });
          if (!abortSignal || !abortSignal.aborted) {
            dispatch(
              reconcileSubtitleData({
                subtitleData: {
                  ...subtitles,
                  data: fromJS(subtitles.data),
                  subtitleId: data.subtitleId,
                  langCode: data.langCode,
                  animData: data.animData,
                },
                fontToLoad,
                subtitleId: data.subtitleId,
                subtitleList,
              })
            );
          }

          res(response);
        } catch (e) { }
      };
      const onReject = (e) => {
        rej(e);
      };

      triggerSimpleAjax(apiUrl, "POST", false, payload, onSuccess, onReject);
    });
}

export function translateSubtitleStatus(payload) {
  const apiUrl = `${API_URL}${API.SUBTITLE.TRANSLATE_STATUS}`;
  return new Promise((resolve, reject) => {
    const onSuccess = async (response) => {
      const { status, subtitles } = response.data;
      let result = {};
      if (status === "SUCCESS") {
        result = { subtitles, status };
      } else if (status === "IN_PROGRESS") {
        result = { status: "RUNNING" };
      } else {
        reject(new Error("failed while tracking.!"));
      }
      resolve(result);
    };
    const onReject = (e) => {
      reject(e);
    };

    triggerSimpleAjax(apiUrl, "POST", false, payload, onSuccess, onReject);
  });
}

export function translateSubtitle(payload, params = {}) {
  const apiUrl = `${API_URL}${API.SUBTITLE.TRANSLATE_SUBTITLE}`;
  let percentage = 5;
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const onSuccess = async (response) => {
        try {
          if (response.status !== "success") {
            params.notify.warn(content.FAILED_TO_TRANSLATE_SUBTITLE);
            return;
          }

          percentage += 5;
          let result = { status: "RUNNING" };
          while (result.status === "RUNNING") {
            const { taskId } = response.data;
            // eslint-disable-next-line no-await-in-loop
            await proceedWait(100);
            // eslint-disable-next-line no-await-in-loop
            result = await translateSubtitleStatus({ taskId, ...payload });
            if (result.status !== "COMPLETED") {
              dispatch(
                setSubtitleStatus({
                  type: "translateSubtitle",
                  toMerge: { percentage },
                })
              );
            }
            if (percentage < 95) {
              percentage += 5;
            }
            // eslint-disable-next-line no-await-in-loop
            await proceedWait(DELAY);
          }

          dispatch(
            setSubtitleStatus({
              type: "translateSubtitle",
              toMerge: { percentage: 99 },
            })
          );

          let currentState = getState(); // Accessing current state
          const { projectId } = currentState.userDetails;
          const subtitleList = await getSubtitleList({ projectId });
          const workspaceWidth = currentState.projectDetails.get("width");
          const workspaceHeight = currentState.projectDetails.get("height");
          const loadedFonts = currentState.app.getIn(["loadedFonts", "fonts"]);
          const data = result.subtitles;
          const subtitles = {
            data: data.data,
            textEffects: data.textEffects,
            textStyles: data.textStyles,
          };

          const { updatedSubtitles, textStyles, textEffects, fontToLoad } =
            await addCordsToSubtitle(subtitles, {
              loadedFonts,
              workspaceWidth,
              workspaceHeight,
            });
          currentState = getState(); // Accessing up-to-date current state after await
          const defaultSubtitle =
            currentState.projectDetails.get("defaultSubtitle");
          if (defaultSubtitle !== data.subtitleId) {
            dispatch(
              insertSubtitleData({
                subtitleData: {
                  ...subtitles,
                  data: updatedSubtitles,
                  textStyles,
                  textEffects,
                  subtitleId: data.subtitleId,
                  langCode: data.langCode,
                  animData: data.animData,
                },
                fontToLoad,
                subtitleId: data.subtitleId,
                subtitleList,
              })
            );
          } else {
            dispatch(
              updateSubtitleData({
                subtitleData: {
                  data: updatedSubtitles,
                },
                fontToLoad,
                subtitleId: data.subtitleId,
              })
            );
          }
          dispatch(
            setSubtitleStatus({
              type: "translateSubtitle",
              toMerge: { isLoading: false, percentage: 0 },
            })
          );
          params.notify.success(content.SUBTITLE_TRANSLATED_SUCCESSFULLY);

          resolve(response);
        } catch (e) {
          dispatch(
            setSubtitleStatus({
              type: "translateSubtitle",
              toMerge: { isLoading: false, percentage: 0 },
            })
          );
          params.notify.warn(content.FAILED_TO_TRANSLATE_SUBTITLE);
        }
      };
      const onReject = (e) => {
        reject(e);
      };

      triggerSimpleAjax(apiUrl, "POST", false, payload, onSuccess, onReject);
    });
}
