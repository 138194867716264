/* eslint-disable prefer-template */
/* eslint-disable operator-assignment */
import React from "react";
import { useIntl } from "react-intl";
import { IconStyled, IconTextPairStyled, ItemToolColorStyled } from "./itemtoolbar-components";
import CustomTooltipComponent from "../../common-components/CustomTooltip";
import { STATIC_PATH } from "../../constants/config";

export const ItemToolOption = (props) => {
    const intl = useIntl();

    const handleClick = (e) => {
        e.stopPropagation(); // to prevent click on expand icon when tool is inside more options

        if (props.onClick) {
            props.onClick(e);
        }
    }

    const tooltipId = `tt-itemtoolbar-item-${props.langId}`;
    let icon = null;
    let src = null;
    let hoverSrc = null;
    let className = props.className ? props.className : "";
    className = className + " item-tool";
    if (props.isActive) {
        className = className + " tool-active";
    }
    if (props.isDisabled) {
        className = className + " tool-disabled";
    }

    if (props.hasIcon) {
        src = props.src;
        hoverSrc = props.hover;

        if (props.isActive && props.active) {
            src = props.active;
            hoverSrc = "";
        }

        if (props.prefix) {
            if (src) {
                src = props.prefix + src;
            }
            if (hoverSrc) {
                hoverSrc = props.prefix + hoverSrc;
            }
        }
    }

    const iconStyle = {};
    if (src) {
        iconStyle["--item-tool-src"] = `url(${src})`;
    }
    if (hoverSrc) {
        iconStyle["--item-tool-hoverSrc"] = `url(${hoverSrc})`;
    }

    if (props.hasIcon && !props.hasText) {
        icon = (
            <IconStyled
                className={className}
                data-tooltip-id={tooltipId}
                onClick={handleClick}
                style={iconStyle}
            >
                <img
                    draggable="false"
                    alt=""
                    src={src}
                />
                {props.children}
            </IconStyled>
        );
    } else if (props.hasText) {
        const text = intl.formatMessage({ id: props.langId });
        let img = null;
        if (props.hasIcon) {
            img = (
                <img
                    draggable="false"
                    alt={text}
                    src={src}
                />
            );
        }
        icon = (
            <IconTextPairStyled
                className={className}
                data-tooltip-id={tooltipId}
                onClick={handleClick}
                $hasIcon={props.hasIcon}
                style={iconStyle}
            >
                {img}
                <p>{text}</p>
                {props.children}
            </IconTextPairStyled>
        );
    }

    let langId = "";
    if (props.disabledLangId && props.isDisabled) {
        langId = props.disabledLangId;
    } else if (!props.hasText && props.langId) {
        langId = props.langId;
    }

    if (
        icon
        && langId
        && (
            !props.ignoreTooltipIfActive
            || (props.ignoreTooltipIfActive && !props.isActive)
        )
    ) {
        icon = (
            <CustomTooltipComponent
                tooltipId={tooltipId}
                dataTooltip={langId}
                tooltipPosition="bottom"
            >
                {icon}
            </CustomTooltipComponent>
        );
    }

    return icon;
}

export const ItemToolColorOption = (props) => {
    const tooltipId = `tt-itemtoolbar-item-${props.langId}`;
    let icon = null;
    let className = props.className ? props.className : "";
    className = className + " item-tool";
    if (props.isActive) {
        className = className + " tool-active";
    }
    let colorsToRender = null;

    if (props.colorType === "TEXT") {
        let { colors } = props;
        if (!colors || (colors && colors.length === 0)) {
            colors = ["#000000"];
        }

        colorsToRender = [];
        let maxColors = 4;
        if (colors.length < maxColors) {
            maxColors = colors.length;
        }

        for (let clrIndex = 0; clrIndex < maxColors; clrIndex = clrIndex + 1) {
            colorsToRender.push(
                <div
                    key={clrIndex}
                    style={{
                        backgroundColor: colors[clrIndex],
                    }}
                />
            );
        }

        className = className + " text-color";

        colorsToRender = (
            <div>
                <div className="icon">
                    <img
                        draggable="false"
                        alt="text color picker"
                        src={STATIC_PATH + "A-icon.svg"}
                    />
                </div>
                <div className="colors">
                    {colorsToRender}
                </div>
            </div>
        );
    } else if (props.colors && props.colors.length > 0) {
        colorsToRender = [];
        let maxColors = 4;
        if (props.colors.length < maxColors) {
            maxColors = props.colors.length;
        }

        for (let clrIndex = 0; clrIndex < maxColors; clrIndex = clrIndex + 1) {
            colorsToRender.push(
                <div
                    key={clrIndex}
                    style={{
                        backgroundColor: props.colors[clrIndex],
                    }}
                />
            );
        }

        if (colorsToRender.length === 3) {
            className = className + " tricolor";
        } else if (colorsToRender.length === 1) {
            className = className + " monocolor";
        }

        colorsToRender = (
            <div>
                {colorsToRender}
            </div>
        );
    } else {
        className = className + " empty-color";
        colorsToRender = (
            <div>
                <img draggable="false" alt="color picker" src={STATIC_PATH + "color-palette.svg"} />
            </div>
        );
    }

    const handleClick = (e) => {
        e.stopPropagation(); // to prevent click on expand icon when tool is inside more options

        if (props.onClick) {
            props.onClick(e);
        }
    }

    icon = (
        <ItemToolColorStyled
            className={className}
            data-tooltip-id={tooltipId}
            onClick={handleClick}
        >
            {colorsToRender}
            {props.children}
        </ItemToolColorStyled>
    );

    if (
        props.langId
        && (
            !props.ignoreTooltipIfActive
            || (props.ignoreTooltipIfActive && !props.isActive)
        )
    ) {
        icon = (
            <CustomTooltipComponent
                tooltipId={tooltipId}
                dataTooltip={props.langId}
                tooltipPosition="bottom"
            >
                {icon}
            </CustomTooltipComponent>
        );
    }

    return icon;
}