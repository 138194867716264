import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getAudioSource } from "../../helper/URLHelper";
import { checkAudioBuffer } from "../../helper/checkAudioBuffer";
import useNotify from "../../helper/hooks/useNotify";
import { updateTimelineTime } from "../../redux/actions/timelineUtils";
import { resetDetachData } from "../../redux/actions/appUtils";
import Spinner, { SPINNER_TYPE } from "../../common-components/Spinner";
import Backdrop from "../../common-components/BackDrop";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";

const Container = styled.div`
  .spinner-wrapper {
    height: 50px;
    width: 75px;
    margin: auto;
  }
`;

const AudioDetacher = () => {
  const { success, warn } = useNotify();
  const dispatch = useDispatch();
  const detachData = useSelector((state) => state.app.get("detachAudioData"));

  const [isLoading, setIsLoading] = useState(false);

  const audioDetachHandler = async (item) => {
    setIsLoading(true);
    const { src } = getAudioSource({
      item: {
        type: item.get("type"),
        subType: item.get("subType"),
        src: item.get("src"),
      },
    });
    const hasBuffer = await checkAudioBuffer(src);
    if (hasBuffer) {
      dispatch(updateTimelineTime(detachData.toJS()));
      success("Audio detached Successfully! 🎧");
    } else {
      warn("No audio to detach from the video! 🎧");
    }
    dispatch(resetDetachData());
    setIsLoading(false);
  };

  useEffect(() => {
    if (detachData) {
      detachData.get("toUpdate").forEach((toUpdate) => {
        if (toUpdate.get("isAdd")) {
          audioDetachHandler(toUpdate.get("newItemData"));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detachData]);

  return (
    isLoading && (
      <Backdrop
        background="rgba(0,0,0,0.5)"
        height="100vh"
        width="100vw"
        zIndex={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <div className="spinner-wrapper">
            <Spinner type={SPINNER_TYPE.ELLIPSIS_SPINNER} />
          </div>
          <Typography
            innerContent="Detaching Audio....."
            display="block"
            color={"white"}
            font={font.boldSmall_22}
          />
        </Container>
      </Backdrop>
    )
  );
};

export default AudioDetacher;
