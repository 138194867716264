/** @description check if alpha is valid */
export const validateAlpha = (alpha) => {
  return alpha === undefined || alpha === null || Number.isNaN(alpha)
    ? 100
    : alpha;
};

const scaleWithinRange = (orgMin, orgMax, reqMin, reqMax, orgNum) => {
  const percentInRange = (orgNum - orgMin) / (orgMax - orgMin);
  return (reqMax - reqMin) * percentInRange + reqMin;
};

/** @description convert hexAlpha range to rgbAlpha range */
export const convertAlphaRange = (alpha) => {
  return alpha === 100
    ? ""
    : alpha === 0
    ? "00"
    : Math.floor(scaleWithinRange(0, 100, 0, 255, alpha)).toString(16);
};

export const buildSubtitleDomElement = (
  subtitleText,
  preventFormat = false
) => {
  if (preventFormat) {
    // for single word display center animation we remove all the extra space and new line
    return subtitleText; /* .split("\n").join(" ") .replace(/\s+/g, ' ').trim() */
  }
  const lines = subtitleText.split("\n");
  return lines
    .map((line) => {
      const trimmedLine = line.trimStart();
      /** @description tlLen - trimmedLine length, lLen - line length */
      const tlLen = trimmedLine.length;
      const lLen = line.length;
      if (tlLen !== lLen) {
        const diff = lLen - tlLen;
        let replaceStr = "";
        for (let i = 0; i < diff; i++) {
          replaceStr += "&nbsp;";
        }
        return `<span>${replaceStr}</span>${trimmedLine}`;
      }
      return line;
    })
    .join("<br/>");
};

// Helper

export function shouldPreventColorAndAddOutLine(effectIndex) {
  const EFFECTS = [3];
  return EFFECTS.includes(effectIndex);
}

export function checkHasOutLineEffect(effectIndex) {
  const EFFECTS = [5, 6];
  return EFFECTS.includes(effectIndex);
}

export function checkHasSplitLineEffect(effectIndex) {
  return false;
  // eslint-disable-next-line no-unreachable
  const EFFECTS = [0, 13];
  return EFFECTS.includes(effectIndex);
}