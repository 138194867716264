import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { font } from "../constants/font";
import getColorAtPercentage from "../helper/getColorAtPercentage";
import vmTheme from "../constants/theme";

/**
 * Render Tooltip
 */
const Tooltip = styled.div`
  position: relative;
  margin-left: ${(props) => props.marginLeft}%;
  top: -13px;
  display: flex;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.lightPrimaryColor} 0 0 no-repeat
    padding-box;
  border: 1px solid;
  color: ${(props) => props.theme.sliderTextColor};
  width: 23px;
  height: 18px;
  border: 1px solid transparent;
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: solid 5px;
    border-color: ${(props) => props.theme.lightPrimaryColor} transparent
      transparent;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => props.margin};
  justify-content: space-between;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="text"] {
    max-width: ${(props) => props.inputWidth};
    height: ${(props) => props.inputHeight};
    color: ${(props) => props.theme.panelPrimaryColor};
    text-align: ${(props) => props.textAlign};
    text-align: center;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 4px;
    opacity: 1;
    &:focus {
      border: 1px solid ${(props) => props.theme.secondaryColor};
      outline: none;
    }
  }
  input[type="range"] {
    pointer-events: auto;
    width: 100%;
    -webkit-appearance: none;
    height: 4px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    margin-bottom: 10px;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type="range"]::-webkit-slider-thumb {
    border: 2px solid ${(props) => props.theme.secondaryBorderColor};
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${(props) => props.theme.polarColor};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
    color: ${(props) => props.theme.secondaryColor};
  }
  input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.01);
    background: ${(props) => props.theme.secondaryBorderColor};
  }
`;

const SliderStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Typography} {
    letter-spacing: 0px;
    width: 39px;
    height: 19px;
  }
`;

function getPercentage(value, minValue, maxValue) {
  const range = maxValue - minValue;
  const adjustedValue = value - minValue;
  const percentage = (adjustedValue / range) * 100;

  // Adjust the percentage to ensure it stays within the 0-100 range
  if (percentage < 0) {
    return 0;
  }
  if (percentage > 100) {
    return 100;
  }
  return percentage;
}

const getBackgroundColor = (value, midPoint, isMidRange, theme) => {
  if (isMidRange) {
    if (value < 50) {
      return `linear-gradient(90deg, ${vmTheme[theme].sliderLinearVolume} 0%, ${vmTheme[theme].sliderLinearVolume} ${value}%, ${vmTheme[theme].secondaryBorderColor} ${value}%, ${vmTheme[theme].secondaryBorderColor} 50%, ${vmTheme[theme].sliderLinearVolume} 50%)`;
    }
    return `linear-gradient(to right, ${vmTheme[theme].sliderLinearVolume} 0%, ${vmTheme[theme].sliderLinearVolume} 50%, ${vmTheme[theme].secondaryBorderColor} 50%, ${vmTheme[theme].secondaryBorderColor} ${value}%, ${vmTheme[theme].sliderLinearVolume} ${value}%)`;
  }
  return `linear-gradient(to right, ${vmTheme[theme].secondaryBorderColor} 0%, ${vmTheme[theme].secondaryBorderColor} ${value}%, ${vmTheme[theme].sliderLinearVolume} ${value}%, ${vmTheme[theme].sliderLinearVolume} 100%)`;
};

const Slider = ({
  min,
  max,
  defaultValue,
  title,
  isMidRange,
  onCallBackAction,
  gradientColor,
  enableTooltip,
  showSliderValue,
  isPercentage,
  textStyle,
  margin,
  width,
  height,
  inputHeight,
  inputWidth,
  step,
  onMouseUp,
  iconSrc,
  onIconAction,
  iconValue,
}) => {
  const [value, setValue] = useState(defaultValue || min);
  const theme = useSelector((state) => state.app.get("theme"));
  const [showTooltip, setTooltip] = useState(false);
  const midPoint = (min + max) / 2;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = useCallback((targetValue) => {
    if (isPercentage) {
      setValue(getPercentage(targetValue, min, max));
    } else {
      setValue(targetValue);
    }
    if (onCallBackAction) {
      const percentage = getPercentage(targetValue, min, max);
      onCallBackAction({
        value: parseFloat(targetValue),
        color: gradientColor
          ? getColorAtPercentage(
              gradientColor ||
                getBackgroundColor(percentage, midPoint, isMidRange, theme),
              percentage
            )
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tooltipAction = useCallback(() => {
    setTooltip(!showTooltip);
  }, [showTooltip]);

  return (
    <>
      {iconSrc && (
        <img
          src={iconSrc}
          alt="vol-icon"
          id="volume-icon"
          onClick={() => {
            onIconAction();
            onChange(iconValue);
          }}
        />
      )}
      <Root
        className="slider-root"
        onMouseEnter={tooltipAction}
        onMouseLeave={tooltipAction}
        margin={margin}
        width={width}
        height={height}
        inputHeight={inputHeight}
        inputWidth={inputWidth}
      >
        {title || showSliderValue ? (
          <SliderStyle className="slider-style">
            <Typography
              content={title}
              font={font.mediumMini}
              color={vmTheme[theme].propertyColor}
              {...textStyle}
            />
            {showSliderValue ? (
              <input
                className="slider-value"
                type="text"
                onChange={(e) => onChange(e.target.value)}
                value={isPercentage ? `${value.toFixed()}%` : value}
              />
            ) : null}
          </SliderStyle>
        ) : null}
        {enableTooltip && (
          <Tooltip
            className="slider-tooltip"
            isVisible={showTooltip && enableTooltip}
            marginLeft={(getPercentage(value, min, max) - 50) * 1.9}
          >
            <Typography
              innerContent={value.toString()}
              font={font.normalMicro}
              color={vmTheme[theme].sliderTextColor}
            />
          </Tooltip>
        )}

        <input
          step={step}
          type="range"
          id="slider-input"
          min={min}
          max={max}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          onMouseUp={onMouseUp ? () => onMouseUp(Number(value)) : undefined}
          style={{
            background:
              gradientColor ||
              getBackgroundColor(
                getPercentage(value, min, max),
                midPoint,
                isMidRange,
                theme
              ),
          }}
        />
      </Root>
    </>
  );
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  defaultValue: 0,
  isMidRange: false,
  enableTooltip: false,
  showSliderValue: false,
  isPercentage: false,
  margin: "auto",
  width: "316px",
  height: "64px",
  inputHeight: "28px",
  inputWidth: "52px",
  step: "1",
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  title: PropTypes.string,
  isMidRange: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  showSliderValue: PropTypes.bool,
  onCallBackAction: PropTypes.func,
  gradientColor: PropTypes.string,
  textStyle: PropTypes.object,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  inputHeight: PropTypes.string,
  inputWidth: PropTypes.string,
  isPercentage: PropTypes.bool,
  step: PropTypes.string,
  iconSrc: PropTypes.string,
  onIconAction: PropTypes.func,
  onMouseUp: PropTypes.func,
  iconValue: PropTypes.number,
};

export default Slider;
