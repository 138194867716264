/// <reference path="../timeline-types.js" />

/* eslint-disable react/sort-comp */

import React from "react";
import { func } from "prop-types";
import { AUTO_SCROLL_INITIAL_STATE } from "../timeline-constants";
import { clamp } from "../timeline-helper";

class AutoScroll extends React.Component {
    constructor(props) {
        super(props);

        this.state = AUTO_SCROLL_INITIAL_STATE;

        this.startScroll = this.startScroll.bind(this);
        this.stopScroll = this.stopScroll.bind(this);
        this.updateAxis = this.updateAxis.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        this.cleanupAutoScroll = this.cleanupAutoScroll.bind(this);
        this.isScrollStatusChanged = this.isScrollStatusChanged.bind(this);

        this.timer = null;
    }

    componentDidMount() {
        if (this.state.scrollStatus.isScrolling) {
            this.cleanupAutoScroll();
            this.autoScroll();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.scrollStatus !== prevState.scrollStatus
            && this.isScrollStatusChanged(this.state, prevState)
        ) {
            this.cleanupAutoScroll();
            if (this.state.scrollStatus.isScrolling) {
                this.autoScroll();
            }
        }
    }

    componentWillUnmount() {
        this.cleanupAutoScroll();
    }

    /**
     * Function to check value equality of states
     * @param {AutoScrollState} state 
     * @param {AutoScrollState} prevState
     */
    isScrollStatusChanged(state, prevState) {
        return (
            state.scrollStatus.context !== prevState.context
            || state.scrollStatus.interval !== prevState.interval
            || state.scrollStatus.isHorizontalScroll !== prevState.isHorizontalScroll
            || state.scrollStatus.isVerticalScroll !== prevState.isVerticalScroll
            || state.scrollStatus.isScrolling !== prevState.isScrolling
            || state.scrollStatus.scrollLeftDisPerInterval !== prevState.scrollLeftDisPerInterval
            || state.scrollStatus.scrollTopDisPerInterval !== prevState.scrollTopDisPerInterval
        );
    }

    autoScroll() {
        const { scrollDeps, scrollStatus } = this.state;
        const { scrollLeftElRef, scrollTopElRef, scrollLeftBy, scrollTopBy, onAutoScroll } = scrollDeps;

        let scrollLeftCountAfterEnd = 0;
        let scrollTopCountAfterEnd = 0;

        const scroll = () => {
            const scrollLeftEl = scrollLeftElRef.current;
            const scrollTopEl = scrollTopElRef.current;
            const callbackParams = { scrollStatus };

            if (scrollLeftEl && scrollStatus.isHorizontalScroll) {
                let newScrollLeft = scrollLeftEl.scrollLeft + scrollStatus.scrollLeftDisPerInterval;
                const reachedScrollEnd = (
                    // towards left
                    (scrollStatus.scrollLeftDisPerInterval < 0 && newScrollLeft <= 0)
                    // towards right
                    || (scrollStatus.scrollLeftDisPerInterval > 0 && newScrollLeft + scrollLeftEl.offsetWidth >= scrollLeftEl.scrollWidth)
                    // not possible unless some config is incorrect, but just in case
                    || scrollStatus.scrollLeftDisPerInterval === 0
                );

                if (reachedScrollEnd) {
                    scrollLeftCountAfterEnd += 1;
                    newScrollLeft = clamp(newScrollLeft, 0, scrollLeftEl.scrollWidth - scrollLeftEl.offsetWidth);
                } else {
                    scrollLeftCountAfterEnd = 0;
                }

                callbackParams.scrollLeft = newScrollLeft;
                callbackParams.scrollLeftCountAfterEnd = scrollLeftCountAfterEnd;

                if (typeof scrollLeftBy === "function") {
                    scrollLeftBy({ left: scrollStatus.scrollLeftDisPerInterval });
                } else {
                    scrollLeftEl.scrollBy({
                        left: scrollStatus.scrollLeftDisPerInterval,
                    });
                }
            }

            if (scrollTopEl && scrollStatus.isVerticalScroll) {
                let newScrollTop = scrollTopEl.scrollTop + scrollStatus.scrollTopDisPerInterval;
                const reachedScrollEnd = (
                    // towards top
                    (scrollStatus.scrollTopDisPerInterval < 0 && newScrollTop <= 0)
                    // towards bottom
                    || (scrollStatus.scrollTopDisPerInterval > 0 && newScrollTop + scrollTopEl.offsetHeight >= scrollTopEl.scrollHeight)
                    // not possible unless some config is incorrect, but just in case
                    || scrollStatus.scrollTopDisPerInterval === 0
                );

                if (reachedScrollEnd) {
                    scrollTopCountAfterEnd += 1;
                    newScrollTop = clamp(newScrollTop, 0, scrollTopEl.scrollHeight - scrollTopEl.offsetHeight);
                } else {
                    scrollTopCountAfterEnd = 0;
                }

                callbackParams.scrollTop = newScrollTop;
                callbackParams.scrollTopCountAfterEnd = scrollTopCountAfterEnd;

                if (typeof scrollTopBy === "function") {
                    scrollTopBy({ top: scrollStatus.scrollTopDisPerInterval });
                } else {
                    scrollTopEl.scrollBy({ top: scrollStatus.scrollTopDisPerInterval });
                }
            }

            if (callbackParams.scrollLeft !== undefined || callbackParams.scrollTop !== undefined) {
                onAutoScroll(callbackParams);
            }

            if (
                // no element found
                (!scrollLeftEl && !scrollTopEl)
                // autoscroll stopped for all directions
                || (!scrollStatus.isHorizontalScroll && !scrollStatus.isVerticalScroll)
            ) {
                this.stopScroll();
            }
        };

        this.timer = setInterval(scroll, scrollStatus.interval);
    }

    cleanupAutoScroll() {
        clearInterval(this.timer);
    }

    /**
     * triggers autoscroll
     * @param {StartScrollParams} params
     */
    startScroll(params = {}) {
        const { scrollDeps, scrollParams } = params;
        this.setState({
            scrollStatus: { isScrolling: true, ...scrollParams },
            scrollDeps: { ...scrollDeps },
        });
    }

    stopScroll() {
        this.setState(AUTO_SCROLL_INITIAL_STATE);
        this.cleanupAutoScroll();
    }

    /**
     * use this function to stop scroll for specific direction
     * @param {AutoScrollUpdateAxisParams} params
     */
    updateAxis(params = {}) {
        const { isHorizontalScroll, isVerticalScroll } = params;
        let toUpdate = this.state.scrollStatus;

        if (isHorizontalScroll !== undefined) {
            toUpdate = { ...toUpdate, isHorizontalScroll };
        }
        if (isVerticalScroll !== undefined) {
            toUpdate = { ...toUpdate, isVerticalScroll };
        }

        if (
            toUpdate.isScrolling
            && !toUpdate.isHorizontalScroll
            && !toUpdate.isVerticalScroll
        ) {
            this.stopScroll();
        } else if (toUpdate !== this.state.scrollStatus) {
            this.setState({ scrollStatus: toUpdate });
        }
    }

    render() {
        return this.props.children({
            startScroll: this.startScroll,
            stopScroll: this.stopScroll,
            updateAxis: this.updateAxis,
            scrollStatus: this.state.scrollStatus,
        });
    }
}

AutoScroll.propTypes = {
    children: func,
};

export default AutoScroll;
