import React, { useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  MenuSection,
  MenuContainer,
  MenuList,
  ToggleSection,
  CollapseIcon,
  ToggleWrapper,
  Icon,
  List,
  IconWrapper,
} from "./sidebar-components";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import {
  setPropertyPanel,
  setExpandPanel,
  setPropertyWindow,
} from "../../redux/actions/appUtils";
import { PANEL, PANEL_ITEMS, VIDEO_TYPES } from "../../constants";
import { STATIC_PATH } from "../../constants/config";
import Panel from "../panels/panel";
import CustomTooltipComponent from "../../common-components/CustomTooltip";
import content from "../../constants/content";
import PropertyWindow from "../panels/propertywindow/propertywindow";
import vmTheme from "../../constants/theme";

const SideBar = ({
  selectedPanel,
  setProperty,
  isExpand,
  setExpand,
  isPropWindowOpen,
  propWindowComponent,
  animatePropertyWindow,
  animateLibrary,
  setPropertyWindow,
  workspaceStage,
  textPropertyShortcut,
  shortcutName,
  swapInfo,
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  const workspaceItems = useSelector((state) =>
    state.projectDetails.get("workspaceItems").filter((item) => {
      return VIDEO_TYPES.includes(item.get("type"));
    })
  );

  /**
   * Function to handle tab toggle using shortcuts.
   * @param {string} shortcut
   */
  const handleShortcuts = (shortcut) => {
    const tabIndex = parseInt(shortcut.substr(3), 10);
    setProperty(PANEL_ITEMS(theme)[tabIndex].name, true);
  };

  const closePropWindow = () => {
    setPropertyWindow({ component: "" });
    setExpand(false);
  };

  useEffect(() => {
    if (shortcutName && shortcutName !== "") {
      handleShortcuts(shortcutName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutName]);

  const isDisable = useCallback(
    (name, videoItems) => {
      let disabled = false;
      if (swapInfo) {
        const panelName = swapInfo.get("panelName");
        const itemType = swapInfo.get("itemType");
        const container = swapInfo.get("container");
        if (
          panelName === "IMAGE" ||
          panelName === "PROPERTIES" ||
          panelName === "GIPHY" ||
          itemType === "IMAGE" ||
          itemType === "PROPERTIES" ||
          itemType === "GIPHY"
        ) {
          disabled = !(
            name === "PROPERTIES" ||
            name === "IMAGE" ||
            name === "GIPHY" ||
            name === "UPLOAD"
          );
        } else if (
          panelName === "VIDEO" ||
          (itemType === "VIDEO" && container === "workspaceItems")
        ) {
          disabled = !(
            (name === "VIDEO" || name === "UPLOAD") &&
            container === "workspaceItems"
          );
        } else if (
          panelName === "MUSIC" ||
          itemType === "MUSIC" ||
          container === "audios"
        ) {
          disabled = !(
            (name === "MUSIC" || name === "UPLOAD") &&
            container === "audios"
          );
        }
      } else if (name === "TRANSITIONS") {
        disabled = !(videoItems >= 2);
      }

      return disabled;
    },
    [swapInfo]
  );

  const ITEMS = useSelector((state) => state.library.panelItems);

  let propWindowMaxWidth = "";
  if (
    selectedPanel === PANEL.SUBTITLE ||
    propWindowComponent === "subtitle_settings"
  ) {
    propWindowMaxWidth = PANEL.SUBTITLE;
  }

  return (
    <MenuSection className="sidebar-tool-bar" isExpand={isExpand}>
      <MenuContainer>
        <MenuList>
          {ITEMS.map(
            ({ name, src, selectedSrc, content, isLibrary = false }) => {
              const isSelected = selectedPanel === name && !isPropWindowOpen;
              return (
                <List
                  key={`${name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setProperty(name, true);
                  }}
                  restricted={isLibrary}
                  isSelected={isSelected}
                  isDisable={isDisable(name, workspaceItems.size)}
                >
                  <IconWrapper isSelected={isSelected} restricted={isLibrary}>
                    <Icon src={isSelected ? src : selectedSrc} alt={content} />
                  </IconWrapper>
                  <Typography
                    display="block"
                    font={font.normalMicroMini}
                    color={
                      isSelected
                        ? vmTheme[theme].sideBarproperty
                        : vmTheme[theme].secondaryColor
                    }
                    content={content}
                    align="center"
                    cursor="pointer"
                    enableTrim={false}
                    margin={"0px auto"}
                  />
                </List>
              );
            }
          )}
        </MenuList>
      </MenuContainer>

      <ToggleSection
        id="toggle-section"
        isExpand={isExpand}
        animateWindow={animateLibrary}
        workspaceStage={workspaceStage}
        maxWidth={selectedPanel}
      >
        <ToggleWrapper isExpand={isExpand} maxWidth={selectedPanel}>
          <Panel selectedMenu={selectedPanel} />
        </ToggleWrapper>
        <CollapseIcon
          onClick={() => {
            setExpand(!isExpand);
            if (selectedPanel === PANEL.VERSION_HISTORY) {
              setProperty(PANEL.PROPERTIES);
            }
          }}
          workspaceStage={workspaceStage}
        >
          <CustomTooltipComponent
            tooltipId={"toggle-tooltip"}
            dataTooltip={content.TOGGLE_MENU}
            tooltipPosition="right"
          >
            <Icon
              data-tooltip-id={"toggle-tooltip"}
              src={`${STATIC_PATH}left-arrow.svg`}
              transform={!isExpand ? "rotate(180deg)" : "rotate(0deg)"}
              alt="arrow"
            />
          </CustomTooltipComponent>
        </CollapseIcon>
      </ToggleSection>
      <ToggleSection
        className={"property-tool-bar"}
        isPropertyWindow
        animateWindow={animatePropertyWindow}
        isExpand={isPropWindowOpen}
        maxWidth={propWindowMaxWidth}
        workspaceStage={workspaceStage}
      >
        <ToggleWrapper>
          <PropertyWindow textPropertyShortcut={textPropertyShortcut} />
        </ToggleWrapper>
        <CollapseIcon onClick={closePropWindow} workspaceStage={workspaceStage}>
          <CustomTooltipComponent
            tooltipId={"toggle-tooltip"}
            dataTooltip={content.TOGGLE_MENU}
            tooltipPosition="right"
          >
            <Icon
              data-tooltip-id={"toggle-tooltip"}
              src={`${STATIC_PATH}left-arrow.svg`}
              alt="arrow"
              width="7px"
              height="12px"
            />
          </CustomTooltipComponent>
        </CollapseIcon>
      </ToggleSection>
    </MenuSection>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProperty: bindActionCreators(setPropertyPanel, dispatch),
    setExpand: bindActionCreators(setExpandPanel, dispatch),
    setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
  };
};

const mapStateToProps = ({ app }) => {
  const selectedPanel = app.getIn(["propertyPanel", "selectedPanel"]);
  const isExpand = app.getIn(["propertyPanel", "isExpand"]);
  return {
    selectedPanel,
    isExpand,
    workspaceStage: app.get("workspaceStage"),
    isPropWindowOpen: app.getIn(["propertyWindow", "isOpened"]),
    propWindowComponent: app.getIn(["propertyWindow", "component"]),
    animatePropertyWindow: app.get("animatePropertyWindow"),
    animateLibrary: app.get("animateLibrary"),
    swapInfo: app.get("swapInfo"),
  };
};

SideBar.propTypes = {
  selectedPanel: PropTypes.string,
  setProperty: PropTypes.func,
  setExpand: PropTypes.func,
  isExpand: PropTypes.bool,
  isPropWindowOpen: PropTypes.bool,
  propWindowComponent: PropTypes.string,
  setPropertyWindow: PropTypes.func,
  workspaceStage: PropTypes.object,
  shortcutName: PropTypes.string,
  textPropertyShortcut: PropTypes.string,
  animatePropertyWindow: PropTypes.bool,
  animateLibrary: PropTypes.bool,
  swapInfo: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
