/* eslint-disable react/sort-comp */

import React, { Component, createContext, createRef } from "react";
import PropTypes from "prop-types";

export const TimelineScrollContext = createContext({ x: 0, y: 0 });
TimelineScrollContext.displayName = "TimelineScrollContext";
export const TimelineScrollUpdateContext = createContext({
  onScrollLeft: null,
  onScrollTop: null,
  registerScrollLeftEl: null,
  registerScrollTopEl: null,
});
TimelineScrollUpdateContext.displayName = "TimelineScrollUpdateContext";

class TimelineScrollProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollPos: { x: 0, y: 0 },
    };

    this.updateScrollPos = this.updateScrollPos.bind(this);
    this.onScrollLeft = this.onScrollLeft.bind(this);
    this.onScrollTop = this.onScrollTop.bind(this);
    this.unregisterScrollEl = this.unregisterScrollEl.bind(this);
    this.registerScrollEl = this.registerScrollEl.bind(this);
    this.registerScrollLeftEl = this.registerScrollLeftEl.bind(this);
    this.registerScrollTopEl = this.registerScrollTopEl.bind(this);
    this.initObserver = this.initObserver.bind(this);
    this.cleanupObserver = this.cleanupObserver.bind(this);

    this.updateCallbacks = {
      onScrollLeft: this.onScrollLeft,
      onScrollTop: this.onScrollTop,
      registerScrollLeftEl: this.registerScrollLeftEl,
      registerScrollTopEl: this.registerScrollTopEl,
    };

    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.scrollLeftElRef = createRef(null);
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.scrollTopElRef = createRef(null);
    /** @type {React.MutableRefObject<ResizeObserver | null>} */
    this.observerRef = createRef(null);
  }

  componentDidMount() {
    this.initObserver();
  }

  componentWillUnmount() {
    this.cleanupObserver();
  }

  updateScrollPos(x, y) {
    this.setState((state) => {
      const newScrollPos = {
        x: x !== undefined ? x : state.scrollPos.x,
        y: y !== undefined ? y : state.scrollPos.y,
      };
      if (state.scrollPos.x !== newScrollPos.x || state.scrollPos.y !== newScrollPos.y) {
        state = {
          ...state,
          scrollPos: newScrollPos,
        };
      }
      return state;
    });
  }

  initObserver() {
    if (window.ResizeObserver) {
      const observer = new window.ResizeObserver(() => {
        const scrollLeftEl = this.scrollLeftElRef.current;
        const scrollTopEl = this.scrollTopElRef.current;
        if (scrollLeftEl && scrollTopEl) {
          this.updateScrollPos(scrollLeftEl.scrollLeft, scrollTopEl.scrollTop);
        }
      });

      if (this.scrollLeftElRef.current) {
        observer.observe(this.scrollLeftElRef.current);
      }
      if (this.scrollTopElRef.current) {
        observer.observe(this.scrollTopElRef.current);
      }
      this.observerRef.current = observer;
    }
  }

  cleanupObserver() {
    this.observerRef.current.disconnect();
    this.observerRef.current = null;
  }

  onScrollLeft() {
    const scrollEl = this.scrollLeftElRef.current;
    if (scrollEl) {
      this.updateScrollPos(scrollEl.scrollLeft, undefined);
    }
  }

  onScrollTop() {
    const scrollEl = this.scrollTopElRef.current;
    if (scrollEl) {
      this.updateScrollPos(undefined, scrollEl.scrollTop);
    }
  }

  /** @param {React.MutableRefObject<HTMLElement | null>} ref */
  unregisterScrollEl(ref) {
    const scrollEl = ref.current;
    const observer = this.observerRef.current;
    if (scrollEl && observer) {
      observer.unobserve(scrollEl);
    }
    ref.current = null;
  }

  /**
   * @param {object} params
   * @param {HTMLElement | null} params.element
   * @param {React.MutableRefObject<HTMLElement | null>} params.ref
   */
  registerScrollEl({ element, ref } = {}) {
    if (element) {
      ref.current = element;
      const observer = this.observerRef.current;
      if (observer) {
        observer.observe(element);
      }
    }
  }

  /** @param {HTMLElement | null>} element */
  registerScrollLeftEl(element) {
    this.unregisterScrollEl(this.scrollLeftElRef);
    this.registerScrollEl({ element, ref: this.scrollLeftElRef });
  }

  /** @param {HTMLElement | null>} element */
  registerScrollTopEl(element) {
    this.unregisterScrollEl(this.scrollTopElRef);
    this.registerScrollEl({ element, ref: this.scrollTopElRef });
  }

  render() {
    return (
      <TimelineScrollContext.Provider value={this.state.scrollPos}>
        <TimelineScrollUpdateContext.Provider value={this.updateCallbacks}>
          {this.props.children}
        </TimelineScrollUpdateContext.Provider>
      </TimelineScrollContext.Provider>
    );
  }
}

TimelineScrollProvider.propTypes = {
  children: PropTypes.node,
};

export default TimelineScrollProvider;
