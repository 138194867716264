import { fromJS } from "immutable";
import content from "../../../constants/content";

export const ADJUSTMENT_KEYS = fromJS({
    bright: content.BRIGHTNESS,
    contrast: content.CONTRAST,
    hue: content.HUE,
    saturate: content.SATURATION,
    tint: content.TINT,
    blur: content.BLUR,
    vignette: content.VIGNETTE,
});

export const FILTER_LIST = fromJS([
    "ORIGINAL",
    "EPIC",
    "FESTIVE",
    "BLUR",
    "SUMMER",
    "AFTER GLOW",
    "SOLAR",
    "SELFIE",
    "CALI",
    "AQUA",
    "NORDIC",
    "WHIMSICAL",
    "RETRO",
    "EDGE",
    "DALLIANCE",
    "PEONY",
    "DARE",
    "ROSIE",
    "GREYSCALE",
    "STREET",
    "STREET-1",
    "SEPIA",
    "SEPIA-1",
    "SEPIA-2",
]);

export const PRESET_FILTERS = fromJS({
    "ORIGINAL"  : {"name": "Original",    "bright" :0,    "contrast" :0,   "hue" :0,    "saturate" :0,      "tint" :0,      "blur" :0,      "vignette" :0},
    "EPIC"      : {"name": "Epic",        "bright" :40,   "contrast" :40,  "hue" :0,    "saturate" :-60,    "tint" :0,      "blur" :0,      "vignette" :0},
    "FESTIVE"   : {"name": "Festive",     "bright" :30,   "contrast" :30,  "hue" :0,    "saturate" :100,    "tint" :0,      "blur" :0,      "vignette" :0},
    "BLUR"      : {"name": "Blur",        "bright" :0,    "contrast" :0,   "hue" :0,    "saturate" :0,      "tint" :0,      "blur" :20,     "vignette" :0},
    "SUMMER"    : {"name": "Summer",      "bright" :40,   "contrast" :60,  "hue" :0,    "saturate" :70,     "tint" :-45,    "blur" :0,      "vignette" :0},
    "AFTER GLOW": {"name": "After glow",  "bright" :30,   "contrast" :40,  "hue" :0,    "saturate" :-24,    "tint" :0,      "blur" :-60,    "vignette" :0},
    "SOLAR"     : {"name": "Solar",       "bright" :44,   "contrast" :20,  "hue" :0,    "saturate" :84,     "tint" :0,      "blur" :0,      "vignette" :0},
    "SELFIE"    : {"name": "Selfie",      "bright" :50,   "contrast" :24,  "hue" :56,   "saturate" :62,     "tint" :-47,    "blur" :-14,    "vignette" :0},
    "CALI"      : {"name": "Cali",        "bright" :78,   "contrast" :0,   "hue" :46,   "saturate" :82,     "tint" :0,      "blur" :-32,    "vignette" :0},
    "AQUA"      : {"name": "Aqua",        "bright" :76,   "contrast" :0,   "hue" :18,   "saturate" :0,      "tint" :29,     "blur" :-34,    "vignette" :0},
    "NORDIC"    : {"name": "Nordic",      "bright" :48,   "contrast" :-50, "hue" :0,    "saturate" :-80,    "tint" :0,      "blur" :-14,    "vignette" :0},
    "RETRO"     : {"name": "Retro",       "bright" :28,   "contrast" :30,  "hue" :46,   "saturate" :60,     "tint" :20,     "blur" :0,      "vignette" :0},
    "WHIMSICAL" : {"name": "Whimsical",   "bright" :92,   "contrast" :22,  "hue" :48,   "saturate" :-110,   "tint" :-62,    "blur" :-50,    "vignette" :0},
    "EDGE"      : {"name": "Edge",        "bright" :40,   "contrast" :8,   "hue" :50,   "saturate" :50,     "tint" :55,     "blur" :-120,   "vignette" :0},
    "DALLIANCE" : {"name": "Dalliance",   "bright" :50,   "contrast" :20,  "hue" :0,    "saturate" :-50,    "tint" :-100,   "blur" :-40,    "vignette" :0},
    "PEONY"     : {"name": "Peony",       "bright" :40,   "contrast" :20,  "hue" :-30,  "saturate" :120,    "tint" :-70,    "blur" :-60,    "vignette" :0},
    "DARE"      : {"name": "Dare",        "bright" :30,   "contrast" :30,  "hue" :-20,  "saturate" :130,    "tint" :0,      "blur" :-30,    "vignette" :0},
    "ROSIE"     : {"name": "Rosie",       "bright" :30,   "contrast" :80,  "hue" :0,    "saturate" :-40,    "tint" :-85,    "blur" :-80,    "vignette" :0},
    "GREYSCALE" : {"name": "Greyscale",   "bright" :40,   "contrast" :10,  "hue" :0,    "saturate" :-200,   "tint" :0,      "blur" :0,      "vignette" :0},
    "STREET"    : {"name": "Street",      "bright" :0,    "contrast" :30,  "hue" :0,    "saturate" :-200,   "tint" :0,      "blur" :-50,    "vignette" :0},
    "STREET-1"  : {"name": "Street-1",    "bright" :0,    "contrast" :40,  "hue" :0,    "saturate" :-200,   "tint" :0,      "blur" :-100,   "vignette" :0},
    "SEPIA"     : {"name": "Sepia",       "bright" :40,   "contrast" :10,  "hue" :0,    "saturate" :-200,   "tint" :-50,    "blur" :0,      "vignette" :0},
    "SEPIA-1"   : {"name": "Sepia-1",     "bright" :40,   "contrast" :10,  "hue" :0,    "saturate" :-80,    "tint" :-50,    "blur" :0,      "vignette" :0},
    "SEPIA-2"   : {"name": "Sepia-2",     "bright" :120,  "contrast" :100, "hue" :0,    "saturate" :-200,   "tint" :-50,    "blur" :-60,    "vignette" :80},
});
