/* eslint-disable react/no-danger, prefer-template, camelcase */

import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import { _iHSL10, hex2rgb } from "../../helper/colorHelper";
import { CustomBGRenderContainer } from "./workspace-components";

class CustomBGRender extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, svgElement: null };
    /** @type {AbortController | null} */
    this.abortController = null;
    this.loadSVGData = this.loadSVGData.bind(this);
    this.setColors = this.setColors.bind(this);
    this.setGradient = this.setGradient.bind(this);
    this.domParser = new DOMParser();
    this.svgContainerRef = createRef();
  }

  componentDidMount() {
    this.loadSVGData(this.props.src);
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.loadSVGData(this.props.src);
    } else if (
      this.props.colors !== prevProps.colors &&
      this.props.src === prevProps.src &&
      this.svgContainerRef.current
    ) {
      this.setColors(
        this.props.colors,
        this.svgContainerRef.current,
        this.props.hasGradient
      );
    }
  }

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  setColors(colors, element, hasGradient) {
    colors.entrySeq().forEach(([_className, _color]) => {
      const cols = element.querySelectorAll(`[class*='${_className}']`);
      for (let i = 0; i < cols.length; i += 1) {
        if (_className === "prop1" && hasGradient === "1") {
          this.setGradient(
            _color,
            _iHSL10(_color.substr(1), -0.009, -0.622, -0.221),
            element
          );
          return;
        }

        let resa = cols[i].className.animVal.replace(/zf/g, ".");
        resa = resa.split("_");
        if (cols[i].firstChild && !cols[i].className.animVal.includes("Mc")) {
          if (resa.length !== 1) {
            const newColor = _iHSL10(
              _color.substr(1),
              parseFloat(resa[1]),
              parseFloat(resa[2]),
              parseFloat(resa[3])
            );
            if (cols[i].children.length > 0) {
              if (cols[i].firstChild.hasAttribute("fill"))
                cols[i].firstChild.setAttribute("fill", newColor);
              if (cols[i].firstChild.hasAttribute("stroke"))
                cols[i].firstChild.setAttribute("stroke", newColor);
            }
          } else if (cols[i].children.length > 0) {
            if (cols[i].firstChild.hasAttribute("fill"))
              cols[i].firstChild.setAttribute("fill", _color);
            if (cols[i].firstChild.hasAttribute("stroke"))
              cols[i].firstChild.setAttribute("stroke", _color);
          }
        } else if (cols[i].hasAttribute("fill")) {
          cols[i].setAttribute("fill", _color);
        }
      }
    });
  }

  setGradient(color, subColor, element) {
    const color1 = hex2rgb(color.substr(1));
    const color2 = hex2rgb(subColor.substr(1));

    const r1 = color1[0];
    const g1 = color1[1];
    const b1 = color1[2];
    // color2
    const r2 = color2[0];
    const g2 = color2[1];
    const b2 = color2[2];

    const r_mid = r1 + (r2 - r1) / 2;
    const g_mid = g1 + (g2 - g1) / 2;
    const b_mid = b1 + (b2 - b1) / 2;

    const defs = element.querySelectorAll("stop");
    defs[0].setAttribute("stop-color", "rgb(" + r1 + "," + g1 + "," + b1 + ")");
    defs[1].setAttribute(
      "stop-color",
      "rgb(" + r_mid + "," + g_mid + "," + b_mid + ")"
    );
    defs[2].setAttribute("stop-color", "rgb(" + r2 + "," + g2 + "," + b2 + ")");
  }

  loadSVGData(srcVal) {
    this.setState({ isLoading: true });

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    fetch(srcVal, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "image/svg+xml",
      },
      signal: this.abortController.signal,
    })
      .then((res) => res.text())
      .then((svgText) => {
        const parsedSvg = this.domParser.parseFromString(svgText, "text/xml");
        if (this.props.colors) {
          this.setColors(
            this.props.colors,
            parsedSvg.documentElement,
            this.props.hasGradient
          );
        }
        const svgElement = (
          <CustomBGRenderContainer
            key={this.props.data_id}
            ref={this.svgContainerRef}
            id={this.props.data_id + "inner"}
            data-id={this.props.data_id}
            dangerouslySetInnerHTML={{
              __html: parsedSvg.documentElement.outerHTML,
            }}
          />
        );
        this.setState({ isLoading: false, svgElement });
      })
      .catch(() => {});
  }

  render() {
    let toRender = null;
    if (this.state.isLoading) {
      toRender = (
        <CustomBGRenderContainer
          key={this.props.data_id}
          id={this.props.data_id + "inner"}
          data-id={this.props.data_id}
        >
          <img alt="bg-loader" src={this.props.proxyImg} />
        </CustomBGRenderContainer>
      );
    } else {
      toRender = this.state.svgElement;
    }
    return toRender;
  }
}

CustomBGRender.propTypes = {
  colors: PropTypes.object,
  data_id: PropTypes.string,
  hasGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  proxyImg: PropTypes.string,
  src: PropTypes.string,
};

export default CustomBGRender;
