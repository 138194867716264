import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Typography from "./Typography";
import Backdrop from "./BackDrop";
import CheckBox from "./CheckBox";
import Action from "./Action";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const { normal } = font;

const Observer = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

/**
 * Render Root div
 */
const RootDiv = styled.div`
  position: relative;
`;

/**
 * Render the button with or without icon
 * Customizable Component
 */
const DropDown = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.background};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : "none"};
  border-radius: ${(props) => props.border};
  width: fit-content;
  cursor: pointer;

  img {
    padding: 4px;
    width: ${(props) => props.iconWidth};
    height: ${(props) => props.iconHeight};
    user-drag: none;
    user-select: none;
  }
  label {
    margin: 0px 4px;
    cursor: pointer;
    user-select: none;
  }
  &:hover {
    background: ${(props) => props.hoverColor};
    label {
      color: ${(props) => props.hoverText};
    }
  }
`;

/**
 * Render the dropdown option
 * Customizable Component
 */
const OptionsWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.polarColor};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.border};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  z-index: 2;
  box-shadow: 0px 1px 3px ${props => props.theme.rgbaBlack};
  opacity: 1;
  background: ${props => props.theme.polarColor} 0% 0% no-repeat padding-box;
  position: absolute;

  &.bottom {
    inset: 0px auto auto 0px;
    transform: translate(0px, 32px);
  }
  &.top {
    inset: auto auto 0px 0px;
    transform: translate(0px, -32px);
  }
  label {
    display: flex;
    flex-direction: column;
  }
  label:hover {
    background: ${(props) => props.background};
    cursor: pointer;
  }
  @media (max-width: 768px) {
    bottom: 0 !important;
    top: auto !important;
    left: 0;
    right: 0;
    position: fixed;
    transform: none !important;
    z-index: 2;
    margin: 0;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -4px 32px ${props => props.theme.rgbaBlack};
    padding: 30px 20px 0px;
    > label {
      font: normal normal 600 16px/14px Poppins;
      letter-spacing: 0px;
      color: ${props => props.theme.panelPrimaryColor};
      text-align: center;
    }
    > div {
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        width: 50%;
      }
    }
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${(props) => props.margin};
`;

const OptionItem = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  padding: ${(props) => props.padding};
  align-items: center;
`;

/**
 * Dropdown component
 * @param {*} props
 * @returns
 */
const DropdownComp = (props) => {
  const {
    icon,
    options,
    labelProps,
    valueProps,
    checkBoxOption,
    checkBoxBorderRadius,
    checkBoxColor,
    actionText,
    header,
    actionFont,
    headerFont,
    optionsMargin,
    onHandlerClick,
    children,
    optionsOpenHandler,
    onAction,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [boundaryClass, setBoundaryClass] = useState("bottom");

  const containerRef = useRef(null);
  const theme = useSelector((state) => state.app.get('theme'));
  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting && entry.intersectionRatio !== 0) {
      setBoundaryClass("top");
    }
  };

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
    // wait for the component to be rendered before using the ref
    setTimeout(() => {
      if (containerRef.current) {
        optionsOpenHandler(-containerRef.current.offsetWidth);
      }
    }, 0);
  };

  // const handleSelectedValue = (selectedOption) => {
  //   alert(selectedOption);
  // };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, Observer);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef]);
  return (
    <RootDiv>
      {showOptions && (
        <Backdrop onClick={() => setShowOptions(false)} zIndex={1} />
      )}
      {showOptions && (
        <OptionsWrapper
          display
          {...props}
          ref={containerRef}
          className={`${boundaryClass} options-wrapper`}
        >
          {header ? <Typography content={header} font={headerFont} /> : null}
          <Options margin={optionsMargin}>
            {options.map((option) => (
              // eslint-disable-next-line react/no-array-index-key
              <OptionItem key={option.id}>
                {checkBoxOption && (
                  <CheckBox
                    id={option.id}
                    borderRadius={checkBoxBorderRadius}
                    selectedValue={option}
                    checkBoxColor={checkBoxColor || vmTheme[theme].primaryBorderColor}
                    isChecked={option.isChecked}
                    label={
                      <Typography
                        content={option.label}
                        padding="6px"
                        {...labelProps}
                      />
                    }
                    onHandlerClick={onHandlerClick}
                  />
                )}
                {option.icon && <img src={option.icon} alt="" />}
              </OptionItem>
            ))}
          </Options>
          {actionText ? (
            <Action
              text={actionText}
              width="100%"
              font={actionFont}
              onClick={() => {
                setShowOptions(false);
                onAction();
              }}
            />
          ) : null}
          {children}
        </OptionsWrapper>
      )}
      <DropDown {...props} onClick={(event) => handleShowOptions(event)}>
        {props.icon && <img src={icon} alt="icon" />}
        {props.valueProps && <Typography enableTrim={false} {...valueProps} />}
      </DropDown>
    </RootDiv>
  );
};

/**
 * Default Props Value for DropComp
 */
DropdownComp.defaultProps = {
  text: "",
  icon: "",
  options: [],
  iconWidth: "",
  iconHeight: "",
  borderColor: "",
  background: "",
  border: "",
  labelProps: {},
  checkBoxOption: false,
  padding: "0px",
  checkBoxBorderRadius: "0px",
  headerFont: normal,
  actionFont: normal,
  optionsMargin: "10px 0px 0px",
  width: "fit-content",
  optionsOpenHandler: () => { },
};

/**
 * Initialize Prop Types for DropComp
 */
DropdownComp.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, id: PropTypes.string })
  ),
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  borderColor: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  labelProps: PropTypes.object,
  valueProps: PropTypes.object,
  checkBoxOption: PropTypes.bool,
  padding: PropTypes.string,
  checkBoxBorderRadius: PropTypes.string,
  checkBoxColor: PropTypes.string,
  header: PropTypes.string,
  actionText: PropTypes.string,
  headerFont: PropTypes.string,
  actionFont: PropTypes.string,
  optionsMargin: PropTypes.string,
  width: PropTypes.string,
  onHandlerClick: PropTypes.func,
  children: PropTypes.array,
  optionsOpenHandler: PropTypes.func,
  onAction: PropTypes.func,
};

export default DropdownComp;
