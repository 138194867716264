/* eslint-disable jsx-a11y/no-static-element-interactions, no-useless-escape, no-restricted-syntax */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  ColorPaletteContainer,
  ColorPickerButton,
  Loader,
  SubtitleStyleWrapper,
  TextButtonWrapper,
} from "./subtitleproperty-components";
import CustomTooltipComponent from "../../../../common-components/CustomTooltip";
import content from "../../../../constants/content";
import FontFamilyProperty from "../text/propertywindow-fontfamily";
import { ITEM_CONFIG, STATIC_PATH } from "../../../../constants/config";
import {
  checkFontFamilyLoadedPromise,
  getSubtitlesFontToLoad,
  loadSubtitleFontFamily,
} from "../../../../helper/fontLoadHelper";
import {
  TEXT_ALIGN,
  TEXT_STYLES,
  alignMentChangeOnSubTitleList,
  getSampleSubtitleText,
  getUpdatedSubTitleList,
} from "../../../../helper/subtitleHelper";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import Slider from "../../../zoom/zoom-slider";
import vmTheme from "../../../../constants/theme";
import useSubtitleStyle from "../../../../helper/hooks/useSubtitleStyle";
import TextStyleButton from "../../../../common-components/TextStyleButton";
import { ItemToolColorOption } from "../../../itemtoolbar/itemtoolbar-options";
import ColorPaletteWindow from "../color/propertywindow-colorpalette";
import { FontSizeList } from "../text/textproperty-components";
import { fontSizes } from "../../../text/text-constants";
import NumberInput from "../../../../common-components/NumberInputComponent";
import {
  setWorkspaceTextFocus,
  updateFontLoadStatus,
} from "../../../../redux/actions/appUtils";

const LINE_HEIGHT = "lineHeight";
const LETTER_SPACING = "letterSpacing";

const SubtitleTextStyle = () => {
  const dispatch = useDispatch();
  const fontSizeContainerRef = useRef();
  const inputFontSizeElmRef = useRef();
  const appReducer = useSelector((state) => state.app);
  const theme = appReducer.get("theme");
  const workspaceZoom = appReducer.get("zoomFactor");
  const workspaceWidth = appReducer.get("projectWidth");
  const workspaceHeight = appReducer.get("projectHeight");
  const loadedFonts = appReducer.getIn(["loadedFonts", "fonts"]);
  const projectDetails = useSelector((state) => state.projectDetails);
  const globalStyles = projectDetails.getIn(["subtitle", "textStyles"]);
  const globalTextEffects = projectDetails.getIn(["subtitle", "textEffects"]);
  const animData = projectDetails.getIn(["subtitle", "animData"]);
  const activeFontFamily = globalStyles.get("fontFamily");
  const activeFontName = globalStyles.get("fontFamilyName");
  const [globalTextStyles, updateGlobalTextStyles] = useSubtitleStyle({
    styleValues: globalStyles,
  });

  const sliderThumbStyle = {
    background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
    border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
    height: "16px",
    width: "16px",
    hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
    hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
  };

  const [isFontFamilyOpen, setIsFontFamilyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStyleLoading, setIsStyleLoading] = useState(false);
  const [letterSpace, setLetterSpace] = useState(0);
  const [lineHeight, setLineHeight] = useState(null);
  const [isColorPaletteOpen, setIsColorPaletteOpen] = useState(false);
  const [isUpdateBgColor, setIsUpdateBgColor] = useState(false);
  const [fontSizeFocused, setFontSizeFocused] = useState(false);
  const [fontSizeContainerBounds, setFontSizeContainerBounds] = useState(false);
  const [fontSize, setFontSize] = useState(
    parseFloat(projectDetails.getIn(["subtitle", "textStyles", "fontSize"]))
  );

  useEffect(() => {
    setLineHeight(
      projectDetails.getIn(["subtitle", "textStyles", LINE_HEIGHT])
    );
    setLetterSpace(
      parseFloat(
        projectDetails.getIn(["subtitle", "textStyles", LETTER_SPACING])
      )
    );
  }, [projectDetails]);

  useEffect(() => {
    updateGlobalTextStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStyles]);

  const toggleFontFamilyWindow = () => setIsFontFamilyOpen((prev) => !prev);

  const toggleColorPalette = () => {
    setIsColorPaletteOpen(!isColorPaletteOpen);
    if (isUpdateBgColor) {
      setIsUpdateBgColor(false);
    }
  };

  const textStyleBuilder = (action, value) => {
    const { BOLD, ITALIC, UNDERLINE, TOGGLE_CASE, FONT_SIZE, TEXT_ALIGN } =
      TEXT_STYLES;
    const { boldData, isBold, isItalic, isUnderline, textTransform } =
      globalTextStyles;
    switch (action) {
      case BOLD: {
        const toLoadFont = {
          fontFamily: isBold ? boldData.regular : boldData.bold,
          fontFamilyName: boldData.fontFamily,
          lineHeight: globalTextStyles.lineHeight,
          isBold: Number(!isBold),
          others: { isUserFont: false, cssUrl: "" },
        };
        const toUpdateStyle = {
          fontFamily: `${isBold ? boldData.regular : boldData.bold}, gfs`,
          lineHeight: globalTextStyles.lineHeight,
          isBold: Number(!isBold),
        };
        return { toLoadFont, toUpdateStyle };
      }
      case ITALIC: {
        const toLoadFont = {
          fontStyle: isItalic ? "normal" : "italic",
          isItalic: Number(!isItalic),
        };
        return { toLoadFont };
      }
      case UNDERLINE: {
        const toLoadFont = {
          textDecoration: isUnderline ? "none" : "underline",
          isUnderline: Number(!isUnderline),
        };
        return { toLoadFont };
      }
      case TOGGLE_CASE: {
        let currentTextTransform = "none";
        if (textTransform === "none") {
          currentTextTransform = "uppercase";
        } else if (textTransform === "uppercase") {
          currentTextTransform = "lowercase";
        } else if (textTransform === "lowercase") {
          currentTextTransform = "capitalize";
        } else if (textTransform === "capitalize") {
          currentTextTransform = "none";
        }
        const toLoadFont = {
          textTransform: currentTextTransform,
        };
        return { toLoadFont };
      }
      case FONT_SIZE: {
        setFontSize(value);
        const toLoadFont = {
          fontSize: `${value}px`,
        };
        return { toLoadFont };
      }
      case TEXT_ALIGN: {
        const toLoadFont = {
          textAlign: value,
        };
        return { toLoadFont };
      }
      default:
        break;
    }
    return null;
  };

  const onColorSelect = (selectedcolor) => {
    const toUpdate = [];
    const textStyles = {};
    if (isUpdateBgColor) {
      textStyles.background = selectedcolor;
    } else {
      textStyles.color = selectedcolor;
    }
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { textStyles },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  /**
   * Function to load the font.
   * @param {object} toLoadFont
   */
  const loadFont = async (toLoadFont) => {
    let fontToLoad = getSubtitlesFontToLoad({}, loadedFonts, toLoadFont);
    const sampleSubtitleText = getSampleSubtitleText(
      projectDetails.get("subtitle").toJS()
    );
    if (fontToLoad.length) {
      fontToLoad = await loadSubtitleFontFamily(fontToLoad, sampleSubtitleText);
    } else {
      await checkFontFamilyLoadedPromise(toLoadFont.fontFamily, false, sampleSubtitleText);
    }
  };

  const getUpdatedDataList = (toLoadFont, toUpdateStyle = toLoadFont, isAlignText = false, alignment) => {
    const toUpdate = [];
    const langId = projectDetails.get("defaultSubtitle");
    let updatedDataList;
    if (isAlignText) {
      updatedDataList = alignMentChangeOnSubTitleList(
        projectDetails.getIn(["subtitle", "data"]),
        alignment,
        workspaceWidth,
        globalTextEffects
      );
    } else {
      updatedDataList = getUpdatedSubTitleList(
        projectDetails.getIn(["subtitle", "data"]),
        toUpdateStyle,
        workspaceWidth,
        workspaceHeight,
        globalStyles,
        globalTextEffects
      );
    }

    Object.entries(updatedDataList).forEach(([dropId, data]) => {
      Object.entries(data).forEach(([id, { toUpdatePos }]) => {
        // timelineId is needed only for delete action. So, ignoring timelineId.
        toUpdate.push({
          container: "subtitleData",
          id,
          dropId,
          langId,
          toUpdate: toUpdatePos,
        });
      });
    });

    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { textStyles: toLoadFont },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const handleFontFamilySelect = async (selectedFont) => {
    setIsLoading(true);
    dispatch(updateFontLoadStatus(true));
    const toLoadFont = {
      fontFamily: selectedFont.get("regular"),
      fontFamilyName: selectedFont.get("fontFamily"),
      lineHeight: selectedFont.get("lineHeight"),
      others: { isUserFont: false, cssUrl: "" },
    };
    const toUpdateStyle = {
      fontFamily: `${selectedFont.get("regular")}, gfs`,
      lineHeight: selectedFont.get("lineHeight"),
    };
    await loadFont(toLoadFont);
    getUpdatedDataList(toLoadFont, toUpdateStyle);
    setIsLoading(false);
    dispatch(updateFontLoadStatus(false));
  };

  const onBoldClick = async () => {
    if (globalTextStyles.boldData.boldCanApply) {
      setIsStyleLoading(true);
      const { boldData, isBold } = globalTextStyles;
      if (isBold) {
        boldData.changeBold = false;
      }
      const { toLoadFont, toUpdateStyle } = textStyleBuilder(TEXT_STYLES.BOLD);
      await loadFont(toLoadFont);
      getUpdatedDataList(toLoadFont, toUpdateStyle);
      setIsStyleLoading(false);
    }
  };
  const isBoldDisabled = !globalTextStyles.boldData.boldCanApply;
  const isBoldActive = globalTextStyles.isBold;

  const onItalicClick = async () => {
    const { toLoadFont } = textStyleBuilder(TEXT_STYLES.ITALIC);
    await loadFont(toLoadFont);
    getUpdatedDataList(toLoadFont);
  };
  const isItalicDisabled = false;
  const isItalicActive = globalTextStyles.isItalic;

  const onUnderlineClick = async () => {
    if (!animData.get("isAnimation")) {
      const { toLoadFont } = textStyleBuilder(TEXT_STYLES.UNDERLINE);
      await loadFont(toLoadFont);
      getUpdatedDataList(toLoadFont);
    }
  };
  const isUnderlineActive = !animData.get("isAnimation") && globalTextStyles.isUnderline;

  const alignmentHandler = async (alignment) => {
    const { textAlign } = globalTextStyles;
    if (textAlign !== alignment) {
      const { toLoadFont } = textStyleBuilder(TEXT_STYLES.TEXT_ALIGN, alignment);
      await loadFont(toLoadFont);
      getUpdatedDataList(toLoadFont, undefined, true, alignment);
    }
  };
  const activeTextAlign = globalTextStyles.textAlign;

  const toggleCaseHandler = async () => {
    const { toLoadFont } = textStyleBuilder(TEXT_STYLES.TOGGLE_CASE);
    await loadFont(toLoadFont);
    getUpdatedDataList(toLoadFont);
  };
  const currentTextTransform = globalTextStyles.textTransform;

  const handleFontSizeClicked = async (size) => {
    if (size !== parseFloat(globalTextStyles.fontSize)) {
      const { toLoadFont } = textStyleBuilder(TEXT_STYLES.FONT_SIZE, size);
      await loadFont(toLoadFont);
      getUpdatedDataList(toLoadFont);
    }
  };

  const getFontSizeValue = () => {
    if (inputFontSizeElmRef && inputFontSizeElmRef.current) {
      return inputFontSizeElmRef.current.value;
    }
    return "";
  };

  const toggleSizes = () => {
    const toUpdate = {
      fontSizeFocused: !fontSizeFocused,
    };

    if (toUpdate.fontSizeFocused && fontSizeContainerRef.current) {
      toUpdate.fontSizeContainerBounds =
        fontSizeContainerRef.current.getBoundingClientRect();
    } else {
      toUpdate.fontSizeFocused = false;
      toUpdate.fontSizeContainerBounds = null;
    }
    setFontSizeFocused(toUpdate.fontSizeFocused);
    setFontSizeContainerBounds(toUpdate.fontSizeContainerBounds);
  };

  const handleFontsizeChange = () => {
    setFontSizeFocused(false);
    const fontSizeValue = getFontSizeValue();
    if (
      fontSizeValue !== "" &&
      parseFloat(fontSizeValue) >= 6 &&
      parseFloat(fontSizeValue) <= 999
    ) {
      let sizeValue = fontSizeValue;
      const splitValue = sizeValue.split(".");
      if (splitValue.length > 1 && splitValue[1].length > 2)
        sizeValue = `${splitValue[0]}.${splitValue[1].substr(0, 2)}`;
      handleFontSizeClicked(parseFloat(sizeValue));
    } else if (fontSizeValue === "") {
      // Commented to prevent NaN.
      // this.setState({ fontSize: parseFloat(fontSizeValue) });
    } else {
      let fontSize = parseFloat(fontSizeValue);
      if (parseFloat(fontSizeValue) > 999) {
        fontSize = globalTextStyles.fontSize;
        if (Number(fontSize) === fontSize && fontSize % 1 !== 0)
          fontSize = parseFloat(fontSize, 10);
      }
      setFontSize(fontSize);
    }
  };

  const handleChangeFontSize = (action) => {
    const minValue = 6;
    const maxValue = 999;
    if (inputFontSizeElmRef && inputFontSizeElmRef.current) {
      let value = getFontSizeValue();
      value = Math.round(value); // empty string returns 0
      let updateValue = minValue;
      if (
        value !== "" &&
        parseFloat(value) >= minValue &&
        parseFloat(value) <= maxValue
      ) {
        if (action === "INCREASE" && parseFloat(value) !== maxValue) {
          updateValue = value + 1;
        } else if (action === "DECREASE" && parseFloat(value) !== minValue) {
          updateValue = value - 1;
        }
        if (
          (action === "INCREASE" && parseFloat(value) === maxValue) ||
          (action === "DECREASE" && parseFloat(value) === minValue)
        ) {
          updateValue = parseFloat(value);
        }
      } else if (
        value === "" &&
        parseFloat(value) < minValue &&
        parseFloat(value) > maxValue
      ) {
        if (action === "INCREASE" && parseFloat(value) < minValue) {
          updateValue = minValue;
        } else if (action === "DECREASE" && parseFloat(value) > maxValue) {
          updateValue = maxValue;
        }
      }
      inputFontSizeElmRef.current.value = updateValue;
      handleFontsizeChange();
    }
  };

  const handleFontSizeFocus = () => {
    toggleSizes();
    if (inputFontSizeElmRef.current) {
      inputFontSizeElmRef.current.select();
    }
    dispatch(setWorkspaceTextFocus(true));
  };

  const handleFontSizeBlur = (e) => {
    dispatch(setWorkspaceTextFocus(false));
    handleFontSizeClicked(parseFloat(e.target.value));
  };

  let fontSizeList;
  if (fontSizeFocused && fontSizeContainerBounds) {
    fontSizeList = (
      <FontSizeList
        className="font-size-list"
        open={fontSizeFocused}
        onOutsideMouseDown={toggleSizes}
        style={{
          left: `${fontSizeContainerBounds.left}px`,
          top: `${fontSizeContainerBounds.bottom + 4}px`,
        }}
      >
        <ul>
          {fontSizes.map((item) => {
            return (
              <li
                key={item}
                onClick={() => {
                  toggleSizes();
                  handleFontSizeClicked(item);
                }}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </FontSizeList>
    );
  }

  const adjustTextLayout = async (attribute, value) => {
    const toLoadFont = {};
    toLoadFont[attribute] = value;
    await loadFont(toLoadFont);
    getUpdatedDataList(toLoadFont);
  };

  const onChangeTextLayout = (attribute, value) => {
    const toUpdateStyle = { [attribute]: value };
    const updatedDataList = getUpdatedSubTitleList(
      projectDetails.getIn(["subtitle", "data"]),
      toUpdateStyle,
      workspaceWidth,
      workspaceHeight,
      globalStyles,
      globalTextEffects
    );
    const subtitleWrapperEl = document.getElementsByClassName("subtitle-wrapper");
    if (subtitleWrapperEl.length) {
      Object.entries(updatedDataList).forEach(([dropId, data]) => {
        Object.entries(data).forEach(([id, { toUpdatePos }]) => {
          for (const itemEl of subtitleWrapperEl) {
            if (dropId === itemEl.dataset.dropId && id === itemEl.dataset.subtitleId) {
              itemEl.style[attribute] = value;
              itemEl.style.height = `${workspaceZoom * toUpdatePos.height}px`;
              itemEl.style.width = `${workspaceZoom * toUpdatePos.width}px`;
              itemEl.style.transform = `translate(${toUpdatePos.x * workspaceZoom}px, ${toUpdatePos.y * workspaceZoom}px)`;
            }
          }
        });
      });
    }
  };

  return (
    <SubtitleStyleWrapper>
      <div className="font-family-wrapper">
        <div className="font-family-size-container">
          <CustomTooltipComponent
            tooltipId={"tt-txtsettings"}
            dataTooltip={content.FONT}
            tooltipPosition="bottom"
          >
            <div
              data-tooltip-id={"tt-txtsettings"}
              onClick={toggleFontFamilyWindow}
              className="font-family-container"
              style={{ fontFamily: activeFontFamily }}
            >
              <span className="font-family-name">
                {activeFontName && activeFontName.replace(/^[\"]|[\"]$/g, "")}
              </span>
              <div
                className={`font-family-toggle ${isLoading ? "font-loading" : ""}`}
              >
                <img
                  className="fontname-loader"
                  src={`${STATIC_PATH}chr-loading.gif`}
                  alt="font-name-loader"
                />
                <img
                  className="font-family-toggle-icon"
                  alt="font-family-toggle"
                  src={`${STATIC_PATH}text-property/font-family-toggle.svg`}
                />
              </div>
            </div>
          </CustomTooltipComponent>
          <div ref={fontSizeContainerRef} className="font-size-container">
            <CustomTooltipComponent
              tooltipId={"tt-txtsettings-fsize"}
              dataTooltip={content.FONT_SIZE}
              tooltipPosition="bottom"
            >
              <div
                className="font-size"
                data-tooltip-id={"tt-txtsettings-fsize"}
              >
                <NumberInput
                  inputRef={inputFontSizeElmRef}
                  min={6}
                  max={999}
                  step={1}
                  value={Math.round(fontSize)}
                  precision={1}
                  onChange={() => { }}
                  onFocus={handleFontSizeFocus}
                  onBlur={handleFontSizeBlur}
                  suffix=""
                  saveOnUnmount={false}
                />
                <div
                  className="font-size-btn"
                  onClick={() => handleChangeFontSize("INCREASE")}
                >
                  <img
                    className="font-size-increase"
                    alt="font-size-increase"
                    src={`${STATIC_PATH}text-property/font-size-increase.svg`}
                  />
                </div>
                <div
                  className="font-size-btn"
                  onClick={() => handleChangeFontSize("DECREASE")}
                >
                  <img
                    className="font-size-decrease"
                    alt="font-size-decrease"
                    src={`${STATIC_PATH}text-property/font-size-decrease.svg`}
                  />
                </div>
              </div>
            </CustomTooltipComponent>
            {fontSizeList}
          </div>
        </div>
        <ItemToolColorOption
          className="text-color-btn"
          langId={content.TEXT_COLOR}
          colorType="TEXT"
          colors={[globalTextStyles.subtitleColor]}
          onClick={toggleColorPalette}
        />
        <ColorPickerButton
          background={globalTextStyles.subtitleBgColor}
          onClick={() => {
            setIsUpdateBgColor(!isUpdateBgColor);
            toggleColorPalette();
          }}
        />
      </div>
      {isFontFamilyOpen && (
        <FontFamilyProperty
          isSubtitle={true}
          activeFontFamily={activeFontFamily}
          activeFontName={activeFontName}
          closeFontFamilyWindow={toggleFontFamilyWindow}
          clickHandlerCallback={handleFontFamilySelect}
        />
      )}
      {isColorPaletteOpen && (
        <ColorPaletteContainer $windowLevel={2}>
          <div className="sw--header" onClick={toggleColorPalette}>
            Color
          </div>
          <div className="sw--body">
            <ColorPaletteWindow
              shouldUpdateRecentColor={false}
              currentColor={globalStyles.get("color")}
              colorSelected={onColorSelect}
              showTitle={true}
            />
          </div>
        </ColorPaletteContainer>
      )}
      <TextButtonWrapper>
        <TextStyleButton
          tooltipLangId={content.BOLD}
          iconType="bold"
          isActive={isBoldActive}
          isDisabled={isBoldDisabled}
          src={`${STATIC_PATH}st-bold${isBoldActive ? "-white" : ""}.svg`}
          onClick={onBoldClick}
        />
        <TextStyleButton
          iconType="italic"
          tooltipLangId={content.ITALIC}
          isActive={isItalicActive}
          isDisabled={isItalicDisabled}
          src={`${STATIC_PATH}st-italic${isItalicActive ? "-white" : ""}.svg`}
          onClick={onItalicClick}
        />
        <TextStyleButton
          iconType="underline"
          tooltipLangId={content.UNDERLINE}
          isDisabled={animData.get("isAnimation")}
          isActive={isUnderlineActive}
          src={`${STATIC_PATH}st-underline${isUnderlineActive ? "-white" : ""}.svg`}
          onClick={onUnderlineClick}
        />
        <TextStyleButton
          iconType="align-left"
          tooltipLangId={content.LEFT_ALIGN}
          isActive={activeTextAlign === TEXT_ALIGN.LEFT}
          src={`${STATIC_PATH}st-left${activeTextAlign === TEXT_ALIGN.LEFT ? "-white" : ""}.svg`}
          activeImg={`${STATIC_PATH}st-left-white.svg`}
          onClick={() => alignmentHandler(TEXT_ALIGN.LEFT)}
        />
        <TextStyleButton
          iconType="align-center"
          tooltipLangId={content.CENTER_ALIGN}
          isActive={activeTextAlign === TEXT_ALIGN.CENTER}
          src={`${STATIC_PATH}st-center${activeTextAlign === TEXT_ALIGN.CENTER ? "-white" : ""}.svg`}
          onClick={() => alignmentHandler(TEXT_ALIGN.CENTER)}
        />
        <TextStyleButton
          iconType="align-right"
          tooltipLangId={content.RIGHT_ALIGN}
          isActive={activeTextAlign === TEXT_ALIGN.RIGHT}
          src={`${STATIC_PATH}st-right${activeTextAlign === TEXT_ALIGN.RIGHT ? "-white" : ""}.svg`}
          activeImg={`${STATIC_PATH}st-right-white.svg`}
          onClick={() => alignmentHandler(TEXT_ALIGN.RIGHT)}
        />
        <TextStyleButton
          iconType="toggle-case"
          tooltipLangId={content.TOGGLE_CASE}
          isActive={currentTextTransform !== "none"}
          src={`${STATIC_PATH}st-tt-${currentTextTransform === "none"
            ? "capitalize"
            : currentTextTransform
            }${currentTextTransform !== "none" ? "-white" : ""}.svg`}
          onClick={toggleCaseHandler}
        />
      </TextButtonWrapper>
      <div className="spacing-settings">
        <div className="spacing-wrapper">
          <span>
            <FormattedMessage id={content.LETTER_SPACING} />
          </span>
          <Slider
            min={-0.2}
            max={1}
            inputMin={-0.2}
            inputMax={1}
            step={0.1}
            value={letterSpace}
            onChangeSliderValue={(e) =>
              onChangeTextLayout(LETTER_SPACING, `${parseFloat(e.target.value)}em`)
            }
            onMouseUp={(e) =>
              adjustTextLayout(LETTER_SPACING, `${parseFloat(e.target.value)}em`)
            }
            border={"none"}
            borderRadius={"3px"}
            background={vmTheme[theme].veryLightGray}
            margin={"0"}
            height={"5px"}
            thumb={sliderThumbStyle}
            progressBackground={vmTheme[theme].secondaryBorderColor}
            isChangeBg={true}
            showValue={false}
            showToolTip={true}
            tooltipBottomOffset={"11px"}
            doubleSide={false}
          />
        </div>
        <div className="spacing-wrapper">
          <span>
            <FormattedMessage id={content.LINE_HEIGHT} />
          </span>
          <Slider
            min={0.5}
            max={ITEM_CONFIG.TEXT.limit.lineHeight.max}
            inputMin={0.5}
            inputMax={ITEM_CONFIG.TEXT.limit.lineHeight.max}
            step={0.01}
            value={lineHeight}
            onChangeSliderValue={(e) =>
              onChangeTextLayout(LINE_HEIGHT, parseFloat(e.target.value))
            }
            onMouseUp={(e) =>
              adjustTextLayout(LINE_HEIGHT, parseFloat(e.target.value))
            }
            border={"none"}
            borderRadius={"3px"}
            background={vmTheme[theme].veryLightGray}
            margin={"0"}
            height={"5px"}
            thumb={sliderThumbStyle}
            progressBackground={vmTheme[theme].secondaryBorderColor}
            isChangeBg={true}
            showValue={false}
            showToolTip={true}
            tooltipBottomOffset={"11px"}
            doubleSide={false}
          />
        </div>
      </div>
      {isStyleLoading && <Loader />}
    </SubtitleStyleWrapper>
  );
};

export default SubtitleTextStyle;
