import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  LeftSection,
  Loader,
  PreviewContainer,
  RightSection,
} from "./shorts-components";
import Workspace from "../workspace/workspace";
import Spinner, { SPINNER_TYPE } from "../../common-components/Spinner";
import {
  setAnimoPlayerId,
  setShortContainerId,
  setShortsPlayer,
} from "../../redux/actions/appUtils";
import ActionTypes from "../../constants/action-types";
import { getSubtitlesByDuration, getNewProject } from "./shorts-helper";
import PlayerControlPanel from "./shorts-player-control";
import ShortsContent from "./shorts-content";
import { getWorkspaceItemSource } from "../../helper/URLHelper";

const ShortsPlayer = ({ projectDetails, userDetails, shortData }) => {
  const dispatch = useDispatch();
  const initStages = useSelector((state) => state.app.get("initStages"));
  const animoPlayerId = `animo-player-${shortData.get("id")}`;
  const shortContainerId = `shorts-preview-${shortData.get("id")}`;
  const videoElRef = useRef();

  const [subtitlesInRange, setSubtitlesInRange] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");

  useEffect(() => {
    if (userDetails) {
      const newProject = getNewProject(projectDetails.toJS());
      const subtitles = getSubtitlesByDuration(
        shortData.get("start"),
        shortData.get("end"),
        projectDetails.get("localSubtitle").toJS()
      );
      setSubtitlesInRange(subtitles);
      dispatch(setAnimoPlayerId(animoPlayerId));
      dispatch(setShortsPlayer({ isShortsPlayer: true }));
      dispatch(setShortContainerId(shortContainerId));
      dispatch({ type: ActionTypes.SET_USER_DATA, payload: { ...userDetails } });
      dispatch({ type: ActionTypes.SET_PROJECT, payload: newProject });
      const item = newProject.workspaceItems[Object.keys(newProject.workspaceItems)[0]];
      const { src } = getWorkspaceItemSource({
        item: { src: item.src, subType: item.subType, type: item.type },
      });
      setVideoSrc(src);
      videoElRef.current.currentTime = shortData.get("start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails, userDetails, shortData]);

  let workspaceComponent = null;
  if (initStages.get("projectLoaded")) {
    workspaceComponent = <Workspace />;
  }

  const timeUpdateHandler = (e) => {
    const { currentTime } = e.target;
    if (currentTime >= shortData.get("end")) {
      videoElRef.current.pause();
      videoElRef.current.currentTime = shortData.get("start");
      videoElRef.current.style.display = "none";
    }
  };

  return (
    <Container>
      <LeftSection id={animoPlayerId}>
        {workspaceComponent || (
          <Loader>
            <Spinner type={SPINNER_TYPE.RING_SPINNER} spinnerHeight={"130px"} />
          </Loader>
        )}
        <PreviewContainer id={shortContainerId}>
          <video
            ref={videoElRef}
            src={videoSrc}
            muted
            onTimeUpdate={timeUpdateHandler}
          />
        </PreviewContainer>
        <PlayerControlPanel
          startTime={shortData.get("start")}
          endTime={shortData.get("end")}
          videoRef={videoElRef}
        />
      </LeftSection>
      <RightSection>
        <ShortsContent
          shortData={shortData}
          subtitlesInRange={subtitlesInRange}
        />
      </RightSection>
    </Container>
  );
};

ShortsPlayer.propTypes = {
  projectDetails: PropTypes.object,
  userDetails: PropTypes.object,
  shortData: PropTypes.object,
};

export default ShortsPlayer;
