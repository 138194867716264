import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ExpandableButton from "../../../common-components/ExpandableButton";
import { ENVIRONMENT, STATIC_PATH } from "../../../constants/config";
import content from "../../../constants/content";
import { font } from "../../../constants/font";
import NumberInput, {
  NumberInputStyled,
} from "../../../common-components/NumberInputComponent";
import Typography from "../../../common-components/Typography";
import vmTheme from "../../../constants/theme";
import {
  ColorPaletteWrapper,
  ColorPickerButton,
} from "./subtitle/subtitleproperty-components";
import Slider from "../../zoom/zoom-slider";
import { setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import ColorPaletteWindow from "./color/propertywindow-colorpalette";
import { PORTAL_ID } from "../../../constants";
import { updateTimelineTime } from "../../../redux/actions/timelineUtils";
import VideoSpeed from "./propertywindow-video-speed"

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 16px;
  border-top: 1px solid #e3e4e8;
  border-bottom: 1px solid #e3e4e8;
`;

const Sensitivity = styled.div`
  padding: 16px;
  .heading {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.propertyColor};
    font: ${font.mediumMini};
  }

  .slider {
    grid-column: auto / span 2;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    > div,
    > div input {
      width: 100%;
    }
  }
  .sensitivity-sec {
    display: flex;
    margin-bottom: 10px;
  }
  ${NumberInputStyled} {
    width: 52px;
    margin-left: auto;
    height: 100%;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 4px;
    cursor: text;

    form {
      border-radius: 4px;
      overflow: hidden;
    }

    input {
      font: ${font.normalBase};
      color: ${(props) => props.theme.panelPrimaryColor};
      text-align: center;
    }
  }
`;

const VideoSection = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));
  const selectedItems = useSelector((state) => state.app.get("selectedItems"));
  const projectData = useSelector((state) => state.projectDetails);
  const item = projectData.getIn(["workspaceItems", selectedItems.get(0)]);
  const { credits, greenScreen  } = useSelector((store)=> store.userDetails);
  const { usedCredits, totalCredits } = credits.bgremovalduration;
  const bgBalCred = totalCredits - usedCredits;
  
  const [isBgRemoval, setIsBgRemoval] = useState(item.get("enableBgRemoval"));
  const [isChromaRemoval, setIsChromaRemoval] = useState(false);
  const [chromaKey, setChromaKey] = useState(null);
  const [tolerance, setTolerance] = useState(15);
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);

  useEffect(() => {
    setIsChromaRemoval(!!item.get("chromaKey"));
    setChromaKey(item.get("chromaKey"));
    setTolerance(
      typeof item.get("tolerance") === "number" ? item.get("tolerance") / 2 : 15
    );
  }, [item]);

  const dispatcher = (toUpdate) => {
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const handleTolerance = (e) => {
    const value = parseFloat(e.target.value);
    setTolerance(value);
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        tolerance: value * 2,
      },
    });
    dispatcher(toUpdate);
  };

  const onFocus = () => {
    dispatch(setWorkspaceTextFocus(true));
  };

  const onBlur = (e) => {
    dispatch(setWorkspaceTextFocus(false));
    handleTolerance(e);
  };

  const toggleColorPalette = () => {
    setIsShowColorPicker(!isShowColorPicker);
  };

  const colorSelectionHandler = (color) => {
    setChromaKey(color);
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        chromaKey: color,
      },
    });
    dispatcher(toUpdate);
  };

  const toggleCheckBox = (isChecked, name) => {
    const toUpdate = [];
    if (name === "BG_REMOVAL") {
      setIsBgRemoval(isChecked);
      toUpdate.push({
        id: item.get("id"),
        container: "workspaceItems",
        toUpdate: {
          enableBgRemoval: isChecked,
        },
      });
    } else if (name === "CHROMA_KEY") {
      setIsChromaRemoval(isChecked);
      toUpdate.push({
        id: item.get("id"),
        container: "workspaceItems",
        toUpdate: {
          chromaKey: isChecked ? chromaKey || "#00FF00" : null,
          tolerance: isChecked ? tolerance * 2 : null,
        },
      });
    }
    dispatcher(toUpdate);
  };

  const onPickerClick = () => {
    setChromaKey("#00FF00");
    const toUpdate = [];
    toUpdate.push({
      id: item.get("id"),
      container: "workspaceItems",
      toUpdate: {
        chromaKey: "#00FF00",
      },
    });
    dispatcher(toUpdate);
  };

  return (
    <div>
      {ENVIRONMENT === "LOCAL" &&
        <ExpandableButton
          icon={`${STATIC_PATH}bgRemoval.svg`}
          heading={content.REMOVE_BACKGROUND_MAIN}
          subHeading={(bgBalCred <= 0 && totalCredits !== 0) ? null : content.REMOVE_BACKGROUND_SUBTEXT}
          checked={isBgRemoval}
          handleCheckBox={(isChecked) => toggleCheckBox(isChecked, "BG_REMOVAL")}
          isDisabled={isChromaRemoval}
          tooltipMessage={content.BG_NOT_ALLOWED_WITH_GREEN_SCREEN}
          isUpgrade={totalCredits === 0}
          isLimitExceed={bgBalCred <= 0 && totalCredits !== 0}
          info={"Preview may appear blurry. Final export will have high-resolution."}
          modalContent={{headerText: content.UPGRADE_BG_REMOVAL,subText: content.SUBTEXT_BG_REMOVAL}}
        />
      }
      <ExpandableButton
        isExpandable
        icon={`${STATIC_PATH}green-screen.svg`}
        heading={content.GREEN_SCREEN_MAIN}
        subHeading={content.GREEN_SCREEN_SUBTEXT}
        checked={isChromaRemoval}
        handleCheckBox={(isChecked) => toggleCheckBox(isChecked, "CHROMA_KEY")}
        isDisabled={isBgRemoval}
        tooltipMessage={content.GREEN_SCREEN_NOT_ALLOWED_WITH_BG}
        isUpgrade={!greenScreen}
        modalContent={{headerText: content.UPGRADE_BG_GREEN,subText: content.SUBTEXT_GREEN_REMOVAL}}
      >
        {isChromaRemoval ? <>
          <Flex className="hr-line">
            <Typography
              innerContent="Color to remove"
              color={vmTheme[theme].contactLabelTextColor}
              display="block"
              font={font.mediumMini}
            />
            <ColorPickerButton
              background={chromaKey}
              onClick={toggleColorPalette}
            />
          </Flex>
          <Sensitivity>
            <div className="sensitivity-sec">
              <p className="heading">
                <FormattedMessage id={content.SENSITIVITY} />
              </p>
              <NumberInput
                min={0}
                max={100}
                step={1}
                value={tolerance}
                precision={1}
                onChange={() => { }}
                onFocus={onFocus}
                onBlur={onBlur}
                suffix="%"
                saveOnUnmount={true}
              />
            </div>
            <div className="slider">
              <Slider
                min={0}
                max={100}
                inputMin={0}
                inputMax={100}
                value={tolerance}
                step={1}
                onChangeSliderValue={() => { }}
                onMouseUp={handleTolerance}
                border={"none"}
                borderRadius={"3px"}
                background={vmTheme[theme].veryLightGray}
                margin={"0"}
                height={"5px"}
                thumb={{
                  background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                  border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                  height: "16px",
                  width: "16px",
                  hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                  hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                }}
                progressBackground={vmTheme[theme].secondaryBorderColor}
                isChangeBg={true}
              />
            </div>
          </Sensitivity>
          {isShowColorPicker &&
            ReactDOM.createPortal(
              <ColorPaletteWrapper top="440px" left="135px">
                <ColorPaletteWindow
                  colorSelected={colorSelectionHandler}
                  closePopup={toggleColorPalette}
                  onPickerClick={onPickerClick}
                  currentColor={chromaKey}
                  itemId={"colorSelector"}
                  stopSlidePropogation={true}
                  from="TextEffectsSection"
                  componentFrom="TXT_EFF_SEC"
                />
              </ColorPaletteWrapper>,
              document.getElementById(PORTAL_ID)
            )}
        </> : null}
      </ExpandableButton>
      <Typography
        content={content.VIDEO_SPEED}
        color={vmTheme[theme].panelPrimaryColor}
        display="block"
        font={font.mediumMini}
      />
      <VideoSpeed />
    </div>
  );
};

export default VideoSection;
