import triggerSimpleAjax from "../httpHelper";

const useApi = () => {

    const get = (url, req, isForm = true) => new Promise((res, rej) => {
        triggerSimpleAjax(
            url,
            "GET",
            isForm,
            req,
            res,
            rej
        );
    });

    const post = (url, req, isForm = true) => new Promise((res, rej) => {
        triggerSimpleAjax(
            url,
            "POST",
            isForm,
            req,
            res,
            rej,
            true
        );
    });

    return {
        get, post
    };
};

export default useApi;
