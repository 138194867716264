import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import content from "../constants/content";
import Typography from "./Typography";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";
import vmTheme from "../constants/theme";

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.polarColor};
  border-radius: 4px;
  margin: 15px 0px;
  position: relative;
  cursor: pointer;
  &::after {
    content: url(${STATIC_PATH}miniDownArrow.svg);
    position: absolute;
    right: 0;
    top: 5px;
    transform: ${(props) => props.transformValue};
  }
`;
const Accordion = styled.a`
  background-color: ${(props) => props.theme.polarColor};
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.primaryColor};
  padding: 5px 0px 0px;
  position: relative;
`;

const OptionWrap = styled.div`
  transition: max-height 0.6s ease;
  margin: 8px 0 0;
  display: ${(props) => props.visible || "none"};
  ::-webkit-scrollbar {
    display: none;
  }
  .imageSection {
    border-radius: 6px 0px 0px 6px;
  }
`;

const Option = styled.div`
  color: ${(props) => props.theme.primaryColor};
  display: inline-block;
  width: -webkit-fill-available;
  padding: 0px;
  cursor: pointer;
  font: ${font.normalBase};
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => props.theme.primaryBorderColor};
  border-radius: 6px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  cursor: pointer;
  .checkedIcon {
    margin-left: auto;
    padding-right: 10px;
  }
  &::after {
    content: url(${(props) => props.icons});
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
  }
  &::before {
    content: "";
    background-image: url(${(props) => props.previewImg});
    display: inline-block;
    background-position: center;
    height: 38px;
    position: absolute;
    width: 37px;
    background-size: cover;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
  }
`;

// eslint-disable-next-line react/prop-types
const AccordionComponent = (props) => {
  const {
    title,
    fontStyle,
    options,
    changeHandler,
    activeMenu,
    labelIcon,
    clickHandler,
    subText,
  } = props;
  const [isCollapsible, setIsCollapsible] = useState(false);
  const theme = useSelector((state) => state.app.get("theme"));
  const toggleAccordion = (e) => {
    setIsCollapsible(!isCollapsible);
    clickHandler(e);
  };
  const onOptionClick = useCallback((option) => {
    setIsCollapsible(false);
    changeHandler(option.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { FREE_USAGE_CREDIT_EXCEEDED } = content;

  return (
    <AccordionSection
      transformValue={
        activeMenu === title && isCollapsible
          ? "rotate(180deg)"
          : "rotate(0deg)"
      }
      isCollapsible={isCollapsible}
      onClick={() => {
        toggleAccordion(title);
      }}
    >
      <Accordion isCollapsible={isCollapsible}>
        <Tooltip heading={FREE_USAGE_CREDIT_EXCEEDED}>
          <img src={labelIcon} height="15px" width="15px" alt="labelIcon" />
        </Tooltip>
        <Typography
          innerContent={title}
          {...fontStyle}
          font={
            activeMenu === title && isCollapsible
              ? font.normalBase
              : font.mediumMini
          }
          cursor="pointer"
          padding="2px 0px"
          className="premieum-title"
        />
        <Typography
          font={font.normalBase_21}
          color={vmTheme[theme].paymentCardTextColor}
          innerContent={subText}
          {...fontStyle}
          padding="0px 20px 0px 40px"
          margin="0px 0px 0px auto"
          cursor="pointer"
        />
      </Accordion>
      <OptionWrap
        className="dropdown-options-wrapper"
        visible={activeMenu === title && isCollapsible ? "block" : "none"}
      >
        {options.map((option, index) => (
          <Tooltip
            content={option.contents}
            heading={option.heading}
            key={`${option.contents + index}`}
          >
            <Option
              className="dropdown-option-item"
              onClick={() => onOptionClick(option)}
              onBlur={() =>
                setTimeout(() => {
                  setIsCollapsible(false);
                })
              }
              icons={option.checkIcon}
              previewImg={option.path}
            >
              <Typography
                font={font.normalBase}
                color={vmTheme[theme].labelTextColor}
                innerContent={option.label}
                {...fontStyle}
                padding="2px 0 2px 50px"
                cursor="pointer"
              />
            </Option>
          </Tooltip>
        ))}
      </OptionWrap>
    </AccordionSection>
  );
};
AccordionComponent.propTypes = {
  title: PropTypes.string,
  fontStyle: PropTypes.string,
  options: PropTypes.array,
  changeHandler: PropTypes.func,
  activeMenu: PropTypes.string,
  labelIcon: PropTypes.string,
  clickHandler: PropTypes.func,
  subText: PropTypes.string,
};

export default AccordionComponent;
