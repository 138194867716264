import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  cursor: pointer;
  margin: 0px 8px 0px 0px;
  align-items: center;
  ${(props) =>
    props.isChecked &&
    css`
      display: flex !important;
    `}
  ${(props) => props.styles}
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  border: 1px solid ${(props) => props.checkBoxColor};
  width: ${(props) => props.checkBoxWidth};
  height: ${(props) => props.checkBoxHeight};
  background: ${(props) =>
    props.isChecked
      ? props.checkBoxActiveColor || props.theme.checkBoxTickColor
      : props.theme.polarColor};
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.isChecked ? "visible" : "hidden")};
  }
  border-radius: ${(props) => props.borderRadius};
  ${(props) =>
    props.isChecked &&
    css`
      border-color: ${(props) =>
        props.checkBoxActiveColor || props.theme.checkBoxTickColor};
    `}
`;

const CheckBox = (props) => {
  const { onHandlerClick, id, label, selectedValue } = props;
  const originalIsChecked = Boolean(props.isChecked);
  /* individidual checkbox should maintain their own states
  or a state update will affect all checkboxes */
  const [isChecked, setisChecked] = useState(originalIsChecked);

  const onClick = () => {
    setisChecked(!isChecked);
    onHandlerClick(!isChecked, selectedValue);
  };

  useEffect(() => {
    setisChecked(originalIsChecked);
  }, [originalIsChecked]);

  return (
    <CheckboxContainer onClick={onClick}>
      <HiddenCheckbox id={id} value={isChecked} />
      <StyledCheckbox {...props} isChecked={isChecked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <label htmlFor={id}>{label}</label>
    </CheckboxContainer>
  );
};
/**
 * Default Props Value for Toggle
 */
CheckBox.defaultProps = {
  onHandlerClick: () => {},
  isChecked: false,
  checkBoxWidth: "16px",
  checkBoxHeight: "16px",
  borderRadius: "0px",
};

/**
 * Initialize Prop Types for Toggle
 */
CheckBox.propTypes = {
  id: PropTypes.string,
  onHandlerClick: PropTypes.func,
  isChecked: PropTypes.bool,
  checkBoxWidth: PropTypes.string,
  checkBoxHeight: PropTypes.string,
  borderRadius: PropTypes.string,
  label: PropTypes.string,
  selectedValue: PropTypes.string,
};

export default CheckBox;
