import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";

const TabWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Updated for three tabs */
  grid-template-rows: 44px;
  margin-bottom: 17px;
  border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};
`;

const TabButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font: ${font.normalBase};
  color: ${(props) => props.theme.secondaryColor};
  background: none;
  border: none;
  padding: 0;
  overflow: hidden;
  transition: color 0.3s;

  img {
    width: 16px;
    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
`;

const Indicator = styled.div`
  position: absolute;
  bottom: 0px;
  left: 2px;
  height: 2px;
  z-index: 0;
  width: ${(props) => `${100 / props.totalTabs}%`};
  background-color: ${(props) => props.theme.secondaryBorderColor};
  border-radius: 6px;
  transform: ${(props) => `translateX(${props.activeTab * 100}%)`};
  transition: transform 0.3s linear;
  @media screen and (max-width: 767px) {
    width: calc(100% / 3);
    transform: ${(props) => `translateX(${props.activeTab * 100}%)`};
  }
`;

export const TabToggleComponent = (props) => {
  const { tab1, tab2, tab3, currentTab, setCurrentTab } = props;

  return (
    <TabWrapper>
      <TabButton
        className={`imgf--tab-btn ${
          currentTab === tab1 ? "tab-btn-active" : ""
        }`}
        onClick={() => setCurrentTab(tab1)}
      >
        <img
          draggable={false}
          src={`${STATIC_PATH}${
            currentTab === tab1 ? "preset-active.svg" : "preset.svg"
          }`}
          alt="filters"
        />
        {tab1}
      </TabButton>
      <TabButton
        className={`imgf--tab-btn ${
          currentTab === tab2 ? "tab-btn-active" : ""
        }`}
        onClick={() => setCurrentTab(tab2)}
      >
        <img
          draggable={false}
          src={`${STATIC_PATH}${
            currentTab === tab2 ? "adjust-filter.svg" : "adjustment.svg"
          }`}
          alt="filters"
        />
        {tab2}
      </TabButton>
      <TabButton
        className={`imgf--tab-btn ${
          currentTab === tab3 ? "tab-btn-active" : ""
        }`}
        onClick={() => setCurrentTab(tab3)}
      >
        <img
          draggable={false}
          src={`${STATIC_PATH}${
            currentTab === tab3 ? "vm-style-active.svg" : "vm-style.svg"
          }`}
          alt="filters"
        />
        {tab3}
      </TabButton>
      <Indicator
        totalTabs={3}
        activeTab={[tab1, tab2, tab3].indexOf(currentTab)}
      />
    </TabWrapper>
  );
};

TabToggleComponent.propTypes = {
  tab1: PropTypes.string,
  tab2: PropTypes.string,
  tab3: PropTypes.string,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
};

export default TabToggleComponent;
