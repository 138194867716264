/* eslint-disable react/sort-comp */

import React from "react";
import { connect } from "react-redux";
import { actions as UndoActionCreators } from "redux-undo-redo";
import PropTypes from "prop-types";
import { IconContainer, Img } from "./header-components";
import { STATIC_PATH } from "../../constants/config";
import CustomTooltipComponent from "../../common-components/CustomTooltip";
import content from "../../constants/content";
import vmTheme from "../../constants/theme/index"

class UndoRedoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.canUndo = this.canUndo.bind(this);
    this.canRedo = this.canRedo.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);
    this.onRedoClick = this.onRedoClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.shortcutName !== prevProps.shortcutName &&
      this.props.shortcutName !== ""
    )
      this.handleShortcuts(this.props.shortcutName);
  }

  canUndo() {
    return (
      this.props.undoRedoList.undoQueue.length > 0 && !this.props.isSwapping
    );
  }

  canRedo() {
    return (
      this.props.undoRedoList.redoQueue.length > 0 && !this.props.isSwapping
    );
  }

  handleShortcuts(option) {
    switch (option) {
      case "UNDO_PROJECT":
      case "UNDO_PROJECT_AGAIN":
        if (this.canUndo()) {
          this.props.onUndo();
        }
        break;
      case "REDO_PROJECT":
      case "REDO_PROJECT_AGAIN":
        if (this.canRedo()) {
          this.props.onRedo();
        }
        break;
      default:
        break;
    }
  }

  onUndoClick() {
    this.handleShortcuts("UNDO_PROJECT");
  }

  onRedoClick() {
    this.handleShortcuts("REDO_PROJECT");
  }

  render() {
    const isUndoDisabled = !this.canUndo();
    const isRedoDisabled = !this.canRedo();

    let undoSrc = `${STATIC_PATH}${vmTheme[this.props.theme].icons.undo}`;
    if (isUndoDisabled) {
      undoSrc = `${STATIC_PATH}${vmTheme[this.props.theme].icons.disabledUndo}`;
    }

    let redoSrc = `${STATIC_PATH}${vmTheme[this.props.theme].icons.redoIcon}`;
    if (isRedoDisabled) {
      redoSrc = `${STATIC_PATH}${vmTheme[this.props.theme].icons.disabledRedo}`;
    }

    return (
      <IconContainer>
        <CustomTooltipComponent
          tooltipId={"undo-tooltip"}
          dataTooltip={content.UNDO}
          tooltipPosition="bottom"
          shortcut={["⌘", "Z"]}
        >
          <li data-tooltip-id={"undo-tooltip"}>
            <Img
              src={undoSrc}
              alt="undo-icon"
              height="20px"
              width="20px"
              className={isUndoDisabled ? "is-disabled" : ""}
              onClick={!isUndoDisabled ? this.onUndoClick : undefined}
            />
          </li>
        </CustomTooltipComponent>
        <CustomTooltipComponent
          tooltipId={"redo-tooltip"}
          dataTooltip={content.REDO}
          tooltipPosition="bottom"
          shortcut={["⌘", "Y"]}
        >
          <li data-tooltip-id={"redo-tooltip"}>
            <Img
              src={redoSrc}
              alt="redo-icon"
              height="20px"
              width="20px"
              className={isRedoDisabled ? "is-disabled" : ""}
              onClick={!isRedoDisabled ? this.onRedoClick : undefined}
            />
          </li>
        </CustomTooltipComponent>
      </IconContainer>
    );
  }
}

UndoRedoComponent.propTypes = {
  onUndo: PropTypes.func,
  onRedo: PropTypes.func,
  undoRedoList: PropTypes.object,
  isSwapping: PropTypes.bool,
  shortcutName: PropTypes.string,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isSwapping: Boolean(state.app.get("swapDetails")),
  undoRedoList: state.undoHistory,
  theme: state.app.get("theme"),
});

const mapDispatchToProps = {
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo,
};

const UndoRedo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoRedoComponent);

export default UndoRedo;
