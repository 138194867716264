import triggerSimpleAjax from "../../helper/httpHelper";
import { API_URL, API } from "../../constants/config";

export function exportVideo(reqData) {
  const apiUrl = `${API_URL}${API.EXPORT_VIDEO}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      reqData,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export function vcCall(payload) {
  const apiUrl = `${API_URL}${API.VC}`;
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "POST",
      true,
      payload,
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}

export function getCardNonce(teamId) {
  const apiUrl = `${API_URL}${API.GET_NONCE}?teamId=${teamId}`;
  //   const fetchParams = {
  //     method: "GET",
  //     mode: "cors",
  //   };
  return new Promise((res, rej) => {
    triggerSimpleAjax(
      apiUrl,
      "GET",
      false,
      {},
      (response) => {
        res(response);
      },
      (error) => {
        rej(error);
      }
    );
  });
}
