/* eslint-disable no-restricted-syntax, guard-for-in */

export const Keymap = {
  workspace: {
    DOWN_ARROW: { key: "shift + down arrow", type: "basic" },
    UP_ARROW: { key: "shift + up arrow", type: "basic" },
    RIGHT_ARROW: { key: "shift + right arrow", type: "basic" },
    LEFT_ARROW: { key: "shift + left arrow", type: "basic" },
    SHIFT_DOWN_ARROW: { key: "down arrow", type: "basic" },
    SHIFT_UP_ARROW: { key: "up arrow", type: "basic" },
    SHIFT_RIGHT_ARROW: { key: "right arrow", type: "basic" },
    SHIFT_LEFT_ARROW: { key: "left arrow", type: "basic" },
    DELETE_ITEM: { key: "delete", type: "basic" },
    DELETE_ITEM_MAC: { key: "backspace", type: "basic" },
    COPY_ITEM: { key: "ctrl + c", type: "basic" },
    PASTE_ITEM: { key: "ctrl + v", type: "basic" },
    COPY_TEXT_STYLE: { key: "ctrl + alt + c", type: "basic" },
    PASTE_TEXT_STYLE: { key: "ctrl + alt + v", type: "basic" },
    CUT_ITEM: { key: "ctrl + x", type: "basic" },
    CTRL_PRESS: { key: "ctrl", type: "basic" },
    // OPEN_SETTINGS: {key: "shift + s", type: "basic"},
    BRING_FRONT: { key: "ctrl + up arrow", type: "basic" },
    MOVE_LAST: { key: "ctrl + shift + down arrow", type: "basic" },
    BRING_FIRST: { key: "ctrl + shift + up arrow", type: "basic" },
    MOVE_BACK: { key: "ctrl + down arrow", type: "basic" },
    FLIP_ITEM: { key: "alt + f", type: "basic" },
    GROUP_ITEM: { key: "ctrl + alt + g", type: "basic" },
    UN_GROUP_ITEM: { key: "ctrl + shift + alt + g", type: "basic" },
    ENTER: { key: "enter", type: "basic" },
    ESCAPE: { key: "escape", type: "basic" },
    ZOOMIN: { key: "ctrl + alt + plus", type: "basic" },
    ZOOMOUT: { key: "ctrl + alt + minus", type: "basic" },
    // START_PLAY: { key: "spacebar", type: "basic" },
    COPY_SCENE_WITH_AUDIO: { key: "ctrl + shift + c", type: "basic" },
    // START_PLAYALL: {key: "shift + spacebar", type: "basic"},
  },
  textProperty: {
    SET_BOLD: { key: "ctrl + b", type: "basic" },
    SET_ITALIC: { key: "ctrl + i", type: "basic" },
    SET_UNDERLINE: { key: "ctrl + u", type: "basic" },
    ALIGN_RIGHT: { key: "ctrl + shift + r", type: "basic" },
    ALIGN_LEFT: { key: "ctrl + shift + l", type: "basic" },
    ALIGN_CENTER: { key: "ctrl + shift + e", type: "basic" },
    TO_UPPERCASE: { key: "ctrl + shift + u", type: "basic" },
    TO_SMALLCASE: { key: "ctrl + shift + s", type: "basic" },
    TO_CAPITALCASE: { key: "ctrl + shift + c", type: "basic" },
    REMOVE_CASE: { key: "ctrl + back slash", type: "basic" },
    INCREASE_FONT_SIZE: { key: "ctrl + shift + period", type: "basic" },
    DECREASE_FONT_SIZE: { key: "ctrl + shift + comma", type: "basic" },
    CHANGE_FONT_FAMILY: { key: "ctrl + shift + f", type: "basic" },
    CHANGE_FONT_COLOR: { key: "ctrl + alt + c", type: "basic" },
  },
  timeline: {
    INCREASE_ITEM_START: { key: "alt + s", type: "basic" },
    DECREASE_ITEM_START: { key: "alt + a", type: "basic" },
    INCREASE_ITEM_END: { key: "alt + w", type: "basic" },
    DECREASE_ITEM_END: { key: "alt + q", type: "basic" },
    INCREASE_ENTER_EFFECT_TIME: { key: "shift + alt + s", type: "basic" },
    DECREASE_ENTER_EFFECT_TIME: { key: "shift + alt + a", type: "basic" },
    INCREASE_EXIT_EFFECT_TIME: { key: "shift + alt + w", type: "basic" },
    DECREASE_EXIT_EFFECT_TIME: { key: "shift + alt + q", type: "basic" },
    INCREASE_PLAYHEAD: { key: "alt + x", type: "basic" },
    DECREASE_PLAYHEAD: { key: "alt + z", type: "basic" },
    COPY_ITEM: { key: "ctrl + c", type: "basic" },
    PASTE_ITEM: { key: "ctrl + v", type: "basic" },
    CUT_ITEM: { key: "ctrl + x", type: "basic" },
    DELETE_ITEM: { key: "delete", type: "basic" },
    DELETE_ITEM_MAC: { key: "backspace", type: "basic" },
    INCREASE_SCENE_DURATION: { key: "alt + l", type: "basic" },
    DECREASE_SCENE_DURATION: { key: "alt + k", type: "basic" },
    CTRL_PRESS: { key: "ctrl", type: "basic" },
    CHANGE_ENTER_EFFECT: { key: "alt + left arrow", type: "basic" },
    CHANGE_EXIT_EFFECT: { key: "alt + right arrow", type: "basic" },
    SELECT_ALL_OBJECT: { key: "ctrl + a", type: "basic" },
    DESELECT_ALL_OBJECT: { key: "ctrl + shift + a", type: "basic" },
  },
  menuBar: {
    SAVE_PROJECT: { key: "ctrl + s", type: "basic" },
    UNDO_PROJECT: { key: "ctrl + z", type: "basic" },
    REDO_PROJECT: { key: "ctrl + y", type: "basic" },
    EXPORT_PROJECT: { key: "ctrl + e", type: "basic" },
  },
  playerControl: {
    START_PLAY_PLAYHEAD: { key: "spacebar", type: "basic" },
    MOVE_PLAYHEAD_1S_RIGHT: { key: "right arrow", type: "basic" },
    MOVE_PLAYHEAD_1S_LEFT: { key: "left arrow", type: "basic" },
    MOVE_PLAYHEAD_5S_RIGHT: { key: "shift + right arrow", type: "basic" },
    MOVE_PLAYHEAD_5S_LEFT: { key: "shift + left arrow", type: "basic" },
    FULL_SCREEN_TOGGLE: { key: "f", type: "basic" },
  },
  library: {
    TAB0: { key: "shift + 1", type: "basic" },
    TAB1: { key: "shift + 2", type: "basic" },
    TAB2: { key: "shift + 3", type: "basic" },
    TAB3: { key: "shift + 4", type: "basic" },
    TAB4: { key: "shift + 5", type: "basic" },
    TAB5: { key: "shift + 6", type: "basic" },
    TAB6: { key: "shift + 7", type: "basic" },
    TAB7: { key: "shift + 8", type: "basic" },
    TAB8: { key: "shift + 9", type: "basic" },
    TAB9: { key: "shift + u", type: "basic" },
    TAB10: { key: "shift + +", type: "basic" },
  },
};

export const Keycode = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  "pause/break": 19,
  "caps lock": 20,
  escape: 27,
  spacebar: 32,
  "page up": 33,
  "page down": 34,
  end: 35,
  home: 36,
  "left arrow": 37,
  "up arrow": 38,
  "right arrow": 39,
  "down arrow": 40,
  insert: 45,
  delete: 46,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  meta: 91,
  meta_ff: 224,
  "right window key": 92,
  "select key": 93,
  "numpad 0": 96,
  "numpad 1": 97,
  "numpad 2": 98,
  "numpad 3": 99,
  "numpad 4": 100,
  "numpad 5": 101,
  "numpad 6": 102,
  "numpad 7": 103,
  "numpad 8": 104,
  "numpad 9": 105,
  multiply: 106,
  add: 107,
  subtract: 109,
  "decimal point": 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  "num lock": 144,
  "scroll lock": 145,
  "semi-colon": 186,
  plus: 187,
  plus_ff: 61,
  comma: 188,
  minus: 189,
  minus_ff: 173,
  period: 190,
  "forward slash": 191,
  "grave accent": 192,
  "open bracket": 219,
  "back slash": 220,
  "close bracket": 221,
  "single quote": 222,
};

function override(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function validateKeydown(e, textFocus, textStatus) {
  const c = e.which || e.keyCode; // Get key code
  if ((c === 32 || c === 46 || c === 8) && !textFocus) override(e);

  if (textFocus) {
    /** @Discription to prevent native undoRedo when text is focused */
    if ((e.code === "KeyZ" || e.code === "KeyY") && (e.ctrlKey || e.metaKey)) {
      override(e);
    }
  }

  if ((e.ctrlKey || e.metaKey) && !e.shiftKey && !e.altKey) {
    switch (c) {
      case 32: // Block Ctrl+space
      case 37: // Block Ctrl+left arrow
      case 39: // Block Ctrl+right arrow
      case 66: // Block Ctrl+B
      case 68: // Block Ctrl+D
      case 69: // Block Ctrl+E
      case 70: // Block Ctrl+F
      case 71: // Block Ctrl+G
      case 73: // Block Ctrl+I
      case 76: // Block Ctrl+L
      case 78: // Block Ctrl+N --Not working
      case 79: // Block Ctrl+O
      case 72: // Block Ctrl+H
      case 74: // Block Ctrl+J
      case 77: // Block Ctrl+M
      case 81: // Block Ctrl+Q
      case 80: // Block Ctrl+P
      case 83: // Block Ctrl+S
      case 84: // Block Ctrl+T --Not working
      case 85: // Block Ctrl+U
      case 89: // Block Ctrl+Y
      case 87: // Block Ctrl+W --Not working
      case 220: // Block Ctrl+Back Slash
        override(e);
        break;
      case 65: // Block Ctrl+A
      case 67: // Block Ctrl+C
        if (!textFocus || e.altKey) override(e);
        break;
      case 82: // Block Ctrl+R
        if (textStatus.get("isSelected")) override(e);
        break;
      default:
        break;
    }
  } else if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
    switch (c) {
      case 67: // Block ctrl+shift+c chrome shortcut to enable responsive mode
      case 70: // Block Ctrl+shift+f chrome shortcut to activate url bar
      case 69: // Block Ctrl+shift+e chrome shortcut to extension
      case 83: // Block Ctrl+shift+s Firefox shortcut to screenshot
      case 65: // Block Ctrl+a Firefox shortcut to open addons
        override(e);
        break;
      case 82: // Block Ctrl+Shift+e Hard Refresh
        if (textStatus.get("isSelected")) override(e);
        break;
      default:
        break;
    }
  } else if ((e.ctrlKey || e.metaKey) && e.altKey) {
    switch (c) {
      case 187: // Block ctrl+alt++
      case 189: // Block Ctrl+alt+-
      case 61: // Block ctrl+alt++
      case 173: // Block Ctrl+alt+-
        override(e);
        break;
      default:
        break;
    }
  } else if (e.altKey) {
    switch (c) {
      case 36: // Block alt+home
      case 37: // Block alt+left arrow
      case 39: // Block alt+right arrow
      case 67: // Block alt+C
      case 68: // Block alt+D
      case 9: // Block alt+tab
        override(e);
        break;
      default:
        break;
    }
  }

  let keyName = "";
  let keyOption = "";
  let key;
  let compName = "";
  const resultArr = [];
  for (key in Keycode) {
    if (e.keyCode === Keycode[key]) keyName = key;
  }

  if (keyName !== "") {
    if (keyName === "add" || e.keyCode === 61) keyName = "plus";
    else if (keyName === "subtract" || e.keyCode === 173) keyName = "minus";
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.altKey)
      keyName = `ctrl + shift + alt + ${keyName}`;
    else if ((e.ctrlKey || e.metaKey) && e.shiftKey)
      keyName = `ctrl + shift + ${keyName}`;
    else if ((e.ctrlKey || e.metaKey) && e.altKey)
      keyName = `ctrl + alt + ${keyName}`;
    else if (e.shiftKey && e.altKey) keyName = `shift + alt + ${keyName}`;
    else if (e.altKey && keyName !== "alt") keyName = `alt + ${keyName}`;
    else if (e.shiftKey && keyName !== "shift") keyName = `shift + ${keyName}`;
    else if (
      (e.ctrlKey || e.metaKey) &&
      keyName !== "ctrl" &&
      keyName !== "meta" &&
      keyName !== "meta_ff"
    )
      keyName = `ctrl + ${keyName}`;
    else if (e.ctrlKey || e.metaKey) keyName = "ctrl";

    for (key in Keymap) {
      const obj = Keymap[key];
      for (const innerkey in obj) {
        if (keyName === obj[innerkey].key && obj[innerkey].type === "basic") {
          keyOption = innerkey;
          compName = key;
          resultArr.push({ option: keyOption, component: compName });
        }
      }
    }
  }

  return resultArr;
}
